<div class="header">
  <h3 class="title">{{ deal.title }}</h3>

  <div class="actions">
    <mat-icon (click)="onDeleteDeal($event)" class="delete-btn">delete</mat-icon>
  </div>
</div>

<div class="date-block">
  <span class="date"><small>{{ deal.person.name }}</small></span>
</div>

<div class="footer">
  <div class="user">
    <span class="name"><div class="img" [matTooltip]="deal.owner.display_name"><img [src]="deal.owner.avatar_url" [alt]="deal.owner.display_name"></div></span>
    
  </div>

  <div class="price">
    <span class="name aling"><mat-icon matBadgeOverlap="false" style="margin-right: 5px;">today</mat-icon><small>{{getDate(deal.date_close)}}</small></span>
    <span class="name aling"><mat-icon matBadgeOverlap="false" style="margin-right: 5px;">chat_bubble_outline</mat-icon><small>{{ deal.comments_aggregate.aggregate.count }}</small></span>
  </div>
</div>