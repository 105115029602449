import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PrivateLayoutComponent } from '../layout/private';

import { PageDashboardComponent } from '../pages/dashboard';
import { PageTasksComponent } from '../pages/tasks';
import { PageContactsComponent } from '../pages/contacts';
import { PageEmailComponent } from '../pages/email';
import { PageDealsComponent } from '../pages/deals';
import { PageProfileComponent } from '../pages/profile';
import { PageSettingsComponent } from '../pages/page-settings';
import { PageChatComponent } from '../pages/page-chat';
import { PageTypographyComponent } from '../pages/ui/typography';
import { PageGridComponent } from '../pages/ui/grid';
import { PageIconsComponent } from '../pages/ui/icons';
import { PageButtonsComponent } from '../pages/ui/buttons';
import { PageBadgesComponent } from '../pages/ui/badges';
import { LoginComponent } from '../pages/login/login.component';
import { PageLeadsComponent } from '../pages/leads/leads.component';

const PRIVATE_ROUTES: Routes = [
  { path: 'dashboard', component: PageDashboardComponent },
  { path: 'tasks', component: PageTasksComponent },
  { path: 'email', component: PageEmailComponent },
  { path: 'contacts', component: PageContactsComponent },
  { path: 'contacts-details/:id', component: PageContactsComponent },
  { path: 'contacts/:id', component: PageContactsComponent },
  { path: 'chat', component: PageChatComponent },
  { path: 'deals', component: PageDealsComponent },
  { path: 'leads', component: PageLeadsComponent },
  { path: 'leads-pipe/:id', component: PageLeadsComponent },
  { path: 'profile', component: PageProfileComponent },
  { path: 'settings', component: PageSettingsComponent },
  { path: 'typography', component: PageTypographyComponent },
  { path: 'grid', component: PageGridComponent },
  { path: 'icons', component: PageIconsComponent },
  { path: 'buttons', component: PageButtonsComponent },
  { path: 'badges', component: PageBadgesComponent }
];

export const ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'private',
    component: PrivateLayoutComponent,
    children: PRIVATE_ROUTES
  }
];

@NgModule({
  imports: [],
  exports: [RouterModule]
})
export class AppRoutingModule {}
