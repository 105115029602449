import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Long` scalar type represents 52-bit integers */
  Long: any;
  /** A time string with format: HH:mm:ss.SSS */
  Time: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  bigint: any;
  citext: any;
  float8: any;
  json: any;
  jsonb: any;
  numeric: any;
  timestamptz: any;
  uuid: any;
};



export type AccountMail = {
  __typename?: 'AccountMail';
  date?: Maybe<Scalars['String']>;
  from?: Maybe<Sender>;
  id?: Maybe<Scalars['String']>;
  inReplyTo?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Sender>;
  sender?: Maybe<Sender>;
  size?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  text?: Maybe<Text>;
  to?: Maybe<Array<Maybe<Sender>>>;
};

export type AdminUser = {
  __typename?: 'AdminUser';
  firstname: Scalars['String'];
  id: Scalars['ID'];
  lastname: Scalars['String'];
  username?: Maybe<Scalars['String']>;
};

export type AuthInput = {
  pass: Scalars['String'];
  user: Scalars['String'];
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type Company = {
  __typename?: 'Company';
  Works?: Maybe<ComponentLpWorks>;
  banner?: Maybe<ComponentLpBanner>;
  created_at: Scalars['DateTime'];
  footer?: Maybe<ComponentLpFooter>;
  header?: Maybe<ComponentLpHeader>;
  id: Scalars['ID'];
  published_at?: Maybe<Scalars['DateTime']>;
  registro?: Maybe<ComponentLpRegistro>;
  sidebar?: Maybe<ComponentLpSidebar>;
  updated_at: Scalars['DateTime'];
};

export type CompanyInput = {
  Works?: Maybe<ComponentLpWorkInput>;
  banner?: Maybe<ComponentLpBannerInput>;
  created_by?: Maybe<Scalars['ID']>;
  footer?: Maybe<ComponentLpFooterInput>;
  header?: Maybe<ComponentLpHeaderInput>;
  published_at?: Maybe<Scalars['DateTime']>;
  registro?: Maybe<ComponentLpRegistroInput>;
  sidebar?: Maybe<ComponentLpSidebarInput>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type ComponentLpBanner = {
  __typename?: 'ComponentLpBanner';
  button?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type ComponentLpBannerInput = {
  button?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ComponentLpButtonHeader = {
  __typename?: 'ComponentLpButtonHeader';
  Label?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  url?: Maybe<Scalars['String']>;
};

export type ComponentLpButtonHeaderInput = {
  Label?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ComponentLpFooter = {
  __typename?: 'ComponentLpFooter';
  copy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  logo?: Maybe<UploadFile>;
};

export type ComponentLpFooterInput = {
  copy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['ID']>;
};

export type ComponentLpHeader = {
  __typename?: 'ComponentLpHeader';
  Button?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type ComponentLpHeaderInput = {
  Button?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ComponentLpLogin = {
  __typename?: 'ComponentLpLogin';
  create_user?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  logo?: Maybe<UploadFile>;
  title?: Maybe<Scalars['String']>;
};

export type ComponentLpLoginInput = {
  create_user?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type ComponentLpMenu = {
  __typename?: 'ComponentLpMenu';
  Title?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  url?: Maybe<Scalars['String']>;
};

export type ComponentLpMenuInput = {
  Title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ComponentLpRegistro = {
  __typename?: 'ComponentLpRegistro';
  Description?: Maybe<Scalars['String']>;
  button_send?: Maybe<Scalars['String']>;
  form_image?: Maybe<UploadFile>;
  forms?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type ComponentLpRegistroInput = {
  Description?: Maybe<Scalars['String']>;
  button_send?: Maybe<Scalars['String']>;
  form_image?: Maybe<Scalars['ID']>;
  forms?: Maybe<Scalars['String']>;
};

export type ComponentLpSidebar = {
  __typename?: 'ComponentLpSidebar';
  Logo?: Maybe<UploadFile>;
  Menu?: Maybe<Array<Maybe<ComponentLpMenu>>>;
  button_header?: Maybe<Array<Maybe<ComponentLpButtonHeader>>>;
  id: Scalars['ID'];
};

export type ComponentLpSidebarInput = {
  Logo?: Maybe<Scalars['ID']>;
  Menu?: Maybe<Array<Maybe<ComponentLpMenuInput>>>;
  button_header?: Maybe<Array<Maybe<ComponentLpButtonHeaderInput>>>;
};

export type ComponentLpStepInput = {
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type ComponentLpSteps = {
  __typename?: 'ComponentLpSteps';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<UploadFile>;
  title?: Maybe<Scalars['String']>;
};

export type ComponentLpWorkInput = {
  Title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  steps?: Maybe<Array<Maybe<ComponentLpStepInput>>>;
};

export type ComponentLpWorks = {
  __typename?: 'ComponentLpWorks';
  Title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  steps?: Maybe<Array<Maybe<ComponentLpSteps>>>;
};

export type CreateAccountInput = {
  account: Scalars['String'];
  imap: HostInput;
  name: Scalars['String'];
  organization_id: Scalars['Int'];
  smtp: HostInput;
};

export type CreateAccountOutput = {
  __typename?: 'CreateAccountOutput';
  account: Scalars['String'];
  state: Scalars['String'];
};



export type DeleteAccountOutput = {
  __typename?: 'DeleteAccountOutput';
  account: Scalars['String'];
  deleted: Scalars['Boolean'];
};

export type ErrorOutput = {
  __typename?: 'ErrorOutput';
  lastError?: Maybe<Scalars['String']>;
  syncTime: Scalars['String'];
};

export type FileInfoInput = {
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type FileInput = {
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['ID']>;
  ext?: Maybe<Scalars['String']>;
  formats?: Maybe<Scalars['JSON']>;
  hash: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  mime: Scalars['String'];
  name: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  provider_metadata?: Maybe<Scalars['JSON']>;
  related?: Maybe<Array<Maybe<Scalars['ID']>>>;
  size: Scalars['Float'];
  updated_by?: Maybe<Scalars['ID']>;
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type HostInput = {
  auth: AuthInput;
  host: Scalars['String'];
  port: Scalars['Int'];
  secure: Scalars['Boolean'];
};

export type InputId = {
  id: Scalars['ID'];
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};


export type Login = {
  __typename?: 'Login';
  created_at: Scalars['DateTime'];
  id: Scalars['ID'];
  login?: Maybe<ComponentLpLogin>;
  published_at?: Maybe<Scalars['DateTime']>;
  updated_at: Scalars['DateTime'];
};

export type LoginInput = {
  created_by?: Maybe<Scalars['ID']>;
  login?: Maybe<ComponentLpLoginInput>;
  published_at?: Maybe<Scalars['DateTime']>;
  updated_by?: Maybe<Scalars['ID']>;
};


export type MailAccountOutput = {
  __typename?: 'MailAccountOutput';
  account: Scalars['String'];
  connect?: Maybe<Omnichannel_Addons_Connects>;
  name: Scalars['String'];
  response?: Maybe<Scalars['String']>;
  serverResponseCode?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  syncTime?: Maybe<Scalars['String']>;
};

export type MailAccountsInput = {
  state?: Maybe<Scalars['String']>;
};

export type Menu = {
  __typename?: 'Menu';
  Title?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  id: Scalars['ID'];
  published_at?: Maybe<Scalars['DateTime']>;
  updated_at: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

export type MenuInput = {
  Title?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['ID']>;
  published_at?: Maybe<Scalars['DateTime']>;
  updated_by?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
};

export type Morph = Company | ComponentLpBanner | ComponentLpButtonHeader | ComponentLpFooter | ComponentLpHeader | ComponentLpLogin | ComponentLpMenu | ComponentLpRegistro | ComponentLpSidebar | ComponentLpSteps | ComponentLpWorks | Login | Menu | Sidebar | UploadFile | UploadFileAggregator | UploadFileAggregatorAvg | UploadFileAggregatorMax | UploadFileAggregatorMin | UploadFileAggregatorSum | UploadFileConnection | UploadFileConnectionAlternativeText | UploadFileConnectionCaption | UploadFileConnectionCreated_At | UploadFileConnectionExt | UploadFileConnectionFormats | UploadFileConnectionHash | UploadFileConnectionHeight | UploadFileConnectionId | UploadFileConnectionMime | UploadFileConnectionName | UploadFileConnectionPreviewUrl | UploadFileConnectionProvider | UploadFileConnectionProvider_Metadata | UploadFileConnectionSize | UploadFileConnectionUpdated_At | UploadFileConnectionUrl | UploadFileConnectionWidth | UploadFileGroupBy | UserPermissionsPasswordPayload | UsersPermissionsLoginPayload | UsersPermissionsMe | UsersPermissionsMeRole | UsersPermissionsPermission | UsersPermissionsRole | UsersPermissionsUser | CreateUserPayload | DeleteCompanyPayload | DeleteFilePayload | DeleteLoginPayload | DeleteMenuPayload | DeleteSidebarPayload | UpdateCompanyPayload | UpdateLoginPayload | UpdateMenuPayload | UpdateSidebarPayload | UpdateUserPayload;

export type Mutation = {
  __typename?: 'Mutation';
  deleteCompany?: Maybe<DeleteCompanyPayload>;
  /** Delete one file */
  deleteFile?: Maybe<DeleteFilePayload>;
  deleteLogin?: Maybe<DeleteLoginPayload>;
  deleteMenu?: Maybe<DeleteMenuPayload>;
  deleteSidebar?: Maybe<DeleteSidebarPayload>;
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  forgotPassword?: Maybe<UserPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  multipleUpload: Array<Maybe<UploadFile>>;
  register: UsersPermissionsLoginPayload;
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  updateCompany?: Maybe<UpdateCompanyPayload>;
  updateFileInfo: UploadFile;
  updateLogin?: Maybe<UpdateLoginPayload>;
  updateMenu?: Maybe<UpdateMenuPayload>;
  updateSidebar?: Maybe<UpdateSidebarPayload>;
  upload: UploadFile;
};


export type MutationDeleteFileArgs = {
  input?: Maybe<DeleteFileInput>;
};


export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};


export type MutationMultipleUploadArgs = {
  field?: Maybe<Scalars['String']>;
  files: Array<Maybe<Scalars['Upload']>>;
  ref?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['ID']>;
  source?: Maybe<Scalars['String']>;
};


export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};


export type MutationResetPasswordArgs = {
  code: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationUpdateCompanyArgs = {
  input?: Maybe<UpdateCompanyInput>;
};


export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID'];
  info: FileInfoInput;
};


export type MutationUpdateLoginArgs = {
  input?: Maybe<UpdateLoginInput>;
};


export type MutationUpdateMenuArgs = {
  input?: Maybe<UpdateMenuInput>;
};


export type MutationUpdateSidebarArgs = {
  input?: Maybe<UpdateSidebarInput>;
};


export type MutationUploadArgs = {
  field?: Maybe<Scalars['String']>;
  file: Scalars['Upload'];
  ref?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['ID']>;
  source?: Maybe<Scalars['String']>;
};

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW'
}

export type Query = {
  __typename?: 'Query';
  get_mail_account?: Maybe<AccountMail>;
};


export type QueryGet_Mail_AccountArgs = {
  accounts?: Maybe<Scalars['uuid']>;
  message_id?: Maybe<Scalars['String']>;
};

export type Reference = {
  action: Scalars['String'];
  message: Scalars['String'];
};

export type Reorder = {
  __typename?: 'Reorder';
  message: Scalars['String'];
};

export type ReorderInput = {
  card_id: Scalars['uuid'];
  list_id: Scalars['uuid'];
  order: Scalars['Int'];
};

export type RoleInput = {
  created_by?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  permissions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  type?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['ID']>;
  users?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type SampleOutput = {
  __typename?: 'SampleOutput';
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
};

export type SendEmailInput = {
  account_id: Scalars['String'];
  bcc?: Maybe<Array<Maybe<SenderSend>>>;
  cc?: Maybe<Array<Maybe<SenderSend>>>;
  from: SenderSend;
  html?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
  reference?: Maybe<Reference>;
  subject: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  to: Array<Maybe<SenderSend>>;
};

export type Sender = {
  __typename?: 'Sender';
  address?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SenderSend = {
  address: Scalars['String'];
  name: Scalars['String'];
};

export type Sidebar = {
  __typename?: 'Sidebar';
  Logo?: Maybe<UploadFile>;
  Menu?: Maybe<Array<Maybe<ComponentLpMenu>>>;
  button_header?: Maybe<Array<Maybe<ComponentLpButtonHeader>>>;
  created_at: Scalars['DateTime'];
  id: Scalars['ID'];
  published_at?: Maybe<Scalars['DateTime']>;
  updated_at: Scalars['DateTime'];
};

export type SidebarInput = {
  Logo?: Maybe<Scalars['ID']>;
  Menu?: Maybe<Array<Maybe<ComponentLpMenuInput>>>;
  button_header?: Maybe<Array<Maybe<ComponentLpButtonHeaderInput>>>;
  created_by?: Maybe<Scalars['ID']>;
  published_at?: Maybe<Scalars['DateTime']>;
  updated_by?: Maybe<Scalars['ID']>;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

export type Text = {
  __typename?: 'Text';
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};



export type UploadFile = {
  __typename?: 'UploadFile';
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  ext?: Maybe<Scalars['String']>;
  formats?: Maybe<Scalars['JSON']>;
  hash: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  mime: Scalars['String'];
  name: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  provider_metadata?: Maybe<Scalars['JSON']>;
  related?: Maybe<Array<Maybe<Morph>>>;
  size: Scalars['Float'];
  updated_at: Scalars['DateTime'];
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};


export type UploadFileRelatedArgs = {
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};

export type UploadFileAggregator = {
  __typename?: 'UploadFileAggregator';
  avg?: Maybe<UploadFileAggregatorAvg>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<UploadFileAggregatorMax>;
  min?: Maybe<UploadFileAggregatorMin>;
  sum?: Maybe<UploadFileAggregatorSum>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type UploadFileAggregatorAvg = {
  __typename?: 'UploadFileAggregatorAvg';
  height?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type UploadFileAggregatorMax = {
  __typename?: 'UploadFileAggregatorMax';
  height?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type UploadFileAggregatorMin = {
  __typename?: 'UploadFileAggregatorMin';
  height?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type UploadFileAggregatorSum = {
  __typename?: 'UploadFileAggregatorSum';
  height?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type UploadFileConnection = {
  __typename?: 'UploadFileConnection';
  aggregate?: Maybe<UploadFileAggregator>;
  groupBy?: Maybe<UploadFileGroupBy>;
  values?: Maybe<Array<Maybe<UploadFile>>>;
};

export type UploadFileConnectionAlternativeText = {
  __typename?: 'UploadFileConnectionAlternativeText';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UploadFileConnectionCaption = {
  __typename?: 'UploadFileConnectionCaption';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UploadFileConnectionCreated_At = {
  __typename?: 'UploadFileConnectionCreated_at';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type UploadFileConnectionExt = {
  __typename?: 'UploadFileConnectionExt';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UploadFileConnectionFormats = {
  __typename?: 'UploadFileConnectionFormats';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['JSON']>;
};

export type UploadFileConnectionHash = {
  __typename?: 'UploadFileConnectionHash';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UploadFileConnectionHeight = {
  __typename?: 'UploadFileConnectionHeight';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['Int']>;
};

export type UploadFileConnectionId = {
  __typename?: 'UploadFileConnectionId';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['ID']>;
};

export type UploadFileConnectionMime = {
  __typename?: 'UploadFileConnectionMime';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UploadFileConnectionName = {
  __typename?: 'UploadFileConnectionName';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UploadFileConnectionPreviewUrl = {
  __typename?: 'UploadFileConnectionPreviewUrl';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UploadFileConnectionProvider = {
  __typename?: 'UploadFileConnectionProvider';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UploadFileConnectionProvider_Metadata = {
  __typename?: 'UploadFileConnectionProvider_metadata';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['JSON']>;
};

export type UploadFileConnectionSize = {
  __typename?: 'UploadFileConnectionSize';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['Float']>;
};

export type UploadFileConnectionUpdated_At = {
  __typename?: 'UploadFileConnectionUpdated_at';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['DateTime']>;
};

export type UploadFileConnectionUrl = {
  __typename?: 'UploadFileConnectionUrl';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['String']>;
};

export type UploadFileConnectionWidth = {
  __typename?: 'UploadFileConnectionWidth';
  connection?: Maybe<UploadFileConnection>;
  key?: Maybe<Scalars['Int']>;
};

export type UploadFileGroupBy = {
  __typename?: 'UploadFileGroupBy';
  alternativeText?: Maybe<Array<Maybe<UploadFileConnectionAlternativeText>>>;
  caption?: Maybe<Array<Maybe<UploadFileConnectionCaption>>>;
  created_at?: Maybe<Array<Maybe<UploadFileConnectionCreated_At>>>;
  ext?: Maybe<Array<Maybe<UploadFileConnectionExt>>>;
  formats?: Maybe<Array<Maybe<UploadFileConnectionFormats>>>;
  hash?: Maybe<Array<Maybe<UploadFileConnectionHash>>>;
  height?: Maybe<Array<Maybe<UploadFileConnectionHeight>>>;
  id?: Maybe<Array<Maybe<UploadFileConnectionId>>>;
  mime?: Maybe<Array<Maybe<UploadFileConnectionMime>>>;
  name?: Maybe<Array<Maybe<UploadFileConnectionName>>>;
  previewUrl?: Maybe<Array<Maybe<UploadFileConnectionPreviewUrl>>>;
  provider?: Maybe<Array<Maybe<UploadFileConnectionProvider>>>;
  provider_metadata?: Maybe<Array<Maybe<UploadFileConnectionProvider_Metadata>>>;
  size?: Maybe<Array<Maybe<UploadFileConnectionSize>>>;
  updated_at?: Maybe<Array<Maybe<UploadFileConnectionUpdated_At>>>;
  url?: Maybe<Array<Maybe<UploadFileConnectionUrl>>>;
  width?: Maybe<Array<Maybe<UploadFileConnectionWidth>>>;
};

export type UserInput = {
  blocked?: Maybe<Scalars['Boolean']>;
  confirmationToken?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  created_by?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  resetPasswordToken?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
  username: Scalars['String'];
};

export type UserPermissionsPasswordPayload = {
  __typename?: 'UserPermissionsPasswordPayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String'];
  password: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  blocked?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  role?: Maybe<UsersPermissionsMeRole>;
  username: Scalars['String'];
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String'];
  controller: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  policy?: Maybe<Scalars['String']>;
  role?: Maybe<UsersPermissionsRole>;
  type: Scalars['String'];
};

export type UsersPermissionsRegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions?: Maybe<Array<Maybe<UsersPermissionsPermission>>>;
  type?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<UsersPermissionsUser>>>;
};


export type UsersPermissionsRolePermissionsArgs = {
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};


export type UsersPermissionsRoleUsersArgs = {
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  blocked?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  provider?: Maybe<Scalars['String']>;
  role?: Maybe<UsersPermissionsRole>;
  updated_at: Scalars['DateTime'];
  username: Scalars['String'];
};

/** columns and relationships of "acl_features" */
export type Acl_Features = {
  __typename?: 'acl_features';
  created_at: Scalars['timestamptz'];
  desktop: Scalars['Boolean'];
  divider: Scalars['Boolean'];
  icon: Scalars['String'];
  id: Scalars['Int'];
  is_submenu: Scalars['Boolean'];
  name: Scalars['String'];
  order: Scalars['Int'];
  product_id: Scalars['Int'];
  routing: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "acl_features" */
export type Acl_Features_Aggregate = {
  __typename?: 'acl_features_aggregate';
  aggregate?: Maybe<Acl_Features_Aggregate_Fields>;
  nodes: Array<Acl_Features>;
};

/** aggregate fields of "acl_features" */
export type Acl_Features_Aggregate_Fields = {
  __typename?: 'acl_features_aggregate_fields';
  avg?: Maybe<Acl_Features_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Acl_Features_Max_Fields>;
  min?: Maybe<Acl_Features_Min_Fields>;
  stddev?: Maybe<Acl_Features_Stddev_Fields>;
  stddev_pop?: Maybe<Acl_Features_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Acl_Features_Stddev_Samp_Fields>;
  sum?: Maybe<Acl_Features_Sum_Fields>;
  var_pop?: Maybe<Acl_Features_Var_Pop_Fields>;
  var_samp?: Maybe<Acl_Features_Var_Samp_Fields>;
  variance?: Maybe<Acl_Features_Variance_Fields>;
};


/** aggregate fields of "acl_features" */
export type Acl_Features_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Acl_Features_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "acl_features" */
export type Acl_Features_Aggregate_Order_By = {
  avg?: Maybe<Acl_Features_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Acl_Features_Max_Order_By>;
  min?: Maybe<Acl_Features_Min_Order_By>;
  stddev?: Maybe<Acl_Features_Stddev_Order_By>;
  stddev_pop?: Maybe<Acl_Features_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Acl_Features_Stddev_Samp_Order_By>;
  sum?: Maybe<Acl_Features_Sum_Order_By>;
  var_pop?: Maybe<Acl_Features_Var_Pop_Order_By>;
  var_samp?: Maybe<Acl_Features_Var_Samp_Order_By>;
  variance?: Maybe<Acl_Features_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "acl_features" */
export type Acl_Features_Arr_Rel_Insert_Input = {
  data: Array<Acl_Features_Insert_Input>;
  on_conflict?: Maybe<Acl_Features_On_Conflict>;
};

/** aggregate avg on columns */
export type Acl_Features_Avg_Fields = {
  __typename?: 'acl_features_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "acl_features" */
export type Acl_Features_Avg_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "acl_features". All fields are combined with a logical 'AND'. */
export type Acl_Features_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Acl_Features_Bool_Exp>>>;
  _not?: Maybe<Acl_Features_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Acl_Features_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  desktop?: Maybe<Boolean_Comparison_Exp>;
  divider?: Maybe<Boolean_Comparison_Exp>;
  icon?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_submenu?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  product_id?: Maybe<Int_Comparison_Exp>;
  routing?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "acl_features" */
export enum Acl_Features_Constraint {
  /** unique or primary key constraint */
  AclFeaturesPkey = 'acl_features_pkey'
}

/** input type for incrementing integer column in table "acl_features" */
export type Acl_Features_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "acl_features" */
export type Acl_Features_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  desktop?: Maybe<Scalars['Boolean']>;
  divider?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_submenu?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  routing?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Acl_Features_Max_Fields = {
  __typename?: 'acl_features_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  routing?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "acl_features" */
export type Acl_Features_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  icon?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  routing?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Acl_Features_Min_Fields = {
  __typename?: 'acl_features_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  routing?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "acl_features" */
export type Acl_Features_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  icon?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  routing?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "acl_features" */
export type Acl_Features_Mutation_Response = {
  __typename?: 'acl_features_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Acl_Features>;
};

/** input type for inserting object relation for remote table "acl_features" */
export type Acl_Features_Obj_Rel_Insert_Input = {
  data: Acl_Features_Insert_Input;
  on_conflict?: Maybe<Acl_Features_On_Conflict>;
};

/** on conflict condition type for table "acl_features" */
export type Acl_Features_On_Conflict = {
  constraint: Acl_Features_Constraint;
  update_columns: Array<Acl_Features_Update_Column>;
  where?: Maybe<Acl_Features_Bool_Exp>;
};

/** ordering options when selecting data from "acl_features" */
export type Acl_Features_Order_By = {
  created_at?: Maybe<Order_By>;
  desktop?: Maybe<Order_By>;
  divider?: Maybe<Order_By>;
  icon?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_submenu?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  routing?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** columns and relationships of "acl_features_permissions" */
export type Acl_Features_Permissions = {
  __typename?: 'acl_features_permissions';
  /** An object relationship */
  acl_feature: Acl_Features;
  acl_features_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  delete: Scalars['Boolean'];
  id: Scalars['Int'];
  read: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
  write: Scalars['Boolean'];
};

/** aggregated selection of "acl_features_permissions" */
export type Acl_Features_Permissions_Aggregate = {
  __typename?: 'acl_features_permissions_aggregate';
  aggregate?: Maybe<Acl_Features_Permissions_Aggregate_Fields>;
  nodes: Array<Acl_Features_Permissions>;
};

/** aggregate fields of "acl_features_permissions" */
export type Acl_Features_Permissions_Aggregate_Fields = {
  __typename?: 'acl_features_permissions_aggregate_fields';
  avg?: Maybe<Acl_Features_Permissions_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Acl_Features_Permissions_Max_Fields>;
  min?: Maybe<Acl_Features_Permissions_Min_Fields>;
  stddev?: Maybe<Acl_Features_Permissions_Stddev_Fields>;
  stddev_pop?: Maybe<Acl_Features_Permissions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Acl_Features_Permissions_Stddev_Samp_Fields>;
  sum?: Maybe<Acl_Features_Permissions_Sum_Fields>;
  var_pop?: Maybe<Acl_Features_Permissions_Var_Pop_Fields>;
  var_samp?: Maybe<Acl_Features_Permissions_Var_Samp_Fields>;
  variance?: Maybe<Acl_Features_Permissions_Variance_Fields>;
};


/** aggregate fields of "acl_features_permissions" */
export type Acl_Features_Permissions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Acl_Features_Permissions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "acl_features_permissions" */
export type Acl_Features_Permissions_Aggregate_Order_By = {
  avg?: Maybe<Acl_Features_Permissions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Acl_Features_Permissions_Max_Order_By>;
  min?: Maybe<Acl_Features_Permissions_Min_Order_By>;
  stddev?: Maybe<Acl_Features_Permissions_Stddev_Order_By>;
  stddev_pop?: Maybe<Acl_Features_Permissions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Acl_Features_Permissions_Stddev_Samp_Order_By>;
  sum?: Maybe<Acl_Features_Permissions_Sum_Order_By>;
  var_pop?: Maybe<Acl_Features_Permissions_Var_Pop_Order_By>;
  var_samp?: Maybe<Acl_Features_Permissions_Var_Samp_Order_By>;
  variance?: Maybe<Acl_Features_Permissions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "acl_features_permissions" */
export type Acl_Features_Permissions_Arr_Rel_Insert_Input = {
  data: Array<Acl_Features_Permissions_Insert_Input>;
  on_conflict?: Maybe<Acl_Features_Permissions_On_Conflict>;
};

/** aggregate avg on columns */
export type Acl_Features_Permissions_Avg_Fields = {
  __typename?: 'acl_features_permissions_avg_fields';
  acl_features_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "acl_features_permissions" */
export type Acl_Features_Permissions_Avg_Order_By = {
  acl_features_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "acl_features_permissions". All fields are combined with a logical 'AND'. */
export type Acl_Features_Permissions_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Acl_Features_Permissions_Bool_Exp>>>;
  _not?: Maybe<Acl_Features_Permissions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Acl_Features_Permissions_Bool_Exp>>>;
  acl_feature?: Maybe<Acl_Features_Bool_Exp>;
  acl_features_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  delete?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  read?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
  write?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "acl_features_permissions" */
export enum Acl_Features_Permissions_Constraint {
  /** unique or primary key constraint */
  AclFeaturesPermissionsAclFeaturesIdUserIdKey = 'acl_features_permissions_acl_features_id_user_id_key',
  /** unique or primary key constraint */
  AclFeaturesPermissionsPkey = 'acl_features_permissions_pkey'
}

/** input type for incrementing integer column in table "acl_features_permissions" */
export type Acl_Features_Permissions_Inc_Input = {
  acl_features_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "acl_features_permissions" */
export type Acl_Features_Permissions_Insert_Input = {
  acl_feature?: Maybe<Acl_Features_Obj_Rel_Insert_Input>;
  acl_features_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  delete?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  read?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
  write?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Acl_Features_Permissions_Max_Fields = {
  __typename?: 'acl_features_permissions_max_fields';
  acl_features_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "acl_features_permissions" */
export type Acl_Features_Permissions_Max_Order_By = {
  acl_features_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Acl_Features_Permissions_Min_Fields = {
  __typename?: 'acl_features_permissions_min_fields';
  acl_features_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "acl_features_permissions" */
export type Acl_Features_Permissions_Min_Order_By = {
  acl_features_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "acl_features_permissions" */
export type Acl_Features_Permissions_Mutation_Response = {
  __typename?: 'acl_features_permissions_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Acl_Features_Permissions>;
};

/** input type for inserting object relation for remote table "acl_features_permissions" */
export type Acl_Features_Permissions_Obj_Rel_Insert_Input = {
  data: Acl_Features_Permissions_Insert_Input;
  on_conflict?: Maybe<Acl_Features_Permissions_On_Conflict>;
};

/** on conflict condition type for table "acl_features_permissions" */
export type Acl_Features_Permissions_On_Conflict = {
  constraint: Acl_Features_Permissions_Constraint;
  update_columns: Array<Acl_Features_Permissions_Update_Column>;
  where?: Maybe<Acl_Features_Permissions_Bool_Exp>;
};

/** ordering options when selecting data from "acl_features_permissions" */
export type Acl_Features_Permissions_Order_By = {
  acl_feature?: Maybe<Acl_Features_Order_By>;
  acl_features_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  delete?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  read?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
  write?: Maybe<Order_By>;
};

/** primary key columns input for table: "acl_features_permissions" */
export type Acl_Features_Permissions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "acl_features_permissions" */
export enum Acl_Features_Permissions_Select_Column {
  /** column name */
  AclFeaturesId = 'acl_features_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Delete = 'delete',
  /** column name */
  Id = 'id',
  /** column name */
  Read = 'read',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Write = 'write'
}

/** input type for updating data in table "acl_features_permissions" */
export type Acl_Features_Permissions_Set_Input = {
  acl_features_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  delete?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  read?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  write?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Acl_Features_Permissions_Stddev_Fields = {
  __typename?: 'acl_features_permissions_stddev_fields';
  acl_features_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "acl_features_permissions" */
export type Acl_Features_Permissions_Stddev_Order_By = {
  acl_features_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Acl_Features_Permissions_Stddev_Pop_Fields = {
  __typename?: 'acl_features_permissions_stddev_pop_fields';
  acl_features_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "acl_features_permissions" */
export type Acl_Features_Permissions_Stddev_Pop_Order_By = {
  acl_features_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Acl_Features_Permissions_Stddev_Samp_Fields = {
  __typename?: 'acl_features_permissions_stddev_samp_fields';
  acl_features_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "acl_features_permissions" */
export type Acl_Features_Permissions_Stddev_Samp_Order_By = {
  acl_features_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Acl_Features_Permissions_Sum_Fields = {
  __typename?: 'acl_features_permissions_sum_fields';
  acl_features_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "acl_features_permissions" */
export type Acl_Features_Permissions_Sum_Order_By = {
  acl_features_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "acl_features_permissions" */
export enum Acl_Features_Permissions_Update_Column {
  /** column name */
  AclFeaturesId = 'acl_features_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Delete = 'delete',
  /** column name */
  Id = 'id',
  /** column name */
  Read = 'read',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Write = 'write'
}

/** aggregate var_pop on columns */
export type Acl_Features_Permissions_Var_Pop_Fields = {
  __typename?: 'acl_features_permissions_var_pop_fields';
  acl_features_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "acl_features_permissions" */
export type Acl_Features_Permissions_Var_Pop_Order_By = {
  acl_features_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Acl_Features_Permissions_Var_Samp_Fields = {
  __typename?: 'acl_features_permissions_var_samp_fields';
  acl_features_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "acl_features_permissions" */
export type Acl_Features_Permissions_Var_Samp_Order_By = {
  acl_features_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Acl_Features_Permissions_Variance_Fields = {
  __typename?: 'acl_features_permissions_variance_fields';
  acl_features_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "acl_features_permissions" */
export type Acl_Features_Permissions_Variance_Order_By = {
  acl_features_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: "acl_features" */
export type Acl_Features_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "acl_features" */
export enum Acl_Features_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Desktop = 'desktop',
  /** column name */
  Divider = 'divider',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  IsSubmenu = 'is_submenu',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Routing = 'routing',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "acl_features" */
export type Acl_Features_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  desktop?: Maybe<Scalars['Boolean']>;
  divider?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_submenu?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  routing?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Acl_Features_Stddev_Fields = {
  __typename?: 'acl_features_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "acl_features" */
export type Acl_Features_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Acl_Features_Stddev_Pop_Fields = {
  __typename?: 'acl_features_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "acl_features" */
export type Acl_Features_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Acl_Features_Stddev_Samp_Fields = {
  __typename?: 'acl_features_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "acl_features" */
export type Acl_Features_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Acl_Features_Sum_Fields = {
  __typename?: 'acl_features_sum_fields';
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "acl_features" */
export type Acl_Features_Sum_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** update columns of table "acl_features" */
export enum Acl_Features_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Desktop = 'desktop',
  /** column name */
  Divider = 'divider',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  IsSubmenu = 'is_submenu',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Routing = 'routing',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Acl_Features_Var_Pop_Fields = {
  __typename?: 'acl_features_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "acl_features" */
export type Acl_Features_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Acl_Features_Var_Samp_Fields = {
  __typename?: 'acl_features_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "acl_features" */
export type Acl_Features_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Acl_Features_Variance_Fields = {
  __typename?: 'acl_features_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "acl_features" */
export type Acl_Features_Variance_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** columns and relationships of "apikeys" */
export type Apikeys = {
  __typename?: 'apikeys';
  active: Scalars['Boolean'];
  api_secret: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  organization: Array<Organizations>;
  /** An aggregated array relationship */
  organization_aggregate: Organizations_Aggregate;
  organization_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "apikeys" */
export type ApikeysOrganizationArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};


/** columns and relationships of "apikeys" */
export type ApikeysOrganization_AggregateArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};

/** aggregated selection of "apikeys" */
export type Apikeys_Aggregate = {
  __typename?: 'apikeys_aggregate';
  aggregate?: Maybe<Apikeys_Aggregate_Fields>;
  nodes: Array<Apikeys>;
};

/** aggregate fields of "apikeys" */
export type Apikeys_Aggregate_Fields = {
  __typename?: 'apikeys_aggregate_fields';
  avg?: Maybe<Apikeys_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Apikeys_Max_Fields>;
  min?: Maybe<Apikeys_Min_Fields>;
  stddev?: Maybe<Apikeys_Stddev_Fields>;
  stddev_pop?: Maybe<Apikeys_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apikeys_Stddev_Samp_Fields>;
  sum?: Maybe<Apikeys_Sum_Fields>;
  var_pop?: Maybe<Apikeys_Var_Pop_Fields>;
  var_samp?: Maybe<Apikeys_Var_Samp_Fields>;
  variance?: Maybe<Apikeys_Variance_Fields>;
};


/** aggregate fields of "apikeys" */
export type Apikeys_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Apikeys_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "apikeys" */
export type Apikeys_Aggregate_Order_By = {
  avg?: Maybe<Apikeys_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Apikeys_Max_Order_By>;
  min?: Maybe<Apikeys_Min_Order_By>;
  stddev?: Maybe<Apikeys_Stddev_Order_By>;
  stddev_pop?: Maybe<Apikeys_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Apikeys_Stddev_Samp_Order_By>;
  sum?: Maybe<Apikeys_Sum_Order_By>;
  var_pop?: Maybe<Apikeys_Var_Pop_Order_By>;
  var_samp?: Maybe<Apikeys_Var_Samp_Order_By>;
  variance?: Maybe<Apikeys_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "apikeys" */
export type Apikeys_Arr_Rel_Insert_Input = {
  data: Array<Apikeys_Insert_Input>;
  on_conflict?: Maybe<Apikeys_On_Conflict>;
};

/** aggregate avg on columns */
export type Apikeys_Avg_Fields = {
  __typename?: 'apikeys_avg_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "apikeys" */
export type Apikeys_Avg_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "apikeys". All fields are combined with a logical 'AND'. */
export type Apikeys_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Apikeys_Bool_Exp>>>;
  _not?: Maybe<Apikeys_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Apikeys_Bool_Exp>>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  api_secret?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  organization?: Maybe<Organizations_Bool_Exp>;
  organization_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "apikeys" */
export enum Apikeys_Constraint {
  /** unique or primary key constraint */
  ApikeysPkey = 'apikeys_pkey'
}

/** input type for incrementing integer column in table "apikeys" */
export type Apikeys_Inc_Input = {
  organization_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "apikeys" */
export type Apikeys_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  api_secret?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization?: Maybe<Organizations_Arr_Rel_Insert_Input>;
  organization_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Apikeys_Max_Fields = {
  __typename?: 'apikeys_max_fields';
  api_secret?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "apikeys" */
export type Apikeys_Max_Order_By = {
  api_secret?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Apikeys_Min_Fields = {
  __typename?: 'apikeys_min_fields';
  api_secret?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "apikeys" */
export type Apikeys_Min_Order_By = {
  api_secret?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "apikeys" */
export type Apikeys_Mutation_Response = {
  __typename?: 'apikeys_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Apikeys>;
};

/** input type for inserting object relation for remote table "apikeys" */
export type Apikeys_Obj_Rel_Insert_Input = {
  data: Apikeys_Insert_Input;
  on_conflict?: Maybe<Apikeys_On_Conflict>;
};

/** on conflict condition type for table "apikeys" */
export type Apikeys_On_Conflict = {
  constraint: Apikeys_Constraint;
  update_columns: Array<Apikeys_Update_Column>;
  where?: Maybe<Apikeys_Bool_Exp>;
};

/** ordering options when selecting data from "apikeys" */
export type Apikeys_Order_By = {
  active?: Maybe<Order_By>;
  api_secret?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_aggregate?: Maybe<Organizations_Aggregate_Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "apikeys" */
export type Apikeys_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "apikeys" */
export enum Apikeys_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ApiSecret = 'api_secret',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "apikeys" */
export type Apikeys_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  api_secret?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Apikeys_Stddev_Fields = {
  __typename?: 'apikeys_stddev_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "apikeys" */
export type Apikeys_Stddev_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Apikeys_Stddev_Pop_Fields = {
  __typename?: 'apikeys_stddev_pop_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "apikeys" */
export type Apikeys_Stddev_Pop_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Apikeys_Stddev_Samp_Fields = {
  __typename?: 'apikeys_stddev_samp_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "apikeys" */
export type Apikeys_Stddev_Samp_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Apikeys_Sum_Fields = {
  __typename?: 'apikeys_sum_fields';
  organization_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "apikeys" */
export type Apikeys_Sum_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** update columns of table "apikeys" */
export enum Apikeys_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ApiSecret = 'api_secret',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Apikeys_Var_Pop_Fields = {
  __typename?: 'apikeys_var_pop_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "apikeys" */
export type Apikeys_Var_Pop_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Apikeys_Var_Samp_Fields = {
  __typename?: 'apikeys_var_samp_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "apikeys" */
export type Apikeys_Var_Samp_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Apikeys_Variance_Fields = {
  __typename?: 'apikeys_variance_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "apikeys" */
export type Apikeys_Variance_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** columns and relationships of "auth.account_providers" */
export type Auth_Account_Providers = {
  __typename?: 'auth_account_providers';
  /** An object relationship */
  account: Auth_Accounts;
  account_id: Scalars['uuid'];
  auth_provider: Scalars['String'];
  auth_provider_unique_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  provider: Auth_Providers;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "auth.account_providers" */
export type Auth_Account_Providers_Aggregate = {
  __typename?: 'auth_account_providers_aggregate';
  aggregate?: Maybe<Auth_Account_Providers_Aggregate_Fields>;
  nodes: Array<Auth_Account_Providers>;
};

/** aggregate fields of "auth.account_providers" */
export type Auth_Account_Providers_Aggregate_Fields = {
  __typename?: 'auth_account_providers_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Auth_Account_Providers_Max_Fields>;
  min?: Maybe<Auth_Account_Providers_Min_Fields>;
};


/** aggregate fields of "auth.account_providers" */
export type Auth_Account_Providers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.account_providers" */
export type Auth_Account_Providers_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Auth_Account_Providers_Max_Order_By>;
  min?: Maybe<Auth_Account_Providers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.account_providers" */
export type Auth_Account_Providers_Arr_Rel_Insert_Input = {
  data: Array<Auth_Account_Providers_Insert_Input>;
  on_conflict?: Maybe<Auth_Account_Providers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.account_providers". All fields are combined with a logical 'AND'. */
export type Auth_Account_Providers_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Auth_Account_Providers_Bool_Exp>>>;
  _not?: Maybe<Auth_Account_Providers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Auth_Account_Providers_Bool_Exp>>>;
  account?: Maybe<Auth_Accounts_Bool_Exp>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  auth_provider?: Maybe<String_Comparison_Exp>;
  auth_provider_unique_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  provider?: Maybe<Auth_Providers_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.account_providers" */
export enum Auth_Account_Providers_Constraint {
  /** unique or primary key constraint */
  AccountProvidersAccountIdAuthProviderKey = 'account_providers_account_id_auth_provider_key',
  /** unique or primary key constraint */
  AccountProvidersAuthProviderAuthProviderUniqueIdKey = 'account_providers_auth_provider_auth_provider_unique_id_key',
  /** unique or primary key constraint */
  AccountProvidersPkey = 'account_providers_pkey'
}

/** input type for inserting data into table "auth.account_providers" */
export type Auth_Account_Providers_Insert_Input = {
  account?: Maybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  provider?: Maybe<Auth_Providers_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Auth_Account_Providers_Max_Fields = {
  __typename?: 'auth_account_providers_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "auth.account_providers" */
export type Auth_Account_Providers_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  auth_provider?: Maybe<Order_By>;
  auth_provider_unique_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Account_Providers_Min_Fields = {
  __typename?: 'auth_account_providers_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "auth.account_providers" */
export type Auth_Account_Providers_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  auth_provider?: Maybe<Order_By>;
  auth_provider_unique_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "auth.account_providers" */
export type Auth_Account_Providers_Mutation_Response = {
  __typename?: 'auth_account_providers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Auth_Account_Providers>;
};

/** input type for inserting object relation for remote table "auth.account_providers" */
export type Auth_Account_Providers_Obj_Rel_Insert_Input = {
  data: Auth_Account_Providers_Insert_Input;
  on_conflict?: Maybe<Auth_Account_Providers_On_Conflict>;
};

/** on conflict condition type for table "auth.account_providers" */
export type Auth_Account_Providers_On_Conflict = {
  constraint: Auth_Account_Providers_Constraint;
  update_columns: Array<Auth_Account_Providers_Update_Column>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};

/** ordering options when selecting data from "auth.account_providers" */
export type Auth_Account_Providers_Order_By = {
  account?: Maybe<Auth_Accounts_Order_By>;
  account_id?: Maybe<Order_By>;
  auth_provider?: Maybe<Order_By>;
  auth_provider_unique_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  provider?: Maybe<Auth_Providers_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "auth.account_providers" */
export type Auth_Account_Providers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.account_providers" */
export enum Auth_Account_Providers_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AuthProvider = 'auth_provider',
  /** column name */
  AuthProviderUniqueId = 'auth_provider_unique_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "auth.account_providers" */
export type Auth_Account_Providers_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "auth.account_providers" */
export enum Auth_Account_Providers_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AuthProvider = 'auth_provider',
  /** column name */
  AuthProviderUniqueId = 'auth_provider_unique_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "auth.account_roles" */
export type Auth_Account_Roles = {
  __typename?: 'auth_account_roles';
  /** An object relationship */
  account: Auth_Accounts;
  account_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  role: Scalars['String'];
  /** An object relationship */
  roleByRole: Auth_Roles;
};

/** aggregated selection of "auth.account_roles" */
export type Auth_Account_Roles_Aggregate = {
  __typename?: 'auth_account_roles_aggregate';
  aggregate?: Maybe<Auth_Account_Roles_Aggregate_Fields>;
  nodes: Array<Auth_Account_Roles>;
};

/** aggregate fields of "auth.account_roles" */
export type Auth_Account_Roles_Aggregate_Fields = {
  __typename?: 'auth_account_roles_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Auth_Account_Roles_Max_Fields>;
  min?: Maybe<Auth_Account_Roles_Min_Fields>;
};


/** aggregate fields of "auth.account_roles" */
export type Auth_Account_Roles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.account_roles" */
export type Auth_Account_Roles_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Auth_Account_Roles_Max_Order_By>;
  min?: Maybe<Auth_Account_Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.account_roles" */
export type Auth_Account_Roles_Arr_Rel_Insert_Input = {
  data: Array<Auth_Account_Roles_Insert_Input>;
  on_conflict?: Maybe<Auth_Account_Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.account_roles". All fields are combined with a logical 'AND'. */
export type Auth_Account_Roles_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Auth_Account_Roles_Bool_Exp>>>;
  _not?: Maybe<Auth_Account_Roles_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Auth_Account_Roles_Bool_Exp>>>;
  account?: Maybe<Auth_Accounts_Bool_Exp>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  role?: Maybe<String_Comparison_Exp>;
  roleByRole?: Maybe<Auth_Roles_Bool_Exp>;
};

/** unique or primary key constraints on table "auth.account_roles" */
export enum Auth_Account_Roles_Constraint {
  /** unique or primary key constraint */
  AccountRolesPkey = 'account_roles_pkey',
  /** unique or primary key constraint */
  UserRolesAccountIdRoleKey = 'user_roles_account_id_role_key'
}

/** input type for inserting data into table "auth.account_roles" */
export type Auth_Account_Roles_Insert_Input = {
  account?: Maybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  roleByRole?: Maybe<Auth_Roles_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Auth_Account_Roles_Max_Fields = {
  __typename?: 'auth_account_roles_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "auth.account_roles" */
export type Auth_Account_Roles_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Account_Roles_Min_Fields = {
  __typename?: 'auth_account_roles_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "auth.account_roles" */
export type Auth_Account_Roles_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
};

/** response of any mutation on the table "auth.account_roles" */
export type Auth_Account_Roles_Mutation_Response = {
  __typename?: 'auth_account_roles_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Auth_Account_Roles>;
};

/** input type for inserting object relation for remote table "auth.account_roles" */
export type Auth_Account_Roles_Obj_Rel_Insert_Input = {
  data: Auth_Account_Roles_Insert_Input;
  on_conflict?: Maybe<Auth_Account_Roles_On_Conflict>;
};

/** on conflict condition type for table "auth.account_roles" */
export type Auth_Account_Roles_On_Conflict = {
  constraint: Auth_Account_Roles_Constraint;
  update_columns: Array<Auth_Account_Roles_Update_Column>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};

/** ordering options when selecting data from "auth.account_roles" */
export type Auth_Account_Roles_Order_By = {
  account?: Maybe<Auth_Accounts_Order_By>;
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  roleByRole?: Maybe<Auth_Roles_Order_By>;
};

/** primary key columns input for table: "auth.account_roles" */
export type Auth_Account_Roles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.account_roles" */
export enum Auth_Account_Roles_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "auth.account_roles" */
export type Auth_Account_Roles_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
};

/** update columns of table "auth.account_roles" */
export enum Auth_Account_Roles_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role'
}

/** columns and relationships of "auth.accounts" */
export type Auth_Accounts = {
  __typename?: 'auth_accounts';
  /** An array relationship */
  account_providers: Array<Auth_Account_Providers>;
  /** An aggregated array relationship */
  account_providers_aggregate: Auth_Account_Providers_Aggregate;
  /** An array relationship */
  account_roles: Array<Auth_Account_Roles>;
  /** An aggregated array relationship */
  account_roles_aggregate: Auth_Account_Roles_Aggregate;
  active: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  custom_register_data?: Maybe<Scalars['jsonb']>;
  default_role: Scalars['String'];
  email?: Maybe<Scalars['citext']>;
  id: Scalars['uuid'];
  is_anonymous: Scalars['Boolean'];
  mfa_enabled: Scalars['Boolean'];
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  /** An array relationship */
  refresh_tokens: Array<Auth_Refresh_Tokens>;
  /** An aggregated array relationship */
  refresh_tokens_aggregate: Auth_Refresh_Tokens_Aggregate;
  /** An object relationship */
  role: Auth_Roles;
  ticket: Scalars['uuid'];
  ticket_expires_at: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsAccount_ProvidersArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsAccount_Providers_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsAccount_RolesArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsAccount_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsCustom_Register_DataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsRefresh_TokensArgs = {
  distinct_on?: Maybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsRefresh_Tokens_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
};

/** aggregated selection of "auth.accounts" */
export type Auth_Accounts_Aggregate = {
  __typename?: 'auth_accounts_aggregate';
  aggregate?: Maybe<Auth_Accounts_Aggregate_Fields>;
  nodes: Array<Auth_Accounts>;
};

/** aggregate fields of "auth.accounts" */
export type Auth_Accounts_Aggregate_Fields = {
  __typename?: 'auth_accounts_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Auth_Accounts_Max_Fields>;
  min?: Maybe<Auth_Accounts_Min_Fields>;
};


/** aggregate fields of "auth.accounts" */
export type Auth_Accounts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Accounts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.accounts" */
export type Auth_Accounts_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Auth_Accounts_Max_Order_By>;
  min?: Maybe<Auth_Accounts_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Auth_Accounts_Append_Input = {
  custom_register_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "auth.accounts" */
export type Auth_Accounts_Arr_Rel_Insert_Input = {
  data: Array<Auth_Accounts_Insert_Input>;
  on_conflict?: Maybe<Auth_Accounts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.accounts". All fields are combined with a logical 'AND'. */
export type Auth_Accounts_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Auth_Accounts_Bool_Exp>>>;
  _not?: Maybe<Auth_Accounts_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Auth_Accounts_Bool_Exp>>>;
  account_providers?: Maybe<Auth_Account_Providers_Bool_Exp>;
  account_roles?: Maybe<Auth_Account_Roles_Bool_Exp>;
  active?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  custom_register_data?: Maybe<Jsonb_Comparison_Exp>;
  default_role?: Maybe<String_Comparison_Exp>;
  email?: Maybe<Citext_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_anonymous?: Maybe<Boolean_Comparison_Exp>;
  mfa_enabled?: Maybe<Boolean_Comparison_Exp>;
  new_email?: Maybe<Citext_Comparison_Exp>;
  otp_secret?: Maybe<String_Comparison_Exp>;
  password_hash?: Maybe<String_Comparison_Exp>;
  refresh_tokens?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
  role?: Maybe<Auth_Roles_Bool_Exp>;
  ticket?: Maybe<Uuid_Comparison_Exp>;
  ticket_expires_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.accounts" */
export enum Auth_Accounts_Constraint {
  /** unique or primary key constraint */
  AccountsEmailKey = 'accounts_email_key',
  /** unique or primary key constraint */
  AccountsNewEmailKey = 'accounts_new_email_key',
  /** unique or primary key constraint */
  AccountsPkey = 'accounts_pkey',
  /** unique or primary key constraint */
  AccountsUserIdKey = 'accounts_user_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Auth_Accounts_Delete_At_Path_Input = {
  custom_register_data?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Auth_Accounts_Delete_Elem_Input = {
  custom_register_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Auth_Accounts_Delete_Key_Input = {
  custom_register_data?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "auth.accounts" */
export type Auth_Accounts_Insert_Input = {
  account_providers?: Maybe<Auth_Account_Providers_Arr_Rel_Insert_Input>;
  account_roles?: Maybe<Auth_Account_Roles_Arr_Rel_Insert_Input>;
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_register_data?: Maybe<Scalars['jsonb']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  is_anonymous?: Maybe<Scalars['Boolean']>;
  mfa_enabled?: Maybe<Scalars['Boolean']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  refresh_tokens?: Maybe<Auth_Refresh_Tokens_Arr_Rel_Insert_Input>;
  role?: Maybe<Auth_Roles_Obj_Rel_Insert_Input>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Auth_Accounts_Max_Fields = {
  __typename?: 'auth_accounts_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "auth.accounts" */
export type Auth_Accounts_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  default_role?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  new_email?: Maybe<Order_By>;
  otp_secret?: Maybe<Order_By>;
  password_hash?: Maybe<Order_By>;
  ticket?: Maybe<Order_By>;
  ticket_expires_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Accounts_Min_Fields = {
  __typename?: 'auth_accounts_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "auth.accounts" */
export type Auth_Accounts_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  default_role?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  new_email?: Maybe<Order_By>;
  otp_secret?: Maybe<Order_By>;
  password_hash?: Maybe<Order_By>;
  ticket?: Maybe<Order_By>;
  ticket_expires_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "auth.accounts" */
export type Auth_Accounts_Mutation_Response = {
  __typename?: 'auth_accounts_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Auth_Accounts>;
};

/** input type for inserting object relation for remote table "auth.accounts" */
export type Auth_Accounts_Obj_Rel_Insert_Input = {
  data: Auth_Accounts_Insert_Input;
  on_conflict?: Maybe<Auth_Accounts_On_Conflict>;
};

/** on conflict condition type for table "auth.accounts" */
export type Auth_Accounts_On_Conflict = {
  constraint: Auth_Accounts_Constraint;
  update_columns: Array<Auth_Accounts_Update_Column>;
  where?: Maybe<Auth_Accounts_Bool_Exp>;
};

/** ordering options when selecting data from "auth.accounts" */
export type Auth_Accounts_Order_By = {
  account_providers_aggregate?: Maybe<Auth_Account_Providers_Aggregate_Order_By>;
  account_roles_aggregate?: Maybe<Auth_Account_Roles_Aggregate_Order_By>;
  active?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  custom_register_data?: Maybe<Order_By>;
  default_role?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_anonymous?: Maybe<Order_By>;
  mfa_enabled?: Maybe<Order_By>;
  new_email?: Maybe<Order_By>;
  otp_secret?: Maybe<Order_By>;
  password_hash?: Maybe<Order_By>;
  refresh_tokens_aggregate?: Maybe<Auth_Refresh_Tokens_Aggregate_Order_By>;
  role?: Maybe<Auth_Roles_Order_By>;
  ticket?: Maybe<Order_By>;
  ticket_expires_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "auth.accounts" */
export type Auth_Accounts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Auth_Accounts_Prepend_Input = {
  custom_register_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "auth.accounts" */
export enum Auth_Accounts_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomRegisterData = 'custom_register_data',
  /** column name */
  DefaultRole = 'default_role',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymous = 'is_anonymous',
  /** column name */
  MfaEnabled = 'mfa_enabled',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  OtpSecret = 'otp_secret',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticket_expires_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "auth.accounts" */
export type Auth_Accounts_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_register_data?: Maybe<Scalars['jsonb']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  is_anonymous?: Maybe<Scalars['Boolean']>;
  mfa_enabled?: Maybe<Scalars['Boolean']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "auth.accounts" */
export enum Auth_Accounts_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomRegisterData = 'custom_register_data',
  /** column name */
  DefaultRole = 'default_role',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymous = 'is_anonymous',
  /** column name */
  MfaEnabled = 'mfa_enabled',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  OtpSecret = 'otp_secret',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticket_expires_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "auth.providers" */
export type Auth_Providers = {
  __typename?: 'auth_providers';
  /** An array relationship */
  account_providers: Array<Auth_Account_Providers>;
  /** An aggregated array relationship */
  account_providers_aggregate: Auth_Account_Providers_Aggregate;
  provider: Scalars['String'];
};


/** columns and relationships of "auth.providers" */
export type Auth_ProvidersAccount_ProvidersArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};


/** columns and relationships of "auth.providers" */
export type Auth_ProvidersAccount_Providers_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};

/** aggregated selection of "auth.providers" */
export type Auth_Providers_Aggregate = {
  __typename?: 'auth_providers_aggregate';
  aggregate?: Maybe<Auth_Providers_Aggregate_Fields>;
  nodes: Array<Auth_Providers>;
};

/** aggregate fields of "auth.providers" */
export type Auth_Providers_Aggregate_Fields = {
  __typename?: 'auth_providers_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Auth_Providers_Max_Fields>;
  min?: Maybe<Auth_Providers_Min_Fields>;
};


/** aggregate fields of "auth.providers" */
export type Auth_Providers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Providers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.providers" */
export type Auth_Providers_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Auth_Providers_Max_Order_By>;
  min?: Maybe<Auth_Providers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.providers" */
export type Auth_Providers_Arr_Rel_Insert_Input = {
  data: Array<Auth_Providers_Insert_Input>;
  on_conflict?: Maybe<Auth_Providers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.providers". All fields are combined with a logical 'AND'. */
export type Auth_Providers_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Auth_Providers_Bool_Exp>>>;
  _not?: Maybe<Auth_Providers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Auth_Providers_Bool_Exp>>>;
  account_providers?: Maybe<Auth_Account_Providers_Bool_Exp>;
  provider?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.providers" */
export enum Auth_Providers_Constraint {
  /** unique or primary key constraint */
  ProvidersPkey = 'providers_pkey'
}

/** input type for inserting data into table "auth.providers" */
export type Auth_Providers_Insert_Input = {
  account_providers?: Maybe<Auth_Account_Providers_Arr_Rel_Insert_Input>;
  provider?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Auth_Providers_Max_Fields = {
  __typename?: 'auth_providers_max_fields';
  provider?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "auth.providers" */
export type Auth_Providers_Max_Order_By = {
  provider?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Providers_Min_Fields = {
  __typename?: 'auth_providers_min_fields';
  provider?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "auth.providers" */
export type Auth_Providers_Min_Order_By = {
  provider?: Maybe<Order_By>;
};

/** response of any mutation on the table "auth.providers" */
export type Auth_Providers_Mutation_Response = {
  __typename?: 'auth_providers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Auth_Providers>;
};

/** input type for inserting object relation for remote table "auth.providers" */
export type Auth_Providers_Obj_Rel_Insert_Input = {
  data: Auth_Providers_Insert_Input;
  on_conflict?: Maybe<Auth_Providers_On_Conflict>;
};

/** on conflict condition type for table "auth.providers" */
export type Auth_Providers_On_Conflict = {
  constraint: Auth_Providers_Constraint;
  update_columns: Array<Auth_Providers_Update_Column>;
  where?: Maybe<Auth_Providers_Bool_Exp>;
};

/** ordering options when selecting data from "auth.providers" */
export type Auth_Providers_Order_By = {
  account_providers_aggregate?: Maybe<Auth_Account_Providers_Aggregate_Order_By>;
  provider?: Maybe<Order_By>;
};

/** primary key columns input for table: "auth.providers" */
export type Auth_Providers_Pk_Columns_Input = {
  provider: Scalars['String'];
};

/** select columns of table "auth.providers" */
export enum Auth_Providers_Select_Column {
  /** column name */
  Provider = 'provider'
}

/** input type for updating data in table "auth.providers" */
export type Auth_Providers_Set_Input = {
  provider?: Maybe<Scalars['String']>;
};

/** update columns of table "auth.providers" */
export enum Auth_Providers_Update_Column {
  /** column name */
  Provider = 'provider'
}

/** columns and relationships of "auth.refresh_tokens" */
export type Auth_Refresh_Tokens = {
  __typename?: 'auth_refresh_tokens';
  /** An object relationship */
  account: Auth_Accounts;
  account_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  expires_at: Scalars['timestamptz'];
  refresh_token: Scalars['uuid'];
};

/** aggregated selection of "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate = {
  __typename?: 'auth_refresh_tokens_aggregate';
  aggregate?: Maybe<Auth_Refresh_Tokens_Aggregate_Fields>;
  nodes: Array<Auth_Refresh_Tokens>;
};

/** aggregate fields of "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate_Fields = {
  __typename?: 'auth_refresh_tokens_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Auth_Refresh_Tokens_Max_Fields>;
  min?: Maybe<Auth_Refresh_Tokens_Min_Fields>;
};


/** aggregate fields of "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Auth_Refresh_Tokens_Max_Order_By>;
  min?: Maybe<Auth_Refresh_Tokens_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Arr_Rel_Insert_Input = {
  data: Array<Auth_Refresh_Tokens_Insert_Input>;
  on_conflict?: Maybe<Auth_Refresh_Tokens_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.refresh_tokens". All fields are combined with a logical 'AND'. */
export type Auth_Refresh_Tokens_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Auth_Refresh_Tokens_Bool_Exp>>>;
  _not?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Auth_Refresh_Tokens_Bool_Exp>>>;
  account?: Maybe<Auth_Accounts_Bool_Exp>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  expires_at?: Maybe<Timestamptz_Comparison_Exp>;
  refresh_token?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.refresh_tokens" */
export enum Auth_Refresh_Tokens_Constraint {
  /** unique or primary key constraint */
  RefreshTokensPkey = 'refresh_tokens_pkey'
}

/** input type for inserting data into table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Insert_Input = {
  account?: Maybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Auth_Refresh_Tokens_Max_Fields = {
  __typename?: 'auth_refresh_tokens_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  refresh_token?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Refresh_Tokens_Min_Fields = {
  __typename?: 'auth_refresh_tokens_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  refresh_token?: Maybe<Order_By>;
};

/** response of any mutation on the table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Mutation_Response = {
  __typename?: 'auth_refresh_tokens_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Auth_Refresh_Tokens>;
};

/** input type for inserting object relation for remote table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Obj_Rel_Insert_Input = {
  data: Auth_Refresh_Tokens_Insert_Input;
  on_conflict?: Maybe<Auth_Refresh_Tokens_On_Conflict>;
};

/** on conflict condition type for table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_On_Conflict = {
  constraint: Auth_Refresh_Tokens_Constraint;
  update_columns: Array<Auth_Refresh_Tokens_Update_Column>;
  where?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
};

/** ordering options when selecting data from "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Order_By = {
  account?: Maybe<Auth_Accounts_Order_By>;
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  refresh_token?: Maybe<Order_By>;
};

/** primary key columns input for table: "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Pk_Columns_Input = {
  refresh_token: Scalars['uuid'];
};

/** select columns of table "auth.refresh_tokens" */
export enum Auth_Refresh_Tokens_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  RefreshToken = 'refresh_token'
}

/** input type for updating data in table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** update columns of table "auth.refresh_tokens" */
export enum Auth_Refresh_Tokens_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  RefreshToken = 'refresh_token'
}

/** columns and relationships of "auth.roles" */
export type Auth_Roles = {
  __typename?: 'auth_roles';
  /** An array relationship */
  account_roles: Array<Auth_Account_Roles>;
  /** An aggregated array relationship */
  account_roles_aggregate: Auth_Account_Roles_Aggregate;
  /** An array relationship */
  accounts: Array<Auth_Accounts>;
  /** An aggregated array relationship */
  accounts_aggregate: Auth_Accounts_Aggregate;
  description?: Maybe<Scalars['String']>;
  role: Scalars['String'];
};


/** columns and relationships of "auth.roles" */
export type Auth_RolesAccount_RolesArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "auth.roles" */
export type Auth_RolesAccount_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "auth.roles" */
export type Auth_RolesAccountsArgs = {
  distinct_on?: Maybe<Array<Auth_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Accounts_Order_By>>;
  where?: Maybe<Auth_Accounts_Bool_Exp>;
};


/** columns and relationships of "auth.roles" */
export type Auth_RolesAccounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Accounts_Order_By>>;
  where?: Maybe<Auth_Accounts_Bool_Exp>;
};

/** aggregated selection of "auth.roles" */
export type Auth_Roles_Aggregate = {
  __typename?: 'auth_roles_aggregate';
  aggregate?: Maybe<Auth_Roles_Aggregate_Fields>;
  nodes: Array<Auth_Roles>;
};

/** aggregate fields of "auth.roles" */
export type Auth_Roles_Aggregate_Fields = {
  __typename?: 'auth_roles_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Auth_Roles_Max_Fields>;
  min?: Maybe<Auth_Roles_Min_Fields>;
};


/** aggregate fields of "auth.roles" */
export type Auth_Roles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Roles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.roles" */
export type Auth_Roles_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Auth_Roles_Max_Order_By>;
  min?: Maybe<Auth_Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.roles" */
export type Auth_Roles_Arr_Rel_Insert_Input = {
  data: Array<Auth_Roles_Insert_Input>;
  on_conflict?: Maybe<Auth_Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.roles". All fields are combined with a logical 'AND'. */
export type Auth_Roles_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Auth_Roles_Bool_Exp>>>;
  _not?: Maybe<Auth_Roles_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Auth_Roles_Bool_Exp>>>;
  account_roles?: Maybe<Auth_Account_Roles_Bool_Exp>;
  accounts?: Maybe<Auth_Accounts_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  role?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.roles" */
export enum Auth_Roles_Constraint {
  /** unique or primary key constraint */
  RolesPkey = 'roles_pkey'
}

/** input type for inserting data into table "auth.roles" */
export type Auth_Roles_Insert_Input = {
  account_roles?: Maybe<Auth_Account_Roles_Arr_Rel_Insert_Input>;
  accounts?: Maybe<Auth_Accounts_Arr_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Auth_Roles_Max_Fields = {
  __typename?: 'auth_roles_max_fields';
  description?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "auth.roles" */
export type Auth_Roles_Max_Order_By = {
  description?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Roles_Min_Fields = {
  __typename?: 'auth_roles_min_fields';
  description?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "auth.roles" */
export type Auth_Roles_Min_Order_By = {
  description?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
};

/** response of any mutation on the table "auth.roles" */
export type Auth_Roles_Mutation_Response = {
  __typename?: 'auth_roles_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Auth_Roles>;
};

/** input type for inserting object relation for remote table "auth.roles" */
export type Auth_Roles_Obj_Rel_Insert_Input = {
  data: Auth_Roles_Insert_Input;
  on_conflict?: Maybe<Auth_Roles_On_Conflict>;
};

/** on conflict condition type for table "auth.roles" */
export type Auth_Roles_On_Conflict = {
  constraint: Auth_Roles_Constraint;
  update_columns: Array<Auth_Roles_Update_Column>;
  where?: Maybe<Auth_Roles_Bool_Exp>;
};

/** ordering options when selecting data from "auth.roles" */
export type Auth_Roles_Order_By = {
  account_roles_aggregate?: Maybe<Auth_Account_Roles_Aggregate_Order_By>;
  accounts_aggregate?: Maybe<Auth_Accounts_Aggregate_Order_By>;
  description?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
};

/** primary key columns input for table: "auth.roles" */
export type Auth_Roles_Pk_Columns_Input = {
  role: Scalars['String'];
};

/** select columns of table "auth.roles" */
export enum Auth_Roles_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "auth.roles" */
export type Auth_Roles_Set_Input = {
  description?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

/** update columns of table "auth.roles" */
export enum Auth_Roles_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Role = 'role'
}


/** expression to compare columns of type bigint. All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "cidades" */
export type Cidades = {
  __typename?: 'cidades';
  /** An object relationship */
  estado?: Maybe<Estados>;
  estado_id: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** aggregated selection of "cidades" */
export type Cidades_Aggregate = {
  __typename?: 'cidades_aggregate';
  aggregate?: Maybe<Cidades_Aggregate_Fields>;
  nodes: Array<Cidades>;
};

/** aggregate fields of "cidades" */
export type Cidades_Aggregate_Fields = {
  __typename?: 'cidades_aggregate_fields';
  avg?: Maybe<Cidades_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Cidades_Max_Fields>;
  min?: Maybe<Cidades_Min_Fields>;
  stddev?: Maybe<Cidades_Stddev_Fields>;
  stddev_pop?: Maybe<Cidades_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cidades_Stddev_Samp_Fields>;
  sum?: Maybe<Cidades_Sum_Fields>;
  var_pop?: Maybe<Cidades_Var_Pop_Fields>;
  var_samp?: Maybe<Cidades_Var_Samp_Fields>;
  variance?: Maybe<Cidades_Variance_Fields>;
};


/** aggregate fields of "cidades" */
export type Cidades_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cidades_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cidades" */
export type Cidades_Aggregate_Order_By = {
  avg?: Maybe<Cidades_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Cidades_Max_Order_By>;
  min?: Maybe<Cidades_Min_Order_By>;
  stddev?: Maybe<Cidades_Stddev_Order_By>;
  stddev_pop?: Maybe<Cidades_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Cidades_Stddev_Samp_Order_By>;
  sum?: Maybe<Cidades_Sum_Order_By>;
  var_pop?: Maybe<Cidades_Var_Pop_Order_By>;
  var_samp?: Maybe<Cidades_Var_Samp_Order_By>;
  variance?: Maybe<Cidades_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "cidades" */
export type Cidades_Arr_Rel_Insert_Input = {
  data: Array<Cidades_Insert_Input>;
  on_conflict?: Maybe<Cidades_On_Conflict>;
};

/** aggregate avg on columns */
export type Cidades_Avg_Fields = {
  __typename?: 'cidades_avg_fields';
  estado_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cidades" */
export type Cidades_Avg_Order_By = {
  estado_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cidades". All fields are combined with a logical 'AND'. */
export type Cidades_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Cidades_Bool_Exp>>>;
  _not?: Maybe<Cidades_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Cidades_Bool_Exp>>>;
  estado?: Maybe<Estados_Bool_Exp>;
  estado_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "cidades" */
export enum Cidades_Constraint {
  /** unique or primary key constraint */
  CidadesPkey = 'cidades_pkey'
}

/** input type for incrementing integer column in table "cidades" */
export type Cidades_Inc_Input = {
  estado_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "cidades" */
export type Cidades_Insert_Input = {
  estado?: Maybe<Estados_Obj_Rel_Insert_Input>;
  estado_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Cidades_Max_Fields = {
  __typename?: 'cidades_max_fields';
  estado_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "cidades" */
export type Cidades_Max_Order_By = {
  estado_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Cidades_Min_Fields = {
  __typename?: 'cidades_min_fields';
  estado_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "cidades" */
export type Cidades_Min_Order_By = {
  estado_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "cidades" */
export type Cidades_Mutation_Response = {
  __typename?: 'cidades_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Cidades>;
};

/** input type for inserting object relation for remote table "cidades" */
export type Cidades_Obj_Rel_Insert_Input = {
  data: Cidades_Insert_Input;
  on_conflict?: Maybe<Cidades_On_Conflict>;
};

/** on conflict condition type for table "cidades" */
export type Cidades_On_Conflict = {
  constraint: Cidades_Constraint;
  update_columns: Array<Cidades_Update_Column>;
  where?: Maybe<Cidades_Bool_Exp>;
};

/** ordering options when selecting data from "cidades" */
export type Cidades_Order_By = {
  estado?: Maybe<Estados_Order_By>;
  estado_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "cidades" */
export type Cidades_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "cidades" */
export enum Cidades_Select_Column {
  /** column name */
  EstadoId = 'estado_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "cidades" */
export type Cidades_Set_Input = {
  estado_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Cidades_Stddev_Fields = {
  __typename?: 'cidades_stddev_fields';
  estado_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cidades" */
export type Cidades_Stddev_Order_By = {
  estado_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cidades_Stddev_Pop_Fields = {
  __typename?: 'cidades_stddev_pop_fields';
  estado_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cidades" */
export type Cidades_Stddev_Pop_Order_By = {
  estado_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cidades_Stddev_Samp_Fields = {
  __typename?: 'cidades_stddev_samp_fields';
  estado_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cidades" */
export type Cidades_Stddev_Samp_Order_By = {
  estado_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Cidades_Sum_Fields = {
  __typename?: 'cidades_sum_fields';
  estado_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "cidades" */
export type Cidades_Sum_Order_By = {
  estado_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "cidades" */
export enum Cidades_Update_Column {
  /** column name */
  EstadoId = 'estado_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Cidades_Var_Pop_Fields = {
  __typename?: 'cidades_var_pop_fields';
  estado_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cidades" */
export type Cidades_Var_Pop_Order_By = {
  estado_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cidades_Var_Samp_Fields = {
  __typename?: 'cidades_var_samp_fields';
  estado_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cidades" */
export type Cidades_Var_Samp_Order_By = {
  estado_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Cidades_Variance_Fields = {
  __typename?: 'cidades_variance_fields';
  estado_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cidades" */
export type Cidades_Variance_Order_By = {
  estado_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};


/** expression to compare columns of type citext. All fields are combined with logical 'AND'. */
export type Citext_Comparison_Exp = {
  _eq?: Maybe<Scalars['citext']>;
  _gt?: Maybe<Scalars['citext']>;
  _gte?: Maybe<Scalars['citext']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['citext']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['citext']>;
  _lte?: Maybe<Scalars['citext']>;
  _neq?: Maybe<Scalars['citext']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['citext']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

export type CreateUserInput = {
  data?: Maybe<UserInput>;
};

export type CreateUserPayload = {
  __typename?: 'createUserPayload';
  user?: Maybe<UsersPermissionsUser>;
};

export type DeleteCompanyPayload = {
  __typename?: 'deleteCompanyPayload';
  company?: Maybe<Company>;
};

export type DeleteFileInput = {
  where?: Maybe<InputId>;
};

export type DeleteFilePayload = {
  __typename?: 'deleteFilePayload';
  file?: Maybe<UploadFile>;
};

export type DeleteLoginPayload = {
  __typename?: 'deleteLoginPayload';
  login?: Maybe<Login>;
};

export type DeleteMenuPayload = {
  __typename?: 'deleteMenuPayload';
  menu?: Maybe<Menu>;
};

export type DeleteSidebarPayload = {
  __typename?: 'deleteSidebarPayload';
  sidebar?: Maybe<Sidebar>;
};

export type EditCompanyInput = {
  Works?: Maybe<EditComponentLpWorkInput>;
  banner?: Maybe<EditComponentLpBannerInput>;
  created_by?: Maybe<Scalars['ID']>;
  footer?: Maybe<EditComponentLpFooterInput>;
  header?: Maybe<EditComponentLpHeaderInput>;
  published_at?: Maybe<Scalars['DateTime']>;
  registro?: Maybe<EditComponentLpRegistroInput>;
  sidebar?: Maybe<EditComponentLpSidebarInput>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type EditComponentLpBannerInput = {
  button?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type EditComponentLpButtonHeaderInput = {
  Label?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
};

export type EditComponentLpFooterInput = {
  copy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  logo?: Maybe<Scalars['ID']>;
};

export type EditComponentLpHeaderInput = {
  Button?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type EditComponentLpLoginInput = {
  create_user?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  logo?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type EditComponentLpMenuInput = {
  Title?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
};

export type EditComponentLpRegistroInput = {
  Description?: Maybe<Scalars['String']>;
  button_send?: Maybe<Scalars['String']>;
  form_image?: Maybe<Scalars['ID']>;
  forms?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type EditComponentLpSidebarInput = {
  Logo?: Maybe<Scalars['ID']>;
  Menu?: Maybe<Array<Maybe<EditComponentLpMenuInput>>>;
  button_header?: Maybe<Array<Maybe<EditComponentLpButtonHeaderInput>>>;
  id?: Maybe<Scalars['ID']>;
};

export type EditComponentLpStepInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type EditComponentLpWorkInput = {
  Title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  steps?: Maybe<Array<Maybe<EditComponentLpStepInput>>>;
};

export type EditFileInput = {
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['ID']>;
  ext?: Maybe<Scalars['String']>;
  formats?: Maybe<Scalars['JSON']>;
  hash?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  mime?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  provider_metadata?: Maybe<Scalars['JSON']>;
  related?: Maybe<Array<Maybe<Scalars['ID']>>>;
  size?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type EditLoginInput = {
  created_by?: Maybe<Scalars['ID']>;
  login?: Maybe<EditComponentLpLoginInput>;
  published_at?: Maybe<Scalars['DateTime']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type EditMenuInput = {
  Title?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['ID']>;
  published_at?: Maybe<Scalars['DateTime']>;
  updated_by?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
};

export type EditRoleInput = {
  created_by?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  type?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['ID']>;
  users?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type EditSidebarInput = {
  Logo?: Maybe<Scalars['ID']>;
  Menu?: Maybe<Array<Maybe<EditComponentLpMenuInput>>>;
  button_header?: Maybe<Array<Maybe<EditComponentLpButtonHeaderInput>>>;
  created_by?: Maybe<Scalars['ID']>;
  published_at?: Maybe<Scalars['DateTime']>;
  updated_by?: Maybe<Scalars['ID']>;
};

export type EditUserInput = {
  blocked?: Maybe<Scalars['Boolean']>;
  confirmationToken?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  created_by?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  resetPasswordToken?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['ID']>;
  updated_by?: Maybe<Scalars['ID']>;
  username?: Maybe<Scalars['String']>;
};

/** columns and relationships of "estados" */
export type Estados = {
  __typename?: 'estados';
  capital_id: Scalars['Int'];
  /** An array relationship */
  cidades: Array<Cidades>;
  /** An aggregated array relationship */
  cidades_aggregate: Cidades_Aggregate;
  id: Scalars['Int'];
  name: Scalars['String'];
  sigla: Scalars['String'];
};


/** columns and relationships of "estados" */
export type EstadosCidadesArgs = {
  distinct_on?: Maybe<Array<Cidades_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cidades_Order_By>>;
  where?: Maybe<Cidades_Bool_Exp>;
};


/** columns and relationships of "estados" */
export type EstadosCidades_AggregateArgs = {
  distinct_on?: Maybe<Array<Cidades_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cidades_Order_By>>;
  where?: Maybe<Cidades_Bool_Exp>;
};

/** aggregated selection of "estados" */
export type Estados_Aggregate = {
  __typename?: 'estados_aggregate';
  aggregate?: Maybe<Estados_Aggregate_Fields>;
  nodes: Array<Estados>;
};

/** aggregate fields of "estados" */
export type Estados_Aggregate_Fields = {
  __typename?: 'estados_aggregate_fields';
  avg?: Maybe<Estados_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Estados_Max_Fields>;
  min?: Maybe<Estados_Min_Fields>;
  stddev?: Maybe<Estados_Stddev_Fields>;
  stddev_pop?: Maybe<Estados_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Estados_Stddev_Samp_Fields>;
  sum?: Maybe<Estados_Sum_Fields>;
  var_pop?: Maybe<Estados_Var_Pop_Fields>;
  var_samp?: Maybe<Estados_Var_Samp_Fields>;
  variance?: Maybe<Estados_Variance_Fields>;
};


/** aggregate fields of "estados" */
export type Estados_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Estados_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "estados" */
export type Estados_Aggregate_Order_By = {
  avg?: Maybe<Estados_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Estados_Max_Order_By>;
  min?: Maybe<Estados_Min_Order_By>;
  stddev?: Maybe<Estados_Stddev_Order_By>;
  stddev_pop?: Maybe<Estados_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Estados_Stddev_Samp_Order_By>;
  sum?: Maybe<Estados_Sum_Order_By>;
  var_pop?: Maybe<Estados_Var_Pop_Order_By>;
  var_samp?: Maybe<Estados_Var_Samp_Order_By>;
  variance?: Maybe<Estados_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "estados" */
export type Estados_Arr_Rel_Insert_Input = {
  data: Array<Estados_Insert_Input>;
  on_conflict?: Maybe<Estados_On_Conflict>;
};

/** aggregate avg on columns */
export type Estados_Avg_Fields = {
  __typename?: 'estados_avg_fields';
  capital_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "estados" */
export type Estados_Avg_Order_By = {
  capital_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "estados". All fields are combined with a logical 'AND'. */
export type Estados_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Estados_Bool_Exp>>>;
  _not?: Maybe<Estados_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Estados_Bool_Exp>>>;
  capital_id?: Maybe<Int_Comparison_Exp>;
  cidades?: Maybe<Cidades_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  sigla?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "estados" */
export enum Estados_Constraint {
  /** unique or primary key constraint */
  EstadosPkey = 'estados_pkey'
}

/** input type for incrementing integer column in table "estados" */
export type Estados_Inc_Input = {
  capital_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "estados" */
export type Estados_Insert_Input = {
  capital_id?: Maybe<Scalars['Int']>;
  cidades?: Maybe<Cidades_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  sigla?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Estados_Max_Fields = {
  __typename?: 'estados_max_fields';
  capital_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  sigla?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "estados" */
export type Estados_Max_Order_By = {
  capital_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  sigla?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Estados_Min_Fields = {
  __typename?: 'estados_min_fields';
  capital_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  sigla?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "estados" */
export type Estados_Min_Order_By = {
  capital_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  sigla?: Maybe<Order_By>;
};

/** response of any mutation on the table "estados" */
export type Estados_Mutation_Response = {
  __typename?: 'estados_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Estados>;
};

/** input type for inserting object relation for remote table "estados" */
export type Estados_Obj_Rel_Insert_Input = {
  data: Estados_Insert_Input;
  on_conflict?: Maybe<Estados_On_Conflict>;
};

/** on conflict condition type for table "estados" */
export type Estados_On_Conflict = {
  constraint: Estados_Constraint;
  update_columns: Array<Estados_Update_Column>;
  where?: Maybe<Estados_Bool_Exp>;
};

/** ordering options when selecting data from "estados" */
export type Estados_Order_By = {
  capital_id?: Maybe<Order_By>;
  cidades_aggregate?: Maybe<Cidades_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  sigla?: Maybe<Order_By>;
};

/** primary key columns input for table: "estados" */
export type Estados_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "estados" */
export enum Estados_Select_Column {
  /** column name */
  CapitalId = 'capital_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Sigla = 'sigla'
}

/** input type for updating data in table "estados" */
export type Estados_Set_Input = {
  capital_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  sigla?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Estados_Stddev_Fields = {
  __typename?: 'estados_stddev_fields';
  capital_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "estados" */
export type Estados_Stddev_Order_By = {
  capital_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Estados_Stddev_Pop_Fields = {
  __typename?: 'estados_stddev_pop_fields';
  capital_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "estados" */
export type Estados_Stddev_Pop_Order_By = {
  capital_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Estados_Stddev_Samp_Fields = {
  __typename?: 'estados_stddev_samp_fields';
  capital_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "estados" */
export type Estados_Stddev_Samp_Order_By = {
  capital_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Estados_Sum_Fields = {
  __typename?: 'estados_sum_fields';
  capital_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "estados" */
export type Estados_Sum_Order_By = {
  capital_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "estados" */
export enum Estados_Update_Column {
  /** column name */
  CapitalId = 'capital_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Sigla = 'sigla'
}

/** aggregate var_pop on columns */
export type Estados_Var_Pop_Fields = {
  __typename?: 'estados_var_pop_fields';
  capital_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "estados" */
export type Estados_Var_Pop_Order_By = {
  capital_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Estados_Var_Samp_Fields = {
  __typename?: 'estados_var_samp_fields';
  capital_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "estados" */
export type Estados_Var_Samp_Order_By = {
  capital_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Estados_Variance_Fields = {
  __typename?: 'estados_variance_fields';
  capital_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "estados" */
export type Estados_Variance_Order_By = {
  capital_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};


/** expression to compare columns of type float8. All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
};


/** expression to compare columns of type json. All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
};


/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "memberships" */
export type Memberships = {
  __typename?: 'memberships';
  /** An object relationship */
  apikey?: Maybe<Apikeys>;
  apikey_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  organizationid: Scalars['Int'];
  permission_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  subscription: Array<Permissions>;
  /** An aggregated array relationship */
  subscription_aggregate: Permissions_Aggregate;
  /** An object relationship */
  teams?: Maybe<Organizations>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "memberships" */
export type MembershipsSubscriptionArgs = {
  distinct_on?: Maybe<Array<Permissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Permissions_Order_By>>;
  where?: Maybe<Permissions_Bool_Exp>;
};


/** columns and relationships of "memberships" */
export type MembershipsSubscription_AggregateArgs = {
  distinct_on?: Maybe<Array<Permissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Permissions_Order_By>>;
  where?: Maybe<Permissions_Bool_Exp>;
};

/** aggregated selection of "memberships" */
export type Memberships_Aggregate = {
  __typename?: 'memberships_aggregate';
  aggregate?: Maybe<Memberships_Aggregate_Fields>;
  nodes: Array<Memberships>;
};

/** aggregate fields of "memberships" */
export type Memberships_Aggregate_Fields = {
  __typename?: 'memberships_aggregate_fields';
  avg?: Maybe<Memberships_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Memberships_Max_Fields>;
  min?: Maybe<Memberships_Min_Fields>;
  stddev?: Maybe<Memberships_Stddev_Fields>;
  stddev_pop?: Maybe<Memberships_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Memberships_Stddev_Samp_Fields>;
  sum?: Maybe<Memberships_Sum_Fields>;
  var_pop?: Maybe<Memberships_Var_Pop_Fields>;
  var_samp?: Maybe<Memberships_Var_Samp_Fields>;
  variance?: Maybe<Memberships_Variance_Fields>;
};


/** aggregate fields of "memberships" */
export type Memberships_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Memberships_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "memberships" */
export type Memberships_Aggregate_Order_By = {
  avg?: Maybe<Memberships_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Memberships_Max_Order_By>;
  min?: Maybe<Memberships_Min_Order_By>;
  stddev?: Maybe<Memberships_Stddev_Order_By>;
  stddev_pop?: Maybe<Memberships_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Memberships_Stddev_Samp_Order_By>;
  sum?: Maybe<Memberships_Sum_Order_By>;
  var_pop?: Maybe<Memberships_Var_Pop_Order_By>;
  var_samp?: Maybe<Memberships_Var_Samp_Order_By>;
  variance?: Maybe<Memberships_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "memberships" */
export type Memberships_Arr_Rel_Insert_Input = {
  data: Array<Memberships_Insert_Input>;
  on_conflict?: Maybe<Memberships_On_Conflict>;
};

/** aggregate avg on columns */
export type Memberships_Avg_Fields = {
  __typename?: 'memberships_avg_fields';
  id?: Maybe<Scalars['Float']>;
  organizationid?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "memberships" */
export type Memberships_Avg_Order_By = {
  id?: Maybe<Order_By>;
  organizationid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "memberships". All fields are combined with a logical 'AND'. */
export type Memberships_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Memberships_Bool_Exp>>>;
  _not?: Maybe<Memberships_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Memberships_Bool_Exp>>>;
  apikey?: Maybe<Apikeys_Bool_Exp>;
  apikey_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  organizationid?: Maybe<Int_Comparison_Exp>;
  permission_id?: Maybe<Uuid_Comparison_Exp>;
  subscription?: Maybe<Permissions_Bool_Exp>;
  teams?: Maybe<Organizations_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "memberships" */
export enum Memberships_Constraint {
  /** unique or primary key constraint */
  MembershipsPkey = 'memberships_pkey'
}

/** input type for incrementing integer column in table "memberships" */
export type Memberships_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  organizationid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "memberships" */
export type Memberships_Insert_Input = {
  apikey?: Maybe<Apikeys_Obj_Rel_Insert_Input>;
  apikey_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  organizationid?: Maybe<Scalars['Int']>;
  permission_id?: Maybe<Scalars['uuid']>;
  subscription?: Maybe<Permissions_Arr_Rel_Insert_Input>;
  teams?: Maybe<Organizations_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Memberships_Max_Fields = {
  __typename?: 'memberships_max_fields';
  apikey_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  organizationid?: Maybe<Scalars['Int']>;
  permission_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "memberships" */
export type Memberships_Max_Order_By = {
  apikey_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organizationid?: Maybe<Order_By>;
  permission_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Memberships_Min_Fields = {
  __typename?: 'memberships_min_fields';
  apikey_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  organizationid?: Maybe<Scalars['Int']>;
  permission_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "memberships" */
export type Memberships_Min_Order_By = {
  apikey_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organizationid?: Maybe<Order_By>;
  permission_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "memberships" */
export type Memberships_Mutation_Response = {
  __typename?: 'memberships_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Memberships>;
};

/** input type for inserting object relation for remote table "memberships" */
export type Memberships_Obj_Rel_Insert_Input = {
  data: Memberships_Insert_Input;
  on_conflict?: Maybe<Memberships_On_Conflict>;
};

/** on conflict condition type for table "memberships" */
export type Memberships_On_Conflict = {
  constraint: Memberships_Constraint;
  update_columns: Array<Memberships_Update_Column>;
  where?: Maybe<Memberships_Bool_Exp>;
};

/** ordering options when selecting data from "memberships" */
export type Memberships_Order_By = {
  apikey?: Maybe<Apikeys_Order_By>;
  apikey_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organizationid?: Maybe<Order_By>;
  permission_id?: Maybe<Order_By>;
  subscription_aggregate?: Maybe<Permissions_Aggregate_Order_By>;
  teams?: Maybe<Organizations_Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "memberships" */
export type Memberships_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "memberships" */
export enum Memberships_Select_Column {
  /** column name */
  ApikeyId = 'apikey_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Organizationid = 'organizationid',
  /** column name */
  PermissionId = 'permission_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "memberships" */
export type Memberships_Set_Input = {
  apikey_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  organizationid?: Maybe<Scalars['Int']>;
  permission_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Memberships_Stddev_Fields = {
  __typename?: 'memberships_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  organizationid?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "memberships" */
export type Memberships_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  organizationid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Memberships_Stddev_Pop_Fields = {
  __typename?: 'memberships_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  organizationid?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "memberships" */
export type Memberships_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  organizationid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Memberships_Stddev_Samp_Fields = {
  __typename?: 'memberships_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  organizationid?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "memberships" */
export type Memberships_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  organizationid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Memberships_Sum_Fields = {
  __typename?: 'memberships_sum_fields';
  id?: Maybe<Scalars['Int']>;
  organizationid?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "memberships" */
export type Memberships_Sum_Order_By = {
  id?: Maybe<Order_By>;
  organizationid?: Maybe<Order_By>;
};

/** update columns of table "memberships" */
export enum Memberships_Update_Column {
  /** column name */
  ApikeyId = 'apikey_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Organizationid = 'organizationid',
  /** column name */
  PermissionId = 'permission_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Memberships_Var_Pop_Fields = {
  __typename?: 'memberships_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  organizationid?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "memberships" */
export type Memberships_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  organizationid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Memberships_Var_Samp_Fields = {
  __typename?: 'memberships_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  organizationid?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "memberships" */
export type Memberships_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  organizationid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Memberships_Variance_Fields = {
  __typename?: 'memberships_variance_fields';
  id?: Maybe<Scalars['Float']>;
  organizationid?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "memberships" */
export type Memberships_Variance_Order_By = {
  id?: Maybe<Order_By>;
  organizationid?: Maybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** perform the action: "create_or_update_account" */
  create_or_update_account?: Maybe<CreateAccountOutput>;
  deleteCompany?: Maybe<DeleteCompanyPayload>;
  /** Delete one file */
  deleteFile?: Maybe<DeleteFilePayload>;
  deleteLogin?: Maybe<DeleteLoginPayload>;
  deleteMenu?: Maybe<DeleteMenuPayload>;
  deleteSidebar?: Maybe<DeleteSidebarPayload>;
  /** perform the action: "delete_account" */
  delete_account?: Maybe<DeleteAccountOutput>;
  /** delete data from the table: "acl_features" */
  delete_acl_features?: Maybe<Acl_Features_Mutation_Response>;
  /** delete single row from the table: "acl_features" */
  delete_acl_features_by_pk?: Maybe<Acl_Features>;
  /** delete data from the table: "acl_features_permissions" */
  delete_acl_features_permissions?: Maybe<Acl_Features_Permissions_Mutation_Response>;
  /** delete single row from the table: "acl_features_permissions" */
  delete_acl_features_permissions_by_pk?: Maybe<Acl_Features_Permissions>;
  /** delete data from the table: "apikeys" */
  delete_apikeys?: Maybe<Apikeys_Mutation_Response>;
  /** delete single row from the table: "apikeys" */
  delete_apikeys_by_pk?: Maybe<Apikeys>;
  /** delete data from the table: "auth.account_providers" */
  delete_auth_account_providers?: Maybe<Auth_Account_Providers_Mutation_Response>;
  /** delete single row from the table: "auth.account_providers" */
  delete_auth_account_providers_by_pk?: Maybe<Auth_Account_Providers>;
  /** delete data from the table: "auth.account_roles" */
  delete_auth_account_roles?: Maybe<Auth_Account_Roles_Mutation_Response>;
  /** delete single row from the table: "auth.account_roles" */
  delete_auth_account_roles_by_pk?: Maybe<Auth_Account_Roles>;
  /** delete data from the table: "auth.accounts" */
  delete_auth_accounts?: Maybe<Auth_Accounts_Mutation_Response>;
  /** delete single row from the table: "auth.accounts" */
  delete_auth_accounts_by_pk?: Maybe<Auth_Accounts>;
  /** delete data from the table: "auth.providers" */
  delete_auth_providers?: Maybe<Auth_Providers_Mutation_Response>;
  /** delete single row from the table: "auth.providers" */
  delete_auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** delete data from the table: "auth.refresh_tokens" */
  delete_auth_refresh_tokens?: Maybe<Auth_Refresh_Tokens_Mutation_Response>;
  /** delete single row from the table: "auth.refresh_tokens" */
  delete_auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** delete data from the table: "auth.roles" */
  delete_auth_roles?: Maybe<Auth_Roles_Mutation_Response>;
  /** delete single row from the table: "auth.roles" */
  delete_auth_roles_by_pk?: Maybe<Auth_Roles>;
  /** delete data from the table: "cidades" */
  delete_cidades?: Maybe<Cidades_Mutation_Response>;
  /** delete single row from the table: "cidades" */
  delete_cidades_by_pk?: Maybe<Cidades>;
  /** delete data from the table: "estados" */
  delete_estados?: Maybe<Estados_Mutation_Response>;
  /** delete single row from the table: "estados" */
  delete_estados_by_pk?: Maybe<Estados>;
  /** delete data from the table: "memberships" */
  delete_memberships?: Maybe<Memberships_Mutation_Response>;
  /** delete single row from the table: "memberships" */
  delete_memberships_by_pk?: Maybe<Memberships>;
  /** delete data from the table: "omnichannel.addons" */
  delete_omnichannel_addons?: Maybe<Omnichannel_Addons_Mutation_Response>;
  /** delete single row from the table: "omnichannel.addons" */
  delete_omnichannel_addons_by_pk?: Maybe<Omnichannel_Addons>;
  /** delete data from the table: "omnichannel.addons_connect_settings" */
  delete_omnichannel_addons_connect_settings?: Maybe<Omnichannel_Addons_Connect_Settings_Mutation_Response>;
  /** delete single row from the table: "omnichannel.addons_connect_settings" */
  delete_omnichannel_addons_connect_settings_by_pk?: Maybe<Omnichannel_Addons_Connect_Settings>;
  /** delete data from the table: "omnichannel.addons_connects" */
  delete_omnichannel_addons_connects?: Maybe<Omnichannel_Addons_Connects_Mutation_Response>;
  /** delete single row from the table: "omnichannel.addons_connects" */
  delete_omnichannel_addons_connects_by_pk?: Maybe<Omnichannel_Addons_Connects>;
  /** delete data from the table: "omnichannel.channel_enabled" */
  delete_omnichannel_channel_enabled?: Maybe<Omnichannel_Channel_Enabled_Mutation_Response>;
  /** delete single row from the table: "omnichannel.channel_enabled" */
  delete_omnichannel_channel_enabled_by_pk?: Maybe<Omnichannel_Channel_Enabled>;
  /** delete data from the table: "omnichannel.channel_enabled_settings" */
  delete_omnichannel_channel_enabled_settings?: Maybe<Omnichannel_Channel_Enabled_Settings_Mutation_Response>;
  /** delete single row from the table: "omnichannel.channel_enabled_settings" */
  delete_omnichannel_channel_enabled_settings_by_pk?: Maybe<Omnichannel_Channel_Enabled_Settings>;
  /** delete data from the table: "omnichannel.channel_message" */
  delete_omnichannel_channel_message?: Maybe<Omnichannel_Channel_Message_Mutation_Response>;
  /** delete single row from the table: "omnichannel.channel_message" */
  delete_omnichannel_channel_message_by_pk?: Maybe<Omnichannel_Channel_Message>;
  /** delete data from the table: "omnichannel.channel_sessions" */
  delete_omnichannel_channel_sessions?: Maybe<Omnichannel_Channel_Sessions_Mutation_Response>;
  /** delete single row from the table: "omnichannel.channel_sessions" */
  delete_omnichannel_channel_sessions_by_pk?: Maybe<Omnichannel_Channel_Sessions>;
  /** delete data from the table: "omnichannel.channels" */
  delete_omnichannel_channels?: Maybe<Omnichannel_Channels_Mutation_Response>;
  /** delete single row from the table: "omnichannel.channels" */
  delete_omnichannel_channels_by_pk?: Maybe<Omnichannel_Channels>;
  /** delete data from the table: "omnichannel.groups" */
  delete_omnichannel_groups?: Maybe<Omnichannel_Groups_Mutation_Response>;
  /** delete single row from the table: "omnichannel.groups" */
  delete_omnichannel_groups_by_pk?: Maybe<Omnichannel_Groups>;
  /** delete data from the table: "omnichannel.groups_users" */
  delete_omnichannel_groups_users?: Maybe<Omnichannel_Groups_Users_Mutation_Response>;
  /** delete single row from the table: "omnichannel.groups_users" */
  delete_omnichannel_groups_users_by_pk?: Maybe<Omnichannel_Groups_Users>;
  /** delete data from the table: "omnichannel.mailbox_mail_addons" */
  delete_omnichannel_mailbox_mail_addons?: Maybe<Omnichannel_Mailbox_Mail_Addons_Mutation_Response>;
  /** delete single row from the table: "omnichannel.mailbox_mail_addons" */
  delete_omnichannel_mailbox_mail_addons_by_pk?: Maybe<Omnichannel_Mailbox_Mail_Addons>;
  /** delete data from the table: "omnichannel.message_mail_addons" */
  delete_omnichannel_message_mail_addons?: Maybe<Omnichannel_Message_Mail_Addons_Mutation_Response>;
  /** delete single row from the table: "omnichannel.message_mail_addons" */
  delete_omnichannel_message_mail_addons_by_pk?: Maybe<Omnichannel_Message_Mail_Addons>;
  /** delete data from the table: "omnichannel.person_activities" */
  delete_omnichannel_person_activities?: Maybe<Omnichannel_Person_Activities_Mutation_Response>;
  /** delete single row from the table: "omnichannel.person_activities" */
  delete_omnichannel_person_activities_by_pk?: Maybe<Omnichannel_Person_Activities>;
  /** delete data from the table: "omnichannel.person_file" */
  delete_omnichannel_person_file?: Maybe<Omnichannel_Person_File_Mutation_Response>;
  /** delete single row from the table: "omnichannel.person_file" */
  delete_omnichannel_person_file_by_pk?: Maybe<Omnichannel_Person_File>;
  /** delete data from the table: "omnichannel.person_mail" */
  delete_omnichannel_person_mail?: Maybe<Omnichannel_Person_Mail_Mutation_Response>;
  /** delete single row from the table: "omnichannel.person_mail" */
  delete_omnichannel_person_mail_by_pk?: Maybe<Omnichannel_Person_Mail>;
  /** delete data from the table: "omnichannel.person_note" */
  delete_omnichannel_person_note?: Maybe<Omnichannel_Person_Note_Mutation_Response>;
  /** delete single row from the table: "omnichannel.person_note" */
  delete_omnichannel_person_note_by_pk?: Maybe<Omnichannel_Person_Note>;
  /** delete data from the table: "omnichannel.person_tags" */
  delete_omnichannel_person_tags?: Maybe<Omnichannel_Person_Tags_Mutation_Response>;
  /** delete single row from the table: "omnichannel.person_tags" */
  delete_omnichannel_person_tags_by_pk?: Maybe<Omnichannel_Person_Tags>;
  /** delete data from the table: "omnichannel.person_type" */
  delete_omnichannel_person_type?: Maybe<Omnichannel_Person_Type_Mutation_Response>;
  /** delete single row from the table: "omnichannel.person_type" */
  delete_omnichannel_person_type_by_pk?: Maybe<Omnichannel_Person_Type>;
  /** delete data from the table: "omnichannel.persons" */
  delete_omnichannel_persons?: Maybe<Omnichannel_Persons_Mutation_Response>;
  /** delete single row from the table: "omnichannel.persons" */
  delete_omnichannel_persons_by_pk?: Maybe<Omnichannel_Persons>;
  /** delete data from the table: "omnichannel.pipeline" */
  delete_omnichannel_pipeline?: Maybe<Omnichannel_Pipeline_Mutation_Response>;
  /** delete single row from the table: "omnichannel.pipeline" */
  delete_omnichannel_pipeline_by_pk?: Maybe<Omnichannel_Pipeline>;
  /** delete data from the table: "omnichannel.pipeline_card" */
  delete_omnichannel_pipeline_card?: Maybe<Omnichannel_Pipeline_Card_Mutation_Response>;
  /** delete single row from the table: "omnichannel.pipeline_card" */
  delete_omnichannel_pipeline_card_by_pk?: Maybe<Omnichannel_Pipeline_Card>;
  /** delete data from the table: "omnichannel.pipeline_comments" */
  delete_omnichannel_pipeline_comments?: Maybe<Omnichannel_Pipeline_Comments_Mutation_Response>;
  /** delete single row from the table: "omnichannel.pipeline_comments" */
  delete_omnichannel_pipeline_comments_by_pk?: Maybe<Omnichannel_Pipeline_Comments>;
  /** delete data from the table: "omnichannel.pipeline_list" */
  delete_omnichannel_pipeline_list?: Maybe<Omnichannel_Pipeline_List_Mutation_Response>;
  /** delete data from the table: "omnichannel.pipeline_list_actions" */
  delete_omnichannel_pipeline_list_actions?: Maybe<Omnichannel_Pipeline_List_Actions_Mutation_Response>;
  /** delete single row from the table: "omnichannel.pipeline_list_actions" */
  delete_omnichannel_pipeline_list_actions_by_pk?: Maybe<Omnichannel_Pipeline_List_Actions>;
  /** delete single row from the table: "omnichannel.pipeline_list" */
  delete_omnichannel_pipeline_list_by_pk?: Maybe<Omnichannel_Pipeline_List>;
  /** delete data from the table: "omnichannel.pipeline_products" */
  delete_omnichannel_pipeline_products?: Maybe<Omnichannel_Pipeline_Products_Mutation_Response>;
  /** delete single row from the table: "omnichannel.pipeline_products" */
  delete_omnichannel_pipeline_products_by_pk?: Maybe<Omnichannel_Pipeline_Products>;
  /** delete data from the table: "omnichannel.pipeline_status" */
  delete_omnichannel_pipeline_status?: Maybe<Omnichannel_Pipeline_Status_Mutation_Response>;
  /** delete single row from the table: "omnichannel.pipeline_status" */
  delete_omnichannel_pipeline_status_by_pk?: Maybe<Omnichannel_Pipeline_Status>;
  /** delete data from the table: "omnichannel.pipeline_tags" */
  delete_omnichannel_pipeline_tags?: Maybe<Omnichannel_Pipeline_Tags_Mutation_Response>;
  /** delete single row from the table: "omnichannel.pipeline_tags" */
  delete_omnichannel_pipeline_tags_by_pk?: Maybe<Omnichannel_Pipeline_Tags>;
  /** delete data from the table: "omnichannel.products" */
  delete_omnichannel_products?: Maybe<Omnichannel_Products_Mutation_Response>;
  /** delete single row from the table: "omnichannel.products" */
  delete_omnichannel_products_by_pk?: Maybe<Omnichannel_Products>;
  /** delete data from the table: "omnichannel.tags" */
  delete_omnichannel_tags?: Maybe<Omnichannel_Tags_Mutation_Response>;
  /** delete single row from the table: "omnichannel.tags" */
  delete_omnichannel_tags_by_pk?: Maybe<Omnichannel_Tags>;
  /** delete data from the table: "omnichannel.vw_activities_group" */
  delete_omnichannel_vw_activities_group?: Maybe<Omnichannel_Vw_Activities_Group_Mutation_Response>;
  /** delete data from the table: "organizations" */
  delete_organizations?: Maybe<Organizations_Mutation_Response>;
  /** delete single row from the table: "organizations" */
  delete_organizations_by_pk?: Maybe<Organizations>;
  /** delete data from the table: "permissions" */
  delete_permissions?: Maybe<Permissions_Mutation_Response>;
  /** delete single row from the table: "permissions" */
  delete_permissions_by_pk?: Maybe<Permissions>;
  /** delete data from the table: "product" */
  delete_product?: Maybe<Product_Mutation_Response>;
  /** delete single row from the table: "product" */
  delete_product_by_pk?: Maybe<Product>;
  /** delete data from the table: "stripe_plan" */
  delete_stripe_plan?: Maybe<Stripe_Plan_Mutation_Response>;
  /** delete single row from the table: "stripe_plan" */
  delete_stripe_plan_by_pk?: Maybe<Stripe_Plan>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  forgotPassword?: Maybe<UserPermissionsPasswordPayload>;
  /** insert data into the table: "acl_features" */
  insert_acl_features?: Maybe<Acl_Features_Mutation_Response>;
  /** insert a single row into the table: "acl_features" */
  insert_acl_features_one?: Maybe<Acl_Features>;
  /** insert data into the table: "acl_features_permissions" */
  insert_acl_features_permissions?: Maybe<Acl_Features_Permissions_Mutation_Response>;
  /** insert a single row into the table: "acl_features_permissions" */
  insert_acl_features_permissions_one?: Maybe<Acl_Features_Permissions>;
  /** insert data into the table: "apikeys" */
  insert_apikeys?: Maybe<Apikeys_Mutation_Response>;
  /** insert a single row into the table: "apikeys" */
  insert_apikeys_one?: Maybe<Apikeys>;
  /** insert data into the table: "auth.account_providers" */
  insert_auth_account_providers?: Maybe<Auth_Account_Providers_Mutation_Response>;
  /** insert a single row into the table: "auth.account_providers" */
  insert_auth_account_providers_one?: Maybe<Auth_Account_Providers>;
  /** insert data into the table: "auth.account_roles" */
  insert_auth_account_roles?: Maybe<Auth_Account_Roles_Mutation_Response>;
  /** insert a single row into the table: "auth.account_roles" */
  insert_auth_account_roles_one?: Maybe<Auth_Account_Roles>;
  /** insert data into the table: "auth.accounts" */
  insert_auth_accounts?: Maybe<Auth_Accounts_Mutation_Response>;
  /** insert a single row into the table: "auth.accounts" */
  insert_auth_accounts_one?: Maybe<Auth_Accounts>;
  /** insert data into the table: "auth.providers" */
  insert_auth_providers?: Maybe<Auth_Providers_Mutation_Response>;
  /** insert a single row into the table: "auth.providers" */
  insert_auth_providers_one?: Maybe<Auth_Providers>;
  /** insert data into the table: "auth.refresh_tokens" */
  insert_auth_refresh_tokens?: Maybe<Auth_Refresh_Tokens_Mutation_Response>;
  /** insert a single row into the table: "auth.refresh_tokens" */
  insert_auth_refresh_tokens_one?: Maybe<Auth_Refresh_Tokens>;
  /** insert data into the table: "auth.roles" */
  insert_auth_roles?: Maybe<Auth_Roles_Mutation_Response>;
  /** insert a single row into the table: "auth.roles" */
  insert_auth_roles_one?: Maybe<Auth_Roles>;
  /** insert data into the table: "cidades" */
  insert_cidades?: Maybe<Cidades_Mutation_Response>;
  /** insert a single row into the table: "cidades" */
  insert_cidades_one?: Maybe<Cidades>;
  /** insert data into the table: "estados" */
  insert_estados?: Maybe<Estados_Mutation_Response>;
  /** insert a single row into the table: "estados" */
  insert_estados_one?: Maybe<Estados>;
  /** insert data into the table: "memberships" */
  insert_memberships?: Maybe<Memberships_Mutation_Response>;
  /** insert a single row into the table: "memberships" */
  insert_memberships_one?: Maybe<Memberships>;
  /** insert data into the table: "omnichannel.addons" */
  insert_omnichannel_addons?: Maybe<Omnichannel_Addons_Mutation_Response>;
  /** insert data into the table: "omnichannel.addons_connect_settings" */
  insert_omnichannel_addons_connect_settings?: Maybe<Omnichannel_Addons_Connect_Settings_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.addons_connect_settings" */
  insert_omnichannel_addons_connect_settings_one?: Maybe<Omnichannel_Addons_Connect_Settings>;
  /** insert data into the table: "omnichannel.addons_connects" */
  insert_omnichannel_addons_connects?: Maybe<Omnichannel_Addons_Connects_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.addons_connects" */
  insert_omnichannel_addons_connects_one?: Maybe<Omnichannel_Addons_Connects>;
  /** insert a single row into the table: "omnichannel.addons" */
  insert_omnichannel_addons_one?: Maybe<Omnichannel_Addons>;
  /** insert data into the table: "omnichannel.channel_enabled" */
  insert_omnichannel_channel_enabled?: Maybe<Omnichannel_Channel_Enabled_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.channel_enabled" */
  insert_omnichannel_channel_enabled_one?: Maybe<Omnichannel_Channel_Enabled>;
  /** insert data into the table: "omnichannel.channel_enabled_settings" */
  insert_omnichannel_channel_enabled_settings?: Maybe<Omnichannel_Channel_Enabled_Settings_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.channel_enabled_settings" */
  insert_omnichannel_channel_enabled_settings_one?: Maybe<Omnichannel_Channel_Enabled_Settings>;
  /** insert data into the table: "omnichannel.channel_message" */
  insert_omnichannel_channel_message?: Maybe<Omnichannel_Channel_Message_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.channel_message" */
  insert_omnichannel_channel_message_one?: Maybe<Omnichannel_Channel_Message>;
  /** insert data into the table: "omnichannel.channel_sessions" */
  insert_omnichannel_channel_sessions?: Maybe<Omnichannel_Channel_Sessions_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.channel_sessions" */
  insert_omnichannel_channel_sessions_one?: Maybe<Omnichannel_Channel_Sessions>;
  /** insert data into the table: "omnichannel.channels" */
  insert_omnichannel_channels?: Maybe<Omnichannel_Channels_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.channels" */
  insert_omnichannel_channels_one?: Maybe<Omnichannel_Channels>;
  /** insert data into the table: "omnichannel.groups" */
  insert_omnichannel_groups?: Maybe<Omnichannel_Groups_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.groups" */
  insert_omnichannel_groups_one?: Maybe<Omnichannel_Groups>;
  /** insert data into the table: "omnichannel.groups_users" */
  insert_omnichannel_groups_users?: Maybe<Omnichannel_Groups_Users_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.groups_users" */
  insert_omnichannel_groups_users_one?: Maybe<Omnichannel_Groups_Users>;
  /** insert data into the table: "omnichannel.mailbox_mail_addons" */
  insert_omnichannel_mailbox_mail_addons?: Maybe<Omnichannel_Mailbox_Mail_Addons_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.mailbox_mail_addons" */
  insert_omnichannel_mailbox_mail_addons_one?: Maybe<Omnichannel_Mailbox_Mail_Addons>;
  /** insert data into the table: "omnichannel.message_mail_addons" */
  insert_omnichannel_message_mail_addons?: Maybe<Omnichannel_Message_Mail_Addons_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.message_mail_addons" */
  insert_omnichannel_message_mail_addons_one?: Maybe<Omnichannel_Message_Mail_Addons>;
  /** insert data into the table: "omnichannel.person_activities" */
  insert_omnichannel_person_activities?: Maybe<Omnichannel_Person_Activities_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.person_activities" */
  insert_omnichannel_person_activities_one?: Maybe<Omnichannel_Person_Activities>;
  /** insert data into the table: "omnichannel.person_file" */
  insert_omnichannel_person_file?: Maybe<Omnichannel_Person_File_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.person_file" */
  insert_omnichannel_person_file_one?: Maybe<Omnichannel_Person_File>;
  /** insert data into the table: "omnichannel.person_mail" */
  insert_omnichannel_person_mail?: Maybe<Omnichannel_Person_Mail_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.person_mail" */
  insert_omnichannel_person_mail_one?: Maybe<Omnichannel_Person_Mail>;
  /** insert data into the table: "omnichannel.person_note" */
  insert_omnichannel_person_note?: Maybe<Omnichannel_Person_Note_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.person_note" */
  insert_omnichannel_person_note_one?: Maybe<Omnichannel_Person_Note>;
  /** insert data into the table: "omnichannel.person_tags" */
  insert_omnichannel_person_tags?: Maybe<Omnichannel_Person_Tags_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.person_tags" */
  insert_omnichannel_person_tags_one?: Maybe<Omnichannel_Person_Tags>;
  /** insert data into the table: "omnichannel.person_type" */
  insert_omnichannel_person_type?: Maybe<Omnichannel_Person_Type_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.person_type" */
  insert_omnichannel_person_type_one?: Maybe<Omnichannel_Person_Type>;
  /** insert data into the table: "omnichannel.persons" */
  insert_omnichannel_persons?: Maybe<Omnichannel_Persons_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.persons" */
  insert_omnichannel_persons_one?: Maybe<Omnichannel_Persons>;
  /** insert data into the table: "omnichannel.pipeline" */
  insert_omnichannel_pipeline?: Maybe<Omnichannel_Pipeline_Mutation_Response>;
  /** insert data into the table: "omnichannel.pipeline_card" */
  insert_omnichannel_pipeline_card?: Maybe<Omnichannel_Pipeline_Card_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.pipeline_card" */
  insert_omnichannel_pipeline_card_one?: Maybe<Omnichannel_Pipeline_Card>;
  /** insert data into the table: "omnichannel.pipeline_comments" */
  insert_omnichannel_pipeline_comments?: Maybe<Omnichannel_Pipeline_Comments_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.pipeline_comments" */
  insert_omnichannel_pipeline_comments_one?: Maybe<Omnichannel_Pipeline_Comments>;
  /** insert data into the table: "omnichannel.pipeline_list" */
  insert_omnichannel_pipeline_list?: Maybe<Omnichannel_Pipeline_List_Mutation_Response>;
  /** insert data into the table: "omnichannel.pipeline_list_actions" */
  insert_omnichannel_pipeline_list_actions?: Maybe<Omnichannel_Pipeline_List_Actions_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.pipeline_list_actions" */
  insert_omnichannel_pipeline_list_actions_one?: Maybe<Omnichannel_Pipeline_List_Actions>;
  /** insert a single row into the table: "omnichannel.pipeline_list" */
  insert_omnichannel_pipeline_list_one?: Maybe<Omnichannel_Pipeline_List>;
  /** insert a single row into the table: "omnichannel.pipeline" */
  insert_omnichannel_pipeline_one?: Maybe<Omnichannel_Pipeline>;
  /** insert data into the table: "omnichannel.pipeline_products" */
  insert_omnichannel_pipeline_products?: Maybe<Omnichannel_Pipeline_Products_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.pipeline_products" */
  insert_omnichannel_pipeline_products_one?: Maybe<Omnichannel_Pipeline_Products>;
  /** insert data into the table: "omnichannel.pipeline_status" */
  insert_omnichannel_pipeline_status?: Maybe<Omnichannel_Pipeline_Status_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.pipeline_status" */
  insert_omnichannel_pipeline_status_one?: Maybe<Omnichannel_Pipeline_Status>;
  /** insert data into the table: "omnichannel.pipeline_tags" */
  insert_omnichannel_pipeline_tags?: Maybe<Omnichannel_Pipeline_Tags_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.pipeline_tags" */
  insert_omnichannel_pipeline_tags_one?: Maybe<Omnichannel_Pipeline_Tags>;
  /** insert data into the table: "omnichannel.products" */
  insert_omnichannel_products?: Maybe<Omnichannel_Products_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.products" */
  insert_omnichannel_products_one?: Maybe<Omnichannel_Products>;
  /** insert data into the table: "omnichannel.tags" */
  insert_omnichannel_tags?: Maybe<Omnichannel_Tags_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.tags" */
  insert_omnichannel_tags_one?: Maybe<Omnichannel_Tags>;
  /** insert data into the table: "omnichannel.vw_activities_group" */
  insert_omnichannel_vw_activities_group?: Maybe<Omnichannel_Vw_Activities_Group_Mutation_Response>;
  /** insert a single row into the table: "omnichannel.vw_activities_group" */
  insert_omnichannel_vw_activities_group_one?: Maybe<Omnichannel_Vw_Activities_Group>;
  /** insert data into the table: "organizations" */
  insert_organizations?: Maybe<Organizations_Mutation_Response>;
  /** insert a single row into the table: "organizations" */
  insert_organizations_one?: Maybe<Organizations>;
  /** insert data into the table: "permissions" */
  insert_permissions?: Maybe<Permissions_Mutation_Response>;
  /** insert a single row into the table: "permissions" */
  insert_permissions_one?: Maybe<Permissions>;
  /** insert data into the table: "product" */
  insert_product?: Maybe<Product_Mutation_Response>;
  /** insert a single row into the table: "product" */
  insert_product_one?: Maybe<Product>;
  /** insert data into the table: "stripe_plan" */
  insert_stripe_plan?: Maybe<Stripe_Plan_Mutation_Response>;
  /** insert a single row into the table: "stripe_plan" */
  insert_stripe_plan_one?: Maybe<Stripe_Plan>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  login: UsersPermissionsLoginPayload;
  multipleUpload: Array<Maybe<UploadFile>>;
  register: UsersPermissionsLoginPayload;
  /** perform the action: "reorder" */
  reorder?: Maybe<Reorder>;
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  /** perform the action: "sendEmail" */
  sendEmail?: Maybe<SampleOutput>;
  updateCompany?: Maybe<UpdateCompanyPayload>;
  updateFileInfo: UploadFile;
  updateLogin?: Maybe<UpdateLoginPayload>;
  updateMenu?: Maybe<UpdateMenuPayload>;
  updateSidebar?: Maybe<UpdateSidebarPayload>;
  /** update data of the table: "acl_features" */
  update_acl_features?: Maybe<Acl_Features_Mutation_Response>;
  /** update single row of the table: "acl_features" */
  update_acl_features_by_pk?: Maybe<Acl_Features>;
  /** update data of the table: "acl_features_permissions" */
  update_acl_features_permissions?: Maybe<Acl_Features_Permissions_Mutation_Response>;
  /** update single row of the table: "acl_features_permissions" */
  update_acl_features_permissions_by_pk?: Maybe<Acl_Features_Permissions>;
  /** update data of the table: "apikeys" */
  update_apikeys?: Maybe<Apikeys_Mutation_Response>;
  /** update single row of the table: "apikeys" */
  update_apikeys_by_pk?: Maybe<Apikeys>;
  /** update data of the table: "auth.account_providers" */
  update_auth_account_providers?: Maybe<Auth_Account_Providers_Mutation_Response>;
  /** update single row of the table: "auth.account_providers" */
  update_auth_account_providers_by_pk?: Maybe<Auth_Account_Providers>;
  /** update data of the table: "auth.account_roles" */
  update_auth_account_roles?: Maybe<Auth_Account_Roles_Mutation_Response>;
  /** update single row of the table: "auth.account_roles" */
  update_auth_account_roles_by_pk?: Maybe<Auth_Account_Roles>;
  /** update data of the table: "auth.accounts" */
  update_auth_accounts?: Maybe<Auth_Accounts_Mutation_Response>;
  /** update single row of the table: "auth.accounts" */
  update_auth_accounts_by_pk?: Maybe<Auth_Accounts>;
  /** update data of the table: "auth.providers" */
  update_auth_providers?: Maybe<Auth_Providers_Mutation_Response>;
  /** update single row of the table: "auth.providers" */
  update_auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** update data of the table: "auth.refresh_tokens" */
  update_auth_refresh_tokens?: Maybe<Auth_Refresh_Tokens_Mutation_Response>;
  /** update single row of the table: "auth.refresh_tokens" */
  update_auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** update data of the table: "auth.roles" */
  update_auth_roles?: Maybe<Auth_Roles_Mutation_Response>;
  /** update single row of the table: "auth.roles" */
  update_auth_roles_by_pk?: Maybe<Auth_Roles>;
  /** update data of the table: "cidades" */
  update_cidades?: Maybe<Cidades_Mutation_Response>;
  /** update single row of the table: "cidades" */
  update_cidades_by_pk?: Maybe<Cidades>;
  /** update data of the table: "estados" */
  update_estados?: Maybe<Estados_Mutation_Response>;
  /** update single row of the table: "estados" */
  update_estados_by_pk?: Maybe<Estados>;
  /** update data of the table: "memberships" */
  update_memberships?: Maybe<Memberships_Mutation_Response>;
  /** update single row of the table: "memberships" */
  update_memberships_by_pk?: Maybe<Memberships>;
  /** update data of the table: "omnichannel.addons" */
  update_omnichannel_addons?: Maybe<Omnichannel_Addons_Mutation_Response>;
  /** update single row of the table: "omnichannel.addons" */
  update_omnichannel_addons_by_pk?: Maybe<Omnichannel_Addons>;
  /** update data of the table: "omnichannel.addons_connect_settings" */
  update_omnichannel_addons_connect_settings?: Maybe<Omnichannel_Addons_Connect_Settings_Mutation_Response>;
  /** update single row of the table: "omnichannel.addons_connect_settings" */
  update_omnichannel_addons_connect_settings_by_pk?: Maybe<Omnichannel_Addons_Connect_Settings>;
  /** update data of the table: "omnichannel.addons_connects" */
  update_omnichannel_addons_connects?: Maybe<Omnichannel_Addons_Connects_Mutation_Response>;
  /** update single row of the table: "omnichannel.addons_connects" */
  update_omnichannel_addons_connects_by_pk?: Maybe<Omnichannel_Addons_Connects>;
  /** update data of the table: "omnichannel.channel_enabled" */
  update_omnichannel_channel_enabled?: Maybe<Omnichannel_Channel_Enabled_Mutation_Response>;
  /** update single row of the table: "omnichannel.channel_enabled" */
  update_omnichannel_channel_enabled_by_pk?: Maybe<Omnichannel_Channel_Enabled>;
  /** update data of the table: "omnichannel.channel_enabled_settings" */
  update_omnichannel_channel_enabled_settings?: Maybe<Omnichannel_Channel_Enabled_Settings_Mutation_Response>;
  /** update single row of the table: "omnichannel.channel_enabled_settings" */
  update_omnichannel_channel_enabled_settings_by_pk?: Maybe<Omnichannel_Channel_Enabled_Settings>;
  /** update data of the table: "omnichannel.channel_message" */
  update_omnichannel_channel_message?: Maybe<Omnichannel_Channel_Message_Mutation_Response>;
  /** update single row of the table: "omnichannel.channel_message" */
  update_omnichannel_channel_message_by_pk?: Maybe<Omnichannel_Channel_Message>;
  /** update data of the table: "omnichannel.channel_sessions" */
  update_omnichannel_channel_sessions?: Maybe<Omnichannel_Channel_Sessions_Mutation_Response>;
  /** update single row of the table: "omnichannel.channel_sessions" */
  update_omnichannel_channel_sessions_by_pk?: Maybe<Omnichannel_Channel_Sessions>;
  /** update data of the table: "omnichannel.channels" */
  update_omnichannel_channels?: Maybe<Omnichannel_Channels_Mutation_Response>;
  /** update single row of the table: "omnichannel.channels" */
  update_omnichannel_channels_by_pk?: Maybe<Omnichannel_Channels>;
  /** update data of the table: "omnichannel.groups" */
  update_omnichannel_groups?: Maybe<Omnichannel_Groups_Mutation_Response>;
  /** update single row of the table: "omnichannel.groups" */
  update_omnichannel_groups_by_pk?: Maybe<Omnichannel_Groups>;
  /** update data of the table: "omnichannel.groups_users" */
  update_omnichannel_groups_users?: Maybe<Omnichannel_Groups_Users_Mutation_Response>;
  /** update single row of the table: "omnichannel.groups_users" */
  update_omnichannel_groups_users_by_pk?: Maybe<Omnichannel_Groups_Users>;
  /** update data of the table: "omnichannel.mailbox_mail_addons" */
  update_omnichannel_mailbox_mail_addons?: Maybe<Omnichannel_Mailbox_Mail_Addons_Mutation_Response>;
  /** update single row of the table: "omnichannel.mailbox_mail_addons" */
  update_omnichannel_mailbox_mail_addons_by_pk?: Maybe<Omnichannel_Mailbox_Mail_Addons>;
  /** update data of the table: "omnichannel.message_mail_addons" */
  update_omnichannel_message_mail_addons?: Maybe<Omnichannel_Message_Mail_Addons_Mutation_Response>;
  /** update single row of the table: "omnichannel.message_mail_addons" */
  update_omnichannel_message_mail_addons_by_pk?: Maybe<Omnichannel_Message_Mail_Addons>;
  /** update data of the table: "omnichannel.person_activities" */
  update_omnichannel_person_activities?: Maybe<Omnichannel_Person_Activities_Mutation_Response>;
  /** update single row of the table: "omnichannel.person_activities" */
  update_omnichannel_person_activities_by_pk?: Maybe<Omnichannel_Person_Activities>;
  /** update data of the table: "omnichannel.person_file" */
  update_omnichannel_person_file?: Maybe<Omnichannel_Person_File_Mutation_Response>;
  /** update single row of the table: "omnichannel.person_file" */
  update_omnichannel_person_file_by_pk?: Maybe<Omnichannel_Person_File>;
  /** update data of the table: "omnichannel.person_mail" */
  update_omnichannel_person_mail?: Maybe<Omnichannel_Person_Mail_Mutation_Response>;
  /** update single row of the table: "omnichannel.person_mail" */
  update_omnichannel_person_mail_by_pk?: Maybe<Omnichannel_Person_Mail>;
  /** update data of the table: "omnichannel.person_note" */
  update_omnichannel_person_note?: Maybe<Omnichannel_Person_Note_Mutation_Response>;
  /** update single row of the table: "omnichannel.person_note" */
  update_omnichannel_person_note_by_pk?: Maybe<Omnichannel_Person_Note>;
  /** update data of the table: "omnichannel.person_tags" */
  update_omnichannel_person_tags?: Maybe<Omnichannel_Person_Tags_Mutation_Response>;
  /** update single row of the table: "omnichannel.person_tags" */
  update_omnichannel_person_tags_by_pk?: Maybe<Omnichannel_Person_Tags>;
  /** update data of the table: "omnichannel.person_type" */
  update_omnichannel_person_type?: Maybe<Omnichannel_Person_Type_Mutation_Response>;
  /** update single row of the table: "omnichannel.person_type" */
  update_omnichannel_person_type_by_pk?: Maybe<Omnichannel_Person_Type>;
  /** update data of the table: "omnichannel.persons" */
  update_omnichannel_persons?: Maybe<Omnichannel_Persons_Mutation_Response>;
  /** update single row of the table: "omnichannel.persons" */
  update_omnichannel_persons_by_pk?: Maybe<Omnichannel_Persons>;
  /** update data of the table: "omnichannel.pipeline" */
  update_omnichannel_pipeline?: Maybe<Omnichannel_Pipeline_Mutation_Response>;
  /** update single row of the table: "omnichannel.pipeline" */
  update_omnichannel_pipeline_by_pk?: Maybe<Omnichannel_Pipeline>;
  /** update data of the table: "omnichannel.pipeline_card" */
  update_omnichannel_pipeline_card?: Maybe<Omnichannel_Pipeline_Card_Mutation_Response>;
  /** update single row of the table: "omnichannel.pipeline_card" */
  update_omnichannel_pipeline_card_by_pk?: Maybe<Omnichannel_Pipeline_Card>;
  /** update data of the table: "omnichannel.pipeline_comments" */
  update_omnichannel_pipeline_comments?: Maybe<Omnichannel_Pipeline_Comments_Mutation_Response>;
  /** update single row of the table: "omnichannel.pipeline_comments" */
  update_omnichannel_pipeline_comments_by_pk?: Maybe<Omnichannel_Pipeline_Comments>;
  /** update data of the table: "omnichannel.pipeline_list" */
  update_omnichannel_pipeline_list?: Maybe<Omnichannel_Pipeline_List_Mutation_Response>;
  /** update data of the table: "omnichannel.pipeline_list_actions" */
  update_omnichannel_pipeline_list_actions?: Maybe<Omnichannel_Pipeline_List_Actions_Mutation_Response>;
  /** update single row of the table: "omnichannel.pipeline_list_actions" */
  update_omnichannel_pipeline_list_actions_by_pk?: Maybe<Omnichannel_Pipeline_List_Actions>;
  /** update single row of the table: "omnichannel.pipeline_list" */
  update_omnichannel_pipeline_list_by_pk?: Maybe<Omnichannel_Pipeline_List>;
  /** update data of the table: "omnichannel.pipeline_products" */
  update_omnichannel_pipeline_products?: Maybe<Omnichannel_Pipeline_Products_Mutation_Response>;
  /** update single row of the table: "omnichannel.pipeline_products" */
  update_omnichannel_pipeline_products_by_pk?: Maybe<Omnichannel_Pipeline_Products>;
  /** update data of the table: "omnichannel.pipeline_status" */
  update_omnichannel_pipeline_status?: Maybe<Omnichannel_Pipeline_Status_Mutation_Response>;
  /** update single row of the table: "omnichannel.pipeline_status" */
  update_omnichannel_pipeline_status_by_pk?: Maybe<Omnichannel_Pipeline_Status>;
  /** update data of the table: "omnichannel.pipeline_tags" */
  update_omnichannel_pipeline_tags?: Maybe<Omnichannel_Pipeline_Tags_Mutation_Response>;
  /** update single row of the table: "omnichannel.pipeline_tags" */
  update_omnichannel_pipeline_tags_by_pk?: Maybe<Omnichannel_Pipeline_Tags>;
  /** update data of the table: "omnichannel.products" */
  update_omnichannel_products?: Maybe<Omnichannel_Products_Mutation_Response>;
  /** update single row of the table: "omnichannel.products" */
  update_omnichannel_products_by_pk?: Maybe<Omnichannel_Products>;
  /** update data of the table: "omnichannel.tags" */
  update_omnichannel_tags?: Maybe<Omnichannel_Tags_Mutation_Response>;
  /** update single row of the table: "omnichannel.tags" */
  update_omnichannel_tags_by_pk?: Maybe<Omnichannel_Tags>;
  /** update data of the table: "omnichannel.vw_activities_group" */
  update_omnichannel_vw_activities_group?: Maybe<Omnichannel_Vw_Activities_Group_Mutation_Response>;
  /** update data of the table: "organizations" */
  update_organizations?: Maybe<Organizations_Mutation_Response>;
  /** update single row of the table: "organizations" */
  update_organizations_by_pk?: Maybe<Organizations>;
  /** update data of the table: "permissions" */
  update_permissions?: Maybe<Permissions_Mutation_Response>;
  /** update single row of the table: "permissions" */
  update_permissions_by_pk?: Maybe<Permissions>;
  /** update data of the table: "product" */
  update_product?: Maybe<Product_Mutation_Response>;
  /** update single row of the table: "product" */
  update_product_by_pk?: Maybe<Product>;
  /** update data of the table: "stripe_plan" */
  update_stripe_plan?: Maybe<Stripe_Plan_Mutation_Response>;
  /** update single row of the table: "stripe_plan" */
  update_stripe_plan_by_pk?: Maybe<Stripe_Plan>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  upload: UploadFile;
};


/** mutation root */
export type Mutation_RootCreate_Or_Update_AccountArgs = {
  accounts: CreateAccountInput;
};


/** mutation root */
export type Mutation_RootDeleteFileArgs = {
  input?: Maybe<DeleteFileInput>;
};


/** mutation root */
export type Mutation_RootDelete_AccountArgs = {
  accounts: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Acl_FeaturesArgs = {
  where: Acl_Features_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Acl_Features_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Acl_Features_PermissionsArgs = {
  where: Acl_Features_Permissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Acl_Features_Permissions_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ApikeysArgs = {
  where: Apikeys_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apikeys_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Account_ProvidersArgs = {
  where: Auth_Account_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Account_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Account_RolesArgs = {
  where: Auth_Account_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Account_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_AccountsArgs = {
  where: Auth_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_ProvidersArgs = {
  where: Auth_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Providers_By_PkArgs = {
  provider: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Refresh_TokensArgs = {
  where: Auth_Refresh_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Refresh_Tokens_By_PkArgs = {
  refresh_token: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_RolesArgs = {
  where: Auth_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Roles_By_PkArgs = {
  role: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CidadesArgs = {
  where: Cidades_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Cidades_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_EstadosArgs = {
  where: Estados_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Estados_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_MembershipsArgs = {
  where: Memberships_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Memberships_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_AddonsArgs = {
  where: Omnichannel_Addons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Addons_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Addons_Connect_SettingsArgs = {
  where: Omnichannel_Addons_Connect_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Addons_Connect_Settings_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Addons_ConnectsArgs = {
  where: Omnichannel_Addons_Connects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Addons_Connects_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Channel_EnabledArgs = {
  where: Omnichannel_Channel_Enabled_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Channel_Enabled_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Channel_Enabled_SettingsArgs = {
  where: Omnichannel_Channel_Enabled_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Channel_Enabled_Settings_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Channel_MessageArgs = {
  where: Omnichannel_Channel_Message_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Channel_Message_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Channel_SessionsArgs = {
  where: Omnichannel_Channel_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Channel_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_ChannelsArgs = {
  where: Omnichannel_Channels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Channels_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_GroupsArgs = {
  where: Omnichannel_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Groups_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Groups_UsersArgs = {
  where: Omnichannel_Groups_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Groups_Users_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Mailbox_Mail_AddonsArgs = {
  where: Omnichannel_Mailbox_Mail_Addons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Mailbox_Mail_Addons_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Message_Mail_AddonsArgs = {
  where: Omnichannel_Message_Mail_Addons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Message_Mail_Addons_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Person_ActivitiesArgs = {
  where: Omnichannel_Person_Activities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Person_Activities_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Person_FileArgs = {
  where: Omnichannel_Person_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Person_File_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Person_MailArgs = {
  where: Omnichannel_Person_Mail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Person_Mail_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Person_NoteArgs = {
  where: Omnichannel_Person_Note_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Person_Note_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Person_TagsArgs = {
  where: Omnichannel_Person_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Person_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Person_TypeArgs = {
  where: Omnichannel_Person_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Person_Type_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_PersonsArgs = {
  where: Omnichannel_Persons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Persons_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_PipelineArgs = {
  where: Omnichannel_Pipeline_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Pipeline_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Pipeline_CardArgs = {
  where: Omnichannel_Pipeline_Card_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Pipeline_Card_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Pipeline_CommentsArgs = {
  where: Omnichannel_Pipeline_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Pipeline_Comments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Pipeline_ListArgs = {
  where: Omnichannel_Pipeline_List_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Pipeline_List_ActionsArgs = {
  where: Omnichannel_Pipeline_List_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Pipeline_List_Actions_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Pipeline_List_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Pipeline_ProductsArgs = {
  where: Omnichannel_Pipeline_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Pipeline_Products_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Pipeline_StatusArgs = {
  where: Omnichannel_Pipeline_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Pipeline_Status_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Pipeline_TagsArgs = {
  where: Omnichannel_Pipeline_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Pipeline_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_ProductsArgs = {
  where: Omnichannel_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Products_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_TagsArgs = {
  where: Omnichannel_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Omnichannel_Vw_Activities_GroupArgs = {
  where: Omnichannel_Vw_Activities_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_OrganizationsArgs = {
  where: Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organizations_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PermissionsArgs = {
  where: Permissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Permissions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ProductArgs = {
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Stripe_PlanArgs = {
  where: Stripe_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Plan_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootEmailConfirmationArgs = {
  confirmation: Scalars['String'];
};


/** mutation root */
export type Mutation_RootForgotPasswordArgs = {
  email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_Acl_FeaturesArgs = {
  objects: Array<Acl_Features_Insert_Input>;
  on_conflict?: Maybe<Acl_Features_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Acl_Features_OneArgs = {
  object: Acl_Features_Insert_Input;
  on_conflict?: Maybe<Acl_Features_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Acl_Features_PermissionsArgs = {
  objects: Array<Acl_Features_Permissions_Insert_Input>;
  on_conflict?: Maybe<Acl_Features_Permissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Acl_Features_Permissions_OneArgs = {
  object: Acl_Features_Permissions_Insert_Input;
  on_conflict?: Maybe<Acl_Features_Permissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ApikeysArgs = {
  objects: Array<Apikeys_Insert_Input>;
  on_conflict?: Maybe<Apikeys_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apikeys_OneArgs = {
  object: Apikeys_Insert_Input;
  on_conflict?: Maybe<Apikeys_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Account_ProvidersArgs = {
  objects: Array<Auth_Account_Providers_Insert_Input>;
  on_conflict?: Maybe<Auth_Account_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Account_Providers_OneArgs = {
  object: Auth_Account_Providers_Insert_Input;
  on_conflict?: Maybe<Auth_Account_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Account_RolesArgs = {
  objects: Array<Auth_Account_Roles_Insert_Input>;
  on_conflict?: Maybe<Auth_Account_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Account_Roles_OneArgs = {
  object: Auth_Account_Roles_Insert_Input;
  on_conflict?: Maybe<Auth_Account_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_AccountsArgs = {
  objects: Array<Auth_Accounts_Insert_Input>;
  on_conflict?: Maybe<Auth_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Accounts_OneArgs = {
  object: Auth_Accounts_Insert_Input;
  on_conflict?: Maybe<Auth_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_ProvidersArgs = {
  objects: Array<Auth_Providers_Insert_Input>;
  on_conflict?: Maybe<Auth_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Providers_OneArgs = {
  object: Auth_Providers_Insert_Input;
  on_conflict?: Maybe<Auth_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Refresh_TokensArgs = {
  objects: Array<Auth_Refresh_Tokens_Insert_Input>;
  on_conflict?: Maybe<Auth_Refresh_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Refresh_Tokens_OneArgs = {
  object: Auth_Refresh_Tokens_Insert_Input;
  on_conflict?: Maybe<Auth_Refresh_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_RolesArgs = {
  objects: Array<Auth_Roles_Insert_Input>;
  on_conflict?: Maybe<Auth_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Roles_OneArgs = {
  object: Auth_Roles_Insert_Input;
  on_conflict?: Maybe<Auth_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CidadesArgs = {
  objects: Array<Cidades_Insert_Input>;
  on_conflict?: Maybe<Cidades_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cidades_OneArgs = {
  object: Cidades_Insert_Input;
  on_conflict?: Maybe<Cidades_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EstadosArgs = {
  objects: Array<Estados_Insert_Input>;
  on_conflict?: Maybe<Estados_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Estados_OneArgs = {
  object: Estados_Insert_Input;
  on_conflict?: Maybe<Estados_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MembershipsArgs = {
  objects: Array<Memberships_Insert_Input>;
  on_conflict?: Maybe<Memberships_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Memberships_OneArgs = {
  object: Memberships_Insert_Input;
  on_conflict?: Maybe<Memberships_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_AddonsArgs = {
  objects: Array<Omnichannel_Addons_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Addons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Addons_Connect_SettingsArgs = {
  objects: Array<Omnichannel_Addons_Connect_Settings_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Addons_Connect_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Addons_Connect_Settings_OneArgs = {
  object: Omnichannel_Addons_Connect_Settings_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Addons_Connect_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Addons_ConnectsArgs = {
  objects: Array<Omnichannel_Addons_Connects_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Addons_Connects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Addons_Connects_OneArgs = {
  object: Omnichannel_Addons_Connects_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Addons_Connects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Addons_OneArgs = {
  object: Omnichannel_Addons_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Addons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Channel_EnabledArgs = {
  objects: Array<Omnichannel_Channel_Enabled_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Channel_Enabled_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Channel_Enabled_OneArgs = {
  object: Omnichannel_Channel_Enabled_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Channel_Enabled_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Channel_Enabled_SettingsArgs = {
  objects: Array<Omnichannel_Channel_Enabled_Settings_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Channel_Enabled_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Channel_Enabled_Settings_OneArgs = {
  object: Omnichannel_Channel_Enabled_Settings_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Channel_Enabled_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Channel_MessageArgs = {
  objects: Array<Omnichannel_Channel_Message_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Channel_Message_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Channel_Message_OneArgs = {
  object: Omnichannel_Channel_Message_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Channel_Message_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Channel_SessionsArgs = {
  objects: Array<Omnichannel_Channel_Sessions_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Channel_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Channel_Sessions_OneArgs = {
  object: Omnichannel_Channel_Sessions_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Channel_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_ChannelsArgs = {
  objects: Array<Omnichannel_Channels_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Channels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Channels_OneArgs = {
  object: Omnichannel_Channels_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Channels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_GroupsArgs = {
  objects: Array<Omnichannel_Groups_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Groups_OneArgs = {
  object: Omnichannel_Groups_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Groups_UsersArgs = {
  objects: Array<Omnichannel_Groups_Users_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Groups_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Groups_Users_OneArgs = {
  object: Omnichannel_Groups_Users_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Groups_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Mailbox_Mail_AddonsArgs = {
  objects: Array<Omnichannel_Mailbox_Mail_Addons_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Mailbox_Mail_Addons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Mailbox_Mail_Addons_OneArgs = {
  object: Omnichannel_Mailbox_Mail_Addons_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Mailbox_Mail_Addons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Message_Mail_AddonsArgs = {
  objects: Array<Omnichannel_Message_Mail_Addons_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Message_Mail_Addons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Message_Mail_Addons_OneArgs = {
  object: Omnichannel_Message_Mail_Addons_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Message_Mail_Addons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Person_ActivitiesArgs = {
  objects: Array<Omnichannel_Person_Activities_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Person_Activities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Person_Activities_OneArgs = {
  object: Omnichannel_Person_Activities_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Person_Activities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Person_FileArgs = {
  objects: Array<Omnichannel_Person_File_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Person_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Person_File_OneArgs = {
  object: Omnichannel_Person_File_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Person_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Person_MailArgs = {
  objects: Array<Omnichannel_Person_Mail_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Person_Mail_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Person_Mail_OneArgs = {
  object: Omnichannel_Person_Mail_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Person_Mail_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Person_NoteArgs = {
  objects: Array<Omnichannel_Person_Note_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Person_Note_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Person_Note_OneArgs = {
  object: Omnichannel_Person_Note_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Person_Note_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Person_TagsArgs = {
  objects: Array<Omnichannel_Person_Tags_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Person_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Person_Tags_OneArgs = {
  object: Omnichannel_Person_Tags_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Person_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Person_TypeArgs = {
  objects: Array<Omnichannel_Person_Type_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Person_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Person_Type_OneArgs = {
  object: Omnichannel_Person_Type_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Person_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_PersonsArgs = {
  objects: Array<Omnichannel_Persons_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Persons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Persons_OneArgs = {
  object: Omnichannel_Persons_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Persons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_PipelineArgs = {
  objects: Array<Omnichannel_Pipeline_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Pipeline_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Pipeline_CardArgs = {
  objects: Array<Omnichannel_Pipeline_Card_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Pipeline_Card_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Pipeline_Card_OneArgs = {
  object: Omnichannel_Pipeline_Card_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Pipeline_Card_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Pipeline_CommentsArgs = {
  objects: Array<Omnichannel_Pipeline_Comments_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Pipeline_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Pipeline_Comments_OneArgs = {
  object: Omnichannel_Pipeline_Comments_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Pipeline_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Pipeline_ListArgs = {
  objects: Array<Omnichannel_Pipeline_List_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Pipeline_List_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Pipeline_List_ActionsArgs = {
  objects: Array<Omnichannel_Pipeline_List_Actions_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Pipeline_List_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Pipeline_List_Actions_OneArgs = {
  object: Omnichannel_Pipeline_List_Actions_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Pipeline_List_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Pipeline_List_OneArgs = {
  object: Omnichannel_Pipeline_List_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Pipeline_List_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Pipeline_OneArgs = {
  object: Omnichannel_Pipeline_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Pipeline_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Pipeline_ProductsArgs = {
  objects: Array<Omnichannel_Pipeline_Products_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Pipeline_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Pipeline_Products_OneArgs = {
  object: Omnichannel_Pipeline_Products_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Pipeline_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Pipeline_StatusArgs = {
  objects: Array<Omnichannel_Pipeline_Status_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Pipeline_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Pipeline_Status_OneArgs = {
  object: Omnichannel_Pipeline_Status_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Pipeline_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Pipeline_TagsArgs = {
  objects: Array<Omnichannel_Pipeline_Tags_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Pipeline_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Pipeline_Tags_OneArgs = {
  object: Omnichannel_Pipeline_Tags_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Pipeline_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_ProductsArgs = {
  objects: Array<Omnichannel_Products_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Products_OneArgs = {
  object: Omnichannel_Products_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_TagsArgs = {
  objects: Array<Omnichannel_Tags_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Tags_OneArgs = {
  object: Omnichannel_Tags_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Vw_Activities_GroupArgs = {
  objects: Array<Omnichannel_Vw_Activities_Group_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Omnichannel_Vw_Activities_Group_OneArgs = {
  object: Omnichannel_Vw_Activities_Group_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_OrganizationsArgs = {
  objects: Array<Organizations_Insert_Input>;
  on_conflict?: Maybe<Organizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organizations_OneArgs = {
  object: Organizations_Insert_Input;
  on_conflict?: Maybe<Organizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PermissionsArgs = {
  objects: Array<Permissions_Insert_Input>;
  on_conflict?: Maybe<Permissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Permissions_OneArgs = {
  object: Permissions_Insert_Input;
  on_conflict?: Maybe<Permissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductArgs = {
  objects: Array<Product_Insert_Input>;
  on_conflict?: Maybe<Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_OneArgs = {
  object: Product_Insert_Input;
  on_conflict?: Maybe<Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_PlanArgs = {
  objects: Array<Stripe_Plan_Insert_Input>;
  on_conflict?: Maybe<Stripe_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Plan_OneArgs = {
  object: Stripe_Plan_Insert_Input;
  on_conflict?: Maybe<Stripe_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootLoginArgs = {
  input: UsersPermissionsLoginInput;
};


/** mutation root */
export type Mutation_RootMultipleUploadArgs = {
  field?: Maybe<Scalars['String']>;
  files: Array<Maybe<Scalars['Upload']>>;
  ref?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['ID']>;
  source?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};


/** mutation root */
export type Mutation_RootReorderArgs = {
  arg1: ReorderInput;
};


/** mutation root */
export type Mutation_RootResetPasswordArgs = {
  code: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSendEmailArgs = {
  arg1: SendEmailInput;
};


/** mutation root */
export type Mutation_RootUpdateCompanyArgs = {
  input?: Maybe<UpdateCompanyInput>;
};


/** mutation root */
export type Mutation_RootUpdateFileInfoArgs = {
  id: Scalars['ID'];
  info: FileInfoInput;
};


/** mutation root */
export type Mutation_RootUpdateLoginArgs = {
  input?: Maybe<UpdateLoginInput>;
};


/** mutation root */
export type Mutation_RootUpdateMenuArgs = {
  input?: Maybe<UpdateMenuInput>;
};


/** mutation root */
export type Mutation_RootUpdateSidebarArgs = {
  input?: Maybe<UpdateSidebarInput>;
};


/** mutation root */
export type Mutation_RootUpdate_Acl_FeaturesArgs = {
  _inc?: Maybe<Acl_Features_Inc_Input>;
  _set?: Maybe<Acl_Features_Set_Input>;
  where: Acl_Features_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Acl_Features_By_PkArgs = {
  _inc?: Maybe<Acl_Features_Inc_Input>;
  _set?: Maybe<Acl_Features_Set_Input>;
  pk_columns: Acl_Features_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Acl_Features_PermissionsArgs = {
  _inc?: Maybe<Acl_Features_Permissions_Inc_Input>;
  _set?: Maybe<Acl_Features_Permissions_Set_Input>;
  where: Acl_Features_Permissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Acl_Features_Permissions_By_PkArgs = {
  _inc?: Maybe<Acl_Features_Permissions_Inc_Input>;
  _set?: Maybe<Acl_Features_Permissions_Set_Input>;
  pk_columns: Acl_Features_Permissions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ApikeysArgs = {
  _inc?: Maybe<Apikeys_Inc_Input>;
  _set?: Maybe<Apikeys_Set_Input>;
  where: Apikeys_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apikeys_By_PkArgs = {
  _inc?: Maybe<Apikeys_Inc_Input>;
  _set?: Maybe<Apikeys_Set_Input>;
  pk_columns: Apikeys_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Account_ProvidersArgs = {
  _set?: Maybe<Auth_Account_Providers_Set_Input>;
  where: Auth_Account_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Account_Providers_By_PkArgs = {
  _set?: Maybe<Auth_Account_Providers_Set_Input>;
  pk_columns: Auth_Account_Providers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Account_RolesArgs = {
  _set?: Maybe<Auth_Account_Roles_Set_Input>;
  where: Auth_Account_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Account_Roles_By_PkArgs = {
  _set?: Maybe<Auth_Account_Roles_Set_Input>;
  pk_columns: Auth_Account_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_AccountsArgs = {
  _append?: Maybe<Auth_Accounts_Append_Input>;
  _delete_at_path?: Maybe<Auth_Accounts_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Auth_Accounts_Delete_Elem_Input>;
  _delete_key?: Maybe<Auth_Accounts_Delete_Key_Input>;
  _prepend?: Maybe<Auth_Accounts_Prepend_Input>;
  _set?: Maybe<Auth_Accounts_Set_Input>;
  where: Auth_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Accounts_By_PkArgs = {
  _append?: Maybe<Auth_Accounts_Append_Input>;
  _delete_at_path?: Maybe<Auth_Accounts_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Auth_Accounts_Delete_Elem_Input>;
  _delete_key?: Maybe<Auth_Accounts_Delete_Key_Input>;
  _prepend?: Maybe<Auth_Accounts_Prepend_Input>;
  _set?: Maybe<Auth_Accounts_Set_Input>;
  pk_columns: Auth_Accounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_ProvidersArgs = {
  _set?: Maybe<Auth_Providers_Set_Input>;
  where: Auth_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Providers_By_PkArgs = {
  _set?: Maybe<Auth_Providers_Set_Input>;
  pk_columns: Auth_Providers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Refresh_TokensArgs = {
  _set?: Maybe<Auth_Refresh_Tokens_Set_Input>;
  where: Auth_Refresh_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Refresh_Tokens_By_PkArgs = {
  _set?: Maybe<Auth_Refresh_Tokens_Set_Input>;
  pk_columns: Auth_Refresh_Tokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_RolesArgs = {
  _set?: Maybe<Auth_Roles_Set_Input>;
  where: Auth_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Roles_By_PkArgs = {
  _set?: Maybe<Auth_Roles_Set_Input>;
  pk_columns: Auth_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CidadesArgs = {
  _inc?: Maybe<Cidades_Inc_Input>;
  _set?: Maybe<Cidades_Set_Input>;
  where: Cidades_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Cidades_By_PkArgs = {
  _inc?: Maybe<Cidades_Inc_Input>;
  _set?: Maybe<Cidades_Set_Input>;
  pk_columns: Cidades_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EstadosArgs = {
  _inc?: Maybe<Estados_Inc_Input>;
  _set?: Maybe<Estados_Set_Input>;
  where: Estados_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Estados_By_PkArgs = {
  _inc?: Maybe<Estados_Inc_Input>;
  _set?: Maybe<Estados_Set_Input>;
  pk_columns: Estados_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MembershipsArgs = {
  _inc?: Maybe<Memberships_Inc_Input>;
  _set?: Maybe<Memberships_Set_Input>;
  where: Memberships_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Memberships_By_PkArgs = {
  _inc?: Maybe<Memberships_Inc_Input>;
  _set?: Maybe<Memberships_Set_Input>;
  pk_columns: Memberships_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_AddonsArgs = {
  _set?: Maybe<Omnichannel_Addons_Set_Input>;
  where: Omnichannel_Addons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Addons_By_PkArgs = {
  _set?: Maybe<Omnichannel_Addons_Set_Input>;
  pk_columns: Omnichannel_Addons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Addons_Connect_SettingsArgs = {
  _set?: Maybe<Omnichannel_Addons_Connect_Settings_Set_Input>;
  where: Omnichannel_Addons_Connect_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Addons_Connect_Settings_By_PkArgs = {
  _set?: Maybe<Omnichannel_Addons_Connect_Settings_Set_Input>;
  pk_columns: Omnichannel_Addons_Connect_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Addons_ConnectsArgs = {
  _inc?: Maybe<Omnichannel_Addons_Connects_Inc_Input>;
  _set?: Maybe<Omnichannel_Addons_Connects_Set_Input>;
  where: Omnichannel_Addons_Connects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Addons_Connects_By_PkArgs = {
  _inc?: Maybe<Omnichannel_Addons_Connects_Inc_Input>;
  _set?: Maybe<Omnichannel_Addons_Connects_Set_Input>;
  pk_columns: Omnichannel_Addons_Connects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Channel_EnabledArgs = {
  _inc?: Maybe<Omnichannel_Channel_Enabled_Inc_Input>;
  _set?: Maybe<Omnichannel_Channel_Enabled_Set_Input>;
  where: Omnichannel_Channel_Enabled_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Channel_Enabled_By_PkArgs = {
  _inc?: Maybe<Omnichannel_Channel_Enabled_Inc_Input>;
  _set?: Maybe<Omnichannel_Channel_Enabled_Set_Input>;
  pk_columns: Omnichannel_Channel_Enabled_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Channel_Enabled_SettingsArgs = {
  _set?: Maybe<Omnichannel_Channel_Enabled_Settings_Set_Input>;
  where: Omnichannel_Channel_Enabled_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Channel_Enabled_Settings_By_PkArgs = {
  _set?: Maybe<Omnichannel_Channel_Enabled_Settings_Set_Input>;
  pk_columns: Omnichannel_Channel_Enabled_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Channel_MessageArgs = {
  _set?: Maybe<Omnichannel_Channel_Message_Set_Input>;
  where: Omnichannel_Channel_Message_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Channel_Message_By_PkArgs = {
  _set?: Maybe<Omnichannel_Channel_Message_Set_Input>;
  pk_columns: Omnichannel_Channel_Message_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Channel_SessionsArgs = {
  _set?: Maybe<Omnichannel_Channel_Sessions_Set_Input>;
  where: Omnichannel_Channel_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Channel_Sessions_By_PkArgs = {
  _set?: Maybe<Omnichannel_Channel_Sessions_Set_Input>;
  pk_columns: Omnichannel_Channel_Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_ChannelsArgs = {
  _set?: Maybe<Omnichannel_Channels_Set_Input>;
  where: Omnichannel_Channels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Channels_By_PkArgs = {
  _set?: Maybe<Omnichannel_Channels_Set_Input>;
  pk_columns: Omnichannel_Channels_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_GroupsArgs = {
  _inc?: Maybe<Omnichannel_Groups_Inc_Input>;
  _set?: Maybe<Omnichannel_Groups_Set_Input>;
  where: Omnichannel_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Groups_By_PkArgs = {
  _inc?: Maybe<Omnichannel_Groups_Inc_Input>;
  _set?: Maybe<Omnichannel_Groups_Set_Input>;
  pk_columns: Omnichannel_Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Groups_UsersArgs = {
  _inc?: Maybe<Omnichannel_Groups_Users_Inc_Input>;
  _set?: Maybe<Omnichannel_Groups_Users_Set_Input>;
  where: Omnichannel_Groups_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Groups_Users_By_PkArgs = {
  _inc?: Maybe<Omnichannel_Groups_Users_Inc_Input>;
  _set?: Maybe<Omnichannel_Groups_Users_Set_Input>;
  pk_columns: Omnichannel_Groups_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Mailbox_Mail_AddonsArgs = {
  _set?: Maybe<Omnichannel_Mailbox_Mail_Addons_Set_Input>;
  where: Omnichannel_Mailbox_Mail_Addons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Mailbox_Mail_Addons_By_PkArgs = {
  _set?: Maybe<Omnichannel_Mailbox_Mail_Addons_Set_Input>;
  pk_columns: Omnichannel_Mailbox_Mail_Addons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Message_Mail_AddonsArgs = {
  _set?: Maybe<Omnichannel_Message_Mail_Addons_Set_Input>;
  where: Omnichannel_Message_Mail_Addons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Message_Mail_Addons_By_PkArgs = {
  _set?: Maybe<Omnichannel_Message_Mail_Addons_Set_Input>;
  pk_columns: Omnichannel_Message_Mail_Addons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Person_ActivitiesArgs = {
  _set?: Maybe<Omnichannel_Person_Activities_Set_Input>;
  where: Omnichannel_Person_Activities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Person_Activities_By_PkArgs = {
  _set?: Maybe<Omnichannel_Person_Activities_Set_Input>;
  pk_columns: Omnichannel_Person_Activities_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Person_FileArgs = {
  _set?: Maybe<Omnichannel_Person_File_Set_Input>;
  where: Omnichannel_Person_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Person_File_By_PkArgs = {
  _set?: Maybe<Omnichannel_Person_File_Set_Input>;
  pk_columns: Omnichannel_Person_File_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Person_MailArgs = {
  _set?: Maybe<Omnichannel_Person_Mail_Set_Input>;
  where: Omnichannel_Person_Mail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Person_Mail_By_PkArgs = {
  _set?: Maybe<Omnichannel_Person_Mail_Set_Input>;
  pk_columns: Omnichannel_Person_Mail_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Person_NoteArgs = {
  _set?: Maybe<Omnichannel_Person_Note_Set_Input>;
  where: Omnichannel_Person_Note_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Person_Note_By_PkArgs = {
  _set?: Maybe<Omnichannel_Person_Note_Set_Input>;
  pk_columns: Omnichannel_Person_Note_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Person_TagsArgs = {
  _set?: Maybe<Omnichannel_Person_Tags_Set_Input>;
  where: Omnichannel_Person_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Person_Tags_By_PkArgs = {
  _set?: Maybe<Omnichannel_Person_Tags_Set_Input>;
  pk_columns: Omnichannel_Person_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Person_TypeArgs = {
  _inc?: Maybe<Omnichannel_Person_Type_Inc_Input>;
  _set?: Maybe<Omnichannel_Person_Type_Set_Input>;
  where: Omnichannel_Person_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Person_Type_By_PkArgs = {
  _inc?: Maybe<Omnichannel_Person_Type_Inc_Input>;
  _set?: Maybe<Omnichannel_Person_Type_Set_Input>;
  pk_columns: Omnichannel_Person_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_PersonsArgs = {
  _inc?: Maybe<Omnichannel_Persons_Inc_Input>;
  _set?: Maybe<Omnichannel_Persons_Set_Input>;
  where: Omnichannel_Persons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Persons_By_PkArgs = {
  _inc?: Maybe<Omnichannel_Persons_Inc_Input>;
  _set?: Maybe<Omnichannel_Persons_Set_Input>;
  pk_columns: Omnichannel_Persons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_PipelineArgs = {
  _inc?: Maybe<Omnichannel_Pipeline_Inc_Input>;
  _set?: Maybe<Omnichannel_Pipeline_Set_Input>;
  where: Omnichannel_Pipeline_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Pipeline_By_PkArgs = {
  _inc?: Maybe<Omnichannel_Pipeline_Inc_Input>;
  _set?: Maybe<Omnichannel_Pipeline_Set_Input>;
  pk_columns: Omnichannel_Pipeline_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Pipeline_CardArgs = {
  _inc?: Maybe<Omnichannel_Pipeline_Card_Inc_Input>;
  _set?: Maybe<Omnichannel_Pipeline_Card_Set_Input>;
  where: Omnichannel_Pipeline_Card_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Pipeline_Card_By_PkArgs = {
  _inc?: Maybe<Omnichannel_Pipeline_Card_Inc_Input>;
  _set?: Maybe<Omnichannel_Pipeline_Card_Set_Input>;
  pk_columns: Omnichannel_Pipeline_Card_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Pipeline_CommentsArgs = {
  _set?: Maybe<Omnichannel_Pipeline_Comments_Set_Input>;
  where: Omnichannel_Pipeline_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Pipeline_Comments_By_PkArgs = {
  _set?: Maybe<Omnichannel_Pipeline_Comments_Set_Input>;
  pk_columns: Omnichannel_Pipeline_Comments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Pipeline_ListArgs = {
  _inc?: Maybe<Omnichannel_Pipeline_List_Inc_Input>;
  _set?: Maybe<Omnichannel_Pipeline_List_Set_Input>;
  where: Omnichannel_Pipeline_List_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Pipeline_List_ActionsArgs = {
  _inc?: Maybe<Omnichannel_Pipeline_List_Actions_Inc_Input>;
  _set?: Maybe<Omnichannel_Pipeline_List_Actions_Set_Input>;
  where: Omnichannel_Pipeline_List_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Pipeline_List_Actions_By_PkArgs = {
  _inc?: Maybe<Omnichannel_Pipeline_List_Actions_Inc_Input>;
  _set?: Maybe<Omnichannel_Pipeline_List_Actions_Set_Input>;
  pk_columns: Omnichannel_Pipeline_List_Actions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Pipeline_List_By_PkArgs = {
  _inc?: Maybe<Omnichannel_Pipeline_List_Inc_Input>;
  _set?: Maybe<Omnichannel_Pipeline_List_Set_Input>;
  pk_columns: Omnichannel_Pipeline_List_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Pipeline_ProductsArgs = {
  _inc?: Maybe<Omnichannel_Pipeline_Products_Inc_Input>;
  _set?: Maybe<Omnichannel_Pipeline_Products_Set_Input>;
  where: Omnichannel_Pipeline_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Pipeline_Products_By_PkArgs = {
  _inc?: Maybe<Omnichannel_Pipeline_Products_Inc_Input>;
  _set?: Maybe<Omnichannel_Pipeline_Products_Set_Input>;
  pk_columns: Omnichannel_Pipeline_Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Pipeline_StatusArgs = {
  _inc?: Maybe<Omnichannel_Pipeline_Status_Inc_Input>;
  _set?: Maybe<Omnichannel_Pipeline_Status_Set_Input>;
  where: Omnichannel_Pipeline_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Pipeline_Status_By_PkArgs = {
  _inc?: Maybe<Omnichannel_Pipeline_Status_Inc_Input>;
  _set?: Maybe<Omnichannel_Pipeline_Status_Set_Input>;
  pk_columns: Omnichannel_Pipeline_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Pipeline_TagsArgs = {
  _set?: Maybe<Omnichannel_Pipeline_Tags_Set_Input>;
  where: Omnichannel_Pipeline_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Pipeline_Tags_By_PkArgs = {
  _set?: Maybe<Omnichannel_Pipeline_Tags_Set_Input>;
  pk_columns: Omnichannel_Pipeline_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_ProductsArgs = {
  _inc?: Maybe<Omnichannel_Products_Inc_Input>;
  _set?: Maybe<Omnichannel_Products_Set_Input>;
  where: Omnichannel_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Products_By_PkArgs = {
  _inc?: Maybe<Omnichannel_Products_Inc_Input>;
  _set?: Maybe<Omnichannel_Products_Set_Input>;
  pk_columns: Omnichannel_Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_TagsArgs = {
  _inc?: Maybe<Omnichannel_Tags_Inc_Input>;
  _set?: Maybe<Omnichannel_Tags_Set_Input>;
  where: Omnichannel_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Tags_By_PkArgs = {
  _inc?: Maybe<Omnichannel_Tags_Inc_Input>;
  _set?: Maybe<Omnichannel_Tags_Set_Input>;
  pk_columns: Omnichannel_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Omnichannel_Vw_Activities_GroupArgs = {
  _inc?: Maybe<Omnichannel_Vw_Activities_Group_Inc_Input>;
  _set?: Maybe<Omnichannel_Vw_Activities_Group_Set_Input>;
  where: Omnichannel_Vw_Activities_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_OrganizationsArgs = {
  _inc?: Maybe<Organizations_Inc_Input>;
  _set?: Maybe<Organizations_Set_Input>;
  where: Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_By_PkArgs = {
  _inc?: Maybe<Organizations_Inc_Input>;
  _set?: Maybe<Organizations_Set_Input>;
  pk_columns: Organizations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PermissionsArgs = {
  _inc?: Maybe<Permissions_Inc_Input>;
  _set?: Maybe<Permissions_Set_Input>;
  where: Permissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Permissions_By_PkArgs = {
  _inc?: Maybe<Permissions_Inc_Input>;
  _set?: Maybe<Permissions_Set_Input>;
  pk_columns: Permissions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProductArgs = {
  _inc?: Maybe<Product_Inc_Input>;
  _set?: Maybe<Product_Set_Input>;
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_By_PkArgs = {
  _inc?: Maybe<Product_Inc_Input>;
  _set?: Maybe<Product_Set_Input>;
  pk_columns: Product_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_PlanArgs = {
  _inc?: Maybe<Stripe_Plan_Inc_Input>;
  _set?: Maybe<Stripe_Plan_Set_Input>;
  where: Stripe_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Plan_By_PkArgs = {
  _inc?: Maybe<Stripe_Plan_Inc_Input>;
  _set?: Maybe<Stripe_Plan_Set_Input>;
  pk_columns: Stripe_Plan_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _inc?: Maybe<Users_Inc_Input>;
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _inc?: Maybe<Users_Inc_Input>;
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUploadArgs = {
  field?: Maybe<Scalars['String']>;
  file: Scalars['Upload'];
  ref?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['ID']>;
  source?: Maybe<Scalars['String']>;
};


/** expression to compare columns of type numeric. All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** columns and relationships of "omnichannel.addons" */
export type Omnichannel_Addons = {
  __typename?: 'omnichannel_addons';
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  image?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "omnichannel.addons" */
export type Omnichannel_Addons_Aggregate = {
  __typename?: 'omnichannel_addons_aggregate';
  aggregate?: Maybe<Omnichannel_Addons_Aggregate_Fields>;
  nodes: Array<Omnichannel_Addons>;
};

/** aggregate fields of "omnichannel.addons" */
export type Omnichannel_Addons_Aggregate_Fields = {
  __typename?: 'omnichannel_addons_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Addons_Max_Fields>;
  min?: Maybe<Omnichannel_Addons_Min_Fields>;
};


/** aggregate fields of "omnichannel.addons" */
export type Omnichannel_Addons_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Addons_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.addons" */
export type Omnichannel_Addons_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Addons_Max_Order_By>;
  min?: Maybe<Omnichannel_Addons_Min_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.addons" */
export type Omnichannel_Addons_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Addons_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Addons_On_Conflict>;
};

/** Boolean expression to filter rows from the table "omnichannel.addons". All fields are combined with a logical 'AND'. */
export type Omnichannel_Addons_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Addons_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Addons_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Addons_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  image?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "omnichannel.addons_connect_settings" */
export type Omnichannel_Addons_Connect_Settings = {
  __typename?: 'omnichannel_addons_connect_settings';
  /** An object relationship */
  addons_connect?: Maybe<Omnichannel_Addons_Connects>;
  addons_connect_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  value: Scalars['String'];
};

/** aggregated selection of "omnichannel.addons_connect_settings" */
export type Omnichannel_Addons_Connect_Settings_Aggregate = {
  __typename?: 'omnichannel_addons_connect_settings_aggregate';
  aggregate?: Maybe<Omnichannel_Addons_Connect_Settings_Aggregate_Fields>;
  nodes: Array<Omnichannel_Addons_Connect_Settings>;
};

/** aggregate fields of "omnichannel.addons_connect_settings" */
export type Omnichannel_Addons_Connect_Settings_Aggregate_Fields = {
  __typename?: 'omnichannel_addons_connect_settings_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Addons_Connect_Settings_Max_Fields>;
  min?: Maybe<Omnichannel_Addons_Connect_Settings_Min_Fields>;
};


/** aggregate fields of "omnichannel.addons_connect_settings" */
export type Omnichannel_Addons_Connect_Settings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Addons_Connect_Settings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.addons_connect_settings" */
export type Omnichannel_Addons_Connect_Settings_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Addons_Connect_Settings_Max_Order_By>;
  min?: Maybe<Omnichannel_Addons_Connect_Settings_Min_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.addons_connect_settings" */
export type Omnichannel_Addons_Connect_Settings_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Addons_Connect_Settings_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Addons_Connect_Settings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "omnichannel.addons_connect_settings". All fields are combined with a logical 'AND'. */
export type Omnichannel_Addons_Connect_Settings_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Addons_Connect_Settings_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Addons_Connect_Settings_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Addons_Connect_Settings_Bool_Exp>>>;
  addons_connect?: Maybe<Omnichannel_Addons_Connects_Bool_Exp>;
  addons_connect_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.addons_connect_settings" */
export enum Omnichannel_Addons_Connect_Settings_Constraint {
  /** unique or primary key constraint */
  AddonsConnectSettingsNameAddonsConnectIdKey = 'addons_connect_settings_name_addons_connect_id_key',
  /** unique or primary key constraint */
  AddonsConnectSettingsPkey = 'addons_connect_settings_pkey'
}

/** input type for inserting data into table "omnichannel.addons_connect_settings" */
export type Omnichannel_Addons_Connect_Settings_Insert_Input = {
  addons_connect?: Maybe<Omnichannel_Addons_Connects_Obj_Rel_Insert_Input>;
  addons_connect_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Omnichannel_Addons_Connect_Settings_Max_Fields = {
  __typename?: 'omnichannel_addons_connect_settings_max_fields';
  addons_connect_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "omnichannel.addons_connect_settings" */
export type Omnichannel_Addons_Connect_Settings_Max_Order_By = {
  addons_connect_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Addons_Connect_Settings_Min_Fields = {
  __typename?: 'omnichannel_addons_connect_settings_min_fields';
  addons_connect_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "omnichannel.addons_connect_settings" */
export type Omnichannel_Addons_Connect_Settings_Min_Order_By = {
  addons_connect_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.addons_connect_settings" */
export type Omnichannel_Addons_Connect_Settings_Mutation_Response = {
  __typename?: 'omnichannel_addons_connect_settings_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Addons_Connect_Settings>;
};

/** input type for inserting object relation for remote table "omnichannel.addons_connect_settings" */
export type Omnichannel_Addons_Connect_Settings_Obj_Rel_Insert_Input = {
  data: Omnichannel_Addons_Connect_Settings_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Addons_Connect_Settings_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.addons_connect_settings" */
export type Omnichannel_Addons_Connect_Settings_On_Conflict = {
  constraint: Omnichannel_Addons_Connect_Settings_Constraint;
  update_columns: Array<Omnichannel_Addons_Connect_Settings_Update_Column>;
  where?: Maybe<Omnichannel_Addons_Connect_Settings_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.addons_connect_settings" */
export type Omnichannel_Addons_Connect_Settings_Order_By = {
  addons_connect?: Maybe<Omnichannel_Addons_Connects_Order_By>;
  addons_connect_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.addons_connect_settings" */
export type Omnichannel_Addons_Connect_Settings_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "omnichannel.addons_connect_settings" */
export enum Omnichannel_Addons_Connect_Settings_Select_Column {
  /** column name */
  AddonsConnectId = 'addons_connect_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "omnichannel.addons_connect_settings" */
export type Omnichannel_Addons_Connect_Settings_Set_Input = {
  addons_connect_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "omnichannel.addons_connect_settings" */
export enum Omnichannel_Addons_Connect_Settings_Update_Column {
  /** column name */
  AddonsConnectId = 'addons_connect_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects = {
  __typename?: 'omnichannel_addons_connects';
  /** An object relationship */
  addons?: Maybe<Omnichannel_Addons>;
  addons_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  organization_id: Scalars['Int'];
  /** An array relationship */
  permissions: Array<Memberships>;
  /** An aggregated array relationship */
  permissions_aggregate: Memberships_Aggregate;
  /** An array relationship */
  settings: Array<Omnichannel_Addons_Connect_Settings>;
  /** An aggregated array relationship */
  settings_aggregate: Omnichannel_Addons_Connect_Settings_Aggregate;
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "omnichannel.addons_connects" */
export type Omnichannel_Addons_ConnectsPermissionsArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};


/** columns and relationships of "omnichannel.addons_connects" */
export type Omnichannel_Addons_ConnectsPermissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};


/** columns and relationships of "omnichannel.addons_connects" */
export type Omnichannel_Addons_ConnectsSettingsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Addons_Connect_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Addons_Connect_Settings_Order_By>>;
  where?: Maybe<Omnichannel_Addons_Connect_Settings_Bool_Exp>;
};


/** columns and relationships of "omnichannel.addons_connects" */
export type Omnichannel_Addons_ConnectsSettings_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Addons_Connect_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Addons_Connect_Settings_Order_By>>;
  where?: Maybe<Omnichannel_Addons_Connect_Settings_Bool_Exp>;
};

/** aggregated selection of "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects_Aggregate = {
  __typename?: 'omnichannel_addons_connects_aggregate';
  aggregate?: Maybe<Omnichannel_Addons_Connects_Aggregate_Fields>;
  nodes: Array<Omnichannel_Addons_Connects>;
};

/** aggregate fields of "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects_Aggregate_Fields = {
  __typename?: 'omnichannel_addons_connects_aggregate_fields';
  avg?: Maybe<Omnichannel_Addons_Connects_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Addons_Connects_Max_Fields>;
  min?: Maybe<Omnichannel_Addons_Connects_Min_Fields>;
  stddev?: Maybe<Omnichannel_Addons_Connects_Stddev_Fields>;
  stddev_pop?: Maybe<Omnichannel_Addons_Connects_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Omnichannel_Addons_Connects_Stddev_Samp_Fields>;
  sum?: Maybe<Omnichannel_Addons_Connects_Sum_Fields>;
  var_pop?: Maybe<Omnichannel_Addons_Connects_Var_Pop_Fields>;
  var_samp?: Maybe<Omnichannel_Addons_Connects_Var_Samp_Fields>;
  variance?: Maybe<Omnichannel_Addons_Connects_Variance_Fields>;
};


/** aggregate fields of "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Addons_Connects_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects_Aggregate_Order_By = {
  avg?: Maybe<Omnichannel_Addons_Connects_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Addons_Connects_Max_Order_By>;
  min?: Maybe<Omnichannel_Addons_Connects_Min_Order_By>;
  stddev?: Maybe<Omnichannel_Addons_Connects_Stddev_Order_By>;
  stddev_pop?: Maybe<Omnichannel_Addons_Connects_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Omnichannel_Addons_Connects_Stddev_Samp_Order_By>;
  sum?: Maybe<Omnichannel_Addons_Connects_Sum_Order_By>;
  var_pop?: Maybe<Omnichannel_Addons_Connects_Var_Pop_Order_By>;
  var_samp?: Maybe<Omnichannel_Addons_Connects_Var_Samp_Order_By>;
  variance?: Maybe<Omnichannel_Addons_Connects_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Addons_Connects_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Addons_Connects_On_Conflict>;
};

/** aggregate avg on columns */
export type Omnichannel_Addons_Connects_Avg_Fields = {
  __typename?: 'omnichannel_addons_connects_avg_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects_Avg_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "omnichannel.addons_connects". All fields are combined with a logical 'AND'. */
export type Omnichannel_Addons_Connects_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Addons_Connects_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Addons_Connects_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Addons_Connects_Bool_Exp>>>;
  addons?: Maybe<Omnichannel_Addons_Bool_Exp>;
  addons_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  organization_id?: Maybe<Int_Comparison_Exp>;
  permissions?: Maybe<Memberships_Bool_Exp>;
  settings?: Maybe<Omnichannel_Addons_Connect_Settings_Bool_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.addons_connects" */
export enum Omnichannel_Addons_Connects_Constraint {
  /** unique or primary key constraint */
  AddonsConnectPkey = 'addons_connect_pkey',
  /** unique or primary key constraint */
  AddonsConnectsOrganizationIdAddonsIdKey = 'addons_connects_organization_id_addons_id_key'
}

/** input type for incrementing integer column in table "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects_Inc_Input = {
  organization_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects_Insert_Input = {
  addons?: Maybe<Omnichannel_Addons_Obj_Rel_Insert_Input>;
  addons_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['Int']>;
  permissions?: Maybe<Memberships_Arr_Rel_Insert_Input>;
  settings?: Maybe<Omnichannel_Addons_Connect_Settings_Arr_Rel_Insert_Input>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Omnichannel_Addons_Connects_Max_Fields = {
  __typename?: 'omnichannel_addons_connects_max_fields';
  addons_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects_Max_Order_By = {
  addons_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Addons_Connects_Min_Fields = {
  __typename?: 'omnichannel_addons_connects_min_fields';
  addons_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects_Min_Order_By = {
  addons_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects_Mutation_Response = {
  __typename?: 'omnichannel_addons_connects_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Addons_Connects>;
};

/** input type for inserting object relation for remote table "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects_Obj_Rel_Insert_Input = {
  data: Omnichannel_Addons_Connects_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Addons_Connects_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects_On_Conflict = {
  constraint: Omnichannel_Addons_Connects_Constraint;
  update_columns: Array<Omnichannel_Addons_Connects_Update_Column>;
  where?: Maybe<Omnichannel_Addons_Connects_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects_Order_By = {
  addons?: Maybe<Omnichannel_Addons_Order_By>;
  addons_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  permissions_aggregate?: Maybe<Memberships_Aggregate_Order_By>;
  settings_aggregate?: Maybe<Omnichannel_Addons_Connect_Settings_Aggregate_Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "omnichannel.addons_connects" */
export enum Omnichannel_Addons_Connects_Select_Column {
  /** column name */
  AddonsId = 'addons_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects_Set_Input = {
  addons_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Omnichannel_Addons_Connects_Stddev_Fields = {
  __typename?: 'omnichannel_addons_connects_stddev_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects_Stddev_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Omnichannel_Addons_Connects_Stddev_Pop_Fields = {
  __typename?: 'omnichannel_addons_connects_stddev_pop_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects_Stddev_Pop_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Omnichannel_Addons_Connects_Stddev_Samp_Fields = {
  __typename?: 'omnichannel_addons_connects_stddev_samp_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects_Stddev_Samp_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Omnichannel_Addons_Connects_Sum_Fields = {
  __typename?: 'omnichannel_addons_connects_sum_fields';
  organization_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects_Sum_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** update columns of table "omnichannel.addons_connects" */
export enum Omnichannel_Addons_Connects_Update_Column {
  /** column name */
  AddonsId = 'addons_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Omnichannel_Addons_Connects_Var_Pop_Fields = {
  __typename?: 'omnichannel_addons_connects_var_pop_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects_Var_Pop_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Omnichannel_Addons_Connects_Var_Samp_Fields = {
  __typename?: 'omnichannel_addons_connects_var_samp_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects_Var_Samp_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Omnichannel_Addons_Connects_Variance_Fields = {
  __typename?: 'omnichannel_addons_connects_variance_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "omnichannel.addons_connects" */
export type Omnichannel_Addons_Connects_Variance_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** unique or primary key constraints on table "omnichannel.addons" */
export enum Omnichannel_Addons_Constraint {
  /** unique or primary key constraint */
  AddonsPkey = 'addons_pkey'
}

/** input type for inserting data into table "omnichannel.addons" */
export type Omnichannel_Addons_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Omnichannel_Addons_Max_Fields = {
  __typename?: 'omnichannel_addons_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "omnichannel.addons" */
export type Omnichannel_Addons_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Addons_Min_Fields = {
  __typename?: 'omnichannel_addons_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "omnichannel.addons" */
export type Omnichannel_Addons_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.addons" */
export type Omnichannel_Addons_Mutation_Response = {
  __typename?: 'omnichannel_addons_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Addons>;
};

/** input type for inserting object relation for remote table "omnichannel.addons" */
export type Omnichannel_Addons_Obj_Rel_Insert_Input = {
  data: Omnichannel_Addons_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Addons_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.addons" */
export type Omnichannel_Addons_On_Conflict = {
  constraint: Omnichannel_Addons_Constraint;
  update_columns: Array<Omnichannel_Addons_Update_Column>;
  where?: Maybe<Omnichannel_Addons_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.addons" */
export type Omnichannel_Addons_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.addons" */
export type Omnichannel_Addons_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "omnichannel.addons" */
export enum Omnichannel_Addons_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "omnichannel.addons" */
export type Omnichannel_Addons_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "omnichannel.addons" */
export enum Omnichannel_Addons_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled = {
  __typename?: 'omnichannel_channel_enabled';
  active: Scalars['Boolean'];
  /** An object relationship */
  channel: Omnichannel_Channels;
  channel_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['Int'];
  /** An array relationship */
  permissions: Array<Memberships>;
  /** An aggregated array relationship */
  permissions_aggregate: Memberships_Aggregate;
  /** An array relationship */
  settings: Array<Omnichannel_Channel_Enabled_Settings>;
  /** An aggregated array relationship */
  settings_aggregate: Omnichannel_Channel_Enabled_Settings_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "omnichannel.channel_enabled" */
export type Omnichannel_Channel_EnabledPermissionsArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};


/** columns and relationships of "omnichannel.channel_enabled" */
export type Omnichannel_Channel_EnabledPermissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};


/** columns and relationships of "omnichannel.channel_enabled" */
export type Omnichannel_Channel_EnabledSettingsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channel_Enabled_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channel_Enabled_Settings_Order_By>>;
  where?: Maybe<Omnichannel_Channel_Enabled_Settings_Bool_Exp>;
};


/** columns and relationships of "omnichannel.channel_enabled" */
export type Omnichannel_Channel_EnabledSettings_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channel_Enabled_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channel_Enabled_Settings_Order_By>>;
  where?: Maybe<Omnichannel_Channel_Enabled_Settings_Bool_Exp>;
};

/** aggregated selection of "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled_Aggregate = {
  __typename?: 'omnichannel_channel_enabled_aggregate';
  aggregate?: Maybe<Omnichannel_Channel_Enabled_Aggregate_Fields>;
  nodes: Array<Omnichannel_Channel_Enabled>;
};

/** aggregate fields of "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled_Aggregate_Fields = {
  __typename?: 'omnichannel_channel_enabled_aggregate_fields';
  avg?: Maybe<Omnichannel_Channel_Enabled_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Channel_Enabled_Max_Fields>;
  min?: Maybe<Omnichannel_Channel_Enabled_Min_Fields>;
  stddev?: Maybe<Omnichannel_Channel_Enabled_Stddev_Fields>;
  stddev_pop?: Maybe<Omnichannel_Channel_Enabled_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Omnichannel_Channel_Enabled_Stddev_Samp_Fields>;
  sum?: Maybe<Omnichannel_Channel_Enabled_Sum_Fields>;
  var_pop?: Maybe<Omnichannel_Channel_Enabled_Var_Pop_Fields>;
  var_samp?: Maybe<Omnichannel_Channel_Enabled_Var_Samp_Fields>;
  variance?: Maybe<Omnichannel_Channel_Enabled_Variance_Fields>;
};


/** aggregate fields of "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Channel_Enabled_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled_Aggregate_Order_By = {
  avg?: Maybe<Omnichannel_Channel_Enabled_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Channel_Enabled_Max_Order_By>;
  min?: Maybe<Omnichannel_Channel_Enabled_Min_Order_By>;
  stddev?: Maybe<Omnichannel_Channel_Enabled_Stddev_Order_By>;
  stddev_pop?: Maybe<Omnichannel_Channel_Enabled_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Omnichannel_Channel_Enabled_Stddev_Samp_Order_By>;
  sum?: Maybe<Omnichannel_Channel_Enabled_Sum_Order_By>;
  var_pop?: Maybe<Omnichannel_Channel_Enabled_Var_Pop_Order_By>;
  var_samp?: Maybe<Omnichannel_Channel_Enabled_Var_Samp_Order_By>;
  variance?: Maybe<Omnichannel_Channel_Enabled_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Channel_Enabled_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Channel_Enabled_On_Conflict>;
};

/** aggregate avg on columns */
export type Omnichannel_Channel_Enabled_Avg_Fields = {
  __typename?: 'omnichannel_channel_enabled_avg_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled_Avg_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "omnichannel.channel_enabled". All fields are combined with a logical 'AND'. */
export type Omnichannel_Channel_Enabled_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Channel_Enabled_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Channel_Enabled_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Channel_Enabled_Bool_Exp>>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  channel?: Maybe<Omnichannel_Channels_Bool_Exp>;
  channel_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  organization?: Maybe<Organizations_Bool_Exp>;
  organization_id?: Maybe<Int_Comparison_Exp>;
  permissions?: Maybe<Memberships_Bool_Exp>;
  settings?: Maybe<Omnichannel_Channel_Enabled_Settings_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.channel_enabled" */
export enum Omnichannel_Channel_Enabled_Constraint {
  /** unique or primary key constraint */
  ChannelEnabledChannelIdOrganizationIdKey = 'channel_enabled_channel_id_organization_id_key',
  /** unique or primary key constraint */
  ChannelEnabledPkey = 'channel_enabled_pkey'
}

/** input type for incrementing integer column in table "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled_Inc_Input = {
  organization_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<Omnichannel_Channels_Obj_Rel_Insert_Input>;
  channel_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization?: Maybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: Maybe<Scalars['Int']>;
  permissions?: Maybe<Memberships_Arr_Rel_Insert_Input>;
  settings?: Maybe<Omnichannel_Channel_Enabled_Settings_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Omnichannel_Channel_Enabled_Max_Fields = {
  __typename?: 'omnichannel_channel_enabled_max_fields';
  channel_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled_Max_Order_By = {
  channel_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Channel_Enabled_Min_Fields = {
  __typename?: 'omnichannel_channel_enabled_min_fields';
  channel_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled_Min_Order_By = {
  channel_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled_Mutation_Response = {
  __typename?: 'omnichannel_channel_enabled_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Channel_Enabled>;
};

/** input type for inserting object relation for remote table "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled_Obj_Rel_Insert_Input = {
  data: Omnichannel_Channel_Enabled_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Channel_Enabled_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled_On_Conflict = {
  constraint: Omnichannel_Channel_Enabled_Constraint;
  update_columns: Array<Omnichannel_Channel_Enabled_Update_Column>;
  where?: Maybe<Omnichannel_Channel_Enabled_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled_Order_By = {
  active?: Maybe<Order_By>;
  channel?: Maybe<Omnichannel_Channels_Order_By>;
  channel_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization?: Maybe<Organizations_Order_By>;
  organization_id?: Maybe<Order_By>;
  permissions_aggregate?: Maybe<Memberships_Aggregate_Order_By>;
  settings_aggregate?: Maybe<Omnichannel_Channel_Enabled_Settings_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "omnichannel.channel_enabled" */
export enum Omnichannel_Channel_Enabled_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  channel_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "omnichannel.channel_enabled_settings" */
export type Omnichannel_Channel_Enabled_Settings = {
  __typename?: 'omnichannel_channel_enabled_settings';
  /** An object relationship */
  channel_enabled: Omnichannel_Channel_Enabled;
  channel_enabled_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  value: Scalars['String'];
};

/** aggregated selection of "omnichannel.channel_enabled_settings" */
export type Omnichannel_Channel_Enabled_Settings_Aggregate = {
  __typename?: 'omnichannel_channel_enabled_settings_aggregate';
  aggregate?: Maybe<Omnichannel_Channel_Enabled_Settings_Aggregate_Fields>;
  nodes: Array<Omnichannel_Channel_Enabled_Settings>;
};

/** aggregate fields of "omnichannel.channel_enabled_settings" */
export type Omnichannel_Channel_Enabled_Settings_Aggregate_Fields = {
  __typename?: 'omnichannel_channel_enabled_settings_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Channel_Enabled_Settings_Max_Fields>;
  min?: Maybe<Omnichannel_Channel_Enabled_Settings_Min_Fields>;
};


/** aggregate fields of "omnichannel.channel_enabled_settings" */
export type Omnichannel_Channel_Enabled_Settings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Channel_Enabled_Settings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.channel_enabled_settings" */
export type Omnichannel_Channel_Enabled_Settings_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Channel_Enabled_Settings_Max_Order_By>;
  min?: Maybe<Omnichannel_Channel_Enabled_Settings_Min_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.channel_enabled_settings" */
export type Omnichannel_Channel_Enabled_Settings_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Channel_Enabled_Settings_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Channel_Enabled_Settings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "omnichannel.channel_enabled_settings". All fields are combined with a logical 'AND'. */
export type Omnichannel_Channel_Enabled_Settings_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Channel_Enabled_Settings_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Channel_Enabled_Settings_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Channel_Enabled_Settings_Bool_Exp>>>;
  channel_enabled?: Maybe<Omnichannel_Channel_Enabled_Bool_Exp>;
  channel_enabled_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.channel_enabled_settings" */
export enum Omnichannel_Channel_Enabled_Settings_Constraint {
  /** unique or primary key constraint */
  ChannelSettingsChannelEnabledIdNameKey = 'channel_settings_channel_enabled_id_name_key',
  /** unique or primary key constraint */
  ChannelSettingsPkey = 'channel_settings_pkey'
}

/** input type for inserting data into table "omnichannel.channel_enabled_settings" */
export type Omnichannel_Channel_Enabled_Settings_Insert_Input = {
  channel_enabled?: Maybe<Omnichannel_Channel_Enabled_Obj_Rel_Insert_Input>;
  channel_enabled_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Omnichannel_Channel_Enabled_Settings_Max_Fields = {
  __typename?: 'omnichannel_channel_enabled_settings_max_fields';
  channel_enabled_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "omnichannel.channel_enabled_settings" */
export type Omnichannel_Channel_Enabled_Settings_Max_Order_By = {
  channel_enabled_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Channel_Enabled_Settings_Min_Fields = {
  __typename?: 'omnichannel_channel_enabled_settings_min_fields';
  channel_enabled_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "omnichannel.channel_enabled_settings" */
export type Omnichannel_Channel_Enabled_Settings_Min_Order_By = {
  channel_enabled_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.channel_enabled_settings" */
export type Omnichannel_Channel_Enabled_Settings_Mutation_Response = {
  __typename?: 'omnichannel_channel_enabled_settings_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Channel_Enabled_Settings>;
};

/** input type for inserting object relation for remote table "omnichannel.channel_enabled_settings" */
export type Omnichannel_Channel_Enabled_Settings_Obj_Rel_Insert_Input = {
  data: Omnichannel_Channel_Enabled_Settings_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Channel_Enabled_Settings_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.channel_enabled_settings" */
export type Omnichannel_Channel_Enabled_Settings_On_Conflict = {
  constraint: Omnichannel_Channel_Enabled_Settings_Constraint;
  update_columns: Array<Omnichannel_Channel_Enabled_Settings_Update_Column>;
  where?: Maybe<Omnichannel_Channel_Enabled_Settings_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.channel_enabled_settings" */
export type Omnichannel_Channel_Enabled_Settings_Order_By = {
  channel_enabled?: Maybe<Omnichannel_Channel_Enabled_Order_By>;
  channel_enabled_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.channel_enabled_settings" */
export type Omnichannel_Channel_Enabled_Settings_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "omnichannel.channel_enabled_settings" */
export enum Omnichannel_Channel_Enabled_Settings_Select_Column {
  /** column name */
  ChannelEnabledId = 'channel_enabled_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "omnichannel.channel_enabled_settings" */
export type Omnichannel_Channel_Enabled_Settings_Set_Input = {
  channel_enabled_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "omnichannel.channel_enabled_settings" */
export enum Omnichannel_Channel_Enabled_Settings_Update_Column {
  /** column name */
  ChannelEnabledId = 'channel_enabled_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type Omnichannel_Channel_Enabled_Stddev_Fields = {
  __typename?: 'omnichannel_channel_enabled_stddev_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled_Stddev_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Omnichannel_Channel_Enabled_Stddev_Pop_Fields = {
  __typename?: 'omnichannel_channel_enabled_stddev_pop_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled_Stddev_Pop_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Omnichannel_Channel_Enabled_Stddev_Samp_Fields = {
  __typename?: 'omnichannel_channel_enabled_stddev_samp_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled_Stddev_Samp_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Omnichannel_Channel_Enabled_Sum_Fields = {
  __typename?: 'omnichannel_channel_enabled_sum_fields';
  organization_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled_Sum_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** update columns of table "omnichannel.channel_enabled" */
export enum Omnichannel_Channel_Enabled_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Omnichannel_Channel_Enabled_Var_Pop_Fields = {
  __typename?: 'omnichannel_channel_enabled_var_pop_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled_Var_Pop_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Omnichannel_Channel_Enabled_Var_Samp_Fields = {
  __typename?: 'omnichannel_channel_enabled_var_samp_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled_Var_Samp_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Omnichannel_Channel_Enabled_Variance_Fields = {
  __typename?: 'omnichannel_channel_enabled_variance_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "omnichannel.channel_enabled" */
export type Omnichannel_Channel_Enabled_Variance_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** columns and relationships of "omnichannel.channel_message" */
export type Omnichannel_Channel_Message = {
  __typename?: 'omnichannel_channel_message';
  channel_enabled_id: Scalars['uuid'];
  /** An object relationship */
  channel_session?: Maybe<Omnichannel_Channel_Sessions>;
  chat_id?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  message: Scalars['String'];
  read: Scalars['Boolean'];
  receive: Scalars['Boolean'];
  session_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "omnichannel.channel_message" */
export type Omnichannel_Channel_Message_Aggregate = {
  __typename?: 'omnichannel_channel_message_aggregate';
  aggregate?: Maybe<Omnichannel_Channel_Message_Aggregate_Fields>;
  nodes: Array<Omnichannel_Channel_Message>;
};

/** aggregate fields of "omnichannel.channel_message" */
export type Omnichannel_Channel_Message_Aggregate_Fields = {
  __typename?: 'omnichannel_channel_message_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Channel_Message_Max_Fields>;
  min?: Maybe<Omnichannel_Channel_Message_Min_Fields>;
};


/** aggregate fields of "omnichannel.channel_message" */
export type Omnichannel_Channel_Message_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Channel_Message_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.channel_message" */
export type Omnichannel_Channel_Message_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Channel_Message_Max_Order_By>;
  min?: Maybe<Omnichannel_Channel_Message_Min_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.channel_message" */
export type Omnichannel_Channel_Message_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Channel_Message_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Channel_Message_On_Conflict>;
};

/** Boolean expression to filter rows from the table "omnichannel.channel_message". All fields are combined with a logical 'AND'. */
export type Omnichannel_Channel_Message_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Channel_Message_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Channel_Message_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Channel_Message_Bool_Exp>>>;
  channel_enabled_id?: Maybe<Uuid_Comparison_Exp>;
  channel_session?: Maybe<Omnichannel_Channel_Sessions_Bool_Exp>;
  chat_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  read?: Maybe<Boolean_Comparison_Exp>;
  receive?: Maybe<Boolean_Comparison_Exp>;
  session_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.channel_message" */
export enum Omnichannel_Channel_Message_Constraint {
  /** unique or primary key constraint */
  ChannelMessagePkey = 'channel_message_pkey'
}

/** input type for inserting data into table "omnichannel.channel_message" */
export type Omnichannel_Channel_Message_Insert_Input = {
  channel_enabled_id?: Maybe<Scalars['uuid']>;
  channel_session?: Maybe<Omnichannel_Channel_Sessions_Obj_Rel_Insert_Input>;
  chat_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  receive?: Maybe<Scalars['Boolean']>;
  session_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Omnichannel_Channel_Message_Max_Fields = {
  __typename?: 'omnichannel_channel_message_max_fields';
  channel_enabled_id?: Maybe<Scalars['uuid']>;
  chat_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "omnichannel.channel_message" */
export type Omnichannel_Channel_Message_Max_Order_By = {
  channel_enabled_id?: Maybe<Order_By>;
  chat_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  session_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Channel_Message_Min_Fields = {
  __typename?: 'omnichannel_channel_message_min_fields';
  channel_enabled_id?: Maybe<Scalars['uuid']>;
  chat_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "omnichannel.channel_message" */
export type Omnichannel_Channel_Message_Min_Order_By = {
  channel_enabled_id?: Maybe<Order_By>;
  chat_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  session_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.channel_message" */
export type Omnichannel_Channel_Message_Mutation_Response = {
  __typename?: 'omnichannel_channel_message_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Channel_Message>;
};

/** input type for inserting object relation for remote table "omnichannel.channel_message" */
export type Omnichannel_Channel_Message_Obj_Rel_Insert_Input = {
  data: Omnichannel_Channel_Message_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Channel_Message_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.channel_message" */
export type Omnichannel_Channel_Message_On_Conflict = {
  constraint: Omnichannel_Channel_Message_Constraint;
  update_columns: Array<Omnichannel_Channel_Message_Update_Column>;
  where?: Maybe<Omnichannel_Channel_Message_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.channel_message" */
export type Omnichannel_Channel_Message_Order_By = {
  channel_enabled_id?: Maybe<Order_By>;
  channel_session?: Maybe<Omnichannel_Channel_Sessions_Order_By>;
  chat_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  read?: Maybe<Order_By>;
  receive?: Maybe<Order_By>;
  session_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.channel_message" */
export type Omnichannel_Channel_Message_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "omnichannel.channel_message" */
export enum Omnichannel_Channel_Message_Select_Column {
  /** column name */
  ChannelEnabledId = 'channel_enabled_id',
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Read = 'read',
  /** column name */
  Receive = 'receive',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "omnichannel.channel_message" */
export type Omnichannel_Channel_Message_Set_Input = {
  channel_enabled_id?: Maybe<Scalars['uuid']>;
  chat_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  receive?: Maybe<Scalars['Boolean']>;
  session_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "omnichannel.channel_message" */
export enum Omnichannel_Channel_Message_Update_Column {
  /** column name */
  ChannelEnabledId = 'channel_enabled_id',
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Read = 'read',
  /** column name */
  Receive = 'receive',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "omnichannel.channel_sessions" */
export type Omnichannel_Channel_Sessions = {
  __typename?: 'omnichannel_channel_sessions';
  /** An object relationship */
  channel_enabled: Omnichannel_Channel_Enabled;
  channel_enabled_id: Scalars['uuid'];
  /** An array relationship */
  channel_messages: Array<Omnichannel_Channel_Message>;
  /** An aggregated array relationship */
  channel_messages_aggregate: Omnichannel_Channel_Message_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  lastActivity: Scalars['timestamptz'];
  profilename: Scalars['String'];
  session_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "omnichannel.channel_sessions" */
export type Omnichannel_Channel_SessionsChannel_MessagesArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channel_Message_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channel_Message_Order_By>>;
  where?: Maybe<Omnichannel_Channel_Message_Bool_Exp>;
};


/** columns and relationships of "omnichannel.channel_sessions" */
export type Omnichannel_Channel_SessionsChannel_Messages_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channel_Message_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channel_Message_Order_By>>;
  where?: Maybe<Omnichannel_Channel_Message_Bool_Exp>;
};

/** aggregated selection of "omnichannel.channel_sessions" */
export type Omnichannel_Channel_Sessions_Aggregate = {
  __typename?: 'omnichannel_channel_sessions_aggregate';
  aggregate?: Maybe<Omnichannel_Channel_Sessions_Aggregate_Fields>;
  nodes: Array<Omnichannel_Channel_Sessions>;
};

/** aggregate fields of "omnichannel.channel_sessions" */
export type Omnichannel_Channel_Sessions_Aggregate_Fields = {
  __typename?: 'omnichannel_channel_sessions_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Channel_Sessions_Max_Fields>;
  min?: Maybe<Omnichannel_Channel_Sessions_Min_Fields>;
};


/** aggregate fields of "omnichannel.channel_sessions" */
export type Omnichannel_Channel_Sessions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Channel_Sessions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.channel_sessions" */
export type Omnichannel_Channel_Sessions_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Channel_Sessions_Max_Order_By>;
  min?: Maybe<Omnichannel_Channel_Sessions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.channel_sessions" */
export type Omnichannel_Channel_Sessions_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Channel_Sessions_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Channel_Sessions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "omnichannel.channel_sessions". All fields are combined with a logical 'AND'. */
export type Omnichannel_Channel_Sessions_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Channel_Sessions_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Channel_Sessions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Channel_Sessions_Bool_Exp>>>;
  channel_enabled?: Maybe<Omnichannel_Channel_Enabled_Bool_Exp>;
  channel_enabled_id?: Maybe<Uuid_Comparison_Exp>;
  channel_messages?: Maybe<Omnichannel_Channel_Message_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lastActivity?: Maybe<Timestamptz_Comparison_Exp>;
  profilename?: Maybe<String_Comparison_Exp>;
  session_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.channel_sessions" */
export enum Omnichannel_Channel_Sessions_Constraint {
  /** unique or primary key constraint */
  ChannelSessionsPkey = 'channel_sessions_pkey',
  /** unique or primary key constraint */
  ChannelSessionsSessionIdChannelEnabledIdKey = 'channel_sessions_session_id_channel_enabled_id_key'
}

/** input type for inserting data into table "omnichannel.channel_sessions" */
export type Omnichannel_Channel_Sessions_Insert_Input = {
  channel_enabled?: Maybe<Omnichannel_Channel_Enabled_Obj_Rel_Insert_Input>;
  channel_enabled_id?: Maybe<Scalars['uuid']>;
  channel_messages?: Maybe<Omnichannel_Channel_Message_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lastActivity?: Maybe<Scalars['timestamptz']>;
  profilename?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Omnichannel_Channel_Sessions_Max_Fields = {
  __typename?: 'omnichannel_channel_sessions_max_fields';
  channel_enabled_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lastActivity?: Maybe<Scalars['timestamptz']>;
  profilename?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "omnichannel.channel_sessions" */
export type Omnichannel_Channel_Sessions_Max_Order_By = {
  channel_enabled_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastActivity?: Maybe<Order_By>;
  profilename?: Maybe<Order_By>;
  session_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Channel_Sessions_Min_Fields = {
  __typename?: 'omnichannel_channel_sessions_min_fields';
  channel_enabled_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lastActivity?: Maybe<Scalars['timestamptz']>;
  profilename?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "omnichannel.channel_sessions" */
export type Omnichannel_Channel_Sessions_Min_Order_By = {
  channel_enabled_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastActivity?: Maybe<Order_By>;
  profilename?: Maybe<Order_By>;
  session_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.channel_sessions" */
export type Omnichannel_Channel_Sessions_Mutation_Response = {
  __typename?: 'omnichannel_channel_sessions_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Channel_Sessions>;
};

/** input type for inserting object relation for remote table "omnichannel.channel_sessions" */
export type Omnichannel_Channel_Sessions_Obj_Rel_Insert_Input = {
  data: Omnichannel_Channel_Sessions_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Channel_Sessions_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.channel_sessions" */
export type Omnichannel_Channel_Sessions_On_Conflict = {
  constraint: Omnichannel_Channel_Sessions_Constraint;
  update_columns: Array<Omnichannel_Channel_Sessions_Update_Column>;
  where?: Maybe<Omnichannel_Channel_Sessions_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.channel_sessions" */
export type Omnichannel_Channel_Sessions_Order_By = {
  channel_enabled?: Maybe<Omnichannel_Channel_Enabled_Order_By>;
  channel_enabled_id?: Maybe<Order_By>;
  channel_messages_aggregate?: Maybe<Omnichannel_Channel_Message_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastActivity?: Maybe<Order_By>;
  profilename?: Maybe<Order_By>;
  session_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.channel_sessions" */
export type Omnichannel_Channel_Sessions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "omnichannel.channel_sessions" */
export enum Omnichannel_Channel_Sessions_Select_Column {
  /** column name */
  ChannelEnabledId = 'channel_enabled_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastActivity = 'lastActivity',
  /** column name */
  Profilename = 'profilename',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "omnichannel.channel_sessions" */
export type Omnichannel_Channel_Sessions_Set_Input = {
  channel_enabled_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lastActivity?: Maybe<Scalars['timestamptz']>;
  profilename?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "omnichannel.channel_sessions" */
export enum Omnichannel_Channel_Sessions_Update_Column {
  /** column name */
  ChannelEnabledId = 'channel_enabled_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastActivity = 'lastActivity',
  /** column name */
  Profilename = 'profilename',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "omnichannel.channels" */
export type Omnichannel_Channels = {
  __typename?: 'omnichannel_channels';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  webhook: Scalars['String'];
};

/** aggregated selection of "omnichannel.channels" */
export type Omnichannel_Channels_Aggregate = {
  __typename?: 'omnichannel_channels_aggregate';
  aggregate?: Maybe<Omnichannel_Channels_Aggregate_Fields>;
  nodes: Array<Omnichannel_Channels>;
};

/** aggregate fields of "omnichannel.channels" */
export type Omnichannel_Channels_Aggregate_Fields = {
  __typename?: 'omnichannel_channels_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Channels_Max_Fields>;
  min?: Maybe<Omnichannel_Channels_Min_Fields>;
};


/** aggregate fields of "omnichannel.channels" */
export type Omnichannel_Channels_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Channels_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.channels" */
export type Omnichannel_Channels_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Channels_Max_Order_By>;
  min?: Maybe<Omnichannel_Channels_Min_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.channels" */
export type Omnichannel_Channels_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Channels_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Channels_On_Conflict>;
};

/** Boolean expression to filter rows from the table "omnichannel.channels". All fields are combined with a logical 'AND'. */
export type Omnichannel_Channels_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Channels_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Channels_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Channels_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  webhook?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.channels" */
export enum Omnichannel_Channels_Constraint {
  /** unique or primary key constraint */
  ChannelsNameKey = 'channels_name_key',
  /** unique or primary key constraint */
  ChannelsPkey = 'channels_pkey'
}

/** input type for inserting data into table "omnichannel.channels" */
export type Omnichannel_Channels_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  webhook?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Omnichannel_Channels_Max_Fields = {
  __typename?: 'omnichannel_channels_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  webhook?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "omnichannel.channels" */
export type Omnichannel_Channels_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  webhook?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Channels_Min_Fields = {
  __typename?: 'omnichannel_channels_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  webhook?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "omnichannel.channels" */
export type Omnichannel_Channels_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  webhook?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.channels" */
export type Omnichannel_Channels_Mutation_Response = {
  __typename?: 'omnichannel_channels_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Channels>;
};

/** input type for inserting object relation for remote table "omnichannel.channels" */
export type Omnichannel_Channels_Obj_Rel_Insert_Input = {
  data: Omnichannel_Channels_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Channels_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.channels" */
export type Omnichannel_Channels_On_Conflict = {
  constraint: Omnichannel_Channels_Constraint;
  update_columns: Array<Omnichannel_Channels_Update_Column>;
  where?: Maybe<Omnichannel_Channels_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.channels" */
export type Omnichannel_Channels_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  webhook?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.channels" */
export type Omnichannel_Channels_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "omnichannel.channels" */
export enum Omnichannel_Channels_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Title = 'title',
  /** column name */
  Webhook = 'webhook'
}

/** input type for updating data in table "omnichannel.channels" */
export type Omnichannel_Channels_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  webhook?: Maybe<Scalars['String']>;
};

/** update columns of table "omnichannel.channels" */
export enum Omnichannel_Channels_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Title = 'title',
  /** column name */
  Webhook = 'webhook'
}

/** columns and relationships of "omnichannel.groups" */
export type Omnichannel_Groups = {
  __typename?: 'omnichannel_groups';
  active: Scalars['Boolean'];
  code?: Maybe<Scalars['String']>;
  dadgroup?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  organization_id: Scalars['Int'];
  /** An array relationship */
  permissions: Array<Memberships>;
  /** An aggregated array relationship */
  permissions_aggregate: Memberships_Aggregate;
};


/** columns and relationships of "omnichannel.groups" */
export type Omnichannel_GroupsPermissionsArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};


/** columns and relationships of "omnichannel.groups" */
export type Omnichannel_GroupsPermissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};

/** aggregated selection of "omnichannel.groups" */
export type Omnichannel_Groups_Aggregate = {
  __typename?: 'omnichannel_groups_aggregate';
  aggregate?: Maybe<Omnichannel_Groups_Aggregate_Fields>;
  nodes: Array<Omnichannel_Groups>;
};

/** aggregate fields of "omnichannel.groups" */
export type Omnichannel_Groups_Aggregate_Fields = {
  __typename?: 'omnichannel_groups_aggregate_fields';
  avg?: Maybe<Omnichannel_Groups_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Groups_Max_Fields>;
  min?: Maybe<Omnichannel_Groups_Min_Fields>;
  stddev?: Maybe<Omnichannel_Groups_Stddev_Fields>;
  stddev_pop?: Maybe<Omnichannel_Groups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Omnichannel_Groups_Stddev_Samp_Fields>;
  sum?: Maybe<Omnichannel_Groups_Sum_Fields>;
  var_pop?: Maybe<Omnichannel_Groups_Var_Pop_Fields>;
  var_samp?: Maybe<Omnichannel_Groups_Var_Samp_Fields>;
  variance?: Maybe<Omnichannel_Groups_Variance_Fields>;
};


/** aggregate fields of "omnichannel.groups" */
export type Omnichannel_Groups_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Groups_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.groups" */
export type Omnichannel_Groups_Aggregate_Order_By = {
  avg?: Maybe<Omnichannel_Groups_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Groups_Max_Order_By>;
  min?: Maybe<Omnichannel_Groups_Min_Order_By>;
  stddev?: Maybe<Omnichannel_Groups_Stddev_Order_By>;
  stddev_pop?: Maybe<Omnichannel_Groups_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Omnichannel_Groups_Stddev_Samp_Order_By>;
  sum?: Maybe<Omnichannel_Groups_Sum_Order_By>;
  var_pop?: Maybe<Omnichannel_Groups_Var_Pop_Order_By>;
  var_samp?: Maybe<Omnichannel_Groups_Var_Samp_Order_By>;
  variance?: Maybe<Omnichannel_Groups_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.groups" */
export type Omnichannel_Groups_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Groups_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Groups_On_Conflict>;
};

/** aggregate avg on columns */
export type Omnichannel_Groups_Avg_Fields = {
  __typename?: 'omnichannel_groups_avg_fields';
  dadgroup?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "omnichannel.groups" */
export type Omnichannel_Groups_Avg_Order_By = {
  dadgroup?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "omnichannel.groups". All fields are combined with a logical 'AND'. */
export type Omnichannel_Groups_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Groups_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Groups_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Groups_Bool_Exp>>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  code?: Maybe<String_Comparison_Exp>;
  dadgroup?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  organization_id?: Maybe<Int_Comparison_Exp>;
  permissions?: Maybe<Memberships_Bool_Exp>;
};

/** unique or primary key constraints on table "omnichannel.groups" */
export enum Omnichannel_Groups_Constraint {
  /** unique or primary key constraint */
  GroupsPkey = 'groups_pkey'
}

/** input type for incrementing integer column in table "omnichannel.groups" */
export type Omnichannel_Groups_Inc_Input = {
  dadgroup?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "omnichannel.groups" */
export type Omnichannel_Groups_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  dadgroup?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  permissions?: Maybe<Memberships_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Omnichannel_Groups_Max_Fields = {
  __typename?: 'omnichannel_groups_max_fields';
  code?: Maybe<Scalars['String']>;
  dadgroup?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "omnichannel.groups" */
export type Omnichannel_Groups_Max_Order_By = {
  code?: Maybe<Order_By>;
  dadgroup?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Groups_Min_Fields = {
  __typename?: 'omnichannel_groups_min_fields';
  code?: Maybe<Scalars['String']>;
  dadgroup?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "omnichannel.groups" */
export type Omnichannel_Groups_Min_Order_By = {
  code?: Maybe<Order_By>;
  dadgroup?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.groups" */
export type Omnichannel_Groups_Mutation_Response = {
  __typename?: 'omnichannel_groups_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Groups>;
};

/** input type for inserting object relation for remote table "omnichannel.groups" */
export type Omnichannel_Groups_Obj_Rel_Insert_Input = {
  data: Omnichannel_Groups_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Groups_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.groups" */
export type Omnichannel_Groups_On_Conflict = {
  constraint: Omnichannel_Groups_Constraint;
  update_columns: Array<Omnichannel_Groups_Update_Column>;
  where?: Maybe<Omnichannel_Groups_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.groups" */
export type Omnichannel_Groups_Order_By = {
  active?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  dadgroup?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  permissions_aggregate?: Maybe<Memberships_Aggregate_Order_By>;
};

/** primary key columns input for table: "omnichannel.groups" */
export type Omnichannel_Groups_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "omnichannel.groups" */
export enum Omnichannel_Groups_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Code = 'code',
  /** column name */
  Dadgroup = 'dadgroup',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id'
}

/** input type for updating data in table "omnichannel.groups" */
export type Omnichannel_Groups_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  dadgroup?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Omnichannel_Groups_Stddev_Fields = {
  __typename?: 'omnichannel_groups_stddev_fields';
  dadgroup?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "omnichannel.groups" */
export type Omnichannel_Groups_Stddev_Order_By = {
  dadgroup?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Omnichannel_Groups_Stddev_Pop_Fields = {
  __typename?: 'omnichannel_groups_stddev_pop_fields';
  dadgroup?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "omnichannel.groups" */
export type Omnichannel_Groups_Stddev_Pop_Order_By = {
  dadgroup?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Omnichannel_Groups_Stddev_Samp_Fields = {
  __typename?: 'omnichannel_groups_stddev_samp_fields';
  dadgroup?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "omnichannel.groups" */
export type Omnichannel_Groups_Stddev_Samp_Order_By = {
  dadgroup?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Omnichannel_Groups_Sum_Fields = {
  __typename?: 'omnichannel_groups_sum_fields';
  dadgroup?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "omnichannel.groups" */
export type Omnichannel_Groups_Sum_Order_By = {
  dadgroup?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** update columns of table "omnichannel.groups" */
export enum Omnichannel_Groups_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Code = 'code',
  /** column name */
  Dadgroup = 'dadgroup',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id'
}

/** columns and relationships of "omnichannel.groups_users" */
export type Omnichannel_Groups_Users = {
  __typename?: 'omnichannel_groups_users';
  browertab?: Maybe<Scalars['Boolean']>;
  celular?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  disable_not_from?: Maybe<Scalars['Int']>;
  disable_not_to?: Maybe<Scalars['Int']>;
  disable_notification: Scalars['Boolean'];
  email_notification?: Maybe<Scalars['String']>;
  enable_celular: Scalars['Boolean'];
  enable_email: Scalars['Boolean'];
  enable_lead?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  group?: Maybe<Omnichannel_Groups>;
  group_id: Scalars['Int'];
  id: Scalars['Int'];
  language?: Maybe<Scalars['String']>;
  lead_qtd?: Maybe<Scalars['Int']>;
  /** An array relationship */
  permissions: Array<Memberships>;
  /** An aggregated array relationship */
  permissions_aggregate: Memberships_Aggregate;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id: Scalars['uuid'];
};


/** columns and relationships of "omnichannel.groups_users" */
export type Omnichannel_Groups_UsersPermissionsArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};


/** columns and relationships of "omnichannel.groups_users" */
export type Omnichannel_Groups_UsersPermissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};

/** aggregated selection of "omnichannel.groups_users" */
export type Omnichannel_Groups_Users_Aggregate = {
  __typename?: 'omnichannel_groups_users_aggregate';
  aggregate?: Maybe<Omnichannel_Groups_Users_Aggregate_Fields>;
  nodes: Array<Omnichannel_Groups_Users>;
};

/** aggregate fields of "omnichannel.groups_users" */
export type Omnichannel_Groups_Users_Aggregate_Fields = {
  __typename?: 'omnichannel_groups_users_aggregate_fields';
  avg?: Maybe<Omnichannel_Groups_Users_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Groups_Users_Max_Fields>;
  min?: Maybe<Omnichannel_Groups_Users_Min_Fields>;
  stddev?: Maybe<Omnichannel_Groups_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Omnichannel_Groups_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Omnichannel_Groups_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Omnichannel_Groups_Users_Sum_Fields>;
  var_pop?: Maybe<Omnichannel_Groups_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Omnichannel_Groups_Users_Var_Samp_Fields>;
  variance?: Maybe<Omnichannel_Groups_Users_Variance_Fields>;
};


/** aggregate fields of "omnichannel.groups_users" */
export type Omnichannel_Groups_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Groups_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.groups_users" */
export type Omnichannel_Groups_Users_Aggregate_Order_By = {
  avg?: Maybe<Omnichannel_Groups_Users_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Groups_Users_Max_Order_By>;
  min?: Maybe<Omnichannel_Groups_Users_Min_Order_By>;
  stddev?: Maybe<Omnichannel_Groups_Users_Stddev_Order_By>;
  stddev_pop?: Maybe<Omnichannel_Groups_Users_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Omnichannel_Groups_Users_Stddev_Samp_Order_By>;
  sum?: Maybe<Omnichannel_Groups_Users_Sum_Order_By>;
  var_pop?: Maybe<Omnichannel_Groups_Users_Var_Pop_Order_By>;
  var_samp?: Maybe<Omnichannel_Groups_Users_Var_Samp_Order_By>;
  variance?: Maybe<Omnichannel_Groups_Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.groups_users" */
export type Omnichannel_Groups_Users_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Groups_Users_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Groups_Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Omnichannel_Groups_Users_Avg_Fields = {
  __typename?: 'omnichannel_groups_users_avg_fields';
  disable_not_from?: Maybe<Scalars['Float']>;
  disable_not_to?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lead_qtd?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "omnichannel.groups_users" */
export type Omnichannel_Groups_Users_Avg_Order_By = {
  disable_not_from?: Maybe<Order_By>;
  disable_not_to?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead_qtd?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "omnichannel.groups_users". All fields are combined with a logical 'AND'. */
export type Omnichannel_Groups_Users_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Groups_Users_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Groups_Users_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Groups_Users_Bool_Exp>>>;
  browertab?: Maybe<Boolean_Comparison_Exp>;
  celular?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  disable_not_from?: Maybe<Int_Comparison_Exp>;
  disable_not_to?: Maybe<Int_Comparison_Exp>;
  disable_notification?: Maybe<Boolean_Comparison_Exp>;
  email_notification?: Maybe<String_Comparison_Exp>;
  enable_celular?: Maybe<Boolean_Comparison_Exp>;
  enable_email?: Maybe<Boolean_Comparison_Exp>;
  enable_lead?: Maybe<Boolean_Comparison_Exp>;
  group?: Maybe<Omnichannel_Groups_Bool_Exp>;
  group_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  lead_qtd?: Maybe<Int_Comparison_Exp>;
  permissions?: Maybe<Memberships_Bool_Exp>;
  timezone?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.groups_users" */
export enum Omnichannel_Groups_Users_Constraint {
  /** unique or primary key constraint */
  GroupsUsersPkey = 'groups_users_pkey',
  /** unique or primary key constraint */
  GroupsUsersUserIdKey = 'groups_users_user_id_key'
}

/** input type for incrementing integer column in table "omnichannel.groups_users" */
export type Omnichannel_Groups_Users_Inc_Input = {
  disable_not_from?: Maybe<Scalars['Int']>;
  disable_not_to?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lead_qtd?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "omnichannel.groups_users" */
export type Omnichannel_Groups_Users_Insert_Input = {
  browertab?: Maybe<Scalars['Boolean']>;
  celular?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  disable_not_from?: Maybe<Scalars['Int']>;
  disable_not_to?: Maybe<Scalars['Int']>;
  disable_notification?: Maybe<Scalars['Boolean']>;
  email_notification?: Maybe<Scalars['String']>;
  enable_celular?: Maybe<Scalars['Boolean']>;
  enable_email?: Maybe<Scalars['Boolean']>;
  enable_lead?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Omnichannel_Groups_Obj_Rel_Insert_Input>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  lead_qtd?: Maybe<Scalars['Int']>;
  permissions?: Maybe<Memberships_Arr_Rel_Insert_Input>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Omnichannel_Groups_Users_Max_Fields = {
  __typename?: 'omnichannel_groups_users_max_fields';
  celular?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  disable_not_from?: Maybe<Scalars['Int']>;
  disable_not_to?: Maybe<Scalars['Int']>;
  email_notification?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  lead_qtd?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "omnichannel.groups_users" */
export type Omnichannel_Groups_Users_Max_Order_By = {
  celular?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  disable_not_from?: Maybe<Order_By>;
  disable_not_to?: Maybe<Order_By>;
  email_notification?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  lead_qtd?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Groups_Users_Min_Fields = {
  __typename?: 'omnichannel_groups_users_min_fields';
  celular?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  disable_not_from?: Maybe<Scalars['Int']>;
  disable_not_to?: Maybe<Scalars['Int']>;
  email_notification?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  lead_qtd?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "omnichannel.groups_users" */
export type Omnichannel_Groups_Users_Min_Order_By = {
  celular?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  disable_not_from?: Maybe<Order_By>;
  disable_not_to?: Maybe<Order_By>;
  email_notification?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  lead_qtd?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.groups_users" */
export type Omnichannel_Groups_Users_Mutation_Response = {
  __typename?: 'omnichannel_groups_users_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Groups_Users>;
};

/** input type for inserting object relation for remote table "omnichannel.groups_users" */
export type Omnichannel_Groups_Users_Obj_Rel_Insert_Input = {
  data: Omnichannel_Groups_Users_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Groups_Users_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.groups_users" */
export type Omnichannel_Groups_Users_On_Conflict = {
  constraint: Omnichannel_Groups_Users_Constraint;
  update_columns: Array<Omnichannel_Groups_Users_Update_Column>;
  where?: Maybe<Omnichannel_Groups_Users_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.groups_users" */
export type Omnichannel_Groups_Users_Order_By = {
  browertab?: Maybe<Order_By>;
  celular?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  disable_not_from?: Maybe<Order_By>;
  disable_not_to?: Maybe<Order_By>;
  disable_notification?: Maybe<Order_By>;
  email_notification?: Maybe<Order_By>;
  enable_celular?: Maybe<Order_By>;
  enable_email?: Maybe<Order_By>;
  enable_lead?: Maybe<Order_By>;
  group?: Maybe<Omnichannel_Groups_Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  lead_qtd?: Maybe<Order_By>;
  permissions_aggregate?: Maybe<Memberships_Aggregate_Order_By>;
  timezone?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.groups_users" */
export type Omnichannel_Groups_Users_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "omnichannel.groups_users" */
export enum Omnichannel_Groups_Users_Select_Column {
  /** column name */
  Browertab = 'browertab',
  /** column name */
  Celular = 'celular',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  DisableNotFrom = 'disable_not_from',
  /** column name */
  DisableNotTo = 'disable_not_to',
  /** column name */
  DisableNotification = 'disable_notification',
  /** column name */
  EmailNotification = 'email_notification',
  /** column name */
  EnableCelular = 'enable_celular',
  /** column name */
  EnableEmail = 'enable_email',
  /** column name */
  EnableLead = 'enable_lead',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  LeadQtd = 'lead_qtd',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "omnichannel.groups_users" */
export type Omnichannel_Groups_Users_Set_Input = {
  browertab?: Maybe<Scalars['Boolean']>;
  celular?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  disable_not_from?: Maybe<Scalars['Int']>;
  disable_not_to?: Maybe<Scalars['Int']>;
  disable_notification?: Maybe<Scalars['Boolean']>;
  email_notification?: Maybe<Scalars['String']>;
  enable_celular?: Maybe<Scalars['Boolean']>;
  enable_email?: Maybe<Scalars['Boolean']>;
  enable_lead?: Maybe<Scalars['Boolean']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  lead_qtd?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Omnichannel_Groups_Users_Stddev_Fields = {
  __typename?: 'omnichannel_groups_users_stddev_fields';
  disable_not_from?: Maybe<Scalars['Float']>;
  disable_not_to?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lead_qtd?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "omnichannel.groups_users" */
export type Omnichannel_Groups_Users_Stddev_Order_By = {
  disable_not_from?: Maybe<Order_By>;
  disable_not_to?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead_qtd?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Omnichannel_Groups_Users_Stddev_Pop_Fields = {
  __typename?: 'omnichannel_groups_users_stddev_pop_fields';
  disable_not_from?: Maybe<Scalars['Float']>;
  disable_not_to?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lead_qtd?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "omnichannel.groups_users" */
export type Omnichannel_Groups_Users_Stddev_Pop_Order_By = {
  disable_not_from?: Maybe<Order_By>;
  disable_not_to?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead_qtd?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Omnichannel_Groups_Users_Stddev_Samp_Fields = {
  __typename?: 'omnichannel_groups_users_stddev_samp_fields';
  disable_not_from?: Maybe<Scalars['Float']>;
  disable_not_to?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lead_qtd?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "omnichannel.groups_users" */
export type Omnichannel_Groups_Users_Stddev_Samp_Order_By = {
  disable_not_from?: Maybe<Order_By>;
  disable_not_to?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead_qtd?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Omnichannel_Groups_Users_Sum_Fields = {
  __typename?: 'omnichannel_groups_users_sum_fields';
  disable_not_from?: Maybe<Scalars['Int']>;
  disable_not_to?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lead_qtd?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "omnichannel.groups_users" */
export type Omnichannel_Groups_Users_Sum_Order_By = {
  disable_not_from?: Maybe<Order_By>;
  disable_not_to?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead_qtd?: Maybe<Order_By>;
};

/** update columns of table "omnichannel.groups_users" */
export enum Omnichannel_Groups_Users_Update_Column {
  /** column name */
  Browertab = 'browertab',
  /** column name */
  Celular = 'celular',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  DisableNotFrom = 'disable_not_from',
  /** column name */
  DisableNotTo = 'disable_not_to',
  /** column name */
  DisableNotification = 'disable_notification',
  /** column name */
  EmailNotification = 'email_notification',
  /** column name */
  EnableCelular = 'enable_celular',
  /** column name */
  EnableEmail = 'enable_email',
  /** column name */
  EnableLead = 'enable_lead',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  LeadQtd = 'lead_qtd',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Omnichannel_Groups_Users_Var_Pop_Fields = {
  __typename?: 'omnichannel_groups_users_var_pop_fields';
  disable_not_from?: Maybe<Scalars['Float']>;
  disable_not_to?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lead_qtd?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "omnichannel.groups_users" */
export type Omnichannel_Groups_Users_Var_Pop_Order_By = {
  disable_not_from?: Maybe<Order_By>;
  disable_not_to?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead_qtd?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Omnichannel_Groups_Users_Var_Samp_Fields = {
  __typename?: 'omnichannel_groups_users_var_samp_fields';
  disable_not_from?: Maybe<Scalars['Float']>;
  disable_not_to?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lead_qtd?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "omnichannel.groups_users" */
export type Omnichannel_Groups_Users_Var_Samp_Order_By = {
  disable_not_from?: Maybe<Order_By>;
  disable_not_to?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead_qtd?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Omnichannel_Groups_Users_Variance_Fields = {
  __typename?: 'omnichannel_groups_users_variance_fields';
  disable_not_from?: Maybe<Scalars['Float']>;
  disable_not_to?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lead_qtd?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "omnichannel.groups_users" */
export type Omnichannel_Groups_Users_Variance_Order_By = {
  disable_not_from?: Maybe<Order_By>;
  disable_not_to?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead_qtd?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Omnichannel_Groups_Var_Pop_Fields = {
  __typename?: 'omnichannel_groups_var_pop_fields';
  dadgroup?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "omnichannel.groups" */
export type Omnichannel_Groups_Var_Pop_Order_By = {
  dadgroup?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Omnichannel_Groups_Var_Samp_Fields = {
  __typename?: 'omnichannel_groups_var_samp_fields';
  dadgroup?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "omnichannel.groups" */
export type Omnichannel_Groups_Var_Samp_Order_By = {
  dadgroup?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Omnichannel_Groups_Variance_Fields = {
  __typename?: 'omnichannel_groups_variance_fields';
  dadgroup?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "omnichannel.groups" */
export type Omnichannel_Groups_Variance_Order_By = {
  dadgroup?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** columns and relationships of "omnichannel.mailbox_mail_addons" */
export type Omnichannel_Mailbox_Mail_Addons = {
  __typename?: 'omnichannel_mailbox_mail_addons';
  /** An object relationship */
  addons_connects?: Maybe<Omnichannel_Addons_Connects>;
  addons_connects_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  mailbox_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "omnichannel.mailbox_mail_addons" */
export type Omnichannel_Mailbox_Mail_Addons_Aggregate = {
  __typename?: 'omnichannel_mailbox_mail_addons_aggregate';
  aggregate?: Maybe<Omnichannel_Mailbox_Mail_Addons_Aggregate_Fields>;
  nodes: Array<Omnichannel_Mailbox_Mail_Addons>;
};

/** aggregate fields of "omnichannel.mailbox_mail_addons" */
export type Omnichannel_Mailbox_Mail_Addons_Aggregate_Fields = {
  __typename?: 'omnichannel_mailbox_mail_addons_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Mailbox_Mail_Addons_Max_Fields>;
  min?: Maybe<Omnichannel_Mailbox_Mail_Addons_Min_Fields>;
};


/** aggregate fields of "omnichannel.mailbox_mail_addons" */
export type Omnichannel_Mailbox_Mail_Addons_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Mailbox_Mail_Addons_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.mailbox_mail_addons" */
export type Omnichannel_Mailbox_Mail_Addons_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Mailbox_Mail_Addons_Max_Order_By>;
  min?: Maybe<Omnichannel_Mailbox_Mail_Addons_Min_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.mailbox_mail_addons" */
export type Omnichannel_Mailbox_Mail_Addons_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Mailbox_Mail_Addons_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Mailbox_Mail_Addons_On_Conflict>;
};

/** Boolean expression to filter rows from the table "omnichannel.mailbox_mail_addons". All fields are combined with a logical 'AND'. */
export type Omnichannel_Mailbox_Mail_Addons_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Mailbox_Mail_Addons_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Mailbox_Mail_Addons_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Mailbox_Mail_Addons_Bool_Exp>>>;
  addons_connects?: Maybe<Omnichannel_Addons_Connects_Bool_Exp>;
  addons_connects_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  mailbox_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.mailbox_mail_addons" */
export enum Omnichannel_Mailbox_Mail_Addons_Constraint {
  /** unique or primary key constraint */
  MailboxMailAddonsPkey = 'mailbox_mail_addons_pkey'
}

/** input type for inserting data into table "omnichannel.mailbox_mail_addons" */
export type Omnichannel_Mailbox_Mail_Addons_Insert_Input = {
  addons_connects?: Maybe<Omnichannel_Addons_Connects_Obj_Rel_Insert_Input>;
  addons_connects_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  mailbox_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Omnichannel_Mailbox_Mail_Addons_Max_Fields = {
  __typename?: 'omnichannel_mailbox_mail_addons_max_fields';
  addons_connects_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  mailbox_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "omnichannel.mailbox_mail_addons" */
export type Omnichannel_Mailbox_Mail_Addons_Max_Order_By = {
  addons_connects_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mailbox_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Mailbox_Mail_Addons_Min_Fields = {
  __typename?: 'omnichannel_mailbox_mail_addons_min_fields';
  addons_connects_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  mailbox_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "omnichannel.mailbox_mail_addons" */
export type Omnichannel_Mailbox_Mail_Addons_Min_Order_By = {
  addons_connects_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mailbox_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.mailbox_mail_addons" */
export type Omnichannel_Mailbox_Mail_Addons_Mutation_Response = {
  __typename?: 'omnichannel_mailbox_mail_addons_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Mailbox_Mail_Addons>;
};

/** input type for inserting object relation for remote table "omnichannel.mailbox_mail_addons" */
export type Omnichannel_Mailbox_Mail_Addons_Obj_Rel_Insert_Input = {
  data: Omnichannel_Mailbox_Mail_Addons_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Mailbox_Mail_Addons_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.mailbox_mail_addons" */
export type Omnichannel_Mailbox_Mail_Addons_On_Conflict = {
  constraint: Omnichannel_Mailbox_Mail_Addons_Constraint;
  update_columns: Array<Omnichannel_Mailbox_Mail_Addons_Update_Column>;
  where?: Maybe<Omnichannel_Mailbox_Mail_Addons_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.mailbox_mail_addons" */
export type Omnichannel_Mailbox_Mail_Addons_Order_By = {
  addons_connects?: Maybe<Omnichannel_Addons_Connects_Order_By>;
  addons_connects_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mailbox_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.mailbox_mail_addons" */
export type Omnichannel_Mailbox_Mail_Addons_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "omnichannel.mailbox_mail_addons" */
export enum Omnichannel_Mailbox_Mail_Addons_Select_Column {
  /** column name */
  AddonsConnectsId = 'addons_connects_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MailboxId = 'mailbox_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "omnichannel.mailbox_mail_addons" */
export type Omnichannel_Mailbox_Mail_Addons_Set_Input = {
  addons_connects_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  mailbox_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "omnichannel.mailbox_mail_addons" */
export enum Omnichannel_Mailbox_Mail_Addons_Update_Column {
  /** column name */
  AddonsConnectsId = 'addons_connects_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MailboxId = 'mailbox_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "omnichannel.message_mail_addons" */
export type Omnichannel_Message_Mail_Addons = {
  __typename?: 'omnichannel_message_mail_addons';
  /** An object relationship */
  addons_connects?: Maybe<Omnichannel_Addons_Connects>;
  addons_connects_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  date_message: Scalars['timestamptz'];
  id: Scalars['uuid'];
  inReplyTo?: Maybe<Scalars['String']>;
  /** An object relationship */
  mailbox?: Maybe<Omnichannel_Mailbox_Mail_Addons>;
  mailbox_mail_addons_id: Scalars['String'];
  mailmessageId?: Maybe<Scalars['String']>;
  /** Remote relationship field */
  message?: Maybe<AccountMail>;
  message_id: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
};

/** aggregated selection of "omnichannel.message_mail_addons" */
export type Omnichannel_Message_Mail_Addons_Aggregate = {
  __typename?: 'omnichannel_message_mail_addons_aggregate';
  aggregate?: Maybe<Omnichannel_Message_Mail_Addons_Aggregate_Fields>;
  nodes: Array<Omnichannel_Message_Mail_Addons>;
};

/** aggregate fields of "omnichannel.message_mail_addons" */
export type Omnichannel_Message_Mail_Addons_Aggregate_Fields = {
  __typename?: 'omnichannel_message_mail_addons_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Message_Mail_Addons_Max_Fields>;
  min?: Maybe<Omnichannel_Message_Mail_Addons_Min_Fields>;
};


/** aggregate fields of "omnichannel.message_mail_addons" */
export type Omnichannel_Message_Mail_Addons_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Message_Mail_Addons_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.message_mail_addons" */
export type Omnichannel_Message_Mail_Addons_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Message_Mail_Addons_Max_Order_By>;
  min?: Maybe<Omnichannel_Message_Mail_Addons_Min_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.message_mail_addons" */
export type Omnichannel_Message_Mail_Addons_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Message_Mail_Addons_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Message_Mail_Addons_On_Conflict>;
};

/** Boolean expression to filter rows from the table "omnichannel.message_mail_addons". All fields are combined with a logical 'AND'. */
export type Omnichannel_Message_Mail_Addons_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Message_Mail_Addons_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Message_Mail_Addons_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Message_Mail_Addons_Bool_Exp>>>;
  addons_connects?: Maybe<Omnichannel_Addons_Connects_Bool_Exp>;
  addons_connects_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  date_message?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  inReplyTo?: Maybe<String_Comparison_Exp>;
  mailbox?: Maybe<Omnichannel_Mailbox_Mail_Addons_Bool_Exp>;
  mailbox_mail_addons_id?: Maybe<String_Comparison_Exp>;
  mailmessageId?: Maybe<String_Comparison_Exp>;
  message_id?: Maybe<String_Comparison_Exp>;
  subject?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.message_mail_addons" */
export enum Omnichannel_Message_Mail_Addons_Constraint {
  /** unique or primary key constraint */
  MessageMailAddonsPkey = 'message_mail_addons_pkey'
}

/** input type for inserting data into table "omnichannel.message_mail_addons" */
export type Omnichannel_Message_Mail_Addons_Insert_Input = {
  addons_connects?: Maybe<Omnichannel_Addons_Connects_Obj_Rel_Insert_Input>;
  addons_connects_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_message?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  inReplyTo?: Maybe<Scalars['String']>;
  mailbox?: Maybe<Omnichannel_Mailbox_Mail_Addons_Obj_Rel_Insert_Input>;
  mailbox_mail_addons_id?: Maybe<Scalars['String']>;
  mailmessageId?: Maybe<Scalars['String']>;
  message_id?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Omnichannel_Message_Mail_Addons_Max_Fields = {
  __typename?: 'omnichannel_message_mail_addons_max_fields';
  addons_connects_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_message?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  inReplyTo?: Maybe<Scalars['String']>;
  mailbox_mail_addons_id?: Maybe<Scalars['String']>;
  mailmessageId?: Maybe<Scalars['String']>;
  message_id?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "omnichannel.message_mail_addons" */
export type Omnichannel_Message_Mail_Addons_Max_Order_By = {
  addons_connects_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_message?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inReplyTo?: Maybe<Order_By>;
  mailbox_mail_addons_id?: Maybe<Order_By>;
  mailmessageId?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Message_Mail_Addons_Min_Fields = {
  __typename?: 'omnichannel_message_mail_addons_min_fields';
  addons_connects_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_message?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  inReplyTo?: Maybe<Scalars['String']>;
  mailbox_mail_addons_id?: Maybe<Scalars['String']>;
  mailmessageId?: Maybe<Scalars['String']>;
  message_id?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "omnichannel.message_mail_addons" */
export type Omnichannel_Message_Mail_Addons_Min_Order_By = {
  addons_connects_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_message?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inReplyTo?: Maybe<Order_By>;
  mailbox_mail_addons_id?: Maybe<Order_By>;
  mailmessageId?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.message_mail_addons" */
export type Omnichannel_Message_Mail_Addons_Mutation_Response = {
  __typename?: 'omnichannel_message_mail_addons_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Message_Mail_Addons>;
};

/** input type for inserting object relation for remote table "omnichannel.message_mail_addons" */
export type Omnichannel_Message_Mail_Addons_Obj_Rel_Insert_Input = {
  data: Omnichannel_Message_Mail_Addons_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Message_Mail_Addons_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.message_mail_addons" */
export type Omnichannel_Message_Mail_Addons_On_Conflict = {
  constraint: Omnichannel_Message_Mail_Addons_Constraint;
  update_columns: Array<Omnichannel_Message_Mail_Addons_Update_Column>;
  where?: Maybe<Omnichannel_Message_Mail_Addons_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.message_mail_addons" */
export type Omnichannel_Message_Mail_Addons_Order_By = {
  addons_connects?: Maybe<Omnichannel_Addons_Connects_Order_By>;
  addons_connects_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_message?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inReplyTo?: Maybe<Order_By>;
  mailbox?: Maybe<Omnichannel_Mailbox_Mail_Addons_Order_By>;
  mailbox_mail_addons_id?: Maybe<Order_By>;
  mailmessageId?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.message_mail_addons" */
export type Omnichannel_Message_Mail_Addons_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "omnichannel.message_mail_addons" */
export enum Omnichannel_Message_Mail_Addons_Select_Column {
  /** column name */
  AddonsConnectsId = 'addons_connects_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateMessage = 'date_message',
  /** column name */
  Id = 'id',
  /** column name */
  InReplyTo = 'inReplyTo',
  /** column name */
  MailboxMailAddonsId = 'mailbox_mail_addons_id',
  /** column name */
  MailmessageId = 'mailmessageId',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  Subject = 'subject'
}

/** input type for updating data in table "omnichannel.message_mail_addons" */
export type Omnichannel_Message_Mail_Addons_Set_Input = {
  addons_connects_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_message?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  inReplyTo?: Maybe<Scalars['String']>;
  mailbox_mail_addons_id?: Maybe<Scalars['String']>;
  mailmessageId?: Maybe<Scalars['String']>;
  message_id?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

/** update columns of table "omnichannel.message_mail_addons" */
export enum Omnichannel_Message_Mail_Addons_Update_Column {
  /** column name */
  AddonsConnectsId = 'addons_connects_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateMessage = 'date_message',
  /** column name */
  Id = 'id',
  /** column name */
  InReplyTo = 'inReplyTo',
  /** column name */
  MailboxMailAddonsId = 'mailbox_mail_addons_id',
  /** column name */
  MailmessageId = 'mailmessageId',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  Subject = 'subject'
}

/** columns and relationships of "omnichannel.person_activities" */
export type Omnichannel_Person_Activities = {
  __typename?: 'omnichannel_person_activities';
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  feature: Scalars['String'];
  /** An object relationship */
  file?: Maybe<Omnichannel_Person_File>;
  id: Scalars['uuid'];
  /** An object relationship */
  mails?: Maybe<Omnichannel_Person_Mail>;
  /** An object relationship */
  note?: Maybe<Omnichannel_Person_Note>;
  /** An object relationship */
  owner?: Maybe<Users>;
  owner_id: Scalars['uuid'];
  /** An object relationship */
  person?: Maybe<Omnichannel_Persons>;
  person_file_id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
  person_mail_id?: Maybe<Scalars['uuid']>;
  person_note_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "omnichannel.person_activities" */
export type Omnichannel_Person_Activities_Aggregate = {
  __typename?: 'omnichannel_person_activities_aggregate';
  aggregate?: Maybe<Omnichannel_Person_Activities_Aggregate_Fields>;
  nodes: Array<Omnichannel_Person_Activities>;
};

/** aggregate fields of "omnichannel.person_activities" */
export type Omnichannel_Person_Activities_Aggregate_Fields = {
  __typename?: 'omnichannel_person_activities_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Person_Activities_Max_Fields>;
  min?: Maybe<Omnichannel_Person_Activities_Min_Fields>;
};


/** aggregate fields of "omnichannel.person_activities" */
export type Omnichannel_Person_Activities_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Person_Activities_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.person_activities" */
export type Omnichannel_Person_Activities_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Person_Activities_Max_Order_By>;
  min?: Maybe<Omnichannel_Person_Activities_Min_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.person_activities" */
export type Omnichannel_Person_Activities_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Person_Activities_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Person_Activities_On_Conflict>;
};

/** Boolean expression to filter rows from the table "omnichannel.person_activities". All fields are combined with a logical 'AND'. */
export type Omnichannel_Person_Activities_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Person_Activities_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Person_Activities_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Person_Activities_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  feature?: Maybe<String_Comparison_Exp>;
  file?: Maybe<Omnichannel_Person_File_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  mails?: Maybe<Omnichannel_Person_Mail_Bool_Exp>;
  note?: Maybe<Omnichannel_Person_Note_Bool_Exp>;
  owner?: Maybe<Users_Bool_Exp>;
  owner_id?: Maybe<Uuid_Comparison_Exp>;
  person?: Maybe<Omnichannel_Persons_Bool_Exp>;
  person_file_id?: Maybe<Uuid_Comparison_Exp>;
  person_id?: Maybe<Uuid_Comparison_Exp>;
  person_mail_id?: Maybe<Uuid_Comparison_Exp>;
  person_note_id?: Maybe<Uuid_Comparison_Exp>;
  tag_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.person_activities" */
export enum Omnichannel_Person_Activities_Constraint {
  /** unique or primary key constraint */
  PersonActivitiesPkey = 'person_activities_pkey'
}

/** input type for inserting data into table "omnichannel.person_activities" */
export type Omnichannel_Person_Activities_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['String']>;
  file?: Maybe<Omnichannel_Person_File_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  mails?: Maybe<Omnichannel_Person_Mail_Obj_Rel_Insert_Input>;
  note?: Maybe<Omnichannel_Person_Note_Obj_Rel_Insert_Input>;
  owner?: Maybe<Users_Obj_Rel_Insert_Input>;
  owner_id?: Maybe<Scalars['uuid']>;
  person?: Maybe<Omnichannel_Persons_Obj_Rel_Insert_Input>;
  person_file_id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
  person_mail_id?: Maybe<Scalars['uuid']>;
  person_note_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Omnichannel_Person_Activities_Max_Fields = {
  __typename?: 'omnichannel_person_activities_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['uuid']>;
  person_file_id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
  person_mail_id?: Maybe<Scalars['uuid']>;
  person_note_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "omnichannel.person_activities" */
export type Omnichannel_Person_Activities_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  feature?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  person_file_id?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
  person_mail_id?: Maybe<Order_By>;
  person_note_id?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Person_Activities_Min_Fields = {
  __typename?: 'omnichannel_person_activities_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['uuid']>;
  person_file_id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
  person_mail_id?: Maybe<Scalars['uuid']>;
  person_note_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "omnichannel.person_activities" */
export type Omnichannel_Person_Activities_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  feature?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  person_file_id?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
  person_mail_id?: Maybe<Order_By>;
  person_note_id?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.person_activities" */
export type Omnichannel_Person_Activities_Mutation_Response = {
  __typename?: 'omnichannel_person_activities_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Person_Activities>;
};

/** input type for inserting object relation for remote table "omnichannel.person_activities" */
export type Omnichannel_Person_Activities_Obj_Rel_Insert_Input = {
  data: Omnichannel_Person_Activities_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Person_Activities_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.person_activities" */
export type Omnichannel_Person_Activities_On_Conflict = {
  constraint: Omnichannel_Person_Activities_Constraint;
  update_columns: Array<Omnichannel_Person_Activities_Update_Column>;
  where?: Maybe<Omnichannel_Person_Activities_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.person_activities" */
export type Omnichannel_Person_Activities_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  feature?: Maybe<Order_By>;
  file?: Maybe<Omnichannel_Person_File_Order_By>;
  id?: Maybe<Order_By>;
  mails?: Maybe<Omnichannel_Person_Mail_Order_By>;
  note?: Maybe<Omnichannel_Person_Note_Order_By>;
  owner?: Maybe<Users_Order_By>;
  owner_id?: Maybe<Order_By>;
  person?: Maybe<Omnichannel_Persons_Order_By>;
  person_file_id?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
  person_mail_id?: Maybe<Order_By>;
  person_note_id?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.person_activities" */
export type Omnichannel_Person_Activities_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "omnichannel.person_activities" */
export enum Omnichannel_Person_Activities_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Feature = 'feature',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  PersonFileId = 'person_file_id',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  PersonMailId = 'person_mail_id',
  /** column name */
  PersonNoteId = 'person_note_id',
  /** column name */
  TagId = 'tag_id'
}

/** input type for updating data in table "omnichannel.person_activities" */
export type Omnichannel_Person_Activities_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['uuid']>;
  person_file_id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
  person_mail_id?: Maybe<Scalars['uuid']>;
  person_note_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "omnichannel.person_activities" */
export enum Omnichannel_Person_Activities_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Feature = 'feature',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  PersonFileId = 'person_file_id',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  PersonMailId = 'person_mail_id',
  /** column name */
  PersonNoteId = 'person_note_id',
  /** column name */
  TagId = 'tag_id'
}

/** columns and relationships of "omnichannel.person_file" */
export type Omnichannel_Person_File = {
  __typename?: 'omnichannel_person_file';
  created_at: Scalars['timestamptz'];
  filename: Scalars['String'];
  id: Scalars['uuid'];
  person_id: Scalars['uuid'];
};

/** aggregated selection of "omnichannel.person_file" */
export type Omnichannel_Person_File_Aggregate = {
  __typename?: 'omnichannel_person_file_aggregate';
  aggregate?: Maybe<Omnichannel_Person_File_Aggregate_Fields>;
  nodes: Array<Omnichannel_Person_File>;
};

/** aggregate fields of "omnichannel.person_file" */
export type Omnichannel_Person_File_Aggregate_Fields = {
  __typename?: 'omnichannel_person_file_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Person_File_Max_Fields>;
  min?: Maybe<Omnichannel_Person_File_Min_Fields>;
};


/** aggregate fields of "omnichannel.person_file" */
export type Omnichannel_Person_File_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Person_File_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.person_file" */
export type Omnichannel_Person_File_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Person_File_Max_Order_By>;
  min?: Maybe<Omnichannel_Person_File_Min_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.person_file" */
export type Omnichannel_Person_File_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Person_File_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Person_File_On_Conflict>;
};

/** Boolean expression to filter rows from the table "omnichannel.person_file". All fields are combined with a logical 'AND'. */
export type Omnichannel_Person_File_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Person_File_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Person_File_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Person_File_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  filename?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  person_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.person_file" */
export enum Omnichannel_Person_File_Constraint {
  /** unique or primary key constraint */
  PersonFilePkey = 'person_file_pkey'
}

/** input type for inserting data into table "omnichannel.person_file" */
export type Omnichannel_Person_File_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Omnichannel_Person_File_Max_Fields = {
  __typename?: 'omnichannel_person_file_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "omnichannel.person_file" */
export type Omnichannel_Person_File_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Person_File_Min_Fields = {
  __typename?: 'omnichannel_person_file_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "omnichannel.person_file" */
export type Omnichannel_Person_File_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.person_file" */
export type Omnichannel_Person_File_Mutation_Response = {
  __typename?: 'omnichannel_person_file_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Person_File>;
};

/** input type for inserting object relation for remote table "omnichannel.person_file" */
export type Omnichannel_Person_File_Obj_Rel_Insert_Input = {
  data: Omnichannel_Person_File_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Person_File_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.person_file" */
export type Omnichannel_Person_File_On_Conflict = {
  constraint: Omnichannel_Person_File_Constraint;
  update_columns: Array<Omnichannel_Person_File_Update_Column>;
  where?: Maybe<Omnichannel_Person_File_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.person_file" */
export type Omnichannel_Person_File_Order_By = {
  created_at?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.person_file" */
export type Omnichannel_Person_File_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "omnichannel.person_file" */
export enum Omnichannel_Person_File_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  PersonId = 'person_id'
}

/** input type for updating data in table "omnichannel.person_file" */
export type Omnichannel_Person_File_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "omnichannel.person_file" */
export enum Omnichannel_Person_File_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  PersonId = 'person_id'
}

/** columns and relationships of "omnichannel.person_mail" */
export type Omnichannel_Person_Mail = {
  __typename?: 'omnichannel_person_mail';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  message?: Maybe<Omnichannel_Message_Mail_Addons>;
  message_mail_addons_id: Scalars['uuid'];
  person_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "omnichannel.person_mail" */
export type Omnichannel_Person_Mail_Aggregate = {
  __typename?: 'omnichannel_person_mail_aggregate';
  aggregate?: Maybe<Omnichannel_Person_Mail_Aggregate_Fields>;
  nodes: Array<Omnichannel_Person_Mail>;
};

/** aggregate fields of "omnichannel.person_mail" */
export type Omnichannel_Person_Mail_Aggregate_Fields = {
  __typename?: 'omnichannel_person_mail_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Person_Mail_Max_Fields>;
  min?: Maybe<Omnichannel_Person_Mail_Min_Fields>;
};


/** aggregate fields of "omnichannel.person_mail" */
export type Omnichannel_Person_Mail_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Person_Mail_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.person_mail" */
export type Omnichannel_Person_Mail_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Person_Mail_Max_Order_By>;
  min?: Maybe<Omnichannel_Person_Mail_Min_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.person_mail" */
export type Omnichannel_Person_Mail_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Person_Mail_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Person_Mail_On_Conflict>;
};

/** Boolean expression to filter rows from the table "omnichannel.person_mail". All fields are combined with a logical 'AND'. */
export type Omnichannel_Person_Mail_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Person_Mail_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Person_Mail_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Person_Mail_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  message?: Maybe<Omnichannel_Message_Mail_Addons_Bool_Exp>;
  message_mail_addons_id?: Maybe<Uuid_Comparison_Exp>;
  person_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.person_mail" */
export enum Omnichannel_Person_Mail_Constraint {
  /** unique or primary key constraint */
  PersonMailPkey = 'person_mail_pkey'
}

/** input type for inserting data into table "omnichannel.person_mail" */
export type Omnichannel_Person_Mail_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Omnichannel_Message_Mail_Addons_Obj_Rel_Insert_Input>;
  message_mail_addons_id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Omnichannel_Person_Mail_Max_Fields = {
  __typename?: 'omnichannel_person_mail_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message_mail_addons_id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "omnichannel.person_mail" */
export type Omnichannel_Person_Mail_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_mail_addons_id?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Person_Mail_Min_Fields = {
  __typename?: 'omnichannel_person_mail_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message_mail_addons_id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "omnichannel.person_mail" */
export type Omnichannel_Person_Mail_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_mail_addons_id?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.person_mail" */
export type Omnichannel_Person_Mail_Mutation_Response = {
  __typename?: 'omnichannel_person_mail_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Person_Mail>;
};

/** input type for inserting object relation for remote table "omnichannel.person_mail" */
export type Omnichannel_Person_Mail_Obj_Rel_Insert_Input = {
  data: Omnichannel_Person_Mail_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Person_Mail_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.person_mail" */
export type Omnichannel_Person_Mail_On_Conflict = {
  constraint: Omnichannel_Person_Mail_Constraint;
  update_columns: Array<Omnichannel_Person_Mail_Update_Column>;
  where?: Maybe<Omnichannel_Person_Mail_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.person_mail" */
export type Omnichannel_Person_Mail_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Omnichannel_Message_Mail_Addons_Order_By>;
  message_mail_addons_id?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.person_mail" */
export type Omnichannel_Person_Mail_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "omnichannel.person_mail" */
export enum Omnichannel_Person_Mail_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MessageMailAddonsId = 'message_mail_addons_id',
  /** column name */
  PersonId = 'person_id'
}

/** input type for updating data in table "omnichannel.person_mail" */
export type Omnichannel_Person_Mail_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message_mail_addons_id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "omnichannel.person_mail" */
export enum Omnichannel_Person_Mail_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MessageMailAddonsId = 'message_mail_addons_id',
  /** column name */
  PersonId = 'person_id'
}

/** columns and relationships of "omnichannel.person_note" */
export type Omnichannel_Person_Note = {
  __typename?: 'omnichannel_person_note';
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  person?: Maybe<Omnichannel_Persons>;
  person_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "omnichannel.person_note" */
export type Omnichannel_Person_Note_Aggregate = {
  __typename?: 'omnichannel_person_note_aggregate';
  aggregate?: Maybe<Omnichannel_Person_Note_Aggregate_Fields>;
  nodes: Array<Omnichannel_Person_Note>;
};

/** aggregate fields of "omnichannel.person_note" */
export type Omnichannel_Person_Note_Aggregate_Fields = {
  __typename?: 'omnichannel_person_note_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Person_Note_Max_Fields>;
  min?: Maybe<Omnichannel_Person_Note_Min_Fields>;
};


/** aggregate fields of "omnichannel.person_note" */
export type Omnichannel_Person_Note_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Person_Note_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.person_note" */
export type Omnichannel_Person_Note_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Person_Note_Max_Order_By>;
  min?: Maybe<Omnichannel_Person_Note_Min_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.person_note" */
export type Omnichannel_Person_Note_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Person_Note_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Person_Note_On_Conflict>;
};

/** Boolean expression to filter rows from the table "omnichannel.person_note". All fields are combined with a logical 'AND'. */
export type Omnichannel_Person_Note_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Person_Note_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Person_Note_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Person_Note_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  person?: Maybe<Omnichannel_Persons_Bool_Exp>;
  person_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.person_note" */
export enum Omnichannel_Person_Note_Constraint {
  /** unique or primary key constraint */
  PersonNotePkey = 'person_note_pkey'
}

/** input type for inserting data into table "omnichannel.person_note" */
export type Omnichannel_Person_Note_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  person?: Maybe<Omnichannel_Persons_Obj_Rel_Insert_Input>;
  person_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Omnichannel_Person_Note_Max_Fields = {
  __typename?: 'omnichannel_person_note_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "omnichannel.person_note" */
export type Omnichannel_Person_Note_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Person_Note_Min_Fields = {
  __typename?: 'omnichannel_person_note_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "omnichannel.person_note" */
export type Omnichannel_Person_Note_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.person_note" */
export type Omnichannel_Person_Note_Mutation_Response = {
  __typename?: 'omnichannel_person_note_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Person_Note>;
};

/** input type for inserting object relation for remote table "omnichannel.person_note" */
export type Omnichannel_Person_Note_Obj_Rel_Insert_Input = {
  data: Omnichannel_Person_Note_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Person_Note_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.person_note" */
export type Omnichannel_Person_Note_On_Conflict = {
  constraint: Omnichannel_Person_Note_Constraint;
  update_columns: Array<Omnichannel_Person_Note_Update_Column>;
  where?: Maybe<Omnichannel_Person_Note_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.person_note" */
export type Omnichannel_Person_Note_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  person?: Maybe<Omnichannel_Persons_Order_By>;
  person_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.person_note" */
export type Omnichannel_Person_Note_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "omnichannel.person_note" */
export enum Omnichannel_Person_Note_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "omnichannel.person_note" */
export type Omnichannel_Person_Note_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "omnichannel.person_note" */
export enum Omnichannel_Person_Note_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "omnichannel.person_tags" */
export type Omnichannel_Person_Tags = {
  __typename?: 'omnichannel_person_tags';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  person?: Maybe<Omnichannel_Persons>;
  person_id: Scalars['uuid'];
  /** An object relationship */
  tag: Omnichannel_Tags;
  tag_id: Scalars['uuid'];
};

/** aggregated selection of "omnichannel.person_tags" */
export type Omnichannel_Person_Tags_Aggregate = {
  __typename?: 'omnichannel_person_tags_aggregate';
  aggregate?: Maybe<Omnichannel_Person_Tags_Aggregate_Fields>;
  nodes: Array<Omnichannel_Person_Tags>;
};

/** aggregate fields of "omnichannel.person_tags" */
export type Omnichannel_Person_Tags_Aggregate_Fields = {
  __typename?: 'omnichannel_person_tags_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Person_Tags_Max_Fields>;
  min?: Maybe<Omnichannel_Person_Tags_Min_Fields>;
};


/** aggregate fields of "omnichannel.person_tags" */
export type Omnichannel_Person_Tags_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Person_Tags_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.person_tags" */
export type Omnichannel_Person_Tags_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Person_Tags_Max_Order_By>;
  min?: Maybe<Omnichannel_Person_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.person_tags" */
export type Omnichannel_Person_Tags_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Person_Tags_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Person_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "omnichannel.person_tags". All fields are combined with a logical 'AND'. */
export type Omnichannel_Person_Tags_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Person_Tags_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Person_Tags_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Person_Tags_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  person?: Maybe<Omnichannel_Persons_Bool_Exp>;
  person_id?: Maybe<Uuid_Comparison_Exp>;
  tag?: Maybe<Omnichannel_Tags_Bool_Exp>;
  tag_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.person_tags" */
export enum Omnichannel_Person_Tags_Constraint {
  /** unique or primary key constraint */
  PersonTagsPkey = 'person_tags_pkey'
}

/** input type for inserting data into table "omnichannel.person_tags" */
export type Omnichannel_Person_Tags_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  person?: Maybe<Omnichannel_Persons_Obj_Rel_Insert_Input>;
  person_id?: Maybe<Scalars['uuid']>;
  tag?: Maybe<Omnichannel_Tags_Obj_Rel_Insert_Input>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Omnichannel_Person_Tags_Max_Fields = {
  __typename?: 'omnichannel_person_tags_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "omnichannel.person_tags" */
export type Omnichannel_Person_Tags_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Person_Tags_Min_Fields = {
  __typename?: 'omnichannel_person_tags_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "omnichannel.person_tags" */
export type Omnichannel_Person_Tags_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.person_tags" */
export type Omnichannel_Person_Tags_Mutation_Response = {
  __typename?: 'omnichannel_person_tags_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Person_Tags>;
};

/** input type for inserting object relation for remote table "omnichannel.person_tags" */
export type Omnichannel_Person_Tags_Obj_Rel_Insert_Input = {
  data: Omnichannel_Person_Tags_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Person_Tags_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.person_tags" */
export type Omnichannel_Person_Tags_On_Conflict = {
  constraint: Omnichannel_Person_Tags_Constraint;
  update_columns: Array<Omnichannel_Person_Tags_Update_Column>;
  where?: Maybe<Omnichannel_Person_Tags_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.person_tags" */
export type Omnichannel_Person_Tags_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  person?: Maybe<Omnichannel_Persons_Order_By>;
  person_id?: Maybe<Order_By>;
  tag?: Maybe<Omnichannel_Tags_Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.person_tags" */
export type Omnichannel_Person_Tags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "omnichannel.person_tags" */
export enum Omnichannel_Person_Tags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  TagId = 'tag_id'
}

/** input type for updating data in table "omnichannel.person_tags" */
export type Omnichannel_Person_Tags_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "omnichannel.person_tags" */
export enum Omnichannel_Person_Tags_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  TagId = 'tag_id'
}

/** columns and relationships of "omnichannel.person_type" */
export type Omnichannel_Person_Type = {
  __typename?: 'omnichannel_person_type';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  organization_id: Scalars['Int'];
  /** An object relationship */
  permission?: Maybe<Memberships>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "omnichannel.person_type" */
export type Omnichannel_Person_Type_Aggregate = {
  __typename?: 'omnichannel_person_type_aggregate';
  aggregate?: Maybe<Omnichannel_Person_Type_Aggregate_Fields>;
  nodes: Array<Omnichannel_Person_Type>;
};

/** aggregate fields of "omnichannel.person_type" */
export type Omnichannel_Person_Type_Aggregate_Fields = {
  __typename?: 'omnichannel_person_type_aggregate_fields';
  avg?: Maybe<Omnichannel_Person_Type_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Person_Type_Max_Fields>;
  min?: Maybe<Omnichannel_Person_Type_Min_Fields>;
  stddev?: Maybe<Omnichannel_Person_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Omnichannel_Person_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Omnichannel_Person_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Omnichannel_Person_Type_Sum_Fields>;
  var_pop?: Maybe<Omnichannel_Person_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Omnichannel_Person_Type_Var_Samp_Fields>;
  variance?: Maybe<Omnichannel_Person_Type_Variance_Fields>;
};


/** aggregate fields of "omnichannel.person_type" */
export type Omnichannel_Person_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Person_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.person_type" */
export type Omnichannel_Person_Type_Aggregate_Order_By = {
  avg?: Maybe<Omnichannel_Person_Type_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Person_Type_Max_Order_By>;
  min?: Maybe<Omnichannel_Person_Type_Min_Order_By>;
  stddev?: Maybe<Omnichannel_Person_Type_Stddev_Order_By>;
  stddev_pop?: Maybe<Omnichannel_Person_Type_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Omnichannel_Person_Type_Stddev_Samp_Order_By>;
  sum?: Maybe<Omnichannel_Person_Type_Sum_Order_By>;
  var_pop?: Maybe<Omnichannel_Person_Type_Var_Pop_Order_By>;
  var_samp?: Maybe<Omnichannel_Person_Type_Var_Samp_Order_By>;
  variance?: Maybe<Omnichannel_Person_Type_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.person_type" */
export type Omnichannel_Person_Type_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Person_Type_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Person_Type_On_Conflict>;
};

/** aggregate avg on columns */
export type Omnichannel_Person_Type_Avg_Fields = {
  __typename?: 'omnichannel_person_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "omnichannel.person_type" */
export type Omnichannel_Person_Type_Avg_Order_By = {
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "omnichannel.person_type". All fields are combined with a logical 'AND'. */
export type Omnichannel_Person_Type_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Person_Type_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Person_Type_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Person_Type_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  organization_id?: Maybe<Int_Comparison_Exp>;
  permission?: Maybe<Memberships_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.person_type" */
export enum Omnichannel_Person_Type_Constraint {
  /** unique or primary key constraint */
  PersonTypePkey = 'person_type_pkey'
}

/** input type for incrementing integer column in table "omnichannel.person_type" */
export type Omnichannel_Person_Type_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "omnichannel.person_type" */
export type Omnichannel_Person_Type_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  permission?: Maybe<Memberships_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Omnichannel_Person_Type_Max_Fields = {
  __typename?: 'omnichannel_person_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "omnichannel.person_type" */
export type Omnichannel_Person_Type_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Person_Type_Min_Fields = {
  __typename?: 'omnichannel_person_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "omnichannel.person_type" */
export type Omnichannel_Person_Type_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.person_type" */
export type Omnichannel_Person_Type_Mutation_Response = {
  __typename?: 'omnichannel_person_type_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Person_Type>;
};

/** input type for inserting object relation for remote table "omnichannel.person_type" */
export type Omnichannel_Person_Type_Obj_Rel_Insert_Input = {
  data: Omnichannel_Person_Type_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Person_Type_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.person_type" */
export type Omnichannel_Person_Type_On_Conflict = {
  constraint: Omnichannel_Person_Type_Constraint;
  update_columns: Array<Omnichannel_Person_Type_Update_Column>;
  where?: Maybe<Omnichannel_Person_Type_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.person_type" */
export type Omnichannel_Person_Type_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  permission?: Maybe<Memberships_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.person_type" */
export type Omnichannel_Person_Type_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "omnichannel.person_type" */
export enum Omnichannel_Person_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "omnichannel.person_type" */
export type Omnichannel_Person_Type_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Omnichannel_Person_Type_Stddev_Fields = {
  __typename?: 'omnichannel_person_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "omnichannel.person_type" */
export type Omnichannel_Person_Type_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Omnichannel_Person_Type_Stddev_Pop_Fields = {
  __typename?: 'omnichannel_person_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "omnichannel.person_type" */
export type Omnichannel_Person_Type_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Omnichannel_Person_Type_Stddev_Samp_Fields = {
  __typename?: 'omnichannel_person_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "omnichannel.person_type" */
export type Omnichannel_Person_Type_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Omnichannel_Person_Type_Sum_Fields = {
  __typename?: 'omnichannel_person_type_sum_fields';
  id?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "omnichannel.person_type" */
export type Omnichannel_Person_Type_Sum_Order_By = {
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** update columns of table "omnichannel.person_type" */
export enum Omnichannel_Person_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Omnichannel_Person_Type_Var_Pop_Fields = {
  __typename?: 'omnichannel_person_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "omnichannel.person_type" */
export type Omnichannel_Person_Type_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Omnichannel_Person_Type_Var_Samp_Fields = {
  __typename?: 'omnichannel_person_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "omnichannel.person_type" */
export type Omnichannel_Person_Type_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Omnichannel_Person_Type_Variance_Fields = {
  __typename?: 'omnichannel_person_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "omnichannel.person_type" */
export type Omnichannel_Person_Type_Variance_Order_By = {
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
};

/** columns and relationships of "omnichannel.persons" */
export type Omnichannel_Persons = {
  __typename?: 'omnichannel_persons';
  active: Scalars['Boolean'];
  /** An array relationship */
  activities: Array<Omnichannel_Person_Activities>;
  /** An aggregated array relationship */
  activities_aggregate: Omnichannel_Person_Activities_Aggregate;
  address?: Maybe<Scalars['String']>;
  avatar: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  cpf_cnpj?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  email?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  /** An array relationship */
  files: Array<Omnichannel_Person_File>;
  /** An aggregated array relationship */
  files_aggregate: Omnichannel_Person_File_Aggregate;
  /** An array relationship */
  group_user: Array<Omnichannel_Groups_Users>;
  /** An aggregated array relationship */
  group_user_aggregate: Omnichannel_Groups_Users_Aggregate;
  id: Scalars['uuid'];
  instagram?: Maybe<Scalars['String']>;
  /** An array relationship */
  mail: Array<Omnichannel_Person_Mail>;
  /** An aggregated array relationship */
  mail_aggregate: Omnichannel_Person_Mail_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  note: Array<Omnichannel_Person_Note>;
  /** An aggregated array relationship */
  note_aggregate: Omnichannel_Person_Note_Aggregate;
  number?: Maybe<Scalars['String']>;
  organization_id: Scalars['Int'];
  owner_id: Scalars['uuid'];
  /** An object relationship */
  permissions?: Maybe<Memberships>;
  person_type?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  /** An object relationship */
  ptypes?: Maybe<Omnichannel_Person_Type>;
  state?: Maybe<Scalars['String']>;
  /** An array relationship */
  tags: Array<Omnichannel_Person_Tags>;
  /** An aggregated array relationship */
  tags_aggregate: Omnichannel_Person_Tags_Aggregate;
  /** An object relationship */
  teams?: Maybe<Organizations>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  website?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};


/** columns and relationships of "omnichannel.persons" */
export type Omnichannel_PersonsActivitiesArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Activities_Order_By>>;
  where?: Maybe<Omnichannel_Person_Activities_Bool_Exp>;
};


/** columns and relationships of "omnichannel.persons" */
export type Omnichannel_PersonsActivities_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Activities_Order_By>>;
  where?: Maybe<Omnichannel_Person_Activities_Bool_Exp>;
};


/** columns and relationships of "omnichannel.persons" */
export type Omnichannel_PersonsFilesArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_File_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_File_Order_By>>;
  where?: Maybe<Omnichannel_Person_File_Bool_Exp>;
};


/** columns and relationships of "omnichannel.persons" */
export type Omnichannel_PersonsFiles_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_File_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_File_Order_By>>;
  where?: Maybe<Omnichannel_Person_File_Bool_Exp>;
};


/** columns and relationships of "omnichannel.persons" */
export type Omnichannel_PersonsGroup_UserArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Groups_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Groups_Users_Order_By>>;
  where?: Maybe<Omnichannel_Groups_Users_Bool_Exp>;
};


/** columns and relationships of "omnichannel.persons" */
export type Omnichannel_PersonsGroup_User_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Groups_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Groups_Users_Order_By>>;
  where?: Maybe<Omnichannel_Groups_Users_Bool_Exp>;
};


/** columns and relationships of "omnichannel.persons" */
export type Omnichannel_PersonsMailArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Mail_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Mail_Order_By>>;
  where?: Maybe<Omnichannel_Person_Mail_Bool_Exp>;
};


/** columns and relationships of "omnichannel.persons" */
export type Omnichannel_PersonsMail_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Mail_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Mail_Order_By>>;
  where?: Maybe<Omnichannel_Person_Mail_Bool_Exp>;
};


/** columns and relationships of "omnichannel.persons" */
export type Omnichannel_PersonsNoteArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Note_Order_By>>;
  where?: Maybe<Omnichannel_Person_Note_Bool_Exp>;
};


/** columns and relationships of "omnichannel.persons" */
export type Omnichannel_PersonsNote_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Note_Order_By>>;
  where?: Maybe<Omnichannel_Person_Note_Bool_Exp>;
};


/** columns and relationships of "omnichannel.persons" */
export type Omnichannel_PersonsTagsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Tags_Order_By>>;
  where?: Maybe<Omnichannel_Person_Tags_Bool_Exp>;
};


/** columns and relationships of "omnichannel.persons" */
export type Omnichannel_PersonsTags_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Tags_Order_By>>;
  where?: Maybe<Omnichannel_Person_Tags_Bool_Exp>;
};

/** aggregated selection of "omnichannel.persons" */
export type Omnichannel_Persons_Aggregate = {
  __typename?: 'omnichannel_persons_aggregate';
  aggregate?: Maybe<Omnichannel_Persons_Aggregate_Fields>;
  nodes: Array<Omnichannel_Persons>;
};

/** aggregate fields of "omnichannel.persons" */
export type Omnichannel_Persons_Aggregate_Fields = {
  __typename?: 'omnichannel_persons_aggregate_fields';
  avg?: Maybe<Omnichannel_Persons_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Persons_Max_Fields>;
  min?: Maybe<Omnichannel_Persons_Min_Fields>;
  stddev?: Maybe<Omnichannel_Persons_Stddev_Fields>;
  stddev_pop?: Maybe<Omnichannel_Persons_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Omnichannel_Persons_Stddev_Samp_Fields>;
  sum?: Maybe<Omnichannel_Persons_Sum_Fields>;
  var_pop?: Maybe<Omnichannel_Persons_Var_Pop_Fields>;
  var_samp?: Maybe<Omnichannel_Persons_Var_Samp_Fields>;
  variance?: Maybe<Omnichannel_Persons_Variance_Fields>;
};


/** aggregate fields of "omnichannel.persons" */
export type Omnichannel_Persons_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Persons_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.persons" */
export type Omnichannel_Persons_Aggregate_Order_By = {
  avg?: Maybe<Omnichannel_Persons_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Persons_Max_Order_By>;
  min?: Maybe<Omnichannel_Persons_Min_Order_By>;
  stddev?: Maybe<Omnichannel_Persons_Stddev_Order_By>;
  stddev_pop?: Maybe<Omnichannel_Persons_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Omnichannel_Persons_Stddev_Samp_Order_By>;
  sum?: Maybe<Omnichannel_Persons_Sum_Order_By>;
  var_pop?: Maybe<Omnichannel_Persons_Var_Pop_Order_By>;
  var_samp?: Maybe<Omnichannel_Persons_Var_Samp_Order_By>;
  variance?: Maybe<Omnichannel_Persons_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.persons" */
export type Omnichannel_Persons_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Persons_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Persons_On_Conflict>;
};

/** aggregate avg on columns */
export type Omnichannel_Persons_Avg_Fields = {
  __typename?: 'omnichannel_persons_avg_fields';
  organization_id?: Maybe<Scalars['Float']>;
  person_type?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "omnichannel.persons" */
export type Omnichannel_Persons_Avg_Order_By = {
  organization_id?: Maybe<Order_By>;
  person_type?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "omnichannel.persons". All fields are combined with a logical 'AND'. */
export type Omnichannel_Persons_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Persons_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Persons_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Persons_Bool_Exp>>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  activities?: Maybe<Omnichannel_Person_Activities_Bool_Exp>;
  address?: Maybe<String_Comparison_Exp>;
  avatar?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  companyName?: Maybe<String_Comparison_Exp>;
  cpf_cnpj?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  facebook?: Maybe<String_Comparison_Exp>;
  files?: Maybe<Omnichannel_Person_File_Bool_Exp>;
  group_user?: Maybe<Omnichannel_Groups_Users_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  instagram?: Maybe<String_Comparison_Exp>;
  mail?: Maybe<Omnichannel_Person_Mail_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  note?: Maybe<Omnichannel_Person_Note_Bool_Exp>;
  number?: Maybe<String_Comparison_Exp>;
  organization_id?: Maybe<Int_Comparison_Exp>;
  owner_id?: Maybe<Uuid_Comparison_Exp>;
  permissions?: Maybe<Memberships_Bool_Exp>;
  person_type?: Maybe<Int_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  ptypes?: Maybe<Omnichannel_Person_Type_Bool_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  tags?: Maybe<Omnichannel_Person_Tags_Bool_Exp>;
  teams?: Maybe<Organizations_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  website?: Maybe<String_Comparison_Exp>;
  whatsapp?: Maybe<String_Comparison_Exp>;
  zipcode?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.persons" */
export enum Omnichannel_Persons_Constraint {
  /** unique or primary key constraint */
  PersonsOrganizationIdEmailKey = 'persons_organization_id_email_key',
  /** unique or primary key constraint */
  PersonsPkey = 'persons_pkey'
}

/** input type for incrementing integer column in table "omnichannel.persons" */
export type Omnichannel_Persons_Inc_Input = {
  organization_id?: Maybe<Scalars['Int']>;
  person_type?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "omnichannel.persons" */
export type Omnichannel_Persons_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  activities?: Maybe<Omnichannel_Person_Activities_Arr_Rel_Insert_Input>;
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  cpf_cnpj?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  files?: Maybe<Omnichannel_Person_File_Arr_Rel_Insert_Input>;
  group_user?: Maybe<Omnichannel_Groups_Users_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  instagram?: Maybe<Scalars['String']>;
  mail?: Maybe<Omnichannel_Person_Mail_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Omnichannel_Person_Note_Arr_Rel_Insert_Input>;
  number?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['uuid']>;
  permissions?: Maybe<Memberships_Obj_Rel_Insert_Input>;
  person_type?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  ptypes?: Maybe<Omnichannel_Person_Type_Obj_Rel_Insert_Input>;
  state?: Maybe<Scalars['String']>;
  tags?: Maybe<Omnichannel_Person_Tags_Arr_Rel_Insert_Input>;
  teams?: Maybe<Organizations_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  website?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Omnichannel_Persons_Max_Fields = {
  __typename?: 'omnichannel_persons_max_fields';
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  cpf_cnpj?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  instagram?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['uuid']>;
  person_type?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "omnichannel.persons" */
export type Omnichannel_Persons_Max_Order_By = {
  address?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  companyName?: Maybe<Order_By>;
  cpf_cnpj?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  facebook?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  instagram?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  person_type?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  website?: Maybe<Order_By>;
  whatsapp?: Maybe<Order_By>;
  zipcode?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Persons_Min_Fields = {
  __typename?: 'omnichannel_persons_min_fields';
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  cpf_cnpj?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  instagram?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['uuid']>;
  person_type?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "omnichannel.persons" */
export type Omnichannel_Persons_Min_Order_By = {
  address?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  companyName?: Maybe<Order_By>;
  cpf_cnpj?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  facebook?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  instagram?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  person_type?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  website?: Maybe<Order_By>;
  whatsapp?: Maybe<Order_By>;
  zipcode?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.persons" */
export type Omnichannel_Persons_Mutation_Response = {
  __typename?: 'omnichannel_persons_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Persons>;
};

/** input type for inserting object relation for remote table "omnichannel.persons" */
export type Omnichannel_Persons_Obj_Rel_Insert_Input = {
  data: Omnichannel_Persons_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Persons_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.persons" */
export type Omnichannel_Persons_On_Conflict = {
  constraint: Omnichannel_Persons_Constraint;
  update_columns: Array<Omnichannel_Persons_Update_Column>;
  where?: Maybe<Omnichannel_Persons_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.persons" */
export type Omnichannel_Persons_Order_By = {
  active?: Maybe<Order_By>;
  activities_aggregate?: Maybe<Omnichannel_Person_Activities_Aggregate_Order_By>;
  address?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  companyName?: Maybe<Order_By>;
  cpf_cnpj?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  facebook?: Maybe<Order_By>;
  files_aggregate?: Maybe<Omnichannel_Person_File_Aggregate_Order_By>;
  group_user_aggregate?: Maybe<Omnichannel_Groups_Users_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  instagram?: Maybe<Order_By>;
  mail_aggregate?: Maybe<Omnichannel_Person_Mail_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  note_aggregate?: Maybe<Omnichannel_Person_Note_Aggregate_Order_By>;
  number?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  permissions?: Maybe<Memberships_Order_By>;
  person_type?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  ptypes?: Maybe<Omnichannel_Person_Type_Order_By>;
  state?: Maybe<Order_By>;
  tags_aggregate?: Maybe<Omnichannel_Person_Tags_Aggregate_Order_By>;
  teams?: Maybe<Organizations_Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  website?: Maybe<Order_By>;
  whatsapp?: Maybe<Order_By>;
  zipcode?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.persons" */
export type Omnichannel_Persons_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "omnichannel.persons" */
export enum Omnichannel_Persons_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Address = 'address',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  City = 'city',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  CpfCnpj = 'cpf_cnpj',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  Id = 'id',
  /** column name */
  Instagram = 'instagram',
  /** column name */
  Name = 'name',
  /** column name */
  Number = 'number',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  PersonType = 'person_type',
  /** column name */
  Phone = 'phone',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Website = 'website',
  /** column name */
  Whatsapp = 'whatsapp',
  /** column name */
  Zipcode = 'zipcode'
}

/** input type for updating data in table "omnichannel.persons" */
export type Omnichannel_Persons_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  cpf_cnpj?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  instagram?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['uuid']>;
  person_type?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Omnichannel_Persons_Stddev_Fields = {
  __typename?: 'omnichannel_persons_stddev_fields';
  organization_id?: Maybe<Scalars['Float']>;
  person_type?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "omnichannel.persons" */
export type Omnichannel_Persons_Stddev_Order_By = {
  organization_id?: Maybe<Order_By>;
  person_type?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Omnichannel_Persons_Stddev_Pop_Fields = {
  __typename?: 'omnichannel_persons_stddev_pop_fields';
  organization_id?: Maybe<Scalars['Float']>;
  person_type?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "omnichannel.persons" */
export type Omnichannel_Persons_Stddev_Pop_Order_By = {
  organization_id?: Maybe<Order_By>;
  person_type?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Omnichannel_Persons_Stddev_Samp_Fields = {
  __typename?: 'omnichannel_persons_stddev_samp_fields';
  organization_id?: Maybe<Scalars['Float']>;
  person_type?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "omnichannel.persons" */
export type Omnichannel_Persons_Stddev_Samp_Order_By = {
  organization_id?: Maybe<Order_By>;
  person_type?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Omnichannel_Persons_Sum_Fields = {
  __typename?: 'omnichannel_persons_sum_fields';
  organization_id?: Maybe<Scalars['Int']>;
  person_type?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "omnichannel.persons" */
export type Omnichannel_Persons_Sum_Order_By = {
  organization_id?: Maybe<Order_By>;
  person_type?: Maybe<Order_By>;
};

/** update columns of table "omnichannel.persons" */
export enum Omnichannel_Persons_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Address = 'address',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  City = 'city',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  CpfCnpj = 'cpf_cnpj',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  Id = 'id',
  /** column name */
  Instagram = 'instagram',
  /** column name */
  Name = 'name',
  /** column name */
  Number = 'number',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  PersonType = 'person_type',
  /** column name */
  Phone = 'phone',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Website = 'website',
  /** column name */
  Whatsapp = 'whatsapp',
  /** column name */
  Zipcode = 'zipcode'
}

/** aggregate var_pop on columns */
export type Omnichannel_Persons_Var_Pop_Fields = {
  __typename?: 'omnichannel_persons_var_pop_fields';
  organization_id?: Maybe<Scalars['Float']>;
  person_type?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "omnichannel.persons" */
export type Omnichannel_Persons_Var_Pop_Order_By = {
  organization_id?: Maybe<Order_By>;
  person_type?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Omnichannel_Persons_Var_Samp_Fields = {
  __typename?: 'omnichannel_persons_var_samp_fields';
  organization_id?: Maybe<Scalars['Float']>;
  person_type?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "omnichannel.persons" */
export type Omnichannel_Persons_Var_Samp_Order_By = {
  organization_id?: Maybe<Order_By>;
  person_type?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Omnichannel_Persons_Variance_Fields = {
  __typename?: 'omnichannel_persons_variance_fields';
  organization_id?: Maybe<Scalars['Float']>;
  person_type?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "omnichannel.persons" */
export type Omnichannel_Persons_Variance_Order_By = {
  organization_id?: Maybe<Order_By>;
  person_type?: Maybe<Order_By>;
};

/** columns and relationships of "omnichannel.pipeline" */
export type Omnichannel_Pipeline = {
  __typename?: 'omnichannel_pipeline';
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  lists: Array<Omnichannel_Pipeline_List>;
  /** An aggregated array relationship */
  lists_aggregate: Omnichannel_Pipeline_List_Aggregate;
  organization_id: Scalars['Int'];
  /** An array relationship */
  permissions: Array<Memberships>;
  /** An aggregated array relationship */
  permissions_aggregate: Memberships_Aggregate;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "omnichannel.pipeline" */
export type Omnichannel_PipelineListsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_List_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_List_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_List_Bool_Exp>;
};


/** columns and relationships of "omnichannel.pipeline" */
export type Omnichannel_PipelineLists_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_List_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_List_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_List_Bool_Exp>;
};


/** columns and relationships of "omnichannel.pipeline" */
export type Omnichannel_PipelinePermissionsArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};


/** columns and relationships of "omnichannel.pipeline" */
export type Omnichannel_PipelinePermissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};

/** aggregated selection of "omnichannel.pipeline" */
export type Omnichannel_Pipeline_Aggregate = {
  __typename?: 'omnichannel_pipeline_aggregate';
  aggregate?: Maybe<Omnichannel_Pipeline_Aggregate_Fields>;
  nodes: Array<Omnichannel_Pipeline>;
};

/** aggregate fields of "omnichannel.pipeline" */
export type Omnichannel_Pipeline_Aggregate_Fields = {
  __typename?: 'omnichannel_pipeline_aggregate_fields';
  avg?: Maybe<Omnichannel_Pipeline_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Pipeline_Max_Fields>;
  min?: Maybe<Omnichannel_Pipeline_Min_Fields>;
  stddev?: Maybe<Omnichannel_Pipeline_Stddev_Fields>;
  stddev_pop?: Maybe<Omnichannel_Pipeline_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Omnichannel_Pipeline_Stddev_Samp_Fields>;
  sum?: Maybe<Omnichannel_Pipeline_Sum_Fields>;
  var_pop?: Maybe<Omnichannel_Pipeline_Var_Pop_Fields>;
  var_samp?: Maybe<Omnichannel_Pipeline_Var_Samp_Fields>;
  variance?: Maybe<Omnichannel_Pipeline_Variance_Fields>;
};


/** aggregate fields of "omnichannel.pipeline" */
export type Omnichannel_Pipeline_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Pipeline_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.pipeline" */
export type Omnichannel_Pipeline_Aggregate_Order_By = {
  avg?: Maybe<Omnichannel_Pipeline_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Pipeline_Max_Order_By>;
  min?: Maybe<Omnichannel_Pipeline_Min_Order_By>;
  stddev?: Maybe<Omnichannel_Pipeline_Stddev_Order_By>;
  stddev_pop?: Maybe<Omnichannel_Pipeline_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Omnichannel_Pipeline_Stddev_Samp_Order_By>;
  sum?: Maybe<Omnichannel_Pipeline_Sum_Order_By>;
  var_pop?: Maybe<Omnichannel_Pipeline_Var_Pop_Order_By>;
  var_samp?: Maybe<Omnichannel_Pipeline_Var_Samp_Order_By>;
  variance?: Maybe<Omnichannel_Pipeline_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.pipeline" */
export type Omnichannel_Pipeline_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Pipeline_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Pipeline_On_Conflict>;
};

/** aggregate avg on columns */
export type Omnichannel_Pipeline_Avg_Fields = {
  __typename?: 'omnichannel_pipeline_avg_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "omnichannel.pipeline" */
export type Omnichannel_Pipeline_Avg_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "omnichannel.pipeline". All fields are combined with a logical 'AND'. */
export type Omnichannel_Pipeline_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Pipeline_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Pipeline_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Pipeline_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lists?: Maybe<Omnichannel_Pipeline_List_Bool_Exp>;
  organization_id?: Maybe<Int_Comparison_Exp>;
  permissions?: Maybe<Memberships_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card = {
  __typename?: 'omnichannel_pipeline_card';
  /** An array relationship */
  comments: Array<Omnichannel_Pipeline_Comments>;
  /** An aggregated array relationship */
  comments_aggregate: Omnichannel_Pipeline_Comments_Aggregate;
  created_at: Scalars['timestamptz'];
  date_close?: Maybe<Scalars['timestamptz']>;
  delete: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  group_user: Array<Omnichannel_Groups_Users>;
  /** An aggregated array relationship */
  group_user_aggregate: Omnichannel_Groups_Users_Aggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  list?: Maybe<Omnichannel_Pipeline_List>;
  order: Scalars['Int'];
  /** An object relationship */
  owner?: Maybe<Users>;
  owner_id: Scalars['uuid'];
  /** An object relationship */
  person?: Maybe<Omnichannel_Persons>;
  person_id: Scalars['uuid'];
  pipeline_list_id: Scalars['uuid'];
  pipeline_status_id: Scalars['uuid'];
  /** An array relationship */
  products: Array<Omnichannel_Pipeline_Products>;
  /** An aggregated array relationship */
  products_aggregate: Omnichannel_Pipeline_Products_Aggregate;
  /** An object relationship */
  status?: Maybe<Omnichannel_Pipeline_Status>;
  /** An array relationship */
  tags: Array<Omnichannel_Pipeline_Tags>;
  /** An aggregated array relationship */
  tags_aggregate: Omnichannel_Pipeline_Tags_Aggregate;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_CardCommentsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Comments_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Comments_Bool_Exp>;
};


/** columns and relationships of "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_CardComments_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Comments_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Comments_Bool_Exp>;
};


/** columns and relationships of "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_CardGroup_UserArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Groups_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Groups_Users_Order_By>>;
  where?: Maybe<Omnichannel_Groups_Users_Bool_Exp>;
};


/** columns and relationships of "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_CardGroup_User_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Groups_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Groups_Users_Order_By>>;
  where?: Maybe<Omnichannel_Groups_Users_Bool_Exp>;
};


/** columns and relationships of "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_CardProductsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Products_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Products_Bool_Exp>;
};


/** columns and relationships of "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_CardProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Products_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Products_Bool_Exp>;
};


/** columns and relationships of "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_CardTagsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Tags_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Tags_Bool_Exp>;
};


/** columns and relationships of "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_CardTags_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Tags_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Tags_Bool_Exp>;
};

/** aggregated selection of "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card_Aggregate = {
  __typename?: 'omnichannel_pipeline_card_aggregate';
  aggregate?: Maybe<Omnichannel_Pipeline_Card_Aggregate_Fields>;
  nodes: Array<Omnichannel_Pipeline_Card>;
};

/** aggregate fields of "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card_Aggregate_Fields = {
  __typename?: 'omnichannel_pipeline_card_aggregate_fields';
  avg?: Maybe<Omnichannel_Pipeline_Card_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Pipeline_Card_Max_Fields>;
  min?: Maybe<Omnichannel_Pipeline_Card_Min_Fields>;
  stddev?: Maybe<Omnichannel_Pipeline_Card_Stddev_Fields>;
  stddev_pop?: Maybe<Omnichannel_Pipeline_Card_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Omnichannel_Pipeline_Card_Stddev_Samp_Fields>;
  sum?: Maybe<Omnichannel_Pipeline_Card_Sum_Fields>;
  var_pop?: Maybe<Omnichannel_Pipeline_Card_Var_Pop_Fields>;
  var_samp?: Maybe<Omnichannel_Pipeline_Card_Var_Samp_Fields>;
  variance?: Maybe<Omnichannel_Pipeline_Card_Variance_Fields>;
};


/** aggregate fields of "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Pipeline_Card_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card_Aggregate_Order_By = {
  avg?: Maybe<Omnichannel_Pipeline_Card_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Pipeline_Card_Max_Order_By>;
  min?: Maybe<Omnichannel_Pipeline_Card_Min_Order_By>;
  stddev?: Maybe<Omnichannel_Pipeline_Card_Stddev_Order_By>;
  stddev_pop?: Maybe<Omnichannel_Pipeline_Card_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Omnichannel_Pipeline_Card_Stddev_Samp_Order_By>;
  sum?: Maybe<Omnichannel_Pipeline_Card_Sum_Order_By>;
  var_pop?: Maybe<Omnichannel_Pipeline_Card_Var_Pop_Order_By>;
  var_samp?: Maybe<Omnichannel_Pipeline_Card_Var_Samp_Order_By>;
  variance?: Maybe<Omnichannel_Pipeline_Card_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Pipeline_Card_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Pipeline_Card_On_Conflict>;
};

/** aggregate avg on columns */
export type Omnichannel_Pipeline_Card_Avg_Fields = {
  __typename?: 'omnichannel_pipeline_card_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card_Avg_Order_By = {
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "omnichannel.pipeline_card". All fields are combined with a logical 'AND'. */
export type Omnichannel_Pipeline_Card_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Pipeline_Card_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Pipeline_Card_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Pipeline_Card_Bool_Exp>>>;
  comments?: Maybe<Omnichannel_Pipeline_Comments_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  date_close?: Maybe<Timestamptz_Comparison_Exp>;
  delete?: Maybe<Boolean_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  group_user?: Maybe<Omnichannel_Groups_Users_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  list?: Maybe<Omnichannel_Pipeline_List_Bool_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  owner?: Maybe<Users_Bool_Exp>;
  owner_id?: Maybe<Uuid_Comparison_Exp>;
  person?: Maybe<Omnichannel_Persons_Bool_Exp>;
  person_id?: Maybe<Uuid_Comparison_Exp>;
  pipeline_list_id?: Maybe<Uuid_Comparison_Exp>;
  pipeline_status_id?: Maybe<Uuid_Comparison_Exp>;
  products?: Maybe<Omnichannel_Pipeline_Products_Bool_Exp>;
  status?: Maybe<Omnichannel_Pipeline_Status_Bool_Exp>;
  tags?: Maybe<Omnichannel_Pipeline_Tags_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.pipeline_card" */
export enum Omnichannel_Pipeline_Card_Constraint {
  /** unique or primary key constraint */
  PipelineCardPkey = 'pipeline_card_pkey'
}

/** input type for incrementing integer column in table "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card_Insert_Input = {
  comments?: Maybe<Omnichannel_Pipeline_Comments_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_close?: Maybe<Scalars['timestamptz']>;
  delete?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  group_user?: Maybe<Omnichannel_Groups_Users_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  list?: Maybe<Omnichannel_Pipeline_List_Obj_Rel_Insert_Input>;
  order?: Maybe<Scalars['Int']>;
  owner?: Maybe<Users_Obj_Rel_Insert_Input>;
  owner_id?: Maybe<Scalars['uuid']>;
  person?: Maybe<Omnichannel_Persons_Obj_Rel_Insert_Input>;
  person_id?: Maybe<Scalars['uuid']>;
  pipeline_list_id?: Maybe<Scalars['uuid']>;
  pipeline_status_id?: Maybe<Scalars['uuid']>;
  products?: Maybe<Omnichannel_Pipeline_Products_Arr_Rel_Insert_Input>;
  status?: Maybe<Omnichannel_Pipeline_Status_Obj_Rel_Insert_Input>;
  tags?: Maybe<Omnichannel_Pipeline_Tags_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Omnichannel_Pipeline_Card_Max_Fields = {
  __typename?: 'omnichannel_pipeline_card_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date_close?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
  pipeline_list_id?: Maybe<Scalars['uuid']>;
  pipeline_status_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  date_close?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
  pipeline_list_id?: Maybe<Order_By>;
  pipeline_status_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Pipeline_Card_Min_Fields = {
  __typename?: 'omnichannel_pipeline_card_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date_close?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
  pipeline_list_id?: Maybe<Scalars['uuid']>;
  pipeline_status_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  date_close?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  person_id?: Maybe<Order_By>;
  pipeline_list_id?: Maybe<Order_By>;
  pipeline_status_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card_Mutation_Response = {
  __typename?: 'omnichannel_pipeline_card_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Pipeline_Card>;
};

/** input type for inserting object relation for remote table "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card_Obj_Rel_Insert_Input = {
  data: Omnichannel_Pipeline_Card_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Pipeline_Card_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card_On_Conflict = {
  constraint: Omnichannel_Pipeline_Card_Constraint;
  update_columns: Array<Omnichannel_Pipeline_Card_Update_Column>;
  where?: Maybe<Omnichannel_Pipeline_Card_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card_Order_By = {
  comments_aggregate?: Maybe<Omnichannel_Pipeline_Comments_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  date_close?: Maybe<Order_By>;
  delete?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  group_user_aggregate?: Maybe<Omnichannel_Groups_Users_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  list?: Maybe<Omnichannel_Pipeline_List_Order_By>;
  order?: Maybe<Order_By>;
  owner?: Maybe<Users_Order_By>;
  owner_id?: Maybe<Order_By>;
  person?: Maybe<Omnichannel_Persons_Order_By>;
  person_id?: Maybe<Order_By>;
  pipeline_list_id?: Maybe<Order_By>;
  pipeline_status_id?: Maybe<Order_By>;
  products_aggregate?: Maybe<Omnichannel_Pipeline_Products_Aggregate_Order_By>;
  status?: Maybe<Omnichannel_Pipeline_Status_Order_By>;
  tags_aggregate?: Maybe<Omnichannel_Pipeline_Tags_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "omnichannel.pipeline_card" */
export enum Omnichannel_Pipeline_Card_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateClose = 'date_close',
  /** column name */
  Delete = 'delete',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  PipelineListId = 'pipeline_list_id',
  /** column name */
  PipelineStatusId = 'pipeline_status_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  date_close?: Maybe<Scalars['timestamptz']>;
  delete?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
  pipeline_list_id?: Maybe<Scalars['uuid']>;
  pipeline_status_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Omnichannel_Pipeline_Card_Stddev_Fields = {
  __typename?: 'omnichannel_pipeline_card_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card_Stddev_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Omnichannel_Pipeline_Card_Stddev_Pop_Fields = {
  __typename?: 'omnichannel_pipeline_card_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Omnichannel_Pipeline_Card_Stddev_Samp_Fields = {
  __typename?: 'omnichannel_pipeline_card_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Omnichannel_Pipeline_Card_Sum_Fields = {
  __typename?: 'omnichannel_pipeline_card_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card_Sum_Order_By = {
  order?: Maybe<Order_By>;
};

/** update columns of table "omnichannel.pipeline_card" */
export enum Omnichannel_Pipeline_Card_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateClose = 'date_close',
  /** column name */
  Delete = 'delete',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  PipelineListId = 'pipeline_list_id',
  /** column name */
  PipelineStatusId = 'pipeline_status_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Omnichannel_Pipeline_Card_Var_Pop_Fields = {
  __typename?: 'omnichannel_pipeline_card_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card_Var_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Omnichannel_Pipeline_Card_Var_Samp_Fields = {
  __typename?: 'omnichannel_pipeline_card_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card_Var_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Omnichannel_Pipeline_Card_Variance_Fields = {
  __typename?: 'omnichannel_pipeline_card_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "omnichannel.pipeline_card" */
export type Omnichannel_Pipeline_Card_Variance_Order_By = {
  order?: Maybe<Order_By>;
};

/** columns and relationships of "omnichannel.pipeline_comments" */
export type Omnichannel_Pipeline_Comments = {
  __typename?: 'omnichannel_pipeline_comments';
  /** An object relationship */
  card?: Maybe<Omnichannel_Pipeline_Card>;
  comment: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  pipeline_card_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "omnichannel.pipeline_comments" */
export type Omnichannel_Pipeline_Comments_Aggregate = {
  __typename?: 'omnichannel_pipeline_comments_aggregate';
  aggregate?: Maybe<Omnichannel_Pipeline_Comments_Aggregate_Fields>;
  nodes: Array<Omnichannel_Pipeline_Comments>;
};

/** aggregate fields of "omnichannel.pipeline_comments" */
export type Omnichannel_Pipeline_Comments_Aggregate_Fields = {
  __typename?: 'omnichannel_pipeline_comments_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Pipeline_Comments_Max_Fields>;
  min?: Maybe<Omnichannel_Pipeline_Comments_Min_Fields>;
};


/** aggregate fields of "omnichannel.pipeline_comments" */
export type Omnichannel_Pipeline_Comments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Pipeline_Comments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.pipeline_comments" */
export type Omnichannel_Pipeline_Comments_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Pipeline_Comments_Max_Order_By>;
  min?: Maybe<Omnichannel_Pipeline_Comments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.pipeline_comments" */
export type Omnichannel_Pipeline_Comments_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Pipeline_Comments_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Pipeline_Comments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "omnichannel.pipeline_comments". All fields are combined with a logical 'AND'. */
export type Omnichannel_Pipeline_Comments_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Pipeline_Comments_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Pipeline_Comments_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Pipeline_Comments_Bool_Exp>>>;
  card?: Maybe<Omnichannel_Pipeline_Card_Bool_Exp>;
  comment?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  pipeline_card_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.pipeline_comments" */
export enum Omnichannel_Pipeline_Comments_Constraint {
  /** unique or primary key constraint */
  PipelineCommentsPkey = 'pipeline_comments_pkey'
}

/** input type for inserting data into table "omnichannel.pipeline_comments" */
export type Omnichannel_Pipeline_Comments_Insert_Input = {
  card?: Maybe<Omnichannel_Pipeline_Card_Obj_Rel_Insert_Input>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  pipeline_card_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Omnichannel_Pipeline_Comments_Max_Fields = {
  __typename?: 'omnichannel_pipeline_comments_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  pipeline_card_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "omnichannel.pipeline_comments" */
export type Omnichannel_Pipeline_Comments_Max_Order_By = {
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pipeline_card_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Pipeline_Comments_Min_Fields = {
  __typename?: 'omnichannel_pipeline_comments_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  pipeline_card_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "omnichannel.pipeline_comments" */
export type Omnichannel_Pipeline_Comments_Min_Order_By = {
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pipeline_card_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.pipeline_comments" */
export type Omnichannel_Pipeline_Comments_Mutation_Response = {
  __typename?: 'omnichannel_pipeline_comments_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Pipeline_Comments>;
};

/** input type for inserting object relation for remote table "omnichannel.pipeline_comments" */
export type Omnichannel_Pipeline_Comments_Obj_Rel_Insert_Input = {
  data: Omnichannel_Pipeline_Comments_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Pipeline_Comments_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.pipeline_comments" */
export type Omnichannel_Pipeline_Comments_On_Conflict = {
  constraint: Omnichannel_Pipeline_Comments_Constraint;
  update_columns: Array<Omnichannel_Pipeline_Comments_Update_Column>;
  where?: Maybe<Omnichannel_Pipeline_Comments_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.pipeline_comments" */
export type Omnichannel_Pipeline_Comments_Order_By = {
  card?: Maybe<Omnichannel_Pipeline_Card_Order_By>;
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pipeline_card_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.pipeline_comments" */
export type Omnichannel_Pipeline_Comments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "omnichannel.pipeline_comments" */
export enum Omnichannel_Pipeline_Comments_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PipelineCardId = 'pipeline_card_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "omnichannel.pipeline_comments" */
export type Omnichannel_Pipeline_Comments_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  pipeline_card_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "omnichannel.pipeline_comments" */
export enum Omnichannel_Pipeline_Comments_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PipelineCardId = 'pipeline_card_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** unique or primary key constraints on table "omnichannel.pipeline" */
export enum Omnichannel_Pipeline_Constraint {
  /** unique or primary key constraint */
  PipelinePkey = 'pipeline_pkey'
}

/** input type for incrementing integer column in table "omnichannel.pipeline" */
export type Omnichannel_Pipeline_Inc_Input = {
  organization_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "omnichannel.pipeline" */
export type Omnichannel_Pipeline_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lists?: Maybe<Omnichannel_Pipeline_List_Arr_Rel_Insert_Input>;
  organization_id?: Maybe<Scalars['Int']>;
  permissions?: Maybe<Memberships_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List = {
  __typename?: 'omnichannel_pipeline_list';
  /** An array relationship */
  actions: Array<Omnichannel_Pipeline_List_Actions>;
  /** An aggregated array relationship */
  actions_aggregate: Omnichannel_Pipeline_List_Actions_Aggregate;
  /** An array relationship */
  cards: Array<Omnichannel_Pipeline_Card>;
  /** An aggregated array relationship */
  cards_aggregate: Omnichannel_Pipeline_Card_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  order: Scalars['Int'];
  /** An object relationship */
  pipeline?: Maybe<Omnichannel_Pipeline>;
  pipeline_id: Scalars['uuid'];
  start: Scalars['Boolean'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_ListActionsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_List_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_List_Actions_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_List_Actions_Bool_Exp>;
};


/** columns and relationships of "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_ListActions_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_List_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_List_Actions_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_List_Actions_Bool_Exp>;
};


/** columns and relationships of "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_ListCardsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Card_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Card_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Card_Bool_Exp>;
};


/** columns and relationships of "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_ListCards_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Card_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Card_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Card_Bool_Exp>;
};

/** columns and relationships of "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions = {
  __typename?: 'omnichannel_pipeline_list_actions';
  /** An object relationship */
  action?: Maybe<Omnichannel_Pipeline_List>;
  action_list_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  list?: Maybe<Omnichannel_Pipeline_List>;
  pipeline_list_id: Scalars['uuid'];
};

/** aggregated selection of "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions_Aggregate = {
  __typename?: 'omnichannel_pipeline_list_actions_aggregate';
  aggregate?: Maybe<Omnichannel_Pipeline_List_Actions_Aggregate_Fields>;
  nodes: Array<Omnichannel_Pipeline_List_Actions>;
};

/** aggregate fields of "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions_Aggregate_Fields = {
  __typename?: 'omnichannel_pipeline_list_actions_aggregate_fields';
  avg?: Maybe<Omnichannel_Pipeline_List_Actions_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Pipeline_List_Actions_Max_Fields>;
  min?: Maybe<Omnichannel_Pipeline_List_Actions_Min_Fields>;
  stddev?: Maybe<Omnichannel_Pipeline_List_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Omnichannel_Pipeline_List_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Omnichannel_Pipeline_List_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Omnichannel_Pipeline_List_Actions_Sum_Fields>;
  var_pop?: Maybe<Omnichannel_Pipeline_List_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Omnichannel_Pipeline_List_Actions_Var_Samp_Fields>;
  variance?: Maybe<Omnichannel_Pipeline_List_Actions_Variance_Fields>;
};


/** aggregate fields of "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Pipeline_List_Actions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions_Aggregate_Order_By = {
  avg?: Maybe<Omnichannel_Pipeline_List_Actions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Pipeline_List_Actions_Max_Order_By>;
  min?: Maybe<Omnichannel_Pipeline_List_Actions_Min_Order_By>;
  stddev?: Maybe<Omnichannel_Pipeline_List_Actions_Stddev_Order_By>;
  stddev_pop?: Maybe<Omnichannel_Pipeline_List_Actions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Omnichannel_Pipeline_List_Actions_Stddev_Samp_Order_By>;
  sum?: Maybe<Omnichannel_Pipeline_List_Actions_Sum_Order_By>;
  var_pop?: Maybe<Omnichannel_Pipeline_List_Actions_Var_Pop_Order_By>;
  var_samp?: Maybe<Omnichannel_Pipeline_List_Actions_Var_Samp_Order_By>;
  variance?: Maybe<Omnichannel_Pipeline_List_Actions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Pipeline_List_Actions_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Pipeline_List_Actions_On_Conflict>;
};

/** aggregate avg on columns */
export type Omnichannel_Pipeline_List_Actions_Avg_Fields = {
  __typename?: 'omnichannel_pipeline_list_actions_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "omnichannel.pipeline_list_actions". All fields are combined with a logical 'AND'. */
export type Omnichannel_Pipeline_List_Actions_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Pipeline_List_Actions_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Pipeline_List_Actions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Pipeline_List_Actions_Bool_Exp>>>;
  action?: Maybe<Omnichannel_Pipeline_List_Bool_Exp>;
  action_list_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  list?: Maybe<Omnichannel_Pipeline_List_Bool_Exp>;
  pipeline_list_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.pipeline_list_actions" */
export enum Omnichannel_Pipeline_List_Actions_Constraint {
  /** unique or primary key constraint */
  PipelineListActionsPkey = 'pipeline_list_actions_pkey'
}

/** input type for incrementing integer column in table "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions_Insert_Input = {
  action?: Maybe<Omnichannel_Pipeline_List_Obj_Rel_Insert_Input>;
  action_list_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  list?: Maybe<Omnichannel_Pipeline_List_Obj_Rel_Insert_Input>;
  pipeline_list_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Omnichannel_Pipeline_List_Actions_Max_Fields = {
  __typename?: 'omnichannel_pipeline_list_actions_max_fields';
  action_list_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  pipeline_list_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions_Max_Order_By = {
  action_list_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pipeline_list_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Pipeline_List_Actions_Min_Fields = {
  __typename?: 'omnichannel_pipeline_list_actions_min_fields';
  action_list_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  pipeline_list_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions_Min_Order_By = {
  action_list_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pipeline_list_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions_Mutation_Response = {
  __typename?: 'omnichannel_pipeline_list_actions_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Pipeline_List_Actions>;
};

/** input type for inserting object relation for remote table "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions_Obj_Rel_Insert_Input = {
  data: Omnichannel_Pipeline_List_Actions_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Pipeline_List_Actions_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions_On_Conflict = {
  constraint: Omnichannel_Pipeline_List_Actions_Constraint;
  update_columns: Array<Omnichannel_Pipeline_List_Actions_Update_Column>;
  where?: Maybe<Omnichannel_Pipeline_List_Actions_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions_Order_By = {
  action?: Maybe<Omnichannel_Pipeline_List_Order_By>;
  action_list_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  list?: Maybe<Omnichannel_Pipeline_List_Order_By>;
  pipeline_list_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "omnichannel.pipeline_list_actions" */
export enum Omnichannel_Pipeline_List_Actions_Select_Column {
  /** column name */
  ActionListId = 'action_list_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PipelineListId = 'pipeline_list_id'
}

/** input type for updating data in table "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions_Set_Input = {
  action_list_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  pipeline_list_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Omnichannel_Pipeline_List_Actions_Stddev_Fields = {
  __typename?: 'omnichannel_pipeline_list_actions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Omnichannel_Pipeline_List_Actions_Stddev_Pop_Fields = {
  __typename?: 'omnichannel_pipeline_list_actions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Omnichannel_Pipeline_List_Actions_Stddev_Samp_Fields = {
  __typename?: 'omnichannel_pipeline_list_actions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Omnichannel_Pipeline_List_Actions_Sum_Fields = {
  __typename?: 'omnichannel_pipeline_list_actions_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "omnichannel.pipeline_list_actions" */
export enum Omnichannel_Pipeline_List_Actions_Update_Column {
  /** column name */
  ActionListId = 'action_list_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PipelineListId = 'pipeline_list_id'
}

/** aggregate var_pop on columns */
export type Omnichannel_Pipeline_List_Actions_Var_Pop_Fields = {
  __typename?: 'omnichannel_pipeline_list_actions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Omnichannel_Pipeline_List_Actions_Var_Samp_Fields = {
  __typename?: 'omnichannel_pipeline_list_actions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Omnichannel_Pipeline_List_Actions_Variance_Fields = {
  __typename?: 'omnichannel_pipeline_list_actions_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "omnichannel.pipeline_list_actions" */
export type Omnichannel_Pipeline_List_Actions_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregated selection of "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List_Aggregate = {
  __typename?: 'omnichannel_pipeline_list_aggregate';
  aggregate?: Maybe<Omnichannel_Pipeline_List_Aggregate_Fields>;
  nodes: Array<Omnichannel_Pipeline_List>;
};

/** aggregate fields of "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List_Aggregate_Fields = {
  __typename?: 'omnichannel_pipeline_list_aggregate_fields';
  avg?: Maybe<Omnichannel_Pipeline_List_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Pipeline_List_Max_Fields>;
  min?: Maybe<Omnichannel_Pipeline_List_Min_Fields>;
  stddev?: Maybe<Omnichannel_Pipeline_List_Stddev_Fields>;
  stddev_pop?: Maybe<Omnichannel_Pipeline_List_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Omnichannel_Pipeline_List_Stddev_Samp_Fields>;
  sum?: Maybe<Omnichannel_Pipeline_List_Sum_Fields>;
  var_pop?: Maybe<Omnichannel_Pipeline_List_Var_Pop_Fields>;
  var_samp?: Maybe<Omnichannel_Pipeline_List_Var_Samp_Fields>;
  variance?: Maybe<Omnichannel_Pipeline_List_Variance_Fields>;
};


/** aggregate fields of "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Pipeline_List_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List_Aggregate_Order_By = {
  avg?: Maybe<Omnichannel_Pipeline_List_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Pipeline_List_Max_Order_By>;
  min?: Maybe<Omnichannel_Pipeline_List_Min_Order_By>;
  stddev?: Maybe<Omnichannel_Pipeline_List_Stddev_Order_By>;
  stddev_pop?: Maybe<Omnichannel_Pipeline_List_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Omnichannel_Pipeline_List_Stddev_Samp_Order_By>;
  sum?: Maybe<Omnichannel_Pipeline_List_Sum_Order_By>;
  var_pop?: Maybe<Omnichannel_Pipeline_List_Var_Pop_Order_By>;
  var_samp?: Maybe<Omnichannel_Pipeline_List_Var_Samp_Order_By>;
  variance?: Maybe<Omnichannel_Pipeline_List_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Pipeline_List_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Pipeline_List_On_Conflict>;
};

/** aggregate avg on columns */
export type Omnichannel_Pipeline_List_Avg_Fields = {
  __typename?: 'omnichannel_pipeline_list_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List_Avg_Order_By = {
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "omnichannel.pipeline_list". All fields are combined with a logical 'AND'. */
export type Omnichannel_Pipeline_List_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Pipeline_List_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Pipeline_List_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Pipeline_List_Bool_Exp>>>;
  actions?: Maybe<Omnichannel_Pipeline_List_Actions_Bool_Exp>;
  cards?: Maybe<Omnichannel_Pipeline_Card_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  pipeline?: Maybe<Omnichannel_Pipeline_Bool_Exp>;
  pipeline_id?: Maybe<Uuid_Comparison_Exp>;
  start?: Maybe<Boolean_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.pipeline_list" */
export enum Omnichannel_Pipeline_List_Constraint {
  /** unique or primary key constraint */
  PipelineListPkey = 'pipeline_list_pkey'
}

/** input type for incrementing integer column in table "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List_Insert_Input = {
  actions?: Maybe<Omnichannel_Pipeline_List_Actions_Arr_Rel_Insert_Input>;
  cards?: Maybe<Omnichannel_Pipeline_Card_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  pipeline?: Maybe<Omnichannel_Pipeline_Obj_Rel_Insert_Input>;
  pipeline_id?: Maybe<Scalars['uuid']>;
  start?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Omnichannel_Pipeline_List_Max_Fields = {
  __typename?: 'omnichannel_pipeline_list_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  pipeline_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Pipeline_List_Min_Fields = {
  __typename?: 'omnichannel_pipeline_list_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  pipeline_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  pipeline_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List_Mutation_Response = {
  __typename?: 'omnichannel_pipeline_list_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Pipeline_List>;
};

/** input type for inserting object relation for remote table "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List_Obj_Rel_Insert_Input = {
  data: Omnichannel_Pipeline_List_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Pipeline_List_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List_On_Conflict = {
  constraint: Omnichannel_Pipeline_List_Constraint;
  update_columns: Array<Omnichannel_Pipeline_List_Update_Column>;
  where?: Maybe<Omnichannel_Pipeline_List_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List_Order_By = {
  actions_aggregate?: Maybe<Omnichannel_Pipeline_List_Actions_Aggregate_Order_By>;
  cards_aggregate?: Maybe<Omnichannel_Pipeline_Card_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  pipeline?: Maybe<Omnichannel_Pipeline_Order_By>;
  pipeline_id?: Maybe<Order_By>;
  start?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "omnichannel.pipeline_list" */
export enum Omnichannel_Pipeline_List_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  PipelineId = 'pipeline_id',
  /** column name */
  Start = 'start',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  pipeline_id?: Maybe<Scalars['uuid']>;
  start?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Omnichannel_Pipeline_List_Stddev_Fields = {
  __typename?: 'omnichannel_pipeline_list_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List_Stddev_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Omnichannel_Pipeline_List_Stddev_Pop_Fields = {
  __typename?: 'omnichannel_pipeline_list_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Omnichannel_Pipeline_List_Stddev_Samp_Fields = {
  __typename?: 'omnichannel_pipeline_list_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Omnichannel_Pipeline_List_Sum_Fields = {
  __typename?: 'omnichannel_pipeline_list_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List_Sum_Order_By = {
  order?: Maybe<Order_By>;
};

/** update columns of table "omnichannel.pipeline_list" */
export enum Omnichannel_Pipeline_List_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  PipelineId = 'pipeline_id',
  /** column name */
  Start = 'start',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Omnichannel_Pipeline_List_Var_Pop_Fields = {
  __typename?: 'omnichannel_pipeline_list_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List_Var_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Omnichannel_Pipeline_List_Var_Samp_Fields = {
  __typename?: 'omnichannel_pipeline_list_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List_Var_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Omnichannel_Pipeline_List_Variance_Fields = {
  __typename?: 'omnichannel_pipeline_list_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "omnichannel.pipeline_list" */
export type Omnichannel_Pipeline_List_Variance_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Omnichannel_Pipeline_Max_Fields = {
  __typename?: 'omnichannel_pipeline_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "omnichannel.pipeline" */
export type Omnichannel_Pipeline_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Pipeline_Min_Fields = {
  __typename?: 'omnichannel_pipeline_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "omnichannel.pipeline" */
export type Omnichannel_Pipeline_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.pipeline" */
export type Omnichannel_Pipeline_Mutation_Response = {
  __typename?: 'omnichannel_pipeline_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Pipeline>;
};

/** input type for inserting object relation for remote table "omnichannel.pipeline" */
export type Omnichannel_Pipeline_Obj_Rel_Insert_Input = {
  data: Omnichannel_Pipeline_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Pipeline_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.pipeline" */
export type Omnichannel_Pipeline_On_Conflict = {
  constraint: Omnichannel_Pipeline_Constraint;
  update_columns: Array<Omnichannel_Pipeline_Update_Column>;
  where?: Maybe<Omnichannel_Pipeline_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.pipeline" */
export type Omnichannel_Pipeline_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lists_aggregate?: Maybe<Omnichannel_Pipeline_List_Aggregate_Order_By>;
  organization_id?: Maybe<Order_By>;
  permissions_aggregate?: Maybe<Memberships_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.pipeline" */
export type Omnichannel_Pipeline_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products = {
  __typename?: 'omnichannel_pipeline_products';
  amount?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  pipeline_card?: Maybe<Omnichannel_Pipeline_Card>;
  pipeline_card_id: Scalars['uuid'];
  /** An object relationship */
  product?: Maybe<Omnichannel_Products>;
  product_id: Scalars['uuid'];
};

/** aggregated selection of "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products_Aggregate = {
  __typename?: 'omnichannel_pipeline_products_aggregate';
  aggregate?: Maybe<Omnichannel_Pipeline_Products_Aggregate_Fields>;
  nodes: Array<Omnichannel_Pipeline_Products>;
};

/** aggregate fields of "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products_Aggregate_Fields = {
  __typename?: 'omnichannel_pipeline_products_aggregate_fields';
  avg?: Maybe<Omnichannel_Pipeline_Products_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Pipeline_Products_Max_Fields>;
  min?: Maybe<Omnichannel_Pipeline_Products_Min_Fields>;
  stddev?: Maybe<Omnichannel_Pipeline_Products_Stddev_Fields>;
  stddev_pop?: Maybe<Omnichannel_Pipeline_Products_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Omnichannel_Pipeline_Products_Stddev_Samp_Fields>;
  sum?: Maybe<Omnichannel_Pipeline_Products_Sum_Fields>;
  var_pop?: Maybe<Omnichannel_Pipeline_Products_Var_Pop_Fields>;
  var_samp?: Maybe<Omnichannel_Pipeline_Products_Var_Samp_Fields>;
  variance?: Maybe<Omnichannel_Pipeline_Products_Variance_Fields>;
};


/** aggregate fields of "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Pipeline_Products_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products_Aggregate_Order_By = {
  avg?: Maybe<Omnichannel_Pipeline_Products_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Pipeline_Products_Max_Order_By>;
  min?: Maybe<Omnichannel_Pipeline_Products_Min_Order_By>;
  stddev?: Maybe<Omnichannel_Pipeline_Products_Stddev_Order_By>;
  stddev_pop?: Maybe<Omnichannel_Pipeline_Products_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Omnichannel_Pipeline_Products_Stddev_Samp_Order_By>;
  sum?: Maybe<Omnichannel_Pipeline_Products_Sum_Order_By>;
  var_pop?: Maybe<Omnichannel_Pipeline_Products_Var_Pop_Order_By>;
  var_samp?: Maybe<Omnichannel_Pipeline_Products_Var_Samp_Order_By>;
  variance?: Maybe<Omnichannel_Pipeline_Products_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Pipeline_Products_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Pipeline_Products_On_Conflict>;
};

/** aggregate avg on columns */
export type Omnichannel_Pipeline_Products_Avg_Fields = {
  __typename?: 'omnichannel_pipeline_products_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products_Avg_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "omnichannel.pipeline_products". All fields are combined with a logical 'AND'. */
export type Omnichannel_Pipeline_Products_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Pipeline_Products_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Pipeline_Products_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Pipeline_Products_Bool_Exp>>>;
  amount?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  pipeline_card?: Maybe<Omnichannel_Pipeline_Card_Bool_Exp>;
  pipeline_card_id?: Maybe<Uuid_Comparison_Exp>;
  product?: Maybe<Omnichannel_Products_Bool_Exp>;
  product_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.pipeline_products" */
export enum Omnichannel_Pipeline_Products_Constraint {
  /** unique or primary key constraint */
  PipelineProductsPipelineCardIdProductIdKey = 'pipeline_products_pipeline_card_id_product_id_key',
  /** unique or primary key constraint */
  PipelineProductsPkey = 'pipeline_products_pkey'
}

/** input type for incrementing integer column in table "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products_Inc_Input = {
  amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products_Insert_Input = {
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  pipeline_card?: Maybe<Omnichannel_Pipeline_Card_Obj_Rel_Insert_Input>;
  pipeline_card_id?: Maybe<Scalars['uuid']>;
  product?: Maybe<Omnichannel_Products_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Omnichannel_Pipeline_Products_Max_Fields = {
  __typename?: 'omnichannel_pipeline_products_max_fields';
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  pipeline_card_id?: Maybe<Scalars['uuid']>;
  product_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products_Max_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pipeline_card_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Pipeline_Products_Min_Fields = {
  __typename?: 'omnichannel_pipeline_products_min_fields';
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  pipeline_card_id?: Maybe<Scalars['uuid']>;
  product_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products_Min_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pipeline_card_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products_Mutation_Response = {
  __typename?: 'omnichannel_pipeline_products_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Pipeline_Products>;
};

/** input type for inserting object relation for remote table "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products_Obj_Rel_Insert_Input = {
  data: Omnichannel_Pipeline_Products_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Pipeline_Products_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products_On_Conflict = {
  constraint: Omnichannel_Pipeline_Products_Constraint;
  update_columns: Array<Omnichannel_Pipeline_Products_Update_Column>;
  where?: Maybe<Omnichannel_Pipeline_Products_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pipeline_card?: Maybe<Omnichannel_Pipeline_Card_Order_By>;
  pipeline_card_id?: Maybe<Order_By>;
  product?: Maybe<Omnichannel_Products_Order_By>;
  product_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "omnichannel.pipeline_products" */
export enum Omnichannel_Pipeline_Products_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PipelineCardId = 'pipeline_card_id',
  /** column name */
  ProductId = 'product_id'
}

/** input type for updating data in table "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products_Set_Input = {
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  pipeline_card_id?: Maybe<Scalars['uuid']>;
  product_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Omnichannel_Pipeline_Products_Stddev_Fields = {
  __typename?: 'omnichannel_pipeline_products_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Omnichannel_Pipeline_Products_Stddev_Pop_Fields = {
  __typename?: 'omnichannel_pipeline_products_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Omnichannel_Pipeline_Products_Stddev_Samp_Fields = {
  __typename?: 'omnichannel_pipeline_products_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Omnichannel_Pipeline_Products_Sum_Fields = {
  __typename?: 'omnichannel_pipeline_products_sum_fields';
  amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products_Sum_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "omnichannel.pipeline_products" */
export enum Omnichannel_Pipeline_Products_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PipelineCardId = 'pipeline_card_id',
  /** column name */
  ProductId = 'product_id'
}

/** aggregate var_pop on columns */
export type Omnichannel_Pipeline_Products_Var_Pop_Fields = {
  __typename?: 'omnichannel_pipeline_products_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Omnichannel_Pipeline_Products_Var_Samp_Fields = {
  __typename?: 'omnichannel_pipeline_products_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Omnichannel_Pipeline_Products_Variance_Fields = {
  __typename?: 'omnichannel_pipeline_products_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "omnichannel.pipeline_products" */
export type Omnichannel_Pipeline_Products_Variance_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** select columns of table "omnichannel.pipeline" */
export enum Omnichannel_Pipeline_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "omnichannel.pipeline" */
export type Omnichannel_Pipeline_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status = {
  __typename?: 'omnichannel_pipeline_status';
  color: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  organization_id: Scalars['Int'];
  /** An array relationship */
  permission: Array<Memberships>;
  /** An aggregated array relationship */
  permission_aggregate: Memberships_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_StatusPermissionArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};


/** columns and relationships of "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_StatusPermission_AggregateArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};

/** aggregated selection of "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status_Aggregate = {
  __typename?: 'omnichannel_pipeline_status_aggregate';
  aggregate?: Maybe<Omnichannel_Pipeline_Status_Aggregate_Fields>;
  nodes: Array<Omnichannel_Pipeline_Status>;
};

/** aggregate fields of "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status_Aggregate_Fields = {
  __typename?: 'omnichannel_pipeline_status_aggregate_fields';
  avg?: Maybe<Omnichannel_Pipeline_Status_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Pipeline_Status_Max_Fields>;
  min?: Maybe<Omnichannel_Pipeline_Status_Min_Fields>;
  stddev?: Maybe<Omnichannel_Pipeline_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Omnichannel_Pipeline_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Omnichannel_Pipeline_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Omnichannel_Pipeline_Status_Sum_Fields>;
  var_pop?: Maybe<Omnichannel_Pipeline_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Omnichannel_Pipeline_Status_Var_Samp_Fields>;
  variance?: Maybe<Omnichannel_Pipeline_Status_Variance_Fields>;
};


/** aggregate fields of "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Pipeline_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status_Aggregate_Order_By = {
  avg?: Maybe<Omnichannel_Pipeline_Status_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Pipeline_Status_Max_Order_By>;
  min?: Maybe<Omnichannel_Pipeline_Status_Min_Order_By>;
  stddev?: Maybe<Omnichannel_Pipeline_Status_Stddev_Order_By>;
  stddev_pop?: Maybe<Omnichannel_Pipeline_Status_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Omnichannel_Pipeline_Status_Stddev_Samp_Order_By>;
  sum?: Maybe<Omnichannel_Pipeline_Status_Sum_Order_By>;
  var_pop?: Maybe<Omnichannel_Pipeline_Status_Var_Pop_Order_By>;
  var_samp?: Maybe<Omnichannel_Pipeline_Status_Var_Samp_Order_By>;
  variance?: Maybe<Omnichannel_Pipeline_Status_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Pipeline_Status_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Pipeline_Status_On_Conflict>;
};

/** aggregate avg on columns */
export type Omnichannel_Pipeline_Status_Avg_Fields = {
  __typename?: 'omnichannel_pipeline_status_avg_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status_Avg_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "omnichannel.pipeline_status". All fields are combined with a logical 'AND'. */
export type Omnichannel_Pipeline_Status_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Pipeline_Status_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Pipeline_Status_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Pipeline_Status_Bool_Exp>>>;
  color?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  organization_id?: Maybe<Int_Comparison_Exp>;
  permission?: Maybe<Memberships_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.pipeline_status" */
export enum Omnichannel_Pipeline_Status_Constraint {
  /** unique or primary key constraint */
  PipelineStatusPkey = 'pipeline_status_pkey'
}

/** input type for incrementing integer column in table "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status_Inc_Input = {
  organization_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status_Insert_Input = {
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  permission?: Maybe<Memberships_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Omnichannel_Pipeline_Status_Max_Fields = {
  __typename?: 'omnichannel_pipeline_status_max_fields';
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status_Max_Order_By = {
  color?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Pipeline_Status_Min_Fields = {
  __typename?: 'omnichannel_pipeline_status_min_fields';
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status_Min_Order_By = {
  color?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status_Mutation_Response = {
  __typename?: 'omnichannel_pipeline_status_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Pipeline_Status>;
};

/** input type for inserting object relation for remote table "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status_Obj_Rel_Insert_Input = {
  data: Omnichannel_Pipeline_Status_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Pipeline_Status_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status_On_Conflict = {
  constraint: Omnichannel_Pipeline_Status_Constraint;
  update_columns: Array<Omnichannel_Pipeline_Status_Update_Column>;
  where?: Maybe<Omnichannel_Pipeline_Status_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status_Order_By = {
  color?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  permission_aggregate?: Maybe<Memberships_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "omnichannel.pipeline_status" */
export enum Omnichannel_Pipeline_Status_Select_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status_Set_Input = {
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Omnichannel_Pipeline_Status_Stddev_Fields = {
  __typename?: 'omnichannel_pipeline_status_stddev_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status_Stddev_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Omnichannel_Pipeline_Status_Stddev_Pop_Fields = {
  __typename?: 'omnichannel_pipeline_status_stddev_pop_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status_Stddev_Pop_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Omnichannel_Pipeline_Status_Stddev_Samp_Fields = {
  __typename?: 'omnichannel_pipeline_status_stddev_samp_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status_Stddev_Samp_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Omnichannel_Pipeline_Status_Sum_Fields = {
  __typename?: 'omnichannel_pipeline_status_sum_fields';
  organization_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status_Sum_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** update columns of table "omnichannel.pipeline_status" */
export enum Omnichannel_Pipeline_Status_Update_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Omnichannel_Pipeline_Status_Var_Pop_Fields = {
  __typename?: 'omnichannel_pipeline_status_var_pop_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status_Var_Pop_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Omnichannel_Pipeline_Status_Var_Samp_Fields = {
  __typename?: 'omnichannel_pipeline_status_var_samp_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status_Var_Samp_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Omnichannel_Pipeline_Status_Variance_Fields = {
  __typename?: 'omnichannel_pipeline_status_variance_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "omnichannel.pipeline_status" */
export type Omnichannel_Pipeline_Status_Variance_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Omnichannel_Pipeline_Stddev_Fields = {
  __typename?: 'omnichannel_pipeline_stddev_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "omnichannel.pipeline" */
export type Omnichannel_Pipeline_Stddev_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Omnichannel_Pipeline_Stddev_Pop_Fields = {
  __typename?: 'omnichannel_pipeline_stddev_pop_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "omnichannel.pipeline" */
export type Omnichannel_Pipeline_Stddev_Pop_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Omnichannel_Pipeline_Stddev_Samp_Fields = {
  __typename?: 'omnichannel_pipeline_stddev_samp_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "omnichannel.pipeline" */
export type Omnichannel_Pipeline_Stddev_Samp_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Omnichannel_Pipeline_Sum_Fields = {
  __typename?: 'omnichannel_pipeline_sum_fields';
  organization_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "omnichannel.pipeline" */
export type Omnichannel_Pipeline_Sum_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** columns and relationships of "omnichannel.pipeline_tags" */
export type Omnichannel_Pipeline_Tags = {
  __typename?: 'omnichannel_pipeline_tags';
  /** An object relationship */
  card?: Maybe<Omnichannel_Pipeline_Card>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  pipeline_card_id: Scalars['uuid'];
  /** An object relationship */
  tag?: Maybe<Omnichannel_Tags>;
  tag_id: Scalars['uuid'];
};

/** aggregated selection of "omnichannel.pipeline_tags" */
export type Omnichannel_Pipeline_Tags_Aggregate = {
  __typename?: 'omnichannel_pipeline_tags_aggregate';
  aggregate?: Maybe<Omnichannel_Pipeline_Tags_Aggregate_Fields>;
  nodes: Array<Omnichannel_Pipeline_Tags>;
};

/** aggregate fields of "omnichannel.pipeline_tags" */
export type Omnichannel_Pipeline_Tags_Aggregate_Fields = {
  __typename?: 'omnichannel_pipeline_tags_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Pipeline_Tags_Max_Fields>;
  min?: Maybe<Omnichannel_Pipeline_Tags_Min_Fields>;
};


/** aggregate fields of "omnichannel.pipeline_tags" */
export type Omnichannel_Pipeline_Tags_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Pipeline_Tags_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.pipeline_tags" */
export type Omnichannel_Pipeline_Tags_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Pipeline_Tags_Max_Order_By>;
  min?: Maybe<Omnichannel_Pipeline_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.pipeline_tags" */
export type Omnichannel_Pipeline_Tags_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Pipeline_Tags_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Pipeline_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "omnichannel.pipeline_tags". All fields are combined with a logical 'AND'. */
export type Omnichannel_Pipeline_Tags_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Pipeline_Tags_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Pipeline_Tags_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Pipeline_Tags_Bool_Exp>>>;
  card?: Maybe<Omnichannel_Pipeline_Card_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  pipeline_card_id?: Maybe<Uuid_Comparison_Exp>;
  tag?: Maybe<Omnichannel_Tags_Bool_Exp>;
  tag_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.pipeline_tags" */
export enum Omnichannel_Pipeline_Tags_Constraint {
  /** unique or primary key constraint */
  PipelineTagsPkey = 'pipeline_tags_pkey'
}

/** input type for inserting data into table "omnichannel.pipeline_tags" */
export type Omnichannel_Pipeline_Tags_Insert_Input = {
  card?: Maybe<Omnichannel_Pipeline_Card_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  pipeline_card_id?: Maybe<Scalars['uuid']>;
  tag?: Maybe<Omnichannel_Tags_Obj_Rel_Insert_Input>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Omnichannel_Pipeline_Tags_Max_Fields = {
  __typename?: 'omnichannel_pipeline_tags_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  pipeline_card_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "omnichannel.pipeline_tags" */
export type Omnichannel_Pipeline_Tags_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pipeline_card_id?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Pipeline_Tags_Min_Fields = {
  __typename?: 'omnichannel_pipeline_tags_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  pipeline_card_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "omnichannel.pipeline_tags" */
export type Omnichannel_Pipeline_Tags_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pipeline_card_id?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.pipeline_tags" */
export type Omnichannel_Pipeline_Tags_Mutation_Response = {
  __typename?: 'omnichannel_pipeline_tags_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Pipeline_Tags>;
};

/** input type for inserting object relation for remote table "omnichannel.pipeline_tags" */
export type Omnichannel_Pipeline_Tags_Obj_Rel_Insert_Input = {
  data: Omnichannel_Pipeline_Tags_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Pipeline_Tags_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.pipeline_tags" */
export type Omnichannel_Pipeline_Tags_On_Conflict = {
  constraint: Omnichannel_Pipeline_Tags_Constraint;
  update_columns: Array<Omnichannel_Pipeline_Tags_Update_Column>;
  where?: Maybe<Omnichannel_Pipeline_Tags_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.pipeline_tags" */
export type Omnichannel_Pipeline_Tags_Order_By = {
  card?: Maybe<Omnichannel_Pipeline_Card_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pipeline_card_id?: Maybe<Order_By>;
  tag?: Maybe<Omnichannel_Tags_Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.pipeline_tags" */
export type Omnichannel_Pipeline_Tags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "omnichannel.pipeline_tags" */
export enum Omnichannel_Pipeline_Tags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PipelineCardId = 'pipeline_card_id',
  /** column name */
  TagId = 'tag_id'
}

/** input type for updating data in table "omnichannel.pipeline_tags" */
export type Omnichannel_Pipeline_Tags_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  pipeline_card_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "omnichannel.pipeline_tags" */
export enum Omnichannel_Pipeline_Tags_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PipelineCardId = 'pipeline_card_id',
  /** column name */
  TagId = 'tag_id'
}

/** update columns of table "omnichannel.pipeline" */
export enum Omnichannel_Pipeline_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Omnichannel_Pipeline_Var_Pop_Fields = {
  __typename?: 'omnichannel_pipeline_var_pop_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "omnichannel.pipeline" */
export type Omnichannel_Pipeline_Var_Pop_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Omnichannel_Pipeline_Var_Samp_Fields = {
  __typename?: 'omnichannel_pipeline_var_samp_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "omnichannel.pipeline" */
export type Omnichannel_Pipeline_Var_Samp_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Omnichannel_Pipeline_Variance_Fields = {
  __typename?: 'omnichannel_pipeline_variance_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "omnichannel.pipeline" */
export type Omnichannel_Pipeline_Variance_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** columns and relationships of "omnichannel.products" */
export type Omnichannel_Products = {
  __typename?: 'omnichannel_products';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  organization_id: Scalars['Int'];
  /** An array relationship */
  permissions: Array<Memberships>;
  /** An aggregated array relationship */
  permissions_aggregate: Memberships_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "omnichannel.products" */
export type Omnichannel_ProductsPermissionsArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};


/** columns and relationships of "omnichannel.products" */
export type Omnichannel_ProductsPermissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};

/** aggregated selection of "omnichannel.products" */
export type Omnichannel_Products_Aggregate = {
  __typename?: 'omnichannel_products_aggregate';
  aggregate?: Maybe<Omnichannel_Products_Aggregate_Fields>;
  nodes: Array<Omnichannel_Products>;
};

/** aggregate fields of "omnichannel.products" */
export type Omnichannel_Products_Aggregate_Fields = {
  __typename?: 'omnichannel_products_aggregate_fields';
  avg?: Maybe<Omnichannel_Products_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Products_Max_Fields>;
  min?: Maybe<Omnichannel_Products_Min_Fields>;
  stddev?: Maybe<Omnichannel_Products_Stddev_Fields>;
  stddev_pop?: Maybe<Omnichannel_Products_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Omnichannel_Products_Stddev_Samp_Fields>;
  sum?: Maybe<Omnichannel_Products_Sum_Fields>;
  var_pop?: Maybe<Omnichannel_Products_Var_Pop_Fields>;
  var_samp?: Maybe<Omnichannel_Products_Var_Samp_Fields>;
  variance?: Maybe<Omnichannel_Products_Variance_Fields>;
};


/** aggregate fields of "omnichannel.products" */
export type Omnichannel_Products_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Products_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.products" */
export type Omnichannel_Products_Aggregate_Order_By = {
  avg?: Maybe<Omnichannel_Products_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Products_Max_Order_By>;
  min?: Maybe<Omnichannel_Products_Min_Order_By>;
  stddev?: Maybe<Omnichannel_Products_Stddev_Order_By>;
  stddev_pop?: Maybe<Omnichannel_Products_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Omnichannel_Products_Stddev_Samp_Order_By>;
  sum?: Maybe<Omnichannel_Products_Sum_Order_By>;
  var_pop?: Maybe<Omnichannel_Products_Var_Pop_Order_By>;
  var_samp?: Maybe<Omnichannel_Products_Var_Samp_Order_By>;
  variance?: Maybe<Omnichannel_Products_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.products" */
export type Omnichannel_Products_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Products_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Products_On_Conflict>;
};

/** aggregate avg on columns */
export type Omnichannel_Products_Avg_Fields = {
  __typename?: 'omnichannel_products_avg_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "omnichannel.products" */
export type Omnichannel_Products_Avg_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "omnichannel.products". All fields are combined with a logical 'AND'. */
export type Omnichannel_Products_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Products_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Products_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Products_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  organization_id?: Maybe<Int_Comparison_Exp>;
  permissions?: Maybe<Memberships_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.products" */
export enum Omnichannel_Products_Constraint {
  /** unique or primary key constraint */
  ProductPkey = 'product_pkey'
}

/** input type for incrementing integer column in table "omnichannel.products" */
export type Omnichannel_Products_Inc_Input = {
  organization_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "omnichannel.products" */
export type Omnichannel_Products_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  permissions?: Maybe<Memberships_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Omnichannel_Products_Max_Fields = {
  __typename?: 'omnichannel_products_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "omnichannel.products" */
export type Omnichannel_Products_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Products_Min_Fields = {
  __typename?: 'omnichannel_products_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "omnichannel.products" */
export type Omnichannel_Products_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.products" */
export type Omnichannel_Products_Mutation_Response = {
  __typename?: 'omnichannel_products_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Products>;
};

/** input type for inserting object relation for remote table "omnichannel.products" */
export type Omnichannel_Products_Obj_Rel_Insert_Input = {
  data: Omnichannel_Products_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Products_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.products" */
export type Omnichannel_Products_On_Conflict = {
  constraint: Omnichannel_Products_Constraint;
  update_columns: Array<Omnichannel_Products_Update_Column>;
  where?: Maybe<Omnichannel_Products_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.products" */
export type Omnichannel_Products_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  permissions_aggregate?: Maybe<Memberships_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.products" */
export type Omnichannel_Products_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "omnichannel.products" */
export enum Omnichannel_Products_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "omnichannel.products" */
export type Omnichannel_Products_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Omnichannel_Products_Stddev_Fields = {
  __typename?: 'omnichannel_products_stddev_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "omnichannel.products" */
export type Omnichannel_Products_Stddev_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Omnichannel_Products_Stddev_Pop_Fields = {
  __typename?: 'omnichannel_products_stddev_pop_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "omnichannel.products" */
export type Omnichannel_Products_Stddev_Pop_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Omnichannel_Products_Stddev_Samp_Fields = {
  __typename?: 'omnichannel_products_stddev_samp_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "omnichannel.products" */
export type Omnichannel_Products_Stddev_Samp_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Omnichannel_Products_Sum_Fields = {
  __typename?: 'omnichannel_products_sum_fields';
  organization_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "omnichannel.products" */
export type Omnichannel_Products_Sum_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** update columns of table "omnichannel.products" */
export enum Omnichannel_Products_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Omnichannel_Products_Var_Pop_Fields = {
  __typename?: 'omnichannel_products_var_pop_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "omnichannel.products" */
export type Omnichannel_Products_Var_Pop_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Omnichannel_Products_Var_Samp_Fields = {
  __typename?: 'omnichannel_products_var_samp_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "omnichannel.products" */
export type Omnichannel_Products_Var_Samp_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Omnichannel_Products_Variance_Fields = {
  __typename?: 'omnichannel_products_variance_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "omnichannel.products" */
export type Omnichannel_Products_Variance_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** columns and relationships of "omnichannel.tags" */
export type Omnichannel_Tags = {
  __typename?: 'omnichannel_tags';
  color: Scalars['String'];
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  feature: Scalars['String'];
  id: Scalars['uuid'];
  organization_id: Scalars['Int'];
  /** An array relationship */
  permission: Array<Memberships>;
  /** An aggregated array relationship */
  permission_aggregate: Memberships_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "omnichannel.tags" */
export type Omnichannel_TagsPermissionArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};


/** columns and relationships of "omnichannel.tags" */
export type Omnichannel_TagsPermission_AggregateArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};

/** aggregated selection of "omnichannel.tags" */
export type Omnichannel_Tags_Aggregate = {
  __typename?: 'omnichannel_tags_aggregate';
  aggregate?: Maybe<Omnichannel_Tags_Aggregate_Fields>;
  nodes: Array<Omnichannel_Tags>;
};

/** aggregate fields of "omnichannel.tags" */
export type Omnichannel_Tags_Aggregate_Fields = {
  __typename?: 'omnichannel_tags_aggregate_fields';
  avg?: Maybe<Omnichannel_Tags_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Tags_Max_Fields>;
  min?: Maybe<Omnichannel_Tags_Min_Fields>;
  stddev?: Maybe<Omnichannel_Tags_Stddev_Fields>;
  stddev_pop?: Maybe<Omnichannel_Tags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Omnichannel_Tags_Stddev_Samp_Fields>;
  sum?: Maybe<Omnichannel_Tags_Sum_Fields>;
  var_pop?: Maybe<Omnichannel_Tags_Var_Pop_Fields>;
  var_samp?: Maybe<Omnichannel_Tags_Var_Samp_Fields>;
  variance?: Maybe<Omnichannel_Tags_Variance_Fields>;
};


/** aggregate fields of "omnichannel.tags" */
export type Omnichannel_Tags_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Tags_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.tags" */
export type Omnichannel_Tags_Aggregate_Order_By = {
  avg?: Maybe<Omnichannel_Tags_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Tags_Max_Order_By>;
  min?: Maybe<Omnichannel_Tags_Min_Order_By>;
  stddev?: Maybe<Omnichannel_Tags_Stddev_Order_By>;
  stddev_pop?: Maybe<Omnichannel_Tags_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Omnichannel_Tags_Stddev_Samp_Order_By>;
  sum?: Maybe<Omnichannel_Tags_Sum_Order_By>;
  var_pop?: Maybe<Omnichannel_Tags_Var_Pop_Order_By>;
  var_samp?: Maybe<Omnichannel_Tags_Var_Samp_Order_By>;
  variance?: Maybe<Omnichannel_Tags_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.tags" */
export type Omnichannel_Tags_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Tags_Insert_Input>;
  on_conflict?: Maybe<Omnichannel_Tags_On_Conflict>;
};

/** aggregate avg on columns */
export type Omnichannel_Tags_Avg_Fields = {
  __typename?: 'omnichannel_tags_avg_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "omnichannel.tags" */
export type Omnichannel_Tags_Avg_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "omnichannel.tags". All fields are combined with a logical 'AND'. */
export type Omnichannel_Tags_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Tags_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Tags_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Tags_Bool_Exp>>>;
  color?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  feature?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  organization_id?: Maybe<Int_Comparison_Exp>;
  permission?: Maybe<Memberships_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "omnichannel.tags" */
export enum Omnichannel_Tags_Constraint {
  /** unique or primary key constraint */
  TagsPkey = 'tags_pkey'
}

/** input type for incrementing integer column in table "omnichannel.tags" */
export type Omnichannel_Tags_Inc_Input = {
  organization_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "omnichannel.tags" */
export type Omnichannel_Tags_Insert_Input = {
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['Int']>;
  permission?: Maybe<Memberships_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Omnichannel_Tags_Max_Fields = {
  __typename?: 'omnichannel_tags_max_fields';
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "omnichannel.tags" */
export type Omnichannel_Tags_Max_Order_By = {
  color?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  feature?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Tags_Min_Fields = {
  __typename?: 'omnichannel_tags_min_fields';
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "omnichannel.tags" */
export type Omnichannel_Tags_Min_Order_By = {
  color?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  feature?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.tags" */
export type Omnichannel_Tags_Mutation_Response = {
  __typename?: 'omnichannel_tags_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Tags>;
};

/** input type for inserting object relation for remote table "omnichannel.tags" */
export type Omnichannel_Tags_Obj_Rel_Insert_Input = {
  data: Omnichannel_Tags_Insert_Input;
  on_conflict?: Maybe<Omnichannel_Tags_On_Conflict>;
};

/** on conflict condition type for table "omnichannel.tags" */
export type Omnichannel_Tags_On_Conflict = {
  constraint: Omnichannel_Tags_Constraint;
  update_columns: Array<Omnichannel_Tags_Update_Column>;
  where?: Maybe<Omnichannel_Tags_Bool_Exp>;
};

/** ordering options when selecting data from "omnichannel.tags" */
export type Omnichannel_Tags_Order_By = {
  color?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  feature?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  permission_aggregate?: Maybe<Memberships_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "omnichannel.tags" */
export type Omnichannel_Tags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "omnichannel.tags" */
export enum Omnichannel_Tags_Select_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Feature = 'feature',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "omnichannel.tags" */
export type Omnichannel_Tags_Set_Input = {
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Omnichannel_Tags_Stddev_Fields = {
  __typename?: 'omnichannel_tags_stddev_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "omnichannel.tags" */
export type Omnichannel_Tags_Stddev_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Omnichannel_Tags_Stddev_Pop_Fields = {
  __typename?: 'omnichannel_tags_stddev_pop_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "omnichannel.tags" */
export type Omnichannel_Tags_Stddev_Pop_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Omnichannel_Tags_Stddev_Samp_Fields = {
  __typename?: 'omnichannel_tags_stddev_samp_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "omnichannel.tags" */
export type Omnichannel_Tags_Stddev_Samp_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Omnichannel_Tags_Sum_Fields = {
  __typename?: 'omnichannel_tags_sum_fields';
  organization_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "omnichannel.tags" */
export type Omnichannel_Tags_Sum_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** update columns of table "omnichannel.tags" */
export enum Omnichannel_Tags_Update_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Feature = 'feature',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Omnichannel_Tags_Var_Pop_Fields = {
  __typename?: 'omnichannel_tags_var_pop_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "omnichannel.tags" */
export type Omnichannel_Tags_Var_Pop_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Omnichannel_Tags_Var_Samp_Fields = {
  __typename?: 'omnichannel_tags_var_samp_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "omnichannel.tags" */
export type Omnichannel_Tags_Var_Samp_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Omnichannel_Tags_Variance_Fields = {
  __typename?: 'omnichannel_tags_variance_fields';
  organization_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "omnichannel.tags" */
export type Omnichannel_Tags_Variance_Order_By = {
  organization_id?: Maybe<Order_By>;
};

/** columns and relationships of "omnichannel.vw_activities_group" */
export type Omnichannel_Vw_Activities_Group = {
  __typename?: 'omnichannel_vw_activities_group';
  /** An object relationship */
  acitvity?: Maybe<Omnichannel_Person_Activities>;
  id?: Maybe<Scalars['uuid']>;
  mon?: Maybe<Scalars['String']>;
  yyyy?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "omnichannel.vw_activities_group" */
export type Omnichannel_Vw_Activities_Group_Aggregate = {
  __typename?: 'omnichannel_vw_activities_group_aggregate';
  aggregate?: Maybe<Omnichannel_Vw_Activities_Group_Aggregate_Fields>;
  nodes: Array<Omnichannel_Vw_Activities_Group>;
};

/** aggregate fields of "omnichannel.vw_activities_group" */
export type Omnichannel_Vw_Activities_Group_Aggregate_Fields = {
  __typename?: 'omnichannel_vw_activities_group_aggregate_fields';
  avg?: Maybe<Omnichannel_Vw_Activities_Group_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Vw_Activities_Group_Max_Fields>;
  min?: Maybe<Omnichannel_Vw_Activities_Group_Min_Fields>;
  stddev?: Maybe<Omnichannel_Vw_Activities_Group_Stddev_Fields>;
  stddev_pop?: Maybe<Omnichannel_Vw_Activities_Group_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Omnichannel_Vw_Activities_Group_Stddev_Samp_Fields>;
  sum?: Maybe<Omnichannel_Vw_Activities_Group_Sum_Fields>;
  var_pop?: Maybe<Omnichannel_Vw_Activities_Group_Var_Pop_Fields>;
  var_samp?: Maybe<Omnichannel_Vw_Activities_Group_Var_Samp_Fields>;
  variance?: Maybe<Omnichannel_Vw_Activities_Group_Variance_Fields>;
};


/** aggregate fields of "omnichannel.vw_activities_group" */
export type Omnichannel_Vw_Activities_Group_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Vw_Activities_Group_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.vw_activities_group" */
export type Omnichannel_Vw_Activities_Group_Aggregate_Order_By = {
  avg?: Maybe<Omnichannel_Vw_Activities_Group_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Vw_Activities_Group_Max_Order_By>;
  min?: Maybe<Omnichannel_Vw_Activities_Group_Min_Order_By>;
  stddev?: Maybe<Omnichannel_Vw_Activities_Group_Stddev_Order_By>;
  stddev_pop?: Maybe<Omnichannel_Vw_Activities_Group_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Omnichannel_Vw_Activities_Group_Stddev_Samp_Order_By>;
  sum?: Maybe<Omnichannel_Vw_Activities_Group_Sum_Order_By>;
  var_pop?: Maybe<Omnichannel_Vw_Activities_Group_Var_Pop_Order_By>;
  var_samp?: Maybe<Omnichannel_Vw_Activities_Group_Var_Samp_Order_By>;
  variance?: Maybe<Omnichannel_Vw_Activities_Group_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "omnichannel.vw_activities_group" */
export type Omnichannel_Vw_Activities_Group_Arr_Rel_Insert_Input = {
  data: Array<Omnichannel_Vw_Activities_Group_Insert_Input>;
};

/** aggregate avg on columns */
export type Omnichannel_Vw_Activities_Group_Avg_Fields = {
  __typename?: 'omnichannel_vw_activities_group_avg_fields';
  yyyy?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "omnichannel.vw_activities_group" */
export type Omnichannel_Vw_Activities_Group_Avg_Order_By = {
  yyyy?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "omnichannel.vw_activities_group". All fields are combined with a logical 'AND'. */
export type Omnichannel_Vw_Activities_Group_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Vw_Activities_Group_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Vw_Activities_Group_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Vw_Activities_Group_Bool_Exp>>>;
  acitvity?: Maybe<Omnichannel_Person_Activities_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  mon?: Maybe<String_Comparison_Exp>;
  yyyy?: Maybe<Float8_Comparison_Exp>;
};

/** input type for incrementing integer column in table "omnichannel.vw_activities_group" */
export type Omnichannel_Vw_Activities_Group_Inc_Input = {
  yyyy?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "omnichannel.vw_activities_group" */
export type Omnichannel_Vw_Activities_Group_Insert_Input = {
  acitvity?: Maybe<Omnichannel_Person_Activities_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  mon?: Maybe<Scalars['String']>;
  yyyy?: Maybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Omnichannel_Vw_Activities_Group_Max_Fields = {
  __typename?: 'omnichannel_vw_activities_group_max_fields';
  id?: Maybe<Scalars['uuid']>;
  mon?: Maybe<Scalars['String']>;
  yyyy?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "omnichannel.vw_activities_group" */
export type Omnichannel_Vw_Activities_Group_Max_Order_By = {
  id?: Maybe<Order_By>;
  mon?: Maybe<Order_By>;
  yyyy?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Vw_Activities_Group_Min_Fields = {
  __typename?: 'omnichannel_vw_activities_group_min_fields';
  id?: Maybe<Scalars['uuid']>;
  mon?: Maybe<Scalars['String']>;
  yyyy?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "omnichannel.vw_activities_group" */
export type Omnichannel_Vw_Activities_Group_Min_Order_By = {
  id?: Maybe<Order_By>;
  mon?: Maybe<Order_By>;
  yyyy?: Maybe<Order_By>;
};

/** response of any mutation on the table "omnichannel.vw_activities_group" */
export type Omnichannel_Vw_Activities_Group_Mutation_Response = {
  __typename?: 'omnichannel_vw_activities_group_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Omnichannel_Vw_Activities_Group>;
};

/** input type for inserting object relation for remote table "omnichannel.vw_activities_group" */
export type Omnichannel_Vw_Activities_Group_Obj_Rel_Insert_Input = {
  data: Omnichannel_Vw_Activities_Group_Insert_Input;
};

/** ordering options when selecting data from "omnichannel.vw_activities_group" */
export type Omnichannel_Vw_Activities_Group_Order_By = {
  acitvity?: Maybe<Omnichannel_Person_Activities_Order_By>;
  id?: Maybe<Order_By>;
  mon?: Maybe<Order_By>;
  yyyy?: Maybe<Order_By>;
};

/** select columns of table "omnichannel.vw_activities_group" */
export enum Omnichannel_Vw_Activities_Group_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Mon = 'mon',
  /** column name */
  Yyyy = 'yyyy'
}

/** input type for updating data in table "omnichannel.vw_activities_group" */
export type Omnichannel_Vw_Activities_Group_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  mon?: Maybe<Scalars['String']>;
  yyyy?: Maybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Omnichannel_Vw_Activities_Group_Stddev_Fields = {
  __typename?: 'omnichannel_vw_activities_group_stddev_fields';
  yyyy?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "omnichannel.vw_activities_group" */
export type Omnichannel_Vw_Activities_Group_Stddev_Order_By = {
  yyyy?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Omnichannel_Vw_Activities_Group_Stddev_Pop_Fields = {
  __typename?: 'omnichannel_vw_activities_group_stddev_pop_fields';
  yyyy?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "omnichannel.vw_activities_group" */
export type Omnichannel_Vw_Activities_Group_Stddev_Pop_Order_By = {
  yyyy?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Omnichannel_Vw_Activities_Group_Stddev_Samp_Fields = {
  __typename?: 'omnichannel_vw_activities_group_stddev_samp_fields';
  yyyy?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "omnichannel.vw_activities_group" */
export type Omnichannel_Vw_Activities_Group_Stddev_Samp_Order_By = {
  yyyy?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Omnichannel_Vw_Activities_Group_Sum_Fields = {
  __typename?: 'omnichannel_vw_activities_group_sum_fields';
  yyyy?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "omnichannel.vw_activities_group" */
export type Omnichannel_Vw_Activities_Group_Sum_Order_By = {
  yyyy?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Omnichannel_Vw_Activities_Group_Var_Pop_Fields = {
  __typename?: 'omnichannel_vw_activities_group_var_pop_fields';
  yyyy?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "omnichannel.vw_activities_group" */
export type Omnichannel_Vw_Activities_Group_Var_Pop_Order_By = {
  yyyy?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Omnichannel_Vw_Activities_Group_Var_Samp_Fields = {
  __typename?: 'omnichannel_vw_activities_group_var_samp_fields';
  yyyy?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "omnichannel.vw_activities_group" */
export type Omnichannel_Vw_Activities_Group_Var_Samp_Order_By = {
  yyyy?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Omnichannel_Vw_Activities_Group_Variance_Fields = {
  __typename?: 'omnichannel_vw_activities_group_variance_fields';
  yyyy?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "omnichannel.vw_activities_group" */
export type Omnichannel_Vw_Activities_Group_Variance_Order_By = {
  yyyy?: Maybe<Order_By>;
};

/** columns and relationships of "omnichannel.vw_randon_users" */
export type Omnichannel_Vw_Randon_Users = {
  __typename?: 'omnichannel_vw_randon_users';
  display_name?: Maybe<Scalars['String']>;
  email_notification?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lead_qtd?: Maybe<Scalars['Int']>;
  numberleads?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  permissions: Array<Memberships>;
  /** An aggregated array relationship */
  permissions_aggregate: Memberships_Aggregate;
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "omnichannel.vw_randon_users" */
export type Omnichannel_Vw_Randon_UsersPermissionsArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};


/** columns and relationships of "omnichannel.vw_randon_users" */
export type Omnichannel_Vw_Randon_UsersPermissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};

/** aggregated selection of "omnichannel.vw_randon_users" */
export type Omnichannel_Vw_Randon_Users_Aggregate = {
  __typename?: 'omnichannel_vw_randon_users_aggregate';
  aggregate?: Maybe<Omnichannel_Vw_Randon_Users_Aggregate_Fields>;
  nodes: Array<Omnichannel_Vw_Randon_Users>;
};

/** aggregate fields of "omnichannel.vw_randon_users" */
export type Omnichannel_Vw_Randon_Users_Aggregate_Fields = {
  __typename?: 'omnichannel_vw_randon_users_aggregate_fields';
  avg?: Maybe<Omnichannel_Vw_Randon_Users_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Omnichannel_Vw_Randon_Users_Max_Fields>;
  min?: Maybe<Omnichannel_Vw_Randon_Users_Min_Fields>;
  stddev?: Maybe<Omnichannel_Vw_Randon_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Omnichannel_Vw_Randon_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Omnichannel_Vw_Randon_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Omnichannel_Vw_Randon_Users_Sum_Fields>;
  var_pop?: Maybe<Omnichannel_Vw_Randon_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Omnichannel_Vw_Randon_Users_Var_Samp_Fields>;
  variance?: Maybe<Omnichannel_Vw_Randon_Users_Variance_Fields>;
};


/** aggregate fields of "omnichannel.vw_randon_users" */
export type Omnichannel_Vw_Randon_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Omnichannel_Vw_Randon_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "omnichannel.vw_randon_users" */
export type Omnichannel_Vw_Randon_Users_Aggregate_Order_By = {
  avg?: Maybe<Omnichannel_Vw_Randon_Users_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Omnichannel_Vw_Randon_Users_Max_Order_By>;
  min?: Maybe<Omnichannel_Vw_Randon_Users_Min_Order_By>;
  stddev?: Maybe<Omnichannel_Vw_Randon_Users_Stddev_Order_By>;
  stddev_pop?: Maybe<Omnichannel_Vw_Randon_Users_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Omnichannel_Vw_Randon_Users_Stddev_Samp_Order_By>;
  sum?: Maybe<Omnichannel_Vw_Randon_Users_Sum_Order_By>;
  var_pop?: Maybe<Omnichannel_Vw_Randon_Users_Var_Pop_Order_By>;
  var_samp?: Maybe<Omnichannel_Vw_Randon_Users_Var_Samp_Order_By>;
  variance?: Maybe<Omnichannel_Vw_Randon_Users_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Omnichannel_Vw_Randon_Users_Avg_Fields = {
  __typename?: 'omnichannel_vw_randon_users_avg_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lead_qtd?: Maybe<Scalars['Float']>;
  numberleads?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "omnichannel.vw_randon_users" */
export type Omnichannel_Vw_Randon_Users_Avg_Order_By = {
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead_qtd?: Maybe<Order_By>;
  numberleads?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "omnichannel.vw_randon_users". All fields are combined with a logical 'AND'. */
export type Omnichannel_Vw_Randon_Users_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Omnichannel_Vw_Randon_Users_Bool_Exp>>>;
  _not?: Maybe<Omnichannel_Vw_Randon_Users_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Omnichannel_Vw_Randon_Users_Bool_Exp>>>;
  display_name?: Maybe<String_Comparison_Exp>;
  email_notification?: Maybe<String_Comparison_Exp>;
  group_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  lead_qtd?: Maybe<Int_Comparison_Exp>;
  numberleads?: Maybe<Bigint_Comparison_Exp>;
  permissions?: Maybe<Memberships_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Omnichannel_Vw_Randon_Users_Max_Fields = {
  __typename?: 'omnichannel_vw_randon_users_max_fields';
  display_name?: Maybe<Scalars['String']>;
  email_notification?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lead_qtd?: Maybe<Scalars['Int']>;
  numberleads?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "omnichannel.vw_randon_users" */
export type Omnichannel_Vw_Randon_Users_Max_Order_By = {
  display_name?: Maybe<Order_By>;
  email_notification?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead_qtd?: Maybe<Order_By>;
  numberleads?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Omnichannel_Vw_Randon_Users_Min_Fields = {
  __typename?: 'omnichannel_vw_randon_users_min_fields';
  display_name?: Maybe<Scalars['String']>;
  email_notification?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lead_qtd?: Maybe<Scalars['Int']>;
  numberleads?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "omnichannel.vw_randon_users" */
export type Omnichannel_Vw_Randon_Users_Min_Order_By = {
  display_name?: Maybe<Order_By>;
  email_notification?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead_qtd?: Maybe<Order_By>;
  numberleads?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** ordering options when selecting data from "omnichannel.vw_randon_users" */
export type Omnichannel_Vw_Randon_Users_Order_By = {
  display_name?: Maybe<Order_By>;
  email_notification?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead_qtd?: Maybe<Order_By>;
  numberleads?: Maybe<Order_By>;
  permissions_aggregate?: Maybe<Memberships_Aggregate_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** select columns of table "omnichannel.vw_randon_users" */
export enum Omnichannel_Vw_Randon_Users_Select_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  EmailNotification = 'email_notification',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  LeadQtd = 'lead_qtd',
  /** column name */
  Numberleads = 'numberleads',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type Omnichannel_Vw_Randon_Users_Stddev_Fields = {
  __typename?: 'omnichannel_vw_randon_users_stddev_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lead_qtd?: Maybe<Scalars['Float']>;
  numberleads?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "omnichannel.vw_randon_users" */
export type Omnichannel_Vw_Randon_Users_Stddev_Order_By = {
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead_qtd?: Maybe<Order_By>;
  numberleads?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Omnichannel_Vw_Randon_Users_Stddev_Pop_Fields = {
  __typename?: 'omnichannel_vw_randon_users_stddev_pop_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lead_qtd?: Maybe<Scalars['Float']>;
  numberleads?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "omnichannel.vw_randon_users" */
export type Omnichannel_Vw_Randon_Users_Stddev_Pop_Order_By = {
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead_qtd?: Maybe<Order_By>;
  numberleads?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Omnichannel_Vw_Randon_Users_Stddev_Samp_Fields = {
  __typename?: 'omnichannel_vw_randon_users_stddev_samp_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lead_qtd?: Maybe<Scalars['Float']>;
  numberleads?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "omnichannel.vw_randon_users" */
export type Omnichannel_Vw_Randon_Users_Stddev_Samp_Order_By = {
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead_qtd?: Maybe<Order_By>;
  numberleads?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Omnichannel_Vw_Randon_Users_Sum_Fields = {
  __typename?: 'omnichannel_vw_randon_users_sum_fields';
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lead_qtd?: Maybe<Scalars['Int']>;
  numberleads?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "omnichannel.vw_randon_users" */
export type Omnichannel_Vw_Randon_Users_Sum_Order_By = {
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead_qtd?: Maybe<Order_By>;
  numberleads?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Omnichannel_Vw_Randon_Users_Var_Pop_Fields = {
  __typename?: 'omnichannel_vw_randon_users_var_pop_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lead_qtd?: Maybe<Scalars['Float']>;
  numberleads?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "omnichannel.vw_randon_users" */
export type Omnichannel_Vw_Randon_Users_Var_Pop_Order_By = {
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead_qtd?: Maybe<Order_By>;
  numberleads?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Omnichannel_Vw_Randon_Users_Var_Samp_Fields = {
  __typename?: 'omnichannel_vw_randon_users_var_samp_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lead_qtd?: Maybe<Scalars['Float']>;
  numberleads?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "omnichannel.vw_randon_users" */
export type Omnichannel_Vw_Randon_Users_Var_Samp_Order_By = {
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead_qtd?: Maybe<Order_By>;
  numberleads?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Omnichannel_Vw_Randon_Users_Variance_Fields = {
  __typename?: 'omnichannel_vw_randon_users_variance_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lead_qtd?: Maybe<Scalars['Float']>;
  numberleads?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "omnichannel.vw_randon_users" */
export type Omnichannel_Vw_Randon_Users_Variance_Order_By = {
  group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lead_qtd?: Maybe<Order_By>;
  numberleads?: Maybe<Order_By>;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "organizations" */
export type Organizations = {
  __typename?: 'organizations';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An array relationship */
  memberships: Array<Memberships>;
  /** An aggregated array relationship */
  memberships_aggregate: Memberships_Aggregate;
  name: Scalars['String'];
  /** An object relationship */
  plan?: Maybe<Stripe_Plan>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripe_plan_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  username: Scalars['String'];
};


/** columns and relationships of "organizations" */
export type OrganizationsMembershipsArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsMemberships_AggregateArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};

/** aggregated selection of "organizations" */
export type Organizations_Aggregate = {
  __typename?: 'organizations_aggregate';
  aggregate?: Maybe<Organizations_Aggregate_Fields>;
  nodes: Array<Organizations>;
};

/** aggregate fields of "organizations" */
export type Organizations_Aggregate_Fields = {
  __typename?: 'organizations_aggregate_fields';
  avg?: Maybe<Organizations_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Organizations_Max_Fields>;
  min?: Maybe<Organizations_Min_Fields>;
  stddev?: Maybe<Organizations_Stddev_Fields>;
  stddev_pop?: Maybe<Organizations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organizations_Stddev_Samp_Fields>;
  sum?: Maybe<Organizations_Sum_Fields>;
  var_pop?: Maybe<Organizations_Var_Pop_Fields>;
  var_samp?: Maybe<Organizations_Var_Samp_Fields>;
  variance?: Maybe<Organizations_Variance_Fields>;
};


/** aggregate fields of "organizations" */
export type Organizations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Organizations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organizations" */
export type Organizations_Aggregate_Order_By = {
  avg?: Maybe<Organizations_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Organizations_Max_Order_By>;
  min?: Maybe<Organizations_Min_Order_By>;
  stddev?: Maybe<Organizations_Stddev_Order_By>;
  stddev_pop?: Maybe<Organizations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Organizations_Stddev_Samp_Order_By>;
  sum?: Maybe<Organizations_Sum_Order_By>;
  var_pop?: Maybe<Organizations_Var_Pop_Order_By>;
  var_samp?: Maybe<Organizations_Var_Samp_Order_By>;
  variance?: Maybe<Organizations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "organizations" */
export type Organizations_Arr_Rel_Insert_Input = {
  data: Array<Organizations_Insert_Input>;
  on_conflict?: Maybe<Organizations_On_Conflict>;
};

/** aggregate avg on columns */
export type Organizations_Avg_Fields = {
  __typename?: 'organizations_avg_fields';
  id?: Maybe<Scalars['Float']>;
  stripe_plan_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "organizations" */
export type Organizations_Avg_Order_By = {
  id?: Maybe<Order_By>;
  stripe_plan_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "organizations". All fields are combined with a logical 'AND'. */
export type Organizations_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Organizations_Bool_Exp>>>;
  _not?: Maybe<Organizations_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Organizations_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  memberships?: Maybe<Memberships_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  plan?: Maybe<Stripe_Plan_Bool_Exp>;
  stripeCustomerId?: Maybe<String_Comparison_Exp>;
  stripe_plan_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  username?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "organizations" */
export enum Organizations_Constraint {
  /** unique or primary key constraint */
  OrganizationsPkey = 'organizations_pkey'
}

/** input type for incrementing integer column in table "organizations" */
export type Organizations_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  stripe_plan_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "organizations" */
export type Organizations_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  memberships?: Maybe<Memberships_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  plan?: Maybe<Stripe_Plan_Obj_Rel_Insert_Input>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripe_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Organizations_Max_Fields = {
  __typename?: 'organizations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripe_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "organizations" */
export type Organizations_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  stripeCustomerId?: Maybe<Order_By>;
  stripe_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Organizations_Min_Fields = {
  __typename?: 'organizations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripe_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "organizations" */
export type Organizations_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  stripeCustomerId?: Maybe<Order_By>;
  stripe_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
};

/** response of any mutation on the table "organizations" */
export type Organizations_Mutation_Response = {
  __typename?: 'organizations_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Organizations>;
};

/** input type for inserting object relation for remote table "organizations" */
export type Organizations_Obj_Rel_Insert_Input = {
  data: Organizations_Insert_Input;
  on_conflict?: Maybe<Organizations_On_Conflict>;
};

/** on conflict condition type for table "organizations" */
export type Organizations_On_Conflict = {
  constraint: Organizations_Constraint;
  update_columns: Array<Organizations_Update_Column>;
  where?: Maybe<Organizations_Bool_Exp>;
};

/** ordering options when selecting data from "organizations" */
export type Organizations_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  memberships_aggregate?: Maybe<Memberships_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  plan?: Maybe<Stripe_Plan_Order_By>;
  stripeCustomerId?: Maybe<Order_By>;
  stripe_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
};

/** primary key columns input for table: "organizations" */
export type Organizations_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "organizations" */
export enum Organizations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  StripeCustomerId = 'stripeCustomerId',
  /** column name */
  StripePlanId = 'stripe_plan_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "organizations" */
export type Organizations_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripe_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Organizations_Stddev_Fields = {
  __typename?: 'organizations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  stripe_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "organizations" */
export type Organizations_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  stripe_plan_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Organizations_Stddev_Pop_Fields = {
  __typename?: 'organizations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  stripe_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "organizations" */
export type Organizations_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  stripe_plan_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Organizations_Stddev_Samp_Fields = {
  __typename?: 'organizations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  stripe_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "organizations" */
export type Organizations_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  stripe_plan_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Organizations_Sum_Fields = {
  __typename?: 'organizations_sum_fields';
  id?: Maybe<Scalars['Int']>;
  stripe_plan_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "organizations" */
export type Organizations_Sum_Order_By = {
  id?: Maybe<Order_By>;
  stripe_plan_id?: Maybe<Order_By>;
};

/** update columns of table "organizations" */
export enum Organizations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  StripeCustomerId = 'stripeCustomerId',
  /** column name */
  StripePlanId = 'stripe_plan_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** aggregate var_pop on columns */
export type Organizations_Var_Pop_Fields = {
  __typename?: 'organizations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  stripe_plan_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "organizations" */
export type Organizations_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  stripe_plan_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Organizations_Var_Samp_Fields = {
  __typename?: 'organizations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  stripe_plan_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "organizations" */
export type Organizations_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  stripe_plan_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Organizations_Variance_Fields = {
  __typename?: 'organizations_variance_fields';
  id?: Maybe<Scalars['Float']>;
  stripe_plan_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "organizations" */
export type Organizations_Variance_Order_By = {
  id?: Maybe<Order_By>;
  stripe_plan_id?: Maybe<Order_By>;
};

/** columns and relationships of "permissions" */
export type Permissions = {
  __typename?: 'permissions';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  organizations_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "permissions" */
export type Permissions_Aggregate = {
  __typename?: 'permissions_aggregate';
  aggregate?: Maybe<Permissions_Aggregate_Fields>;
  nodes: Array<Permissions>;
};

/** aggregate fields of "permissions" */
export type Permissions_Aggregate_Fields = {
  __typename?: 'permissions_aggregate_fields';
  avg?: Maybe<Permissions_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Permissions_Max_Fields>;
  min?: Maybe<Permissions_Min_Fields>;
  stddev?: Maybe<Permissions_Stddev_Fields>;
  stddev_pop?: Maybe<Permissions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Permissions_Stddev_Samp_Fields>;
  sum?: Maybe<Permissions_Sum_Fields>;
  var_pop?: Maybe<Permissions_Var_Pop_Fields>;
  var_samp?: Maybe<Permissions_Var_Samp_Fields>;
  variance?: Maybe<Permissions_Variance_Fields>;
};


/** aggregate fields of "permissions" */
export type Permissions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Permissions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "permissions" */
export type Permissions_Aggregate_Order_By = {
  avg?: Maybe<Permissions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Permissions_Max_Order_By>;
  min?: Maybe<Permissions_Min_Order_By>;
  stddev?: Maybe<Permissions_Stddev_Order_By>;
  stddev_pop?: Maybe<Permissions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Permissions_Stddev_Samp_Order_By>;
  sum?: Maybe<Permissions_Sum_Order_By>;
  var_pop?: Maybe<Permissions_Var_Pop_Order_By>;
  var_samp?: Maybe<Permissions_Var_Samp_Order_By>;
  variance?: Maybe<Permissions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "permissions" */
export type Permissions_Arr_Rel_Insert_Input = {
  data: Array<Permissions_Insert_Input>;
  on_conflict?: Maybe<Permissions_On_Conflict>;
};

/** aggregate avg on columns */
export type Permissions_Avg_Fields = {
  __typename?: 'permissions_avg_fields';
  organizations_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "permissions" */
export type Permissions_Avg_Order_By = {
  organizations_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "permissions". All fields are combined with a logical 'AND'. */
export type Permissions_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Permissions_Bool_Exp>>>;
  _not?: Maybe<Permissions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Permissions_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  organizations_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "permissions" */
export enum Permissions_Constraint {
  /** unique or primary key constraint */
  PermissionPkey = 'permission_pkey'
}

/** input type for incrementing integer column in table "permissions" */
export type Permissions_Inc_Input = {
  organizations_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "permissions" */
export type Permissions_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organizations_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Permissions_Max_Fields = {
  __typename?: 'permissions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organizations_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "permissions" */
export type Permissions_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organizations_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Permissions_Min_Fields = {
  __typename?: 'permissions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organizations_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "permissions" */
export type Permissions_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organizations_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "permissions" */
export type Permissions_Mutation_Response = {
  __typename?: 'permissions_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Permissions>;
};

/** input type for inserting object relation for remote table "permissions" */
export type Permissions_Obj_Rel_Insert_Input = {
  data: Permissions_Insert_Input;
  on_conflict?: Maybe<Permissions_On_Conflict>;
};

/** on conflict condition type for table "permissions" */
export type Permissions_On_Conflict = {
  constraint: Permissions_Constraint;
  update_columns: Array<Permissions_Update_Column>;
  where?: Maybe<Permissions_Bool_Exp>;
};

/** ordering options when selecting data from "permissions" */
export type Permissions_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organizations_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "permissions" */
export type Permissions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "permissions" */
export enum Permissions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationsId = 'organizations_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "permissions" */
export type Permissions_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organizations_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Permissions_Stddev_Fields = {
  __typename?: 'permissions_stddev_fields';
  organizations_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "permissions" */
export type Permissions_Stddev_Order_By = {
  organizations_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Permissions_Stddev_Pop_Fields = {
  __typename?: 'permissions_stddev_pop_fields';
  organizations_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "permissions" */
export type Permissions_Stddev_Pop_Order_By = {
  organizations_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Permissions_Stddev_Samp_Fields = {
  __typename?: 'permissions_stddev_samp_fields';
  organizations_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "permissions" */
export type Permissions_Stddev_Samp_Order_By = {
  organizations_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Permissions_Sum_Fields = {
  __typename?: 'permissions_sum_fields';
  organizations_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "permissions" */
export type Permissions_Sum_Order_By = {
  organizations_id?: Maybe<Order_By>;
};

/** update columns of table "permissions" */
export enum Permissions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationsId = 'organizations_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Permissions_Var_Pop_Fields = {
  __typename?: 'permissions_var_pop_fields';
  organizations_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "permissions" */
export type Permissions_Var_Pop_Order_By = {
  organizations_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Permissions_Var_Samp_Fields = {
  __typename?: 'permissions_var_samp_fields';
  organizations_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "permissions" */
export type Permissions_Var_Samp_Order_By = {
  organizations_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Permissions_Variance_Fields = {
  __typename?: 'permissions_variance_fields';
  organizations_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "permissions" */
export type Permissions_Variance_Order_By = {
  organizations_id?: Maybe<Order_By>;
};

/** columns and relationships of "product" */
export type Product = {
  __typename?: 'product';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** aggregated selection of "product" */
export type Product_Aggregate = {
  __typename?: 'product_aggregate';
  aggregate?: Maybe<Product_Aggregate_Fields>;
  nodes: Array<Product>;
};

/** aggregate fields of "product" */
export type Product_Aggregate_Fields = {
  __typename?: 'product_aggregate_fields';
  avg?: Maybe<Product_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Product_Max_Fields>;
  min?: Maybe<Product_Min_Fields>;
  stddev?: Maybe<Product_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Sum_Fields>;
  var_pop?: Maybe<Product_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Var_Samp_Fields>;
  variance?: Maybe<Product_Variance_Fields>;
};


/** aggregate fields of "product" */
export type Product_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product" */
export type Product_Aggregate_Order_By = {
  avg?: Maybe<Product_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Product_Max_Order_By>;
  min?: Maybe<Product_Min_Order_By>;
  stddev?: Maybe<Product_Stddev_Order_By>;
  stddev_pop?: Maybe<Product_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Product_Stddev_Samp_Order_By>;
  sum?: Maybe<Product_Sum_Order_By>;
  var_pop?: Maybe<Product_Var_Pop_Order_By>;
  var_samp?: Maybe<Product_Var_Samp_Order_By>;
  variance?: Maybe<Product_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product" */
export type Product_Arr_Rel_Insert_Input = {
  data: Array<Product_Insert_Input>;
  on_conflict?: Maybe<Product_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Avg_Fields = {
  __typename?: 'product_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product" */
export type Product_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product". All fields are combined with a logical 'AND'. */
export type Product_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Product_Bool_Exp>>>;
  _not?: Maybe<Product_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Product_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product" */
export enum Product_Constraint {
  /** unique or primary key constraint */
  ProductPkey = 'product_pkey'
}

/** input type for incrementing integer column in table "product" */
export type Product_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "product" */
export type Product_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Product_Max_Fields = {
  __typename?: 'product_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "product" */
export type Product_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Min_Fields = {
  __typename?: 'product_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "product" */
export type Product_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "product" */
export type Product_Mutation_Response = {
  __typename?: 'product_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Product>;
};

/** input type for inserting object relation for remote table "product" */
export type Product_Obj_Rel_Insert_Input = {
  data: Product_Insert_Input;
  on_conflict?: Maybe<Product_On_Conflict>;
};

/** on conflict condition type for table "product" */
export type Product_On_Conflict = {
  constraint: Product_Constraint;
  update_columns: Array<Product_Update_Column>;
  where?: Maybe<Product_Bool_Exp>;
};

/** ordering options when selecting data from "product" */
export type Product_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "product" */
export type Product_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "product" */
export enum Product_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "product" */
export type Product_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Product_Stddev_Fields = {
  __typename?: 'product_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product" */
export type Product_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Stddev_Pop_Fields = {
  __typename?: 'product_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product" */
export type Product_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Stddev_Samp_Fields = {
  __typename?: 'product_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product" */
export type Product_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Product_Sum_Fields = {
  __typename?: 'product_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "product" */
export type Product_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "product" */
export enum Product_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Product_Var_Pop_Fields = {
  __typename?: 'product_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product" */
export type Product_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Var_Samp_Fields = {
  __typename?: 'product_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product" */
export type Product_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Variance_Fields = {
  __typename?: 'product_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product" */
export type Product_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** perform the action: "accounts" */
  accounts?: Maybe<MailAccountOutput>;
  /** fetch data from the table: "acl_features" */
  acl_features: Array<Acl_Features>;
  /** fetch aggregated fields from the table: "acl_features" */
  acl_features_aggregate: Acl_Features_Aggregate;
  /** fetch data from the table: "acl_features" using primary key columns */
  acl_features_by_pk?: Maybe<Acl_Features>;
  /** fetch data from the table: "acl_features_permissions" */
  acl_features_permissions: Array<Acl_Features_Permissions>;
  /** fetch aggregated fields from the table: "acl_features_permissions" */
  acl_features_permissions_aggregate: Acl_Features_Permissions_Aggregate;
  /** fetch data from the table: "acl_features_permissions" using primary key columns */
  acl_features_permissions_by_pk?: Maybe<Acl_Features_Permissions>;
  /** fetch data from the table: "apikeys" */
  apikeys: Array<Apikeys>;
  /** fetch aggregated fields from the table: "apikeys" */
  apikeys_aggregate: Apikeys_Aggregate;
  /** fetch data from the table: "apikeys" using primary key columns */
  apikeys_by_pk?: Maybe<Apikeys>;
  /** fetch data from the table: "auth.account_providers" */
  auth_account_providers: Array<Auth_Account_Providers>;
  /** fetch aggregated fields from the table: "auth.account_providers" */
  auth_account_providers_aggregate: Auth_Account_Providers_Aggregate;
  /** fetch data from the table: "auth.account_providers" using primary key columns */
  auth_account_providers_by_pk?: Maybe<Auth_Account_Providers>;
  /** fetch data from the table: "auth.account_roles" */
  auth_account_roles: Array<Auth_Account_Roles>;
  /** fetch aggregated fields from the table: "auth.account_roles" */
  auth_account_roles_aggregate: Auth_Account_Roles_Aggregate;
  /** fetch data from the table: "auth.account_roles" using primary key columns */
  auth_account_roles_by_pk?: Maybe<Auth_Account_Roles>;
  /** fetch data from the table: "auth.accounts" */
  auth_accounts: Array<Auth_Accounts>;
  /** fetch aggregated fields from the table: "auth.accounts" */
  auth_accounts_aggregate: Auth_Accounts_Aggregate;
  /** fetch data from the table: "auth.accounts" using primary key columns */
  auth_accounts_by_pk?: Maybe<Auth_Accounts>;
  /** fetch data from the table: "auth.providers" */
  auth_providers: Array<Auth_Providers>;
  /** fetch aggregated fields from the table: "auth.providers" */
  auth_providers_aggregate: Auth_Providers_Aggregate;
  /** fetch data from the table: "auth.providers" using primary key columns */
  auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** fetch data from the table: "auth.refresh_tokens" */
  auth_refresh_tokens: Array<Auth_Refresh_Tokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  auth_refresh_tokens_aggregate: Auth_Refresh_Tokens_Aggregate;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** fetch data from the table: "auth.roles" */
  auth_roles: Array<Auth_Roles>;
  /** fetch aggregated fields from the table: "auth.roles" */
  auth_roles_aggregate: Auth_Roles_Aggregate;
  /** fetch data from the table: "auth.roles" using primary key columns */
  auth_roles_by_pk?: Maybe<Auth_Roles>;
  /** fetch data from the table: "cidades" */
  cidades: Array<Cidades>;
  /** fetch aggregated fields from the table: "cidades" */
  cidades_aggregate: Cidades_Aggregate;
  /** fetch data from the table: "cidades" using primary key columns */
  cidades_by_pk?: Maybe<Cidades>;
  company?: Maybe<Company>;
  /** fetch data from the table: "estados" */
  estados: Array<Estados>;
  /** fetch aggregated fields from the table: "estados" */
  estados_aggregate: Estados_Aggregate;
  /** fetch data from the table: "estados" using primary key columns */
  estados_by_pk?: Maybe<Estados>;
  files?: Maybe<Array<Maybe<UploadFile>>>;
  filesConnection?: Maybe<UploadFileConnection>;
  get_mail_account?: Maybe<AccountMail>;
  login?: Maybe<Login>;
  me?: Maybe<UsersPermissionsMe>;
  /** fetch data from the table: "memberships" */
  memberships: Array<Memberships>;
  /** fetch aggregated fields from the table: "memberships" */
  memberships_aggregate: Memberships_Aggregate;
  /** fetch data from the table: "memberships" using primary key columns */
  memberships_by_pk?: Maybe<Memberships>;
  menu?: Maybe<Menu>;
  /** fetch data from the table: "omnichannel.addons" */
  omnichannel_addons: Array<Omnichannel_Addons>;
  /** fetch aggregated fields from the table: "omnichannel.addons" */
  omnichannel_addons_aggregate: Omnichannel_Addons_Aggregate;
  /** fetch data from the table: "omnichannel.addons" using primary key columns */
  omnichannel_addons_by_pk?: Maybe<Omnichannel_Addons>;
  /** fetch data from the table: "omnichannel.addons_connect_settings" */
  omnichannel_addons_connect_settings: Array<Omnichannel_Addons_Connect_Settings>;
  /** fetch aggregated fields from the table: "omnichannel.addons_connect_settings" */
  omnichannel_addons_connect_settings_aggregate: Omnichannel_Addons_Connect_Settings_Aggregate;
  /** fetch data from the table: "omnichannel.addons_connect_settings" using primary key columns */
  omnichannel_addons_connect_settings_by_pk?: Maybe<Omnichannel_Addons_Connect_Settings>;
  /** fetch data from the table: "omnichannel.addons_connects" */
  omnichannel_addons_connects: Array<Omnichannel_Addons_Connects>;
  /** fetch aggregated fields from the table: "omnichannel.addons_connects" */
  omnichannel_addons_connects_aggregate: Omnichannel_Addons_Connects_Aggregate;
  /** fetch data from the table: "omnichannel.addons_connects" using primary key columns */
  omnichannel_addons_connects_by_pk?: Maybe<Omnichannel_Addons_Connects>;
  /** fetch data from the table: "omnichannel.channel_enabled" */
  omnichannel_channel_enabled: Array<Omnichannel_Channel_Enabled>;
  /** fetch aggregated fields from the table: "omnichannel.channel_enabled" */
  omnichannel_channel_enabled_aggregate: Omnichannel_Channel_Enabled_Aggregate;
  /** fetch data from the table: "omnichannel.channel_enabled" using primary key columns */
  omnichannel_channel_enabled_by_pk?: Maybe<Omnichannel_Channel_Enabled>;
  /** fetch data from the table: "omnichannel.channel_enabled_settings" */
  omnichannel_channel_enabled_settings: Array<Omnichannel_Channel_Enabled_Settings>;
  /** fetch aggregated fields from the table: "omnichannel.channel_enabled_settings" */
  omnichannel_channel_enabled_settings_aggregate: Omnichannel_Channel_Enabled_Settings_Aggregate;
  /** fetch data from the table: "omnichannel.channel_enabled_settings" using primary key columns */
  omnichannel_channel_enabled_settings_by_pk?: Maybe<Omnichannel_Channel_Enabled_Settings>;
  /** fetch data from the table: "omnichannel.channel_message" */
  omnichannel_channel_message: Array<Omnichannel_Channel_Message>;
  /** fetch aggregated fields from the table: "omnichannel.channel_message" */
  omnichannel_channel_message_aggregate: Omnichannel_Channel_Message_Aggregate;
  /** fetch data from the table: "omnichannel.channel_message" using primary key columns */
  omnichannel_channel_message_by_pk?: Maybe<Omnichannel_Channel_Message>;
  /** fetch data from the table: "omnichannel.channel_sessions" */
  omnichannel_channel_sessions: Array<Omnichannel_Channel_Sessions>;
  /** fetch aggregated fields from the table: "omnichannel.channel_sessions" */
  omnichannel_channel_sessions_aggregate: Omnichannel_Channel_Sessions_Aggregate;
  /** fetch data from the table: "omnichannel.channel_sessions" using primary key columns */
  omnichannel_channel_sessions_by_pk?: Maybe<Omnichannel_Channel_Sessions>;
  /** fetch data from the table: "omnichannel.channels" */
  omnichannel_channels: Array<Omnichannel_Channels>;
  /** fetch aggregated fields from the table: "omnichannel.channels" */
  omnichannel_channels_aggregate: Omnichannel_Channels_Aggregate;
  /** fetch data from the table: "omnichannel.channels" using primary key columns */
  omnichannel_channels_by_pk?: Maybe<Omnichannel_Channels>;
  /** fetch data from the table: "omnichannel.groups" */
  omnichannel_groups: Array<Omnichannel_Groups>;
  /** fetch aggregated fields from the table: "omnichannel.groups" */
  omnichannel_groups_aggregate: Omnichannel_Groups_Aggregate;
  /** fetch data from the table: "omnichannel.groups" using primary key columns */
  omnichannel_groups_by_pk?: Maybe<Omnichannel_Groups>;
  /** fetch data from the table: "omnichannel.groups_users" */
  omnichannel_groups_users: Array<Omnichannel_Groups_Users>;
  /** fetch aggregated fields from the table: "omnichannel.groups_users" */
  omnichannel_groups_users_aggregate: Omnichannel_Groups_Users_Aggregate;
  /** fetch data from the table: "omnichannel.groups_users" using primary key columns */
  omnichannel_groups_users_by_pk?: Maybe<Omnichannel_Groups_Users>;
  /** fetch data from the table: "omnichannel.mailbox_mail_addons" */
  omnichannel_mailbox_mail_addons: Array<Omnichannel_Mailbox_Mail_Addons>;
  /** fetch aggregated fields from the table: "omnichannel.mailbox_mail_addons" */
  omnichannel_mailbox_mail_addons_aggregate: Omnichannel_Mailbox_Mail_Addons_Aggregate;
  /** fetch data from the table: "omnichannel.mailbox_mail_addons" using primary key columns */
  omnichannel_mailbox_mail_addons_by_pk?: Maybe<Omnichannel_Mailbox_Mail_Addons>;
  /** fetch data from the table: "omnichannel.message_mail_addons" */
  omnichannel_message_mail_addons: Array<Omnichannel_Message_Mail_Addons>;
  /** fetch aggregated fields from the table: "omnichannel.message_mail_addons" */
  omnichannel_message_mail_addons_aggregate: Omnichannel_Message_Mail_Addons_Aggregate;
  /** fetch data from the table: "omnichannel.message_mail_addons" using primary key columns */
  omnichannel_message_mail_addons_by_pk?: Maybe<Omnichannel_Message_Mail_Addons>;
  /** fetch data from the table: "omnichannel.person_activities" */
  omnichannel_person_activities: Array<Omnichannel_Person_Activities>;
  /** fetch aggregated fields from the table: "omnichannel.person_activities" */
  omnichannel_person_activities_aggregate: Omnichannel_Person_Activities_Aggregate;
  /** fetch data from the table: "omnichannel.person_activities" using primary key columns */
  omnichannel_person_activities_by_pk?: Maybe<Omnichannel_Person_Activities>;
  /** fetch data from the table: "omnichannel.person_file" */
  omnichannel_person_file: Array<Omnichannel_Person_File>;
  /** fetch aggregated fields from the table: "omnichannel.person_file" */
  omnichannel_person_file_aggregate: Omnichannel_Person_File_Aggregate;
  /** fetch data from the table: "omnichannel.person_file" using primary key columns */
  omnichannel_person_file_by_pk?: Maybe<Omnichannel_Person_File>;
  /** fetch data from the table: "omnichannel.person_mail" */
  omnichannel_person_mail: Array<Omnichannel_Person_Mail>;
  /** fetch aggregated fields from the table: "omnichannel.person_mail" */
  omnichannel_person_mail_aggregate: Omnichannel_Person_Mail_Aggregate;
  /** fetch data from the table: "omnichannel.person_mail" using primary key columns */
  omnichannel_person_mail_by_pk?: Maybe<Omnichannel_Person_Mail>;
  /** fetch data from the table: "omnichannel.person_note" */
  omnichannel_person_note: Array<Omnichannel_Person_Note>;
  /** fetch aggregated fields from the table: "omnichannel.person_note" */
  omnichannel_person_note_aggregate: Omnichannel_Person_Note_Aggregate;
  /** fetch data from the table: "omnichannel.person_note" using primary key columns */
  omnichannel_person_note_by_pk?: Maybe<Omnichannel_Person_Note>;
  /** fetch data from the table: "omnichannel.person_tags" */
  omnichannel_person_tags: Array<Omnichannel_Person_Tags>;
  /** fetch aggregated fields from the table: "omnichannel.person_tags" */
  omnichannel_person_tags_aggregate: Omnichannel_Person_Tags_Aggregate;
  /** fetch data from the table: "omnichannel.person_tags" using primary key columns */
  omnichannel_person_tags_by_pk?: Maybe<Omnichannel_Person_Tags>;
  /** fetch data from the table: "omnichannel.person_type" */
  omnichannel_person_type: Array<Omnichannel_Person_Type>;
  /** fetch aggregated fields from the table: "omnichannel.person_type" */
  omnichannel_person_type_aggregate: Omnichannel_Person_Type_Aggregate;
  /** fetch data from the table: "omnichannel.person_type" using primary key columns */
  omnichannel_person_type_by_pk?: Maybe<Omnichannel_Person_Type>;
  /** fetch data from the table: "omnichannel.persons" */
  omnichannel_persons: Array<Omnichannel_Persons>;
  /** fetch aggregated fields from the table: "omnichannel.persons" */
  omnichannel_persons_aggregate: Omnichannel_Persons_Aggregate;
  /** fetch data from the table: "omnichannel.persons" using primary key columns */
  omnichannel_persons_by_pk?: Maybe<Omnichannel_Persons>;
  /** fetch data from the table: "omnichannel.pipeline" */
  omnichannel_pipeline: Array<Omnichannel_Pipeline>;
  /** fetch aggregated fields from the table: "omnichannel.pipeline" */
  omnichannel_pipeline_aggregate: Omnichannel_Pipeline_Aggregate;
  /** fetch data from the table: "omnichannel.pipeline" using primary key columns */
  omnichannel_pipeline_by_pk?: Maybe<Omnichannel_Pipeline>;
  /** fetch data from the table: "omnichannel.pipeline_card" */
  omnichannel_pipeline_card: Array<Omnichannel_Pipeline_Card>;
  /** fetch aggregated fields from the table: "omnichannel.pipeline_card" */
  omnichannel_pipeline_card_aggregate: Omnichannel_Pipeline_Card_Aggregate;
  /** fetch data from the table: "omnichannel.pipeline_card" using primary key columns */
  omnichannel_pipeline_card_by_pk?: Maybe<Omnichannel_Pipeline_Card>;
  /** fetch data from the table: "omnichannel.pipeline_comments" */
  omnichannel_pipeline_comments: Array<Omnichannel_Pipeline_Comments>;
  /** fetch aggregated fields from the table: "omnichannel.pipeline_comments" */
  omnichannel_pipeline_comments_aggregate: Omnichannel_Pipeline_Comments_Aggregate;
  /** fetch data from the table: "omnichannel.pipeline_comments" using primary key columns */
  omnichannel_pipeline_comments_by_pk?: Maybe<Omnichannel_Pipeline_Comments>;
  /** fetch data from the table: "omnichannel.pipeline_list" */
  omnichannel_pipeline_list: Array<Omnichannel_Pipeline_List>;
  /** fetch data from the table: "omnichannel.pipeline_list_actions" */
  omnichannel_pipeline_list_actions: Array<Omnichannel_Pipeline_List_Actions>;
  /** fetch aggregated fields from the table: "omnichannel.pipeline_list_actions" */
  omnichannel_pipeline_list_actions_aggregate: Omnichannel_Pipeline_List_Actions_Aggregate;
  /** fetch data from the table: "omnichannel.pipeline_list_actions" using primary key columns */
  omnichannel_pipeline_list_actions_by_pk?: Maybe<Omnichannel_Pipeline_List_Actions>;
  /** fetch aggregated fields from the table: "omnichannel.pipeline_list" */
  omnichannel_pipeline_list_aggregate: Omnichannel_Pipeline_List_Aggregate;
  /** fetch data from the table: "omnichannel.pipeline_list" using primary key columns */
  omnichannel_pipeline_list_by_pk?: Maybe<Omnichannel_Pipeline_List>;
  /** fetch data from the table: "omnichannel.pipeline_products" */
  omnichannel_pipeline_products: Array<Omnichannel_Pipeline_Products>;
  /** fetch aggregated fields from the table: "omnichannel.pipeline_products" */
  omnichannel_pipeline_products_aggregate: Omnichannel_Pipeline_Products_Aggregate;
  /** fetch data from the table: "omnichannel.pipeline_products" using primary key columns */
  omnichannel_pipeline_products_by_pk?: Maybe<Omnichannel_Pipeline_Products>;
  /** fetch data from the table: "omnichannel.pipeline_status" */
  omnichannel_pipeline_status: Array<Omnichannel_Pipeline_Status>;
  /** fetch aggregated fields from the table: "omnichannel.pipeline_status" */
  omnichannel_pipeline_status_aggregate: Omnichannel_Pipeline_Status_Aggregate;
  /** fetch data from the table: "omnichannel.pipeline_status" using primary key columns */
  omnichannel_pipeline_status_by_pk?: Maybe<Omnichannel_Pipeline_Status>;
  /** fetch data from the table: "omnichannel.pipeline_tags" */
  omnichannel_pipeline_tags: Array<Omnichannel_Pipeline_Tags>;
  /** fetch aggregated fields from the table: "omnichannel.pipeline_tags" */
  omnichannel_pipeline_tags_aggregate: Omnichannel_Pipeline_Tags_Aggregate;
  /** fetch data from the table: "omnichannel.pipeline_tags" using primary key columns */
  omnichannel_pipeline_tags_by_pk?: Maybe<Omnichannel_Pipeline_Tags>;
  /** fetch data from the table: "omnichannel.products" */
  omnichannel_products: Array<Omnichannel_Products>;
  /** fetch aggregated fields from the table: "omnichannel.products" */
  omnichannel_products_aggregate: Omnichannel_Products_Aggregate;
  /** fetch data from the table: "omnichannel.products" using primary key columns */
  omnichannel_products_by_pk?: Maybe<Omnichannel_Products>;
  /** fetch data from the table: "omnichannel.tags" */
  omnichannel_tags: Array<Omnichannel_Tags>;
  /** fetch aggregated fields from the table: "omnichannel.tags" */
  omnichannel_tags_aggregate: Omnichannel_Tags_Aggregate;
  /** fetch data from the table: "omnichannel.tags" using primary key columns */
  omnichannel_tags_by_pk?: Maybe<Omnichannel_Tags>;
  /** fetch data from the table: "omnichannel.vw_activities_group" */
  omnichannel_vw_activities_group: Array<Omnichannel_Vw_Activities_Group>;
  /** fetch aggregated fields from the table: "omnichannel.vw_activities_group" */
  omnichannel_vw_activities_group_aggregate: Omnichannel_Vw_Activities_Group_Aggregate;
  /** fetch data from the table: "omnichannel.vw_randon_users" */
  omnichannel_vw_randon_users: Array<Omnichannel_Vw_Randon_Users>;
  /** fetch aggregated fields from the table: "omnichannel.vw_randon_users" */
  omnichannel_vw_randon_users_aggregate: Omnichannel_Vw_Randon_Users_Aggregate;
  /** fetch data from the table: "organizations" */
  organizations: Array<Organizations>;
  /** fetch aggregated fields from the table: "organizations" */
  organizations_aggregate: Organizations_Aggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** fetch data from the table: "permissions" */
  permissions: Array<Permissions>;
  /** fetch aggregated fields from the table: "permissions" */
  permissions_aggregate: Permissions_Aggregate;
  /** fetch data from the table: "permissions" using primary key columns */
  permissions_by_pk?: Maybe<Permissions>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  sidebar?: Maybe<Sidebar>;
  /** fetch data from the table: "stripe_plan" */
  stripe_plan: Array<Stripe_Plan>;
  /** fetch aggregated fields from the table: "stripe_plan" */
  stripe_plan_aggregate: Stripe_Plan_Aggregate;
  /** fetch data from the table: "stripe_plan" using primary key columns */
  stripe_plan_by_pk?: Maybe<Stripe_Plan>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


/** query root */
export type Query_RootAccountsArgs = {
  accounts?: Maybe<Scalars['String']>;
};


/** query root */
export type Query_RootAcl_FeaturesArgs = {
  distinct_on?: Maybe<Array<Acl_Features_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Acl_Features_Order_By>>;
  where?: Maybe<Acl_Features_Bool_Exp>;
};


/** query root */
export type Query_RootAcl_Features_AggregateArgs = {
  distinct_on?: Maybe<Array<Acl_Features_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Acl_Features_Order_By>>;
  where?: Maybe<Acl_Features_Bool_Exp>;
};


/** query root */
export type Query_RootAcl_Features_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootAcl_Features_PermissionsArgs = {
  distinct_on?: Maybe<Array<Acl_Features_Permissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Acl_Features_Permissions_Order_By>>;
  where?: Maybe<Acl_Features_Permissions_Bool_Exp>;
};


/** query root */
export type Query_RootAcl_Features_Permissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Acl_Features_Permissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Acl_Features_Permissions_Order_By>>;
  where?: Maybe<Acl_Features_Permissions_Bool_Exp>;
};


/** query root */
export type Query_RootAcl_Features_Permissions_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootApikeysArgs = {
  distinct_on?: Maybe<Array<Apikeys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apikeys_Order_By>>;
  where?: Maybe<Apikeys_Bool_Exp>;
};


/** query root */
export type Query_RootApikeys_AggregateArgs = {
  distinct_on?: Maybe<Array<Apikeys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apikeys_Order_By>>;
  where?: Maybe<Apikeys_Bool_Exp>;
};


/** query root */
export type Query_RootApikeys_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootAuth_Account_ProvidersArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Account_Providers_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Account_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootAuth_Account_RolesArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Account_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Account_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootAuth_AccountsArgs = {
  distinct_on?: Maybe<Array<Auth_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Accounts_Order_By>>;
  where?: Maybe<Auth_Accounts_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Accounts_Order_By>>;
  where?: Maybe<Auth_Accounts_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootAuth_ProvidersArgs = {
  distinct_on?: Maybe<Array<Auth_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Providers_Order_By>>;
  where?: Maybe<Auth_Providers_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Providers_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Providers_Order_By>>;
  where?: Maybe<Auth_Providers_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Providers_By_PkArgs = {
  provider: Scalars['String'];
};


/** query root */
export type Query_RootAuth_Refresh_TokensArgs = {
  distinct_on?: Maybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Refresh_Tokens_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Refresh_Tokens_By_PkArgs = {
  refresh_token: Scalars['uuid'];
};


/** query root */
export type Query_RootAuth_RolesArgs = {
  distinct_on?: Maybe<Array<Auth_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Roles_Order_By>>;
  where?: Maybe<Auth_Roles_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Roles_Order_By>>;
  where?: Maybe<Auth_Roles_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Roles_By_PkArgs = {
  role: Scalars['String'];
};


/** query root */
export type Query_RootCidadesArgs = {
  distinct_on?: Maybe<Array<Cidades_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cidades_Order_By>>;
  where?: Maybe<Cidades_Bool_Exp>;
};


/** query root */
export type Query_RootCidades_AggregateArgs = {
  distinct_on?: Maybe<Array<Cidades_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cidades_Order_By>>;
  where?: Maybe<Cidades_Bool_Exp>;
};


/** query root */
export type Query_RootCidades_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootCompanyArgs = {
  publicationState?: Maybe<PublicationState>;
};


/** query root */
export type Query_RootEstadosArgs = {
  distinct_on?: Maybe<Array<Estados_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Estados_Order_By>>;
  where?: Maybe<Estados_Bool_Exp>;
};


/** query root */
export type Query_RootEstados_AggregateArgs = {
  distinct_on?: Maybe<Array<Estados_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Estados_Order_By>>;
  where?: Maybe<Estados_Bool_Exp>;
};


/** query root */
export type Query_RootEstados_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootFilesArgs = {
  limit?: Maybe<Scalars['Int']>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};


/** query root */
export type Query_RootFilesConnectionArgs = {
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};


/** query root */
export type Query_RootGet_Mail_AccountArgs = {
  accounts?: Maybe<Scalars['uuid']>;
  message_id?: Maybe<Scalars['String']>;
};


/** query root */
export type Query_RootLoginArgs = {
  publicationState?: Maybe<PublicationState>;
};


/** query root */
export type Query_RootMembershipsArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};


/** query root */
export type Query_RootMemberships_AggregateArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};


/** query root */
export type Query_RootMemberships_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootMenuArgs = {
  publicationState?: Maybe<PublicationState>;
};


/** query root */
export type Query_RootOmnichannel_AddonsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Addons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Addons_Order_By>>;
  where?: Maybe<Omnichannel_Addons_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Addons_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Addons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Addons_Order_By>>;
  where?: Maybe<Omnichannel_Addons_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Addons_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_Addons_Connect_SettingsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Addons_Connect_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Addons_Connect_Settings_Order_By>>;
  where?: Maybe<Omnichannel_Addons_Connect_Settings_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Addons_Connect_Settings_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Addons_Connect_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Addons_Connect_Settings_Order_By>>;
  where?: Maybe<Omnichannel_Addons_Connect_Settings_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Addons_Connect_Settings_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_Addons_ConnectsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Addons_Connects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Addons_Connects_Order_By>>;
  where?: Maybe<Omnichannel_Addons_Connects_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Addons_Connects_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Addons_Connects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Addons_Connects_Order_By>>;
  where?: Maybe<Omnichannel_Addons_Connects_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Addons_Connects_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_Channel_EnabledArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channel_Enabled_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channel_Enabled_Order_By>>;
  where?: Maybe<Omnichannel_Channel_Enabled_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Channel_Enabled_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channel_Enabled_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channel_Enabled_Order_By>>;
  where?: Maybe<Omnichannel_Channel_Enabled_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Channel_Enabled_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_Channel_Enabled_SettingsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channel_Enabled_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channel_Enabled_Settings_Order_By>>;
  where?: Maybe<Omnichannel_Channel_Enabled_Settings_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Channel_Enabled_Settings_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channel_Enabled_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channel_Enabled_Settings_Order_By>>;
  where?: Maybe<Omnichannel_Channel_Enabled_Settings_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Channel_Enabled_Settings_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_Channel_MessageArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channel_Message_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channel_Message_Order_By>>;
  where?: Maybe<Omnichannel_Channel_Message_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Channel_Message_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channel_Message_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channel_Message_Order_By>>;
  where?: Maybe<Omnichannel_Channel_Message_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Channel_Message_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_Channel_SessionsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channel_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channel_Sessions_Order_By>>;
  where?: Maybe<Omnichannel_Channel_Sessions_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Channel_Sessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channel_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channel_Sessions_Order_By>>;
  where?: Maybe<Omnichannel_Channel_Sessions_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Channel_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_ChannelsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channels_Order_By>>;
  where?: Maybe<Omnichannel_Channels_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Channels_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channels_Order_By>>;
  where?: Maybe<Omnichannel_Channels_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Channels_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_GroupsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Groups_Order_By>>;
  where?: Maybe<Omnichannel_Groups_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Groups_Order_By>>;
  where?: Maybe<Omnichannel_Groups_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Groups_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootOmnichannel_Groups_UsersArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Groups_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Groups_Users_Order_By>>;
  where?: Maybe<Omnichannel_Groups_Users_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Groups_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Groups_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Groups_Users_Order_By>>;
  where?: Maybe<Omnichannel_Groups_Users_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Groups_Users_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootOmnichannel_Mailbox_Mail_AddonsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Mailbox_Mail_Addons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Mailbox_Mail_Addons_Order_By>>;
  where?: Maybe<Omnichannel_Mailbox_Mail_Addons_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Mailbox_Mail_Addons_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Mailbox_Mail_Addons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Mailbox_Mail_Addons_Order_By>>;
  where?: Maybe<Omnichannel_Mailbox_Mail_Addons_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Mailbox_Mail_Addons_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_Message_Mail_AddonsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Message_Mail_Addons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Message_Mail_Addons_Order_By>>;
  where?: Maybe<Omnichannel_Message_Mail_Addons_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Message_Mail_Addons_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Message_Mail_Addons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Message_Mail_Addons_Order_By>>;
  where?: Maybe<Omnichannel_Message_Mail_Addons_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Message_Mail_Addons_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_Person_ActivitiesArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Activities_Order_By>>;
  where?: Maybe<Omnichannel_Person_Activities_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Person_Activities_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Activities_Order_By>>;
  where?: Maybe<Omnichannel_Person_Activities_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Person_Activities_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_Person_FileArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_File_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_File_Order_By>>;
  where?: Maybe<Omnichannel_Person_File_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Person_File_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_File_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_File_Order_By>>;
  where?: Maybe<Omnichannel_Person_File_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Person_File_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_Person_MailArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Mail_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Mail_Order_By>>;
  where?: Maybe<Omnichannel_Person_Mail_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Person_Mail_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Mail_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Mail_Order_By>>;
  where?: Maybe<Omnichannel_Person_Mail_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Person_Mail_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_Person_NoteArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Note_Order_By>>;
  where?: Maybe<Omnichannel_Person_Note_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Person_Note_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Note_Order_By>>;
  where?: Maybe<Omnichannel_Person_Note_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Person_Note_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_Person_TagsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Tags_Order_By>>;
  where?: Maybe<Omnichannel_Person_Tags_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Person_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Tags_Order_By>>;
  where?: Maybe<Omnichannel_Person_Tags_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Person_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_Person_TypeArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Type_Order_By>>;
  where?: Maybe<Omnichannel_Person_Type_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Person_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Type_Order_By>>;
  where?: Maybe<Omnichannel_Person_Type_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Person_Type_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootOmnichannel_PersonsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Persons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Persons_Order_By>>;
  where?: Maybe<Omnichannel_Persons_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Persons_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Persons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Persons_Order_By>>;
  where?: Maybe<Omnichannel_Persons_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Persons_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_PipelineArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Pipeline_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Pipeline_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_Pipeline_CardArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Card_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Card_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Card_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Pipeline_Card_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Card_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Card_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Card_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Pipeline_Card_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_Pipeline_CommentsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Comments_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Comments_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Pipeline_Comments_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Comments_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Comments_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Pipeline_Comments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_Pipeline_ListArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_List_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_List_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_List_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Pipeline_List_ActionsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_List_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_List_Actions_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_List_Actions_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Pipeline_List_Actions_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_List_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_List_Actions_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_List_Actions_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Pipeline_List_Actions_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootOmnichannel_Pipeline_List_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_List_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_List_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_List_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Pipeline_List_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_Pipeline_ProductsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Products_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Products_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Pipeline_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Products_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Products_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Pipeline_Products_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootOmnichannel_Pipeline_StatusArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Status_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Status_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Pipeline_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Status_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Status_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Pipeline_Status_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_Pipeline_TagsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Tags_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Tags_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Pipeline_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Tags_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Tags_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Pipeline_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_ProductsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Products_Order_By>>;
  where?: Maybe<Omnichannel_Products_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Products_Order_By>>;
  where?: Maybe<Omnichannel_Products_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Products_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_TagsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Tags_Order_By>>;
  where?: Maybe<Omnichannel_Tags_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Tags_Order_By>>;
  where?: Maybe<Omnichannel_Tags_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOmnichannel_Vw_Activities_GroupArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Vw_Activities_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Vw_Activities_Group_Order_By>>;
  where?: Maybe<Omnichannel_Vw_Activities_Group_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Vw_Activities_Group_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Vw_Activities_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Vw_Activities_Group_Order_By>>;
  where?: Maybe<Omnichannel_Vw_Activities_Group_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Vw_Randon_UsersArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Vw_Randon_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Vw_Randon_Users_Order_By>>;
  where?: Maybe<Omnichannel_Vw_Randon_Users_Bool_Exp>;
};


/** query root */
export type Query_RootOmnichannel_Vw_Randon_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Vw_Randon_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Vw_Randon_Users_Order_By>>;
  where?: Maybe<Omnichannel_Vw_Randon_Users_Bool_Exp>;
};


/** query root */
export type Query_RootOrganizationsArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};


/** query root */
export type Query_RootOrganizations_AggregateArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};


/** query root */
export type Query_RootOrganizations_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootPermissionsArgs = {
  distinct_on?: Maybe<Array<Permissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Permissions_Order_By>>;
  where?: Maybe<Permissions_Bool_Exp>;
};


/** query root */
export type Query_RootPermissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Permissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Permissions_Order_By>>;
  where?: Maybe<Permissions_Bool_Exp>;
};


/** query root */
export type Query_RootPermissions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootProductArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


/** query root */
export type Query_RootProduct_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


/** query root */
export type Query_RootProduct_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootSidebarArgs = {
  publicationState?: Maybe<PublicationState>;
};


/** query root */
export type Query_RootStripe_PlanArgs = {
  distinct_on?: Maybe<Array<Stripe_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stripe_Plan_Order_By>>;
  where?: Maybe<Stripe_Plan_Bool_Exp>;
};


/** query root */
export type Query_RootStripe_Plan_AggregateArgs = {
  distinct_on?: Maybe<Array<Stripe_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stripe_Plan_Order_By>>;
  where?: Maybe<Stripe_Plan_Bool_Exp>;
};


/** query root */
export type Query_RootStripe_Plan_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "stripe_plan" */
export type Stripe_Plan = {
  __typename?: 'stripe_plan';
  billing_period: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  limit_bot: Scalars['Int'];
  limit_task: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['numeric'];
  price_id: Scalars['String'];
  product_id: Scalars['Int'];
  /** An object relationship */
  products?: Maybe<Product>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "stripe_plan" */
export type Stripe_Plan_Aggregate = {
  __typename?: 'stripe_plan_aggregate';
  aggregate?: Maybe<Stripe_Plan_Aggregate_Fields>;
  nodes: Array<Stripe_Plan>;
};

/** aggregate fields of "stripe_plan" */
export type Stripe_Plan_Aggregate_Fields = {
  __typename?: 'stripe_plan_aggregate_fields';
  avg?: Maybe<Stripe_Plan_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Stripe_Plan_Max_Fields>;
  min?: Maybe<Stripe_Plan_Min_Fields>;
  stddev?: Maybe<Stripe_Plan_Stddev_Fields>;
  stddev_pop?: Maybe<Stripe_Plan_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stripe_Plan_Stddev_Samp_Fields>;
  sum?: Maybe<Stripe_Plan_Sum_Fields>;
  var_pop?: Maybe<Stripe_Plan_Var_Pop_Fields>;
  var_samp?: Maybe<Stripe_Plan_Var_Samp_Fields>;
  variance?: Maybe<Stripe_Plan_Variance_Fields>;
};


/** aggregate fields of "stripe_plan" */
export type Stripe_Plan_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stripe_Plan_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stripe_plan" */
export type Stripe_Plan_Aggregate_Order_By = {
  avg?: Maybe<Stripe_Plan_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Stripe_Plan_Max_Order_By>;
  min?: Maybe<Stripe_Plan_Min_Order_By>;
  stddev?: Maybe<Stripe_Plan_Stddev_Order_By>;
  stddev_pop?: Maybe<Stripe_Plan_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Stripe_Plan_Stddev_Samp_Order_By>;
  sum?: Maybe<Stripe_Plan_Sum_Order_By>;
  var_pop?: Maybe<Stripe_Plan_Var_Pop_Order_By>;
  var_samp?: Maybe<Stripe_Plan_Var_Samp_Order_By>;
  variance?: Maybe<Stripe_Plan_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stripe_plan" */
export type Stripe_Plan_Arr_Rel_Insert_Input = {
  data: Array<Stripe_Plan_Insert_Input>;
  on_conflict?: Maybe<Stripe_Plan_On_Conflict>;
};

/** aggregate avg on columns */
export type Stripe_Plan_Avg_Fields = {
  __typename?: 'stripe_plan_avg_fields';
  billing_period?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_bot?: Maybe<Scalars['Float']>;
  limit_task?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stripe_plan" */
export type Stripe_Plan_Avg_Order_By = {
  billing_period?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  limit_bot?: Maybe<Order_By>;
  limit_task?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stripe_plan". All fields are combined with a logical 'AND'. */
export type Stripe_Plan_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Stripe_Plan_Bool_Exp>>>;
  _not?: Maybe<Stripe_Plan_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Stripe_Plan_Bool_Exp>>>;
  billing_period?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  limit_bot?: Maybe<Int_Comparison_Exp>;
  limit_task?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  price_id?: Maybe<String_Comparison_Exp>;
  product_id?: Maybe<Int_Comparison_Exp>;
  products?: Maybe<Product_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "stripe_plan" */
export enum Stripe_Plan_Constraint {
  /** unique or primary key constraint */
  StripePlanPkey = 'stripe_plan_pkey'
}

/** input type for incrementing integer column in table "stripe_plan" */
export type Stripe_Plan_Inc_Input = {
  billing_period?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  limit_bot?: Maybe<Scalars['Int']>;
  limit_task?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "stripe_plan" */
export type Stripe_Plan_Insert_Input = {
  billing_period?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  limit_bot?: Maybe<Scalars['Int']>;
  limit_task?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  price_id?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['Int']>;
  products?: Maybe<Product_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Stripe_Plan_Max_Fields = {
  __typename?: 'stripe_plan_max_fields';
  billing_period?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  limit_bot?: Maybe<Scalars['Int']>;
  limit_task?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  price_id?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "stripe_plan" */
export type Stripe_Plan_Max_Order_By = {
  billing_period?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  limit_bot?: Maybe<Order_By>;
  limit_task?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  price_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Stripe_Plan_Min_Fields = {
  __typename?: 'stripe_plan_min_fields';
  billing_period?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  limit_bot?: Maybe<Scalars['Int']>;
  limit_task?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  price_id?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "stripe_plan" */
export type Stripe_Plan_Min_Order_By = {
  billing_period?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  limit_bot?: Maybe<Order_By>;
  limit_task?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  price_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "stripe_plan" */
export type Stripe_Plan_Mutation_Response = {
  __typename?: 'stripe_plan_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Stripe_Plan>;
};

/** input type for inserting object relation for remote table "stripe_plan" */
export type Stripe_Plan_Obj_Rel_Insert_Input = {
  data: Stripe_Plan_Insert_Input;
  on_conflict?: Maybe<Stripe_Plan_On_Conflict>;
};

/** on conflict condition type for table "stripe_plan" */
export type Stripe_Plan_On_Conflict = {
  constraint: Stripe_Plan_Constraint;
  update_columns: Array<Stripe_Plan_Update_Column>;
  where?: Maybe<Stripe_Plan_Bool_Exp>;
};

/** ordering options when selecting data from "stripe_plan" */
export type Stripe_Plan_Order_By = {
  billing_period?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  limit_bot?: Maybe<Order_By>;
  limit_task?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  price_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  products?: Maybe<Product_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "stripe_plan" */
export type Stripe_Plan_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "stripe_plan" */
export enum Stripe_Plan_Select_Column {
  /** column name */
  BillingPeriod = 'billing_period',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LimitBot = 'limit_bot',
  /** column name */
  LimitTask = 'limit_task',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  PriceId = 'price_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "stripe_plan" */
export type Stripe_Plan_Set_Input = {
  billing_period?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  limit_bot?: Maybe<Scalars['Int']>;
  limit_task?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  price_id?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Stripe_Plan_Stddev_Fields = {
  __typename?: 'stripe_plan_stddev_fields';
  billing_period?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_bot?: Maybe<Scalars['Float']>;
  limit_task?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stripe_plan" */
export type Stripe_Plan_Stddev_Order_By = {
  billing_period?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  limit_bot?: Maybe<Order_By>;
  limit_task?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stripe_Plan_Stddev_Pop_Fields = {
  __typename?: 'stripe_plan_stddev_pop_fields';
  billing_period?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_bot?: Maybe<Scalars['Float']>;
  limit_task?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stripe_plan" */
export type Stripe_Plan_Stddev_Pop_Order_By = {
  billing_period?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  limit_bot?: Maybe<Order_By>;
  limit_task?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stripe_Plan_Stddev_Samp_Fields = {
  __typename?: 'stripe_plan_stddev_samp_fields';
  billing_period?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_bot?: Maybe<Scalars['Float']>;
  limit_task?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stripe_plan" */
export type Stripe_Plan_Stddev_Samp_Order_By = {
  billing_period?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  limit_bot?: Maybe<Order_By>;
  limit_task?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Stripe_Plan_Sum_Fields = {
  __typename?: 'stripe_plan_sum_fields';
  billing_period?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  limit_bot?: Maybe<Scalars['Int']>;
  limit_task?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "stripe_plan" */
export type Stripe_Plan_Sum_Order_By = {
  billing_period?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  limit_bot?: Maybe<Order_By>;
  limit_task?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** update columns of table "stripe_plan" */
export enum Stripe_Plan_Update_Column {
  /** column name */
  BillingPeriod = 'billing_period',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LimitBot = 'limit_bot',
  /** column name */
  LimitTask = 'limit_task',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  PriceId = 'price_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Stripe_Plan_Var_Pop_Fields = {
  __typename?: 'stripe_plan_var_pop_fields';
  billing_period?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_bot?: Maybe<Scalars['Float']>;
  limit_task?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stripe_plan" */
export type Stripe_Plan_Var_Pop_Order_By = {
  billing_period?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  limit_bot?: Maybe<Order_By>;
  limit_task?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stripe_Plan_Var_Samp_Fields = {
  __typename?: 'stripe_plan_var_samp_fields';
  billing_period?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_bot?: Maybe<Scalars['Float']>;
  limit_task?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stripe_plan" */
export type Stripe_Plan_Var_Samp_Order_By = {
  billing_period?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  limit_bot?: Maybe<Order_By>;
  limit_task?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Stripe_Plan_Variance_Fields = {
  __typename?: 'stripe_plan_variance_fields';
  billing_period?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_bot?: Maybe<Scalars['Float']>;
  limit_task?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stripe_plan" */
export type Stripe_Plan_Variance_Order_By = {
  billing_period?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  limit_bot?: Maybe<Order_By>;
  limit_task?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** perform the action: "accounts" */
  accounts?: Maybe<MailAccountOutput>;
  /** fetch data from the table: "acl_features" */
  acl_features: Array<Acl_Features>;
  /** fetch aggregated fields from the table: "acl_features" */
  acl_features_aggregate: Acl_Features_Aggregate;
  /** fetch data from the table: "acl_features" using primary key columns */
  acl_features_by_pk?: Maybe<Acl_Features>;
  /** fetch data from the table: "acl_features_permissions" */
  acl_features_permissions: Array<Acl_Features_Permissions>;
  /** fetch aggregated fields from the table: "acl_features_permissions" */
  acl_features_permissions_aggregate: Acl_Features_Permissions_Aggregate;
  /** fetch data from the table: "acl_features_permissions" using primary key columns */
  acl_features_permissions_by_pk?: Maybe<Acl_Features_Permissions>;
  /** fetch data from the table: "apikeys" */
  apikeys: Array<Apikeys>;
  /** fetch aggregated fields from the table: "apikeys" */
  apikeys_aggregate: Apikeys_Aggregate;
  /** fetch data from the table: "apikeys" using primary key columns */
  apikeys_by_pk?: Maybe<Apikeys>;
  /** fetch data from the table: "auth.account_providers" */
  auth_account_providers: Array<Auth_Account_Providers>;
  /** fetch aggregated fields from the table: "auth.account_providers" */
  auth_account_providers_aggregate: Auth_Account_Providers_Aggregate;
  /** fetch data from the table: "auth.account_providers" using primary key columns */
  auth_account_providers_by_pk?: Maybe<Auth_Account_Providers>;
  /** fetch data from the table: "auth.account_roles" */
  auth_account_roles: Array<Auth_Account_Roles>;
  /** fetch aggregated fields from the table: "auth.account_roles" */
  auth_account_roles_aggregate: Auth_Account_Roles_Aggregate;
  /** fetch data from the table: "auth.account_roles" using primary key columns */
  auth_account_roles_by_pk?: Maybe<Auth_Account_Roles>;
  /** fetch data from the table: "auth.accounts" */
  auth_accounts: Array<Auth_Accounts>;
  /** fetch aggregated fields from the table: "auth.accounts" */
  auth_accounts_aggregate: Auth_Accounts_Aggregate;
  /** fetch data from the table: "auth.accounts" using primary key columns */
  auth_accounts_by_pk?: Maybe<Auth_Accounts>;
  /** fetch data from the table: "auth.providers" */
  auth_providers: Array<Auth_Providers>;
  /** fetch aggregated fields from the table: "auth.providers" */
  auth_providers_aggregate: Auth_Providers_Aggregate;
  /** fetch data from the table: "auth.providers" using primary key columns */
  auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** fetch data from the table: "auth.refresh_tokens" */
  auth_refresh_tokens: Array<Auth_Refresh_Tokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  auth_refresh_tokens_aggregate: Auth_Refresh_Tokens_Aggregate;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** fetch data from the table: "auth.roles" */
  auth_roles: Array<Auth_Roles>;
  /** fetch aggregated fields from the table: "auth.roles" */
  auth_roles_aggregate: Auth_Roles_Aggregate;
  /** fetch data from the table: "auth.roles" using primary key columns */
  auth_roles_by_pk?: Maybe<Auth_Roles>;
  /** fetch data from the table: "cidades" */
  cidades: Array<Cidades>;
  /** fetch aggregated fields from the table: "cidades" */
  cidades_aggregate: Cidades_Aggregate;
  /** fetch data from the table: "cidades" using primary key columns */
  cidades_by_pk?: Maybe<Cidades>;
  /** fetch data from the table: "estados" */
  estados: Array<Estados>;
  /** fetch aggregated fields from the table: "estados" */
  estados_aggregate: Estados_Aggregate;
  /** fetch data from the table: "estados" using primary key columns */
  estados_by_pk?: Maybe<Estados>;
  /** fetch data from the table: "memberships" */
  memberships: Array<Memberships>;
  /** fetch aggregated fields from the table: "memberships" */
  memberships_aggregate: Memberships_Aggregate;
  /** fetch data from the table: "memberships" using primary key columns */
  memberships_by_pk?: Maybe<Memberships>;
  /** fetch data from the table: "omnichannel.addons" */
  omnichannel_addons: Array<Omnichannel_Addons>;
  /** fetch aggregated fields from the table: "omnichannel.addons" */
  omnichannel_addons_aggregate: Omnichannel_Addons_Aggregate;
  /** fetch data from the table: "omnichannel.addons" using primary key columns */
  omnichannel_addons_by_pk?: Maybe<Omnichannel_Addons>;
  /** fetch data from the table: "omnichannel.addons_connect_settings" */
  omnichannel_addons_connect_settings: Array<Omnichannel_Addons_Connect_Settings>;
  /** fetch aggregated fields from the table: "omnichannel.addons_connect_settings" */
  omnichannel_addons_connect_settings_aggregate: Omnichannel_Addons_Connect_Settings_Aggregate;
  /** fetch data from the table: "omnichannel.addons_connect_settings" using primary key columns */
  omnichannel_addons_connect_settings_by_pk?: Maybe<Omnichannel_Addons_Connect_Settings>;
  /** fetch data from the table: "omnichannel.addons_connects" */
  omnichannel_addons_connects: Array<Omnichannel_Addons_Connects>;
  /** fetch aggregated fields from the table: "omnichannel.addons_connects" */
  omnichannel_addons_connects_aggregate: Omnichannel_Addons_Connects_Aggregate;
  /** fetch data from the table: "omnichannel.addons_connects" using primary key columns */
  omnichannel_addons_connects_by_pk?: Maybe<Omnichannel_Addons_Connects>;
  /** fetch data from the table: "omnichannel.channel_enabled" */
  omnichannel_channel_enabled: Array<Omnichannel_Channel_Enabled>;
  /** fetch aggregated fields from the table: "omnichannel.channel_enabled" */
  omnichannel_channel_enabled_aggregate: Omnichannel_Channel_Enabled_Aggregate;
  /** fetch data from the table: "omnichannel.channel_enabled" using primary key columns */
  omnichannel_channel_enabled_by_pk?: Maybe<Omnichannel_Channel_Enabled>;
  /** fetch data from the table: "omnichannel.channel_enabled_settings" */
  omnichannel_channel_enabled_settings: Array<Omnichannel_Channel_Enabled_Settings>;
  /** fetch aggregated fields from the table: "omnichannel.channel_enabled_settings" */
  omnichannel_channel_enabled_settings_aggregate: Omnichannel_Channel_Enabled_Settings_Aggregate;
  /** fetch data from the table: "omnichannel.channel_enabled_settings" using primary key columns */
  omnichannel_channel_enabled_settings_by_pk?: Maybe<Omnichannel_Channel_Enabled_Settings>;
  /** fetch data from the table: "omnichannel.channel_message" */
  omnichannel_channel_message: Array<Omnichannel_Channel_Message>;
  /** fetch aggregated fields from the table: "omnichannel.channel_message" */
  omnichannel_channel_message_aggregate: Omnichannel_Channel_Message_Aggregate;
  /** fetch data from the table: "omnichannel.channel_message" using primary key columns */
  omnichannel_channel_message_by_pk?: Maybe<Omnichannel_Channel_Message>;
  /** fetch data from the table: "omnichannel.channel_sessions" */
  omnichannel_channel_sessions: Array<Omnichannel_Channel_Sessions>;
  /** fetch aggregated fields from the table: "omnichannel.channel_sessions" */
  omnichannel_channel_sessions_aggregate: Omnichannel_Channel_Sessions_Aggregate;
  /** fetch data from the table: "omnichannel.channel_sessions" using primary key columns */
  omnichannel_channel_sessions_by_pk?: Maybe<Omnichannel_Channel_Sessions>;
  /** fetch data from the table: "omnichannel.channels" */
  omnichannel_channels: Array<Omnichannel_Channels>;
  /** fetch aggregated fields from the table: "omnichannel.channels" */
  omnichannel_channels_aggregate: Omnichannel_Channels_Aggregate;
  /** fetch data from the table: "omnichannel.channels" using primary key columns */
  omnichannel_channels_by_pk?: Maybe<Omnichannel_Channels>;
  /** fetch data from the table: "omnichannel.groups" */
  omnichannel_groups: Array<Omnichannel_Groups>;
  /** fetch aggregated fields from the table: "omnichannel.groups" */
  omnichannel_groups_aggregate: Omnichannel_Groups_Aggregate;
  /** fetch data from the table: "omnichannel.groups" using primary key columns */
  omnichannel_groups_by_pk?: Maybe<Omnichannel_Groups>;
  /** fetch data from the table: "omnichannel.groups_users" */
  omnichannel_groups_users: Array<Omnichannel_Groups_Users>;
  /** fetch aggregated fields from the table: "omnichannel.groups_users" */
  omnichannel_groups_users_aggregate: Omnichannel_Groups_Users_Aggregate;
  /** fetch data from the table: "omnichannel.groups_users" using primary key columns */
  omnichannel_groups_users_by_pk?: Maybe<Omnichannel_Groups_Users>;
  /** fetch data from the table: "omnichannel.mailbox_mail_addons" */
  omnichannel_mailbox_mail_addons: Array<Omnichannel_Mailbox_Mail_Addons>;
  /** fetch aggregated fields from the table: "omnichannel.mailbox_mail_addons" */
  omnichannel_mailbox_mail_addons_aggregate: Omnichannel_Mailbox_Mail_Addons_Aggregate;
  /** fetch data from the table: "omnichannel.mailbox_mail_addons" using primary key columns */
  omnichannel_mailbox_mail_addons_by_pk?: Maybe<Omnichannel_Mailbox_Mail_Addons>;
  /** fetch data from the table: "omnichannel.message_mail_addons" */
  omnichannel_message_mail_addons: Array<Omnichannel_Message_Mail_Addons>;
  /** fetch aggregated fields from the table: "omnichannel.message_mail_addons" */
  omnichannel_message_mail_addons_aggregate: Omnichannel_Message_Mail_Addons_Aggregate;
  /** fetch data from the table: "omnichannel.message_mail_addons" using primary key columns */
  omnichannel_message_mail_addons_by_pk?: Maybe<Omnichannel_Message_Mail_Addons>;
  /** fetch data from the table: "omnichannel.person_activities" */
  omnichannel_person_activities: Array<Omnichannel_Person_Activities>;
  /** fetch aggregated fields from the table: "omnichannel.person_activities" */
  omnichannel_person_activities_aggregate: Omnichannel_Person_Activities_Aggregate;
  /** fetch data from the table: "omnichannel.person_activities" using primary key columns */
  omnichannel_person_activities_by_pk?: Maybe<Omnichannel_Person_Activities>;
  /** fetch data from the table: "omnichannel.person_file" */
  omnichannel_person_file: Array<Omnichannel_Person_File>;
  /** fetch aggregated fields from the table: "omnichannel.person_file" */
  omnichannel_person_file_aggregate: Omnichannel_Person_File_Aggregate;
  /** fetch data from the table: "omnichannel.person_file" using primary key columns */
  omnichannel_person_file_by_pk?: Maybe<Omnichannel_Person_File>;
  /** fetch data from the table: "omnichannel.person_mail" */
  omnichannel_person_mail: Array<Omnichannel_Person_Mail>;
  /** fetch aggregated fields from the table: "omnichannel.person_mail" */
  omnichannel_person_mail_aggregate: Omnichannel_Person_Mail_Aggregate;
  /** fetch data from the table: "omnichannel.person_mail" using primary key columns */
  omnichannel_person_mail_by_pk?: Maybe<Omnichannel_Person_Mail>;
  /** fetch data from the table: "omnichannel.person_note" */
  omnichannel_person_note: Array<Omnichannel_Person_Note>;
  /** fetch aggregated fields from the table: "omnichannel.person_note" */
  omnichannel_person_note_aggregate: Omnichannel_Person_Note_Aggregate;
  /** fetch data from the table: "omnichannel.person_note" using primary key columns */
  omnichannel_person_note_by_pk?: Maybe<Omnichannel_Person_Note>;
  /** fetch data from the table: "omnichannel.person_tags" */
  omnichannel_person_tags: Array<Omnichannel_Person_Tags>;
  /** fetch aggregated fields from the table: "omnichannel.person_tags" */
  omnichannel_person_tags_aggregate: Omnichannel_Person_Tags_Aggregate;
  /** fetch data from the table: "omnichannel.person_tags" using primary key columns */
  omnichannel_person_tags_by_pk?: Maybe<Omnichannel_Person_Tags>;
  /** fetch data from the table: "omnichannel.person_type" */
  omnichannel_person_type: Array<Omnichannel_Person_Type>;
  /** fetch aggregated fields from the table: "omnichannel.person_type" */
  omnichannel_person_type_aggregate: Omnichannel_Person_Type_Aggregate;
  /** fetch data from the table: "omnichannel.person_type" using primary key columns */
  omnichannel_person_type_by_pk?: Maybe<Omnichannel_Person_Type>;
  /** fetch data from the table: "omnichannel.persons" */
  omnichannel_persons: Array<Omnichannel_Persons>;
  /** fetch aggregated fields from the table: "omnichannel.persons" */
  omnichannel_persons_aggregate: Omnichannel_Persons_Aggregate;
  /** fetch data from the table: "omnichannel.persons" using primary key columns */
  omnichannel_persons_by_pk?: Maybe<Omnichannel_Persons>;
  /** fetch data from the table: "omnichannel.pipeline" */
  omnichannel_pipeline: Array<Omnichannel_Pipeline>;
  /** fetch aggregated fields from the table: "omnichannel.pipeline" */
  omnichannel_pipeline_aggregate: Omnichannel_Pipeline_Aggregate;
  /** fetch data from the table: "omnichannel.pipeline" using primary key columns */
  omnichannel_pipeline_by_pk?: Maybe<Omnichannel_Pipeline>;
  /** fetch data from the table: "omnichannel.pipeline_card" */
  omnichannel_pipeline_card: Array<Omnichannel_Pipeline_Card>;
  /** fetch aggregated fields from the table: "omnichannel.pipeline_card" */
  omnichannel_pipeline_card_aggregate: Omnichannel_Pipeline_Card_Aggregate;
  /** fetch data from the table: "omnichannel.pipeline_card" using primary key columns */
  omnichannel_pipeline_card_by_pk?: Maybe<Omnichannel_Pipeline_Card>;
  /** fetch data from the table: "omnichannel.pipeline_comments" */
  omnichannel_pipeline_comments: Array<Omnichannel_Pipeline_Comments>;
  /** fetch aggregated fields from the table: "omnichannel.pipeline_comments" */
  omnichannel_pipeline_comments_aggregate: Omnichannel_Pipeline_Comments_Aggregate;
  /** fetch data from the table: "omnichannel.pipeline_comments" using primary key columns */
  omnichannel_pipeline_comments_by_pk?: Maybe<Omnichannel_Pipeline_Comments>;
  /** fetch data from the table: "omnichannel.pipeline_list" */
  omnichannel_pipeline_list: Array<Omnichannel_Pipeline_List>;
  /** fetch data from the table: "omnichannel.pipeline_list_actions" */
  omnichannel_pipeline_list_actions: Array<Omnichannel_Pipeline_List_Actions>;
  /** fetch aggregated fields from the table: "omnichannel.pipeline_list_actions" */
  omnichannel_pipeline_list_actions_aggregate: Omnichannel_Pipeline_List_Actions_Aggregate;
  /** fetch data from the table: "omnichannel.pipeline_list_actions" using primary key columns */
  omnichannel_pipeline_list_actions_by_pk?: Maybe<Omnichannel_Pipeline_List_Actions>;
  /** fetch aggregated fields from the table: "omnichannel.pipeline_list" */
  omnichannel_pipeline_list_aggregate: Omnichannel_Pipeline_List_Aggregate;
  /** fetch data from the table: "omnichannel.pipeline_list" using primary key columns */
  omnichannel_pipeline_list_by_pk?: Maybe<Omnichannel_Pipeline_List>;
  /** fetch data from the table: "omnichannel.pipeline_products" */
  omnichannel_pipeline_products: Array<Omnichannel_Pipeline_Products>;
  /** fetch aggregated fields from the table: "omnichannel.pipeline_products" */
  omnichannel_pipeline_products_aggregate: Omnichannel_Pipeline_Products_Aggregate;
  /** fetch data from the table: "omnichannel.pipeline_products" using primary key columns */
  omnichannel_pipeline_products_by_pk?: Maybe<Omnichannel_Pipeline_Products>;
  /** fetch data from the table: "omnichannel.pipeline_status" */
  omnichannel_pipeline_status: Array<Omnichannel_Pipeline_Status>;
  /** fetch aggregated fields from the table: "omnichannel.pipeline_status" */
  omnichannel_pipeline_status_aggregate: Omnichannel_Pipeline_Status_Aggregate;
  /** fetch data from the table: "omnichannel.pipeline_status" using primary key columns */
  omnichannel_pipeline_status_by_pk?: Maybe<Omnichannel_Pipeline_Status>;
  /** fetch data from the table: "omnichannel.pipeline_tags" */
  omnichannel_pipeline_tags: Array<Omnichannel_Pipeline_Tags>;
  /** fetch aggregated fields from the table: "omnichannel.pipeline_tags" */
  omnichannel_pipeline_tags_aggregate: Omnichannel_Pipeline_Tags_Aggregate;
  /** fetch data from the table: "omnichannel.pipeline_tags" using primary key columns */
  omnichannel_pipeline_tags_by_pk?: Maybe<Omnichannel_Pipeline_Tags>;
  /** fetch data from the table: "omnichannel.products" */
  omnichannel_products: Array<Omnichannel_Products>;
  /** fetch aggregated fields from the table: "omnichannel.products" */
  omnichannel_products_aggregate: Omnichannel_Products_Aggregate;
  /** fetch data from the table: "omnichannel.products" using primary key columns */
  omnichannel_products_by_pk?: Maybe<Omnichannel_Products>;
  /** fetch data from the table: "omnichannel.tags" */
  omnichannel_tags: Array<Omnichannel_Tags>;
  /** fetch aggregated fields from the table: "omnichannel.tags" */
  omnichannel_tags_aggregate: Omnichannel_Tags_Aggregate;
  /** fetch data from the table: "omnichannel.tags" using primary key columns */
  omnichannel_tags_by_pk?: Maybe<Omnichannel_Tags>;
  /** fetch data from the table: "omnichannel.vw_activities_group" */
  omnichannel_vw_activities_group: Array<Omnichannel_Vw_Activities_Group>;
  /** fetch aggregated fields from the table: "omnichannel.vw_activities_group" */
  omnichannel_vw_activities_group_aggregate: Omnichannel_Vw_Activities_Group_Aggregate;
  /** fetch data from the table: "omnichannel.vw_randon_users" */
  omnichannel_vw_randon_users: Array<Omnichannel_Vw_Randon_Users>;
  /** fetch aggregated fields from the table: "omnichannel.vw_randon_users" */
  omnichannel_vw_randon_users_aggregate: Omnichannel_Vw_Randon_Users_Aggregate;
  /** fetch data from the table: "organizations" */
  organizations: Array<Organizations>;
  /** fetch aggregated fields from the table: "organizations" */
  organizations_aggregate: Organizations_Aggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** fetch data from the table: "permissions" */
  permissions: Array<Permissions>;
  /** fetch aggregated fields from the table: "permissions" */
  permissions_aggregate: Permissions_Aggregate;
  /** fetch data from the table: "permissions" using primary key columns */
  permissions_by_pk?: Maybe<Permissions>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "stripe_plan" */
  stripe_plan: Array<Stripe_Plan>;
  /** fetch aggregated fields from the table: "stripe_plan" */
  stripe_plan_aggregate: Stripe_Plan_Aggregate;
  /** fetch data from the table: "stripe_plan" using primary key columns */
  stripe_plan_by_pk?: Maybe<Stripe_Plan>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


/** subscription root */
export type Subscription_RootAccountsArgs = {
  accounts?: Maybe<Scalars['String']>;
};


/** subscription root */
export type Subscription_RootAcl_FeaturesArgs = {
  distinct_on?: Maybe<Array<Acl_Features_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Acl_Features_Order_By>>;
  where?: Maybe<Acl_Features_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAcl_Features_AggregateArgs = {
  distinct_on?: Maybe<Array<Acl_Features_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Acl_Features_Order_By>>;
  where?: Maybe<Acl_Features_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAcl_Features_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootAcl_Features_PermissionsArgs = {
  distinct_on?: Maybe<Array<Acl_Features_Permissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Acl_Features_Permissions_Order_By>>;
  where?: Maybe<Acl_Features_Permissions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAcl_Features_Permissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Acl_Features_Permissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Acl_Features_Permissions_Order_By>>;
  where?: Maybe<Acl_Features_Permissions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAcl_Features_Permissions_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootApikeysArgs = {
  distinct_on?: Maybe<Array<Apikeys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apikeys_Order_By>>;
  where?: Maybe<Apikeys_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootApikeys_AggregateArgs = {
  distinct_on?: Maybe<Array<Apikeys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apikeys_Order_By>>;
  where?: Maybe<Apikeys_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootApikeys_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootAuth_Account_ProvidersArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Account_Providers_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Account_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootAuth_Account_RolesArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Account_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Account_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootAuth_AccountsArgs = {
  distinct_on?: Maybe<Array<Auth_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Accounts_Order_By>>;
  where?: Maybe<Auth_Accounts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Accounts_Order_By>>;
  where?: Maybe<Auth_Accounts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootAuth_ProvidersArgs = {
  distinct_on?: Maybe<Array<Auth_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Providers_Order_By>>;
  where?: Maybe<Auth_Providers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Providers_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Providers_Order_By>>;
  where?: Maybe<Auth_Providers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Providers_By_PkArgs = {
  provider: Scalars['String'];
};


/** subscription root */
export type Subscription_RootAuth_Refresh_TokensArgs = {
  distinct_on?: Maybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Refresh_Tokens_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Refresh_Tokens_By_PkArgs = {
  refresh_token: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootAuth_RolesArgs = {
  distinct_on?: Maybe<Array<Auth_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Roles_Order_By>>;
  where?: Maybe<Auth_Roles_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Roles_Order_By>>;
  where?: Maybe<Auth_Roles_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Roles_By_PkArgs = {
  role: Scalars['String'];
};


/** subscription root */
export type Subscription_RootCidadesArgs = {
  distinct_on?: Maybe<Array<Cidades_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cidades_Order_By>>;
  where?: Maybe<Cidades_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCidades_AggregateArgs = {
  distinct_on?: Maybe<Array<Cidades_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cidades_Order_By>>;
  where?: Maybe<Cidades_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCidades_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootEstadosArgs = {
  distinct_on?: Maybe<Array<Estados_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Estados_Order_By>>;
  where?: Maybe<Estados_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEstados_AggregateArgs = {
  distinct_on?: Maybe<Array<Estados_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Estados_Order_By>>;
  where?: Maybe<Estados_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEstados_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootMembershipsArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMemberships_AggregateArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMemberships_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootOmnichannel_AddonsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Addons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Addons_Order_By>>;
  where?: Maybe<Omnichannel_Addons_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Addons_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Addons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Addons_Order_By>>;
  where?: Maybe<Omnichannel_Addons_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Addons_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_Addons_Connect_SettingsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Addons_Connect_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Addons_Connect_Settings_Order_By>>;
  where?: Maybe<Omnichannel_Addons_Connect_Settings_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Addons_Connect_Settings_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Addons_Connect_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Addons_Connect_Settings_Order_By>>;
  where?: Maybe<Omnichannel_Addons_Connect_Settings_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Addons_Connect_Settings_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_Addons_ConnectsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Addons_Connects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Addons_Connects_Order_By>>;
  where?: Maybe<Omnichannel_Addons_Connects_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Addons_Connects_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Addons_Connects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Addons_Connects_Order_By>>;
  where?: Maybe<Omnichannel_Addons_Connects_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Addons_Connects_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_Channel_EnabledArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channel_Enabled_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channel_Enabled_Order_By>>;
  where?: Maybe<Omnichannel_Channel_Enabled_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Channel_Enabled_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channel_Enabled_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channel_Enabled_Order_By>>;
  where?: Maybe<Omnichannel_Channel_Enabled_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Channel_Enabled_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_Channel_Enabled_SettingsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channel_Enabled_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channel_Enabled_Settings_Order_By>>;
  where?: Maybe<Omnichannel_Channel_Enabled_Settings_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Channel_Enabled_Settings_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channel_Enabled_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channel_Enabled_Settings_Order_By>>;
  where?: Maybe<Omnichannel_Channel_Enabled_Settings_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Channel_Enabled_Settings_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_Channel_MessageArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channel_Message_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channel_Message_Order_By>>;
  where?: Maybe<Omnichannel_Channel_Message_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Channel_Message_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channel_Message_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channel_Message_Order_By>>;
  where?: Maybe<Omnichannel_Channel_Message_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Channel_Message_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_Channel_SessionsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channel_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channel_Sessions_Order_By>>;
  where?: Maybe<Omnichannel_Channel_Sessions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Channel_Sessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channel_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channel_Sessions_Order_By>>;
  where?: Maybe<Omnichannel_Channel_Sessions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Channel_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_ChannelsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channels_Order_By>>;
  where?: Maybe<Omnichannel_Channels_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Channels_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Channels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Channels_Order_By>>;
  where?: Maybe<Omnichannel_Channels_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Channels_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_GroupsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Groups_Order_By>>;
  where?: Maybe<Omnichannel_Groups_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Groups_Order_By>>;
  where?: Maybe<Omnichannel_Groups_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Groups_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootOmnichannel_Groups_UsersArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Groups_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Groups_Users_Order_By>>;
  where?: Maybe<Omnichannel_Groups_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Groups_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Groups_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Groups_Users_Order_By>>;
  where?: Maybe<Omnichannel_Groups_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Groups_Users_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootOmnichannel_Mailbox_Mail_AddonsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Mailbox_Mail_Addons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Mailbox_Mail_Addons_Order_By>>;
  where?: Maybe<Omnichannel_Mailbox_Mail_Addons_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Mailbox_Mail_Addons_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Mailbox_Mail_Addons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Mailbox_Mail_Addons_Order_By>>;
  where?: Maybe<Omnichannel_Mailbox_Mail_Addons_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Mailbox_Mail_Addons_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_Message_Mail_AddonsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Message_Mail_Addons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Message_Mail_Addons_Order_By>>;
  where?: Maybe<Omnichannel_Message_Mail_Addons_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Message_Mail_Addons_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Message_Mail_Addons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Message_Mail_Addons_Order_By>>;
  where?: Maybe<Omnichannel_Message_Mail_Addons_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Message_Mail_Addons_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_Person_ActivitiesArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Activities_Order_By>>;
  where?: Maybe<Omnichannel_Person_Activities_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Person_Activities_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Activities_Order_By>>;
  where?: Maybe<Omnichannel_Person_Activities_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Person_Activities_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_Person_FileArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_File_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_File_Order_By>>;
  where?: Maybe<Omnichannel_Person_File_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Person_File_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_File_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_File_Order_By>>;
  where?: Maybe<Omnichannel_Person_File_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Person_File_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_Person_MailArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Mail_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Mail_Order_By>>;
  where?: Maybe<Omnichannel_Person_Mail_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Person_Mail_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Mail_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Mail_Order_By>>;
  where?: Maybe<Omnichannel_Person_Mail_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Person_Mail_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_Person_NoteArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Note_Order_By>>;
  where?: Maybe<Omnichannel_Person_Note_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Person_Note_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Note_Order_By>>;
  where?: Maybe<Omnichannel_Person_Note_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Person_Note_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_Person_TagsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Tags_Order_By>>;
  where?: Maybe<Omnichannel_Person_Tags_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Person_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Tags_Order_By>>;
  where?: Maybe<Omnichannel_Person_Tags_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Person_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_Person_TypeArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Type_Order_By>>;
  where?: Maybe<Omnichannel_Person_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Person_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Person_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Person_Type_Order_By>>;
  where?: Maybe<Omnichannel_Person_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Person_Type_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootOmnichannel_PersonsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Persons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Persons_Order_By>>;
  where?: Maybe<Omnichannel_Persons_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Persons_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Persons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Persons_Order_By>>;
  where?: Maybe<Omnichannel_Persons_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Persons_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_PipelineArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Pipeline_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Pipeline_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_Pipeline_CardArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Card_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Card_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Card_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Pipeline_Card_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Card_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Card_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Card_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Pipeline_Card_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_Pipeline_CommentsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Comments_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Comments_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Pipeline_Comments_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Comments_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Comments_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Pipeline_Comments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_Pipeline_ListArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_List_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_List_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_List_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Pipeline_List_ActionsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_List_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_List_Actions_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_List_Actions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Pipeline_List_Actions_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_List_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_List_Actions_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_List_Actions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Pipeline_List_Actions_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootOmnichannel_Pipeline_List_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_List_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_List_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_List_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Pipeline_List_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_Pipeline_ProductsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Products_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Products_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Pipeline_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Products_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Products_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Pipeline_Products_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootOmnichannel_Pipeline_StatusArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Status_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Status_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Pipeline_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Status_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Status_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Pipeline_Status_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_Pipeline_TagsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Tags_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Tags_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Pipeline_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Pipeline_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Pipeline_Tags_Order_By>>;
  where?: Maybe<Omnichannel_Pipeline_Tags_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Pipeline_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_ProductsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Products_Order_By>>;
  where?: Maybe<Omnichannel_Products_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Products_Order_By>>;
  where?: Maybe<Omnichannel_Products_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Products_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_TagsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Tags_Order_By>>;
  where?: Maybe<Omnichannel_Tags_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Tags_Order_By>>;
  where?: Maybe<Omnichannel_Tags_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOmnichannel_Vw_Activities_GroupArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Vw_Activities_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Vw_Activities_Group_Order_By>>;
  where?: Maybe<Omnichannel_Vw_Activities_Group_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Vw_Activities_Group_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Vw_Activities_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Vw_Activities_Group_Order_By>>;
  where?: Maybe<Omnichannel_Vw_Activities_Group_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Vw_Randon_UsersArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Vw_Randon_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Vw_Randon_Users_Order_By>>;
  where?: Maybe<Omnichannel_Vw_Randon_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOmnichannel_Vw_Randon_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Vw_Randon_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Vw_Randon_Users_Order_By>>;
  where?: Maybe<Omnichannel_Vw_Randon_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOrganizationsArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOrganizations_AggregateArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOrganizations_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootPermissionsArgs = {
  distinct_on?: Maybe<Array<Permissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Permissions_Order_By>>;
  where?: Maybe<Permissions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPermissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Permissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Permissions_Order_By>>;
  where?: Maybe<Permissions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPermissions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootProductArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProduct_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProduct_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootStripe_PlanArgs = {
  distinct_on?: Maybe<Array<Stripe_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stripe_Plan_Order_By>>;
  where?: Maybe<Stripe_Plan_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStripe_Plan_AggregateArgs = {
  distinct_on?: Maybe<Array<Stripe_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stripe_Plan_Order_By>>;
  where?: Maybe<Stripe_Plan_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStripe_Plan_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

export type UpdateCompanyInput = {
  data?: Maybe<EditCompanyInput>;
};

export type UpdateCompanyPayload = {
  __typename?: 'updateCompanyPayload';
  company?: Maybe<Company>;
};

export type UpdateLoginInput = {
  data?: Maybe<EditLoginInput>;
};

export type UpdateLoginPayload = {
  __typename?: 'updateLoginPayload';
  login?: Maybe<Login>;
};

export type UpdateMenuInput = {
  data?: Maybe<EditMenuInput>;
};

export type UpdateMenuPayload = {
  __typename?: 'updateMenuPayload';
  menu?: Maybe<Menu>;
};

export type UpdateSidebarInput = {
  data?: Maybe<EditSidebarInput>;
};

export type UpdateSidebarPayload = {
  __typename?: 'updateSidebarPayload';
  sidebar?: Maybe<Sidebar>;
};

export type UpdateUserInput = {
  data?: Maybe<EditUserInput>;
  where?: Maybe<InputId>;
};

export type UpdateUserPayload = {
  __typename?: 'updateUserPayload';
  user?: Maybe<UsersPermissionsUser>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  /** An object relationship */
  account?: Maybe<Auth_Accounts>;
  /** An array relationship */
  acl_features_permissions: Array<Acl_Features_Permissions>;
  /** An aggregated array relationship */
  acl_features_permissions_aggregate: Acl_Features_Permissions_Aggregate;
  avatar_url?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  display_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  feature_default?: Maybe<Acl_Features>;
  feature_default_id: Scalars['Int'];
  /** An array relationship */
  groups: Array<Omnichannel_Groups_Users>;
  /** An aggregated array relationship */
  groups_aggregate: Omnichannel_Groups_Users_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  members: Array<Memberships>;
  /** An aggregated array relationship */
  members_aggregate: Memberships_Aggregate;
  permission_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  subscription: Array<Permissions>;
  /** An aggregated array relationship */
  subscription_aggregate: Permissions_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "users" */
export type UsersAcl_Features_PermissionsArgs = {
  distinct_on?: Maybe<Array<Acl_Features_Permissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Acl_Features_Permissions_Order_By>>;
  where?: Maybe<Acl_Features_Permissions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersAcl_Features_Permissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Acl_Features_Permissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Acl_Features_Permissions_Order_By>>;
  where?: Maybe<Acl_Features_Permissions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersGroupsArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Groups_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Groups_Users_Order_By>>;
  where?: Maybe<Omnichannel_Groups_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersGroups_AggregateArgs = {
  distinct_on?: Maybe<Array<Omnichannel_Groups_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Omnichannel_Groups_Users_Order_By>>;
  where?: Maybe<Omnichannel_Groups_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersMembersArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersMembers_AggregateArgs = {
  distinct_on?: Maybe<Array<Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Memberships_Order_By>>;
  where?: Maybe<Memberships_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSubscriptionArgs = {
  distinct_on?: Maybe<Array<Permissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Permissions_Order_By>>;
  where?: Maybe<Permissions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSubscription_AggregateArgs = {
  distinct_on?: Maybe<Array<Permissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Permissions_Order_By>>;
  where?: Maybe<Permissions_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  avg?: Maybe<Users_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Users_Max_Order_By>;
  min?: Maybe<Users_Min_Order_By>;
  stddev?: Maybe<Users_Stddev_Order_By>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Order_By>;
  sum?: Maybe<Users_Sum_Order_By>;
  var_pop?: Maybe<Users_Var_Pop_Order_By>;
  var_samp?: Maybe<Users_Var_Samp_Order_By>;
  variance?: Maybe<Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'users_avg_fields';
  feature_default_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "users" */
export type Users_Avg_Order_By = {
  feature_default_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  account?: Maybe<Auth_Accounts_Bool_Exp>;
  acl_features_permissions?: Maybe<Acl_Features_Permissions_Bool_Exp>;
  avatar_url?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  display_name?: Maybe<String_Comparison_Exp>;
  feature_default?: Maybe<Acl_Features_Bool_Exp>;
  feature_default_id?: Maybe<Int_Comparison_Exp>;
  groups?: Maybe<Omnichannel_Groups_Users_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  members?: Maybe<Memberships_Bool_Exp>;
  permission_id?: Maybe<Uuid_Comparison_Exp>;
  subscription?: Maybe<Permissions_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** input type for incrementing integer column in table "users" */
export type Users_Inc_Input = {
  feature_default_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  account?: Maybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  acl_features_permissions?: Maybe<Acl_Features_Permissions_Arr_Rel_Insert_Input>;
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  feature_default?: Maybe<Acl_Features_Obj_Rel_Insert_Input>;
  feature_default_id?: Maybe<Scalars['Int']>;
  groups?: Maybe<Omnichannel_Groups_Users_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  members?: Maybe<Memberships_Arr_Rel_Insert_Input>;
  permission_id?: Maybe<Scalars['uuid']>;
  subscription?: Maybe<Permissions_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  feature_default_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  permission_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  avatar_url?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  display_name?: Maybe<Order_By>;
  feature_default_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  permission_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  feature_default_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  permission_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  avatar_url?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  display_name?: Maybe<Order_By>;
  feature_default_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  permission_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** ordering options when selecting data from "users" */
export type Users_Order_By = {
  account?: Maybe<Auth_Accounts_Order_By>;
  acl_features_permissions_aggregate?: Maybe<Acl_Features_Permissions_Aggregate_Order_By>;
  avatar_url?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  display_name?: Maybe<Order_By>;
  feature_default?: Maybe<Acl_Features_Order_By>;
  feature_default_id?: Maybe<Order_By>;
  groups_aggregate?: Maybe<Omnichannel_Groups_Users_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  members_aggregate?: Maybe<Memberships_Aggregate_Order_By>;
  permission_id?: Maybe<Order_By>;
  subscription_aggregate?: Maybe<Permissions_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "users" */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  FeatureDefaultId = 'feature_default_id',
  /** column name */
  Id = 'id',
  /** column name */
  PermissionId = 'permission_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  feature_default_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  permission_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'users_stddev_fields';
  feature_default_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "users" */
export type Users_Stddev_Order_By = {
  feature_default_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'users_stddev_pop_fields';
  feature_default_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "users" */
export type Users_Stddev_Pop_Order_By = {
  feature_default_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'users_stddev_samp_fields';
  feature_default_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "users" */
export type Users_Stddev_Samp_Order_By = {
  feature_default_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'users_sum_fields';
  feature_default_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "users" */
export type Users_Sum_Order_By = {
  feature_default_id?: Maybe<Order_By>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  FeatureDefaultId = 'feature_default_id',
  /** column name */
  Id = 'id',
  /** column name */
  PermissionId = 'permission_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'users_var_pop_fields';
  feature_default_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "users" */
export type Users_Var_Pop_Order_By = {
  feature_default_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'users_var_samp_fields';
  feature_default_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "users" */
export type Users_Var_Samp_Order_By = {
  feature_default_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'users_variance_fields';
  feature_default_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "users" */
export type Users_Variance_Order_By = {
  feature_default_id?: Maybe<Order_By>;
};


/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type GetPipelineOneQueryVariables = Exact<{
  pipeline_id: Scalars['uuid'];
}>;


export type GetPipelineOneQuery = (
  { __typename?: 'query_root' }
  & { omnichannel_pipeline_by_pk?: Maybe<(
    { __typename?: 'omnichannel_pipeline' }
    & Pick<Omnichannel_Pipeline, 'id' | 'description' | 'created_at' | 'title' | 'updated_at'>
    & { lists: Array<(
      { __typename?: 'omnichannel_pipeline_list' }
      & Pick<Omnichannel_Pipeline_List, 'id' | 'order' | 'title' | 'start'>
      & { actions: Array<(
        { __typename?: 'omnichannel_pipeline_list_actions' }
        & { action?: Maybe<(
          { __typename?: 'omnichannel_pipeline_list' }
          & Pick<Omnichannel_Pipeline_List, 'id' | 'title'>
        )> }
      )>, cards_aggregate: (
        { __typename?: 'omnichannel_pipeline_card_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'omnichannel_pipeline_card_aggregate_fields' }
          & Pick<Omnichannel_Pipeline_Card_Aggregate_Fields, 'count'>
        )> }
      ) }
    )> }
  )> }
);

export type GetPipelineQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPipelineQuery = (
  { __typename?: 'query_root' }
  & { omnichannel_pipeline: Array<(
    { __typename?: 'omnichannel_pipeline' }
    & Pick<Omnichannel_Pipeline, 'id' | 'description' | 'title'>
  )> }
);

export type InsertCardPipelineMutationVariables = Exact<{
  name_person: Scalars['String'];
  avatar: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  ptype: Scalars['Int'];
  owner_id: Scalars['uuid'];
  order: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  list_id: Scalars['uuid'];
  status_id: Scalars['uuid'];
  organization_id: Scalars['Int'];
  date_close?: Maybe<Scalars['timestamptz']>;
}>;


export type InsertCardPipelineMutation = (
  { __typename?: 'mutation_root' }
  & { insert_omnichannel_pipeline_card_one?: Maybe<(
    { __typename?: 'omnichannel_pipeline_card' }
    & Pick<Omnichannel_Pipeline_Card, 'id'>
  )> }
);

export type InsertCardPipelineIdPersonMutationVariables = Exact<{
  person_id: Scalars['uuid'];
  owner_id: Scalars['uuid'];
  order: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  list_id: Scalars['uuid'];
  status_id: Scalars['uuid'];
  organization_id: Scalars['Int'];
  date_close?: Maybe<Scalars['timestamptz']>;
}>;


export type InsertCardPipelineIdPersonMutation = (
  { __typename?: 'mutation_root' }
  & { insert_omnichannel_pipeline_card_one?: Maybe<(
    { __typename?: 'omnichannel_pipeline_card' }
    & Pick<Omnichannel_Pipeline_Card, 'id'>
  )> }
);

export type GetPipelineStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPipelineStatusQuery = (
  { __typename?: 'query_root' }
  & { omnichannel_pipeline_status: Array<(
    { __typename?: 'omnichannel_pipeline_status' }
    & Pick<Omnichannel_Pipeline_Status, 'id' | 'name' | 'color'>
  )> }
);

export type GetProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProductsQuery = (
  { __typename?: 'query_root' }
  & { omnichannel_products: Array<(
    { __typename?: 'omnichannel_products' }
    & Pick<Omnichannel_Products, 'id' | 'name' | 'description'>
  )> }
);

export type GetPersonFilterQueryVariables = Exact<{
  filter: Scalars['String'];
}>;


export type GetPersonFilterQuery = (
  { __typename?: 'query_root' }
  & { omnichannel_persons: Array<(
    { __typename?: 'omnichannel_persons' }
    & Pick<Omnichannel_Persons, 'id' | 'name' | 'email' | 'avatar' | 'phone' | 'person_type'>
  )> }
);

export type GetPersonTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPersonTypesQuery = (
  { __typename?: 'query_root' }
  & { omnichannel_person_type: Array<(
    { __typename?: 'omnichannel_person_type' }
    & Pick<Omnichannel_Person_Type, 'id' | 'name'>
  )> }
);

export type InsertProductPipelineMutationVariables = Exact<{
  itens: Array<Omnichannel_Pipeline_Products_Insert_Input> | Omnichannel_Pipeline_Products_Insert_Input;
}>;


export type InsertProductPipelineMutation = (
  { __typename?: 'mutation_root' }
  & { insert_omnichannel_pipeline_products?: Maybe<(
    { __typename?: 'omnichannel_pipeline_products_mutation_response' }
    & { returning: Array<(
      { __typename?: 'omnichannel_pipeline_products' }
      & Pick<Omnichannel_Pipeline_Products, 'id'>
    )> }
  )> }
);

export type GetCardsforListsQueryVariables = Exact<{
  list_id: Scalars['uuid'];
}>;


export type GetCardsforListsQuery = (
  { __typename?: 'query_root' }
  & { omnichannel_pipeline_card: Array<(
    { __typename?: 'omnichannel_pipeline_card' }
    & Pick<Omnichannel_Pipeline_Card, 'date_close' | 'description' | 'id' | 'person_id' | 'title' | 'updated_at' | 'owner_id' | 'created_at' | 'order'>
    & { person?: Maybe<(
      { __typename?: 'omnichannel_persons' }
      & Pick<Omnichannel_Persons, 'avatar' | 'id' | 'name' | 'phone' | 'person_type' | 'email' | 'created_at'>
      & { ptypes?: Maybe<(
        { __typename?: 'omnichannel_person_type' }
        & Pick<Omnichannel_Person_Type, 'id' | 'name'>
      )> }
    )>, tags: Array<(
      { __typename?: 'omnichannel_pipeline_tags' }
      & { tag?: Maybe<(
        { __typename?: 'omnichannel_tags' }
        & Pick<Omnichannel_Tags, 'description' | 'color' | 'id'>
      )> }
    )>, products: Array<(
      { __typename?: 'omnichannel_pipeline_products' }
      & Pick<Omnichannel_Pipeline_Products, 'id'>
      & { product?: Maybe<(
        { __typename?: 'omnichannel_products' }
        & Pick<Omnichannel_Products, 'id' | 'name'>
      )> }
    )>, status?: Maybe<(
      { __typename?: 'omnichannel_pipeline_status' }
      & Pick<Omnichannel_Pipeline_Status, 'id' | 'name' | 'color'>
    )>, owner?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'avatar_url' | 'display_name' | 'id'>
    )>, comments_aggregate: (
      { __typename?: 'omnichannel_pipeline_comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'omnichannel_pipeline_comments_aggregate_fields' }
        & Pick<Omnichannel_Pipeline_Comments_Aggregate_Fields, 'count'>
      )> }
    ), comments: Array<(
      { __typename?: 'omnichannel_pipeline_comments' }
      & Pick<Omnichannel_Pipeline_Comments, 'comment' | 'created_at'>
      & { user?: Maybe<(
        { __typename?: 'users' }
        & Pick<Users, 'display_name' | 'avatar_url' | 'id'>
      )> }
    )> }
  )> }
);

export type GetSubscriptionCardsSubscriptionVariables = Exact<{
  list_id: Scalars['uuid'];
  limit: Scalars['Int'];
  person_name?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['uuid']>;
}>;


export type GetSubscriptionCardsSubscription = (
  { __typename?: 'subscription_root' }
  & { omnichannel_pipeline_card: Array<(
    { __typename?: 'omnichannel_pipeline_card' }
    & Pick<Omnichannel_Pipeline_Card, 'date_close' | 'description' | 'id' | 'person_id' | 'pipeline_list_id' | 'title' | 'updated_at' | 'owner_id' | 'created_at' | 'order'>
    & { person?: Maybe<(
      { __typename?: 'omnichannel_persons' }
      & Pick<Omnichannel_Persons, 'avatar' | 'id' | 'name' | 'phone' | 'person_type' | 'email' | 'created_at'>
      & { ptypes?: Maybe<(
        { __typename?: 'omnichannel_person_type' }
        & Pick<Omnichannel_Person_Type, 'id' | 'name'>
      )> }
    )>, tags: Array<(
      { __typename?: 'omnichannel_pipeline_tags' }
      & { tag?: Maybe<(
        { __typename?: 'omnichannel_tags' }
        & Pick<Omnichannel_Tags, 'description' | 'color' | 'id'>
      )> }
    )>, products: Array<(
      { __typename?: 'omnichannel_pipeline_products' }
      & Pick<Omnichannel_Pipeline_Products, 'id'>
      & { product?: Maybe<(
        { __typename?: 'omnichannel_products' }
        & Pick<Omnichannel_Products, 'id' | 'name'>
      )> }
    )>, status?: Maybe<(
      { __typename?: 'omnichannel_pipeline_status' }
      & Pick<Omnichannel_Pipeline_Status, 'id' | 'name' | 'color'>
    )>, owner?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'avatar_url' | 'display_name' | 'id'>
    )>, comments_aggregate: (
      { __typename?: 'omnichannel_pipeline_comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'omnichannel_pipeline_comments_aggregate_fields' }
        & Pick<Omnichannel_Pipeline_Comments_Aggregate_Fields, 'count'>
      )> }
    ), comments: Array<(
      { __typename?: 'omnichannel_pipeline_comments' }
      & Pick<Omnichannel_Pipeline_Comments, 'comment' | 'created_at'>
      & { user?: Maybe<(
        { __typename?: 'users' }
        & Pick<Users, 'display_name' | 'avatar_url' | 'id'>
      )> }
    )> }
  )> }
);

export type ChangeOrderCardsMutationVariables = Exact<{
  list_id: Scalars['uuid'];
  card_id: Scalars['uuid'];
  order: Scalars['Int'];
}>;


export type ChangeOrderCardsMutation = (
  { __typename?: 'mutation_root' }
  & { reorder?: Maybe<(
    { __typename?: 'Reorder' }
    & Pick<Reorder, 'message'>
  )> }
);

export type ChangeListOrdersCardsMutationVariables = Exact<{
  list_id: Scalars['uuid'];
  card_id: Scalars['uuid'];
  order: Scalars['Int'];
}>;


export type ChangeListOrdersCardsMutation = (
  { __typename?: 'mutation_root' }
  & { update_omnichannel_pipeline_card_by_pk?: Maybe<(
    { __typename?: 'omnichannel_pipeline_card' }
    & Pick<Omnichannel_Pipeline_Card, 'id'>
  )>, reorder?: Maybe<(
    { __typename?: 'Reorder' }
    & Pick<Reorder, 'message'>
  )> }
);

export type UpdatePersonMutationVariables = Exact<{
  id: Scalars['uuid'];
  email: Scalars['String'];
  avatar: Scalars['String'];
  phone: Scalars['String'];
  name: Scalars['String'];
  ptype: Scalars['Int'];
}>;


export type UpdatePersonMutation = (
  { __typename?: 'mutation_root' }
  & { update_omnichannel_persons_by_pk?: Maybe<(
    { __typename?: 'omnichannel_persons' }
    & Pick<Omnichannel_Persons, 'id'>
  )> }
);

export type UpdateCardStatusMutationVariables = Exact<{
  id: Scalars['uuid'];
  status_id: Scalars['uuid'];
  date_close?: Maybe<Scalars['timestamptz']>;
  owner_id: Scalars['uuid'];
}>;


export type UpdateCardStatusMutation = (
  { __typename?: 'mutation_root' }
  & { update_omnichannel_pipeline_card_by_pk?: Maybe<(
    { __typename?: 'omnichannel_pipeline_card' }
    & Pick<Omnichannel_Pipeline_Card, 'id'>
  )> }
);

export type UpdateCardDescriptionMutationVariables = Exact<{
  id: Scalars['uuid'];
  description: Scalars['String'];
}>;


export type UpdateCardDescriptionMutation = (
  { __typename?: 'mutation_root' }
  & { update_omnichannel_pipeline_card_by_pk?: Maybe<(
    { __typename?: 'omnichannel_pipeline_card' }
    & Pick<Omnichannel_Pipeline_Card, 'id'>
  )> }
);

export type InsertCommentCardMutationVariables = Exact<{
  comment: Scalars['String'];
  card_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
}>;


export type InsertCommentCardMutation = (
  { __typename?: 'mutation_root' }
  & { insert_omnichannel_pipeline_comments_one?: Maybe<(
    { __typename?: 'omnichannel_pipeline_comments' }
    & Pick<Omnichannel_Pipeline_Comments, 'id'>
  )> }
);

export type DeleteCommentMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteCommentMutation = (
  { __typename?: 'mutation_root' }
  & { delete_omnichannel_pipeline_comments_by_pk?: Maybe<(
    { __typename?: 'omnichannel_pipeline_comments' }
    & Pick<Omnichannel_Pipeline_Comments, 'id'>
  )> }
);

export type DeleteCardMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteCardMutation = (
  { __typename?: 'mutation_root' }
  & { delete_omnichannel_pipeline_card_by_pk?: Maybe<(
    { __typename?: 'omnichannel_pipeline_card' }
    & Pick<Omnichannel_Pipeline_Card, 'id'>
  )> }
);

export type GetCommentsCardSubscriptionVariables = Exact<{
  card_id: Scalars['uuid'];
}>;


export type GetCommentsCardSubscription = (
  { __typename?: 'subscription_root' }
  & { omnichannel_pipeline_comments: Array<(
    { __typename?: 'omnichannel_pipeline_comments' }
    & Pick<Omnichannel_Pipeline_Comments, 'id' | 'comment' | 'created_at' | 'user_id'>
    & { user?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'avatar_url' | 'display_name' | 'id'>
    )> }
  )> }
);

export type GetUsersLeadsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersLeadsQuery = (
  { __typename?: 'query_root' }
  & { omnichannel_groups_users: Array<(
    { __typename?: 'omnichannel_groups_users' }
    & Pick<Omnichannel_Groups_Users, 'id'>
    & { user?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'display_name'>
    )> }
  )> }
);

export type DisableCardMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DisableCardMutation = (
  { __typename?: 'mutation_root' }
  & { update_omnichannel_pipeline_card_by_pk?: Maybe<(
    { __typename?: 'omnichannel_pipeline_card' }
    & Pick<Omnichannel_Pipeline_Card, 'id'>
  )> }
);

export type GetOnwerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOnwerQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'display_name'>
  )> }
);

export type GetGroupUsersQueryVariables = Exact<{
  user_id: Scalars['uuid'];
}>;


export type GetGroupUsersQuery = (
  { __typename?: 'query_root' }
  & { omnichannel_groups_users: Array<(
    { __typename?: 'omnichannel_groups_users' }
    & Pick<Omnichannel_Groups_Users, 'browertab' | 'celular' | 'currency' | 'disable_not_from' | 'disable_not_to' | 'email_notification' | 'enable_lead' | 'group_id' | 'id' | 'language' | 'timezone' | 'user_id' | 'enable_celular' | 'enable_email' | 'disable_notification'>
    & { user?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'avatar_url' | 'display_name' | 'updated_at' | 'created_at'>
    )>, group?: Maybe<(
      { __typename?: 'omnichannel_groups' }
      & Pick<Omnichannel_Groups, 'id' | 'code' | 'name'>
    )> }
  )> }
);

export type UpdatePreferencesMutationVariables = Exact<{
  user_id: Scalars['uuid'];
  timezone: Scalars['String'];
  currency: Scalars['String'];
  language: Scalars['String'];
}>;


export type UpdatePreferencesMutation = (
  { __typename?: 'mutation_root' }
  & { update_omnichannel_groups_users?: Maybe<(
    { __typename?: 'omnichannel_groups_users_mutation_response' }
    & { returning: Array<(
      { __typename?: 'omnichannel_groups_users' }
      & Pick<Omnichannel_Groups_Users, 'id'>
    )> }
  )> }
);

export type UpdateNotificationMutationVariables = Exact<{
  user_id: Scalars['uuid'];
  browertab: Scalars['Boolean'];
  enable_celular: Scalars['Boolean'];
  enable_email: Scalars['Boolean'];
  disable_notification: Scalars['Boolean'];
  enable_lead: Scalars['Boolean'];
  email_notification?: Maybe<Scalars['String']>;
  celular?: Maybe<Scalars['String']>;
  disable_not_to?: Maybe<Scalars['Int']>;
  disable_not_from?: Maybe<Scalars['Int']>;
}>;


export type UpdateNotificationMutation = (
  { __typename?: 'mutation_root' }
  & { update_omnichannel_groups_users?: Maybe<(
    { __typename?: 'omnichannel_groups_users_mutation_response' }
    & { returning: Array<(
      { __typename?: 'omnichannel_groups_users' }
      & Pick<Omnichannel_Groups_Users, 'id'>
    )> }
  )> }
);

export type GetUsersQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
  text_search?: Maybe<Scalars['String']>;
}>;


export type GetUsersQuery = (
  { __typename?: 'query_root' }
  & { omnichannel_groups_users: Array<(
    { __typename?: 'omnichannel_groups_users' }
    & Pick<Omnichannel_Groups_Users, 'browertab' | 'celular' | 'created_at' | 'currency' | 'disable_not_from' | 'disable_not_to' | 'disable_notification' | 'email_notification' | 'enable_celular' | 'enable_email' | 'enable_lead' | 'user_id'>
    & { group?: Maybe<(
      { __typename?: 'omnichannel_groups' }
      & Pick<Omnichannel_Groups, 'active' | 'id' | 'name'>
    )>, user?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'display_name' | 'avatar_url' | 'id'>
      & { account?: Maybe<(
        { __typename?: 'auth_accounts' }
        & Pick<Auth_Accounts, 'email'>
      )> }
    )> }
  )>, omnichannel_groups_users_aggregate: (
    { __typename?: 'omnichannel_groups_users_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'omnichannel_groups_users_aggregate_fields' }
      & Pick<Omnichannel_Groups_Users_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type EnabledLeadMutationVariables = Exact<{
  itens: Array<Omnichannel_Groups_Users_Insert_Input> | Omnichannel_Groups_Users_Insert_Input;
}>;


export type EnabledLeadMutation = (
  { __typename?: 'mutation_root' }
  & { insert_omnichannel_groups_users?: Maybe<(
    { __typename?: 'omnichannel_groups_users_mutation_response' }
    & { returning: Array<(
      { __typename?: 'omnichannel_groups_users' }
      & Pick<Omnichannel_Groups_Users, 'id'>
    )> }
  )> }
);

export type ActiveAccountMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ActiveAccountMutation = (
  { __typename?: 'mutation_root' }
  & { update_auth_accounts?: Maybe<(
    { __typename?: 'auth_accounts_mutation_response' }
    & { returning: Array<(
      { __typename?: 'auth_accounts' }
      & Pick<Auth_Accounts, 'id'>
    )> }
  )> }
);

export type UpdateSubcriptionUserMutationVariables = Exact<{
  id: Scalars['uuid'];
  avatar_url?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
}>;


export type UpdateSubcriptionUserMutation = (
  { __typename?: 'mutation_root' }
  & { update_users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
  )> }
);

export type InsertMemberMutationVariables = Exact<{
  user_id: Scalars['uuid'];
}>;


export type InsertMemberMutation = (
  { __typename?: 'mutation_root' }
  & { insert_memberships_one?: Maybe<(
    { __typename?: 'memberships' }
    & Pick<Memberships, 'id'>
  )> }
);

export type InsertRolesUserMutationVariables = Exact<{
  user_id: Scalars['uuid'];
}>;


export type InsertRolesUserMutation = (
  { __typename?: 'mutation_root' }
  & { insert_acl_features_permissions?: Maybe<(
    { __typename?: 'acl_features_permissions_mutation_response' }
    & { returning: Array<(
      { __typename?: 'acl_features_permissions' }
      & Pick<Acl_Features_Permissions, 'id'>
    )> }
  )> }
);

export type InsertGroupMutationVariables = Exact<{
  user_id: Scalars['uuid'];
}>;


export type InsertGroupMutation = (
  { __typename?: 'mutation_root' }
  & { insert_omnichannel_groups_users_one?: Maybe<(
    { __typename?: 'omnichannel_groups_users' }
    & Pick<Omnichannel_Groups_Users, 'id'>
  )> }
);

export type Omnichannel_TagsQueryVariables = Exact<{ [key: string]: never; }>;


export type Omnichannel_TagsQuery = (
  { __typename?: 'query_root' }
  & { omnichannel_tags: Array<(
    { __typename?: 'omnichannel_tags' }
    & Pick<Omnichannel_Tags, 'description' | 'id' | 'color'>
  )> }
);

export type Query_Person_TypesQueryVariables = Exact<{
  teamid: Scalars['Int'];
}>;


export type Query_Person_TypesQuery = (
  { __typename?: 'query_root' }
  & { omnichannel_person_type: Array<(
    { __typename?: 'omnichannel_person_type' }
    & { value: Omnichannel_Person_Type['id'], viewValue: Omnichannel_Person_Type['name'] }
  )> }
);

export type Persons_AggregateQueryVariables = Exact<{
  teamid: Scalars['Int'];
  limit: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
  person_type?: Maybe<Scalars['Int']>;
  text_search?: Maybe<Scalars['String']>;
}>;


export type Persons_AggregateQuery = (
  { __typename?: 'query_root' }
  & { omnichannel_persons_aggregate: (
    { __typename?: 'omnichannel_persons_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'omnichannel_persons_aggregate_fields' }
      & Pick<Omnichannel_Persons_Aggregate_Fields, 'count'>
    )> }
  ), omnichannel_persons: Array<(
    { __typename?: 'omnichannel_persons' }
    & Pick<Omnichannel_Persons, 'id' | 'name' | 'avatar' | 'city' | 'companyName' | 'cpf_cnpj' | 'email' | 'instagram' | 'phone' | 'whatsapp'>
    & { ptypes?: Maybe<(
      { __typename?: 'omnichannel_person_type' }
      & Pick<Omnichannel_Person_Type, 'id' | 'name'>
    )> }
  )> }
);

export type Person_TypesQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Person_TypesQuery = (
  { __typename?: 'query_root' }
  & { omnichannel_persons_by_pk?: Maybe<(
    { __typename?: 'omnichannel_persons' }
    & Pick<Omnichannel_Persons, 'avatar' | 'address' | 'city' | 'name' | 'state' | 'id' | 'email' | 'zipcode' | 'owner_id' | 'person_type' | 'cpf_cnpj' | 'phone' | 'whatsapp' | 'companyName'>
    & { tags: Array<(
      { __typename?: 'omnichannel_person_tags' }
      & { tag: (
        { __typename?: 'omnichannel_tags' }
        & Pick<Omnichannel_Tags, 'color' | 'id' | 'description'>
      ) }
    )> }
  )> }
);

export type Insert_PersonMutationVariables = Exact<{
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  cpf_cnpj?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  organization_id: Scalars['Int'];
  owner_id: Scalars['uuid'];
  person_type: Scalars['Int'];
  phone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
}>;


export type Insert_PersonMutation = (
  { __typename?: 'mutation_root' }
  & { insert_omnichannel_persons_one?: Maybe<(
    { __typename?: 'omnichannel_persons' }
    & Pick<Omnichannel_Persons, 'id'>
  )> }
);

export type Update_PersonMutationVariables = Exact<{
  id: Scalars['uuid'];
  address?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  cpf_cnpj?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  person_type?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
}>;


export type Update_PersonMutation = (
  { __typename?: 'mutation_root' }
  & { update_omnichannel_persons_by_pk?: Maybe<(
    { __typename?: 'omnichannel_persons' }
    & Pick<Omnichannel_Persons, 'id'>
  )> }
);

export type Insert_Person_NoteMutationVariables = Exact<{
  person_id: Scalars['uuid'];
  description: Scalars['String'];
}>;


export type Insert_Person_NoteMutation = (
  { __typename?: 'mutation_root' }
  & { insert_omnichannel_person_note_one?: Maybe<(
    { __typename?: 'omnichannel_person_note' }
    & Pick<Omnichannel_Person_Note, 'id'>
  )> }
);

export type Insert_Person_TagsMutationVariables = Exact<{
  person_id: Scalars['uuid'];
  tag_id: Scalars['uuid'];
}>;


export type Insert_Person_TagsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_omnichannel_person_tags_one?: Maybe<(
    { __typename?: 'omnichannel_person_tags' }
    & Pick<Omnichannel_Person_Tags, 'id'>
  )> }
);

export type Delete_Person_TagsMutationVariables = Exact<{
  person_id: Scalars['uuid'];
  tag_id: Scalars['uuid'];
}>;


export type Delete_Person_TagsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_omnichannel_person_tags?: Maybe<(
    { __typename?: 'omnichannel_person_tags_mutation_response' }
    & { returning: Array<(
      { __typename?: 'omnichannel_person_tags' }
      & Pick<Omnichannel_Person_Tags, 'id'>
    )> }
  )> }
);

export type SendEmailMutationVariables = Exact<{
  account_id: Scalars['String'];
  address: Scalars['String'];
  name: Scalars['String'];
  html: Scalars['String'];
  subject: Scalars['String'];
  address1: Scalars['String'];
  name1: Scalars['String'];
}>;


export type SendEmailMutation = (
  { __typename?: 'mutation_root' }
  & { sendEmail?: Maybe<(
    { __typename?: 'SampleOutput' }
    & Pick<SampleOutput, 'error' | 'message' | 'messageId' | 'response' | 'statusCode'>
  )> }
);

export type GetActivitiesPersonSubscriptionVariables = Exact<{
  person_id?: Maybe<Scalars['uuid']>;
}>;


export type GetActivitiesPersonSubscription = (
  { __typename?: 'subscription_root' }
  & { omnichannel_vw_activities_group: Array<(
    { __typename?: 'omnichannel_vw_activities_group' }
    & Pick<Omnichannel_Vw_Activities_Group, 'id' | 'mon' | 'yyyy'>
    & { acitvity?: Maybe<(
      { __typename?: 'omnichannel_person_activities' }
      & Pick<Omnichannel_Person_Activities, 'id' | 'feature' | 'description' | 'created_at' | 'person_mail_id' | 'person_note_id' | 'tag_id' | 'person_id' | 'person_file_id'>
      & { owner?: Maybe<(
        { __typename?: 'users' }
        & Pick<Users, 'display_name' | 'avatar_url'>
      )>, note?: Maybe<(
        { __typename?: 'omnichannel_person_note' }
        & Pick<Omnichannel_Person_Note, 'description'>
      )> }
    )> }
  )> }
);

export type Omnichannel_PipelineQueryVariables = Exact<{ [key: string]: never; }>;


export type Omnichannel_PipelineQuery = (
  { __typename?: 'query_root' }
  & { omnichannel_pipeline: Array<(
    { __typename?: 'omnichannel_pipeline' }
    & Pick<Omnichannel_Pipeline, 'id' | 'description' | 'created_at' | 'title' | 'updated_at'>
    & { lists: Array<(
      { __typename?: 'omnichannel_pipeline_list' }
      & Pick<Omnichannel_Pipeline_List, 'id' | 'order' | 'title'>
      & { cards: Array<(
        { __typename?: 'omnichannel_pipeline_card' }
        & Pick<Omnichannel_Pipeline_Card, 'id' | 'description' | 'created_at' | 'order'>
        & { owner?: Maybe<(
          { __typename?: 'users' }
          & Pick<Users, 'display_name' | 'avatar_url'>
        )>, person?: Maybe<(
          { __typename?: 'omnichannel_persons' }
          & Pick<Omnichannel_Persons, 'avatar' | 'id' | 'name'>
        )>, comments_aggregate: (
          { __typename?: 'omnichannel_pipeline_comments_aggregate' }
          & { aggregate?: Maybe<(
            { __typename?: 'omnichannel_pipeline_comments_aggregate_fields' }
            & Pick<Omnichannel_Pipeline_Comments_Aggregate_Fields, 'count'>
          )> }
        ), status?: Maybe<(
          { __typename?: 'omnichannel_pipeline_status' }
          & Pick<Omnichannel_Pipeline_Status, 'id' | 'name' | 'color'>
        )>, tags: Array<(
          { __typename?: 'omnichannel_pipeline_tags' }
          & { tag?: Maybe<(
            { __typename?: 'omnichannel_tags' }
            & Pick<Omnichannel_Tags, 'id' | 'color' | 'description'>
          )> }
        )> }
      )>, cards_aggregate: (
        { __typename?: 'omnichannel_pipeline_card_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'omnichannel_pipeline_card_aggregate_fields' }
          & Pick<Omnichannel_Pipeline_Card_Aggregate_Fields, 'count'>
        )> }
      ) }
    )> }
  )> }
);

export const GetPipelineOneDocument = gql`
    query GetPipelineOne($pipeline_id: uuid!) {
  omnichannel_pipeline_by_pk(id: $pipeline_id) {
    id
    description
    created_at
    title
    updated_at
    lists {
      id
      order
      title
      start
      actions {
        action {
          id
          title
        }
      }
      cards_aggregate(where: {delete: {_eq: false}}) {
        aggregate {
          count
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPipelineOneGQL extends Apollo.Query<GetPipelineOneQuery, GetPipelineOneQueryVariables> {
    document = GetPipelineOneDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPipelineDocument = gql`
    query GetPipeline {
  omnichannel_pipeline {
    id
    description
    title
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPipelineGQL extends Apollo.Query<GetPipelineQuery, GetPipelineQueryVariables> {
    document = GetPipelineDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertCardPipelineDocument = gql`
    mutation InsertCardPipeline($name_person: String!, $avatar: String!, $email: String, $phone: String, $ptype: Int!, $owner_id: uuid!, $order: Int!, $description: String, $title: String!, $list_id: uuid!, $status_id: uuid!, $organization_id: Int!, $date_close: timestamptz) {
  insert_omnichannel_pipeline_card_one(
    object: {person: {data: {name: $name_person, avatar: $avatar, owner_id: $owner_id, organization_id: $organization_id, email: $email, phone: $phone, person_type: $ptype}}, owner_id: $owner_id, order: $order, description: $description, title: $title, pipeline_list_id: $list_id, pipeline_status_id: $status_id, date_close: $date_close}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertCardPipelineGQL extends Apollo.Mutation<InsertCardPipelineMutation, InsertCardPipelineMutationVariables> {
    document = InsertCardPipelineDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertCardPipelineIdPersonDocument = gql`
    mutation InsertCardPipelineIDPerson($person_id: uuid!, $owner_id: uuid!, $order: Int!, $description: String, $title: String!, $list_id: uuid!, $status_id: uuid!, $organization_id: Int!, $date_close: timestamptz) {
  insert_omnichannel_pipeline_card_one(
    object: {person_id: $person_id, owner_id: $owner_id, order: $order, description: $description, title: $title, pipeline_list_id: $list_id, pipeline_status_id: $status_id, date_close: $date_close}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertCardPipelineIdPersonGQL extends Apollo.Mutation<InsertCardPipelineIdPersonMutation, InsertCardPipelineIdPersonMutationVariables> {
    document = InsertCardPipelineIdPersonDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPipelineStatusDocument = gql`
    query GetPipelineStatus {
  omnichannel_pipeline_status {
    id
    name
    color
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPipelineStatusGQL extends Apollo.Query<GetPipelineStatusQuery, GetPipelineStatusQueryVariables> {
    document = GetPipelineStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProductsDocument = gql`
    query GetProducts {
  omnichannel_products {
    id
    name
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductsGQL extends Apollo.Query<GetProductsQuery, GetProductsQueryVariables> {
    document = GetProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPersonFilterDocument = gql`
    query GetPersonFilter($filter: String!) {
  omnichannel_persons(where: {name: {_like: $filter}}) {
    id
    name
    email
    avatar
    phone
    person_type
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPersonFilterGQL extends Apollo.Query<GetPersonFilterQuery, GetPersonFilterQueryVariables> {
    document = GetPersonFilterDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPersonTypesDocument = gql`
    query GetPersonTypes {
  omnichannel_person_type {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPersonTypesGQL extends Apollo.Query<GetPersonTypesQuery, GetPersonTypesQueryVariables> {
    document = GetPersonTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertProductPipelineDocument = gql`
    mutation InsertProductPipeline($itens: [omnichannel_pipeline_products_insert_input!]!) {
  insert_omnichannel_pipeline_products(
    objects: $itens
    on_conflict: {constraint: pipeline_products_pipeline_card_id_product_id_key, update_columns: created_at}
  ) {
    returning {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertProductPipelineGQL extends Apollo.Mutation<InsertProductPipelineMutation, InsertProductPipelineMutationVariables> {
    document = InsertProductPipelineDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCardsforListsDocument = gql`
    query GetCardsforLists($list_id: uuid!) {
  omnichannel_pipeline_card(
    where: {pipeline_list_id: {_eq: $list_id}, delete: {_eq: false}}
    order_by: {order: asc}
  ) {
    date_close
    description
    id
    person_id
    person {
      avatar
      id
      name
      phone
      person_type
      ptypes {
        id
        name
      }
      email
      created_at
    }
    title
    updated_at
    tags {
      tag {
        description
        color
        id
      }
    }
    products {
      product {
        id
        name
      }
      id
    }
    status {
      id
      name
      color
    }
    owner_id
    owner {
      avatar_url
      display_name
      id
    }
    created_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    comments {
      comment
      created_at
      user {
        display_name
        avatar_url
        id
      }
    }
    order
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCardsforListsGQL extends Apollo.Query<GetCardsforListsQuery, GetCardsforListsQueryVariables> {
    document = GetCardsforListsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSubscriptionCardsDocument = gql`
    subscription GetSubscriptionCards($list_id: uuid!, $limit: Int!, $person_name: String, $offset: Int, $owner_id: uuid) {
  omnichannel_pipeline_card(
    where: {pipeline_list_id: {_eq: $list_id}, person: {name: {_like: $person_name}}, delete: {_eq: false}, owner_id: {_eq: $owner_id}}
    order_by: {order: asc}
    offset: $offset
    limit: $limit
  ) {
    date_close
    description
    id
    person_id
    pipeline_list_id
    person {
      avatar
      id
      name
      phone
      person_type
      ptypes {
        id
        name
      }
      email
      created_at
    }
    title
    updated_at
    tags {
      tag {
        description
        color
        id
      }
    }
    products {
      product {
        id
        name
      }
      id
    }
    status {
      id
      name
      color
    }
    owner_id
    owner {
      avatar_url
      display_name
      id
    }
    created_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    comments {
      comment
      created_at
      user {
        display_name
        avatar_url
        id
      }
    }
    order
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSubscriptionCardsGQL extends Apollo.Subscription<GetSubscriptionCardsSubscription, GetSubscriptionCardsSubscriptionVariables> {
    document = GetSubscriptionCardsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangeOrderCardsDocument = gql`
    mutation ChangeOrderCards($list_id: uuid!, $card_id: uuid!, $order: Int!) {
  reorder(arg1: {card_id: $card_id, list_id: $list_id, order: $order}) {
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangeOrderCardsGQL extends Apollo.Mutation<ChangeOrderCardsMutation, ChangeOrderCardsMutationVariables> {
    document = ChangeOrderCardsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangeListOrdersCardsDocument = gql`
    mutation ChangeListOrdersCards($list_id: uuid!, $card_id: uuid!, $order: Int!) {
  update_omnichannel_pipeline_card_by_pk(
    pk_columns: {id: $card_id}
    _set: {pipeline_list_id: $list_id}
  ) {
    id
  }
  reorder(arg1: {card_id: $card_id, list_id: $list_id, order: $order}) {
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangeListOrdersCardsGQL extends Apollo.Mutation<ChangeListOrdersCardsMutation, ChangeListOrdersCardsMutationVariables> {
    document = ChangeListOrdersCardsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePersonDocument = gql`
    mutation UpdatePerson($id: uuid!, $email: String!, $avatar: String!, $phone: String!, $name: String!, $ptype: Int!) {
  update_omnichannel_persons_by_pk(
    pk_columns: {id: $id}
    _set: {email: $email, avatar: $avatar, phone: $phone, name: $name, person_type: $ptype}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePersonGQL extends Apollo.Mutation<UpdatePersonMutation, UpdatePersonMutationVariables> {
    document = UpdatePersonDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCardStatusDocument = gql`
    mutation UpdateCardStatus($id: uuid!, $status_id: uuid!, $date_close: timestamptz, $owner_id: uuid!) {
  update_omnichannel_pipeline_card_by_pk(
    pk_columns: {id: $id}
    _set: {pipeline_status_id: $status_id, date_close: $date_close, owner_id: $owner_id}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCardStatusGQL extends Apollo.Mutation<UpdateCardStatusMutation, UpdateCardStatusMutationVariables> {
    document = UpdateCardStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCardDescriptionDocument = gql`
    mutation UpdateCardDescription($id: uuid!, $description: String!) {
  update_omnichannel_pipeline_card_by_pk(
    pk_columns: {id: $id}
    _set: {description: $description}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCardDescriptionGQL extends Apollo.Mutation<UpdateCardDescriptionMutation, UpdateCardDescriptionMutationVariables> {
    document = UpdateCardDescriptionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertCommentCardDocument = gql`
    mutation InsertCommentCard($comment: String!, $card_id: uuid!, $user_id: uuid!) {
  insert_omnichannel_pipeline_comments_one(
    object: {comment: $comment, pipeline_card_id: $card_id, user_id: $user_id}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertCommentCardGQL extends Apollo.Mutation<InsertCommentCardMutation, InsertCommentCardMutationVariables> {
    document = InsertCommentCardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCommentDocument = gql`
    mutation DeleteComment($id: uuid!) {
  delete_omnichannel_pipeline_comments_by_pk(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCommentGQL extends Apollo.Mutation<DeleteCommentMutation, DeleteCommentMutationVariables> {
    document = DeleteCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCardDocument = gql`
    mutation DeleteCard($id: uuid!) {
  delete_omnichannel_pipeline_card_by_pk(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCardGQL extends Apollo.Mutation<DeleteCardMutation, DeleteCardMutationVariables> {
    document = DeleteCardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCommentsCardDocument = gql`
    subscription GetCommentsCard($card_id: uuid!) {
  omnichannel_pipeline_comments(
    order_by: {created_at: desc}
    where: {pipeline_card_id: {_eq: $card_id}}
  ) {
    id
    comment
    created_at
    id
    user {
      avatar_url
      display_name
      id
    }
    user_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCommentsCardGQL extends Apollo.Subscription<GetCommentsCardSubscription, GetCommentsCardSubscriptionVariables> {
    document = GetCommentsCardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUsersLeadsDocument = gql`
    query GetUsersLeads {
  omnichannel_groups_users {
    id
    user {
      id
      display_name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUsersLeadsGQL extends Apollo.Query<GetUsersLeadsQuery, GetUsersLeadsQueryVariables> {
    document = GetUsersLeadsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DisableCardDocument = gql`
    mutation DisableCard($id: uuid!) {
  update_omnichannel_pipeline_card_by_pk(
    pk_columns: {id: $id}
    _set: {delete: true}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DisableCardGQL extends Apollo.Mutation<DisableCardMutation, DisableCardMutationVariables> {
    document = DisableCardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetOnwerDocument = gql`
    query GetOnwer {
  users(order_by: {display_name: asc}) {
    id
    display_name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOnwerGQL extends Apollo.Query<GetOnwerQuery, GetOnwerQueryVariables> {
    document = GetOnwerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetGroupUsersDocument = gql`
    query GetGroupUsers($user_id: uuid!) {
  omnichannel_groups_users(where: {user_id: {_eq: $user_id}}) {
    browertab
    celular
    currency
    disable_not_from
    disable_not_to
    email_notification
    enable_lead
    group_id
    id
    language
    timezone
    user_id
    enable_celular
    enable_email
    disable_notification
    user {
      avatar_url
      display_name
      updated_at
      created_at
    }
    group {
      id
      code
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGroupUsersGQL extends Apollo.Query<GetGroupUsersQuery, GetGroupUsersQueryVariables> {
    document = GetGroupUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePreferencesDocument = gql`
    mutation UpdatePreferences($user_id: uuid!, $timezone: String!, $currency: String!, $language: String!) {
  update_omnichannel_groups_users(
    where: {user_id: {_eq: $user_id}}
    _set: {timezone: $timezone, currency: $currency, language: $language}
  ) {
    returning {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePreferencesGQL extends Apollo.Mutation<UpdatePreferencesMutation, UpdatePreferencesMutationVariables> {
    document = UpdatePreferencesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateNotificationDocument = gql`
    mutation UpdateNotification($user_id: uuid!, $browertab: Boolean!, $enable_celular: Boolean!, $enable_email: Boolean!, $disable_notification: Boolean!, $enable_lead: Boolean!, $email_notification: String, $celular: String, $disable_not_to: Int, $disable_not_from: Int) {
  update_omnichannel_groups_users(
    where: {user_id: {_eq: $user_id}}
    _set: {browertab: $browertab, enable_celular: $enable_celular, enable_email: $enable_email, disable_notification: $disable_notification, enable_lead: $enable_lead, email_notification: $email_notification, celular: $celular, disable_not_to: $disable_not_to, disable_not_from: $disable_not_from}
  ) {
    returning {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateNotificationGQL extends Apollo.Mutation<UpdateNotificationMutation, UpdateNotificationMutationVariables> {
    document = UpdateNotificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUsersDocument = gql`
    query GetUsers($limit: Int!, $offset: Int, $text_search: String) {
  omnichannel_groups_users(
    order_by: {created_at: desc}
    limit: $limit
    offset: $offset
    where: {user: {display_name: {_like: $text_search}}}
  ) {
    browertab
    celular
    created_at
    currency
    disable_not_from
    disable_not_to
    disable_notification
    email_notification
    enable_celular
    enable_email
    enable_lead
    group {
      active
      id
      name
    }
    user {
      display_name
      avatar_url
      id
      account {
        email
      }
    }
    user_id
  }
  omnichannel_groups_users_aggregate(
    where: {user: {display_name: {_like: $text_search}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUsersGQL extends Apollo.Query<GetUsersQuery, GetUsersQueryVariables> {
    document = GetUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EnabledLeadDocument = gql`
    mutation EnabledLead($itens: [omnichannel_groups_users_insert_input!]!) {
  insert_omnichannel_groups_users(
    objects: $itens
    on_conflict: {constraint: groups_users_user_id_key, update_columns: [enable_lead, lead_qtd]}
  ) {
    returning {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EnabledLeadGQL extends Apollo.Mutation<EnabledLeadMutation, EnabledLeadMutationVariables> {
    document = EnabledLeadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ActiveAccountDocument = gql`
    mutation ActiveAccount($id: uuid!) {
  update_auth_accounts(where: {user_id: {_eq: $id}}, _set: {active: true}) {
    returning {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ActiveAccountGQL extends Apollo.Mutation<ActiveAccountMutation, ActiveAccountMutationVariables> {
    document = ActiveAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSubcriptionUserDocument = gql`
    mutation UpdateSubcriptionUser($id: uuid!, $avatar_url: String = "", $display_name: String = "") {
  update_users_by_pk(
    pk_columns: {id: $id}
    _set: {permission_id: "9e4a72c7-0849-43a0-8e55-494bd3236382", feature_default_id: 7, avatar_url: $avatar_url, display_name: $display_name}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSubcriptionUserGQL extends Apollo.Mutation<UpdateSubcriptionUserMutation, UpdateSubcriptionUserMutationVariables> {
    document = UpdateSubcriptionUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertMemberDocument = gql`
    mutation InsertMember($user_id: uuid!) {
  insert_memberships_one(
    object: {organizationid: 2, user_id: $user_id, apikey_id: "907629c0-0ed7-4854-ab13-782d63160fe8"}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertMemberGQL extends Apollo.Mutation<InsertMemberMutation, InsertMemberMutationVariables> {
    document = InsertMemberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertRolesUserDocument = gql`
    mutation InsertRolesUser($user_id: uuid!) {
  insert_acl_features_permissions(
    objects: [{acl_features_id: 7, delete: true, read: true, write: true, user_id: $user_id}, {acl_features_id: 8, delete: true, read: true, write: true, user_id: $user_id}]
  ) {
    returning {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertRolesUserGQL extends Apollo.Mutation<InsertRolesUserMutation, InsertRolesUserMutationVariables> {
    document = InsertRolesUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertGroupDocument = gql`
    mutation InsertGroup($user_id: uuid!) {
  insert_omnichannel_groups_users_one(
    object: {group_id: 2, lead_qtd: 0, user_id: $user_id, enable_lead: false, enable_email: false, enable_celular: false}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertGroupGQL extends Apollo.Mutation<InsertGroupMutation, InsertGroupMutationVariables> {
    document = InsertGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const Omnichannel_TagsDocument = gql`
    query omnichannel_tags {
  omnichannel_tags {
    description
    id
    color
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class Omnichannel_TagsGQL extends Apollo.Query<Omnichannel_TagsQuery, Omnichannel_TagsQueryVariables> {
    document = Omnichannel_TagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const Query_Person_TypesDocument = gql`
    query query_person_types($teamid: Int!) {
  omnichannel_person_type(where: {organization_id: {_eq: $teamid}}) {
    value: id
    viewValue: name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class Query_Person_TypesGQL extends Apollo.Query<Query_Person_TypesQuery, Query_Person_TypesQueryVariables> {
    document = Query_Person_TypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const Persons_AggregateDocument = gql`
    query persons_aggregate($teamid: Int!, $limit: Int!, $offset: Int, $person_type: Int, $text_search: String) {
  omnichannel_persons_aggregate(
    where: {organization_id: {_eq: $teamid}, person_type: {_eq: $person_type}, name: {_like: $text_search}}
    order_by: {created_at: desc}
  ) {
    aggregate {
      count
    }
  }
  omnichannel_persons(
    where: {organization_id: {_eq: $teamid}, person_type: {_eq: $person_type}, name: {_like: $text_search}}
    limit: $limit
    offset: $offset
    order_by: {created_at: desc}
  ) {
    id
    name
    avatar
    city
    companyName
    cpf_cnpj
    email
    instagram
    phone
    whatsapp
    ptypes {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class Persons_AggregateGQL extends Apollo.Query<Persons_AggregateQuery, Persons_AggregateQueryVariables> {
    document = Persons_AggregateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const Person_TypesDocument = gql`
    query person_types($id: uuid!) {
  omnichannel_persons_by_pk(id: $id) {
    avatar
    address
    city
    name
    state
    id
    email
    zipcode
    owner_id
    person_type
    cpf_cnpj
    phone
    whatsapp
    companyName
    tags {
      tag {
        color
        id
        description
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class Person_TypesGQL extends Apollo.Query<Person_TypesQuery, Person_TypesQueryVariables> {
    document = Person_TypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const Insert_PersonDocument = gql`
    mutation insert_person($address: String, $avatar: String, $companyName: String, $cpf_cnpj: String, $email: String, $facebook: String, $instagram: String, $name: String, $number: String, $organization_id: Int!, $owner_id: uuid!, $person_type: Int!, $phone: String, $website: String, $whatsapp: String, $zipcode: String, $state: String, $city: String) {
  insert_omnichannel_persons_one(
    object: {active: true, address: $address, avatar: $avatar, companyName: $companyName, cpf_cnpj: $cpf_cnpj, email: $email, facebook: $facebook, instagram: $instagram, name: $name, number: $number, organization_id: $organization_id, owner_id: $owner_id, person_type: $person_type, phone: $phone, website: $website, whatsapp: $whatsapp, zipcode: $zipcode, state: $state, city: $city}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class Insert_PersonGQL extends Apollo.Mutation<Insert_PersonMutation, Insert_PersonMutationVariables> {
    document = Insert_PersonDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const Update_PersonDocument = gql`
    mutation update_person($id: uuid!, $address: String, $companyName: String, $cpf_cnpj: String, $email: String, $facebook: String, $instagram: String, $name: String!, $number: String, $person_type: Int, $phone: String, $state: String, $website: String, $whatsapp: String, $zipcode: String, $city: String) {
  update_omnichannel_persons_by_pk(
    pk_columns: {id: $id}
    _set: {address: $address, companyName: $companyName, cpf_cnpj: $cpf_cnpj, email: $email, facebook: $facebook, instagram: $instagram, name: $name, number: $number, person_type: $person_type, phone: $phone, state: $state, website: $website, whatsapp: $whatsapp, zipcode: $zipcode, city: $city}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class Update_PersonGQL extends Apollo.Mutation<Update_PersonMutation, Update_PersonMutationVariables> {
    document = Update_PersonDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const Insert_Person_NoteDocument = gql`
    mutation insert_person_note($person_id: uuid!, $description: String!) {
  insert_omnichannel_person_note_one(
    object: {description: $description, person_id: $person_id}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class Insert_Person_NoteGQL extends Apollo.Mutation<Insert_Person_NoteMutation, Insert_Person_NoteMutationVariables> {
    document = Insert_Person_NoteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const Insert_Person_TagsDocument = gql`
    mutation insert_person_tags($person_id: uuid!, $tag_id: uuid!) {
  insert_omnichannel_person_tags_one(
    object: {person_id: $person_id, tag_id: $tag_id}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class Insert_Person_TagsGQL extends Apollo.Mutation<Insert_Person_TagsMutation, Insert_Person_TagsMutationVariables> {
    document = Insert_Person_TagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const Delete_Person_TagsDocument = gql`
    mutation delete_person_tags($person_id: uuid!, $tag_id: uuid!) {
  delete_omnichannel_person_tags(
    where: {person_id: {_eq: $person_id}, tag_id: {_eq: $tag_id}}
  ) {
    returning {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class Delete_Person_TagsGQL extends Apollo.Mutation<Delete_Person_TagsMutation, Delete_Person_TagsMutationVariables> {
    document = Delete_Person_TagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendEmailDocument = gql`
    mutation SendEmail($account_id: String!, $address: String!, $name: String!, $html: String!, $subject: String!, $address1: String!, $name1: String!) {
  sendEmail(
    arg1: {account_id: $account_id, from: {address: $address, name: $name}, subject: $subject, to: {address: $address1, name: $name1}, html: $html}
  ) {
    error
    message
    messageId
    response
    statusCode
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendEmailGQL extends Apollo.Mutation<SendEmailMutation, SendEmailMutationVariables> {
    document = SendEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetActivitiesPersonDocument = gql`
    subscription GetActivitiesPerson($person_id: uuid = "") {
  omnichannel_vw_activities_group(
    where: {acitvity: {person_id: {_eq: $person_id}}}
  ) {
    id
    mon
    yyyy
    acitvity {
      id
      feature
      description
      created_at
      owner {
        display_name
        avatar_url
      }
      person_mail_id
      person_note_id
      tag_id
      person_id
      person_file_id
      note {
        description
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetActivitiesPersonGQL extends Apollo.Subscription<GetActivitiesPersonSubscription, GetActivitiesPersonSubscriptionVariables> {
    document = GetActivitiesPersonDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const Omnichannel_PipelineDocument = gql`
    query omnichannel_pipeline {
  omnichannel_pipeline {
    id
    description
    created_at
    title
    updated_at
    lists {
      id
      order
      title
      cards {
        id
        description
        created_at
        order
        owner {
          display_name
          avatar_url
        }
        person {
          avatar
          id
          name
        }
        comments_aggregate {
          aggregate {
            count
          }
        }
        status {
          id
          name
          color
        }
        tags {
          tag {
            id
            color
            description
          }
        }
      }
      cards_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class Omnichannel_PipelineGQL extends Apollo.Query<Omnichannel_PipelineQuery, Omnichannel_PipelineQueryVariables> {
    document = Omnichannel_PipelineDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }