import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { HttpService } from './http.service';
import { IUser } from '../interfaces/user';
import { distinctUntilChanged } from 'rxjs/operators';
import * as Query from '../graph-ql/queries';
import { Apollo } from 'apollo-angular';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private profileUrl: string;
  private profileSource: BehaviorSubject<IUser>;
  profile$: Observable<IUser>;

  constructor(private httpSv: HttpService, private apollo: Apollo, public auth: AuthService) {
    this.profileUrl = '../../../assets/data/profile.json';
    this.profileSource = new BehaviorSubject({
      avatar: '',
      display_name: '',
      role: '',
      email: '',
      phone: '',
      city: '',
      state: '',
      country: '',
      company: ''
    });

    this.profile$ = this.profileSource.asObservable();
    this.apollo.watchQuery<any>({
      query: Query.query_profile,
      pollInterval: 500,
      variables: { "id": this.auth.getUserID() }
    })
      .valueChanges
      .subscribe(({ data }) => {
        let res = {
          avatar: data.profile.avatar,
          display_name: data.profile.display_name,
          role: "",
          email: data.profile.account.email,
          phone: '',
          city: '',
          state: '',
          country: '',
          company: ''
        }
        this.profileSource.next(res)
      });
    //this.httpSv.getData<IUser>(this.profileUrl).subscribe(res => this.profileSource.next(res));
  }

  getProfile(): Observable<IUser> {
    return this.profileSource.asObservable().pipe(distinctUntilChanged());
  }

  editProfile(profile: IUser): void {
    this.profileSource.next({ ...this.profileSource.value, ...profile });
  }
}
