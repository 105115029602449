import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tc-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TCBadgeComponent implements OnInit {
  @HostBinding('class.tc-badge') true;

  @HostBinding('class.purple') get purple() {
    return this.view === 'purple';
  }
  @HostBinding('class.yellow') get yellow() {
    return this.view === 'yellow';
  }
  @HostBinding('class.green') get green() {
    return this.view === 'green';
  }
  @HostBinding('class.red') get red() {
    return this.view === 'red';
  }
  @HostBinding('class.dark-blue') get darkBlue() {
    return this.view === 'dark-blue';
  }

  @Input() view: string;

  constructor() {}

  ngOnInit() {}
}
