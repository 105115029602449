<mat-card>
  <mat-card-header>
    <mat-card-title>Material badges</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-chip-list>
      <mat-chip>Default</mat-chip>
      <mat-chip color="primary" selected>Primary</mat-chip>
      <mat-chip color="accent" selected>Accent</mat-chip>
      <mat-chip color="warn" selected>Warn</mat-chip>
    </mat-chip-list>
  </mat-card-content>
</mat-card>

<mat-card class="mb-0">
  <mat-card-header>
    <mat-card-title>Theme badges</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="elem-list">
      <tc-badge>Default</tc-badge>
      <tc-badge [view]="'purple'">Purple</tc-badge>
      <tc-badge [view]="'yellow'">Yellow</tc-badge>
      <tc-badge [view]="'green'">Green</tc-badge>
      <tc-badge [view]="'red'">Red</tc-badge>
      <tc-badge [view]="'dark-blue'">Dark blue</tc-badge>
    </div>
  </mat-card-content>
</mat-card>
