import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { DealType, IDeal, LeadType } from '../../../interfaces/deal';
import dayjs from 'dayjs'
import { MatDialog } from '@angular/material';
import { DeleteDialogComponent } from '../../components/delete-dialog';

import {
  GetCardsforListsQuery,
  DisableCardGQL
} from '../../../../generated/graphql'

@Component({
  selector: 'app-lead',
  templateUrl: './lead.component.html',
  styleUrls: ['./lead.component.scss']
})
export class LeadComponent implements OnInit {
  @HostBinding('class.app-task') true;

  @HostBinding('class.low') get isLow() {
    return this.deal.status.color === LeadType.low.toString();
  }
  @HostBinding('class.high') get isHigh() {
    return this.deal.status.color === LeadType.high.toString();
  }
  @HostBinding('class.urgent') get isUrgent() {
    return this.deal.status.color === LeadType.urgent.toString();
  }

  @Input() deal: any;

  @Output() editDeal: EventEmitter<IDeal>;
  @Output() deleteDeal: EventEmitter<any>;

  constructor(private dialog: MatDialog,) {
    this.editDeal = new EventEmitter<IDeal>();
    this.deleteDeal = new EventEmitter<IDeal>();
  }

  ngOnInit() {}

  onDealEdit(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.editDeal.emit(this.deal);
  }

  getDate(value: string): string {
    return dayjs(value).format('DD/MM');
  }

  onDeleteDeal(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    const MSG = 'Tem certeza de que deseja excluir esta oportunidade?';

    const REF = this.dialog.open(DeleteDialogComponent, {
      width: '91.5%',
      maxWidth: '435px',
      data: MSG
    });

    REF.afterClosed()
      .subscribe((isdelete) => {
        this.deleteDeal.emit({deal: this.deal.id, isdelete: isdelete});
        /*if(isdelete){
          this.store.dispatch(layoutActions.setLoadingPage({ loading: true }));
          this.disableCard.mutate({id: deal.id}).subscribe(() =>{
            this.store.dispatch(layoutActions.setLoadingPage({ loading: false }));
          });
        }*/
      });
  }
}
