<div
  class="search-input-wrap"
  (click)="input.focus()"
  [ngClass]="{ focus: focused }"
  [ngStyle]="{ 'background-color': bgColor }"
>
  <mat-icon class="icon">search</mat-icon>

  <mat-form-field class="p-0" appearance="none">
    <input
      #input
      [(ngModel)]="value"
      (focus)="onFocus()"
      (blur)="onBlur()"
      class="p-0"
      matInput
      (keydown.enter)="applyFilter($event)"
      placeholder="{{ placeholder }}"
    />
  </mat-form-field>
</div>
