<div class="row d-flex">
  <div class="col-xl-7 col-lg-6 col-sm-12">
    <mat-card class="mb-0">
      <app-task-list [tasks]="tasks$ | async"></app-task-list>
    </mat-card>
  </div>

  <div class="col-xl-5 col-lg-6 col-sm-12 mobile-first">
    <div class="row">
      <div class="col-12">
        <mat-card>
          <mat-card-header class="d-flex justify-content-between">
            <mat-card-title>Deals</mat-card-title>
            <div class="d-flex align-items-center header-select-wrap">
              <span class="label mr-2">Deals:</span>
              <mat-form-field class="select" [appearance]="'none'">
                <mat-select [formControl]="dealsSelect">
                  <mat-option [value]="'Dayli'">Daily</mat-option>
                  <mat-option [value]="'Monthly'">Monthly</mat-option>
                  <mat-option [value]="'Yearly'">Yearly</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-card-header>
          <tc-circle-badge class="mb-2" [color]="'#109CF1'">Closed deals</tc-circle-badge>
          <div
            class="chart-container container-h-250"
            echarts
            [options]="lineOptions"
          ></div>
        </mat-card>
      </div>
      <div class="col-12">
        <mat-card class="chart-card mb-lg-0">
          <mat-card-header class="d-flex justify-content-between">
            <mat-card-title>Tasks</mat-card-title>
            <div class="d-flex align-items-center header-select-wrap">
              <span class="label mr-2">Tasks:</span>
              <mat-form-field class="select" [appearance]="'none'">
                <mat-select [formControl]="tasksSelect">
                  <mat-option [value]="'This week'">This week</mat-option>
                  <mat-option [value]="'This month'">This month</mat-option>
                  <mat-option [value]="'This year'">This year</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-card-header>

          <div class="card-content">
            <div class="chart">
              <div
                class="chart-container container-h-240 container-w-235"
                echarts
                [options]="pieOptions"
              ></div>
              <div class="chart-label-block">
                <span class="chart-label">
                  60%
                </span>
              </div>
            </div>
            <div class="statuses">
              <tc-circle-badge [view]="'green'">Completed</tc-circle-badge>
              <tc-circle-badge [view]="'yellow'">Active</tc-circle-badge>
              <tc-circle-badge [view]="'red'">Ended</tc-circle-badge>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
