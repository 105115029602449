<div class="header">
  <span class="task">{{ task.title }}</span>
  <span class="type">{{ task.type }}</span>
</div>

<div class="body"><span class="due-date">Due date: </span> <span>{{ task.dueDate }}</span></div>

<div class="footer">
  <div class="user">
    <div class="img"><img [src]="task.user.avatar" [alt]="task.user.name"></div>
    <span class="name">{{ task.user.name }}</span>
  </div>

  <div class="status">
    <div class="actions">
      <div class="statuses">
        <ng-container *ngFor="let status of getViewArr(task.status)" >
          <tc-circle-badge [view]="status"></tc-circle-badge>
        </ng-container>
      </div>

      <div class="icons">
        <mat-icon class="action edit-btn">edit</mat-icon>
        <mat-icon (click)="onDeleteTask($event)" class="action delete-btn">delete</mat-icon>
      </div>
    </div>

    <tc-badge [view]="getBadgeView(task.status)">{{ task.status }}</tc-badge>
  </div>
</div>
