import { Component, HostBinding, OnInit, Input } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { Store } from '@ngrx/store';

import { IMenuItem } from '../../../interfaces/menu';
import { HttpService } from '../../../services/http.service';
import { IAccoutnInfo } from '../../../interfaces/account-info';

import { IState } from '../../../interfaces/state';
import { toggleSidebar } from '../../../store/actions/sidebar.actions';

import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  @HostBinding('class.side-bar') true;
  @HostBinding('class.minimized') get minimizedState() {
    return this.minimized;
  }
  @HostBinding('class.mobile') get mobileState() {
    return this.mobile;
  }

  @Input() minimized: boolean;
  @Input() mobile: boolean;

  logo: string;
  minLogo: string;

  accountInfo: IAccoutnInfo;

  menuUrl: string;
  menu: Observable<IMenuItem[]>;

  private menuTypesSource: BehaviorSubject<IMenuItem[]>;

  constructor(private httpSv: HttpService, private store: Store<IState>, public auth: AuthService) { }

  ngOnInit() {
    let _user = this.auth.getUserDetails();
    this.accountInfo = {
      name: _user.users_by_pk.display_name,
      avatar: _user.users_by_pk.avatar_url,
      minAvatar: _user.users_by_pk.avatar_url,
      email: _user.users_by_pk.account.email
    };

    this.menuTypesSource = new BehaviorSubject([]);
    this.menu = this.menuTypesSource.asObservable();
    this.getMenu();

    //this.menuUrl = '../../../../assets/data/menu.json';
    //this.menu = this.getMenu(this.menuUrl);
    this.logo = '../../../../assets/svg/logo.svg';
    this.minLogo = '../../../../assets/svg/logo-min.svg';
  }

  getMenu(): void {
    let _user = JSON.parse(localStorage.getItem('user'));
    this.menuTypesSource.next(_user.users_by_pk.acl_features_permissions);
    //return this.httpSv.getData<IMenuItem[]>(url);
  }

  changeNavState(state: boolean): void {
    this.store.dispatch(toggleSidebar(state));
  }
}
