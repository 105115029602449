<div class="conversations-block">
  <app-chat-list #list [userId]="userId" [chatList]="chatList$ | async"></app-chat-list>
</div>

<div class="messages-block">
  <div class="back-btn" (click)="deselectChat()">
  <mat-icon>arrow_back_ios</mat-icon>
  <span class="text">Back to messages</span>
</div>

  <app-dialog
    (deselectChat)="deselectChat()"
    #dialog
    [user]="profile$ | async"
    [chat]="selectedChat$ | async"
  ></app-dialog>
</div>
