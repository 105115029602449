<div (click)="open()" class="select-box" [ngClass]="selectBoxClass(this.selectedValue)">
  {{ selectedValue.text }} <mat-icon>keyboard_arrow_down</mat-icon>
</div>

<div class="list-wrap" [@shrink]="opened ? 'active' : 'inactive'">
  <ul class="options-list">
    <li
      class="options-list-item"
      *ngFor="let option of displayedOptions(options, selectedValue.value)"
      (click)="select(option)"
    >
      {{ option.text }}
    </li>
  </ul>
</div>
