import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { LayoutModule } from './layout/layout.module';
import { PagesModule } from './pages/pages.module';

import { AppRoutingModule, ROUTES } from './routing/app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { reducers } from './store/reducers';
import { GraphQLModule } from './graph-ql/graph-ql.module';

import {
  MatSnackBarModule
} from '@angular/material';
import { registerLocaleData } from '@angular/common';
import pt from '@angular/common/locales/pt';
import { FormsModule } from '@angular/forms';
import { ShareService } from './services/share.service';

import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, ApolloLink, GraphQLRequest, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/link-context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { split } from '@apollo/client/core';
import { getMainDefinition } from '@apollo/client/utilities';
import { HttpHeaders } from '@angular/common/http';
import { getOperationAST } from 'graphql';
import { onError } from '@apollo/link-error';
import { AuthService } from "./services/auth.service"
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {AppInfiniteComponent} from './directive/infinitescroll.directive'

registerLocaleData(pt);

@NgModule({
  declarations: [AppComponent, AppInfiniteComponent],
  imports: [
    PagesModule,
    LayoutModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    RouterModule.forRoot(ROUTES, { useHash: true }),
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers),
    MatSnackBarModule,
    FormsModule,
    InfiniteScrollModule
  ],
  exports: [AppInfiniteComponent],
  providers: [ShareService],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(
    private apollo: Apollo,
    httpLink: HttpLink,
    private authSV: AuthService
  ) {
    //const protocol = environment.https ? 'https' : 'http';

    // Creating new HttpLink for Apollo Client
    const http = httpLink.create({ uri: 'https://server.donit.io/v1/graphql' })

    // Creating new WebSocketLink for ApolloClient to support GraphQL subscriptions
    const ws = new WebSocketLink({
      uri: `wss://server.donit.io/v1/graphql`,
      options: {
        reconnect: true,
        connectionParams: {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        },
      }
    });

    // authContext to set Authorization token for every request sent from client
    const authContext = setContext(async (request, previousContext) => {
      // Getting the token from the session service
      const token = await localStorage.getItem('access_token');

      // return {} if token is not set yet
      if (!token) {
        return {}
      }

      // Set Authorization headers with token
      return {
        headers: { Authorization: `Bearer ${token}` }
      }
    });

    // Error handling for GraphQL client
    const error = onError(({ graphQLErrors, networkError, response, operation }) => {
      if (graphQLErrors) {
        if (graphQLErrors && graphQLErrors.length > 0 && graphQLErrors[0].message === "Could not verify JWT: JWTExpired") {
          this.authSV.authorize();
        }
        console.log('Network', networkError)
      }

      // Login the Network errors
      if (networkError) {
        console.log(`[Network Error]:`, networkError);
      }
    });

    // afterwareLink, Apollo link
    const afterwareLink = new ApolloLink((operation, forward) => {
      // tap to the forward reuqest operation
      return forward(operation).map(response => {

        // get the response headers
        const {
          response: { headers }
        } = operation.getContext();

        console.log('teste')

        // check if headers recieved
        if (headers) {
          // get the token from the response header
          const _token = headers.get('Authorization');

          // check if token is not null
          if (_token && _token !== 'null') {

            // get playload for of the token
            //const payload = getPayload(_token);

            // set the new token into the session
            //this.session.setToken(_token);
          }
        }

        return response;
      });
    });

    // creating the conditional link for http and ws requests
    const link = split(({ query }) => {
      const { kind, operation }: any = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    }, ws, ApolloLink.from([authContext, error, afterwareLink, http]));

    // creating the final Apollo client link with all the parameters
    apollo.create({
      link: link,
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: 'network-only'
        }
      }
    });
  }
}
