import { IUser } from './user';

export interface IChatResponse {
  id: string;
  avatar?: string;
  title?: string;
  membersIds: string[];
  messages: IMessage[];
}

export interface IChat {
  id: string;
  avatar?: string;
  title?: string;
  members: IUser[];
  messages: IMessage[];
}

export interface IMessage {
  text: string;
  date: string;
  sender: string;
  status: MessageStatus;
}

export enum MessageStatus {
  delivered = 'read',
  read = 'read',
  unread = 'unread',
  fail = 'failed'
}
