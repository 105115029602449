export interface IEmail {
  id: string;
  status: EmailStatuses;
  subject: string;
  lastUpdated: string;
  openRate: string;
  img?: string;
  text?: string;
  recipients?: number;
  opens: number;
  linkClicks?: number;
}

export interface IEmailComponent {
  title: string;
  icon: string;
}

export enum EmailStatuses {
  all = 'All',
  draft = 'Draft',
  archived = 'Archived',
  sent = 'Sent',
  scheduled = 'Scheduled'
}

export enum EmailStyles {
  draft = 'yellow',
  scheduled = 'purple',
  sent = 'green',
  archived = 'red'
}
