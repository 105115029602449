<div class="header">
  <div class="row">
    <div class="col-6 status-wrap">
      <tc-badge class="status" [view]="'green'">{{ email.status }}</tc-badge>
    </div>

    <div class="col-6 actions-wrap d-none d-md-block">
      <div class="actions">
        <tc-circle-badge [view]="'red'"></tc-circle-badge>
        <mat-icon (click)="deleteEmail()" class="action delete-btn">delete</mat-icon>
      </div>
    </div>

    <div class="col-12 col-md-6">
      <h2 class="subject">
        {{ email.subject }}
      </h2>
    </div>

    <div class="col-6 last-updated-wrap">
      <div class="last-updated">
         {{ email.lastUpdated }}
      </div>
    </div>

    <div class="col-6">
      <ul class="info-list">
        <li class="info-item">
          <span class="item-label">Recipients:</span>
          <span class="value" (click)="onOpenRecipients()">{{ email.recipients }}</span>
        </li>

        <li class="info-item">
          <span class="item-label">Opens:</span>
          <span class="value">{{ email.opens }}</span>
        </li>

        <li class="info-item">
          <span class="item-label">Link clicks:</span>
          <span class="value">{{ email.linkClicks }}</span>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="img" [ngStyle]="{ 'background-image': 'url('+ email.img +')' }"></div>

<div class="body">
  <h4 class="email-title">{{ email.subject }}</h4>

  <span class="text">
    {{ email.text }}
  </span>
</div>
