import {
  Component,
  HostBinding,
  Input,
  OnInit,
  OnDestroy
} from '@angular/core';
import {
  NavigationEnd,
  Router,
  RoutesRecognized,
  ActivatedRoute
} from '@angular/router';

import {
  trigger,
  state,
  animate,
  transition,
  style
} from '@angular/animations';

import { IMenuItem } from '../../../interfaces/menu';
import { Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('filters', [
      state(
        'active',
        style({
          height: '*',
          visibility: 'visible'
        })
      ),
      state(
        'inactive',
        style({
          height: 0,
          visibility: 'hidden'
        })
      ),
      transition('inactive => active', animate('300ms ease-in-out')),
      transition('active => inactive', animate('200ms ease-in'))
    ])
  ]
})
export class MenuComponent implements OnInit, OnDestroy {
  @HostBinding('class.menu') true;
  @HostBinding('class.minimized') get minimizedState() {
    return this.minimized;
  }
  @HostBinding('class.mobile') get mobileState() {
    return this.mobile;
  }

  @Input() menu: any[];
  @Input() mobile: boolean;
  @Input() minimized: boolean

  routerSubscription: Subscription;
  activeParam: string;

  constructor(private router: Router, private activeRoute: ActivatedRoute) {}

  ngOnInit(): void {
    //this.menu = [];
    this.activeParam = '';

    this.routerSubscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((event: RoutesRecognized) => event.url),
        startWith(this.router.url)
      )
      .subscribe(url => {
        const PAGE_URL = url.split('/')[2];
        this.activeParam = this.activeRoute.snapshot.queryParamMap.get('filter');
        this.menu.forEach(item => (item.active = item.acl_feature.routing === PAGE_URL));
        console.log(this.menu);
      });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  toggleSub(event: Event, item: IMenuItem) {
    event.preventDefault();

    item.active = !item.active;
    console.log(item)
  }
}
