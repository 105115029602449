<mat-card class="mb-0">
  <mat-card-header>
    <div class="title-block">
      <h3 class="title">Tags</h3>

      <button class="p-0" [type]="'tertiary'" tc-button>
        Create new <mat-icon svgIcon="add"></mat-icon>
      </button>
    </div>

    <div class="search-wrap">
      <app-search [block]="true" [placeholder]="'Search tag'" [bgColor]="'#F5F8FA'"></app-search>
    </div>
  </mat-card-header>

  <div class="card-content">
    <div class="tag-field" *ngFor="let tagGroup of (tags$ | async); last as islast">
      <div class="title-block tag-title-block">
        <h3 class="tag-title">{{ tagGroup.title }}</h3>
        <span class="counter">{{ tagGroup.tags.length }}</span>
      </div>

      <div class="tags">
        <div class="tag" *ngFor="let tag of tagGroup.tags">
          <tc-badge [view]="tag.view">{{ tag.text }}</tc-badge>
          <mat-icon class="action edit">edit</mat-icon>
          <mat-icon class="action delete">delete</mat-icon>
        </div>
      </div>

      <div *ngIf="!islast" class="divider"></div>
    </div>

    <button class="save-btn ml-auto" tc-button>Save</button>
  </div>
</mat-card>
