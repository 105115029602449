import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  HostBinding,
  Output,
  EventEmitter,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IState } from '../../../interfaces/state';
import { Store, select } from '@ngrx/store';
import * as layoutActions from '../../../store/actions/layout.actions';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchComponent),
      multi: true
    }
  ]
})
export class SearchComponent implements OnInit, ControlValueAccessor {
  @HostBinding('class.search') true;
  @HostBinding('class.block') @Input() block: true;

  @Input() placeholder: string;
  @Input() bgColor: string;
  @Input() local: boolean = false;

  @Output() searchChange: EventEmitter<string>;

  focused: boolean;
  _value: string;

  onChange: any = () => {};
  onTouched: any = () => {};
  constructor(private store: Store<IState>) {
    this.searchChange = new EventEmitter<string>();
  }

  ngOnInit() {}

  onFocus() {
    this.focused = true;
  }

  onBlur() {
    this.focused = false;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  applyFilter(event: any) {
    let _value = event.target.value == "" || event.target.value == null ? '%%' : event.target.value;
    if(this.local){
      this.searchChange.emit(event.target.value)
    }else{
      this.store.dispatch(layoutActions.setSearchContent({ textsearch: _value, typeSearch: "contact" }));
    }
  }

  set value(value: string) {
    this._value = value;
    this.onChange(value);
  }

  get value(): string {
    return this._value;
  }
}
