<div class="top-bar">
  <div class="selects">
    <div class="d-flex align-items-center header-select-wrap">
      <span class="label mr-2">Pipeline:</span>
      <mat-form-field class="select" [appearance]="'none'">
        <mat-select [value]="id">
          <mat-option *ngFor="let f of pipelineList$ | async" [value]="f.id">{{f.title}}</mat-option>
        </mat-select>
      </mat-form-field>
      <span class="label mr-2 leftfilter">Responsável:</span>
      <mat-form-field class="select" [appearance]="'none'">
        <mat-select [value]="ownerid" (selectionChange)="filterOnwer($event)">
          <mat-option value="all">Todos</mat-option>
          <mat-option *ngFor="let f of owners$ | async" [value]="f.id">{{f.display_name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-icon class="search">search</mat-icon>
  </div>

  <button class="create-task-btn" type="button" tc-button (click)="addDeal()">
    Add négocio
  </button>
</div>

<ng-container *ngIf="pipelineOne$ | async as pipeline; else loading">
<div class="deals-block">
  <div class="deals-lists-block" cdkDropListGroup>
    <app-list-leads *ngFor="let list of pipeline.lists; let i = index" [data]="list"></app-list-leads>
  </div>

  <!--<div
    class="total-bar-wrap"
    *ngIf="!(dragging && layout !== layoutStates.desktop) || layout === layoutStates.desktop"
  >
    <div class="total-bar">
      <div class="total-block" *ngFor="let list of pipeline.lists"></div>
    </div> 
  </div>-->
</div>
</ng-container>
<ng-template #loading>
  <div>Carregando...</div>
</ng-template>

<div class="remove-bar-wrap" *ngIf="dragging && layout !== layoutStates.desktop">
  <div class="remove-bar">
    <div class="bg"></div>
    <span><mat-icon>delete</mat-icon> Remove</span>
  </div>
</div>
