import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

import { HttpService } from './http.service';

import { IDeal, IDealResponce } from '../interfaces/deal';
import { UsersService } from './users.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DealsService {
  private dealsSource: BehaviorSubject<IDeal[]>;
  private dealsUrl: string;

  deals$: Observable<IDeal[]>;

  constructor(private httpSv: HttpService, private userSv: UsersService) {
    this.dealsUrl = '../../assets/data/deals.json';

    this.dealsSource = new BehaviorSubject([]);
    this.deals$ = this.dealsSource.asObservable();

    combineLatest(this.httpSv.getData<IDealResponce[]>(this.dealsUrl), this.userSv.users$)
      .pipe(
        map(([deals, users]) =>
          deals.map(deal => {
            const { userId, ...rest } = deal;

            return { ...rest, associatedWith: users.find(user => user.id === deal.userId) };
          })
        )
      )
      .subscribe(res => this.dealsSource.next(res));
  }

  updateDeals(arr: IDeal[]): void {
    const updateItems = (d: IDeal) => arr.find(newDeal => newDeal.id === d.id) || d;
    const NEW_DEALS = this.dealsSource.value.map(updateItems);

    this.dealsSource.next([...NEW_DEALS]);
  }

  addDeal(deal: IDeal): void {
    this.dealsSource.next([...this.dealsSource.value, deal]);
  }

  editDeal(deal: IDeal): void {
    const DATA_SOURCE = this.dealsSource.value;
    const DEAL_INDEX = DATA_SOURCE.findIndex(_deal => _deal.id === deal.id);
    DATA_SOURCE[DEAL_INDEX] = deal;

    this.dealsSource.next([...DATA_SOURCE]);
  }

  deleteDeal(deal: IDeal): void {
    const DATA = this.dealsSource.value.filter(d => d.id !== deal.id);

    this.dealsSource.next([...DATA]);
  }
}
