import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IState } from '../../interfaces/state';

import { ISettingTabs } from '../../interfaces/setting-tabs';
import { distinctUntilChanged, filter, map, startWith, pluck } from 'rxjs/operators';
import * as layoutActions from '../../store/actions/layout.actions';
import { Observable, BehaviorSubject } from 'rxjs';

import {
  GetGroupUsersGQL,
  GetGroupUsersQuery
} from '../../../generated/graphql';

@Component({
  selector: 'app-settings',
  templateUrl: './page-settings.component.html',
  styleUrls: ['./page-settings.component.scss']
})
export class PageSettingsComponent implements OnInit, OnDestroy {
  displayedSection: string;
  settingsTabs: ISettingTabs[];
  routerSubscription: Subscription;

  settings$: Observable<any[]>;
  private settingsTypesSource: BehaviorSubject<any>;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private store: Store<IState>,
    private getSetting: GetGroupUsersGQL,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(layoutActions.setPageTitle({ title: 'Configurações' }));
    this.store.dispatch(layoutActions.setPagePlaceholder({ placeholder: '' }));

    this.initTabs();
    this.routerSubscription = this.activeRoute.queryParams
      .pipe(
        map(p => p.section),
        filter(v => v),
        distinctUntilChanged(),
        startWith('account')
      )
      .subscribe(section => (this.displayedSection = section));
      let queryPersonFilter = this.getSetting.watch({ user_id: localStorage.getItem("user_id") });
      this.settings$ = queryPersonFilter.valueChanges.pipe(pluck('data', 'omnichannel_groups_users'));
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  setSection(section: string): void {
    this.router.navigate([], { queryParams: { section: section } });
  }

  initTabs(): void {
    this.settingsTabs = [
      {
        icon: 'home',
        title: 'Conta',
        desc: 'Suas preferências de conta',
        value: 'account'
      },
      {
        icon: 'notifications',
        title: 'Notificações',
        desc: 'Suas preferências de notificação',
        value: 'notifications'
      },
      /*{
        icon: 'integrations',
        title: 'Integrations',
        desc: 'Add or remove integrations',
        value: 'integrations'
      },*/
      {
        icon: 'account_box',
        title: 'Usuários',
        desc: 'Gerenciar Usuários',
        value: 'user'
      },
      /*{
        icon: 'tags',
        title: 'Tags',
        desc: 'Add or remove tags',
        value: 'tags'
      }*/
    ];
  }
}
