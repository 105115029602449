<div class="header">
  <div class="row">
    <div class="col-6 status-wrap">
      <tc-badge class="status" [view]="'yellow'">Draft</tc-badge>
    </div>

    <div class="col-6 actions-wrap d-none d-md-block">
      <div class="actions">
        <tc-circle-badge [view]="'red'"></tc-circle-badge>
        <mat-icon (click)="deleteEmail()" class="action delete-btn">delete</mat-icon>
      </div>
    </div>

    <div class="col-12 col-md-6">
      <mat-form-field class="full-width">
        <mat-label>Email subject</mat-label>
        <input class="subject-input" [formControl]="subject" matInput placeholder="Email subject" />
      </mat-form-field>
    </div>

    <div class="col-6 last-updated-wrap">
      <div class="last-updated">Saved at {{ currentDate | date }}</div>
    </div>

    <div class="col-6">
      <button class="p-0" tc-button [type]="'tertiary'">Add recipients</button>
    </div>

    <div class="col-6 text-right">
      <button
        class="p-0 d-md-inline d-lg-none"
        (click)="onShowComponents()"
        tc-button
        [type]="'tertiary'"
      >
        Paste component
      </button>
    </div>
  </div>
</div>

<div class="img">
  <img class="img-icon" src="../../../../assets/svg/img-icon.svg" alt="img-icon" />
  <span class="primary-text">Drop an image here</span>
  <span class="secondary-text">or</span>
  <button class="browse-btn" tc-button [type]="'tertiary'">Browse</button>
</div>

<div class="body">
  <h4 class="email-title">{{ subject.value }}</h4>

  <span class="text">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec volutpat nibh dui, at maximus
    odio rhoncus at. Mauris at velit eu turpis sollicitudin congue a ac quam. Mauris facilisis
    pulvinar lectus, maximus placerat leo elementum ut. Etiam a diam volutpat turpis luctus
    vulputate ut eu felis. Vestibulum dictum, purus eu auctor pretium, elit elit porta lorem, in
    convallis tortor erat in leo. Aliquam ac consectetur urna, eget pharetra neque. Morbi dictum
    faucibus eleifend. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec volutpat nibh
    dui, at maximus odio rhoncus at. Mauris at velit eu turpis sollicitudin congue a ac quam. Mauris
    facilisis pulvinar lectus, maximus placerat leo elementum ut. Etiam a diam volutpat turpis
    luctus vulputate ut eu felis. Vestibulum dictum, purus eu auctor pretium, elit elit porta lorem,
    in convallis tortor erat in leo. Aliquam ac consectetur urna, eget pharetra neque. Morbi dictum
    faucibus eleifend. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec volutpat nibh
    dui, at maximus odio rhoncus at
  </span>
</div>
