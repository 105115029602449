<ul class="chat-ul">
  <li
    class="chat-li"
    *ngFor="let chat of chatList"
    (click)="onSelectChat(chat)"
    [ngClass]="{ active: (selectedChat$ | async) && (selectedChat$ | async).id === chat.id }"
  >
    <div class="img-block">
      <img
        [src]="getChatAvatar(chat)"
        [alt]="getChatTitle(chat)"
        class="conversation-avatar"
      />
    </div>

    <div class="text-block" *ngIf="getLastMessage(chat) as msg">
      <div class="header">
        <span class="title">
          {{ getChatTitle(chat) }}
        </span>
        <span class="date">
          {{ msg.date }}
        </span>
      </div>

      <div class="footer">
        <span class="message">
          <span class="prefix" *ngIf="msg.sender === userId">You:</span>
          {{ msg.text }}
        </span>

        <div class="indicator-block">
          <div class="indicator {{ msg.status }}">
            {{ msg.status === 'unread' ? getUnreadMessages(chat) : '' }}
          </div>
        </div>
      </div>
    </div>
  </li>
</ul>
