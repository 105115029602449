import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { IEmail } from '../../../interfaces/email';
import { EmailService } from '../../../services/email.service';
import { DeleteDialogComponent } from '../delete-dialog';
import { MatDialog } from '@angular/material';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-email-view',
  templateUrl: './email-view.component.html',
  styleUrls: ['./email-view.component.scss']
})
export class EmailViewComponent implements OnInit {
  @HostBinding('class.email-view') true;
  @Input() email: IEmail;

  @Output() openRecipients: EventEmitter<boolean>;
  @Output() back: EventEmitter<void>;

  showRecipients: boolean;

  constructor(private emailSv: EmailService, private dialog: MatDialog) {
    this.openRecipients = new EventEmitter();
    this.back = new EventEmitter();
  }

  ngOnInit() {}

  onOpenRecipients(): void {
    this.showRecipients = !this.showRecipients;
    this.openRecipients.emit(this.showRecipients);
  }

  deleteEmail(): void {
    const MSG = 'Are you sure you want to delete this email?';

    const REF = this.dialog.open(DeleteDialogComponent, {
      width: '91.5%',
      maxWidth: '435px',
      data: MSG
    });

    REF.afterClosed()
      .pipe(filter(res => !!res))
      .subscribe(() => {
        this.emailSv.deleteEmail(this.email);
        this.back.emit();
      });
  }
}
