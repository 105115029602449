<div class="row">
  <div class="col-md-12 col-lg-4">
    <div class="top-bar-mobile">
      <div class="back-btn d-flex align-items-center" (click)="onBack()">
        <mat-icon>arrow_back_ios</mat-icon>
        Voltar para lista de contatos
      </div>
    </div>

    <mat-card class="contact-info mb-lg-0">
      <div class="header">
        <mat-icon *ngIf="!creating" class="delete-btn" (click)="onDelete()">delete</mat-icon>

        <div class="img-wrap">
          <img *ngIf="contact.avatar" [src]="contact.avatar" [alt]="contact.name" />

          <div *ngIf="!contact.avatar" class="no-picture">
            <img class="no-picture-icon" src="../../../../assets/svg/img-icon.svg" alt="no picture" />
          </div>

          <button class="edit-btn" [ngClass]="{ active: editing || creating }" (click)="edit()">
            <mat-icon>{{ editing || creating ? 'check' : 'edit' }}</mat-icon>
          </button>
        </div>

        <ng-container *ngIf="!creating">
          <span class="name">{{ contact.name }}</span>
          <!--<span class="role">{{ contact.role }} at {{ contact.companyName }}</span>-->
        </ng-container>

        <ng-container *ngIf="creating">
          <span class="name">Criar um novo contato</span>
        </ng-container>
        <tags-input [editing]="(editing || creating)" (create)="onCreateTag($event)" (delete)="onDeleteTag($event)"
          [selected]="tagsValuesOptions"></tags-input>
        <!--<div class="social-icons" *ngIf="!creating">
          <mat-icon class="svg-icon icon web-site">language</mat-icon>
          <mat-icon class="svg-icon icon twitter" svgIcon="twitter"></mat-icon>
          <mat-icon class="svg-icon icon facebook" svgIcon="facebook"></mat-icon>
        </div>-->
      </div>

      <div class="info">
        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <ng-container *ngFor="let f of formSchema">
              <div class="col-sm-12 col-md-6 col-lg-12">
                <mat-form-field [appearance]="!(editing || creating) && 'none'">
                  <mat-label>{{ f.label }}</mat-label>
                  <input *ngIf="f.type == 'text'" matInput [readonly]="!(editing || creating)"
                    [formControlName]="f.key" />
                  <input *ngIf="f.type == 'tel'" matInput [textMask]="{mask: mask}" [readonly]="!(editing || creating)"
                    [formControlName]="f.key" />
                  <mat-select [disabled]="!(editing || creating)" *ngIf="f.type == 'select'" [formControlName]="f.key">
                    <mat-option *ngFor="let item of f.options | async" [value]="item.value">
                      {{item.viewValue}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="contactForm.controls[f.key].status == 'INVALID'">Precisa ser preenchido</mat-error>
                </mat-form-field>
              </div>
            </ng-container>

            <div class="actions" style="
          width: 100%;
          text-align: right;
      ">
              <div class="icons">
              </div>

              <div class="buttons">
                <button tc-button [type]="'tertiary'">Salvar</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </mat-card>
  </div>

  <div class="col-12 col-lg-8">
    <mat-tab-group mat-stretch-tabs class="tabs contacts-tabs">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">insert_drive_file</mat-icon>
          Nova nota
        </ng-template>
        <div class="tab-content">
          <!--<mat-form-field appearance="none" class="text-area">
            <textarea matInput cdkTextareaAutosize [(ngModel)]="editNota" placeholder="Insira aqui sua nota..."
              cdkAutosizeMinRows="6" cdkAutosizeMaxRows="6"></textarea>
          </mat-form-field>-->
          <div contenteditable="true" class="text-area" [(ngModel)]="editNota" #ctrl="ngModel">
            Digite aqui o seu e-mail
          </div>

          <div class="actions">
            <div class="icons">
              <button (click)="clear()" mat-icon-button>
                <mat-icon>format_clear</mat-icon>
              </button>
              <button (click)="iBold('bold')" mat-icon-button>
                <mat-icon>format_bold</mat-icon>
              </button>
              <button (click)="iBold('justifyCenter')" mat-icon-button>
                <mat-icon>format_align_center</mat-icon>
              </button>
              <button (click)="iBold('justifyFull')" mat-icon-button>
                <mat-icon>format_align_justify</mat-icon>
              </button>
              <button (click)="iBold('justifyLeft')" mat-icon-button>
                <mat-icon>format_align_left</mat-icon>
              </button>
              <button (click)="iBold('justifyRight')" mat-icon-button>
                <mat-icon>format_align_right</mat-icon>
              </button>
              <button (click)="iBold('insertUnorderedList')" mat-icon-button>
                <mat-icon>format_list_bulleted</mat-icon>
              </button>
              <button (click)="iBold('insertOrderedList')" mat-icon-button>
                <mat-icon>format_list_numbered</mat-icon>
              </button>
            </div>

            <div class="buttons">
              <button *ngIf="!creating" class="cancel-btn" tc-button [type]="'tertiary'">Cancelar</button>
              <button *ngIf="!creating" tc-button [type]="'tertiary'" (click)="onCreateNote()">Salvar</button>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">email</mat-icon>
          E-mail
        </ng-template>

        <div class="tab-content">
          <input matInput placeholder="Digite aqui o assunto do e-mail" [(ngModel)]="subject" />
          <div contenteditable="true" class="text-area" [(ngModel)]="bodyEmail" #ctrl="ngModel">
            Digite aqui o seu e-mail
          </div>
          <!--<pre [innerHTML]="bodyEmail"></pre>
          <textarea>{{bodyEmail}}</textarea>
          <div appearance="none" class="text-area">
            <textarea [froalaEditor]="options" [(froalaModel)]="bodyEmail" matInput cdkTextareaAutosize
              placeholder="Envie um novo e-mail" cdkAutosizeMinRows="6" cdkAutosizeMaxRows="6"></textarea>
          </div>-->

          <div class="actions">
            <div class="icons">
              <button (click)="clear()" mat-icon-button>
                <mat-icon>format_clear</mat-icon>
              </button>
              <button (click)="iBold('bold')" mat-icon-button>
                <mat-icon>format_bold</mat-icon>
              </button>
              <button (click)="iBold('justifyCenter')" mat-icon-button>
                <mat-icon>format_align_center</mat-icon>
              </button>
              <button (click)="iBold('justifyFull')" mat-icon-button>
                <mat-icon>format_align_justify</mat-icon>
              </button>
              <button (click)="iBold('justifyLeft')" mat-icon-button>
                <mat-icon>format_align_left</mat-icon>
              </button>
              <button (click)="iBold('justifyRight')" mat-icon-button>
                <mat-icon>format_align_right</mat-icon>
              </button>
              <button (click)="iBold('insertUnorderedList')" mat-icon-button>
                <mat-icon>format_list_bulleted</mat-icon>
              </button>
              <button (click)="iBold('insertOrderedList')" mat-icon-button>
                <mat-icon>format_list_numbered</mat-icon>
              </button>
            </div>

            <div class="buttons">
              <button *ngIf="!creating" class="cancel-btn" tc-button [type]="'tertiary'">Cancelar</button>
              <button *ngIf="!creating" tc-button [type]="'tertiary'" (click)="onSendEmail()">Enviar</button>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab disabled>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">attach_file</mat-icon>
          Novo arquivo
        </ng-template>

        <div class="tab-content">
          <mat-form-field appearance="none" class="text-area">
            <textarea matInput cdkTextareaAutosize placeholder="Create new task here" cdkAutosizeMinRows="6"
              cdkAutosizeMaxRows="6"></textarea>
          </mat-form-field>

          <div class="actions">
            <div class="icons">
              <mat-icon>title</mat-icon>
            </div>

            <div class="buttons">
              <button class="cancel-btn" tc-button [type]="'tertiary'">Cancel</button>
              <button tc-button [type]="'tertiary'">Create</button>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab disabled>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">call</mat-icon>
          Ligar
        </ng-template>

        <div class="tab-content">
          <mat-form-field appearance="none" class="text-area">
            <textarea matInput cdkTextareaAutosize placeholder="Leave new call info" cdkAutosizeMinRows="6"
              cdkAutosizeMaxRows="6"></textarea>
          </mat-form-field>

          <div class="actions">
            <div class="icons">
              <mat-icon>title</mat-icon>
              <mat-icon>insert_link</mat-icon>
              <mat-icon>attach_file</mat-icon>
            </div>

            <div class="buttons">
              <button class="cancel-btn" tc-button [type]="'tertiary'">Cancel</button>
              <button tc-button [type]="'tertiary'">Save</button>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab disabled>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">view_agenda</mat-icon>
          Nova tarefa
        </ng-template>

        <div class="tab-content">
          <mat-form-field appearance="none" class="text-area">
            <textarea matInput cdkTextareaAutosize placeholder="Add new activity here" cdkAutosizeMinRows="6"
              cdkAutosizeMaxRows="6"></textarea>
          </mat-form-field>

          <div class="actions">
            <div class="icons">
              <mat-icon>title</mat-icon>
            </div>

            <div class="buttons">
              <button class="cancel-btn" tc-button [type]="'tertiary'">Cancel</button>
              <button tc-button [type]="'tertiary'">Add</button>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab disabled>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">calendar_today</mat-icon>
          Agenda
        </ng-template>

        <div class="tab-content">
          <mat-form-field appearance="none" class="text-area">
            <textarea matInput cdkTextareaAutosize placeholder="Leave new event here" cdkAutosizeMinRows="6"
              cdkAutosizeMaxRows="6"></textarea>
          </mat-form-field>

          <div class="actions">
            <div class="icons">
              <mat-icon>title</mat-icon>
              <mat-icon>insert_link</mat-icon>
              <mat-icon>attach_file</mat-icon>
            </div>

            <div class="buttons">
              <button class="cancel-btn" tc-button [type]="'tertiary'">Cancel</button>
              <button tc-button [type]="'tertiary'">Save</button>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>

    <div class="activity">
      <div class="top-bar">
        <div class="select d-flex align-items-center header-select-wrap">
          <span class="label mr-2">Filtro</span>
          <mat-form-field class="select" [appearance]="'none'">
            <mat-select [value]="'All'">
              <mat-option [value]="'All'">Todos</mat-option>
              <mat-option [value]="'Person'">Contato</mat-option>
              <mat-option [value]="'Note'">Notas</mat-option>
              <mat-option [value]="'Email'">E-mail</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <mat-card class="mb-0">
        <ng-container *ngIf="!creating">
          <mat-list class="activity-list">
            <ng-container *ngFor="let activity of person_activity$ | async">
              <h3 class="list-subheader">{{ activity.date }}</h3>
              <mat-list-item class="list-item item-list2" *ngFor="let task of activity.tasks">
                <mat-icon class="item-icon" mat-list-icon>{{ task.icon }}</mat-icon>
                <div class="item-text" *ngIf="task.icon == 'call'">
                  <span class="item-title" [innerHtml]="task.title"></span>
                  <span class="item-date">{{task.user}} - {{task.date}}</span>
                </div>
                <div class="item-text" *ngIf="task.icon == 'chat_bubble_outline'">
                  <span class="item-title2" [innerHtml]="task.title"></span>
                  <span class="item-date2">{{task.user}} - {{task.date}}</span>
                </div>
              </mat-list-item>
            </ng-container>
          </mat-list>
        </ng-container>

        <ng-container *ngIf="creating">
          <div class="no-data">
            <img class="no-picture-img" src="../../../../assets/svg/no-data.svg" alt="no data" />

            <span class="no-history">
              Sem atividades
            </span>
          </div>
        </ng-container>
      </mat-card>
    </div>
  </div>
</div>