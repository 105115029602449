<ng-container *ngIf="chat">
  <div class="dialog-header">
    <div class="title-block">
      <div class="back-btn" (click)="onDeselectChat()">
        <mat-icon>arrow_back_ios</mat-icon>
      </div>
      <div class="img-block">
        <img [src]="chat.avatar" [alt]="chat.title" class="chat-avatar" />
      </div>
      <span class="title">{{ chat.title }}</span>
      <div class="status"></div>
    </div>

    <mat-icon class="menu">more_verticaly</mat-icon>
  </div>

  <div class="messages-wrap">
    <div class="messages-list" #chatEl>
      <div
        class="message"
        *ngFor="let message of chat.messages"
        [ngClass]="{ 'my-message': message.sender === user.id }"
      >
        <div class="message-sender">
          <div class="img-block">
            <img
              [src]="getUserAvatar(message.sender)"
              [alt]="message.sender === user.id ? 'You' : getUserTitle(message.sender)"
              class="chat-avatar"
            />
          </div>
          <span class="title">
            {{ message.sender === user.id ? 'You' : getUserTitle(message.sender) }}
          </span>
          <span class="date">{{ getMessageTime(message) }}</span>
        </div>
        <div class="message-text">
          {{ message.text }}
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-footer">
    <mat-form-field [appearance]="'none'" class="input-field no-padding">
      <textarea
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5"
        placeholder="Type a message..."
        [formControl]="chatInput"
        (keydown.enter)="onSendMessage($event)"
      ></textarea>
    </mat-form-field>

    <div class="actions">
      <mat-icon class="action">attach_file</mat-icon>
      <mat-icon class="action">insert_emoticon</mat-icon>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!chat">
  <div class="no-chat">
    Please select a chat to start messaging
  </div>
</ng-container>
