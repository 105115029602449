import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, startWith, tap, switchMap, pluck } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

import { IState } from '../../interfaces/state';
import { DealStatus, DealType, IDeal } from '../../interfaces/deal';
import { DealsService } from '../../services/deals.service';
import { UsersService } from '../../services/users.service';
import { CreateDealComponent } from '../../layout/components/create-deal/create-deal.component';
import { LayoutStates } from '../../interfaces/layout-state';
import * as layoutActions from '../../store/actions/layout.actions';
import { EditDealComponent } from '../../layout/components/edit-deal';
import { OpenedDealComponent } from '../../layout/components/opened-deal';
import { DeleteDialogComponent } from '../../layout/components/delete-dialog';
import { Apollo, gql } from 'apollo-angular';

import {
  GetPipelineGQL,
  GetPipelineQuery,
  GetPipelineOneGQL,
  GetPipelineOneQuery,
  GetOnwerGQL,
  GetOnwerQuery
} from '../../../generated/graphql'

const SUBSCRIBE_TO_ONLINE_USERS = gql`
 subscription GetSubscriptionCards($list_id: uuid!) {
    omnichannel_pipeline_card(where: {pipeline_list_id: {_eq: $list_id}}, order_by: {order: asc}) {
      date_close
      description
      id
    }
 }`

@Component({
  selector: 'page-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss']
})
export class PageLeadsComponent implements OnInit {
  dealsSubscription: Subscription;
  deals: IDeal[];

  newDeals: IDeal[];
  wonDeals: IDeal[];
  lostDeals: IDeal[];

  filter$: Observable<DealType>;

  pipelineOne$: Observable<GetPipelineOneQuery['omnichannel_pipeline_by_pk']>;
  pipelineList$: Observable<GetPipelineQuery['omnichannel_pipeline']>;
  owners$: Observable<GetOnwerQuery['users']>;

  dealStatuses: typeof DealStatus;
  dragging: boolean;
  id: string = "";
  ownerid: string = "all";
  private queryPipelineList = this.getPipeline.watch({});
  private queryOnwerList = this.getOnwer.watch({});

  listidStart = "";

  layout: string;
  layoutStates: typeof LayoutStates;
  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private userSv: UsersService,
    private dealsSv: DealsService,
    private store: Store<IState>,
    private dialog: MatDialog,
    private getPipeline: GetPipelineGQL,
    private getPipelineOne: GetPipelineOneGQL,
    private getOnwer: GetOnwerGQL,
    private apollo: Apollo
  ) { }

  ngOnInit() {
    this.store.dispatch(layoutActions.setPageTitle({ title: 'Négocio' }));
    this.store.dispatch(layoutActions.setPagePlaceholder({ placeholder: 'Procurar em négocio' }));

    this.store.dispatch(layoutActions.setLoadingPage({ loading: true }));

    this.pipelineList$ = this.queryPipelineList.valueChanges.pipe(pluck('data', 'omnichannel_pipeline'));
    this.owners$ = this.queryOnwerList.valueChanges.pipe(pluck('data', 'users'));

    this.pipelineList$.subscribe(data => {
      if (data.length > 0) {
        this.router.navigate(['private/leads-pipe', data[0].id]);
      }
    });

    this.pipelineOne$ = this.activeRoute.params.pipe(
      switchMap(({ id }) => this.getPipelineOne.fetch({ pipeline_id: id })),
      map(({ data }) => {
        this.store.dispatch(layoutActions.setLoadingPage({ loading: false }));
        if(data?.omnichannel_pipeline_by_pk != undefined){
          this.listidStart = data?.omnichannel_pipeline_by_pk.lists.find(p => p.start == true).id;
          console.log('start', this.listidStart);
        }
        return data?.omnichannel_pipeline_by_pk
      }
      )
    );

    /*this.apollo.subscribe<any>({
      query: SUBSCRIBE_TO_ONLINE_USERS,
      variables: { list_id: "a609722a-da99-4f95-83b0-2e3f75103407" }
    }).subscribe(({ data }) => {
      console.log('got data ', data);
    },
      (error) => {
        console.log('there was an error sending the query', error);
      });*/

    this.activeRoute.params.subscribe(params => {
      this.id = params['id']; // (+) converts string 'id' to a number
      console.log('>>>', this.id);
      // In a real app: dispatch action to load the details here.
    });

    this.ownerid = "all";
  }

  getPrevious($lists, currentIndex) {
    if (currentIndex > 0) {
      return "list_" + $lists[currentIndex - 1].order.toString();
    }
    return null;
  }

  getNext($lists, currentIndex) {
    if (currentIndex < $lists.length - 1) {
      return "list_" + $lists[currentIndex + 1].order.toString();
    }
    return null;
  }

  drop(event: CdkDragDrop<IDeal[]>, status: DealStatus): void {
    if (event.container === event.previousContainer) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      event.item.data.status = status;
    }
  }

  filterOnwer($event:any){
    console.log('filter',$event);
    this.store.dispatch(layoutActions.setSearchContent({ textsearch: $event.value, typeSearch: "onwer" }));
  }

  getTotalPrice(arr: IDeal[]): number {
    const getPrice = (d: IDeal) => d.price;
    const getTotal = (acc, val) => acc + val;

    return 0;//arr.map(getPrice).reduce(getTotal, 0);
  }

  addDeal() {
    const dialogRef = this.dialog.open(CreateDealComponent, {
      width: '435px',
      maxWidth: '91.5%',
      data: { user: this.userSv.users$, list_id: this.listidStart }
    });

    dialogRef.afterClosed().subscribe(deal => deal && this.dealsSv.addDeal(deal));
  }

  editDeal(deal: IDeal) {
    const dialogRef = this.dialog.open(EditDealComponent, {
      width: '435px',
      maxWidth: '91.5%',
      data: {
        users: this.userSv.users$,
        deal
      }
    });

    dialogRef
      .afterClosed()
      .pipe(filter(res => !!res))
      .subscribe(res => {
        this.dealsSv.editDeal(res);
      });
  }

  openDeal(deal: IDeal): void {
    this.dialog.open(OpenedDealComponent, {
      width: '750px',
      maxWidth: '91.5%',
      height: '91vh',
      data: deal,
      autoFocus: false
    });
  }

  deleteDeal(deal: IDeal): void {
    const MSG = 'Are you sure you want to delete this deal?';

    const REF = this.dialog.open(DeleteDialogComponent, {
      width: '91.5%',
      maxWidth: '435px',
      data: MSG
    });

    REF.afterClosed()
      .pipe(filter(res => res))
      .subscribe(() => this.dealsSv.deleteDeal(deal));
  }

  onDragging(): void {
    this.dragging = true;
  }

  onRelease(): void {
    this.dragging = false;
  }
}
