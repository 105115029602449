import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import moment from 'moment'
import { Apollo } from 'apollo-angular';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { HttpLink } from 'apollo-angular/http';
import { onError } from '@apollo/link-error';
import { ApolloClientOptions, ApolloLink, GraphQLRequest, InMemoryCache } from '@apollo/client/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WebSocketLink } from '@apollo/client/link/ws';
import { split } from '@apollo/client/core';
import { getMainDefinition } from '@apollo/client/utilities';

(window as any).global = window;

const auth = "https://donit.io/auth/login";
const callback = "https://atendimento.donit.io";
//const callback = "http://localhost:4200";
import { HttpParams } from '@angular/common/http';

interface Definintion {
    kind: string;
    operation?: string;
};

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    /*auth0 = new auth0.WebAuth({
      clientID: 'TKlskjJRzMQZw6JbuQ0Y8aX9nJnb01t5',
      domain: 'aossie.auth0.com',
      responseType: 'token id_token',
      redirectUri: 'https://hasura-angular-todo.herokuapp.com/',
      scope: 'openid'
    });*/

    getParamValueQueryString(paramName) {
        const url = window.location.href;
        let paramValue;
        if (url.includes('?')) {
            if (url.includes('section')) {
                return undefined;
            }
            const httpParams = new HttpParams({ fromString: url.split('?')[1] });
            paramValue = httpParams.get(paramName);
        }
        return paramValue;
    }

    constructor(public router: Router, public apollo: Apollo, public httpLink: HttpLink, private http: HttpClient, private _snackBar: MatSnackBar) { }

    public login(): void {
        this.authorize();
    }

    public authorize() {
        window.location.href = `${auth}?callback=${callback}`;
    }

    public handleAuthentication(): void {
        let token = localStorage.getItem("access_token");
        let user_id = localStorage.getItem("user_id");
        let expiresAt = localStorage.getItem("expires_at");
        //Get Authorize
        if (this.getParamValueQueryString('access_token') !== undefined && this.getParamValueQueryString('x-user-id') !== undefined) {
            let newToken = this.getParamValueQueryString('token');
            newToken = newToken.replace('"', '').replace('"', '');
            let auth = {
                accessToken: newToken,
                user_id: this.getParamValueQueryString('x-user-id'),
                expires_at: moment().add(86400000, 'ms').toDate()
            }
            this.setSession(auth);
            //this.createApollo();
            window.location.href = "/";
            //this.router.navigate(['/login']);
        } else if (token == undefined && user_id == undefined) {
            this.authorize();
        } else {
            let _date = new Date(expiresAt);
            let currentTime = new Date();
            if (_date > currentTime) {
                //this.createApollo();
                this.router.navigate(['/login']);
            }
            else {
                this.authorize();
            }
        }


        console.log('access_token', this.getParamValueQueryString('token'));
        console.log('x-id', this.getParamValueQueryString('x-user-id'));

        //let expiresAt = moment().add(900000, 'ms').toDate();
        console.log('expires', expiresAt);
        /*this.auth0.parseHash((err, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            window.location.hash = '';
            this.setSession(authResult);
            location.reload();
          } else if (err) {
            this.router.navigate(['/']);
            console.log(err);
          }
        });*/
    }

    public getUserID(): string {
        return localStorage.getItem("user_id")
    }

    public getUserDetails(): any {
        return JSON.parse(localStorage.getItem("user"));
    }

    public changePassword(old_password: string, new_password: string) {
        var pass = { "old_password": old_password, "new_password": new_password };
        const authHeader = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', `Bearer ${localStorage.getItem('access_token')}`)

        let change = this.http.post(`https://backend-a5688c81.nhost.app/auth/change-password/`, pass, { headers: authHeader })
            .subscribe(
                data => this._snackBar.open(`Senha alterada com sucesso.`, "Fechar", {
                    duration: 2000,
                }),
                error => {
                    if (error.error.statusCode == 401) {
                        this._snackBar.open(`Ops: Preencha com a senha atual correta.`, "Fechar", {
                            duration: 2000,
                        });
                    }
                }
            );
    }

    public createUser(email: string, password: string) {
        var pass = { "email": email, "password": password };
        const authHeader = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', `Bearer ${localStorage.getItem('access_token')}`)

        let change = this.http.post(`https://auth.donit.io/auth/register`, pass, { headers: authHeader })
            .subscribe(
                data => this._snackBar.open(`Usuário cadastrado com sucesso.`, "Fechar", {
                    duration: 2000,
                }),
                error => {
                    this._snackBar.open(`Ops: Problema ao cadastrar o usuário.`, "Fechar", {
                        duration: 2000,
                    });
                }
            );
    }

    public createApollo() {
        const uri = 'https://server.donit.io/v1/graphql';

        const authHeader = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', `Bearer ${localStorage.getItem('access_token')}`)

        const http = this.httpLink.create({ uri, headers: authHeader });

        // Create a WebSocket link:
        const ws = new WebSocketLink({
            uri: `wss://server.donit.io/v1/graphql`,
            options: {
                lazy: true,
                reconnect: true,
                connectionParams: {
                    headers: authHeader
                }
            },
        });

        const link = split(
            // split based on operation type
            ({ query }) => {
                const { kind, operation }: Definintion = getMainDefinition(query);
                return (
                    kind === 'OperationDefinition' && operation === 'subscription'
                );
            },
            ws,
            http,
        );

        const errorLink = onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors && graphQLErrors.length > 0 && graphQLErrors[0].message === "Could not verify JWT: JWTExpired") {
                this.authorize();
            }
            console.log('Network', networkError)
        });

        this.apollo.create({
            link: ApolloLink.from([errorLink, link]),
            cache: new InMemoryCache()
        });
    }

    private setSession(authResult): void {
        //const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('user_id', authResult.user_id);
        localStorage.setItem('expires_at', authResult.expires_at);
    }

    public logout(): void {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('expires_at');
        location.reload();
        this.authorize();
    }

    public isAuthenticated(): boolean {
        let expiresAt = localStorage.getItem("expires_at");
        let _date = new Date(expiresAt);
        let currentTime = new Date();
        if (_date > currentTime) {
            return true;
        }
        else {
            this.authorize();
        }
    }
}