import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IState } from '../../../interfaces/state';
import { Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as layoutActions from '../../../store/actions/layout.actions';

import {
  UpdateNotificationGQL
} from '../../../../generated/graphql'

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['../account/account.component.scss', './notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  @Input() settings: any;
  form: FormGroup;
  public mask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];

  constructor(private updateNotification: UpdateNotificationGQL,
    private store: Store<IState>,
    private _snackBar: MatSnackBar,) { }

  ngOnInit() {
    this.form = new FormGroup({
      browertab: new FormControl(this.settings[0].browertab, [Validators.required]),
      email_notification: new FormControl(this.settings[0].email_notification),
      celular: new FormControl(this.settings[0].celular),
      disable_not_to: new FormControl(this.settings[0].disable_not_to),
      disable_not_from: new FormControl(this.settings[0].disable_not_from),
      enable_celular: new FormControl(this.settings[0].enable_celular, [Validators.required]),
      enable_email: new FormControl(this.settings[0].enable_email, [Validators.required]),
      enable_lead: new FormControl(this.settings[0].enable_lead, [Validators.required]),
      disable_notification: new FormControl(this.settings[0].disable_notification, [Validators.required]),
    });
  }

  onSave() {
    if (this.form.invalid) {
      return;
    }
    this.store.dispatch(layoutActions.setLoadingPage({ loading: true }));
    let _save = this.form.value;
    _save["user_id"] = localStorage.getItem("user_id");

    this.updateNotification.mutate(_save).subscribe(v => {
      this.store.dispatch(layoutActions.setLoadingPage({ loading: false }));
      this._snackBar.open(`Preferências de notificação alterada com sucesso`, "Fechar", {
        duration: 2000,
      });
    });
  }
}
