import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-recipients-dialog',
  templateUrl: './recipients-dialog.component.html',
  styleUrls: ['./recipients-dialog.component.scss']
})
export class RecipientsDialogComponent implements OnInit {
  filterInput: FormControl;
  recipientFilter$: Observable<string>;
  filteredRecipients$: Observable<string[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private recipients$: Observable<string[]>,
    private dialogRef: MatDialogRef<RecipientsDialogComponent>
  ) {}

  ngOnInit() {
    this.filterInput = new FormControl('');
    this.recipientFilter$ = this.filterInput.valueChanges.pipe(startWith(this.filterInput.value));
    this.filteredRecipients$ = combineLatest(this.recipients$, this.recipientFilter$).pipe(
      distinctUntilChanged(),
      debounceTime(200),
      map(([recipients, filter]) => recipients.filter(item => item.includes(filter)))
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
