import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'app-sidebar-toggle',
  templateUrl: './side-bar-toggle.component.html',
  styleUrls: ['./side-bar-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideBarToggleComponent implements OnInit {
  @HostBinding('class.side-bar-toggle') true;
  @HostBinding('class.active') get activeState() {
    return this.state;
  }

  @Output() stateChange: EventEmitter<boolean>;

  state: boolean;
  constructor() {
    this.stateChange = new EventEmitter();
  }

  ngOnInit() {
    this.state = JSON.parse(localStorage.getItem('toggleSidebar')) || false;
    this.stateChange.emit(this.state);
  }

  @HostListener('click')
  toggle(): void {
    this.state = !this.state;
    this.stateChange.emit(this.state);
  }
}
