<div class="desktop-tabs">
  <div
    class="tab"
    *ngFor="let tab of settingsTabs"
    (click)="setSection(tab.value)"
    [ngClass]="{ active: displayedSection === tab.value }"
  >
    <div class="icon">
      <mat-icon class="svg-icon">{{ tab.icon }}</mat-icon>
    </div>
    <div class="text">
      <h3 class="title">{{ tab.title }}</h3>
      <span class="desc">{{ tab.desc }}</span>
    </div>
  </div>
</div>

<div class="tablet-tabs">
  <mat-tab-group class="tabs settings-tabs">
    <mat-tab
      *ngFor="let tab of settingsTabs"
      [ngClass]="{ active: displayedSection === tab.value }"
    >
      <ng-template mat-tab-label>
        <div class="settings-tab" (click)="setSection(tab.value)">
          <div class="icon">
            <mat-icon class="svg-icon">{{ tab.icon }}</mat-icon>
          </div>
          <div class="text">
            <h3 class="title">{{ tab.title }}</h3>
            <span class="desc">{{ tab.desc }}</span>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="displayed-section" *ngIf="settings$ | async as settings" [ngSwitch]="displayedSection">
  <ng-container *ngSwitchCase="'account'">
    <app-account [settings]="settings"></app-account>
  </ng-container>

  <ng-container *ngSwitchCase="'notifications'">
    <app-notifications [settings]="settings"></app-notifications>
  </ng-container>

  <ng-container *ngSwitchCase="'integrations'">
    <app-integrations></app-integrations>
  </ng-container>

  <ng-container *ngSwitchCase="'user'">
    <app-user></app-user>
  </ng-container>

  <ng-container *ngSwitchCase="'tags'">
    <app-tags></app-tags>
  </ng-container>
</div>
