<div class="header">
  <h4 mat-dialog-title class="mb-0">Criar um novo negócio</h4>
  <mat-icon (click)="closeDialog()">close</mat-icon>
</div>

<mat-dialog-content class="content">
  <div class="statuses">
    <tc-badge
      *ngFor="let type of pipelineStatus$ | async"
      [ngClass]="{ active: selectedType === type.id }"
      (click)="selectedType = type.id"
      [view]="type.color"
    >
      {{ type.name }}
    </tc-badge>
  </div>

  <form [formGroup]="dealForm" class="task-form">

    <mat-form-field>
      <mat-label>Produtos</mat-label>
      <mat-select formControlName="products" multiple (selectionChange)="addProducts($event.value)">
        <mat-option *ngFor="let product of products$ | async" [value]="product">{{product.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngFor="let item of qtdProduct">
      <mat-label>Quantidade de Produtos - {{item.title}}</mat-label>
      <input matInput type='number' placeholder="Entre com a quantidade de produtos" [formControlName]="item.name" />
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-label>Contato</mat-label>
      <input type="text"
             placeholder="Selecione ou informe um contato"
             matInput
             formControlName="person_id" 
             [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of options" [value]="option.name" (click)="OnSelectPerson(option)">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field *ngIf="openPerson" [appearance]="!(newPerson) && 'none'">
      <mat-label>Nome</mat-label>
      <input [readonly]="!newPerson" matInput placeholder="Nome do contato" formControlName="name_person" />
      <ng-container *ngIf="name_person.invalid && (name_person.touched || name_person.dirty)">
        <mat-error @fadeIn>Entre com um nome para o contato</mat-error>
      </ng-container>
    </mat-form-field>

    <mat-form-field *ngIf="openPerson" [appearance]="!(newPerson) && 'none'">
      <mat-label>Tipo do contato</mat-label>
      <mat-select formControlName="ptype">
        <mat-option *ngFor="let item of person_types$ | async" [value]="item.id">{{item.name}}</mat-option>
      </mat-select>
      <ng-container *ngIf="ptype.invalid && (ptype.touched || ptype.dirty)">
        <mat-error @fadeIn>Selecione um tipo de contato</mat-error>
      </ng-container>
    </mat-form-field>

    <mat-form-field *ngIf="openPerson" [appearance]="!(newPerson) && 'none'">
      <mat-label>E-mail</mat-label>
      <input  [readonly]="!newPerson" matInput placeholder="E-mail do contato" formControlName="email" />
    </mat-form-field>

    <mat-form-field *ngIf="openPerson" [appearance]="!(newPerson) && 'none'">
      <mat-label>Telefone</mat-label>
      <input  [readonly]="!newPerson" matInput placeholder="Telefone do contato" [textMask]="{mask: mask}" formControlName="phone" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>Título</mat-label>
      <input matInput placeholder="Título" formControlName="title" />
      <ng-container *ngIf="title.invalid && (title.touched || title.dirty)">
        <mat-error @fadeIn>Entre com um Título</mat-error>
      </ng-container>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Data de fechamento esperada</mat-label>
      <input matInput [matDatepicker]="picker" placeholder="Selecione uma data" formControlName="date_close"/>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>

      <ng-container *ngIf="closeDate.invalid && (closeDate.touched || closeDate.dirty)">
        <mat-error @fadeIn>Selecione uma possível data de fechamento</mat-error>
      </ng-container>
    </mat-form-field>
  </form>
</mat-dialog-content>

<button (click)="submitDeal()" tc-button [block]="true">Criar um novo négocio</button>
