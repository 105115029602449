import { IUser } from './user';
import { IAttachment, IComment } from './deal';

enum Styles {
  active = 'yellow',
  completed = 'green',
  ended = 'red'
}

export interface ITask {
  id: string,
  title: string;
  dueDate: string;
  user: IUser;
  status: TaskStatuses;
  type: TaskTypes;
  description: string;
  attachments: IAttachment[];
  comments: IComment[];
}

export enum TaskStatuses {
  active = 'Active',
  completed = 'Completed',
  ended = 'Ended'
}

export enum TaskTypes {
  all = 'All',
  reminder = 'Reminder',
  call = 'Call',
  event = 'Event'
}

export enum TaskStyles {
  active = 'yellow',
  completed = 'green',
  ended = 'red'
}

export const BadgeStyles = {
  [TaskStatuses.completed]: Styles.completed,
  [TaskStatuses.active]: Styles.active,
  [TaskStatuses.ended]: Styles.ended
};
