import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IChat, IMessage, MessageStatus } from '../../../../interfaces/chat';
import { ChatService } from '../../../../services/chat.service';
import { IUser } from '../../../../interfaces/user';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatListComponent implements OnInit {
  @Input() chatList: IChat[];
  @Input() userId: any;

  selectedChat$: Observable<IChat>;

  constructor(private chatSv: ChatService) {
    this.chatList = [];
  }

  ngOnInit() {
    this.selectedChat$ = this.chatSv.selectedChat$;
  }

  getLastMessage(chat: IChat): IMessage {
    const MESSAGES_LAST = chat.messages.length - 1;
    return chat.messages[MESSAGES_LAST];
  }

  getChatTitle(chat: IChat): string {
    let title = chat.title;

    if (!title) {
      title = this.getMembersName(chat.members);
    }

    return title;
  }

  getMembersName(members: IUser[]): string {
    const notUser = (member: IUser) => member.id !== this.userId;
    const getNames = (user: IUser) => user.name;
    const reduceNames = (chat, user) => `${chat}, ${user}`;

    return members
      .filter(notUser)
      .map(getNames)
      .reduce(reduceNames)
      .slice(0, -1);
  }

  getChatAvatar(chat: IChat): string {
    let avatar = chat.avatar;

    if (!avatar) {
      avatar = this.getMembersAvatar(chat);
    }
    return avatar;
  }

  getMembersAvatar(chat: IChat): string {
    const notUser = (member: IUser) => member.id !== this.userId;
    return chat.members.find(notUser).avatar;
  }

  getUnreadMessages(chat: IChat): number {
    const getUnread = (msg: IMessage) => msg.status === MessageStatus.unread;
    return chat.messages.filter(getUnread).length;
  }

  onSelectChat(chat: IChat): void {
    this.chatSv.selectChat(chat);
  }

  deselectChat(): void {
    this.chatSv.deselectChat();
  }
}
