import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IState } from '../../interfaces/state';

import { ITask } from '../../interfaces/task';
import { HttpService } from '../../services/http.service';
import { TasksService } from '../../services/tasks.service';

import echarts, { EChartOption } from 'echarts';
import * as layoutActions from '../../store/actions/layout.actions';
import { take } from 'rxjs/operators';


@Component({
  selector: 'page-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class PageDashboardComponent implements OnInit, OnDestroy {
  tasks$: Observable<ITask[]>;

  lineOptions: EChartOption;
  pieOptions: EChartOption;
  tasksSelect: FormControl;
  dealsSelect: FormControl;

  constructor(
    private httpSv: HttpService,
    private store: Store<IState>,
    private tasksSv: TasksService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(layoutActions.setPageTitle({ title: 'Dashboard' }));
    this.store.dispatch(layoutActions.setPagePlaceholder({ placeholder: 'Global search' }));

    this.tasks$ = this.tasksSv.tasks$;

    this.setLineOptions();
    this.setPieOptions();

    this.tasksSelect = new FormControl('This month');
    this.dealsSelect = new FormControl('Monthly');
  }

  ngOnDestroy(): void {
    this.store.dispatch(layoutActions.resetPageData());
  }

  setLineOptions() {
    this.lineOptions = {
      color: ['#109CF1'],
      boundaryGap: true,
      grid: {
        left: '30px',
        right: 0,
        top: '15px',
        bottom: '30px'
      },
      xAxis: {
        boundaryGap: false,
        show: true,
        type: 'category',
        data: ['1 Dec', '', '8 Dec', '', '16 Dec', '', '31 Dec', '']
      },
      yAxis: {
        show: true,
        boundaryGap: false,
        axisLabel: {
          align: 'left',
          inside: false,
          margin: 30
        },
        axisTick: {
          interval: 50
        },
        axisLine: {
          show: false
        },
        splitLine: {
          lineStyle: {
            type: 'dashed'
          }
        }
      },
      tooltip: {
        trigger: 'axis'
      },
      series: [
        {
          name: 'Activity',
          type: 'line',
          data: [50, 100, 105, 150, 50, 90, 150, 140, 160, 200],
          smooth: true,
          symbol: 'none',
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(16,156,241,0.3)'
                },
                {
                  offset: 0.6,
                  color: 'rgba(16,156,241,0.2)'
                },
                {
                  offset: 1,
                  color: 'rgba(16,156,241,0.05)'
                }
              ])
            }
          }
        }
      ]
    };
  }

  setPieOptions() {
    this.pieOptions = {
      color: ['#FFB946', '#2ED47A', '#F7685B'],
      series: [
        {
          startAngle: 230,
          hoverAnimation: false,
          type: 'pie',
          radius: ['98%', '93%'],
          label: {
            show: false
          },
          data: [{ value: 225 }, { value: 500 }, { value: 125 }]
        }
      ]
    };
  }
}
