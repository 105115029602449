import { Injectable } from '@angular/core';

import { HttpService } from './http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { IEmail } from '../interfaces/email';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  private emails: IEmail[];
  private emailsUrl: string;

  emailSubject: BehaviorSubject<IEmail[]>;
  emails$: Observable<IEmail[]>;

  constructor(private httpSv: HttpService) {
    this.emailSubject = new BehaviorSubject<IEmail[]>([]);
    this.emails$ = this.emailSubject.asObservable();
    this.emailsUrl = '../../assets/data/emails.json';
    this.fetchEmails();
  }

  private fetchEmails(): void {
    this.httpSv
      .getData<IEmail[]>(this.emailsUrl)
      .pipe(tap(emails => (this.emails = emails)))
      .subscribe(emails => this.emailSubject.next(emails));
  }

  deleteEmail(email: IEmail): void {
    const EMAILS = this.emailSubject.value.filter(_email => _email.id !== email.id);
    console.log(EMAILS);
    this.emailSubject.next([...EMAILS]);
  }

  getEmails(): IEmail[] {
    return this.emails;
  }
}
