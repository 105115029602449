<div class="top-bar">
  <div class="selects">
    <div class="d-flex align-items-center header-select-wrap">
      <span class="label mr-2">Date:</span>
      <mat-form-field class="select" [appearance]="'none'">
        <mat-select [formControl]="dateSelect">
          <mat-option [value]="'All'">All</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="d-flex align-items-center header-select-wrap">
      <span class="label mr-2">Type:</span>
      <mat-form-field class="select" [appearance]="'none'">
        <mat-select [formControl]="typeSelect">
          <mat-option [value]="'All'">All</mat-option>
          <mat-option [value]="'Reminder'">Reminder</mat-option>
          <mat-option [value]="'Call'">Call</mat-option>
          <mat-option [value]="'Event'">Event</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-icon class="search">search</mat-icon>
  </div>

  <button class="create-task-btn" type="button" tc-button (click)="createTask()">
    Create task
  </button>
</div>

<table mat-table class="tasks-table" [dataSource]="tasks$ | async">
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <ng-container *ngIf="selectedData.isEmpty()">
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selectedData.hasValue() && isSelected()"
        >
        </mat-checkbox>
      </ng-container>

      <ng-container *ngIf="selectedData.hasValue()">
        <div class="counter" (click)="masterToggle()">
          {{ selectedData.selected.length }}
        </div>
      </ng-container>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? selectedData.toggle(row) : null"
        [checked]="selectedData.isSelected(row)"
      >
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>
      <ng-container *ngIf="!selectedData.hasValue()">
        Status
      </ng-container>

      <div class="selected" *ngIf="selectedData.hasValue()">
        {{ selectedData.selected.length }} selected
      </div>
    </th>
    <td mat-cell class="status-td" *matCellDef="let task">
      <tc-badge [view]="badgeViews[task.status]"> {{ task.status }}</tc-badge>
    </td>
  </ng-container>

  <!-- Status Column -->
  <ng-container matColumnDef="title">
    <th mat-header-cell class="actions-th" *matHeaderCellDef>
      <ng-container *ngIf="!selectedData.hasValue()">
        Title
      </ng-container>

      <ng-container *ngIf="selectedData.hasValue()">
        <div class="actions">
          <tc-circle-badge *ngFor="let badge of badgeArr" [view]="badge"></tc-circle-badge>

          <mat-icon class="action">edit</mat-icon>
          <mat-icon (click)="onDeleteTask()" class="action delete-btn">delete</mat-icon>
        </div>
      </ng-container>
    </th>
    <td mat-cell class="title-td" *matCellDef="let task">{{ task.title }}</td>
  </ng-container>

  <!-- Type Column -->
  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef>
      <ng-container *ngIf="!selectedData.hasValue()">
        Type
      </ng-container>
    </th>
    <td mat-cell *matCellDef="let task">{{ task.type }}</td>
  </ng-container>

  <!-- User Column -->
  <ng-container matColumnDef="user">
    <th mat-header-cell *matHeaderCellDef>
      <ng-container *ngIf="!selectedData.hasValue()">
        Associated with
      </ng-container>
    </th>
    <td mat-cell class="user-td" *matCellDef="let task">
      <img [src]="task.user.avatar" [alt]="task.user.name" class="user-img" />
      <span class="user-label"> {{ task.user.name }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="dueDate">
    <th mat-header-cell *matHeaderCellDef>
      <ng-container *ngIf="!selectedData.hasValue()">
        Due date
      </ng-container>
    </th>
    <td mat-cell *matCellDef="let task">{{ task.dueDate }}</td>
  </ng-container>

  <tr class="header-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    class="table-row"
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    [ngClass]="{ selected: selectedData.isSelected(row) }"
    (click)="selectedData.toggle(row)"
  ></tr>
</table>
