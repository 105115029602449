import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';

import { IActivity, IContact, IContactInfo } from '../../../interfaces/contact';
import { DeleteDialogComponent } from '../delete-dialog';
import { filter } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FateControllerService } from 'fate-editor';

//GRAPH
import { ContactsService } from '../../../services/contacts.service';

import { TagsService } from '../../../services/tags.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { ITagGroup } from '../../../interfaces/tag';
import FroalaEditor from 'froala-editor';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IState } from '../../../interfaces/state';
import { Store } from '@ngrx/store';
import * as layoutActions from '../../../store/actions/layout.actions';
import dayjs from 'dayjs'

import {
  SendEmailGQL,
  GetActivitiesPersonGQL,
  Insert_Person_NoteGQL
} from '../../../../generated/graphql'

@Component({
  selector: 'app-contact-view',
  templateUrl: './contact-view.component.html',
  styleUrls: ['./contact-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactViewComponent implements OnInit {
  //@Input() contact: IContact;

  private _contact: any;
  @Input() set contact(value: any) {
    this._contact = value;
    this.tagsValuesOptions = value.tags;
    this.buildFields(this.formSchema);
  }

  get contact(): any {
    return this._contact.omnichannel_persons_by_pk || this._contact;
  }

  @Input() creating: boolean;
  @Input() person_id: string;

  @Output() back: EventEmitter<void>;
  @Output() delete: EventEmitter<string>;
  @Output() create: EventEmitter<IContact>;
  @Output() createNote: EventEmitter<any>;

  contactInfo: IContactInfo;
  infoFields: string[];
  activities: IActivity[];
  person_activity$: Observable<IActivity[]>;
  private person_activityTypesSource: BehaviorSubject<IActivity[]>;
  editing: boolean;
  public mask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
  editNota: String = "Insira aqui sua nota...";
  tagsOptions: any = [];
  tagsValuesOptions: any = [];

  bodyEmail: string = "Digite aqui o corpo do seu e-mail";
  subject: string;

  modules = {
    formula: true,
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      ["image", "code-block"]
    ]
  };

  public options: Object = {
    charCounterCount: true,
    "toolbarInline": true,
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat'],
  };

  //Forms
  contactForm: FormGroup;
  contactLabels: any = {};
  submitted = false;
  formSchema = [
    { key: 'name', value: '', validators: [Validators.required], label: "Nome", type: "text" },
    { key: 'email', value: '', validators: [Validators.email], label: "E-mail", type: "text" },
    { key: 'phone', value: '', validators: [], label: "Telefone", type: "tel" },
    {
      key: 'person_type', value: '', validators: [], label: "Tipo do contato", type: "select",
      options: []
    },
    { key: 'whatsapp', value: '', validators: [], label: "Whatsapp", type: "tel" },
    { key: 'address', value: '', validators: [], label: "Endereço", type: "text" },
    { key: 'number', value: '', validators: [], label: "Número/Complemento", type: "text" },
    { key: 'city', value: '', validators: [], label: "Cidade", type: "text" },
    { key: 'state', value: '', validators: [], label: "Estado", type: "text" },
    { key: 'cpf_cnpj', value: '', validators: [], label: "CPF/CNPJ", type: "text" },
    { key: 'companyName', value: '', validators: [], label: "Empresa", type: "text" },
    { key: 'website', value: '', validators: [], label: "Site", type: "text" },
    { key: 'facebook', value: '', validators: [], label: "Facebook", type: "text" },
    { key: 'instagram', value: '', validators: [], label: "Instagram", type: "text" },
  ];

  constructor(private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private contactSv: ContactsService,
    fateControllerService: FateControllerService,
    private tagSv: TagsService,
    private sendEmail: SendEmailGQL,
    private getActivitiesPerson: GetActivitiesPersonGQL,
    private insertNote: Insert_Person_NoteGQL,
    private _snackBar: MatSnackBar,
    private store: Store<IState>,) {
    this.back = new EventEmitter<void>();
    this.delete = new EventEmitter<string>();
    this.create = new EventEmitter<IContact>();
    this.createNote = new EventEmitter<any>();

    this.contact = {
      id: '_',
      instagram: '',
      facebook: '',
      website: '',
      cpf_cnpj: '',
      email: '',
      companyName: '',
      name: '',
      address: '',
      recentActivity: '',
      activity: null,
      phone: '',
      avatar: '',
      number: '',
      active: true,
      whatsapp: '',
      person_type: 0,
      organization_id: 0,
      owner_id: ''
    };
  }

  tags = [
    {
      "view": "yellow",
      "text": "Active"
    },
    {
      "view": "green",
      "text": "Completed"
    },
    {
      "view": "red",
      "text": "Ended"
    }
  ];

  selectOptions = [
    {
      text: 'Admin',
      value: 'admin',
      view: 'dark-blue'
    },
    {
      text: 'Read',
      value: 'read',
      view: 'gray'
    },
    {
      text: 'Edit',
      value: 'edit',
      view: 'yellow'
    }
  ];

  mounthSTR: any = {
    "Jan": "Janeiro",
    "Feb": "Fevereiro",
    "Mar": "Março"
  }

  iconService = {

  }

  editor:any;

  ngOnInit() {
    this.person_activityTypesSource = new BehaviorSubject([]);
    this.person_activity$ = this.person_activityTypesSource.asObservable();
    this.editor = document.getElementById('editor');
    /*this.activities = [
      {
        date: 'December 2019',
        tasks: [
          {
            title: 'You made a call to Jane Doe',
            date: 'Monday 3 at 14:30 EST',
            icon: 'call'
          },
          {
            title: 'Completed task <span class="color-accent">Office  meet-up</span>',
            date: 'Saturday 1 at 9:40 EST',
            icon: 'check'
          }
        ]
      },
      {
        date: 'October 2019',
        tasks: [
          {
            title: 'You made a call to Jane Doe',
            date: 'Monday 3 at 14:30 EST',
            icon: 'call'
          },
          {
            title: 'Jane Doe opened email: <span class="color-accent">New rules</span>',
            date: 'Friday 30 at 9:40 EST',
            icon: 'email'
          },
          {
            title: 'Completed task <span class="color-accent">Office  meet-up</span> ',
            date: 'Saturday 1 at 9:40 EST',
            icon: 'check'
          }
        ]
      }
    ];*/

    FroalaEditor.DefineIcon('alert', { NAME: 'info' });
    FroalaEditor.RegisterCommand('alert', {
      title: 'Hello',
      focus: false,
      undo: false,
      refreshAfterCallback: false,

      callback: () => {
        alert('Hello!');
      }
    });

    this.buildFields(this.formSchema);
  }

  iBold(format: string) {
    document.execCommand(format, false, null); // toggles bold formatting
  }

  clear() {
    document.execCommand("RemoveFormat") // toggles bold formatting
  }

  buildFields(_form: any[]) {
    this.contactForm = this.formBuilder.group({
      id: [null]
    });

    for (const key in _form) {
      if (Object.prototype.hasOwnProperty.call(_form, key)) {
        const field = _form[key];
        if (field.key === 'person_type') {
          field.options = this.contactSv.person_type$;
          this.contactSv.getPersonTypes()
        }
        this.contactLabels[field.key] = field.label;
        this.contactForm.addControl(field.key, new FormControl(this.contact[field.key], field.validators));
      }
    }

    this.loadActivity();
  }

  loadActivity() {
    if (this._contact.omnichannel_persons_by_pk != undefined) {
      let _getActivity = this.getActivitiesPerson.subscribe({ person_id: this.person_id });
      _getActivity
        .subscribe(({ data }) => {
          this.activities = [];
          let mounth = "";
          let i = -1;
          for (const key in data.omnichannel_vw_activities_group) {
            if (Object.prototype.hasOwnProperty.call(data.omnichannel_vw_activities_group, key)) {
              const element = data.omnichannel_vw_activities_group[key];
              if (element.mon != mounth) {
                let _date = `${this.mounthSTR[element.mon]} ${element.yyyy}`;
                this.activities.push({ date: _date, tasks: [] })
                i++;
                mounth = element.mon;
              }

              let title = element.acitvity.description;
              let icon = 'call';

              if (element.acitvity.person_note_id != null) {
                title = element.acitvity.note.description;
                icon = 'chat_bubble_outline'
              }

              this.activities[i].tasks.push({
                user: element.acitvity.owner.display_name,
                title: title,
                date: this.getDate(element.acitvity.created_at),
                icon: icon
              });
            }
          }
          this.person_activityTypesSource.next(this.activities);
        });
    }
  }

  getDate(value: string): string {
    return dayjs(value).format('DD/MM/YYYY HH:mm');
  }

  onDelete(): void {
    const REF = this.dialog.open(DeleteDialogComponent, {
      width: '91.5%',
      maxWidth: '435px',
      data: 'Are you sure you want to delete this contact?'
    });

    REF.afterClosed()
      .pipe(filter(res => res))
      .subscribe(() => this.delete.emit(this.contact.id));
  }

  onClose(): void {
    console.log('tag was closed.');
  }

  preventDefault(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    console.log('tag can not be closed.');
  }

  label(key: string) {
    return this.contactLabels[key] || '';
  }

  debug(values: any) {
    console.log(values);
  }

  onCreateTag($event) {
    this.contactSv.insertTags(this._contact.id, $event);
  }

  onDeleteTag($event) {
    this.contactSv.deleteTags(this._contact.id, $event);
  }

  setInfo(contact: IContact): any[] {
    console.log('edit', contact)
    const [FIRST_NAME, LAST_NAME] = contact.name !== '' ? contact.name.split(' ') : ['', ''];
    const INFO = {
      Nome: contact.name,
      'E-mail': contact.email,
      Telefone: contact.phone,
      Whatsapp: contact.whatsapp,
      Endereço: contact.address,
      'Número/Complemento': contact.number,
      'CPF/CNPJ': contact.cpf_cnpj,
      Empresa: contact.companyName,
      Site: contact.website,
      Facebook: contact.facebook,
      Instagram: contact.instagram,
    };

    return [INFO, Object.keys(INFO)];
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.contactForm.invalid) {
      return;
    }

    let _dataSave = this.contactForm.value;
    _dataSave["avatar"] = `https://ui-avatars.com/api/?name=${_dataSave.name}`;
    this.create.emit(this.contactForm.value);
  }

  edit(): void {
    if (!this.creating) {
      this.editing = !this.editing;
    }
  }

  onCreateNote(): void {
    this.createNote.emit(this.editNota);
    this.editNota = "";
  }

  onSendEmail(): void {

    if (!this.subject || this.subject == "") {
      this._snackBar.open(`Informe o assunto do e-mail.`, "Fechar", {
        duration: 2000,
      });
      return;
    }

    if (!this.bodyEmail || this.bodyEmail == "") {
      this._snackBar.open(`Escreva um e-mail.`, "Fechar", {
        duration: 2000,
      });
      return;
    }

    if (!this._contact.omnichannel_persons_by_pk.email || this._contact.omnichannel_persons_by_pk.email == "") {
      this._snackBar.open(`Ops. Este contato não contém e-mail cadastro.`, "Fechar", {
        duration: 2000,
      });
      return;
    }

    if (!this._contact.omnichannel_addons_connects || this._contact.omnichannel_addons_connects.length == 0) {
      this._snackBar.open(`Nenhuma conta foi configurada pare envio de e-mail.`, "Fechar", {
        duration: 2000,
      });
      return;
    }

    let addonEmailId = this._contact.omnichannel_addons_connects.find(p => p.addons_id == "eaa9b2c0-dd80-4de5-bd9d-0ceaf909a572");
    let addressFrom = addonEmailId.settings.find(p => p.name == "address");
    let nameFrom = addonEmailId.settings.find(p => p.name == "name");

    let _dataSave = {
      account_id: addonEmailId.id,
      address: addressFrom.value,
      name: nameFrom.value,
      html: this.bodyEmail,
      subject: this.subject,
      address1: this._contact.omnichannel_persons_by_pk.email,
      name1: this._contact.omnichannel_persons_by_pk.name
    };

    this.sendEmail.mutate(_dataSave).subscribe(v => {
      this.store.dispatch(layoutActions.setLoadingPage({ loading: false }));
      this._snackBar.open(`E-mail enviado com sucesso.`, "Fechar", {
        duration: 2000,
      });
      this.bodyEmail = "";
      this.subject = "";
    });
  }

  onBack(): void {
    this.back.emit();
  }
}
