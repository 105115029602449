<mat-card>
  <mat-card-header>
    <mat-card-title>Action icons</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-icon>3d_rotation</mat-icon>
    <mat-icon>accessibility</mat-icon>
    <mat-icon>accessible</mat-icon>
    <mat-icon>account_balance</mat-icon>
    <mat-icon>account_balance_wallet</mat-icon>
    <mat-icon>account_box</mat-icon>
    <mat-icon>account_circle</mat-icon>
    <mat-icon>add_shopping_cart</mat-icon>
    <mat-icon>alarm</mat-icon>
    <mat-icon>alarm_add</mat-icon>
    <mat-icon>alarm_off</mat-icon>
    <mat-icon>alarm_on</mat-icon>
    <mat-icon>all_out</mat-icon>
    <mat-icon>android</mat-icon>
    <mat-icon>announcement</mat-icon>
    <mat-icon>aspect_ratio</mat-icon>
    <mat-icon>assessment</mat-icon>
    <mat-icon>assignment</mat-icon>
    <mat-icon>assignment_ind</mat-icon>
    <mat-icon>assignment_late</mat-icon>
    <mat-icon>assignment_return</mat-icon>
    <mat-icon>assignment_returned</mat-icon>
    <mat-icon>assignment_turned_in</mat-icon>
    <mat-icon>autorenew</mat-icon>
    <mat-icon>backup</mat-icon>
    <mat-icon>book</mat-icon>
    <mat-icon>bookmark</mat-icon>
    <mat-icon>bookmark_outline</mat-icon>
    <mat-icon>bug_report</mat-icon>
    <mat-icon>build</mat-icon>
    <mat-icon>cached</mat-icon>
    <mat-icon>camera_enhanced</mat-icon>
    <mat-icon>card_giftcard</mat-icon>
    <mat-icon>card_membership</mat-icon>
    <mat-icon>card_travel</mat-icon>
    <mat-icon>change_history</mat-icon>
    <mat-icon>check_circle</mat-icon>
    <mat-icon>chrome_reader_mode</mat-icon>
    <mat-icon>class</mat-icon>
    <mat-icon>code</mat-icon>
    <mat-icon>compare_arrows</mat-icon>
    <mat-icon>copyright</mat-icon>
    <mat-icon>credit_card</mat-icon>
    <mat-icon>dashboard</mat-icon>
    <mat-icon>date_range</mat-icon>
    <mat-icon>delete</mat-icon>
    <mat-icon>delete_forever</mat-icon>
    <mat-icon>description</mat-icon>
    <mat-icon>dns</mat-icon>
    <mat-icon>done</mat-icon>
    <mat-icon>done_all</mat-icon>
    <mat-icon>donut_large</mat-icon>
    <mat-icon>donut_small</mat-icon>
    <mat-icon>eject</mat-icon>
    <mat-icon>euro_symbol</mat-icon>
    <mat-icon>event</mat-icon>
    <mat-icon>event_seat</mat-icon>
    <mat-icon>exit_to_app</mat-icon>
    <mat-icon>explore</mat-icon>
    <mat-icon>extension</mat-icon>
    <mat-icon>face</mat-icon>
    <mat-icon>favorite</mat-icon>
    <mat-icon>favorite_border</mat-icon>
    <mat-icon>feedback</mat-icon>
    <mat-icon>find_in_page</mat-icon>
    <mat-icon>find_replace</mat-icon>
    <mat-icon>fingerprint</mat-icon>
    <mat-icon>flight_land</mat-icon>
    <mat-icon>flight_takeoff</mat-icon>
    <mat-icon>flip_to_back</mat-icon>
    <mat-icon>flip_to_front</mat-icon>
    <mat-icon>g_translate</mat-icon>
    <mat-icon>gavel</mat-icon>
    <mat-icon>get_app</mat-icon>
    <mat-icon>gif</mat-icon>
    <mat-icon>grade</mat-icon>
    <mat-icon>group_work</mat-icon>
    <mat-icon>help</mat-icon>
    <mat-icon>help_outline</mat-icon>
    <mat-icon>highlight_off</mat-icon>
    <mat-icon>history</mat-icon>
    <mat-icon>home</mat-icon>
    <mat-icon>hourglass_empty</mat-icon>
    <mat-icon>hourglass_full</mat-icon>
    <mat-icon>http</mat-icon>
    <mat-icon>https</mat-icon>
    <mat-icon>important_devices</mat-icon>
    <mat-icon>info</mat-icon>
    <mat-icon>info_outline</mat-icon>
    <mat-icon>input</mat-icon>
    <mat-icon>invert_colors</mat-icon>
    <mat-icon>label</mat-icon>
    <mat-icon>label_outline</mat-icon>
    <mat-icon>language</mat-icon>
    <mat-icon>launch</mat-icon>
    <mat-icon>lightbulb_outline</mat-icon>
    <mat-icon>line_style</mat-icon>
    <mat-icon>line_weight</mat-icon>
    <mat-icon>list</mat-icon>
    <mat-icon>lock</mat-icon>
    <mat-icon>lock_open</mat-icon>
    <mat-icon>lock_outline</mat-icon>
    <mat-icon>loyalty</mat-icon>
    <mat-icon>markunread_mailbox</mat-icon>
    <mat-icon>motorcycle</mat-icon>
    <mat-icon>note_add</mat-icon>
    <mat-icon>offline_pin</mat-icon>
    <mat-icon>opacity</mat-icon>
    <mat-icon>open_in_browser</mat-icon>
    <mat-icon>open_in_new</mat-icon>
    <mat-icon>open_with</mat-icon>
    <mat-icon>pageview</mat-icon>
    <mat-icon>pan_tool</mat-icon>
    <mat-icon>payment</mat-icon>
    <mat-icon>perm_camera_mic</mat-icon>
    <mat-icon>perm_contact_calendar</mat-icon>
    <mat-icon>perm_data_setting</mat-icon>
    <mat-icon>perm_device_information</mat-icon>
    <mat-icon>perm_identity</mat-icon>
    <mat-icon>perm_media</mat-icon>
    <mat-icon>perm_phone_msg</mat-icon>
    <mat-icon>perm_scan_wifi</mat-icon>
    <mat-icon>pets</mat-icon>
    <mat-icon>picture_in_picture</mat-icon>
    <mat-icon>picture_in_picture_alt</mat-icon>
    <mat-icon>play_for_work</mat-icon>
    <mat-icon>polymer</mat-icon>
    <mat-icon>power_settings_new</mat-icon>
    <mat-icon>pregnant_woman</mat-icon>
    <mat-icon>print</mat-icon>
    <mat-icon>query_builder</mat-icon>
    <mat-icon>question_answer</mat-icon>
    <mat-icon>receipt</mat-icon>
    <mat-icon>record_voice_over</mat-icon>
    <mat-icon>redeem</mat-icon>
    <mat-icon>remove_shopping_cart</mat-icon>
    <mat-icon>reorder</mat-icon>
    <mat-icon>report_problem</mat-icon>
    <mat-icon>restore</mat-icon>
    <mat-icon>restore_page</mat-icon>
    <mat-icon>room</mat-icon>
    <mat-icon>rounded_corner</mat-icon>
    <mat-icon>rowing</mat-icon>
    <mat-icon>schedule</mat-icon>
    <mat-icon>search</mat-icon>
    <mat-icon>settings</mat-icon>
    <mat-icon>settings_applications</mat-icon>
    <mat-icon>settings_backup_restore</mat-icon>
    <mat-icon>settings_bluetooth</mat-icon>
    <mat-icon>settings_brightness</mat-icon>
    <mat-icon>settings_cell</mat-icon>
    <mat-icon>settings_ethernet</mat-icon>
    <mat-icon>settings_input_antenna</mat-icon>
    <mat-icon>settings_input_component</mat-icon>
    <mat-icon>settings_input_composite</mat-icon>
    <mat-icon>settings_input_hdmi</mat-icon>
    <mat-icon>settings_input_svideo</mat-icon>
    <mat-icon>settings_overscan</mat-icon>
    <mat-icon>settings_phone</mat-icon>
    <mat-icon>settings_power</mat-icon>
    <mat-icon>settings_remote</mat-icon>
    <mat-icon>settings_voice</mat-icon>
    <mat-icon>shop</mat-icon>
    <mat-icon>shop_two</mat-icon>
    <mat-icon>shopping_basket</mat-icon>
    <mat-icon>shopping_cart</mat-icon>
    <mat-icon>speaker_notes</mat-icon>
    <mat-icon>speaker_notes_off</mat-icon>
    <mat-icon>spellcheck</mat-icon>
    <mat-icon>star_rate</mat-icon>
    <mat-icon>stars</mat-icon>
    <mat-icon>store</mat-icon>
    <mat-icon>subject</mat-icon>
    <mat-icon>supervisor_account</mat-icon>
    <mat-icon>swap_horiz</mat-icon>
    <mat-icon>swap_vert</mat-icon>
    <mat-icon>swap_vertial_circle</mat-icon>
    <mat-icon>system_update_alt</mat-icon>
    <mat-icon>tab</mat-icon>
    <mat-icon>tab_unselected</mat-icon>
    <mat-icon>theaters</mat-icon>
    <mat-icon>thumb_down</mat-icon>
    <mat-icon>thumb_up</mat-icon>
    <mat-icon>thumbs_up_down</mat-icon>
    <mat-icon>timeline</mat-icon>
    <mat-icon>toc</mat-icon>
    <mat-icon>today</mat-icon>
    <mat-icon>toll</mat-icon>
    <mat-icon>touch_app</mat-icon>
    <mat-icon>track_changes</mat-icon>
    <mat-icon>translate</mat-icon>
    <mat-icon>trending_down</mat-icon>
    <mat-icon>trending_flat</mat-icon>
    <mat-icon>trending_up</mat-icon>
    <mat-icon>turned_in</mat-icon>
    <mat-icon>turned_in_not</mat-icon>
    <mat-icon>update</mat-icon>
    <mat-icon>verified_user</mat-icon>
    <mat-icon>view_agenda</mat-icon>
    <mat-icon>view_array</mat-icon>
    <mat-icon>view_carousel</mat-icon>
    <mat-icon>view_column</mat-icon>
    <mat-icon>view_day</mat-icon>
    <mat-icon>view_headline</mat-icon>
    <mat-icon>view_list</mat-icon>
    <mat-icon>view_module</mat-icon>
    <mat-icon>view_quilt</mat-icon>
    <mat-icon>view_stream</mat-icon>
    <mat-icon>view_week</mat-icon>
    <mat-icon>visibility</mat-icon>
    <mat-icon>visibility_off</mat-icon>
    <mat-icon>watch_later</mat-icon>
    <mat-icon>work</mat-icon>
    <mat-icon>youtube_searched_for</mat-icon>
    <mat-icon>zoom_in</mat-icon>
    <mat-icon>zoom_out</mat-icon>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>Alert icons</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-icon>add_alert</mat-icon>
    <mat-icon>error</mat-icon>
    <mat-icon>error_outline</mat-icon>
    <mat-icon>warning</mat-icon>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>Av icons</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-icon>add_to_queue</mat-icon>
    <mat-icon>airplay</mat-icon>
    <mat-icon>album</mat-icon>
    <mat-icon>art_track</mat-icon>
    <mat-icon>av_timer</mat-icon>
    <mat-icon>branding_watermark</mat-icon>
    <mat-icon>call_to_action</mat-icon>
    <mat-icon>closed_caption</mat-icon>
    <mat-icon>equalizer</mat-icon>
    <mat-icon>explicit</mat-icon>
    <mat-icon>fast_forward</mat-icon>
    <mat-icon>fast_rewind</mat-icon>
    <mat-icon>featured_play_list</mat-icon>
    <mat-icon>featured_video</mat-icon>
    <mat-icon>fiber_manual_record</mat-icon>
    <mat-icon>forward_10</mat-icon>
    <mat-icon>forward_30</mat-icon>
    <mat-icon>forward_5</mat-icon>
    <mat-icon>games</mat-icon>
    <mat-icon>hd</mat-icon>
    <mat-icon>hearing</mat-icon>
    <mat-icon>high_quality</mat-icon>
    <mat-icon>my_library_add</mat-icon>
    <mat-icon>my_library_books</mat-icon>
    <mat-icon>my_library_music</mat-icon>
    <mat-icon>loop</mat-icon>
    <mat-icon>mic</mat-icon>
    <mat-icon>mic_none</mat-icon>
    <mat-icon>mic_off</mat-icon>
    <mat-icon>movie</mat-icon>
    <mat-icon>music_video</mat-icon>
    <mat-icon>new_releases</mat-icon>
    <mat-icon>not_interested</mat-icon>
    <mat-icon>note</mat-icon>
    <mat-icon>pause</mat-icon>
    <mat-icon>pause_circle_filled</mat-icon>
    <mat-icon>pause_circle_outline</mat-icon>
    <mat-icon>play_arrow</mat-icon>
    <mat-icon>play_circle_fill</mat-icon>
    <mat-icon>play_circle_outline</mat-icon>
    <mat-icon>playlist_add</mat-icon>
    <mat-icon>playlist_add_check</mat-icon>
    <mat-icon>playlist_play</mat-icon>
    <mat-icon>queue</mat-icon>
    <mat-icon>queue_music</mat-icon>
    <mat-icon>queue_play_next</mat-icon>
    <mat-icon>radio</mat-icon>
    <mat-icon>recent_actors</mat-icon>
    <mat-icon>remove_from_queue</mat-icon>
    <mat-icon>repeat</mat-icon>
    <mat-icon>repeat_one</mat-icon>
    <mat-icon>replay</mat-icon>
    <mat-icon>replay_10</mat-icon>
    <mat-icon>replay_30</mat-icon>
    <mat-icon>replay_5</mat-icon>
    <mat-icon>shuffle</mat-icon>
    <mat-icon>skip_next</mat-icon>
    <mat-icon>skip_previous</mat-icon>
    <mat-icon>slow_motion_video</mat-icon>
    <mat-icon>snooze</mat-icon>
    <mat-icon>sort_by_alpha</mat-icon>
    <mat-icon>stop</mat-icon>
    <mat-icon>subscriptions</mat-icon>
    <mat-icon>subtitles</mat-icon>
    <mat-icon>surround_sound</mat-icon>
    <mat-icon>video_call</mat-icon>
    <mat-icon>video_label</mat-icon>
    <mat-icon>video_library</mat-icon>
    <mat-icon>videocam</mat-icon>
    <mat-icon>videocam_off</mat-icon>
    <mat-icon>volume_down</mat-icon>
    <mat-icon>volume_mute</mat-icon>
    <mat-icon>volume_off</mat-icon>
    <mat-icon>volume_up</mat-icon>
    <mat-icon>web</mat-icon>
    <mat-icon>web_asset</mat-icon>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>Communication icons</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-icon>business</mat-icon>
    <mat-icon>call</mat-icon>
    <mat-icon>call_end</mat-icon>
    <mat-icon>call_made</mat-icon>
    <mat-icon>call_merge</mat-icon>
    <mat-icon>call_missed</mat-icon>
    <mat-icon>call_missed_outgoing</mat-icon>
    <mat-icon>call_received</mat-icon>
    <mat-icon>call_split</mat-icon>
    <mat-icon>chat</mat-icon>
    <mat-icon>chat_bubble</mat-icon>
    <mat-icon>chat_bubble_outline</mat-icon>
    <mat-icon>clear_all</mat-icon>
    <mat-icon>comment</mat-icon>
    <mat-icon>contact_mail</mat-icon>
    <mat-icon>contact_phone</mat-icon>
    <mat-icon>contacts</mat-icon>
    <mat-icon>dialer_sip</mat-icon>
    <mat-icon>dialpad</mat-icon>
    <mat-icon>email</mat-icon>
    <mat-icon>forum</mat-icon>
    <mat-icon>import_contacts</mat-icon>
    <mat-icon>import_export</mat-icon>
    <mat-icon>invert_colors_off</mat-icon>
    <mat-icon>live_help</mat-icon>
    <mat-icon>location_off</mat-icon>
    <mat-icon>location_on</mat-icon>
    <mat-icon>mail_outline</mat-icon>
    <mat-icon>message</mat-icon>
    <mat-icon>no_sim</mat-icon>
    <mat-icon>phone</mat-icon>
    <mat-icon>phonelink_erase</mat-icon>
    <mat-icon>phonelink_lock</mat-icon>
    <mat-icon>phonelink_ring</mat-icon>
    <mat-icon>phonelink_setup</mat-icon>
    <mat-icon>portable_wifi_off</mat-icon>
    <mat-icon>present_to_all</mat-icon>
    <mat-icon>ring_volume</mat-icon>
    <mat-icon>rss_feed</mat-icon>
    <mat-icon>screen_share</mat-icon>
    <mat-icon>stay_current_landscape</mat-icon>
    <mat-icon>stay_current_portrait</mat-icon>
    <mat-icon>stay_primary_landscape</mat-icon>
    <mat-icon>stay_primary_portrait</mat-icon>
    <mat-icon>stop_screen_share</mat-icon>
    <mat-icon>swap_calls</mat-icon>
    <mat-icon>textsms</mat-icon>
    <mat-icon>voicemail</mat-icon>
    <mat-icon>vpn_key</mat-icon>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>Content icons</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-icon>add</mat-icon>
    <mat-icon>add_box</mat-icon>
    <mat-icon>add_circle</mat-icon>
    <mat-icon>add_circle_outline</mat-icon>
    <mat-icon>archive</mat-icon>
    <mat-icon>backspace</mat-icon>
    <mat-icon>block</mat-icon>
    <mat-icon>clear</mat-icon>
    <mat-icon>content_copy</mat-icon>
    <mat-icon>content_cut</mat-icon>
    <mat-icon>content_paste</mat-icon>
    <mat-icon>create</mat-icon>
    <mat-icon>delete_sweep</mat-icon>
    <mat-icon>drafts</mat-icon>
    <mat-icon>filter_list</mat-icon>
    <mat-icon>flag</mat-icon>
    <mat-icon>font_download</mat-icon>
    <mat-icon>forward</mat-icon>
    <mat-icon>gesture</mat-icon>
    <mat-icon>inbox</mat-icon>
    <mat-icon>link</mat-icon>
    <mat-icon>low_priority</mat-icon>
    <mat-icon>mail</mat-icon>
    <mat-icon>markunread</mat-icon>
    <mat-icon>move_to_inbox</mat-icon>
    <mat-icon>next_week</mat-icon>
    <mat-icon>redo</mat-icon>
    <mat-icon>remove</mat-icon>
    <mat-icon>remove_circle</mat-icon>
    <mat-icon>remove_circle_outline</mat-icon>
    <mat-icon>reply</mat-icon>
    <mat-icon>reply_all</mat-icon>
    <mat-icon>report</mat-icon>
    <mat-icon>save</mat-icon>
    <mat-icon>select_all</mat-icon>
    <mat-icon>send</mat-icon>
    <mat-icon>sort</mat-icon>
    <mat-icon>text_format</mat-icon>
    <mat-icon>unarchive</mat-icon>
    <mat-icon>undo</mat-icon>
    <mat-icon>weekend</mat-icon>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>Device icons</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-icon>access_alarms</mat-icon>
    <mat-icon>access_alarm</mat-icon>
    <mat-icon>access_time</mat-icon>
    <mat-icon>add_alarm</mat-icon>
    <mat-icon>airplanemode_on</mat-icon>
    <mat-icon>airplanemode_inactive</mat-icon>
    <mat-icon>battery_charging_full</mat-icon>
    <mat-icon>battery_full</mat-icon>
    <mat-icon>battery_std</mat-icon>
    <mat-icon>battery_unknown</mat-icon>
    <mat-icon>bluetooth</mat-icon>
    <mat-icon>bluetooth_connected</mat-icon>
    <mat-icon>bluetooth_disabled</mat-icon>
    <mat-icon>bluetooth_searching</mat-icon>
    <mat-icon>brightness_auto</mat-icon>
    <mat-icon>brightness_high</mat-icon>
    <mat-icon>brightness_low</mat-icon>
    <mat-icon>brightness_medium</mat-icon>
    <mat-icon>data_usage</mat-icon>
    <mat-icon>developer_mode</mat-icon>
    <mat-icon>devices</mat-icon>
    <mat-icon>dvr</mat-icon>
    <mat-icon>gps_fixed</mat-icon>
    <mat-icon>gps_not_fixed</mat-icon>
    <mat-icon>gps_off</mat-icon>
    <mat-icon>graphic_eq</mat-icon>
    <mat-icon>location_disabled</mat-icon>
    <mat-icon>location_searching</mat-icon>
    <mat-icon>network_cell</mat-icon>
    <mat-icon>network_wifi</mat-icon>
    <mat-icon>nfc</mat-icon>
    <mat-icon>screen_lock_landscape</mat-icon>
    <mat-icon>screen_lock_portrait</mat-icon>
    <mat-icon>screen_lock_rotation</mat-icon>
    <mat-icon>screen_rotation</mat-icon>
    <mat-icon>sd_storage</mat-icon>
    <mat-icon>settings_system_daydream</mat-icon>
    <mat-icon>signal_cellular_4_bar</mat-icon>
    <mat-icon>signal_cellular_connected_no_internet_4_bar</mat-icon>
    <mat-icon>signal_cellular_no_sim</mat-icon>
    <mat-icon>signal_cellular_null</mat-icon>
    <mat-icon>signal_cellular_off</mat-icon>
    <mat-icon>signal_wifi_4_bar</mat-icon>
    <mat-icon>signal_wifi_4_bar_lock</mat-icon>
    <mat-icon>signal_wifi_off</mat-icon>
    <mat-icon>storage</mat-icon>
    <mat-icon>usb</mat-icon>
    <mat-icon>wallpaper</mat-icon>
    <mat-icon>wifi_lock</mat-icon>
    <mat-icon>wifi_tethering</mat-icon>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>Editor icons</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-icon>attach_file</mat-icon>
    <mat-icon>attach_money</mat-icon>
    <mat-icon>border_all</mat-icon>
    <mat-icon>border_bottom</mat-icon>
    <mat-icon>border_clear</mat-icon>
    <mat-icon>border_color</mat-icon>
    <mat-icon>border_horizontal</mat-icon>
    <mat-icon>border_inner</mat-icon>
    <mat-icon>border_left</mat-icon>
    <mat-icon>border_outer</mat-icon>
    <mat-icon>border_right</mat-icon>
    <mat-icon>border_style</mat-icon>
    <mat-icon>border_top</mat-icon>
    <mat-icon>border_vertical</mat-icon>
    <mat-icon>bubble_chart</mat-icon>
    <mat-icon>drag_handle</mat-icon>
    <mat-icon>format_align_center</mat-icon>
    <mat-icon>format_align_justify</mat-icon>
    <mat-icon>format_align_left</mat-icon>
    <mat-icon>format_align_right</mat-icon>
    <mat-icon>format_bold</mat-icon>
    <mat-icon>format_clear</mat-icon>
    <mat-icon>format_color_fill</mat-icon>
    <mat-icon>format_color_reset</mat-icon>
    <mat-icon>format_color_text</mat-icon>
    <mat-icon>format_indent_decrease</mat-icon>
    <mat-icon>format_indent_increase</mat-icon>
    <mat-icon>format_italic</mat-icon>
    <mat-icon>format_line_spacing</mat-icon>
    <mat-icon>format_list_bulleted</mat-icon>
    <mat-icon>format_list_numbered</mat-icon>
    <mat-icon>format_paint</mat-icon>
    <mat-icon>format_quote</mat-icon>
    <mat-icon>format_shapes</mat-icon>
    <mat-icon>format_size</mat-icon>
    <mat-icon>format_strikethrough</mat-icon>
    <mat-icon>format_textdirection_l_to_r</mat-icon>
    <mat-icon>format_textdirection_r_to_l</mat-icon>
    <mat-icon>format_underline</mat-icon>
    <mat-icon>functions</mat-icon>
    <mat-icon>highlight</mat-icon>
    <mat-icon>insert_chart</mat-icon>
    <mat-icon>insert_comment</mat-icon>
    <mat-icon>insert_drive_file</mat-icon>
    <mat-icon>insert_emoticon</mat-icon>
    <mat-icon>insert_invitation</mat-icon>
    <mat-icon>insert_link</mat-icon>
    <mat-icon>insert_photo</mat-icon>
    <mat-icon>linear_scale</mat-icon>
    <mat-icon>merge_type</mat-icon>
    <mat-icon>mode_comment</mat-icon>
    <mat-icon>mode_edit</mat-icon>
    <mat-icon>monetization_on</mat-icon>
    <mat-icon>money_off</mat-icon>
    <mat-icon>multiline_chart</mat-icon>
    <mat-icon>pie_chart</mat-icon>
    <mat-icon>pie_chart_outline</mat-icon>
    <mat-icon>publish</mat-icon>
    <mat-icon>short_text</mat-icon>
    <mat-icon>show_chart</mat-icon>
    <mat-icon>space_bar</mat-icon>
    <mat-icon>strikethrough_s</mat-icon>
    <mat-icon>text_fields</mat-icon>
    <mat-icon>title</mat-icon>
    <mat-icon>vertical_align_bottom</mat-icon>
    <mat-icon>vertical_align_center</mat-icon>
    <mat-icon>vertical_align_top</mat-icon>
    <mat-icon>wrap_text</mat-icon>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>File icons</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-icon>attachment</mat-icon>
    <mat-icon>cloud</mat-icon>
    <mat-icon>cloud_circle</mat-icon>
    <mat-icon>cloud_done</mat-icon>
    <mat-icon>cloud_download</mat-icon>
    <mat-icon>cloud_off</mat-icon>
    <mat-icon>cloud_queue</mat-icon>
    <mat-icon>cloud_upload</mat-icon>
    <mat-icon>create_new_folder</mat-icon>
    <mat-icon>file_download</mat-icon>
    <mat-icon>file_upload</mat-icon>
    <mat-icon>folder</mat-icon>
    <mat-icon>folder_open</mat-icon>
    <mat-icon>folder_shared</mat-icon>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>Hardware icons</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-icon>cast</mat-icon>
    <mat-icon>cast_connected</mat-icon>
    <mat-icon>computer</mat-icon>
    <mat-icon>desktop_mac</mat-icon>
    <mat-icon>desktop_windows</mat-icon>
    <mat-icon>device_hub</mat-icon>
    <mat-icon>devices_other</mat-icon>
    <mat-icon>dock</mat-icon>
    <mat-icon>gamepad</mat-icon>
    <mat-icon>headset</mat-icon>
    <mat-icon>headset_mic</mat-icon>
    <mat-icon>keyboard</mat-icon>
    <mat-icon>keyboard_arrow_down</mat-icon>
    <mat-icon>keyboard_arrow_left</mat-icon>
    <mat-icon>keyboard_arrow_right</mat-icon>
    <mat-icon>keyboard_arrow_up</mat-icon>
    <mat-icon>keyboard_backspace</mat-icon>
    <mat-icon>keyboard_capslock</mat-icon>
    <mat-icon>keyboard_hide</mat-icon>
    <mat-icon>keyboard_return</mat-icon>
    <mat-icon>keyboard_tab</mat-icon>
    <mat-icon>keyboard_voice</mat-icon>
    <mat-icon>laptop</mat-icon>
    <mat-icon>laptop_chromebook</mat-icon>
    <mat-icon>laptop_mac</mat-icon>
    <mat-icon>laptop_windows</mat-icon>
    <mat-icon>memory</mat-icon>
    <mat-icon>mouse</mat-icon>
    <mat-icon>phone_android</mat-icon>
    <mat-icon>phone_iphone</mat-icon>
    <mat-icon>phonelink</mat-icon>
    <mat-icon>phonelink_off</mat-icon>
    <mat-icon>power_input</mat-icon>
    <mat-icon>router</mat-icon>
    <mat-icon>scanner</mat-icon>
    <mat-icon>security</mat-icon>
    <mat-icon>sim_card</mat-icon>
    <mat-icon>smartphone</mat-icon>
    <mat-icon>speaker</mat-icon>
    <mat-icon>speaker_group</mat-icon>
    <mat-icon>tablet</mat-icon>
    <mat-icon>tablet_android</mat-icon>
    <mat-icon>tablet_mac</mat-icon>
    <mat-icon>toys</mat-icon>
    <mat-icon>tv</mat-icon>
    <mat-icon>watch</mat-icon>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>Image icons</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-icon>add_a_photo</mat-icon>
    <mat-icon>add_to_photos</mat-icon>
    <mat-icon>adjust</mat-icon>
    <mat-icon>assistant_photo</mat-icon>
    <mat-icon>audiotrack</mat-icon>
    <mat-icon>blur_circular</mat-icon>
    <mat-icon>blur_linear</mat-icon>
    <mat-icon>blur_off</mat-icon>
    <mat-icon>blur_on</mat-icon>
    <mat-icon>brightness_1</mat-icon>
    <mat-icon>brightness_2</mat-icon>
    <mat-icon>brightness_3</mat-icon>
    <mat-icon>brightness_4</mat-icon>
    <mat-icon>brightness_5</mat-icon>
    <mat-icon>brightness_6</mat-icon>
    <mat-icon>brightness_7</mat-icon>
    <mat-icon>broken_image</mat-icon>
    <mat-icon>brush</mat-icon>
    <mat-icon>burst_mode</mat-icon>
    <mat-icon>camera</mat-icon>
    <mat-icon>camera_alt</mat-icon>
    <mat-icon>camera_front</mat-icon>
    <mat-icon>camera_rear</mat-icon>
    <mat-icon>camera_roll</mat-icon>
    <mat-icon>center_focus_strong</mat-icon>
    <mat-icon>center_focus_weak</mat-icon>
    <mat-icon>collections</mat-icon>
    <mat-icon>collections_bookmark</mat-icon>
    <mat-icon>color_lens</mat-icon>
    <mat-icon>colorize</mat-icon>
    <mat-icon>compare</mat-icon>
    <mat-icon>control_point</mat-icon>
    <mat-icon>control_point_duplicate</mat-icon>
    <mat-icon>crop</mat-icon>
    <mat-icon>crop_16_9</mat-icon>
    <mat-icon>crop_3_2</mat-icon>
    <mat-icon>crop_5_4</mat-icon>
    <mat-icon>crop_7_5</mat-icon>
    <mat-icon>crop_din</mat-icon>
    <mat-icon>crop_free</mat-icon>
    <mat-icon>crop_landscape</mat-icon>
    <mat-icon>crop_original</mat-icon>
    <mat-icon>crop_portrait</mat-icon>
    <mat-icon>crop_rotate</mat-icon>
    <mat-icon>crop_square</mat-icon>
    <mat-icon>dehaze</mat-icon>
    <mat-icon>details</mat-icon>
    <mat-icon>edit</mat-icon>
    <mat-icon>exposure</mat-icon>
    <mat-icon>exposure_neg_1</mat-icon>
    <mat-icon>exposure_neg_2</mat-icon>
    <mat-icon>exposure_plus_1</mat-icon>
    <mat-icon>exposure_plus_2</mat-icon>
    <mat-icon>exposure_zero</mat-icon>
    <mat-icon>filter</mat-icon>
    <mat-icon>filter_1</mat-icon>
    <mat-icon>filter_2</mat-icon>
    <mat-icon>filter_3</mat-icon>
    <mat-icon>filter_4</mat-icon>
    <mat-icon>filter_5</mat-icon>
    <mat-icon>filter_6</mat-icon>
    <mat-icon>filter_7</mat-icon>
    <mat-icon>filter_8</mat-icon>
    <mat-icon>filter_9</mat-icon>
    <mat-icon>filter_9_plus</mat-icon>
    <mat-icon>filter_b_and_w</mat-icon>
    <mat-icon>filter_center_focus</mat-icon>
    <mat-icon>filter_drama</mat-icon>
    <mat-icon>filter_frames</mat-icon>
    <mat-icon>filter_hdr</mat-icon>
    <mat-icon>filter_none</mat-icon>
    <mat-icon>filter_tilt_shift</mat-icon>
    <mat-icon>filter_vintage</mat-icon>
    <mat-icon>flare</mat-icon>
    <mat-icon>flash_auto</mat-icon>
    <mat-icon>flash_off</mat-icon>
    <mat-icon>flash_on</mat-icon>
    <mat-icon>flip</mat-icon>
    <mat-icon>gradient</mat-icon>
    <mat-icon>grain</mat-icon>
    <mat-icon>grid_off</mat-icon>
    <mat-icon>grid_on</mat-icon>
    <mat-icon>hdr_off</mat-icon>
    <mat-icon>hdr_on</mat-icon>
    <mat-icon>hdr_strong</mat-icon>
    <mat-icon>hdr_weak</mat-icon>
    <mat-icon>healing</mat-icon>
    <mat-icon>image</mat-icon>
    <mat-icon>image_aspect_ratio</mat-icon>
    <mat-icon>iso</mat-icon>
    <mat-icon>landscape</mat-icon>
    <mat-icon>leak_add</mat-icon>
    <mat-icon>leak_remove</mat-icon>
    <mat-icon>lens</mat-icon>
    <mat-icon>linked_camera</mat-icon>
    <mat-icon>looks</mat-icon>
    <mat-icon>looks_3</mat-icon>
    <mat-icon>looks_4</mat-icon>
    <mat-icon>looks_5</mat-icon>
    <mat-icon>looks_6</mat-icon>
    <mat-icon>looks_one</mat-icon>
    <mat-icon>looks_two</mat-icon>
    <mat-icon>loupe</mat-icon>
    <mat-icon>monochrome_photos</mat-icon>
    <mat-icon>movie_creation</mat-icon>
    <mat-icon>movie_filter</mat-icon>
    <mat-icon>music_note</mat-icon>
    <mat-icon>nature</mat-icon>
    <mat-icon>nature_people</mat-icon>
    <mat-icon>navigate_before</mat-icon>
    <mat-icon>navigate_next</mat-icon>
    <mat-icon>palette</mat-icon>
    <mat-icon>panorama</mat-icon>
    <mat-icon>panorama_fisheye</mat-icon>
    <mat-icon>panorama_horizontal</mat-icon>
    <mat-icon>panorama_vertical</mat-icon>
    <mat-icon>panorama_wide_angle</mat-icon>
    <mat-icon>photo</mat-icon>
    <mat-icon>photo_album</mat-icon>
    <mat-icon>photo_camera</mat-icon>
    <mat-icon>photo_filter</mat-icon>
    <mat-icon>photo_library</mat-icon>
    <mat-icon>photo_size_select_actual</mat-icon>
    <mat-icon>photo_size_select_large</mat-icon>
    <mat-icon>photo_size_select_small</mat-icon>
    <mat-icon>picture_as_pdf</mat-icon>
    <mat-icon>portrait</mat-icon>
    <mat-icon>remove_red_eye</mat-icon>
    <mat-icon>rotate_90_degrees_ccw</mat-icon>
    <mat-icon>rotate_left</mat-icon>
    <mat-icon>rotate_right</mat-icon>
    <mat-icon>slideshow</mat-icon>
    <mat-icon>straighten</mat-icon>
    <mat-icon>style</mat-icon>
    <mat-icon>switch_camera</mat-icon>
    <mat-icon>switch_video</mat-icon>
    <mat-icon>tag_faces</mat-icon>
    <mat-icon>texture</mat-icon>
    <mat-icon>timelapse</mat-icon>
    <mat-icon>timer</mat-icon>
    <mat-icon>timer_10</mat-icon>
    <mat-icon>timer_3</mat-icon>
    <mat-icon>timer_off</mat-icon>
    <mat-icon>tonality</mat-icon>
    <mat-icon>transform</mat-icon>
    <mat-icon>tune</mat-icon>
    <mat-icon>view_comfy</mat-icon>
    <mat-icon>view_compact</mat-icon>
    <mat-icon>vignette</mat-icon>
    <mat-icon>wb_auto</mat-icon>
    <mat-icon>wb_cloudy</mat-icon>
    <mat-icon>wb_incandescent</mat-icon>
    <mat-icon>wb_sunny</mat-icon>
  </mat-card-content>
</mat-card>

<mat-card [title]="'Maps icons'">
  <mat-card-header>
    <mat-card-title></mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-icon>add_location</mat-icon>
    <mat-icon>beenhere</mat-icon>
    <mat-icon>directions</mat-icon>
    <mat-icon>directions_bike</mat-icon>
    <mat-icon>directions_bus</mat-icon>
    <mat-icon>directions_car</mat-icon>
    <mat-icon>directions_ferry</mat-icon>
    <mat-icon>directions_subway</mat-icon>
    <mat-icon>directions_train</mat-icon>
    <mat-icon>directions_transit</mat-icon>
    <mat-icon>directions_walk</mat-icon>
    <mat-icon>edit_location</mat-icon>
    <mat-icon>ev_station</mat-icon>
    <mat-icon>flight</mat-icon>
    <mat-icon>hotel</mat-icon>
    <mat-icon>layers</mat-icon>
    <mat-icon>layers_clear</mat-icon>
    <mat-icon>local_activity</mat-icon>
    <mat-icon>local_airport</mat-icon>
    <mat-icon>local_atm</mat-icon>
    <mat-icon>local_bar</mat-icon>
    <mat-icon>local_cafe</mat-icon>
    <mat-icon>local_car_wash</mat-icon>
    <mat-icon>local_convenience_store</mat-icon>
    <mat-icon>local_dining</mat-icon>
    <mat-icon>local_drink</mat-icon>
    <mat-icon>local_florist</mat-icon>
    <mat-icon>local_gas_station</mat-icon>
    <mat-icon>local_grocery_store</mat-icon>
    <mat-icon>local_hospital</mat-icon>
    <mat-icon>local_hotel</mat-icon>
    <mat-icon>local_laundry_service</mat-icon>
    <mat-icon>local_library</mat-icon>
    <mat-icon>local_mall</mat-icon>
    <mat-icon>local_movies</mat-icon>
    <mat-icon>local_offer</mat-icon>
    <mat-icon>local_parking</mat-icon>
    <mat-icon>local_pharmacy</mat-icon>
    <mat-icon>local_phone</mat-icon>
    <mat-icon>local_pizza</mat-icon>
    <mat-icon>local_play</mat-icon>
    <mat-icon>local_post_office</mat-icon>
    <mat-icon>local_print_shop</mat-icon>
    <mat-icon>local_restaurant</mat-icon>
    <mat-icon>local_see</mat-icon>
    <mat-icon>local_shipping</mat-icon>
    <mat-icon>local_taxi</mat-icon>
    <mat-icon>map</mat-icon>
    <mat-icon>my_location</mat-icon>
    <mat-icon>navigation</mat-icon>
    <mat-icon>near_me</mat-icon>
    <mat-icon>person_pin_circle</mat-icon>
    <mat-icon>person_pin</mat-icon>
    <mat-icon>pin_drop</mat-icon>
    <mat-icon>place</mat-icon>
    <mat-icon>rate_review</mat-icon>
    <mat-icon>restaurant</mat-icon>
    <mat-icon>restaurant_menu</mat-icon>
    <mat-icon>satellite</mat-icon>
    <mat-icon>store_mall_directory</mat-icon>
    <mat-icon>streetview</mat-icon>
    <mat-icon>subway</mat-icon>
    <mat-icon>terrain</mat-icon>
    <mat-icon>traffic</mat-icon>
    <mat-icon>train</mat-icon>
    <mat-icon>tram</mat-icon>
    <mat-icon>transfer_within_a_station</mat-icon>
    <mat-icon>zoom_out_map</mat-icon>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>Navigation icons</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-icon>apps</mat-icon>
    <mat-icon>arrow_back</mat-icon>
    <mat-icon>arrow_downward</mat-icon>
    <mat-icon>arrow_drop_down</mat-icon>
    <mat-icon>arrow_drop_down_circle</mat-icon>
    <mat-icon>arrow_drop_up</mat-icon>
    <mat-icon>arrow_forward</mat-icon>
    <mat-icon>arrow_upwards</mat-icon>
    <mat-icon>cancel</mat-icon>
    <mat-icon>check</mat-icon>
    <mat-icon>chevron_left</mat-icon>
    <mat-icon>chevron_right</mat-icon>
    <mat-icon>close</mat-icon>
    <mat-icon>expand_less</mat-icon>
    <mat-icon>expand_more</mat-icon>
    <mat-icon>first_page</mat-icon>
    <mat-icon>fullscreen</mat-icon>
    <mat-icon>fullscreen_exit</mat-icon>
    <mat-icon>last_page</mat-icon>
    <mat-icon>menu</mat-icon>
    <mat-icon>more_horiz</mat-icon>
    <mat-icon>more_vert</mat-icon>
    <mat-icon>refresh</mat-icon>
    <mat-icon>subdirectory_arrow_left</mat-icon>
    <mat-icon>subdirectory_arrow_right</mat-icon>
    <mat-icon>adb</mat-icon>
    <mat-icon>airline_seat_flat</mat-icon>
    <mat-icon>airline_seat_individual_suite</mat-icon>
    <mat-icon>airline_seat_legroom_extra</mat-icon>
    <mat-icon>airline_seat_legroom_normal</mat-icon>
    <mat-icon>airline_seat_legroom_reduced</mat-icon>
    <mat-icon>airline_seat_recline_extra</mat-icon>
    <mat-icon>airline_seat_recline_normal</mat-icon>
    <mat-icon>bluetooth_audio</mat-icon>
    <mat-icon>confirmation_number</mat-icon>
    <mat-icon>disc_full</mat-icon>
    <mat-icon>do_not_disturb</mat-icon>
    <mat-icon>do_not_disturb_alt</mat-icon>
    <mat-icon>do_not_disturb_off</mat-icon>
    <mat-icon>do_not_disturb_on</mat-icon>
    <mat-icon>drive_eta</mat-icon>
    <mat-icon>enhanced_encryption</mat-icon>
    <mat-icon>event_available</mat-icon>
    <mat-icon>event_busy</mat-icon>
    <mat-icon>event_note</mat-icon>
    <mat-icon>folder_special</mat-icon>
    <mat-icon>live_tv</mat-icon>
    <mat-icon>mms</mat-icon>
    <mat-icon>more</mat-icon>
    <mat-icon>network_check</mat-icon>
    <mat-icon>network_locked</mat-icon>
    <mat-icon>no_encryption</mat-icon>
    <mat-icon>ondemand_video</mat-icon>
    <mat-icon>personal_video</mat-icon>
    <mat-icon>phone_bluetooth_speaker</mat-icon>
    <mat-icon>phone_forwarded</mat-icon>
    <mat-icon>phone_in_talk</mat-icon>
    <mat-icon>phone_locked</mat-icon>
    <mat-icon>phone_missed</mat-icon>
    <mat-icon>phone_paused</mat-icon>
    <mat-icon>power</mat-icon>
    <mat-icon>priority_high</mat-icon>
    <mat-icon>sd_card</mat-icon>
    <mat-icon>sim_card_alert</mat-icon>
    <mat-icon>sms</mat-icon>
    <mat-icon>sms_failed</mat-icon>
    <mat-icon>sync</mat-icon>
    <mat-icon>sync_disabled</mat-icon>
    <mat-icon>sync_problem</mat-icon>
    <mat-icon>system_update</mat-icon>
    <mat-icon>tap_and_play</mat-icon>
    <mat-icon>time_to_leave</mat-icon>
    <mat-icon>vibration</mat-icon>
    <mat-icon>voice_chat</mat-icon>
    <mat-icon>vpn_lock</mat-icon>
    <mat-icon>wc</mat-icon>
    <mat-icon>wifi</mat-icon>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>Places icons</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-icon>ac_unit</mat-icon>
    <mat-icon>airport_shuttle</mat-icon>
    <mat-icon>all_inclusive</mat-icon>
    <mat-icon>beach_access</mat-icon>
    <mat-icon>business_center</mat-icon>
    <mat-icon>casino</mat-icon>
    <mat-icon>child_care</mat-icon>
    <mat-icon>fitness_center</mat-icon>
    <mat-icon>free_breakfast</mat-icon>
    <mat-icon>golf_course</mat-icon>
    <mat-icon>hot_tub</mat-icon>
    <mat-icon>kitchen</mat-icon>
    <mat-icon>pool</mat-icon>
    <mat-icon>room_service</mat-icon>
    <mat-icon>rv_hookup</mat-icon>
    <mat-icon>smoke_free</mat-icon>
    <mat-icon>spa</mat-icon>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>Social icons</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-icon>cake</mat-icon>
    <mat-icon>domain</mat-icon>
    <mat-icon>group</mat-icon>
    <mat-icon>group_add</mat-icon>
    <mat-icon>location_city</mat-icon>
    <mat-icon>mood</mat-icon>
    <mat-icon>mood_bad</mat-icon>
    <mat-icon>notifications</mat-icon>
    <mat-icon>notifications_none</mat-icon>
    <mat-icon>notifications_off</mat-icon>
    <mat-icon>notifications_active</mat-icon>
    <mat-icon>notifications_paused</mat-icon>
    <mat-icon>pages</mat-icon>
    <mat-icon>party_mode</mat-icon>
    <mat-icon>people</mat-icon>
    <mat-icon>people_outline</mat-icon>
    <mat-icon>person</mat-icon>
    <mat-icon>person_add</mat-icon>
    <mat-icon>person_outline</mat-icon>
    <mat-icon>plus_one</mat-icon>
    <mat-icon>poll</mat-icon>
    <mat-icon>public</mat-icon>
    <mat-icon>school</mat-icon>
    <mat-icon>sentiment_dissatisfied</mat-icon>
    <mat-icon>sentiment_neutral</mat-icon>
    <mat-icon>sentiment_satisfied</mat-icon>
    <mat-icon>sentiment_very_dissatisfied</mat-icon>
    <mat-icon>sentiment_very_satisfied</mat-icon>
    <mat-icon>share</mat-icon>
    <mat-icon>whatshot</mat-icon>
  </mat-card-content>
</mat-card>

<mat-card class="mb-0">
  <mat-card-header>
    <mat-card-title>Toggle icons</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-icon>check_box</mat-icon>
    <mat-icon>check_box_outline_blank</mat-icon>
    <mat-icon>indeterminate_check_box</mat-icon>
    <mat-icon>radio_button_unchecked</mat-icon>
    <mat-icon>radio_button_checked</mat-icon>
    <mat-icon>star</mat-icon>
    <mat-icon>star_half</mat-icon>
    <mat-icon>star_border</mat-icon>
  </mat-card-content>
</mat-card>
