<div class="header">
  <h4 mat-dialog-title class="mb-0">Cadastrar um novo usuário</h4>
  <mat-icon (click)="closeDialog()">close</mat-icon>
</div>

<mat-dialog-content class="content">
  <form [formGroup]="userForm" class="task-form">

    <mat-form-field>
      <mat-label>Nome</mat-label>
      <input matInput placeholder="Nome do usuário" formControlName="name" />
      <ng-container *ngIf="name.invalid && (name.touched || name.dirty)">
        <mat-error @fadeIn>Entre com nome do usuário</mat-error>
      </ng-container>
    </mat-form-field>

    <mat-form-field>
      <mat-label>E-mail</mat-label>
      <input matInput placeholder="E-mail do usuário" formControlName="email" />
      <ng-container *ngIf="email.invalid && (email.touched || email.dirty)">
        <mat-error @fadeIn>Entre com e-mail para o usuário</mat-error>
      </ng-container>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Senha</mat-label>
      <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
      <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
  </form>
</mat-dialog-content>

<button (click)="submitDeal()" tc-button [block]="true">Cadastrar usuário</button>
