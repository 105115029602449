import { Component, EventEmitter, HostBinding, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DeleteDialogComponent } from '../delete-dialog';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { EmailService } from '../../../services/email.service';

@Component({
  selector: 'app-create-email',
  templateUrl: './create-email.component.html',
  styleUrls: ['./create-email.component.scss']
})
export class CreateEmailComponent implements OnInit {
  @HostBinding('class.create-email') true;

  @Output() showComponents: EventEmitter<void>;
  @Output() back: EventEmitter<void>;

  currentDate: Date;
  subjectInput: FormControl;
  filterInput: FormControl;
  constructor(private dialog: MatDialog, private emailSv: EmailService) {
    this.showComponents = new EventEmitter<void>();
    this.back = new EventEmitter();
  }

  ngOnInit() {
    this.subjectInput = new FormControl('Lorem ipsum');
    this.filterInput = new FormControl('');
    this.currentDate = new Date();
  }

  onShowComponents(): void {
    this.showComponents.emit();
  }

  get subject() {
    return this.subjectInput;
  }

  get filter() {
    return this.filterInput;
  }

  deleteEmail(): void {
    const MSG = 'Are you sure you want to delete this email?';

    const REF = this.dialog.open(DeleteDialogComponent, {
      width: '91.5%',
      maxWidth: '435px',
      data: MSG
    });

    REF.afterClosed()
      .pipe(filter(res => res))
      .subscribe(() => {
        this.back.emit();
      });
  }
}
