<mat-card class="mb-0">
  <mat-card-header>
    <div class="title-block">
      <h3 class="title">Colleagues</h3>

      <button class="p-0" [type]="'tertiary'" tc-button>Invite new  <mat-icon svgIcon="add"></mat-icon></button>
    </div>

    <div class="search-wrap">
      <app-search
        [block]="true"
        [placeholder]="'Search colleague'"
        [bgColor]="'#F5F8FA'"
      ></app-search>
    </div>
  </mat-card-header>

  <div class="users-list-wrap">
    <ul class="users-list">
      <li class="users-list-item" *ngFor="let user of users$ | async">
        <div class="avatar-block">
          <img class="user-img" [src]="user.avatar" [alt]="user.name">

          <span class="user-name">{{ user.name }}</span>
        </div>

        <div class="select-wrap">
          <tc-select [options]="selectOptions" [value]="user.permission"></tc-select>
        </div>
      </li>
    </ul>
  </div>
</mat-card>
