<ng-container *ngIf="info">
  <div class="account-info-wrap">
    <a class="img-wrap" routerLink="profile" routerLinkActive="active">
      <img [alt]="info.name" class="account-img" [src]="mobile ? info.minAvatar : info.avatar" />
    </a>

    <div class="info" *ngIf="!minimized && !mobile">
      <span class="name medium">{{ info.name }}</span>
      <span class="email regular-11pt">{{ info.email }}</span>
    </div>
  </div>
</ng-container>
