<div class="navbar-wrap">
  <app-search [placeholder]="placeholder" *ngIf="!mobile"></app-search>

  <app-account-info
    [info]="accountInfo"
    *ngIf="mobile"
    [mobile]="true"
  ></app-account-info>

  <div *ngIf="mobile" class="page-header">
    {{ pageTitle }}
  </div>

  <app-notifications-menu
    [notifications]="notifications | async"
  ></app-notifications-menu>

  <app-settings *ngIf="mobile"></app-settings>
</div>
<mat-divider></mat-divider>
