import {IUser} from './user';

export interface IDeal {
  id: string;
  company: string;
  closeDate: string;
  associatedWith: IUser;
  price: number;
  type: DealType;
  status: DealStatus;
  description: string;
  attachments: IAttachment[];
  comments: IComment[];
}

export interface IDealResponce {
  id: string;
  company: string;
  closeDate: string;
  userId: string;
  price: number;
  type: DealType;
  status: DealStatus;
  description: string;
  attachments: IAttachment[];
  comments: IComment[];
}

export interface IComment {
  sender: IUser;
  text: string;
  addingDate: string;
}

export interface IAttachment {
  title: string;
  img: string;
  addingDate: string;
}

export enum DealType {
  low = 'Low',
  high = 'High',
  urgent = 'Urgent'
}

export enum LeadType {
  low = 'yellow',
  high = 'green',
  urgent = 'red'
}

export enum DealStatus {
  new = 'New',
  won = 'Won',
  lost = 'Lost'
}

export const BadgeStyles = {
  [DealType.low]: 'yellow',
  [DealType.high]: 'green',
  [DealType.urgent]: 'red'
};
