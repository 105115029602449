import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';

import { IEmailComponent } from '../../../interfaces/email';
import {debounceTime, distinctUntilChanged, map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-email-components',
  templateUrl: './email-components.component.html',
  styleUrls: ['./email-components.component.scss']
})
export class EmailComponentsComponent implements OnInit {
  filterInput: FormControl;
  componentsFilter$: Observable<string>;
  filteredComponents$: Observable<IEmailComponent[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public components$: Observable<IEmailComponent[]>,
    private dialogRef: MatDialogRef<EmailComponentsComponent>
  ) {}

  ngOnInit() {
    this.filterInput = new FormControl('');
    this.componentsFilter$ = this.filterInput.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(200),
      startWith(this.filterInput.value)
    );

    this.filteredComponents$ = combineLatest(this.components$, this.componentsFilter$).pipe(
      map(([components, filter]) =>
        components.filter(component => component.title.toLowerCase().includes(filter.toLowerCase()))
      )
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
