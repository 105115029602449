<mat-icon class="settings-icon" [matMenuTriggerFor]="menu">more_horiz</mat-icon>

<mat-menu class="notification-panel" #menu="matMenu">
  <ul class="list">
    <li class="item">
      <div class="item-content">
        <mat-icon>person</mat-icon>

        <div class="text">
          <a class="regular link" routerLink="/private/settings" [queryParams]="{ section: 'account' }">
            Account
          </a>
          <p class="regular-10pt">Account preferences</p>
        </div>
      </div>
    </li>

    <li class="item">
      <div class="item-content">
        <mat-icon>notifications</mat-icon>

        <div class="text">
          <a class="regular link" routerLink="/private/settings" [queryParams]="{ section: 'notifications' }">
            Notifications
          </a>
          <p class="regular-10pt">Notification preferences</p>
        </div>
      </div>
    </li>

    <li class="item">
      <div class="item-content">
        <mat-icon>add_box</mat-icon>

        <div class="text">
          <a class="regular link" routerLink="/private/settings" [queryParams]="{ section: 'integrations' }">
            Integrations
          </a>
          <p class="regular-10pt">Manage integrations</p>
        </div>
      </div>
    </li>

    <li class="item">
      <div class="item-content">
        <mat-icon>people</mat-icon>

        <div class="text">
          <a
            class="regular link"
            routerLink="/private/settings"
            [queryParams]="{ section: 'colleagues' }"
          >
            Colleagues
          </a>
          <p class="regular-10pt">Manage colleagues</p>
        </div>
      </div>
    </li>

    <li class="item">
      <div class="item-content">
        <mat-icon>label</mat-icon>

        <div class="text">
          <a class="regular link" routerLink="/private/settings" [queryParams]="{ section: 'tags' }">
            Tags
          </a>
          <p class="regular-10pt">Add or remove tags</p>
        </div>
      </div>
    </li>
  </ul>
</mat-menu>
