<div class="top-bar">
  <ng-container *ngIf="!selectedEmail && !creatingEmail">
    <div class="selects">
      <div class="select header-select-wrap">
        <span class="label mr-2">Email type:</span>
        <mat-form-field class="select" [appearance]="'none'" floatLabel="never">
          <mat-select [formControl]="emailTypeSelect">
            <mat-option [value]="'All'">All</mat-option>
            <mat-option [value]="'Sent'">Sent</mat-option>
            <mat-option [value]="'Scheduled'">Scheduled</mat-option>
            <mat-option [value]="'Draft'">Draft</mat-option>
            <mat-option [value]="'Archived'">Archived</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="select m-0 header-select-wrap">
        <span class="label mr-2">Campaign:</span>
        <mat-form-field class="select" [appearance]="'none'">
          <mat-select [formControl]="campaignSelect">
            <mat-option [value]="'All'">All</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-icon class="search">search</mat-icon>
    </div>

    <div class="actions">
      <div class="action">
        <div class="action-label">Import</div>
        <mat-icon>cloud_download</mat-icon>
      </div>

      <div class="action">
        <div class="action-label">Switch columns</div>
        <mat-icon>view_column</mat-icon>
      </div>
    </div>

    <button type="button" tc-button (click)="createEmail()">
      Create email
    </button>
  </ng-container>

  <ng-container *ngIf="selectedEmail && !creatingEmail">
    <div class="email-view-top-bar">
      <div
        class="back-btn d-flex align-items-center"
        (click)="selectedEmail = null"
        *ngIf="layout !== layoutStates.mobile"
      >
        <mat-icon>arrow_back_ios</mat-icon>
        <span class="btn-text">Back to emails</span>
      </div>

      <button class="mobile" type="button" tc-button>
        Send again
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="creatingEmail && !selectedEmail">
    <div class="email-view-top-bar">
      <div
        class="back-btn d-flex align-items-center"
        (click)="creatingEmail = false"
        *ngIf="layout !== layoutStates.mobile"
      >
        <mat-icon>arrow_back_ios</mat-icon>
        Back to emails
      </div>

      <button class="mobile" type="button" tc-button>
        Send email
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="layout === layoutStates.mobile && (selectedEmail || creatingEmail)">
    <div class="mobile-top-bar-wrap">
      <div class="d-flex justify-content-between w-100">
        <div class="back-btn d-flex align-items-center d-block" (click)="backToEmails()">
          <mat-icon>arrow_back_ios</mat-icon>
          <span class="text">Back to emails</span>
        </div>

        <div class="actions mobile">
          <tc-circle-badge [size]="'lg'" [view]="'red'"></tc-circle-badge>
          <a class="action"><mat-icon (click)="deleteEmail()" class="delete-btn m-0">delete</mat-icon></a>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<table mat-table class="email-table" [dataSource]="emails$" *ngIf="!selectedEmail && !creatingEmail">
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <ng-container *ngIf="selectedData.isEmpty()">
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selectedData.hasValue() && isAllSelected()"
        >
        </mat-checkbox>
      </ng-container>

      <ng-container *ngIf="selectedData.hasValue()">
        <div class="counter" (click)="masterToggle()">
          {{ selectedData.selected.length }}
        </div>
      </ng-container>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? selectedData.toggle(row) : null"
        [checked]="selectedData.isSelected(row)"
      >
      </mat-checkbox>
    </td>
  </ng-container>

  <!--Status Column -->
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>
      <ng-container *ngIf="!selectedData.hasValue()">
        Status
      </ng-container>

      <div class="selected" *ngIf="selectedData.hasValue()">
        {{ selectedData.selected.length }} selected
      </div>
    </th>
    <td mat-cell class="status-td" *matCellDef="let email">
      <tc-badge [view]="badgeViews[email.status]"> {{ email.status }}</tc-badge>
    </td>
  </ng-container>

  <!--Subject Column -->
  <ng-container matColumnDef="subject">
    <th mat-header-cell *matHeaderCellDef>
      <ng-container *ngIf="!selectedData.hasValue()">
        Subject
      </ng-container>

      <ng-container *ngIf="selectedData.hasValue()">
        <div class="actions">
          <tc-circle-badge
            [ngClass]="{ 'mr-auto': layout !== layoutStates.desktop }"
            [size]="layout !== layoutStates.desktop ? 'lg' : 'md'"
            [view]="'red'"
          ></tc-circle-badge>

          <mat-icon class="action">edit</mat-icon>
          <mat-icon class="action delete-btn" (click)="deleteEmail()">delete</mat-icon>
        </div>
      </ng-container>
    </th>
    <td mat-cell class="subject-td" *matCellDef="let email">
      <a (click)="selectedEmail = email">{{ email.subject }}</a>
    </td>
  </ng-container>

  <!-- Last Updated Column -->
  <ng-container matColumnDef="lastUpdated">
    <th mat-header-cell *matHeaderCellDef>
      <ng-container *ngIf="!selectedData.hasValue()">
        Last updated
      </ng-container>
    </th>
    <td mat-cell *matCellDef="let email">{{ email.lastUpdated }}</td>
  </ng-container>

  <!-- Open Rate Column -->
  <ng-container matColumnDef="openRate">
    <th mat-header-cell class="open-rate-td" *matHeaderCellDef>
      <ng-container *ngIf="!selectedData.hasValue()">
        Open rate
      </ng-container>
    </th>
    <td mat-cell *matCellDef="let email">{{ email.openRate }}%</td>
  </ng-container>

  <tr class="header-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    class="table-row"
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    [ngClass]="{ selected: selectedData.isSelected(row) }"
    (click)="selectedData.toggle(row)"
  ></tr>
</table>

<div
  class="email-view-block"
  *ngIf="selectedEmail && !creatingEmail"
  [ngClass]="{ 'show-recipients': showRecipients && layout === layoutStates.desktop }"
>
  <app-email-view (back)="backToEmails()"  (openRecipients)="onShowRecipients($event)" [email]="selectedEmail"></app-email-view>

  <div class="recipients addition-block">
    <h3 class="title">Recipients</h3>

    <div class="search-wrap">
      <app-search
        [formControl]="recipientInput"
        [placeholder]="'Search recipient'"
        [bgColor]="'#F5F8FA'"
      ></app-search>
    </div>

    <div class="list-wrap">
      <ul class="recipients-list">
        <li class="recipient-item" *ngFor="let recipient of filteredRecipients$ | async">
          <span class="email">{{ recipient }}</span>
          <span class="actions">
            <a class="action"><mat-icon>edit</mat-icon></a>
            <a class="action"><mat-icon class="delete-btn">delete</mat-icon></a>
          </span>
        </li>
      </ul>
    </div>
  </div>
</div>

<div
  class="create-email-block"
  *ngIf="creatingEmail && !selectedEmail"
  [ngClass]="{ 'hide-components': layout !== layoutStates.desktop }"
>
  <app-create-email (back)="backToEmails()" (showComponents)="onShowEmailComponents()"></app-create-email>
  <div class="addition-block">
    <h3 class="title">Components</h3>

    <div class="search-wrap">
      <app-search
        [formControl]="emailComponentsInput"
        [placeholder]="'Search component'"
        [bgColor]="'#F5F8FA'"
      ></app-search>
    </div>

    <div class="components-list">
      <div class="component" *ngFor="let component of filteredEmailComponents$ | async">
        <div class="component-icon">
          <img [src]="component.icon" [alt]="component.title" />
        </div>

        <span class="title">
          {{ component.title }}
        </span>
      </div>
    </div>
  </div>
</div>
