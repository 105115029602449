import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { combineLatest, Observable, BehaviorSubject } from 'rxjs';
import { filter, map, startWith, pluck } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as layoutActions from '../../../store/actions/layout.actions';

import { BadgeStyles, ITask, TaskStatuses, TaskTypes } from '../../../interfaces/task';
import { IState } from '../../../interfaces/state';

import { TasksService } from '../../../services/tasks.service';
import { UsersService } from '../../../services/users.service';

import { CreateTaskComponent } from '../../../layout/components/create-task';
import { LayoutStates } from '../../../interfaces/layout-state';
import { DeleteDialogComponent } from '../../../layout/components/delete-dialog';
import { CreateUserComponent } from '../../../layout/components/create-user';

import {
  GetUsersGQL,
  EnabledLeadGQL
} from '../../../../generated/graphql';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  displayedColumns: string[];
  badgeViews: any;
  badgeArr: string[];

  tasks$: Observable<ITask[]>;
  type$: Observable<TaskTypes>;
  status$: Observable<TaskStatuses>;

  selectedData: SelectionModel<any>;

  dateSelect: FormControl;
  typeSelect: FormControl;
  dialogRef: MatDialogRef<CreateTaskComponent>;

  layout: LayoutStates;

  users$: Observable<any[]>;
  usersDS$: MatTableDataSource<any>;
  usersList = [];
  private usersTypesSource: BehaviorSubject<any>;

  length: Number = 100;
  pageSize = 60;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  currentPage = 0;
  offset = 0;
  text_search = null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  queryUsersfilter = this.getUsers.watch({ limit: this.pageSize, offset: this.offset, text_search: this.text_search});

  constructor(private activeRoute: ActivatedRoute,
    private store: Store<IState>,
    private _snackBar: MatSnackBar,
    private getUsers: GetUsersGQL,
    private enabledLead: EnabledLeadGQL,
    public tasksSv: TasksService,
    public userSv: UsersService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.load();
    this.badgeViews = BadgeStyles;
    this.badgeArr = Object.values(BadgeStyles);

    this.dateSelect = new FormControl('All');
    this.typeSelect = new FormControl('All');
    this.selectedData = new SelectionModel(true, []);

    this.store.subscribe(state => {
      const layout = state.layoutState.type;
      switch (layout) {
        case LayoutStates.desktop:
          this.displayedColumns = ['select', 'user', 'status', 'email'];
          break;
        case LayoutStates.tablet:
          this.displayedColumns = ['select', 'user', 'status', 'email'];
          break;
        case LayoutStates.mobile:
          this.displayedColumns = ['select', 'user', 'status'];
          break;
        default:
          this.displayedColumns = ['select', 'user', 'status', 'email'];
      }
    });
  }

  load(): void{
    this.queryUsersfilter = this.getUsers.watch({ limit: this.pageSize, offset: this.offset, text_search: this.text_search});
    this.users$ = this.queryUsersfilter.valueChanges.pipe(pluck('data', 'omnichannel_groups_users'));
    this.store.dispatch(layoutActions.setLoadingPage({ loading: true }));
    this.users$.subscribe(data => {
      console.log('sss');
      this.usersDS$ = new MatTableDataSource(data);
      this.usersList = data;
      this.store.dispatch(layoutActions.setLoadingPage({ loading: false }));
    });
  }

  isSelected(): boolean {
    const SELECTED = this.selectedData.selected.length;
    const ROWS = this.usersList.length;
    return SELECTED === ROWS;
  }

  masterToggle(): void {
    this.isSelected()
      ? this.selectedData.clear()
      : this.usersList.forEach(task => this.selectedData.select(task));
  }

  createTask(): void {
    this.dialogRef = this.dialog.open(CreateTaskComponent, {
      width: '435px',
      maxWidth: '91.5%',
      data: this.userSv.users$
    });

    this.dialogRef.afterClosed().subscribe(task => task && this.tasksSv.addTask(task));
  }

  addUser() {
    const dialogRef = this.dialog.open(CreateUserComponent, {
      width: '435px',
      maxWidth: '91.5%',
      data: {}
    });

    dialogRef.afterClosed().subscribe(data => {
      this.load();
      this.queryUsersfilter.refetch();
    });
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.offset = this.currentPage * this.pageSize;
    this.load();
    this.queryUsersfilter.refetch();
  }

  onSearch($event:string): void {
    this.text_search = $event == "" ? null : $event;
    this.load();
    this.queryUsersfilter.refetch();
  }

  enableLead(_enable: boolean): void {
    let _select = [];
    for (let element of this.selectedData.selected) {
        _select.push({user_id: element.user_id, enable_lead: _enable, group_id: element.group.id, lead_qtd: 0});
    }
    this.store.dispatch(layoutActions.setLoadingPage({ loading: true }));
    this.enabledLead.mutate({ itens: _select}).subscribe(v => {
      this.store.dispatch(layoutActions.setLoadingPage({ loading: false }));
      if(_enable){
        this._snackBar.open(`Usuário(s) habilitados com sucesso`, "Fechar", {
          duration: 2000,
        });
      } else{
        this._snackBar.open(`Usuário(s) desabilitados com sucesso`, "Fechar", {
          duration: 2000,
        });
      }
      this.selectedData.clear();
      this.queryUsersfilter.refetch();
    });
  }

  onDeleteTask(): void {
    const MSG = 'Are you sure you want to delete this task?';

    const REF = this.dialog.open(DeleteDialogComponent, {
      width: '91.5%',
      maxWidth: '435px',
      data: MSG
    });

    REF.afterClosed()
      .pipe(filter(res => !!res))
      .subscribe(() => {
        this.selectedData.selected.forEach(t => this.tasksSv.deleteTask(t));
        this.selectedData.clear();
      });
  }
}
