import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {Observable, throwError as observableThrowError} from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(private http: HttpClient) {}

  getData<T>(source: string): Observable<T> {
    return this.http.get<T>(source).pipe(catchError(this.handleError));
  }

  private handleError(error: any) {
    return observableThrowError(error.error || 'Server error');
  }
}
