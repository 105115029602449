<ng-container *ngIf="profile$ | async as profile">
  <div class="avatar-block">
    <div class="img-block">
      <img class="profile-img" [src]="profile.avatar" [alt]="profile.firstName" />

      <button *ngIf="editing" class="select-picture" mat-icon-button>
        <mat-icon svgIcon="img"></mat-icon>
      </button>

      <button class="edit-btn" [ngClass]="{ active: editing }" (click)="edit()">
        <mat-icon>{{ editing ? 'check' : 'edit' }}</mat-icon>
      </button>
    </div>

    <tc-badge [view]="'dark-blue'">{{ profile.role }}</tc-badge>
    <mat-divider [vertical]="true"></mat-divider>
  </div>

  <div class="info-block">
    <form class="form-block profile-form" [formGroup]="profileForm">
      <h3 class="sub-header">Informações básicas</h3>

      <mat-form-field [appearance]="!editing && 'none'">
        <mat-label>Nome</mat-label>
        <input [readonly]="!editing" matInput placeholder="Nome completo" formControlName="display_name" />
      </mat-form-field>

      <mat-form-field [appearance]="!editing && 'none'">
        <mat-label>E-mail</mat-label>
        <input [readonly]="!editing" matInput placeholder="E-mail" formControlName="email" />
      </mat-form-field>

      <!--<h3 class="sub-header">Additional information</h3>

      <mat-form-field [appearance]="!editing && 'none'">
        <mat-label>City</mat-label>
        <input [readonly]="!editing" matInput placeholder="City" formControlName="city" />
      </mat-form-field>

      <mat-form-field [appearance]="!editing && 'none'">
        <mat-label>State</mat-label>
        <input [readonly]="!editing" matInput placeholder="State" formControlName="state" />
      </mat-form-field>

      <mat-form-field [appearance]="!editing && 'none'">
        <mat-label>Country</mat-label>
        <input [readonly]="!editing" matInput placeholder="Country" formControlName="country" />
      </mat-form-field>

      <h3 class="sub-header">Job</h3>

      <mat-form-field [appearance]="!editing && 'none'">
        <mat-label>Company</mat-label>
        <input [readonly]="!editing" matInput placeholder="Company" formControlName="company" />
      </mat-form-field>-->
    </form>
  </div>

  <div class="password-block">
    <form class="form-block password-form" [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
      <h3 class="sub-header">Alterar senha</h3>
      <mat-form-field>
        <mat-label>Senha anterior</mat-label>
        <input
          matInput
          type="password"
          placeholder="Entre com sua senha atual"
          formControlName="prevPassword"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Digite uma nova senha</mat-label>
        <input matInput type="password" placeholder="Nova senha" formControlName="newPassword" />

        <ng-container *ngIf="newPassword.invalid && newPassword.touched">
          <mat-error @fadeIn>A senha deve ter pelo menos 6 caracteres</mat-error>
        </ng-container>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Confirme a nova senha</mat-label>
        <input
          matInput
          type="password"
          placeholder="Confirme a nova senha"
          formControlName="confirmPassword"
          [attr.disabled]="!editing"
        />

        <ng-container *ngIf="confirmPassword.invalid && confirmPassword.touched">
          <mat-error @fadeIn>As senhas não conferem</mat-error>
        </ng-container>
      </mat-form-field>

      <button [block]="true" [type]="'secondary'" tc-button>
        Alterar senha
      </button>
    </form>
  </div>
</ng-container>
