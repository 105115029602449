<mat-card class="mb-0">
  <mat-card-header>
    <h3 class="title">Suas preferências de conta</h3>
  </mat-card-header>

  <form [formGroup]="form">
    <div class="card-content">
      <div class="selects">
        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field>
              <mat-label>Fuso horário</mat-label>
              <mat-select [value]="'0'" formControlName="timezone">
                <mat-option [value]="'0'">GMT-3 - Horário Padrão de Brasília</mat-option>
                <mat-option [value]="'1'">GMT-2 - Ilha Fernando de Noronha</mat-option>
                <mat-option [value]="'2'">GMT-4 - Horário Padrão do Amazonas</mat-option>
                <mat-option [value]="'3'">GMT-5 - Horário Padrão do Acre</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field>
              <mat-label>Grupo</mat-label>
              <input type="text" matInput [value]="settings ? settings[0].group.name : ''" disabled>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field>
              <mat-label>Idioma</mat-label>
              <mat-select [value]="'PT-BR'" formControlName="currency">
                <mat-option [value]="'PT-BR'">Português</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field>
              <mat-label>Moeda</mat-label>
              <mat-select [value]="'R$'" formControlName="language">
                <mat-option [value]="'R$'">Real R$</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <button class="ml-auto" (click)="onSave()" tc-button>Salvar</button>
    </div>
  </form>
</mat-card>