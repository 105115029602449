<ul class="filter-list">
  <li class="list-item" *ngFor="let filter of filters">
    <a
      class="item-link"
      [routerLink]=""
      [queryParams]="{ filter: filter.value }"
      [ngClass]="{ active: filter.value === active }"
    >
      <tc-circle-badge [color]="filter.color" [size]="'sm'"></tc-circle-badge>
      <span class="filter-text">{{ filter.title}}</span>
    </a>
  </li>
</ul>
