import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material';

import { IState } from '../../interfaces/state';
import { LayoutStates } from '../../interfaces/layout-state';
import { IContact } from '../../interfaces/contact';

import { ContactsService } from '../../services/contacts.service';
import * as layoutActions from '../../store/actions/layout.actions';
import { DeleteDialogComponent } from '../../layout/components/delete-dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class PageContactsComponent implements OnInit, AfterViewInit {
  columns: string[];
  layoutState: LayoutStates;
  layoutStates: typeof LayoutStates;

  selectedData: SelectionModel<IContact>;

  companyFilter$: Observable<string>;
  companies$: Observable<string[]>;
  companySelect: FormControl;

  contacts$: MatTableDataSource<IContact>;

  selectedContact: IContact;
  creating: boolean;

  length: Number = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  currentPage = 0;

  personTypes = [];

  id: string;
  private sub: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // MatPaginator Output
  pageEvent: PageEvent;

  constructor(
    private store: Store<IState>,
    private contactSv: ContactsService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.store.dispatch(layoutActions.setPageTitle({ title: 'Contatos' }));
    this.store.dispatch(layoutActions.setPagePlaceholder({ placeholder: 'Procurar Nome, E-mail, Telefone ou Whatsapp' }));

    this.layoutStates = LayoutStates;
    this.selectedData = new SelectionModel(true, []);
    this.companySelect = new FormControl('All');

    //this.companies$ = this.contactSv.contacts$;

    this.companyFilter$ = this.companySelect.valueChanges.pipe(
      distinctUntilChanged(),
      startWith(this.companySelect.value)
    );

    this.contactSv.contacts$.subscribe(data => {
      this.contacts$ = new MatTableDataSource(data);
    });

    this.contactSv.contactsCount$.subscribe(data => {
      this.length = data;
    });

    this.contactSv.person_type$.subscribe(data => {
      this.personTypes = data;
    });

    this.contactSv.textFilter$.subscribe(filter => {
      this.currentPage = 0;
      this.contactSv.getContacts(this.length, this.currentPage, this.pageSize, null, filter);
      this.contactSv.refresh();
    });

    this.companyFilter$.subscribe(filter => {
      if (!filter || filter === 'All') {
        this.contactSv.getContacts(this.length, this.currentPage, this.pageSize);
        this.contactSv.refresh();
      }
      else {
        this.contactSv.getContacts(this.length, this.currentPage, this.pageSize, Number(filter));
        this.contactSv.refresh();
      }
    });

    this.sub = this.route.params.subscribe(params => {
      this.id = params['id']; // (+) converts string 'id' to a number
      if(this.id){
        this.contactSv.contactsOne$.subscribe(data => {
          this.selectedContact = data;
        });
        this.contactSv.getContactOne(this.id);
        this.contactSv.refreshOne();
      }
      // In a real app: dispatch action to load the details here.
   });

    //this.contacts$ = this.contactSv.contacts$;
    this.contactSv.getPersonTypes();
    this.contactSv.getContacts(this.length, this.currentPage, this.pageSize);
    /*this.contacts$ = combineLatest(this.contactSv.contacts$, this.companyFilter$).pipe(
      map(([contacts, filter]) => {
        if (!filter || filter === 'All') {
          return contacts;
        }

        return contacts.filter(c => c.companyName === filter);
      })
    );*/

    this.store.subscribe(state => {
      this.layoutState = state.layoutState.type;

      switch (this.layoutState) {
        case LayoutStates.desktop:
          this.columns = ['select', 'name', 'email', 'tipo', 'phone'];
          break;
        case LayoutStates.tablet:
          this.columns = ['select', 'name', 'email', 'tipo', 'phone'];
          break;
        default:
          this.columns = ['select', 'name', 'email'];
      }
    });
  }

  dutchRangeLabel(page: number, pageSize: number, length: number) {
    if (length == 0 || pageSize == 0) { return `0 de ${length}`; }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} de ${length}`;
  }

  ngAfterViewInit() {
    if(this.paginator){
      this.paginator._intl.itemsPerPageLabel = 'Registros por página';
      this.paginator._intl.lastPageLabel = 'Última página';
      this.paginator._intl.nextPageLabel = 'Próxima página';
      this.paginator._intl.previousPageLabel = 'Página anterior';
      this.paginator._intl.firstPageLabel = 'Primeira';
      this.paginator._intl.getRangeLabel = this.dutchRangeLabel;
    }
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.contactSv.getContacts(this.length, this.currentPage, this.pageSize);
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  goToContactDetails(id) {
    this.router.navigate(['private/contacts-details', id]); 
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selectedData.clear()
      : this.contacts$.data.forEach(c => this.selectedData.select(c));
  }

  isAllSelected(): boolean {
    const SELECTED = this.selectedData.selected.length;
    const ROWS = this.contacts$.data.length;
    return SELECTED === ROWS;
  }

  onBack(): void {
    this.creating = false;
    this.selectedContact = null;
    this.router.navigate(['private/contacts']); 
  }

  onDeleteContact(id: string): void {
    this.creating = false;
    this.selectedContact = null;
    this.contactSv.deleteContact(id);
  }

  onCreateContact(value: IContact): void {
    if(this.id && this.id !== ""){
      this.contactSv.updateContacts(this.id, value);
    } else{
      this.contactSv.addContacts(value);
    }
  }

  onCreateNota(value: string): void {
    this.contactSv.insertPerson(this.id, value);
  }

  deleteContacts(): void {
    const MSG =
      this.selectedData.selected.length > 1
        ? 'Are you sure you want to delete this contacts?'
        : 'Are you sure you want to delete this contact?';

    const REF = this.dialog.open(DeleteDialogComponent, {
      width: '91.5%',
      maxWidth: '435px',
      data: MSG
    });

    REF.afterClosed()
      .pipe(filter(res => res))
      .subscribe((res) => {
        this.selectedData.selected.forEach(c => {
          this.contactSv.deleteContact(c.id);
        });
        this.selectedData.clear();
      });
  }
}
