import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { IIntegration } from '../interfaces/integration';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {
  private integrationsSource: BehaviorSubject<IIntegration[]>;
  private integrationsUrl: string;

  integrations$: Observable<IIntegration[]>;

  constructor(private httpSv: HttpService) {
    this.integrationsUrl = '../../../assets/data/integrations.json';
    this.integrationsSource = new BehaviorSubject([]);
    this.integrations$ = this.integrationsSource.asObservable();

    this.httpSv
      .getData<IIntegration[]>(this.integrationsUrl)
      .subscribe(res => this.integrationsSource.next(res));
  }
}
