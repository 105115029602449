import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { IAccoutnInfo } from '../../../interfaces/account-info';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent implements OnInit {
  @HostBinding('class.account-info') true;
  @HostBinding('class.minimized') @Input() minimized: boolean;
  @HostBinding('class.mobile') @Input() mobile: boolean;

  @Input() info: IAccoutnInfo;

  constructor(public auth: AuthService) {}

  ngOnInit() {
    
  }
}
