<div class="header">
  <h3 mat-dialog-title class="mb-0">{{ deal.title }}</h3>
  <div class="options-headers">
    <tc-circle-badge class="status-icon" [view]="deal.status.color"></tc-circle-badge>
    <mat-icon (click)="closeDialog()">close</mat-icon>
  </div>
</div>

<mat-dialog-content class="content">
  <div class="info section">
    <div class="description section">
      <div>
        <div class="section-title-block">
          <h4 class="section-title">Descrição</h4>
          <mat-icon *ngIf="!editDescription" class="edit-btn" (click)="onEditDescription()">edit</mat-icon>
        </div>

        <div class="description-block section">
          <span class="description">
            <span *ngIf="!editDescription">{{descriptionText}}</span>
            <div *ngIf="editDescription" class="comment-field">
              <mat-form-field appearance="none" class="text-area no-border">
                <textarea matInput cdkTextareaAutosize [(ngModel)]="descriptionText" placeholder="Entre com a descrição" cdkAutosizeMinRows="4"
                  cdkAutosizeMaxRows="4">{{descriptionText}}</textarea>
              </mat-form-field>

              <div class="comment-actions">
                <div class="icons">
                </div>

                <div class="buttons">
                  <button class="send-button" tc-button [type]="'tertiary'" (click)="saveDescription()">Salvar</button>
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>
      <div>
        <form [formGroup]="contactForm" class="info-contact section">
          <div class="contatc">
            <div class="section-title-block">
              <h4 class="section-title">
                Contato
              </h4>
            </div>
            <div class="description-block section">
              <span class="description">
                <mat-form-field [appearance]="!(editPerson) && 'none'">
                  <mat-label>Nome</mat-label>
                  <input [readonly]="!editPerson" matInput placeholder="Nome do contato"
                    formControlName="name" />
                </mat-form-field>

                <mat-form-field [appearance]="!(editPerson) && 'none'">
                  <mat-label>Tipo do contato</mat-label>
                  <mat-select formControlName="ptype" [disabled]="!editPerson">
                    <mat-option *ngFor="let item of person_types$ | async" [value]="item.id">{{item.name}}</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field [appearance]="!(editPerson) && 'none'">
                  <mat-label>E-mail</mat-label>
                  <input [readonly]="!editPerson" matInput placeholder="E-mail do contato" formControlName="email" />
                </mat-form-field>

                <mat-form-field [appearance]="!(editPerson) && 'none'">
                  <mat-label>Telefone</mat-label>
                  <input [readonly]="!editPerson" matInput placeholder="Telefone do contato" [textMask]="{mask: mask}"
                    formControlName="phone" />
                </mat-form-field>
              </span>
            </div>
          </div>
          <div class="product">
            <div class="section-title-block">
              <h4 class="section-title">
                Produto
              </h4>
              <mat-icon *ngIf="!editPerson" class="edit-btn" (click)="onEditPerson()">edit</mat-icon>
              <mat-icon *ngIf="editPerson" class="edit-btn" (click)="saveContact()">save</mat-icon>
            </div>
            <div class="description-block section">
              <span class="description">
                <mat-form-field [appearance]="!(editPerson) && 'none'">
                  <mat-label>Produtos</mat-label>
                  <mat-select formControlName="products" [disabled]="!editPerson" multiple>
                    <mat-option *ngFor="let product of products$ | async" [value]="product.id">{{product.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="status section">
      <div class="section-title-block">
        <h4 class="section-title">

        </h4>
        <mat-icon *ngIf="!editStatus" class="edit-btn" (click)="onEditStatus()">edit</mat-icon>
        <mat-icon *ngIf="editStatus" class="edit-btn" (click)="saveStatus()">save</mat-icon>
      </div>

      <div class="description-block section">
        <span class="description">
          <form [formGroup]="statusForm" class="task-form">
            <mat-form-field [appearance]="!(editStatus) && 'none'">
              <mat-label>Responsável</mat-label>
              <mat-select formControlName="owner_id" [disabled]="!editStatus">
                <mat-option *ngFor="let item of leadsusers$ | async" [value]="item.user.id">{{item.user.display_name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field [appearance]="!(editStatus) && 'none'">
              <mat-label>Status</mat-label>
              <mat-select formControlName="status_id" [disabled]="!editStatus">
                <mat-option *ngFor="let item of pipelineStatus$ | async" [value]="item.id">{{item.name}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field  [appearance]="!(editStatus) && 'none'">
              <mat-label>Data de fechamento esperada</mat-label>
              <input matInput [readonly]="!editStatus" [matDatepicker]="picker" placeholder="Selecione uma data" formControlName="date_close" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>

              <ng-container *ngIf="date_close.invalid && (date_close.touched || date_close.dirty)">
                <mat-error @fadeIn>Selecione uma possível data de fechamento</mat-error>
              </ng-container>
            </mat-form-field>
          </form>
          <mat-divider></mat-divider>
          <div class="others">
            <div class="text-field">
              <span class="label">Criado em:</span>
              <span class="value">{{ getDate(deal.created_at) }}</span>
            </div>
            <div class="text-field">
              <span class="label">Alterado em:</span>
              <span class="value">{{ getDate(deal.updated_at) }}</span>
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>

  <!--<div class="attachments section">
    <div class="section-title-block">
      <h4 class="section-title">Attachments</h4>
    </div>

    <div class="attachment" *ngFor="let attachment of deal.attachments">
      <div class="img-block">
        <img [src]="attachment.img" [alt]="attachment.title" class="attachment-img" />
      </div>

      <div class="info-block">
        <div class="attachment-info">
          <h5 class="attachment-title">{{ attachment.title }}</h5>
          <span class="adding-date">{{ attachment.addingDate }}</span>
        </div>

        <div class="actions">
          <mat-icon class="edit-btn">edit</mat-icon>
          <mat-icon class="delete-btn">delete</mat-icon>
        </div>
      </div>
    </div>
  </div>-->

  <div class="leave-comment section">
    <div class="section-title-block">
      <h4 class="section-title">Deixe um comentário</h4>
    </div>

    <div class="comment-field">
      <mat-form-field appearance="none" class="text-area no-border">
        <textarea
          matInput
          [(ngModel)]="commentText"
          cdkTextareaAutosize
          placeholder="Entre com o comentário"
          cdkAutosizeMinRows="4"
          cdkAutosizeMaxRows="4"
        ></textarea>
      </mat-form-field>

      <div class="comment-actions">
        <div class="icons">
          <!--<mat-icon>title</mat-icon>
          <mat-icon>insert_link</mat-icon>
          <mat-icon>attach_file</mat-icon>-->
        </div>

        <div class="buttons">
          <button class="send-button" tc-button [type]="'tertiary'" (click)="saveComment()">Enviar</button>
        </div>
      </div>
    </div>
  </div>

  <div class="comments section">
    <div class="section-title-block">
      <h4 class="section-title">Comentários</h4>
    </div>

    <div class="comment-wrap" *ngFor="let comment of comments$ | async">
      <div class="sender">
        <img [src]="comment.user.avatar_url" [alt]="comment.user.display_name" class="sender-avatar" />
        <span class="sender-name">{{ comment.user.display_name }}</span>
        <div class="adding-date">{{ getDate(comment.created_at) }}</div>
      </div>

      <div class="comment">
        <div class="comment-text">
          {{ comment.comment }}
        </div>

        <div class="comment-actions">
          <!--<button class="reply-btn" tc-button [type]="'tertiary'">Responder</button>-->

          <div class="actions">
            <!--<mat-icon class="edit-btn">edit</mat-icon>-->
            <mat-icon class="delete-btn" (click)="onDeleteComment(comment.id)">delete</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-actions section mb-0" *ngIf="deal.actions.length > 0">
    <div class="section-title-block">
      <h4 class="section-title">Ações</h4>
    </div>

    <div class="buttons">
      <button mat-dialog-close *ngFor="let action of deal.actions" (click)="onActions(action.action.id)" tc-button>{{action.action.title}}</button>
    </div>
  </div>
</mat-dialog-content>