<app-logo [minimize]="minimized" [src]="logo" [minSrc]="minLogo"></app-logo>

<mat-divider *ngIf="!minimized && !mobile" class="divider"></mat-divider>

<div class="side-nav-wrap">
  <app-account-info
    [minimized]="minimized"
    [info]="accountInfo"
  ></app-account-info>

  <app-menu [mobile]="mobile" [minimized]="minimized" [menu]="menu | async"></app-menu>

  <app-sidebar-toggle
    *ngIf="!mobile"
    (stateChange)="changeNavState($event)"
    class="d-none d-lg-flex"
  ></app-sidebar-toggle>
</div>
