<div class="header">
  <h4 mat-dialog-title class="mb-0">Create task</h4>
  <mat-icon (click)="closeDialog()">close</mat-icon>
</div>

<mat-dialog-content class="content">
  <div class="statuses">
    <tc-badge
      *ngFor="let status of statuses"
      [ngClass]="{ active: taskStatus === status }"
      (click)="taskStatus = status"
      [view]="badgeViews[status]"
    >
      {{ status }}
    </tc-badge>
  </div>

  <form [formGroup]="taskForm" class="task-form">
    <mat-form-field class="full-width">
      <mat-label>Title</mat-label>
      <input matInput placeholder="Title" formControlName="title" />
      <ng-container *ngIf="title.invalid && (title.touched || title.dirty)">
        <mat-error @fadeIn>Please enter title</mat-error>
      </ng-container>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Type</mat-label>
      <mat-select formControlName="type">
        <mat-option *ngFor="let type of types" [value]="type">{{
          type
        }}</mat-option>
      </mat-select>

      <ng-container *ngIf="type.invalid && (type.touched || type.dirty)">
        <mat-error @fadeIn>Please select task type</mat-error>
      </ng-container>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Associated with</mat-label>
      <mat-select formControlName="user">
        <mat-option [value]="user.id" *ngFor="let user of users | async">
          <div class="user-option">
            <img class="user-img" [src]="user.avatar" [alt]="user.name" />
            <span class="user-name">{{ user.name }}</span>
          </div>
        </mat-option>
      </mat-select>

      <ng-container *ngIf="user.invalid && (user.touched || user.dirty)">
        <mat-error @fadeIn>Please select associated user</mat-error>
      </ng-container>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Due date</mat-label>
      <mat-select formControlName="dueDate">
        <mat-option [value]="'Today'">Today</mat-option>
        <mat-option [value]="'Tomorrow'">Tomorrow</mat-option>
        <mat-option [value]="'Next week'">Next week</mat-option>
        <mat-option [value]="'This month'">This month</mat-option>
      </mat-select>

      <ng-container
        *ngIf="dueDate.invalid && (dueDate.touched || dueDate.dirty)"
      >
        <mat-error @fadeIn>Please select task date</mat-error>
      </ng-container>
    </mat-form-field>
  </form>
</mat-dialog-content>

<button (click)="submitTask()" tc-button [block]="true">Create task</button>
