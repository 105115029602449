<div class="header">
  <h3 class="title">{{ deal.company }}</h3>

  <div class="actions">
    <mat-icon (click)="onDealEdit($event)" class="edit-btn">edit</mat-icon>
    <mat-icon (click)="onDeleteDeal($event)" class="delete-btn">delete</mat-icon>
  </div>
</div>

<div class="date-block">
  <span class="label">Close date:</span>
  <span class="date">{{ deal.closeDate }}</span>
</div>

<div class="footer">
  <div class="user">
    <div class="img"><img [src]="deal.associatedWith.avatar" [alt]="deal.associatedWith.name"></div>
    <span class="name">{{ deal.associatedWith.name }}</span>
  </div>

  <div class="price">${{ deal.price }}</div>
</div>
