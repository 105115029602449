import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { IContact } from '../interfaces/contact';
import { HttpService } from './http.service';

//GRAPH
import * as Query from '../graph-ql/queries';
import { Apollo, Subscription, QueryRef } from 'apollo-angular';
import * as Mutation from '../graph-ql/mutation';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store, select } from '@ngrx/store';
import { IState } from '../interfaces/state';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {
  private contactSource: BehaviorSubject<IContact[]>;
  private contactsUrl: string;

  contacts$: Observable<IContact[]>;

  person_type$: Observable<any[]>;
  private personTypesSource: BehaviorSubject<IContact[]>;

  private contactSourceCount: BehaviorSubject<Number>;
  contactsCount$: Observable<Number>;

  private contactSourceOne: BehaviorSubject<IContact>;
  contactsOne$: Observable<IContact>;

  textFilter$: Observable<string>;
  private textFilterSource: BehaviorSubject<string>;

  tagsFilter$: Observable<any>;
  private tagsFilterSource: BehaviorSubject<any>;

  private querySubscription: any;
  postsQuery: QueryRef<any>;

  private queryOneSubscription: any;
  postsOneQuery: QueryRef<any>;

  constructor(private httpSv: HttpService, private apollo: Apollo, private router: Router, private _snackBar: MatSnackBar, private store: Store<IState>) {
    this.contactsUrl = '../../assets/data/contacts.json';
    this.contactSource = new BehaviorSubject([]);
    this.contacts$ = this.contactSource.asObservable();

    this.personTypesSource = new BehaviorSubject([]);
    this.person_type$ = this.personTypesSource.asObservable();
    this.contactSourceCount = new BehaviorSubject(0);
    this.contactsCount$ = this.contactSourceCount.asObservable();
    this.textFilterSource = new BehaviorSubject("");
    this.textFilter$ = this.textFilterSource.asObservable();
    this.contactSourceOne = new BehaviorSubject({});
    this.contactsOne$ = this.contactSourceOne.asObservable();
    this.tagsFilterSource = new BehaviorSubject({});
    this.tagsFilter$ = this.tagsFilterSource.asObservable();

    this.store.subscribe(state => {
      this.textFilterSource.next(state.layoutState.pageTextsearch);
    });
    //this.httpSv.getData<IContact[]>(this.contactsUrl).subscribe(res => this.contactSource.next(res));
  }

  getContacts(length: Number, currentPage: number, pageSize: number, person_type?: number, text_search?: string): IContact[] {

    let _limit = pageSize;
    let _offset = currentPage * pageSize;
    let _likeText = text_search ? text_search : null;

    this.postsQuery = this.apollo.watchQuery<any>({
      query: Query.query_persons_aggregate,
      variables: { "teamid": Number(localStorage.getItem("teamid")), limit: _limit, offset: _offset, person_type: person_type, text_search: _likeText }
    });
    this.querySubscription = this.postsQuery
      .valueChanges
      .subscribe(({ data, loading }) => {
        this.contactSource.next(data.omnichannel_persons);
        this.contactSourceCount.next(data.omnichannel_persons_aggregate.aggregate.count);
      });

    /*this.querySubscription = this.apollo.watchQuery<any>({
      query: Query.query_persons_aggregate,
      variables: { "teamid": Number(localStorage.getItem("teamid")), limit: _limit, offset: _offset, person_type: null},
      pollInterval: 500,
    }).valueChanges
      .subscribe(({ data }) => {
        this.contactSource.next(data.omnichannel_persons);
        this.contactSourceCount.next(data.omnichannel_persons_aggregate.aggregate.count);
    });*/
    return this.contactSource.value;
  }

  refresh() {
    this.postsQuery.refetch()
  }

  deleteContact(id: string): void {
    const contacts = this.contactSource.value.filter(c => c.id !== id);
    this.contactSource.next(contacts);
  }

  getContactOne(id: string) {
    this.postsOneQuery = this.apollo.watchQuery<any>({
      query: Query.query_person_one,
      variables: { "id": id }
    });
    this.queryOneSubscription = this.postsOneQuery
      .valueChanges
      .subscribe(({ data, loading }) => {
        this.contactSourceOne.next(data)
      });
  }

  refreshOne() {
    this.postsOneQuery.refetch()
  }

  getPersonTypes() {
    this.apollo.watchQuery<any>({
      query: Query.query_person_type,
      variables: { "teamid": Number(localStorage.getItem("teamid")) }
    }).valueChanges
      .subscribe(({ data }) => {
        this.personTypesSource.next(data.omnichannel_person_type)
      });
  }

  getPersonTags() {
    this.apollo.watchQuery<any>({
      query: Query.query_tags,
      variables: {}
    }).valueChanges
      .subscribe(({ data }) => {
        this.tagsFilterSource.next(data.omnichannel_tags)
      });
  }

  addContacts(contact: IContact): void {
    contact.organization_id = Number(localStorage.getItem("teamid"));
    contact.owner_id = localStorage.getItem("user_id");
    /*if (contact.phone != "") {
      let _newphone = `${contact.phone.area}-${contact.phone.exchange}`
      delete contact.phone;
      contact.phone = _newphone;
    }
    if (contact.whatsapp != "") {
      let _newwhatsapp = `${contact.whatsapp.area}-${contact.whatsapp.exchange}`
      delete contact.whatsapp;
      contact.whatsapp = _newwhatsapp;
    }*/
    delete contact.id;
    this.apollo.mutate<any>({
      mutation: Mutation.insert_person,
      variables: contact
    }).subscribe(({ data }) => {
      this._snackBar.open(`Contato criado com sucesso`, "Fechar", {
        duration: 2000,
      });
    }, (error) => {
      console.log('there was an error sending the query', error);
      this._snackBar.open(`Ops: Erro ao gravar o contato. Erro:${error.message}`, "Fechar", {
        duration: 2000,
      });
    });
  }

  updateContacts(id: string, contact: IContact): void {
    contact.id = id;
    delete contact.owner_id;
    delete contact.organization_id;
    delete contact.avatar;
    this.apollo.mutate<any>({
      mutation: Mutation.update_person,
      variables: contact
    }).subscribe(({ data }) => {
      this._snackBar.open(`Contato alterado com sucesso`, "Fechar", {
        duration: 2000,
      });
    }, (error) => {
      this._snackBar.open(`Ops: Erro ao gravar o contato. Erro:${error.message}`, "Fechar", {
        duration: 20000,
      });
    });
  }

  insertPerson(person_id: string, description: string): void {
    let _note = {person_id: person_id, description: description};
    this.apollo.mutate<any>({
      mutation: Mutation.insert_person_note,
      variables: _note
    }).subscribe(({ data }) => {
      this._snackBar.open(`Nota cadastrado com sucesso`, "Fechar", {
        duration: 2000,
      });
    }, (error) => {
      this._snackBar.open(`Ops: Erro ao gravar a nota. Erro:${error.message}`, "Fechar", {
        duration: 20000,
      });
    });
  }

  insertTags(person_id: string, tag_id: string): void {
    let _note = {person_id: person_id, tag_id: tag_id};
    this.apollo.mutate<any>({
      mutation: Mutation.insert_person_tags,
      variables: _note
    }).subscribe(({ data }) => {
      this._snackBar.open(`Entiqueta adicionado com sucesso`, "Fechar", {
        duration: 2000,
      });
    }, (error) => {
      this._snackBar.open(`Ops: Erro ao gravar a entiqueta. Erro:${error.message}`, "Fechar", {
        duration: 20000,
      });
    });
  }

  deleteTags(person_id: string, tag_id: string): void {
    let _note = {person_id: person_id, tag_id: tag_id};
    this.apollo.mutate<any>({
      mutation: Mutation.delete_person_tags,
      variables: _note
    }).subscribe(({ data }) => {
      this._snackBar.open(`Entiqueta removida com sucesso`, "Fechar", {
        duration: 2000,
      });
    }, (error) => {
      this._snackBar.open(`Ops: Erro ao gravar a entiqueta. Erro:${error.message}`, "Fechar", {
        duration: 20000,
      });
    });
  }
}
