import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, ViewChild, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable, BehaviorSubject} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { TagsService } from '../../../services/tags.service';

/**
 * @title Chips Autocomplete
 */
@Component({
  selector: 'tags-input',
  templateUrl: 'tags-input.component.html',
  styleUrls: ['tags-input.component.scss'],
})
export class TagsInputComponent implements OnInit {
  tags = [
    {
      "view": "yellow",
      "text": "Active"
    },
    {
      "view": "green",
      "text": "Completed"
    },
    {
      "view": "red",
      "text": "Ended"
    }
  ];

  tagsSelect$: Observable<any[]>;
  private tagsSelectSource: BehaviorSubject<any[]>;
  tagsSelect:any[] = [];

  tags$: Observable<string>;

  @Input() editing: boolean = false;
  @Output() create: EventEmitter<void>;
  @Output() delete: EventEmitter<void>;

  @Input() set selected(value: any) {
     this.tagsSelect = value;
  }
  
  get selected(): any {
      return this.tagsSelect;
  }

  constructor(private tagsSv: TagsService,){
    this.create = new EventEmitter<any>();
    this.delete = new EventEmitter<any>();
    this.tagsSelectSource = new BehaviorSubject([]);
    this.tagsSelect$ = this.tagsSelectSource.asObservable();
  }

  ngOnInit() {
    this.tags$ = this.tagsSv.tagsFilter$;
    this.tagsSv.getPersonTags();
  }

  onSelect($tag_value){
    let _tag = {tag: $tag_value};
    this.create.emit($tag_value.id);
    this.tagsSelect.push(_tag);
    this.tagsSelectSource.next(this.tagsSelect);
  }

  onRemove($index, tag_id){
    this.delete.emit(tag_id);
    this.tagsSelect.splice($index,1);
    this.tagsSelectSource.next(this.tagsSelect);
  }

  onEnable($tag_id){
    if(this.tagsSelect && this.tagsSelect.length > 0){
      let _tag = this.tagsSelect.find(s => s.id == $tag_id);
      if(_tag){
        return true;
      }
      return false;
    }
    return false;
  }
}