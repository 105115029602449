import { Injectable } from '@angular/core';

import { HttpService } from './http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { IUser } from '../interfaces/user';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService  {
  userSubject$: BehaviorSubject<IUser[]>;
  users$: Observable<IUser[]>;
  usersUrl: string;

  constructor(private http: HttpService) {
    this.usersUrl = '../../../assets/data/users.json';
    this.userSubject$ = new BehaviorSubject([]);
    this.users$ = this.userSubject$.asObservable();

    this.http
      .getData<IUser[]>(this.usersUrl)
      .subscribe(users => this.userSubject$.next(users));
  }
}
