import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  EventEmitter,
  Output
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { Apollo, gql } from 'apollo-angular';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  Omnichannel_Pipeline_Card,
  Omnichannel_Pipeline_List,
  GetCardsforListsGQL,
  GetCardsforListsQuery,
  GetSubscriptionCardsGQL,
  GetSubscriptionCardsSubscription,
  ChangeOrderCardsGQL,
  ChangeOrderCardsMutation,
  ChangeListOrdersCardsGQL,
  DisableCardGQL
} from '../../../../generated/graphql'

import { IState } from '../../../interfaces/state';
import * as layoutActions from '../../../store/actions/layout.actions';

//remorver
import { DealStatus, DealType, IDeal } from '../../../interfaces/deal';
import { filter, map, startWith, tap, switchMap, pluck } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription, BehaviorSubject } from 'rxjs';

import { ShareService } from '../../../services/share.service';
import { EditDealComponent } from '../edit-deal';
import { OpenedDealComponent } from '../opened-deal';
import { DeleteDialogComponent } from '../../components/delete-dialog';

const SUBSCRIBE_TO_ONLINE_USERS = gql`
 subscription GetSubscriptionCards($list_id: uuid!) {
    omnichannel_pipeline_card(where: {pipeline_list_id: {_eq: $list_id}}, order_by: {order: asc}) {
      date_close
      description
      id
    }
 }`

@Component({
  selector: 'app-list-leads',
  templateUrl: './list-leads.component.html',
  styleUrls: ['./list-leads.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListLeadsComponent implements OnInit {

  @Input() data: Omnichannel_Pipeline_List;
  @Input() previousList: string;
  @Input() nextList: string;

  @HostBinding('class.deals-list-wrap') someField: boolean = true;

  loading: Observable<boolean>;
  private loadingTypesSource: BehaviorSubject<boolean>;

  dragging: boolean;
  wonDeals: IDeal[];
  lostDeals: IDeal[];

  pipelineCards: any;

  pipelineCards$: Observable<GetSubscriptionCardsSubscription['omnichannel_pipeline_card']> = new Observable();
  private queryPipelineCard: any;

  cards$: Observable<any[]>;
  private cardsTypesSource: BehaviorSubject<any>;

  dealStatuses: typeof DealStatus;

  connectTo = [];

  length: Number = 100;
  pageSize = 50;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  currentPage = 0;
  offset = 0;
  text_search = null;
  disabled = false;

  @Output() deleteClick: EventEmitter<boolean>;

  constructor(
    private store: Store<IState>,
    private getPipelineCards: GetSubscriptionCardsGQL,
    private changeOrderCards: ChangeOrderCardsGQL,
    private changeListOrderCards: ChangeListOrdersCardsGQL,
    private disableCard: DisableCardGQL,
    private dialog: MatDialog,
    private ss: ShareService,
    private apollo: Apollo) { }

  ngOnInit() {
    this.deleteClick = new EventEmitter<boolean>();
    this.loadingTypesSource = new BehaviorSubject(true);
    this.loading = this.loadingTypesSource.asObservable();
    this.pipelineCards = [];
    this.wonDeals = [];
    this.lostDeals = [];
    this.dealStatuses = DealStatus;

    this.getPipelineCards.subscribe({ list_id: this.data.id, limit: this.pageSize })
      .subscribe(({ data }) => {
        this.cardsTypesSource.next(data.omnichannel_pipeline_card);
        this.pipelineCards = data.omnichannel_pipeline_card;
        this.loadingTypesSource.next(false);
      });

    this.store.subscribe(state => {
      if (state.layoutState.pageTextsearch != "" && state.layoutState.pageTypeSearch == "contact") {
        this.loadingTypesSource.next(true);
        this.text_search = state.layoutState.pageTextsearch;
        this.getPipelineCards.subscribe({ list_id: this.data.id, limit: this.pageSize, person_name: this.text_search })
          .subscribe(({ data }) => {
            this.cardsTypesSource.next(data.omnichannel_pipeline_card);
            this.pipelineCards = data.omnichannel_pipeline_card;
            this.loadingTypesSource.next(false);
          });
      } else if (state.layoutState.pageTextsearch != "" && state.layoutState.pageTypeSearch == "onwer") {
        if (state.layoutState.pageTextsearch == "all") {
          this.loadingTypesSource.next(true);
          this.text_search = state.layoutState.pageTextsearch;
          this.getPipelineCards.subscribe({ list_id: this.data.id, limit: this.pageSize })
            .subscribe(({ data }) => {
              this.cardsTypesSource.next(data.omnichannel_pipeline_card);
              this.pipelineCards = data.omnichannel_pipeline_card;
              this.loadingTypesSource.next(false);
            });
        }
        else {
          this.loadingTypesSource.next(true);
          this.text_search = state.layoutState.pageTextsearch;
          this.getPipelineCards.subscribe({ list_id: this.data.id, limit: this.pageSize, owner_id: this.text_search })
            .subscribe(({ data }) => {
              this.cardsTypesSource.next(data.omnichannel_pipeline_card);
              this.pipelineCards = data.omnichannel_pipeline_card;
              this.loadingTypesSource.next(false);
            });
        }
      }
    });

    //this.queryPipelineCard = this.getPipelineCards.watch({ list_id: this.data.id });
    //this.pipelineCards$ = this.queryPipelineCard.valueChanges.pipe(pluck('data', 'omnichannel_pipeline_card'));
    this.cardsTypesSource = new BehaviorSubject([]);
    this.cards$ = this.cardsTypesSource.asObservable();
    /*this.pipelineCards$.subscribe(state => {
      this.cardsTypesSource.next(state);
      this.pipelineCards = state;
    });*/
  }

  get isLoading() {
    return this.loadingTypesSource.asObservable();
  }

  onScrollDown() {
    this.offset = this.offset + this.pageSize;
    if (this.data.cards_aggregate.aggregate.count <= this.offset) {
      //this.disabled = true;
    }
    //this.loadingTypesSource.next(true);
    this.getPipelineCards.subscribe({ list_id: this.data.id, limit: this.pageSize, person_name: this.text_search, offset: this.offset })
      .subscribe(({ data }) => {
        //this.disabled = true;
        this.pipelineCards.push(...data.omnichannel_pipeline_card);
        this.cardsTypesSource.next(this.pipelineCards);
      });
  }

  drop(event: CdkDragDrop<Omnichannel_Pipeline_Card[]>, status: any): void {
    if (event.container === event.previousContainer) {
      this.store.dispatch(layoutActions.setLoadingPage({ loading: true }));
      let changeCard = [...event.container.data];
      moveItemInArray(changeCard, event.previousIndex, event.currentIndex);
      this.pipelineCards = changeCard;
      this.cardsTypesSource.next(changeCard);
      this.changeOrderCards.mutate({ list_id: this.data.id, card_id: event.item.data.id, order: event.currentIndex }).subscribe(v => {
        console.log('save', v);
        this.store.dispatch(layoutActions.setLoadingPage({ loading: false }));
      });
    } else {
      this.store.dispatch(layoutActions.setLoadingPage({ loading: true }));
      let prevchangeCard = [...event.previousContainer.data];
      let changeCard = [...event.container.data];
      this.changeListOrderCards.mutate({ list_id: this.data.id, card_id: event.item.data.id, order: event.currentIndex }).subscribe(v => {
        console.log('save', v);
        this.store.dispatch(layoutActions.setLoadingPage({ loading: false }));
      });
      transferArrayItem(
        prevchangeCard,
        changeCard,
        event.previousIndex,
        event.currentIndex
      );
      this.pipelineCards = changeCard;
      this.cardsTypesSource.next(changeCard);
      //event.item.data.status = status;
    }
  }

  editDeal(deal: IDeal) {
    const dialogRef = this.dialog.open(EditDealComponent, {
      width: '435px',
      maxWidth: '91.5%',
      data: {
        users: this.data,
        deal
      }
    });

    dialogRef
      .afterClosed()
      .pipe(filter(res => !!res))
      .subscribe(res => {
        //this.dealsSv.editDeal(res);
      });
  }

  openDeal(deal: any): void {

    deal["actions"] = this.data.actions;

    this.dialog.open(OpenedDealComponent, {
      width: '950px',
      maxWidth: '91.5%',
      height: '91vh',
      data: deal,
      autoFocus: false
    });
  }

  deleteDeal(event: any): void {
    if (event.isdelete) {
      this.store.dispatch(layoutActions.setLoadingPage({ loading: true }));
      this.disableCard.mutate({ id: event.deal }).subscribe(() => {
        this.store.dispatch(layoutActions.setLoadingPage({ loading: false }));
      });
    }
  }

  onDragging(): void {
    this.dragging = true;
  }

  onRelease(): void {
    this.dragging = false;
  }
}
