import { Component, OnInit } from '@angular/core';

export interface Tile {
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'page-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class PageGridComponent implements OnInit {
  tiles: Tile[];

  constructor() {
    this.tiles = [
      { text: '1', cols: 3, rows: 1 },
      { text: '2', cols: 1, rows: 2 },
      { text: '3', cols: 1, rows: 1 },
      { text: '4', cols: 2, rows: 1 },
    ];
  }

  ngOnInit() {}
}
