<mat-card class="mb-0">
  <mat-card-header>
    <div class="title-block">
      <h3 class="title">Gerenciar usuários</h3>

      <button class="p-0" [type]="'tertiary'" (click)="addUser()" tc-button>Adicionar usuário<mat-icon svgIcon="add"></mat-icon></button>
    </div>

    <div class="search-wrap">
      <app-search [block]="true" [local]="true" (searchChange)="onSearch($event)" [placeholder]="'Procure por usuários'" [bgColor]="'#F5F8FA'"></app-search>
    </div>
  </mat-card-header>

  <div class="users-list-wrap">
    <table mat-table class="tasks-table" [dataSource]="usersDS$" class="table-user">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container *ngIf="selectedData.isEmpty()">
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selectedData.hasValue() && isSelected()">
            </mat-checkbox>
          </ng-container>

          <ng-container *ngIf="selectedData.hasValue()">
            <div class="counter" (click)="masterToggle()">
              {{ selectedData.selected.length }}
            </div>
          </ng-container>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectedData.toggle(row) : null"
            [checked]="selectedData.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- User Column -->
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container *ngIf="!selectedData.hasValue()">
            Nome
          </ng-container>
          <div class="selected" *ngIf="selectedData.hasValue()">
            {{ selectedData.selected.length }} selecionado
          </div>
        </th>
        <td mat-cell class="user-td" *matCellDef="let task">
          <img [src]="task.user.avatar_url" [alt]="task.user.display_name" class="user-img" />
          <span class="user-label"> {{ task.user.display_name }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container *ngIf="!selectedData.hasValue()">
            Hab. Lead
          </ng-container>
          <ng-container *ngIf="selectedData.hasValue()">
            <div class="actions">
              <mat-icon (click)="enableLead(true)" matTooltip="Habilitar para receber lead" class="action">thumb_up</mat-icon>
              <mat-icon (click)="enableLead(false)" matTooltip="Disabilitar para receber lead" class="action">thumb_down</mat-icon>
              <mat-icon matTooltip="Remover usuários" class="action delete-btn">delete</mat-icon>
            </div>
          </ng-container>
        </th>
        <td mat-cell class="status-td" *matCellDef="let task">
          <!--<tc-badge [view]="badgeViews[task.status]"> {{ task.status }}</tc-badge>-->
          <tc-badge *ngIf="task.enable_lead" view="green" style="width: 90px;">Sim</tc-badge>
          <tc-badge *ngIf="!task.enable_lead" view="red" style="width: 90px;">Não</tc-badge>
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell class="actions-th" *matHeaderCellDef>
          <ng-container *ngIf="!selectedData.hasValue()">
            E-mail
          </ng-container>
        </th>
        <td mat-cell class="title-td" *matCellDef="let task">{{ task.user.account.email }}</td>
      </ng-container>

      <tr class="header-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr class="table-row" mat-row *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{ selected: selectedData.isSelected(row) }" (click)="selectedData.toggle(row)"></tr>
    </table>
    <mat-paginator *ngIf="usersDS$.data.length > 0" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
      (page)="pageEvent = handlePage($event)" #paginator></mat-paginator>
    <div class="not-found" *ngIf="usersDS$.data.length === 0">
      Nenhum registro foi encontrado!
      </div>
  </div>
</mat-card>