<mat-card class="mb-0">
  <mat-card-header>
    <div class="title-block">
      <h3 class="title">Integrations</h3>

      <div class="selects d-flex">
        <div class="select-wrap">
          <span class="label mr-2">Category:</span>
          <mat-form-field class="select" [appearance]="'none'" floatLabel="never">
            <mat-select [value]="'All'">
              <mat-option [value]="'All'">All</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="select-wrap">
          <span class="label mr-2">Filter:</span>
          <mat-form-field class="select" [appearance]="'none'">
            <mat-select [value]="'All'">
              <mat-option [value]="'All'">All</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="search-wrap">
      <app-search [block]="true" [placeholder]="'Search integrations'" [bgColor]="'#F5F8FA'"></app-search>
    </div>
  </mat-card-header>

  <h4 class="list-title">Your integrations</h4>
  <div class="list-wrap">
    <div class="integrations-list">
      <div class="row">
        <div
          class="col-sm-12 col-md-6 col-xl-4"
          *ngFor="let integration of connectedIntegrations$ | async"
        >
          <div class="integration">
            <div class="logo-block">
              <img [src]="integration.img" [alt]="integration.title" />
            </div>

            <h3 class="title">{{ integration.title }}</h3>

            <div class="desc-block">
              <span class="desc">{{ integration.desc }}</span>
            </div>

            <div class="footer">
              <div class="state-block">
                <button [type]="'tertiary'" class="connect-btn" [ngClass]="{ connected: integration.connected }" tc-button>
                  {{ integration.connected ? 'Connected' : 'Connect' }}
                </button>
              </div>

              <mat-icon class="delete-btn">delete</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <h4 class="list-title">Popular integrations</h4>
  <div class="list-wrap mb-0">
    <div class="integrations-list">
      <div class="row">
        <div
          class="col-sm-12 col-md-6 col-xl-4"
          *ngFor="let integration of popularIntegrations$ | async"
        >
          <div class="integration">
            <div class="logo-block">
              <img [src]="integration.img" [alt]="integration.title" />
            </div>

            <h3 class="title">{{ integration.title }}</h3>

            <div class="desc-block">
              <span class="desc">{{ integration.desc }}</span>
            </div>

            <div class="footer">
              <div class="state-block">
                <button [type]="'tertiary'" [ngClass]="{ connected: integration.connected }" tc-button>
                  {{ integration.connected ? 'Connected' : 'Connect' }}
                </button>
              </div>

              <mat-icon class="delete-btn">delete</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>
