import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

import { IChatResponse, IChat, IMessage } from '../interfaces/chat';
import { UsersService } from './users.service';
import { HttpService } from './http.service';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private isChatSelectedSource: BehaviorSubject<boolean>;
  private chatResponse$: Observable<IChatResponse[]>;
  private selectedChatSource: BehaviorSubject<IChat>;
  private chatSource: BehaviorSubject<IChat[]>;
  private chatUrl: string;

  chatList$: Observable<IChat[]>;
  selectedChat$: Observable<IChat>;
  isChatSelected$: Observable<boolean>;

  constructor(private httpSv: HttpService, private usersSv: UsersService) {
    this.isChatSelectedSource = new BehaviorSubject(true);
    this.selectedChatSource = new BehaviorSubject(null);
    this.chatSource = new BehaviorSubject([]);

    this.chatList$ = this.chatSource.asObservable();
    this.selectedChat$ = this.selectedChatSource.asObservable();
    this.isChatSelected$ = this.isChatSelectedSource.asObservable();

    this.chatUrl = '../../assets/data/chat-messages.json';

    this.chatResponse$ = this.httpSv.getData<IChatResponse[]>(this.chatUrl);

    combineLatest(this.chatResponse$, this.usersSv.users$)
      .pipe(
        map(([chat, users]) =>
          chat.map(con => {
            const { membersIds, ...conversation } = con;

            return {
              ...conversation,
              members: con.membersIds.map(memberId => users.find(user => user.id === memberId))
            };
          })
        )
      )
      .subscribe(chat => {
        this.chatSource.next(chat);
        this.selectedChatSource.next(chat[0]);
      });
  }

  sendMessage(message: IMessage, id: any): void {
    const CHAT_SOURCE = this.chatSource.value;
    const CHAT_INDEX = CHAT_SOURCE.findIndex(chat => chat.id === id);
    const SELECTED_CHAT = CHAT_SOURCE[CHAT_INDEX];
    CHAT_SOURCE[CHAT_INDEX] = { ...SELECTED_CHAT, messages: [...SELECTED_CHAT.messages, message] };

    this.chatSource.next([...CHAT_SOURCE]);
  }

  selectChat(chat: IChat): void {
    this.selectedChatSource.next(chat);
    this.isChatSelectedSource.next(true);
  }

  deselectChat(): void {
    this.isChatSelectedSource.next(false);
  }
}
