import { Component, OnInit } from '@angular/core';

import { UsersService } from '../../../services/users.service';
import { Observable } from 'rxjs';
import { IUser } from '../../../interfaces/user';
import { ISelectOption } from '../../../interfaces/permission-option';

@Component({
  selector: 'app-colleagues',
  templateUrl: './colleagues.component.html',
  styleUrls: ['./colleagues.component.scss']
})
export class ColleaguesComponent implements OnInit {
  users$: Observable<IUser[]>;
  selectOptions: ISelectOption[];
  constructor(private userSv: UsersService) {}

  ngOnInit() {
    this.users$ = this.userSv.users$;
    this.selectOptions = [
      {
        text: 'Admin',
        value: 'admin',
        view: 'dark-blue'
      },
      {
        text: 'Read',
        value: 'read',
        view: 'gray'
      },
      {
        text: 'Edit',
        value: 'edit',
        view: 'yellow'
      }
    ];
  }
}
