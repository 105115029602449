import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IState } from '../../../interfaces/state';
import { Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as layoutActions from '../../../store/actions/layout.actions';

import {
  UpdatePreferencesGQL
} from '../../../../generated/graphql'

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  @Input() settings: any;
  form: FormGroup;

  constructor(private updatePreferences: UpdatePreferencesGQL,
    private store: Store<IState>,
    private _snackBar: MatSnackBar,) { }

  ngOnInit() {
    console.log(this.settings);
    this.form = new FormGroup({
      timezone: new FormControl(this.settings[0].timezone, [Validators.required]),
      currency: new FormControl(this.settings[0].currency, [Validators.required]),
      language: new FormControl(this.settings[0].language, [Validators.required]),
    });
  }

  onSave() {
    if (this.form.invalid) {
      return;
    }
    this.store.dispatch(layoutActions.setLoadingPage({ loading: true }));
    let _save = this.form.value;
    _save["user_id"] = localStorage.getItem("user_id");

    this.updatePreferences.mutate(_save).subscribe(v => {
      this.store.dispatch(layoutActions.setLoadingPage({ loading: false }));
      this._snackBar.open(`Preferências alterada com sucesso`, "Fechar", {
        duration: 2000,
      });
    });
  }

}
