import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { FateModule, FateMaterialModule } from 'fate-editor';

import { UiModule } from '../ui/ui.module';

import { PrivateLayoutComponent } from './private';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ContentEditableFormDirective } from '../directive/content-editable-form.directive';

import {
  MatProgressBarModule,
  MatFormFieldModule,
  MatButtonModule,
  MatMenuModule,
  MatBadgeModule,
  MatIconModule,
  MatListModule,
  MatInputModule,
  MatDividerModule,
  MatCardModule,
  MatSelectModule,
  MatDialogModule,
  MatTabsModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCheckboxModule,
  MatAutocompleteModule,
  MatChipsModule,
  MatTableModule,
  MatTooltipModule,
} from '@angular/material';

import { TopNavComponent } from './components/top-nav';
import { SearchComponent } from './components/search';
import { NotificationsComponent } from './components/notifications';
import { SideBarComponent } from './components/side-bar';

import { LogoComponent } from './components/logo';
import { MenuComponent } from './components/menu';
import { CreateTaskComponent } from './components/create-task';
import { FilterListComponent } from './components/filter-list';
import { AccountInfoComponent } from './components/account-info';
import { SideBarToggleComponent } from './components/side-bar-toggle';
import { SettingsComponent } from './components/settings/settings.component';
import { TaskListComponent, TaskListItemComponent } from './components/task-list';
import { EmailViewComponent } from './components/email-view';
import { RecipientsComponent } from './components/recipients';
import { RecipientsDialogComponent } from './components/recipients-dialog';
import { CreateEmailComponent } from './components/create-email';
import { EmailComponentsComponent } from './components/email-components';
import { ContactViewComponent } from './components/contact-view';
import { DeleteDialogComponent } from './components/delete-dialog';
import { DealComponent } from './components/deal';
import { LeadComponent } from './components/lead';
import { CreateDealComponent } from './components/create-deal/create-deal.component';
import { AccountComponent } from './components/account';
import { IntegrationsComponent } from './components/integrations';
import { ColleaguesComponent } from './components/colleagues';
import { TagsComponent } from './components/tags';
import { NotificationsMenuComponent } from './components/notifications-menu';
import { ChatComponent, ChatListComponent, DialogComponent } from './components/chat';
import { EditDealComponent } from './components/edit-deal';
import { OpenedDealComponent } from './components/opened-deal';
import { OpenedTaskComponent } from './components/opened-task';
import { MyTelInput } from './components/tel-input';
import { PersonTypeInput } from './components/person-type-input';
import { TagsInputComponent } from './components/tags-input';
import { NgxQuillModule } from '@dimpu/ngx-quill'
import { ListLeadsComponent } from './components/list-leads';
import { CreateUserComponent } from './components/create-user';
import { NgxLoadingModule } from 'ngx-loading';
import { UserComponent } from './components/user';
import { ScrollingModule} from '@angular/cdk/scrolling';
import { InfiniteScrollModule } from '@thisissoon/angular-infinite-scroll';
//import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

@NgModule({
  declarations: [
    PrivateLayoutComponent,
    NotificationsComponent,
    SearchComponent,
    TopNavComponent,
    SideBarComponent,
    LogoComponent,
    AccountInfoComponent,
    MenuComponent,
    FilterListComponent,
    SideBarToggleComponent,
    SettingsComponent,

    TaskListComponent,
    TaskListItemComponent,
    CreateTaskComponent,
    EmailViewComponent,
    RecipientsComponent,
    RecipientsDialogComponent,
    CreateEmailComponent,
    EmailComponentsComponent,
    ContactViewComponent,
    DeleteDialogComponent,
    DealComponent,
    LeadComponent,
    CreateDealComponent,
    CreateUserComponent,
    AccountComponent,
    IntegrationsComponent,
    ColleaguesComponent,
    TagsComponent,
    NotificationsMenuComponent,
    ChatComponent,
    DialogComponent,
    ChatListComponent,
    EditDealComponent,
    OpenedDealComponent,
    OpenedTaskComponent,
    MyTelInput,
    PersonTypeInput,
    TagsInputComponent,
    ListLeadsComponent,
    UserComponent,
    ContentEditableFormDirective
  ],
  imports: [
    CommonModule,
    UiModule,
    MatMenuModule,
    RouterModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatInputModule,
    MatSelectModule,
    MatCardModule,
    MatProgressBarModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatCheckboxModule,
    TextMaskModule,
    FateModule,
    FateMaterialModule,
    MatAutocompleteModule,
    MatChipsModule,
    NgxQuillModule,
    DragDropModule,
    NgxSkeletonLoaderModule,
    NgxLoadingModule,
    MatTableModule,
    MatTooltipModule,
    ScrollingModule,
    InfiniteScrollModule,
    FroalaEditorModule.forRoot(), FroalaViewModule.forRoot()
  ],
  exports: [
    TaskListComponent,
    SearchComponent,
    EmailViewComponent,
    RecipientsComponent,
    CreateEmailComponent,
    ContactViewComponent,
    DealComponent,
    LeadComponent,
    AccountComponent,
    NotificationsComponent,
    IntegrationsComponent,
    ColleaguesComponent,
    TagsComponent,
    ChatComponent,
    MyTelInput,
    PersonTypeInput,
    TagsInputComponent,
    ListLeadsComponent,
    UserComponent
  ],
  providers: [MatDatepickerModule],
  entryComponents: [
    CreateTaskComponent,
    RecipientsDialogComponent,
    EmailComponentsComponent,
    DeleteDialogComponent,
    CreateDealComponent,
    CreateUserComponent,
    EditDealComponent,
    OpenedDealComponent,
    OpenedTaskComponent
  ]
})
export class LayoutModule {}
