import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ISelectOption } from '../../../interfaces/permission-option';

import { trigger, state, style, animate, transition } from '@angular/animations';

const shrink = trigger('shrink', [
  state(
    'active',
    style({
      height: '*',
      opacity: 1,
      display: 'block'
    })
  ),
  state(
    'inactive',
    style({
      height: 0,
      opacity: 0,
      display: 'none'
    })
  ),
  transition('inactive => active', animate('200ms ease-in-out')),
  transition('active => inactive', animate('200ms ease-in'))
]);

@Component({
  selector: 'tc-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  animations: [shrink]
})
export class TCSelectComponent implements OnInit {
  @HostBinding('class.tc-select') true;
  @HostBinding('class.dark-blue') get darkBlue() {
    return this.selectedValue.view === 'dark-blue';
  }
  @HostBinding('class.grey') get grey() {
    return this.selectedValue.view === 'grey';
  }
  @HostBinding('class.yellow') get yellow() {
    return this.selectedValue.view === 'yellow';
  }

  @Input() value: string;
  @Input() options: ISelectOption[];

  selectedValue: ISelectOption;
  opened: boolean;
  constructor() {
    this.options = [];
    this.value = '';
  }

  ngOnInit() {
    this.selectedValue = this.options.find(option => option.value === this.value);
  }

  displayedOptions(arr: ISelectOption[], selected: string): ISelectOption[] {
    const filterOptions = (opt: ISelectOption) => opt.value !== selected;
    return this.options.filter(filterOptions);
  }

  open(): void {
    this.opened = !this.opened;
  }

  select(opt: ISelectOption): void {
    this.selectedValue = opt;
    this.opened = false;
  }

  selectBoxClass(value: ISelectOption): any {
    return { [value.view]: value.view };
  }
}
