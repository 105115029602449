import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BadgeStyles, IDeal } from '../../../interfaces/deal';
import { map, pluck, startWith, debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import dayjs from 'dayjs'
import { IState } from '../../../interfaces/state';
import { Store } from '@ngrx/store';
import * as layoutActions from '../../../store/actions/layout.actions';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  GetPipelineStatusQuery,
  GetPipelineStatusGQL,
  GetProductsQuery,
  GetProductsGQL,
  GetPersonFilterQuery,
  GetPersonFilterGQL,
  GetPersonTypesGQL,
  GetPersonTypesQuery,
  InsertCardPipelineMutation,
  InsertProductPipelineMutation,
  InsertCardPipelineIdPersonMutation,
  InsertCardPipelineGQL,
  InsertProductPipelineGQL,
  InsertCardPipelineIdPersonGQL,
  UpdatePersonGQL,
  UpdateCardStatusGQL,
  UpdateCardDescriptionGQL,
  InsertCommentCardGQL,
  DeleteCommentGQL,
  GetCommentsCardGQL,
  ChangeListOrdersCardsGQL,
  GetUsersLeadsGQL,
  GetUsersLeadsQuery
} from '../../../../generated/graphql'

@Component({
  selector: 'app-opened-deal',
  templateUrl: './opened-deal.component.html',
  styleUrls: ['./opened-deal.component.scss']
})
export class OpenedDealComponent implements OnInit {
  badgeViews: any;

  statusForm: FormGroup;
  contactForm: FormGroup;
  productForm: FormGroup;

  editPerson = false;
  editProduct = false;
  editStatus = false;
  editDescription = false;

  descriptionText = "";
  commentText = "";

  public mask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];

  pipelineStatus$: Observable<GetPipelineStatusQuery['omnichannel_pipeline_status']>;
  products$: Observable<GetProductsQuery['omnichannel_products']>;
  persons$: Observable<GetPersonFilterQuery['omnichannel_persons']>;
  person_types$: Observable<GetPersonTypesQuery['omnichannel_person_type']>;
  leadsusers$: Observable<GetUsersLeadsQuery['omnichannel_groups_users']>;

  private queryStatus = this.getStatus.watch({});
  private queryProducts = this.getProducts.watch({});
  private queryPersonTypes = this.getPersonTypes.watch({});
  private queryUserLeadTypes = this.getUserLeads.watch({});

  comments$: Observable<any[]>;
  private commentsTypesSource: BehaviorSubject<any>;

  constructor(
    private dialogRef: MatDialogRef<OpenedDealComponent>,
    @Inject(MAT_DIALOG_DATA) public deal: any,
    private store: Store<IState>,
    private _snackBar: MatSnackBar,
    private getStatus: GetPipelineStatusGQL,
    private getProducts: GetProductsGQL,
    private getContacts: GetPersonFilterGQL,
    private getPersonTypes: GetPersonTypesGQL,
    private insertCardProduct: InsertProductPipelineGQL,
    private updatePerson: UpdatePersonGQL,
    private updateStatus: UpdateCardStatusGQL,
    private updateDescription: UpdateCardDescriptionGQL,
    private insertComment: InsertCommentCardGQL,
    private deleteComment: DeleteCommentGQL,
    private getComment: GetCommentsCardGQL,
    private getUserLeads: GetUsersLeadsGQL,
    private changeListOrderCards: ChangeListOrdersCardsGQL,
  ) { }

  ngOnInit() {
    this.badgeViews = BadgeStyles;
    this.pipelineStatus$ = this.queryStatus.valueChanges.pipe(pluck('data', 'omnichannel_pipeline_status'));
    this.products$ = this.queryProducts.valueChanges.pipe(pluck('data', 'omnichannel_products'));
    this.person_types$ = this.queryPersonTypes.valueChanges.pipe(pluck('data', 'omnichannel_person_type'));
    this.leadsusers$ = this.queryUserLeadTypes.valueChanges.pipe(pluck('data', 'omnichannel_groups_users'));

    this.statusForm = new FormGroup({
      status_id: new FormControl(this.deal.status.id),
      date_close: new FormControl(this.deal.date_close, [Validators.required]),
      owner_id: new FormControl(this.deal.owner_id, [Validators.required]),
      //ptype: new FormControl('', [Validators.required]),
    });

    let _products = [];
    _products = this.deal.products.map(v => {
      return v.product.id;
    });

    this.contactForm = new FormGroup({
      email: new FormControl(this.deal.person.email),
      phone: new FormControl(this.deal.person.phone),
      ptype: new FormControl(this.deal.person.person_type, [Validators.required]),
      name: new FormControl(this.deal.person.name, [Validators.required]),
      products: new FormControl(_products, [Validators.required]),
    });

    this.commentsTypesSource = new BehaviorSubject({});
    this.comments$ = this.commentsTypesSource.asObservable();

    this.getComment.subscribe({ card_id: this.deal.id })
    .subscribe(({ data }) => {
      this.commentsTypesSource.next(data.omnichannel_pipeline_comments);
    });

    this.descriptionText = this.deal.description;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onEditStatus(): void {
    this.editStatus = true;
  }

  getDate(value: string): string {
    return dayjs(value).format('DD/MM/YYYY HH:mm');
  }

  onEditProduct(): void {
    this.editProduct = true;
  }

  onEditPerson(): void {
    this.editPerson = true;
  }

  onEditDescription(): void {
    this.editDescription = true;
  }

  //Altera o cadastro de pessoa
  saveContact(): void {
    if (this.contactForm.invalid) {
      return;
    }
    this.store.dispatch(layoutActions.setLoadingPage({ loading: true }));
    let _dataSave = JSON.parse(JSON.stringify(this.contactForm.value));
    _dataSave["id"] = this.deal.person.id;
    _dataSave["avatar"] = "https://ui-avatars.com/api/?name=" + _dataSave.name;
    delete _dataSave.products;
    this.updatePerson.mutate(_dataSave).subscribe(v => {
      this.store.dispatch(layoutActions.setLoadingPage({ loading: false }));
      this._snackBar.open(`Pessoa alterada com sucesso`, "Fechar", {
        duration: 2000,
      });
      this.editPerson = false;
    });
  }

  //Altera o cadastro de pessoa
  saveStatus(): void {
    if (this.statusForm.invalid) {
      return;
    }
    this.store.dispatch(layoutActions.setLoadingPage({ loading: true }));
    let _dataSave = JSON.parse(JSON.stringify(this.statusForm.value));
    _dataSave["id"] = this.deal.id;
    delete _dataSave.products;
    this.updateStatus.mutate(_dataSave).subscribe(v => {
      this.store.dispatch(layoutActions.setLoadingPage({ loading: false }));
      this._snackBar.open(`Status alterado com sucesso`, "Fechar", {
        duration: 2000,
      });
      this.editStatus = false;
    });
  }

  //Altera a descrição do card
  saveDescription(): void {
    if (this.descriptionText == "" && this.descriptionText == null) {
      this.editDescription = false;
      return;
    }
    this.store.dispatch(layoutActions.setLoadingPage({ loading: true }));
    let _dataSave = { id: this.deal.id, description: this.descriptionText };
    this.updateDescription.mutate(_dataSave).subscribe(v => {
      this.store.dispatch(layoutActions.setLoadingPage({ loading: false }));
      this._snackBar.open(`Descrição alterada com sucesso`, "Fechar", {
        duration: 2000,
      });
      this.editDescription = false;
    });
  }

  //Inseri um comentário
  saveComment(): void {
    if (this.commentText == "" && this.commentText == null) {
      return;
    }
    this.store.dispatch(layoutActions.setLoadingPage({ loading: true }));
    let _dataSave = { card_id: this.deal.id, comment: this.commentText, user_id: localStorage.getItem("user_id")};
    this.insertComment.mutate(_dataSave).subscribe(v => {
      this.store.dispatch(layoutActions.setLoadingPage({ loading: false }));
      this._snackBar.open(`Comentário cadastrado com sucesso`, "Fechar", {
        duration: 2000,
      });
      this.commentText == ""
    });
  }

  onDeleteComment($id: string): void {
    this.store.dispatch(layoutActions.setLoadingPage({ loading: true }));
    let _dataSave = {id: $id};
    this.deleteComment.mutate(_dataSave).subscribe(v => {
      this.store.dispatch(layoutActions.setLoadingPage({ loading: false }));
      this._snackBar.open(`Comentário removido com sucesso`, "Fechar", {
        duration: 2000,
      });
      this.commentText == ""
    });
  }

  onActions($list_id: string): void {
    this.store.dispatch(layoutActions.setLoadingPage({ loading: true }));
    this.changeListOrderCards.mutate({ list_id: $list_id, card_id: this.deal.id, order: 0}).subscribe(v => {
      this._snackBar.open(`Negócio movido com sucesso.`, "Fechar", {
        duration: 2000,
      });
      this.store.dispatch(layoutActions.setLoadingPage({ loading: false }));
      this.dialogRef.close();
    });
  }

  get date_close() {
    return this.statusForm.get('date_close');
  }
  get ptype() {
    return this.contactForm.get('ptype');
  }

  get email() {
    return this.contactForm.get('email');
  }

  get phone() {
    return this.contactForm.get('phone');
  }

  get name_person() {
    return this.contactForm.get('name_person');
  }

  get products() {
    return this.contactForm.get('products');
  }
}
