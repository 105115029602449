<ng-container *ngIf="!selectedContact && !creating">
  <div class="top-bar">
    <div class="selects">
      <div class="d-flex align-items-center header-select-wrap">
        <span class="label mr-2">Tipo de contato:</span>
        <mat-form-field class="select" [appearance]="'none'">
          <mat-select [formControl]="companySelect">
            <mat-option [value]="'All'">Todos</mat-option>

            <mat-option *ngFor="let company of personTypes" [value]="company.value">
              {{ company.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-icon class="search">Procurar</mat-icon>
    </div>

    <button class="create-task-btn" type="button" tc-button (click)="creating = true">
      Add contato
    </button>
  </div>

  <div class="mat-elevation-z8">
    <table mat-table matSort matSortActive="name" class="contacts-table" [dataSource]="contacts$">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container *ngIf="selectedData.isEmpty()">
            <mat-checkbox (change)="$event ? masterToggle() : null"
              [checked]="selectedData.hasValue() && isAllSelected()">
            </mat-checkbox>
          </ng-container>

          <ng-container *ngIf="selectedData.hasValue()">
            <div class="counter" (click)="masterToggle()">
              {{ selectedData.selected.length }}
            </div>
          </ng-container>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectedData.toggle(row) : null"
            [checked]="selectedData.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          <ng-container *ngIf="!selectedData.hasValue()">
            Name
          </ng-container>

          <div class="selected d-flex  align-items-center" *ngIf="selectedData.hasValue()">
            {{ selectedData.selected.length }} selecionado

            <ng-container *ngIf="layoutState !== layoutStates.mobile">
              <mat-icon class="ml-4 delete-btn" (click)="deleteContacts()">delete</mat-icon>
            </ng-container>
          </div>
        </th>
        <td mat-cell class="name-td" *matCellDef="let contact">
          <div class="user-wrap" (click)="goToContactDetails(contact.id)">
            <img class="contact-img" [src]="contact.avatar" [alt]="contact.name" />
            <span class="name">{{ contact.name }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container *ngIf="!selectedData.hasValue()">
            Email
          </ng-container>
        </th>
        <td mat-cell class="email-td" *matCellDef="let contact">
          {{ contact.email }}
        </td>
      </ng-container>

      <ng-container matColumnDef="tipo">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container *ngIf="!selectedData.hasValue()">
            Tipo
          </ng-container>
        </th>
        <td mat-cell *matCellDef="let contact">
          {{ contact.ptypes.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container *ngIf="!selectedData.hasValue()">
            Telefone
          </ng-container>
        </th>
        <td mat-cell *matCellDef="let contact">
          {{ contact.phone }}
        </td>
      </ng-container>

      <tr class="header-row" mat-header-row *matHeaderRowDef="columns"></tr>
      <tr class="table-row" mat-row *matRowDef="let row; columns: columns"
        [ngClass]="{ selected: selectedData.isSelected(row) }" (click)="selectedData.toggle(row)"></tr>
    </table>
    <mat-paginator *ngIf="contacts$.data.length > 0" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
    (page)="pageEvent = handlePage($event)" #paginator></mat-paginator>
    <div *ngIf="contacts$.data.length === 0">
      Nenhum registro foi encontrado!
      </div>
  </div>
</ng-container>

<div class="contact-view-block" *ngIf="selectedContact && !creating">
  <app-contact-view (delete)="onDeleteContact($event)" (back)="onBack()" (create)="onCreateContact($event)" (createNote)="onCreateNota($event)" [contact]="selectedContact" [person_id]="id"></app-contact-view>
</div>

<div class="create-contact-block" *ngIf="creating && !selectedContact">
  <app-contact-view (back)="onBack()" (create)="onCreateContact($event)" [creating]="true"></app-contact-view>
</div>