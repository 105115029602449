import { Component } from '@angular/core';
import InfiniteScroll from 'ngx-infinite-scroll';

@Component({
  selector: 'app-infinite',
  template: `
    <div
      class="search-results"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollUpDistance]="1.5"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScrollDown()"
      (scrolledUp)="onScrollUp()"
    ></div>
  `,
})
export class AppInfiniteComponent {
  onScrollDown() {
    console.log('scrolled down!!');
  }

  onScrollUp() {
    console.log('scrolled up!!');
  }
}