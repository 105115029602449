import { Component, Inject, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { map, pluck, startWith, debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import { DealType, BadgeStyles } from '../../../interfaces/deal';
import { fadeIn } from '../../../animations/form-error';
import { IUser } from '../../../interfaces/user';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IState } from '../../../interfaces/state';
import { Store } from '@ngrx/store';
import * as layoutActions from '../../../store/actions/layout.actions';
import { AuthService } from '../../../services/auth.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';

import {
  ActiveAccountGQL,
  UpdateSubcriptionUserGQL,
  InsertMemberGQL,
  InsertRolesUserGQL,
  InsertGroupGQL
} from '../../../../generated/graphql'

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
  animations: [fadeIn]
})
export class CreateUserComponent implements OnInit {
  userForm: FormGroup;
  person_id_value = '';

  hide = true;

  constructor(
    private dialogRef: MatDialogRef<CreateUserComponent>,
    @Inject(MAT_DIALOG_DATA) public users: any,
    private _snackBar: MatSnackBar,
    private activeUser: ActiveAccountGQL,
    private changeUser: UpdateSubcriptionUserGQL,
    private insertMember: InsertMemberGQL,
    private insertRoles: InsertRolesUserGQL,
    private insertGroups: InsertGroupGQL,
    public auth: AuthService,
    private store: Store<IState>,
    private http: HttpClient
  ) { }

  ngOnInit() {

    this.initTaskForm();
  }

  initTaskForm(): void {
    this.userForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  submitDeal(): void {

    if (this.userForm.invalid) {
      return;
    }

    this.store.dispatch(layoutActions.setLoadingPage({ loading: true }));

    var pass = { "email": this.userForm.value.email, "password": this.userForm.value.password };
    const authHeader = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${localStorage.getItem('access_token')}`)

    let change = this.http.post(`https://auth.donit.io/auth/register`, pass, { headers: authHeader })
      .subscribe(
        data => {
          let res: any = data;
          let user_id = res.user.id;
          let avatar = `https://ui-avatars.com/api/?name=${this.userForm.value.name}&background=random`;
          this.activeUser.mutate({ id: user_id }).subscribe(v => {

          });
          this.changeUser.mutate({ id: user_id, display_name: this.userForm.value.name, avatar_url: avatar}).subscribe(v => {

          });
          this.insertMember.mutate({ user_id: user_id }).subscribe(v => {

          });
          this.insertRoles.mutate({ user_id: user_id }).subscribe(v => {

          });
          this.insertGroups.mutate({ user_id: user_id }).subscribe(v => {
            this.dialogRef.close();
          });
          this._snackBar.open(`Usuário cadastrado com sucesso.`, "Fechar", {
            duration: 2000,
          });
          this.store.dispatch(layoutActions.setLoadingPage({ loading: false }));
        },
        error => {
          this._snackBar.open(`Ops: Problema ao cadastrar o usuário.`, "Fechar", {
            duration: 2000,
          });
        }
      );

    /*

    let _dataSave = JSON.parse(JSON.stringify(this.dealForm.value));

    if(_dataSave.status_id == ""){
      this._snackBar.open(`Ops: Você precisa selecionar o Status.`, "Fechar", {
        duration: 2000,
      });
      return;
    }

    _dataSave["list_id"] = this.list_id;
    _dataSave["organization_id"] = Number(localStorage.getItem("teamid"));
    _dataSave["owner_id"] = localStorage.getItem("user_id");
    _dataSave["order"] = 0;

    //Salva os produtos
    let _products = JSON.parse(JSON.stringify(_dataSave.products));
    let _productsNew = { itens: []};
    let index = 0;
    for (let prods in _products) {
      let _amount = _dataSave[this.qtdProduct[index].name];
      delete _dataSave[this.qtdProduct[index].name];
      _productsNew.itens.push({"pipeline_card_id": "", "product_id": _products[prods].id, "amount": _amount});
      index++;
    }

    if(this.newPerson){
      delete _dataSave.person_id;
      delete _dataSave.status;
      delete _dataSave.products;
      _dataSave["avatar"] = "https://ui-avatars.com/api/?name="+_dataSave.name_person;
      this.insertCard.mutate(_dataSave).subscribe(v => {
        let _productsSave = {itens : _productsNew.itens.map(function(itens) {
          itens["pipeline_card_id"] = v.data.insert_omnichannel_pipeline_card_one.id;
          return itens;
        })};
        this.changeOrderCards.mutate({ list_id: this.list_id, card_id: v.data.insert_omnichannel_pipeline_card_one.id, order: 0}).subscribe(v => {
          console.log('save', v);
          this.store.dispatch(layoutActions.setLoadingPage({ loading: false }));
        });
        this.insertCardProduct.mutate(_productsSave).subscribe(v => {
          this._snackBar.open(`Negócio criado com sucesso`, "Fechar", {
            duration: 2000,
          });
          this.dialogRef.close(this.dealForm.value);
        });
      });
    }
    else{
      delete _dataSave.name_person;
      delete _dataSave.email;
      delete _dataSave.phone;
      delete _dataSave.status;
      delete _dataSave.ptype;
      delete _dataSave.products;
      _dataSave.person_id = this.person_id_value;
      this.insertCardwithID.mutate(_dataSave).subscribe(v => {
        let _productsSave = {itens : _productsNew.itens.map(function(itens) {
          itens["pipeline_card_id"] = v.data.insert_omnichannel_pipeline_card_one.id;
          return itens;
        })};
        this.changeOrderCards.mutate({ list_id: this.list_id, card_id: v.data.insert_omnichannel_pipeline_card_one.id, order: 0}).subscribe(v => {
          console.log('save', v);
          this.store.dispatch(layoutActions.setLoadingPage({ loading: false }));
        });
        this.insertCardProduct.mutate(_productsSave).subscribe(v => {
          this._snackBar.open(`Négocio criado com sucesso`, "Fechar", {
            duration: 2000,
          });
          this.dialogRef.close(this.dealForm.value);
        });
      });
    }*/
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  get name() {
    return this.userForm.get('name');
  }

  get email() {
    return this.userForm.get('email');
  }

  get password() {
    return this.userForm.get('password');
  }
}
