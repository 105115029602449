<div>
  <div class="tags">
    <div class="tag" *ngFor="let tag of tagsSelect;let i=index">
      <tc-badge [view]="tag.tag.color">{{ tag.tag.description }}</tc-badge>
      <mat-icon class="action delete" *ngIf="editing" (click)="onRemove(i, tag.tag.id)">delete</mat-icon>
    </div>
    <div class="actions" *ngIf="editing">
      <div class="action" [matMenuTriggerFor]="menu">
        <mat-icon>label_outline</mat-icon>
        <div class="action-label">Entiqueta</div>
      </div>
    </div>
  </div>
  <mat-menu #menu="matMenu" class="my-menu">
    <button mat-menu-item *ngFor="let item of tags$ | async" (click)="onSelect(item)" [hidden]="onEnable(item.id)">
      <tc-badge [view]="item.color" style="min-width: 150px;">{{item.description}}</tc-badge>
    </button>
    <span *ngIf="(tags$ | async).length === 0" class="">Nenhuma entiqueta encontrada</span>
  </mat-menu>
</div>