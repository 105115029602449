import { Action, createReducer, on } from '@ngrx/store';

import * as layout from '../actions/layout.actions';

import { ILayoutState, LayoutStates } from '../../interfaces/layout-state';

export const initialState: ILayoutState = {
  type: LayoutStates.desktop
};

const layoutReducer = createReducer(
  initialState,
  on(layout.setDesktop, state => ({ ...state, type: LayoutStates.desktop })),
  on(layout.setTablet, state => ({ ...state, type: LayoutStates.tablet })),
  on(layout.setMobile, state => ({ ...state, type: LayoutStates.mobile })),
  on(layout.resetPageData, state => ({ type: state.type })),
  on(layout.setPageTitle, (state, { title }) => ({ ...state, pageTitle: title })),
  on(layout.setLoadingPage, (state, { loading }) => ({ ...state, loading: loading })),
  on(layout.setPagePlaceholder, (state, { placeholder }) => ({
    ...state,
    pagePlaceholder: placeholder
  })),
  on(layout.setSearchContent, (state, { textsearch, typeSearch }) => ({
    ...state,
    pageTextsearch: textsearch,
    pageTypeSearch: typeSearch
  }))
);

export function reducer(state: ILayoutState, action: Action): ILayoutState {
  return layoutReducer(state, action);
}
