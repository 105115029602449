<div class="header">
  <div class="labels">
    <div class="label" *ngIf="tasks">
      {{ getCompleted(tasks).length }} task <span class="green">completed</span> out of {{ tasks.length }}
    </div>
    <div class="week">
      <span class="label">Show:</span>
      <mat-form-field class="header-select select" [appearance]="'none'">
        <mat-select [formControl]="weekSelect">
          <mat-option [value]="'Prev week'">Prev Week</mat-option>
          <mat-option [value]="'This week'">This Week</mat-option>
          <mat-option [value]="'Next week'">Next Week</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <mat-progress-bar [value]="progress" class="green"></mat-progress-bar>
</div>

<div class="current-date">
   <span class="date">23 December, </span><span class="day">Sunday</span>
</div>

<div class="week-view">
  <div
    class="day-view"
    [ngClass]="{ current: dayView.current, selected: dayView.selected }"
    *ngFor="let dayView of week"
  >
    <span class="day">{{ dayView.day }}</span>
    <span class="date">{{ dayView.date }}</span>
  </div>
</div>

<mat-divider></mat-divider>

<div class="task-list">
  <app-task-list-item
    *ngFor="let task of tasks | slice:0:3"
    (deleteTask)="onDeleteTask($event)"
    (openTask)="onOpenTask($event)"
    [task]="task"
  ></app-task-list-item>
</div>

<div class="show-more text-center">
  <button class="mx-auto" tc-button [type]="'tertiary'">Show more</button>
</div>
