<div class="title-block">
  <h3 class="title">Recipients</h3>

  <mat-icon (click)="closeDialog()">close</mat-icon>
</div>
<div class="search-wrap">
  <app-search
    [formControl]="filterInput"
    [placeholder]="'Search recipient'"
    [bgColor]="'#F5F8FA'"
  ></app-search>
</div>

<div class="list-wrap">
  <ul class="recipients-list">
    <li class="recipient-item" *ngFor="let recipient of filteredRecipients$ | async">
      <div class="recipient-item-content">
        <span class="email">{{ recipient }}</span>
        <span class="actions">
        <a class="action">
          <mat-icon>edit</mat-icon>
        </a>
        <a class="action delete">
          <mat-icon>delete</mat-icon>
        </a>
      </span>
      </div>
    </li>
  </ul>
</div>
