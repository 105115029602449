export interface ILayoutState {
  type: LayoutStates;
  pageTitle?: string;
  pagePlaceholder?: string;
  pageTextsearch?: string;
  pageTypeSearch?: string;
  loading?: boolean;
}

export enum LayoutStates {
  mobile = 'Mobile',
  tablet = 'Tablet',
  desktop = 'Desktop'
}
