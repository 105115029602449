import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as layoutActions from '../../store/actions/layout.actions';

import { BadgeStyles, ITask, TaskStatuses, TaskTypes } from '../../interfaces/task';
import { IState } from '../../interfaces/state';

import { TasksService } from '../../services/tasks.service';
import { UsersService } from '../../services/users.service';

import { CreateTaskComponent } from '../../layout/components/create-task';
import { LayoutStates } from '../../interfaces/layout-state';
import { DeleteDialogComponent } from '../../layout/components/delete-dialog';

@Component({
  selector: 'page-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class PageTasksComponent implements OnInit {
  displayedColumns: string[];
  badgeViews: any;
  badgeArr: string[];

  tasks$: Observable<ITask[]>;
  type$: Observable<TaskTypes>;
  status$: Observable<TaskStatuses>;

  selectedData: SelectionModel<ITask>;

  dateSelect: FormControl;
  typeSelect: FormControl;
  dialogRef: MatDialogRef<CreateTaskComponent>;

  layout: LayoutStates;
  constructor(
    private activeRoute: ActivatedRoute,
    private store: Store<IState>,
    public tasksSv: TasksService,
    public userSv: UsersService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.store.dispatch(layoutActions.setPageTitle({ title: 'Tasks' }));
    this.store.dispatch(layoutActions.setPagePlaceholder({ placeholder: 'Search for a task' }));

    this.badgeViews = BadgeStyles;
    this.badgeArr = Object.values(BadgeStyles);

    this.dateSelect = new FormControl('All');
    this.typeSelect = new FormControl('All');
    this.selectedData = new SelectionModel(true, []);

    this.status$ = this.activeRoute.queryParams.pipe(
      map(p => p.filter),
      startWith(this.activeRoute.snapshot.queryParams.filter)
    );

    this.type$ = this.typeSelect.valueChanges.pipe(startWith(this.typeSelect.value));

    this.tasks$ = combineLatest(this.status$, this.type$, this.tasksSv.tasks$).pipe(
      map(([status, type, tasks]) => {
        let filteredTasks = [...tasks];

        if (status) {
          filteredTasks = tasks.filter(task => task.status === status);
        }

        if (type !== TaskTypes.all) {
          filteredTasks = tasks.filter(task => task.type === type);
        }

        return filteredTasks;
      })
    );

    this.store.subscribe(state => {
      const layout = state.layoutState.type;
      switch (layout) {
        case LayoutStates.desktop:
          this.displayedColumns = ['select', 'status', 'title', 'type', 'user', 'dueDate'];
          break;
        case LayoutStates.tablet:
          this.displayedColumns = ['select', 'status', 'title', 'dueDate'];
          break;
        case LayoutStates.mobile:
          this.displayedColumns = ['select', 'status', 'title'];
          break;
        default:
          this.displayedColumns = ['select', 'status', 'title', 'type', 'user', 'dueDate'];
      }
    });
  }

  isSelected(): boolean {
    const SELECTED = this.selectedData.selected.length;
    const ROWS = this.tasksSv.getAllTasks().length;
    return SELECTED === ROWS;
  }

  masterToggle(): void {
    this.isSelected()
      ? this.selectedData.clear()
      : this.tasksSv.getAllTasks().forEach(task => this.selectedData.select(task));
  }

  createTask(): void {
    this.dialogRef = this.dialog.open(CreateTaskComponent, {
      width: '435px',
      maxWidth: '91.5%',
      data: this.userSv.users$
    });

    this.dialogRef.afterClosed().subscribe(task => task && this.tasksSv.addTask(task));
  }

  onDeleteTask(): void {
    const MSG = 'Are you sure you want to delete this task?';

    const REF = this.dialog.open(DeleteDialogComponent, {
      width: '91.5%',
      maxWidth: '435px',
      data: MSG
    });

    REF.afterClosed()
      .pipe(filter(res => !!res))
      .subscribe(() => {
        this.selectedData.selected.forEach(t => this.tasksSv.deleteTask(t));
        this.selectedData.clear();
      });
  }
}
