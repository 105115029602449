import {
  ChangeDetectionStrategy,
  Component, HostBinding,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { AuthService } from '../../../services/auth.service';

import { INotification } from '../../../interfaces/notification';

@Component({
  selector: 'app-notifications-menu',
  templateUrl: './notifications-menu.component.html',
  styleUrls: ['./notifications-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NotificationsMenuComponent implements OnInit {
  @HostBinding('class.app-notifications') true;
  @Input() notifications: INotification[];

  constructor(public auth: AuthService) {}

  ngOnInit() {
    this.notifications = [];
  }

  logout() {
    this.auth.logout();
  }
}
