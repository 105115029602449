import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ITask, TaskStatuses } from '../../../../interfaces/task';

@Component({
  selector: 'app-task-list-item',
  templateUrl: './task-list-item.component.html',
  styleUrls: ['./task-list-item.component.scss']
})
export class TaskListItemComponent implements OnInit {
  @Input() task: ITask;

  @Output() deleteTask: EventEmitter<ITask>;
  @Output() openTask: EventEmitter<ITask>;

  badgeStatuses: any;
  constructor() {
    this.deleteTask = new EventEmitter<ITask>();
    this.openTask = new EventEmitter<ITask>();
  }

  ngOnInit() {
    this.badgeStatuses = {
      Completed: 'green',
      Active: 'yellow',
      Ended: 'red'
    };
  }

  @HostListener('click', ['$event.target'])
  public OnOpenTask() {
    this.openTask.emit(this.task);
  }

  getBadgeView(status: TaskStatuses): string {
    return this.badgeStatuses[status];
  }

  getViewArr(status: TaskStatuses): string[] {
    const statusArr: string[] = Object.values(this.badgeStatuses);
    return statusArr.filter(item => item !== this.getBadgeView(status));
  }

  onDeleteTask(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.deleteTask.emit(this.task);
  }
}
