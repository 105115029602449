import { Action, createReducer, on } from '@ngrx/store';

import * as sidebarActions from '../actions/sidebar.actions';
import { ISidebarState } from '../../interfaces/sidebar-state';

export const initialState: ISidebarState = {
  type: 'full-size'
};

const sideBarReducer = createReducer(
  initialState,
  on(sidebarActions.setSidebarFullSize, state => ({ ...state, type: 'full-size' })),
  on(sidebarActions.setSidebarMinimized, state => ({ ...state, type: 'minimized'})),
  on(sidebarActions.setSidebarMobile, state => ({ ...state, type: 'mobile' }))
);

export function reducer(state: ISidebarState, action: Action): ISidebarState {
  return sideBarReducer(state, action);
}
