import { Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  startWith,
  tap
} from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';

import { IState } from '../../interfaces/state';

import { EmailStatuses, EmailStyles, IEmail, IEmailComponent } from '../../interfaces/email';

import { EmailService } from '../../services/email.service';
import { HttpService } from '../../services/http.service';
import { LayoutStates } from '../../interfaces/layout-state';

import { RecipientsDialogComponent } from '../../layout/components/recipients-dialog';
import { EmailComponentsComponent } from '../../layout/components/email-components';
import * as layoutActions from '../../store/actions/layout.actions';
import { DeleteDialogComponent } from '../../layout/components/delete-dialog';

@Component({
  selector: 'page-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class PageEmailComponent implements OnInit, AfterViewInit, OnDestroy {
  recipientsUrl: string;
  showRecipients: boolean;

  recipients$: Observable<string[]>;
  recipientFilter$: Observable<string>;
  filteredRecipients$: Observable<string[]>;

  emailComponentsUrl: string;
  emailComponentsFilter$: Observable<string>;
  emailComponents$: Observable<IEmailComponent[]>;
  filteredEmailComponents$: Observable<IEmailComponent[]>;

  badgeViews: any;
  badgeArr: string[];

  displayedColumns: string[];
  selectedData: SelectionModel<IEmail>;

  selectedEmail: IEmail;
  creatingEmail: boolean;

  recipientInput: FormControl;
  emailTypeSelect: FormControl;
  campaignSelect: FormControl;
  emailComponentsInput: FormControl;

  emails$: Observable<IEmail[]>;
  emailFilter$: Observable<EmailStatuses>;

  layout: LayoutStates;
  layoutStates: typeof LayoutStates;

  queryParamsSubscription: Subscription;

  constructor(
    private activeRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private emailSv: EmailService,
    private store: Store<IState>,
    private httpSv: HttpService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.store.dispatch(layoutActions.setPageTitle({ title: 'Email' }));
    this.store.dispatch(layoutActions.setPagePlaceholder({ placeholder: 'Search for a mail' }));

    this.layoutStates = LayoutStates;
    this.badgeViews = this.badgeStyles();
    this.badgeArr = Object.values<string>(this.badgeViews);
    this.emailComponentsUrl = '../../../assets/data/email-components.json';

    this.recipientInput = new FormControl('');
    this.emailTypeSelect = new FormControl('All');
    this.campaignSelect = new FormControl('All');
    this.emailComponentsInput = new FormControl('');

    this.selectedData = new SelectionModel<IEmail>(true, []);

    this.queryParamsSubscription = this.emailTypeSelect.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(status => {
        this.router.navigate([], { queryParams: { filter: status }, relativeTo: this.activeRoute });
      });

    this.emailFilter$ = this.activeRoute.queryParams.pipe(
      distinctUntilKeyChanged('filter'),
      map(p => p.filter),
      tap(filter => {
        if (this.emailTypeSelect.value !== filter) {
          this.emailTypeSelect.setValue(filter);
        }
      })
    );

    this.emails$ = combineLatest(this.emailSv.emails$, this.emailFilter$).pipe(
      distinctUntilChanged(),
      map(([emails, status]) => {
        let filteredEmails = [...emails];

        if (status && status !== EmailStatuses.all) {
          filteredEmails = emails.filter(email => email.status === status);
        }

        return filteredEmails;
      })
    );

    this.emailComponents$ = this.httpSv.getData<IEmailComponent[]>(this.emailComponentsUrl);

    this.emailComponentsFilter$ = this.emailComponentsInput.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(200),
      startWith(this.emailComponentsInput.value)
    );

    this.recipientFilter$ = this.recipientInput.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(200),
      startWith(this.recipientInput.value)
    );

    this.recipientsUrl = '../../../assets/data/recipients.json';
    this.recipients$ = this.getRecipients();

    this.store.subscribe(state => {
      this.layout = state.layoutState.type;

      switch (this.layout) {
        case LayoutStates.desktop:
          this.displayedColumns = ['select', 'status', 'subject', 'lastUpdated', 'openRate'];
          break;
        case LayoutStates.tablet:
          this.displayedColumns = ['select', 'status', 'subject', 'openRate'];
          break;
        default:
          this.displayedColumns = ['select', 'status', 'subject'];
      }
    });
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
    this.filteredRecipients$ = combineLatest(this.recipients$, this.recipientFilter$).pipe(
      distinctUntilChanged(),
      debounceTime(200),
      map(([recipients, filter]) =>
        recipients.filter(item => item.toLowerCase().includes(filter.toLowerCase()))
      )
    );

    this.filteredEmailComponents$ = combineLatest(
      this.emailComponents$,
      this.emailComponentsFilter$
    ).pipe(
      map(([components, filter]) => {
        return components.filter(component =>
          component.title.toLowerCase().includes(filter.toLowerCase())
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.queryParamsSubscription.unsubscribe();
  }

  badgeStyles(): any {
    const STYLES = {};
    Object.keys(EmailStatuses).forEach(key => (STYLES[EmailStatuses[key]] = EmailStyles[key]));
    return STYLES;
  }

  isAllSelected(): boolean {
    const SELECTED = this.selectedData.selected.length;
    const ROWS = this.emailSv.getEmails().length;
    return SELECTED === ROWS;
  }

  getRecipients(): Observable<string[]> {
    return this.httpSv
      .getData<{ email: string }[]>(this.recipientsUrl)
      .pipe(map(res => res.map(item => item.email)));
  }

  onShowRecipients(state: boolean): void {
    if (this.layout === LayoutStates.desktop) {
      this.showRecipients = state;
    } else {
      this.dialog.open(RecipientsDialogComponent, {
        width: '440px',
        maxWidth: '91.5%',
        data: this.recipients$
      });
    }
  }

  onShowEmailComponents(): void {
    this.dialog.open(EmailComponentsComponent, {
      width: '350px',
      maxWidth: '91.5%',
      data: this.emailComponents$
    });
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selectedData.clear()
      : this.emailSv.getEmails().forEach(email => this.selectedData.select(email));
  }

  deleteEmail(): void {
    const MSG =
      this.selectedData.selected.length > 1
        ? 'Are you sure you want to delete this emails?'
        : 'Are you sure you want to delete this email?';

    const REF = this.dialog.open(DeleteDialogComponent, {
      width: '91.5%',
      maxWidth: '435px',
      data: MSG
    });

    REF.afterClosed()
      .pipe(filter(res => res))
      .subscribe(() => {
        this.selectedData.selected.forEach(email => {
          this.emailSv.deleteEmail(email);
          this.selectedData.toggle(email);
        });
      });
  }

  backToEmails(): void {
    this.selectedEmail = null;
    this.creatingEmail = false;
  }

  createEmail(): void {
    this.creatingEmail = true;
    this.selectedEmail = null;
  }
}
