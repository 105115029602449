import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ITask, TaskTypes } from '../interfaces/task';
import { HttpService } from './http.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TasksService {
  private tasks: ITask[];

  taskSubject: BehaviorSubject<ITask[]>;
  tasks$: Observable<ITask[]>;

  taskUrl: string;

  constructor(private http: HttpService) {
    this.taskUrl = '../../assets/data/tasks.json';
    this.taskSubject = new BehaviorSubject([]);

    this.tasks$ = this.taskSubject.asObservable();

    this.http
      .getData<ITask[]>(this.taskUrl)
      .pipe(tap(tasks => (this.tasks = tasks)))
      .subscribe(tasks => this.taskSubject.next(tasks));
  }

  addTask(task: ITask): void {
    this.tasks = [...this.tasks, task];
    this.taskSubject.next(this.tasks);
  }

  deleteTask(deletedTask: ITask): void {
    this.tasks = this.tasks.filter(task => deletedTask.id !== task.id);
    console.log(this.tasks);
    this.taskSubject.next(this.tasks);
  }

  getAllTasks(): ITask[] {
    return this.tasks;
  }
}
