<div class="top-bar">
  <div class="selects">
    <div class="d-flex align-items-center header-select-wrap">
      <span class="label mr-2">Pipeline:</span>
      <mat-form-field class="select" [appearance]="'none'">
        <mat-select [value]="'All'">
          <mat-option [value]="'All'">All</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-icon class="search">search</mat-icon>
  </div>

  <button class="create-task-btn" type="button" tc-button (click)="addDeal()">
    Add deal
  </button>
</div>

<div class="deals-block">
  <div class="deals-lists-block">
    <div class="deals-list-wrap">
      <div class="title-block">
        <h3 class="title">New sales deals</h3>
        <span class="counter">{{ newDeals.length }}</span>
      </div>

      <div
        class="list-block"
        cdkDropList
        #newList="cdkDropList"
        [cdkDropListData]="newDeals"
        (cdkDropListDropped)="drop($event, dealStatuses.new)"
        [cdkDropListConnectedTo]="[wonList, lostList]"
      >
        <div
          class="drag-block"
          *ngFor="let deal of newDeals"
          cdkDrag
          [cdkDragData]="deal"
          (cdkDragStarted)="onDragging()"
          (cdkDragReleased)="onRelease()"
        >
          <div class="drop-placeholder" *cdkDragPlaceholder></div>
          <app-deal [deal]="deal" (deleteDeal)="deleteDeal($event)" (click)="openDeal(deal)" (editDeal)="editDeal($event)"></app-deal>
        </div>
      </div>
    </div>

    <div class="deals-list-wrap">
      <div class="title-block">
        <h3 class="title">Won</h3>
        <span class="counter">{{ wonDeals.length }}</span>
      </div>

      <div
        class="list-block"
        cdkDropList
        #wonList="cdkDropList"
        [cdkDropListData]="wonDeals"
        [cdkDropListConnectedTo]="[newList, lostList]"
        (cdkDropListDropped)="drop($event, dealStatuses.won)"
      >
        <div
          class="drag-block"
          *ngFor="let deal of wonDeals"
          cdkDrag
          [cdkDragData]="deal"
          (cdkDragStarted)="onDragging()"
          (cdkDragReleased)="onRelease()"
        >
          <div class="drop-placeholder" *cdkDragPlaceholder></div>
          <app-deal [deal]="deal" (deleteDeal)="deleteDeal($event)" (click)="openDeal(deal)" (editDeal)="editDeal($event)"></app-deal>
        </div>
      </div>
    </div>

    <div class="deals-list-wrap">
      <div class="title-block">
        <h3 class="title">Lost</h3>
        <span class="counter">{{ lostDeals.length }}</span>
      </div>

      <div
        class="list-block"
        cdkDropList
        #lostList="cdkDropList"
        [cdkDropListData]="lostDeals"
        (cdkDropListDropped)="drop($event, dealStatuses.lost)"
        [cdkDropListConnectedTo]="[newList, wonList]"
      >
        <div
          class="drag-block"
          *ngFor="let deal of lostDeals"
          cdkDrag
          [cdkDragData]="deal"
          (cdkDragStarted)="onDragging()"
          (cdkDragReleased)="onRelease()"
        >
          <div class="drop-placeholder" *cdkDragPlaceholder></div>
          <app-deal [deal]="deal" (deleteDeal)="deleteDeal($event)" (click)="openDeal(deal)" (editDeal)="editDeal($event)"></app-deal>
        </div>
      </div>
    </div>

    <div class="deals-list-wrap">
      <div class="title-block">
        <h3 class="title">Lost</h3>
        <span class="counter">{{ lostDeals.length }}</span>
      </div>

      <div
        class="list-block"
        cdkDropList
        #lostList="cdkDropList"
        [cdkDropListData]="lostDeals"
        (cdkDropListDropped)="drop($event, dealStatuses.lost)"
        [cdkDropListConnectedTo]="[newList, wonList]"
      >
        <div
          class="drag-block"
          *ngFor="let deal of lostDeals"
          cdkDrag
          [cdkDragData]="deal"
          (cdkDragStarted)="onDragging()"
          (cdkDragReleased)="onRelease()"
        >
          <div class="drop-placeholder" *cdkDragPlaceholder></div>
          <app-deal [deal]="deal" (deleteDeal)="deleteDeal($event)" (click)="openDeal(deal)" (editDeal)="editDeal($event)"></app-deal>
        </div>
      </div>
    </div>
  </div>

  <div
    class="total-bar-wrap"
    *ngIf="!(dragging && layout !== layoutStates.desktop) || layout === layoutStates.desktop"
  >
    <div class="total-bar">
      <div class="total-block">Total: $ {{ getTotalPrice(newDeals) }}</div>
      <div class="total-block">Total: $ {{ getTotalPrice(wonDeals) }}</div>
      <div class="total-block">Total: $ {{ getTotalPrice(lostDeals) }}</div>
      <div class="total-block">Total: $ {{ getTotalPrice(lostDeals) }}</div>
    </div> 
  </div>
</div>

<div class="remove-bar-wrap" *ngIf="dragging && layout !== layoutStates.desktop">
  <div class="remove-bar">
    <div class="bg"></div>
    <span><mat-icon>delete</mat-icon> Remove</span>
  </div>
</div>
