import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: '[tc-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TCButtonComponent implements OnInit {
  @HostBinding('class.tc-btn') true;
  @HostBinding('class.block') @Input() block: boolean;
  @HostBinding('class.btn-disabled') @Input() disabled: boolean;

  @HostBinding('class.btn-default') get viewDefault() {
    return this.view === 'default';
  }
  @HostBinding('class.btn-error') get viewError() {
    return this.view === 'error';
  }

  @HostBinding('class.btn-accent') get accentType() {
    return this.type === 'accent';
  }
  @HostBinding('class.btn-secondary') get secondaryType() {
    return this.type === 'secondary';
  }
  @HostBinding('class.btn-tertiary') get tertiaryType() {
    return this.type === 'tertiary';
  }

  @Input() view: string;
  @Input() beforeIcon: string;
  @Input() afterIcon: string;
  @Input() type: string;

  constructor() {
    this.disabled = false;
    this.view = 'default';
  }

  ngOnInit() {}
}
