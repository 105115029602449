import {
  ChangeDetectionStrategy,
  ContentChild,
  Component,
  HostBinding,
  Input,
  OnInit, ElementRef
} from '@angular/core';

@Component({
  selector: 'tc-circle-badge',
  templateUrl: './circle-badge.component.html',
  styleUrls: ['./circle-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TCCircleBadgeComponent implements OnInit {
  @ContentChild('label') label: ElementRef;

  @HostBinding('class.tc-circle-badge') true;

  @HostBinding('class.purple') get purpleView() {
    return this.view === 'purple';
  }
  @HostBinding('class.yellow') get yellowView() {
    return this.view === 'yellow';
  }
  @HostBinding('class.green') get greenView() {
    return this.view === 'green';
  }
  @HostBinding('class.red') get redView() {
    return this.view === 'red';
  }

  @HostBinding('class.sm') get smSize() {
    return this.size === 'sm';
  }
  @HostBinding('class.md') get mdSize() {
    return this.size === 'md';
  }
  @HostBinding('class.lg') get lgSize() {
    return this.size === 'lg';
  }

  @HostBinding('style.width.px') get width() {
    return this.customSize;
  }
  @HostBinding('style.width.px') get height() {
    return this.customSize;
  }

  @HostBinding('style.border-width.px') @Input() borderWidth: number;

  @Input() color: string;
  @Input() view: string;
  @Input() customSize: number;
  @Input() size: 'sm' | 'md' | 'lg';
  showLabel: boolean;
  constructor() {}

  ngOnInit() {
    this.showLabel = this.label && this.label.nativeElement.childNodes.length > 0;
  }
}
