<div style="width: fit-content; margin: auto;">
    <div class="group">
        <div class="bigSqr">
            <div class="square first"></div>
            <div class="square second"></div>
            <div class="square third"></div>
            <div class="square fourth"></div>
        </div>
        <div class="text">Aguarde...</div>
    </div>
</div>