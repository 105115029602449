import { Component, HostBinding, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FormControl } from '@angular/forms';
import { filter } from 'rxjs/operators';

import { ITask, TaskStatuses } from '../../../interfaces/task';
import { DeleteDialogComponent } from '../delete-dialog';
import { OpenedTaskComponent } from '../opened-task';
import { TasksService } from '../../../services/tasks.service';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit, OnChanges {
  @HostBinding('class.email-list') true;
  @Input() tasks: ITask[];

  weekSelect: FormControl;
  currentDate: string;
  progress: number;
  week: any[];

  constructor(private dialog: MatDialog, private tasksSv: TasksService) {}

  ngOnInit() {
    this.weekSelect = new FormControl('This week');

    this.week = [
      { day: 'Sun', date: 23, selected: true },
      { day: 'Mon', date: 24, current: true },
      { day: 'Tue', date: 25 },
      { day: 'Wed', date: 26 },
      { day: 'Thu', date: 27 },
      { day: 'Fri', date: 28 },
      { day: 'Sat', date: 29 }
    ];
  }

  ngOnChanges(): void {
    if (this.tasks && this.tasks.length) {
      this.progress = this.getProgress(this.tasks);
      this.currentDate = this.tasks[0].dueDate.split(',')[0];
      this.currentDate = this.tasks[0].dueDate.split(',')[1];
    }
  }

  getProgress(tasks: ITask[]): number {
    return (this.getCompleted(tasks).length / tasks.length) * 100;
  }

  getCompleted(tasks: ITask[]): ITask[] {
    return tasks.filter(task => task.status === TaskStatuses.completed);
  }

  onDeleteTask(task: ITask): void {
    const MSG = 'Are you sure you want to delete this task?';

    const REF = this.dialog.open(DeleteDialogComponent, {
      width: '91.5%',
      maxWidth: '435px',
      data: MSG
    });

    REF.afterClosed()
      .pipe(filter(res => !!res))
      .subscribe(() => this.tasksSv.deleteTask(task));
  }

  onOpenTask(task: ITask): void {
    this.dialog.open(OpenedTaskComponent, {
      width: '750px',
      maxWidth: '91.5%',
      height: '91vh',
      data: task,
      autoFocus: false
    });
  }
}
