<div class="header">
  <h3 mat-dialog-title class="mb-0">{{ task.title }}</h3>
  <mat-icon (click)="closeDialog()">close</mat-icon>
</div>

<mat-dialog-content class="content">
  <div class="info section">
    <div class="text-fields-block">
      <div class="text-field">
        <span class="label">Type:</span>
        <span class="value">{{ task.type }}</span>
      </div>
      <div class="text-field">
        <span class="label">Due date:</span>
        <span class="value">{{ task.dueDate }}</span>
      </div>
      <div class="text-field">
        <span class="label">Associated with:</span>
        <span class="value">
          <img [src]="task.user.avatar" [alt]="task.user.name" />
        </span>

        <button mat-icon-button class="add-user small">
          <mat-icon class="add-icon">add_circle</mat-icon>
        </button>
      </div>
    </div>

    <div class="type">
      <tc-badge [view]="badgeViews[task.status]">
        {{ task.status }}
      </tc-badge>
    </div>
  </div>

  <div class="description section">
    <div class="section-title-block">
      <h4 class="section-title">Description</h4>
      <mat-icon class="edit-btn">edit</mat-icon>
    </div>

    <div class="description-block section">
      <span class="description">
        {{ task.description }}
      </span>
    </div>
  </div>

  <div class="attachments section">
    <div class="section-title-block">
      <h4 class="section-title">Attachments</h4>
    </div>

    <div class="attachment" *ngFor="let attachment of task.attachments">
      <div class="img-block">
        <img [src]="attachment.img" [alt]="attachment.title" class="attachment-img" />
      </div>

      <div class="info-block">
        <div class="attachment-info">
          <h5 class="attachment-title">{{ attachment.title }}</h5>
          <span class="adding-date">{{ attachment.addingDate }}</span>
        </div>

        <div class="actions">
          <mat-icon class="edit-btn">edit</mat-icon>
          <mat-icon class="delete-btn">delete</mat-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="leave-comment section">
    <div class="section-title-block">
      <h4 class="section-title">Leave a comment</h4>
    </div>

    <div class="comment-field">
      <mat-form-field appearance="none" class="text-area no-border">
        <textarea
          matInput
          cdkTextareaAutosize
          placeholder="Enter a comment"
          cdkAutosizeMinRows="4"
          cdkAutosizeMaxRows="4"
        ></textarea>
      </mat-form-field>

      <div class="comment-actions">
        <div class="icons">
          <mat-icon>title</mat-icon>
          <mat-icon>insert_link</mat-icon>
          <mat-icon>attach_file</mat-icon>
        </div>

        <div class="buttons">
          <button class="send-button" tc-button [type]="'tertiary'">Send</button>
        </div>
      </div>
    </div>
  </div>

  <div class="comments section">
    <div class="section-title-block">
      <h4 class="section-title">Comments</h4>
    </div>

    <div class="comment-wrap" *ngFor="let comment of task.comments">
      <div class="sender">
        <img [src]="comment.sender.avatar" [alt]="comment.sender.name" class="sender-avatar" />
        <span class="sender-name">{{ comment.sender.name }}</span>
        <div class="adding-date">{{ comment.addingDate }}</div>
      </div>

      <div class="comment">
        <div class="comment-text">
          {{ comment.text }}
        </div>

        <div class="comment-actions">
          <button class="reply-btn" tc-button [type]="'tertiary'">Reply</button>

          <div class="actions">
            <mat-icon class="edit-btn">edit</mat-icon>
            <mat-icon class="delete-btn">delete</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-actions section mb-0">
    <div class="section-title-block">
      <h4 class="section-title">Actions</h4>
    </div>

    <div class="buttons">
      <button mat-dialog-close [type]="'secondary'" tc-button>Ended</button>
      <button mat-dialog-close tc-button>Completed</button>
    </div>
  </div>
</mat-dialog-content>
