import gql from 'graphql-tag';

export const insert_person = gql`
mutation insert_person($address: String, $avatar: String, $companyName: String, $cpf_cnpj: String, $email: String, $facebook: String, $instagram: String, $name: String, $number: String, $organization_id: Int!, $owner_id: uuid!, $person_type: Int!, $phone: String, $website: String, $whatsapp: String, $zipcode: String, $state: String, $city: String) {
  insert_omnichannel_persons_one(object: {active: true, address: $address, avatar: $avatar, companyName: $companyName, cpf_cnpj: $cpf_cnpj, email: $email, facebook: $facebook, instagram: $instagram, name: $name, number: $number, organization_id: $organization_id, owner_id: $owner_id, person_type: $person_type, phone: $phone, website: $website, whatsapp: $whatsapp, zipcode: $zipcode, state: $state, city: $city}) {
    id
  }
}`;

export const update_person = gql`
mutation update_person($id: uuid!, $address: String, $companyName: String, $cpf_cnpj: String, $email: String, $facebook: String, $instagram: String, $name: String!, $number: String, $person_type: Int, $phone: String, $state: String, $website: String, $whatsapp: String, $zipcode: String, $city: String) {
  update_omnichannel_persons_by_pk(pk_columns: {id: $id}, _set: {address: $address, companyName: $companyName, cpf_cnpj: $cpf_cnpj, email: $email, facebook: $facebook, instagram: $instagram, name: $name, number: $number, person_type: $person_type, phone: $phone, state: $state, website: $website, whatsapp: $whatsapp, zipcode: $zipcode, city: $city}) {
    id
  }
}`;

export const insert_person_note = gql`
mutation insert_person_note($person_id: uuid!, $description: String!) {
  insert_omnichannel_person_note_one(object: {description: $description, person_id: $person_id}) {
    id
  }
}`;

export const insert_person_tags = gql`
mutation insert_person_tags($person_id: uuid!, $tag_id: uuid!) {
  insert_omnichannel_person_tags_one(object: {person_id: $person_id, tag_id: $tag_id}) {
    id
  }
}`;

export const delete_person_tags = gql`
mutation delete_person_tags($person_id: uuid!, $tag_id: uuid!) {
  delete_omnichannel_person_tags(where: {person_id: {_eq: $person_id}, tag_id: {_eq: $tag_id}}) {
    returning {
      id
    }
  }
}`;