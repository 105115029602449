import { Component, OnInit } from '@angular/core';

import { IntegrationsService } from '../../../services/integrations.service';
import { Observable } from 'rxjs';
import { IIntegration } from '../../../interfaces/integration';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {
  connectedIntegrations$: Observable<IIntegration[]>;
  popularIntegrations$: Observable<IIntegration[]>;

  constructor(private intSv: IntegrationsService) {
    this.connectedIntegrations$ = this.intSv.integrations$.pipe(
      map(integrations => integrations.filter(i => i.connected))
    );

    this.popularIntegrations$ = this.intSv.integrations$.pipe(
      map(integrations => integrations.filter(i => !i.connected))
    );
  }

  ngOnInit() {}

  toggleConnect(integraton: IIntegration): void {

  }
}
