import gql from 'graphql-tag';

export const query_user_one = gql`
query query_user_one($id: uuid!) {
  users_by_pk(id: $id) {
    avatar_url
    id
    display_name
    account {
      email
      default_role
    }
    members {
      teams {
        id
        name
        plan {
          id
          name
        }
      }
    }
    acl_features_permissions(order_by: {acl_feature: {order: asc}}) {
      id
      read
      write
      delete
      created_at
      updated_at
      acl_feature {
        desktop
        divider
        icon
        id
        is_submenu
        name
        order
        routing
      }
    }
    feature_default {
      routing
      id
    }
  }
}`;

export const query_profile = gql`
query user_query($id: uuid!) {
  profile:users_by_pk(id: $id) {
    avatar:avatar_url
    display_name
    account {
      email
    }
    members {
      teams {
        name
        plan {
          id
          name
        }
      }
    }
  }
}
`;

export const query_person_type = gql`
query query_person_types($teamid: Int!) {
  omnichannel_person_type(where: {organization_id: {_eq: $teamid}}) {
    value: id
    viewValue: name
  }
}
`;

export const query_persons_aggregate = gql`
query persons_aggregate($teamid: Int!, $limit: Int!, $offset: Int, $person_type: Int, $text_search: String) {
  omnichannel_persons_aggregate(where: {organization_id: {_eq: $teamid}, person_type: {_eq: $person_type}, name: {_like: $text_search}}, order_by: {created_at: desc}) {
    aggregate {
      count
    }
  }
  omnichannel_persons(where: {organization_id: {_eq: $teamid}, person_type: {_eq: $person_type}, name: {_like: $text_search}}, limit: $limit, offset: $offset, order_by: {created_at: desc}) {
    id
    name
    avatar
    city
    companyName
    cpf_cnpj
    email
    instagram
    phone
    whatsapp
    ptypes {
      id
      name
    }
  }
}
`;

export const query_person_one = gql`
query person_types($id: uuid!) {
  omnichannel_persons_by_pk(id: $id){
    avatar
    address
    city
    name
    state
    id
    email
    zipcode
    owner_id
    person_type
    cpf_cnpj
    phone
    whatsapp
    companyName
    tags{
      tag{
        color
        id
        description
      }
    }
  }
  omnichannel_addons_connects {
    id
    addons_id
    addons {
      title
    }
    settings{
      name
      value
    }
  }
}
`;

export const query_tags = gql`
query omnichannel_tags {
  omnichannel_tags {
    description
    id
    color
  }
}
`;

export const GET_DEAL_ALL = gql`
query omnichannel_pipeline {
  omnichannel_pipeline {
    id
    description
    created_at
  }
}
`;