<mat-icon matTooltip="Sair" (click)="logout()">logout</mat-icon>

<mat-menu class="notification-panel" #menu="matMenu">
  <ng-container *ngIf="notifications.length">
    <ul class="list">
      <li class="item" *ngFor="let item of notifications">
        <div class="item-content">
          <mat-icon>{{ item.icon }}</mat-icon>

          <div class="text">
            <a class="regular link" href="#">{{ item.title }}</a>
            <p class="regular-10pt">{{ item.date }}</p>
          </div>
        </div>
      </li>
    </ul>
  </ng-container>

  <div mat-menu-item *ngIf="!notifications || notifications.length === 0">
    <span class="empty-item">No notifications</span>
  </div>
</mat-menu>
