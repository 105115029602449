<app-top-nav [mobile]="mobile" [minimized]="minimized"></app-top-nav>
<app-side-nav [mobile]="mobile" [minimized]="minimized"></app-side-nav>

<div class="main-content">
  <main>
    <router-outlet></router-outlet>
  </main>
</div>
<ng-template #customLoadingTemplate>
  <div class="custom-class">
    <h3>
      Loading...
    </h3>
    <button (click)="showAlert()">
      Click me!
    </button>
  </div>
</ng-template>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
