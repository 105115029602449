import { Component, Inject, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { map, pluck, startWith, debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';

import { DealType, BadgeStyles } from '../../../interfaces/deal';
import { fadeIn } from '../../../animations/form-error';
import { IUser } from '../../../interfaces/user';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IState } from '../../../interfaces/state';
import { Store } from '@ngrx/store';
import * as layoutActions from '../../../store/actions/layout.actions';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

import {
  GetPipelineStatusQuery, 
  GetPipelineStatusGQL,
  GetProductsQuery,
  GetProductsGQL,
  GetPersonFilterQuery,
  GetPersonFilterGQL,
  GetPersonTypesGQL,
  GetPersonTypesQuery,
  InsertCardPipelineMutation,
  InsertProductPipelineMutation,
  InsertCardPipelineIdPersonMutation,
  InsertCardPipelineGQL,
  InsertProductPipelineGQL,
  InsertCardPipelineIdPersonGQL,
  ChangeOrderCardsGQL,
  ChangeListOrdersCardsGQL
} from '../../../../generated/graphql'

@Component({
  selector: 'app-create-deal',
  templateUrl: './create-deal.component.html',
  styleUrls: ['./create-deal.component.scss'],
  animations: [fadeIn]
})
export class CreateDealComponent implements OnInit {
  statuses: string[];
  badgeViews: any;
  types: string[];
  qtdProduct: any[] = [];
  dealForm: FormGroup;
  person_id = new FormControl();
  prouducts = new FormControl();
  newPerson: boolean = false;
  openPerson: boolean = false;
  @Input() list_id: string = "a609722a-da99-4f95-83b0-2e3f75103407";
  public mask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
  person_id_value = '';

  options: any = [];
  filteredOptions: Observable<string[]>;

  pipelineStatus$: Observable<GetPipelineStatusQuery['omnichannel_pipeline_status']>;
  products$: Observable<GetProductsQuery['omnichannel_products']>;
  persons$: Observable<GetPersonFilterQuery['omnichannel_persons']>;
  person_types$: Observable<GetPersonTypesQuery['omnichannel_person_type']>;

  private queryStatus = this.getStatus.watch({});
  private queryProducts = this.getProducts.watch({});  
  private queryPersonTypes = this.getPersonTypes.watch({});  

  constructor(
    private dialogRef: MatDialogRef<CreateDealComponent>,
    @Inject(MAT_DIALOG_DATA) public users: any,
    private _snackBar: MatSnackBar,
    private getStatus: GetPipelineStatusGQL,
    private getProducts: GetProductsGQL,
    private getContacts: GetPersonFilterGQL,
    private getPersonTypes: GetPersonTypesGQL,
    private insertCard: InsertCardPipelineGQL,
    private insertCardProduct: InsertProductPipelineGQL,
    private insertCardwithID: InsertCardPipelineIdPersonGQL,
    private changeOrderCards: ChangeOrderCardsGQL,
    private store: Store<IState>,
  ) {}

  ngOnInit() {
    this.badgeViews = BadgeStyles;
    this.types = Object.values(DealType);
    this.pipelineStatus$ = this.queryStatus.valueChanges.pipe(pluck('data', 'omnichannel_pipeline_status'));
    this.products$ = this.queryProducts.valueChanges.pipe(pluck('data', 'omnichannel_products'));
    this.person_types$ = this.queryPersonTypes.valueChanges.pipe(pluck('data', 'omnichannel_person_type'));
    this.list_id = this.users.list_id;

    this.initTaskForm();
  }

  initTaskForm(): void {
    this.person_id_value = '';
    this.person_id = new FormControl('', [Validators.required]),
    this.person_id.valueChanges
    .pipe(
      debounceTime(100),
      distinctUntilChanged(),
      filter( value => value.length > 0)
    )
    .subscribe(v => {
      console.log('vamos', v)
      this._filter(v);
    });

    this.dealForm = new FormGroup({
      products: new FormControl('', [Validators.required]),
      status_id: new FormControl(''),
      title: new FormControl('', [Validators.required]),
      person_id: this.person_id,
      name_person: new FormControl('', [Validators.required]),
      ptype: new FormControl('', [Validators.required]),
      email: new FormControl(''),
      phone: new FormControl(''),
      date_close: new FormControl(''),
      status: new FormControl('New')
    });
  }

  private _filter(value: string){
    const filterValue = `${value}%`;
    let queryPersonFilter = this.getContacts.watch({filter: filterValue});
    this.persons$ = queryPersonFilter.valueChanges.pipe(pluck('data', 'omnichannel_persons'));
    this.persons$.subscribe(v => {
      if(v.length == 0){
        this.options = [];
        this.options.push({name: `Adicionar ${value} como um novo contato`, id: 'novo', novo: value});
      } else{
        this.options = v;
      }
    });
    queryPersonFilter.refetch();
  }

  OnSelectPerson(person){
    if(person.novo == undefined){
      this.dealForm.controls['name_person'].setValue(person.name);
      this.dealForm.controls['email'].setValue(person.email);
      this.dealForm.controls['phone'].setValue(person.phone);
      this.dealForm.controls['ptype'].setValue(person.person_type);
      this.dealForm.controls['title'].setValue(`Negócio ${person.name}`);
      this.person_id_value = person.id;
      this.newPerson = false;
    }
    else{
      this.dealForm.controls['name_person'].setValue(person.novo);
      this.dealForm.controls['title'].setValue(`Negócio ${person.novo}`);
      this.dealForm.controls['email'].setValue('');
      this.dealForm.controls['phone'].setValue('');
      this.dealForm.controls['ptype'].setValue('');
      this.newPerson = true;
    }
    this.openPerson = true;
  }

  submitDeal(): void {

    if (this.dealForm.invalid) {
      return;
    }

    this.store.dispatch(layoutActions.setLoadingPage({ loading: true }));

    let _dataSave = JSON.parse(JSON.stringify(this.dealForm.value));

    if(_dataSave.status_id == ""){
      this._snackBar.open(`Ops: Você precisa selecionar o Status.`, "Fechar", {
        duration: 2000,
      });
      return;
    }

    _dataSave["list_id"] = this.list_id;
    _dataSave["organization_id"] = Number(localStorage.getItem("teamid"));
    _dataSave["owner_id"] = localStorage.getItem("user_id");
    _dataSave["order"] = 0;

    //Salva os produtos
    let _products = JSON.parse(JSON.stringify(_dataSave.products));
    let _productsNew = { itens: []};
    let index = 0;
    for (let prods in _products) {
      let _amount = _dataSave[this.qtdProduct[index].name];
      delete _dataSave[this.qtdProduct[index].name];
      _productsNew.itens.push({"pipeline_card_id": "", "product_id": _products[prods].id, "amount": _amount});
      index++;
    }

    if(this.newPerson){
      delete _dataSave.person_id;
      delete _dataSave.status;
      delete _dataSave.products;
      _dataSave["avatar"] = "https://ui-avatars.com/api/?name="+_dataSave.name_person;
      this.insertCard.mutate(_dataSave).subscribe(v => {
        let _productsSave = {itens : _productsNew.itens.map(function(itens) {
          itens["pipeline_card_id"] = v.data.insert_omnichannel_pipeline_card_one.id;
          return itens;
        })};
        this.changeOrderCards.mutate({ list_id: this.list_id, card_id: v.data.insert_omnichannel_pipeline_card_one.id, order: 0}).subscribe(v => {
          console.log('save', v);
          this.store.dispatch(layoutActions.setLoadingPage({ loading: false }));
        });
        this.insertCardProduct.mutate(_productsSave).subscribe(v => {
          this._snackBar.open(`Negócio criado com sucesso`, "Fechar", {
            duration: 2000,
          });
          this.dialogRef.close(this.dealForm.value);
        });
      });
    }
    else{
      delete _dataSave.name_person;
      delete _dataSave.email;
      delete _dataSave.phone;
      delete _dataSave.status;
      delete _dataSave.ptype;
      delete _dataSave.products;
      _dataSave.person_id = this.person_id_value;
      this.insertCardwithID.mutate(_dataSave).subscribe(v => {
        let _productsSave = {itens : _productsNew.itens.map(function(itens) {
          itens["pipeline_card_id"] = v.data.insert_omnichannel_pipeline_card_one.id;
          return itens;
        })};
        this.changeOrderCards.mutate({ list_id: this.list_id, card_id: v.data.insert_omnichannel_pipeline_card_one.id, order: 0}).subscribe(v => {
          console.log('save', v);
          this.store.dispatch(layoutActions.setLoadingPage({ loading: false }));
        });
        this.insertCardProduct.mutate(_productsSave).subscribe(v => {
          this._snackBar.open(`Négocio criado com sucesso`, "Fechar", {
            duration: 2000,
          });
          this.dialogRef.close(this.dealForm.value);
        });
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  addProducts($event: any): void {
    this.qtdProduct = [];
    for (let prod in $event) {
      this.qtdProduct.push({name: 'p_'+prod, title: $event[prod].name});
      this.dealForm.addControl('p_'+prod, new FormControl('', Validators.required));
    }
  }

  get selectedType(): string {
    return this.dealForm.get('status_id').value;
  }

  set selectedType(type: string) {
    //if (Object.values(DealType).includes(type)) {
      this.dealForm.get('status_id').setValue(type);
    //}
  }

  get title() {
    return this.dealForm.get('title');
  }

  get name_person() {
    return this.dealForm.get('name_person');
  }

  get company() {
    return this.dealForm.get('company');
  }

  get associatedWith() {
    return this.dealForm.get('associatedWith');
  }

  get closeDate() {
    return this.dealForm.get('date_close');
  }

  get ptype() {
    return this.dealForm.get('ptype');
  }

  get price() {
    return this.dealForm.get('price');
  }
}
