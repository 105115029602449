import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent implements OnInit {
  @HostBinding('class.logo') true;
  @HostBinding('class.minimized') @Input() minimize: boolean;

  @Input() src: string;
  @Input() minSrc: string;
  @Input() alt: string;

  logo: string;

  constructor() {}

  ngOnInit() {}
}
