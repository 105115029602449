<div class="title-block">
  <h3 class="title">Components</h3>

  <mat-icon (click)="closeDialog()">close</mat-icon>
</div>

<div class="search-wrap">
  <app-search
    [formControl]="filterInput"
    [placeholder]="'Search component'"
    [bgColor]="'#F5F8FA'"
  ></app-search>
</div>

<div class="list-wrap">
  <div class="components-list">
    <div class="component" *ngFor="let component of filteredComponents$ | async">
      <div class="component-icon">
        <img [src]="component.icon" [alt]="component.title" />
      </div>

      <span class="title">
        {{ component.title }}
      </span>
    </div>
  </div>
</div>
