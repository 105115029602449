<div class="deals-list-wrap" *ngIf="cards$ | async as cards">
  <div class="title-block">
    <h3 class="title">{{data.title}}</h3>
    <span class="counter">{{ data.cards_aggregate.aggregate.count }}</span>
  </div>
  <div
    *ngIf="!(isLoading | async)"
    class="list-block"
    cdkDropList
    [cdkDropListData]="cards$ | async"
    (cdkDropListDropped)="drop($event, dealStatuses.new)"
    snInfiniteScroll
    (scrollEnd)="onScrollDown()"
    [offset]="1000"
    [disabled]="disabled"
  >
    <div
      class="drag-block"
      *ngFor="let deal of cards"
      cdkDrag
      [cdkDragData]="deal"
      (cdkDragStarted)="onDragging()"
      (cdkDragReleased)="onRelease()"
    >
      <div class="drop-placeholder" *cdkDragPlaceholder></div>
      <app-lead [deal]="deal" (deleteDeal)="deleteDeal($event)" (click)="openDeal(deal)" (editDeal)="editDeal($event)" (deleteDeal)="deleteDeal($event)" ></app-lead>
    </div>
  </div>
  <ngx-skeleton-loader *ngIf="(isLoading | async)" count="3" [theme]="{height: '90px'}"></ngx-skeleton-loader>
</div>