<mat-card class="mb-0">
  <mat-card-header>
    <h3 class="title">Preferências de notificação</h3>
  </mat-card-header>

  <form [formGroup]="form">
    <div class="card-content">
      <div class="preference-field">
        <div class="text">
          <h4 class="title">Notificação da guia do navegador</h4>
          <span class="desc">Exibir contagem de novas mensagens na guia do navegador</span>
        </div>

        <mat-checkbox formControlName="browertab"></mat-checkbox>
        <div class="divider"></div>
      </div>

      <div class="preference-field">
        <div class="text">
          <h4 class="title">Habilitar envio do lead</h4>
          <span class="desc">Habilita a receber lead.</span>
        </div>

        <mat-checkbox formControlName="enable_lead"></mat-checkbox>
        <div class="divider"></div>
      </div>

      <div class="preference-field">
        <div class="text email">
          <h4 class="title">Notificações de email</h4>

          <mat-form-field class="email-field">
            <mat-label>E-mail</mat-label>
            <input type="text" matInput  formControlName="email_notification" value="fulano@empresa.com">
          </mat-form-field>
        </div>

        <mat-checkbox formControlName="enable_email"></mat-checkbox>
        <div class="divider"></div>
      </div>

      <div class="preference-field">
        <div class="text email">
          <h4 class="title">Notificações por SMS</h4>

          <mat-form-field class="email-field">
            <mat-label>Celular</mat-label>
            <input type="text" matInput formControlName="celular" [textMask]="{mask: mask}">
          </mat-form-field>
        </div>

        <mat-checkbox formControlName="enable_celular"></mat-checkbox>
        <div class="divider"></div>
      </div>


      <div class="preference-field">
        <div class="text">
          <h4 class="title">Desativar notificações por e-mail automaticamente</h4>

          <div class="time-selects">
            <mat-form-field>
              <mat-label>De</mat-label>
              <mat-select [value]="08" formControlName="disable_not_from">
                <mat-option [value]="06">06:00</mat-option>
                <mat-option [value]="07">07:00</mat-option>
                <mat-option [value]="08">08:00</mat-option>
                <mat-option [value]="09">09:00</mat-option>
                <mat-option [value]="10">10:00</mat-option>
                <mat-option [value]="11">11:00</mat-option>
                <mat-option [value]="12">12:00</mat-option>
                <mat-option [value]="13">13:00</mat-option>
                <mat-option [value]="14">14:00</mat-option>
                <mat-option [value]="15">15:00</mat-option>
                <mat-option [value]="16">16:00</mat-option>
                <mat-option [value]="17">17:00</mat-option>
                <mat-option [value]="18">18:00</mat-option>
                <mat-option [value]="19">19:00</mat-option>
                <mat-option [value]="20">20:00</mat-option>
                <mat-option [value]="21">21:00</mat-option>
                <mat-option [value]="22">22:00</mat-option>
                <mat-option [value]="23">23:00</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Até</mat-label>
              <mat-select [value]="18" formControlName="disable_not_to">
                <mat-option [value]="06">06:00</mat-option>
                <mat-option [value]="07">07:00</mat-option>
                <mat-option [value]="08">08:00</mat-option>
                <mat-option [value]="09">09:00</mat-option>
                <mat-option [value]="10">10:00</mat-option>
                <mat-option [value]="11">11:00</mat-option>
                <mat-option [value]="12">12:00</mat-option>
                <mat-option [value]="13">13:00</mat-option>
                <mat-option [value]="14">14:00</mat-option>
                <mat-option [value]="15">15:00</mat-option>
                <mat-option [value]="16">16:00</mat-option>
                <mat-option [value]="17">17:00</mat-option>
                <mat-option [value]="18">18:00</mat-option>
                <mat-option [value]="19">19:00</mat-option>
                <mat-option [value]="20">20:00</mat-option>
                <mat-option [value]="21">21:00</mat-option>
                <mat-option [value]="22">22:00</mat-option>
                <mat-option [value]="23">23:00</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <mat-checkbox formControlName="disable_notification"></mat-checkbox>
      </div>
      <button class="ml-auto" (click)="onSave()" tc-button>Salvar</button>
    </div>
  </form>
</mat-card>