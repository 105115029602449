import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'page-chat',
  templateUrl: './page-chat.component.html',
  styleUrls: ['./page-chat.component.scss']
})
export class PageChatComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
