import { createAction } from '@ngrx/store';

const SET_MINIMIZED = '[Toggle] Set minimized';

const SET_FULL_SIZE = '[Toggle] Set full view';

const SET_MOBILE = '[Window] Mobile';

export const setSidebarMinimized = createAction(SET_MINIMIZED);
export const setSidebarFullSize = createAction(SET_FULL_SIZE);
export const setSidebarMobile = createAction(SET_MOBILE);

export const toggleSidebar = (state: boolean) => {
  localStorage.setItem('toggleSidebar', String(state));
  if (state) {
    return setSidebarMinimized();
  }

  return setSidebarFullSize();
};
