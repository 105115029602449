import { ActionReducerMap } from '@ngrx/store';

import * as fromSidebar from './side-bar.reducer';
import * as fromLayout from './layout.reducer';

import { IState } from '../../interfaces/state';

export const reducers: ActionReducerMap<IState> = {
  sidebarState: fromSidebar.reducer,
  layoutState: fromLayout.reducer
};

