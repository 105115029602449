<mat-card>
  <mat-card-header>
    <mat-card-title>Material buttons</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <h6>Basic Buttons</h6>
    <div class="elem-list">
      <button mat-button>Basic</button>
      <button mat-button color="primary">Primary</button>
      <button mat-button color="accent">Accent</button>
      <button mat-button color="warn">Warn</button>
      <button mat-button disabled>Disabled</button>
      <a mat-button routerLink=".">Link</a>
    </div>

    <h6>Raised Buttons</h6>
    <div class="elem-list">
      <button mat-raised-button>Basic</button>
      <button mat-raised-button color="primary">Primary</button>
      <button mat-raised-button color="accent">Accent</button>
      <button mat-raised-button color="warn">Warn</button>
      <button mat-raised-button disabled>Disabled</button>
      <a mat-raised-button routerLink=".">Link</a>
    </div>

    <h6>Stroked Buttons</h6>
    <div class="elem-list">
      <button mat-stroked-button>Basic</button>
      <button mat-stroked-button color="primary">Primary</button>
      <button mat-stroked-button color="accent">Accent</button>
      <button mat-stroked-button color="warn">Warn</button>
      <button mat-stroked-button disabled>Disabled</button>
      <a mat-stroked-button routerLink=".">Link</a>
    </div>

    <h6>Flat Buttons</h6>
    <div class="elem-list">
      <button mat-flat-button>Basic</button>
      <button mat-flat-button color="primary">Primary</button>
      <button mat-flat-button color="accent">Accent</button>
      <button mat-flat-button color="warn">Warn</button>
      <button mat-flat-button disabled>Disabled</button>
      <a mat-flat-button routerLink=".">Link</a>
    </div>

    <h6>Icon Buttons</h6>
    <div class="elem-list">
      <button mat-icon-button>
        <mat-icon>face</mat-icon>
      </button>
      <button mat-icon-button color="primary">
        <mat-icon>shopping_basket</mat-icon>
      </button>
      <button mat-icon-button color="accent">
        <mat-icon>language</mat-icon>
      </button>
      <button mat-icon-button color="warn">
        <mat-icon>donut_small</mat-icon>
      </button>
      <button mat-icon-button disabled>
        <mat-icon>favorite</mat-icon>
      </button>
    </div>

    <h6>Fab Buttons</h6>
    <div class="elem-list">
      <button mat-fab><mat-icon>face</mat-icon></button>
      <button mat-fab color="primary"><mat-icon>shopping_basket</mat-icon></button>
      <button mat-fab color="accent"><mat-icon>language</mat-icon></button>
      <button mat-fab color="warn"><mat-icon>donut_small</mat-icon></button>
      <button mat-fab disabled><mat-icon>favorite</mat-icon></button>
    </div>

    <h6>Mini Fab Buttons</h6>
    <div class="elem-list">
      <button mat-mini-fab><mat-icon>face</mat-icon></button>
      <button mat-mini-fab color="primary"><mat-icon>shopping_basket</mat-icon></button>
      <button mat-mini-fab color="accent"><mat-icon>language</mat-icon></button>
      <button mat-mini-fab color="warn"><mat-icon>donut_small</mat-icon></button>
      <button mat-mini-fab disabled><mat-icon>favorite</mat-icon></button>
    </div>
  </mat-card-content>
</mat-card>

<mat-card class="mb-0">
  <mat-card-header>
    <mat-card-title>Theme buttons</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="elem-list">
      <button tc-button>Default</button>
      <button tc-button [view]="'error'">Error</button>
      <button tc-button [type]="'accent'">Accent</button>
      <button tc-button [type]="'secondary'">Secondary</button>
      <button tc-button [type]="'tertiary'">Tertiary</button>
      <button tc-button [disabled]="true">Disabled</button>
    </div>
  </mat-card-content>
</mat-card>
