<mat-card class="example-card">
  <mat-card-header>
    <mat-card-title>Basic grid-list</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-grid-list cols="2" rowHeight="2:1">
      <mat-grid-tile>1</mat-grid-tile>
      <mat-grid-tile>2</mat-grid-tile>
      <mat-grid-tile>3</mat-grid-tile>
      <mat-grid-tile>4</mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>

<mat-card class="example-card">
  <mat-card-header>
    <mat-card-title>Dynamic grid-list</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-grid-list cols="4" rowHeight="100px">
      <mat-grid-tile
        *ngFor="let tile of tiles"
        [colspan]="tile.cols"
        [rowspan]="tile.rows"
      >
        {{ tile.text }}
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>

<mat-card class="example-card mb-0">
  <mat-card-header>
    <mat-card-title>Bootstrap grid</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="container">
      <div class="row">
        <div class="col col-12 col-sm-1">col-1</div>
        <div class="col col-12 col-sm-11">col-11</div>
      </div>
      <div class="row">
        <div class="col col-12 col-sm-2">col-2</div>
        <div class="col col-12 col-sm-10">col-10</div>
      </div>
      <div class="row">
        <div class="col col-12 col-sm-3">col-3</div>
        <div class="col col-12 col-sm-9">col-9</div>
      </div>
      <div class="row">
        <div class="col col-12 col-sm-4">col-4</div>
        <div class="col col-12 col-sm-8">col-8</div>
      </div>
      <div class="row">
        <div class="col col-12 col-sm-5">col-5</div>
        <div class="col col-12 col-sm-7">col-7</div>
      </div>
      <div class="row">
        <div class="col col-12 col-sm-6">col-6</div>
        <div class="col col-12 col-sm-6">col-6</div>
      </div>
      <div class="row">
        <div class="col col-12 col-sm-7">col-7</div>
        <div class="col col-12 col-sm-5">col-5</div>
      </div>
      <div class="row">
        <div class="col col-12 col-sm-8">col-8</div>
        <div class="col col-12 col-sm-4">col-4</div>
      </div>
      <div class="row">
        <div class="col col-12 col-sm-9">col-9</div>
        <div class="col col-12 col-sm-3">col-3</div>
      </div>
      <div class="row">
        <div class="col col-12 col-sm-10">col-10</div>
        <div class="col col-12 col-sm-2">col-2</div>
      </div>
      <div class="row">
        <div class="col col-12 col-sm-11">col-11</div>
        <div class="col col-12 col-sm-1">col-1</div>
      </div>
      <div class="row">
        <div class="col col-12">col-12</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
