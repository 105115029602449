<ul class="menu-ul">
  <ng-container *ngFor="let item of menu">
    <li class="divider-item" *ngIf="item.acl_feature.divider && !mobile">
      <mat-divider class="divider"></mat-divider>
    </li>

    <ng-container *ngIf="(item.desktop && !mobile) || !item.desktop">
      <li class="menu-li" [ngClass]="{ 'has-sub': item.acl_feature.is_submenu }" #rla="routerLinkActive" routerLinkActive="active">
        <a
          class="item-link"
          [routerLink]="item.acl_feature.routing"
          *ngIf="!item.acl_feature.is_submenu"
        >
          <mat-icon class="item-icon">{{ item.acl_feature.icon }}</mat-icon>

          <span class="item-text">{{ item.acl_feature.name }}</span>
        </a>

        <a
          class="item-link"
          href="#"
          *ngIf="item.sub"
          (click)="toggleSub($event, item)"
        >
          <mat-icon class="item-icon">{{ item.acl_feature.icon }}</mat-icon>

          <span class="item-text">{{ item.acl_feature.name }}</span>

          <mat-icon class="item-sub-marker" *ngIf="item.acl_feature.is_submenu">keyboard_arrow_down</mat-icon>
        </a>

        <ng-container *ngIf="item.sub">
          <ul class="sub-ul" [@filters]="(item.active) ? 'active' : 'inactive'">
            <li class="menu-li" *ngFor="let itemSub of item.sub">
              <a
                class="item-link"
                [routerLink]="itemSub.routing"
                routerLinkActive="active"
                #sub="routerLinkActive"
              >
                <span class="item-text">{{ itemSub.title }}</span>
              </a>
            </li>
          </ul>
        </ng-container>
      </li>

      <li
        *ngIf="item.filters && item.filters.length && !mobile"
        [@filters]="(item.active || rla.isActive) && (!minimized) ? 'active' : 'inactive'"
        class="filters"
      >
        <app-filter-list [active]="activeParam" [filters]="item.filters"></app-filter-list>
      </li>
    </ng-container>
  </ng-container>
</ul>
