import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material';

import { TCCircleBadgeComponent } from './components/circle-badge';
import { TCButtonComponent } from './components/button';
import { TCBadgeComponent } from './components/badge';
import { TCSelectComponent } from './components/select/select.component';

@NgModule({
  declarations: [TCCircleBadgeComponent, TCBadgeComponent, TCButtonComponent, TCSelectComponent],
  imports: [CommonModule, MatIconModule],
  exports: [TCCircleBadgeComponent, TCBadgeComponent, TCButtonComponent, TCSelectComponent]
})
export class UiModule {}
