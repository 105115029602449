<div class="header">
  <h4 mat-dialog-title class="mb-0">Edit deal</h4>
  <mat-icon (click)="closeDialog()">close</mat-icon>
</div>

<mat-dialog-content class="content">
  <div class="statuses">
    <tc-badge
      *ngFor="let type of types"
      [ngClass]="{ active: selectedType === type }"
      (click)="selectedType = type"
      [view]="badgeViews[type]"
    >
      {{ type }}
    </tc-badge>
  </div>

  <form [formGroup]="dealForm" class="task-form">
    <mat-form-field class="full-width">
      <mat-label>Subject</mat-label>
      <input matInput placeholder="Subject" formControlName="company" />
      <ng-container *ngIf="company.invalid && (company.touched || company.dirty)">
        <mat-error @fadeIn>Enter deal subject</mat-error>
      </ng-container>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Associated with</mat-label>
      <mat-select formControlName="associatedWith">
        <mat-option [value]="user.id" *ngFor="let user of data.users | async">
          <div class="user-option">
            <img class="user-img" [src]="user.avatar" [alt]="user.name" />
            <span class="user-name">{{ user.name }}</span>
          </div>
        </mat-option>
      </mat-select>

      <ng-container *ngIf="associatedWith.invalid && (associatedWith.touched || associatedWith.dirty)">
        <mat-error @fadeIn>Please select associated user</mat-error>
      </ng-container>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Close date</mat-label>
      <input matInput [matDatepicker]="picker" placeholder="Choose close date"  [formControl]="closeDate"/>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>

      <ng-container *ngIf="closeDate.invalid && (closeDate.touched || closeDate.dirty)">
        <mat-error @fadeIn>Please select task date</mat-error>
      </ng-container>
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>Amount</mat-label>
      <span matPrefix>$ &nbsp;</span>
      <input matInput placeholder="Subject" formControlName="price" />
      <ng-container *ngIf="price.invalid && (price.touched || price.dirty)">
        <mat-error @fadeIn>Enter deal amount</mat-error>
      </ng-container>
    </mat-form-field>
  </form>
</mat-dialog-content>

<button (click)="editDeal()" tc-button [block]="true">Update</button>
