import { Component, HostBinding, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';

import { IUser } from '../../interfaces/user';
import { ProfileService } from '../../services/profile.service';

import { fadeIn } from '../../animations/form-error';
import * as layoutActions from '../../store/actions/layout.actions';
import { IState } from '../../interfaces/state';

import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'page-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: [fadeIn]
})
export class PageProfileComponent implements OnInit {
  @HostBinding('class.page-profile') true;

  profile$: Observable<IUser>;

  profileForm: FormGroup;
  passwordForm: FormGroup;
  submitted = false;

  editing: boolean;

  constructor(private profileSv: ProfileService, private store: Store<IState>, public auth: AuthService, private router: Router, private _snackBar: MatSnackBar) {}

  ngOnInit() {
    this.editing = false;
    this.store.dispatch(layoutActions.setPageTitle({ title: 'Seu profile' }));
    this.store.dispatch(layoutActions.setPagePlaceholder({ placeholder: '' }));

    this.profile$ = this.profileSv.getProfile();
    this.initProfileForm(this.profile$);
    this.initPasswordForm();
  }

  edit(): void {
    if (this.editing) {
      this.profileSv.editProfile(this.profileForm.value);
    }

    this.editing = !this.editing;
  }

  initProfileForm(profileObservable: Observable<IUser>): void {
    profileObservable.subscribe(
      profile =>
        (this.profileForm = new FormGroup(
          {
            display_name: new FormControl(profile.display_name),
            email: new FormControl(profile.email),
            phone: new FormControl(profile.phone),
            city: new FormControl(profile.city),
            state: new FormControl(profile.state),
            country: new FormControl(profile.country),
            company: new FormControl(profile.company)
          },
          { validators: [Validators.required] }
        ))
    );
  }

  initPasswordForm(): void {
    this.passwordForm = new FormGroup(
      {
        prevPassword: new FormControl(''),
        newPassword: new FormControl('', [Validators.minLength(6)]),
        confirmPassword: new FormControl('')
      },
      { validators: [Validators.required, this.MatchPassword] }
    );
  }

  get prevPassword() {
    return this.passwordForm.get('prevPassword');
  }

  get newPassword() {
    return this.passwordForm.get('newPassword');
  }

  get confirmPassword() {
    return this.passwordForm.get('confirmPassword');
  }

  MatchPassword(control: AbstractControl) {
    const PASSWORD = control.get('newPassword').value;

    const CONFIRM = control.get('confirmPassword').value;

    if (PASSWORD !== CONFIRM) {
      control.get('confirmPassword').setErrors({ ConfirmPassword: true });
    } else {
      return null;
    }
  }

  async onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.passwordForm.invalid) {
      return;
    }

    try {
      this.auth.changePassword(this.passwordForm.value.prevPassword, this.passwordForm.value.newPassword);
    } catch (err) {
        this._snackBar.open(`Erro:${err.message}`, "Fechar", {
          duration: 2000,
        });
      return;
    }
  }
}
