import { Component, OnInit } from '@angular/core';

import { TagsService } from '../../../services/tags.service';
import { Observable } from 'rxjs';
import { ITagGroup } from '../../../interfaces/tag';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {
  tags$: Observable<ITagGroup[]>;
  constructor(private tagSv: TagsService) {}

  ngOnInit() {
    this.tags$ = this.tagSv.tags$;
  }
}
