import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'page-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss']
})
export class PageIconsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
