import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: [
    '../notifications-menu/notifications-menu.component.scss',
    './settings.component.scss'
  ]
})
export class SettingsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
