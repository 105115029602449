import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material';
import { AuthService } from "./services/auth.service"

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
  `
})
export class AppComponent {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer, public auth: AuthService) {
    auth.handleAuthentication();
    console.log('auth');
  }

  addIcons(): void {
    this.iconRegistry.addSvgIcon(
      'notification',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/notification.svg')
    );
    this.iconRegistry.addSvgIcon(
      'facebook',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/facebook-icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'twitter',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/twitter-icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'account_settings',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/account-settings-icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'notifications',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/notifications-icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'integrations',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/integrations-icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'colleagues',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/colleagues-icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'tags',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/tags-icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'add',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/add.svg')
    );
    this.iconRegistry.addSvgIcon(
      'avatar',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/avatar-icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'img',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/img-icon.svg')
    );
  }
}
