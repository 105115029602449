import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import * as Query from '../../graph-ql/queries';
import { Apollo } from 'apollo-angular';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


    constructor(public auth: AuthService, private router: Router, private apollo: Apollo) {
        auth.isAuthenticated();
    }
    ngOnInit() {
        this.apollo.watchQuery<any>({
            query: Query.query_user_one,
            variables: { "id": this.auth.getUserID() }
        })
            .valueChanges
            .subscribe(({ data }) => {
                localStorage.setItem('user', JSON.stringify(data));
                localStorage.setItem('teamid', data.users_by_pk.members[0].teams.id);
                let _routing = '/private/dashboard';
                if(data.users_by_pk.feature_default != undefined && data.users_by_pk.feature_default.routing != undefined){
                    _routing = `/private/${data.users_by_pk.feature_default.routing}`
                }
                this.router.navigate([_routing]);
            });
    }

}