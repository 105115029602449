import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { DealType, IDeal } from '../../../interfaces/deal';

@Component({
  selector: 'app-deal',
  templateUrl: './deal.component.html',
  styleUrls: ['./deal.component.scss']
})
export class DealComponent implements OnInit {
  @HostBinding('class.app-task') true;

  @HostBinding('class.low') get isLow() {
    return this.deal.type === DealType.low;
  }
  @HostBinding('class.high') get isHigh() {
    return this.deal.type === DealType.high;
  }
  @HostBinding('class.urgent') get isUrgent() {
    return this.deal.type === DealType.urgent;
  }

  @Input() deal: IDeal;

  @Output() editDeal: EventEmitter<IDeal>;
  @Output() deleteDeal: EventEmitter<IDeal>;

  constructor() {
    this.editDeal = new EventEmitter<IDeal>();
    this.deleteDeal = new EventEmitter<IDeal>();
  }

  ngOnInit() {}

  onDealEdit(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.editDeal.emit(this.deal);
  }

  onDeleteDeal(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.deleteDeal.emit(this.deal);
  }
}
