import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ITask, BadgeStyles } from '../../../interfaces/task';

@Component({
  selector: 'app-opened-task',
  templateUrl: './opened-task.component.html',
  styleUrls: ['../opened-deal/opened-deal.component.scss', './opened-task.component.scss']
})
export class OpenedTaskComponent implements OnInit {
  badgeViews: any;

  constructor(
    private dialogRef: MatDialogRef<OpenedTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public task: ITask
  ) {}

  ngOnInit() {
    this.badgeViews = BadgeStyles;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
