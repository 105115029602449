import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ITagGroup } from '../interfaces/tag';
import { HttpService } from './http.service';
import * as Query from '../graph-ql/queries';
import { Apollo, Subscription, QueryRef } from 'apollo-angular';
import * as Mutation from '../graph-ql/mutation';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class TagsService {
  private tagsSource: BehaviorSubject<ITagGroup[]>;
  private tagsUrl: string;
  tags$: Observable<ITagGroup[]>;

  tagsFilter$: Observable<any>;
  private tagsFilterSource: BehaviorSubject<any>;

  constructor(private httpSv: HttpService, private apollo: Apollo, private router: Router, private _snackBar: MatSnackBar) {
    this.tagsUrl = '../../../assets/data/tags.json';
    this.tagsSource = new BehaviorSubject([]);
    this.tags$ = this.tagsSource.asObservable();

    this.tagsFilterSource = new BehaviorSubject({});
    this.tagsFilter$ = this.tagsFilterSource.asObservable();

    this.httpSv.getData<ITagGroup[]>(this.tagsUrl).subscribe(res => this.tagsSource.next(res));
  }

  getPersonTags() {
    this.apollo.watchQuery<any>({
      query: Query.query_tags,
      variables: {}
    }).valueChanges
      .subscribe(({ data }) => {
        this.tagsFilterSource.next(data.omnichannel_tags)
      });
  }
}
